export enum DISCOUNT_CODE_STATUS {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
  EXPIRED = 'EXPIRED',
}

export const discountCodesStatusesLabel = {
  [DISCOUNT_CODE_STATUS.ACTIVE]: {
    classStyle: 'approved',
    label: DISCOUNT_CODE_STATUS.ACTIVE,
  },
  [DISCOUNT_CODE_STATUS.DISABLED]: {
    classStyle: 'rejected',
    label: DISCOUNT_CODE_STATUS.DISABLED,
  },
  [DISCOUNT_CODE_STATUS.EXPIRED]: {
    classStyle: 'expired',
    label: DISCOUNT_CODE_STATUS.EXPIRED,
  },
}

export enum DISCOUNT_CODE_USERS_STATUS {
  INVITED = 'INVITED',
  REDEEMED = 'REDEEMED',
  EXPIRED = 'EXPIRED',
}

export const discountCodeUsersStatusesLabel = {
  [DISCOUNT_CODE_USERS_STATUS.INVITED]: {
    classStyle: 'running',
    label: DISCOUNT_CODE_USERS_STATUS.INVITED,
  },
  [DISCOUNT_CODE_USERS_STATUS.REDEEMED]: {
    classStyle: 'approved',
    label: DISCOUNT_CODE_USERS_STATUS.REDEEMED,
  },
  [DISCOUNT_CODE_USERS_STATUS.EXPIRED]: {
    classStyle: 'expired',
    label: DISCOUNT_CODE_USERS_STATUS.EXPIRED,
  },
}
