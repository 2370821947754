import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { AxiosResponse } from 'axios'
import IAction, { TName } from 'src/interfaces/IAction'

import * as CONSTANTS from './targetingKeys.constants'
import { requestHttp, urls } from 'src/api'
import { getResponseErrorMessage } from 'src/helpers'

export const targetingKeyRequest = (): IAction => ({
  type: CONSTANTS.TARGETING_KEYS_REQUEST,
})

export const targetingKeyEndRequest = (): IAction => ({
  type: CONSTANTS.TARGETING_KEYS_END_REQUEST,
})

export const targetingKeySuccess = (data: any): IAction => ({
  type: CONSTANTS.TARGETING_KEYS_SUCCESS,
  payload: data,
})

export const targetingKeyDetailsSuccess = (data: any): IAction => ({
  type: CONSTANTS.TARGETING_KEYS_DETAILS_SUCCESS,
  payload: data,
})

export const targetingKeyFailure = (error: string): IAction => ({
  type: CONSTANTS.TARGETING_KEYS_FAILURE,
  error,
})

export const createTargetingKey = (targetingKey:any): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse> => {
  try {
    dispatch(targetingKeyRequest())
    const response = await requestHttp.post(urls.getCreateTargetingKeyUrl(),targetingKey)
    const { content } = response.data.data;
    dispatch(targetingKeyEndRequest())
    return content;
  } catch (error) {
    dispatch(targetingKeyFailure(getResponseErrorMessage(error)))
    return error
  }
}

export const editTargetingKey = (targetingKeyId:number,targetingKey:any): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse> => {
  try {
    dispatch(targetingKeyRequest())
    const response = await requestHttp.put(urls.getEditTargetingKeyUrl(targetingKeyId),targetingKey)
    const { content } = response.data.data;
    dispatch(targetingKeyEndRequest())
    return content;
  } catch (error) {
    dispatch(targetingKeyFailure(getResponseErrorMessage(error)))
    return error
  }
}

export const getAllTargetingKeys = (): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse> => {
  try {
    dispatch(targetingKeyRequest())
    const response = await requestHttp.get(urls.getTargetingKeysListUrl()+'?offset=0,limit=500')
    const { content } = response.data.data;
    dispatch(targetingKeySuccess({data:content.results,rootTotal:content.total}))
    return content;
  } catch (error) {
    dispatch(targetingKeyFailure(getResponseErrorMessage(error)))
    return error
  }
}

// export const getTargetingKeyDetails = (targetingKeyId:number): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (
//   dispatch: ThunkDispatch<{}, {}, AnyAction>
// ): Promise<AxiosResponse> => {
//   try {
//     dispatch(targetingKeyRequest())
//     const response = await requestHttp.get(urls.getTargetingKeyDetailsUrl(targetingKeyId))
//     const { content } = response.data.data;
//     dispatch(targetingKeyEndRequest())
//     return content;
//   } catch (error) {
//     dispatch(targetingKeyFailure(getResponseErrorMessage(error)))
//     return error
//   }
// }

export const deleteTargetingKey = (targetingKeyId:number): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse> => {
  try {
    dispatch(targetingKeyRequest())
    const response = await requestHttp.delete(urls.getDeleteTargetingKeyUrl(targetingKeyId))
    const { content } = response.data.data;
    dispatch(targetingKeyEndRequest())
    return content;
  } catch (error) {
    dispatch(targetingKeyFailure(getResponseErrorMessage(error)))
    return error
  }
}

// export const toggleTargetingKeyStatus = (id:number,isEnabled:boolean): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (
//   dispatch: ThunkDispatch<{}, {}, AnyAction>
// ): Promise<AxiosResponse> => {
//   try {
//     dispatch(targetingKeyRequest())
//     const response = await requestHttp.patch(urls.toggleTargetingKeyStatus(),{id,isEnabled})
//     const { content } = response.data.data;
//     dispatch(targetingKeyEndRequest())
//     return content;
//   } catch (error) {
//     dispatch(targetingKeyFailure(getResponseErrorMessage(error)))
//     return error
//   }
// }


