export const MODULE_NAME = 'campaign'
/* actions */

export const RESET_CAMPAIGN_STATE = `${MODULE_NAME}/RESET_CAMPAIGN_STATE`
export const SET_CAMPAIGN_LIST_PARAMS = `${MODULE_NAME}/SET_CAMPAIGN_LIST_PARAMS`
export const SET_CAMPAIGN_GALLERY_PARAMS = `${MODULE_NAME}/SET_CAMPAIGN_GALLERY_PARAMS`
export const SET_CURRENT_PAGE = `${MODULE_NAME}/SET_CURRENT_PAGE`
export const SET_TIMELINE_CURRENT_PAGE = `${MODULE_NAME}/SET_TIMELINE_CURRENT_PAGE`
export const SET_GALLERY_CURRENT_PAGE = `${MODULE_NAME}/SET_GALLERY_CURRENT_PAGE`

export const FETCH_CAMPAIGNS_REQUEST = `${MODULE_NAME}/FETCH_CAMPAIGNS_REQUEST`
export const FETCH_CAMPAIGNS_SUCCESS = `${MODULE_NAME}/FETCH_CAMPAIGNS_SUCCESS`
export const FETCH_CAMPAIGNS_FAILURE = `${MODULE_NAME}/FETCH_CAMPAIGNS_FAILURE`

export const FETCH_CAMPAIGN_DETAILS_REQUEST = `${MODULE_NAME}/FETCH_CAMPAIGN_DETAILS_REQUEST`
export const FETCH_CAMPAIGN_DETAILS_SUCCESS = `${MODULE_NAME}/FETCH_CAMPAIGN_DETAILS_SUCCESS`
export const FETCH_CAMPAIGN_DETAILS_FAILURE = `${MODULE_NAME}/FETCH_CAMPAIGN_DETAILS_FAILURE`

export const FETCH_CAMPAIGN_SETTINGS_REQUEST = `${MODULE_NAME}/FETCH_CAMPAIGN_SETTINGS_REQUEST`
export const FETCH_CAMPAIGN_SETTINGS_SUCCESS = `${MODULE_NAME}/FETCH_CAMPAIGN_SETTINGS_SUCCESS`
export const FETCH_CAMPAIGN_SETTINGS_FAILURE = `${MODULE_NAME}/FETCH_CAMPAIGN_SETTINGS_FAILURE`

export const FETCH_CAMPAIGN_DISTRIBUTION_REQUEST = `${MODULE_NAME}/FETCH_CAMPAIGN_DISTRIBUTION_REQUEST`
export const FETCH_CAMPAIGN_DISTRIBUTION_SUCCESS = `${MODULE_NAME}/FETCH_CAMPAIGN_DISTRIBUTION_SUCCESS`
export const FETCH_CAMPAIGN_DISTRIBUTION_FAILURE = `${MODULE_NAME}/FETCH_CAMPAIGN_DISTRIBUTION_FAILURE`

export const FETCH_CAMPAIGN_PROPS_REQUEST = `${MODULE_NAME}/FETCH_CAMPAIGN_PROPS_REQUEST`
export const FETCH_CAMPAIGN_PROPS_SUCCESS = `${MODULE_NAME}/FETCH_CAMPAIGN_PROPS_SUCCESS`
export const FETCH_CAMPAIGN_PROPS_FAILURE = `${MODULE_NAME}/FETCH_CAMPAIGN_PROPS_FAILURE`

export const FETCH_CAMPAIGNS_QUANTITY_INFO_SUCCESS = `${MODULE_NAME}/FETCH_CAMPAIGNS_QUANTITY_INFO_SUCCESS`
export const FETCH_CAMPAIGNS_QUANTITY_INFO_FAILURE = `${MODULE_NAME}/FETCH_CAMPAIGNS_QUANTITY_INFO_FAILURE`

export const UPDATE_CAMPAIGN_REQUEST = `${MODULE_NAME}/UPDATE_CAMPAIGN_REQUEST`
export const UPDATE_CAMPAIGN_SUCCESS = `${MODULE_NAME}/UPDATE_CAMPAIGN_SUCCESS`
export const UPDATE_CAMPAIGN_FAILURE = `${MODULE_NAME}/UPDATE_CAMPAIGN_FAILURE`

export const FETCH_CAMPAIGN_ANALYTICS_INFO_REQUEST = `${MODULE_NAME}/FETCH_CAMPAIGN_ANALYTICS_INFO_REQUEST`
export const FETCH_CAMPAIGN_ANALYTICS_INFO_SUCCESS = `${MODULE_NAME}/FETCH_CAMPAIGN_ANALYTICS_INFO_SUCCESS`
export const FETCH_CAMPAIGN_ANALYTICS_INFO_FAILURE = `${MODULE_NAME}/FETCH_CAMPAIGN_ANALYTICS_INFO_FAILURE`

export const FETCH_CAMPAIGN_ANALYTICS_DATA_REQUEST = `${MODULE_NAME}/FETCH_CAMPAIGN_ANALYTICS_DATA_REQUEST`
export const FETCH_CAMPAIGN_ANALYTICS_DATA_SUCCESS = `${MODULE_NAME}/FETCH_CAMPAIGN_ANALYTICS_DATA_SUCCESS`
export const FETCH_CAMPAIGN_ANALYTICS_DATA_FAILURE = `${MODULE_NAME}/FETCH_CAMPAIGN_ANALYTICS_DATA_FAILURE`

export const SET_DIST_ANALYTICS_FILTER = `${MODULE_NAME}/SET_DIST_ANALYTICS_FILTER`
export const SET_MEDIA_BUY_ANALYTICS_FILTER = `${MODULE_NAME}/SET_MEDIA_BUY_ANALYTICS_FILTER`

export const FETCH_CAMPAIGN_ANALYTICS_MEDIA_BUY_REQUEST = `${MODULE_NAME}/FETCH_CAMPAIGN_ANALYTICS_MEDIA_BUY_REQUEST`
export const FETCH_CAMPAIGN_ANALYTICS_MEDIA_BUY_SUCCESS = `${MODULE_NAME}/FETCH_CAMPAIGN_ANALYTICS_MEDIA_BUY_SUCCESS`
export const FETCH_CAMPAIGN_ANALYTICS_MEDIA_BUY_FAILURE = `${MODULE_NAME}/FETCH_CAMPAIGN_ANALYTICS_MEDIA_BUY_FAILURE`

export const FETCH_CAMPAIGN_SURVEY_REQUEST = `${MODULE_NAME}/FETCH_CAMPAIGN_SURVEY_REQUEST`
export const FETCH_CAMPAIGN_SURVEY_SUCCESS = `${MODULE_NAME}/FETCH_CAMPAIGN_SURVEY_SUCCESS`
export const FETCH_CAMPAIGN_SURVEY_FAILURE = `${MODULE_NAME}/FETCH_CAMPAIGN_SURVEY_FAILURE`

export const SAVE_CAMPAIGN_SURVEY_REQUEST = `${MODULE_NAME}/SAVE_CAMPAIGN_SURVEY_REQUEST`
export const SAVE_CAMPAIGN_SURVEY_SUCCESS = `${MODULE_NAME}/SAVE_CAMPAIGN_SURVEY_SUCCESS`
export const SAVE_CAMPAIGN_SURVEY_FAILURE = `${MODULE_NAME}/SAVE_CAMPAIGN_SURVEY_FAILURE`

export const FETCH_CAMPAIGN_MEDIA_BUY_FILTER_REQUEST = `${MODULE_NAME}/FETCH_CAMPAIGN_MEDIA_BUY_FILTER_REQUEST`
export const FETCH_CAMPAIGN_MEDIA_BUY_FILTER_SUCCESS = `${MODULE_NAME}/FETCH_CAMPAIGN_MEDIA_BUY_FILTER_SUCCESS`
export const FETCH_CAMPAIGN_MEDIA_BUY_FILTER_FAILURE = `${MODULE_NAME}/FETCH_CAMPAIGN_MEDIA_BUY_FILTER_FAILURE`

export const SAVE_CAMPAIGN_MEDIA_BUY_FILTER_REQUEST = `${MODULE_NAME}/SAVE_CAMPAIGN_MEDIA_BUY_FILTER_REQUEST`
export const SAVE_CAMPAIGN_MEDIA_BUY_FILTER_SUCCESS = `${MODULE_NAME}/SAVE_CAMPAIGN_MEDIA_BUY_FILTER_SUCCESS`
export const SAVE_CAMPAIGN_MEDIA_BUY_FILTER_FAILURE = `${MODULE_NAME}/SAVE_CAMPAIGN_MEDIA_BUY_FILTER_FAILURE`

export const FETCH_CAMPAIGN_MEDIA_BUY_REQUEST = `${MODULE_NAME}/FETCH_CAMPAIGN_MEDIA_BUY_REQUEST`
export const FETCH_CAMPAIGN_MEDIA_BUY_SUCCESS = `${MODULE_NAME}/FETCH_CAMPAIGN_MEDIA_BUY_SUCCESS`
export const FETCH_CAMPAIGN_MEDIA_BUY_FAILURE = `${MODULE_NAME}/FETCH_CAMPAIGN_MEDIA_BUY_FAILURE`

export const SAVE_CAMPAIGN_MEDIA_BUY_REQUEST = `${MODULE_NAME}/SAVE_CAMPAIGN_MEDIA_BUY_REQUEST`
export const SAVE_CAMPAIGN_MEDIA_BUY_SUCCESS = `${MODULE_NAME}/SAVE_CAMPAIGN_MEDIA_BUY_SUCCESS`
export const SAVE_CAMPAIGN_MEDIA_BUY_FAILURE = `${MODULE_NAME}/SAVE_CAMPAIGN_MEDIA_BUY_FAILURE`

export const FETCH_CAMPAIGN_TIMELINE_REQUEST = `${MODULE_NAME}/FETCH_CAMPAIGN_TIMELINE_REQUEST`
export const FETCH_CAMPAIGN_TIMELINE_SUCCESS = `${MODULE_NAME}/FETCH_CAMPAIGN_TIMELINE_SUCCESS`
export const FETCH_CAMPAIGN_TIMELINE_FAILURE = `${MODULE_NAME}/FETCH_CAMPAIGN_TIMELINE_FAILURE`

export const SAVE_CAMPAIGN_TIMELINE_REQUEST = `${MODULE_NAME}/SAVE_CAMPAIGN_TIMELINE_REQUEST`
export const SAVE_CAMPAIGN_TIMELINE_SUCCESS = `${MODULE_NAME}/SAVE_CAMPAIGN_TIMELINE_SUCCESS`
export const SAVE_CAMPAIGN_TIMELINE_FAILURE = `${MODULE_NAME}/SAVE_CAMPAIGN_TIMELINE_FAILURE`

export const UPLOAD_CAMPAIGN_TIMELINE_IMAGES_REQUEST = `${MODULE_NAME}/SAVE_CAMPAIGN_TIMELINE_IMAGES_REQUEST`
export const UPLOAD_CAMPAIGN_TIMELINE_IMAGES_SUCCESS = `${MODULE_NAME}/SAVE_CAMPAIGN_TIMELINE_IMAGES_SUCCESS`
export const UPLOAD_CAMPAIGN_TIMELINE_IMAGES_FAILURE = `${MODULE_NAME}/SAVE_CAMPAIGN_TIMELINE_IMAGES_FAILURE`

export const FETCH_CLIENT_SURVEYS_REQUEST = `${MODULE_NAME}/FETCH_CLIENT_SURVEYS_REQUEST`
export const FETCH_CLIENT_SURVEYS_SUCCESS = `${MODULE_NAME}/FETCH_CLIENT_SURVEYS_SUCCESS`
export const FETCH_CLIENT_SURVEYS_FAILURE = `${MODULE_NAME}/FETCH_CLIENT_SURVEYS_FAILURE`

export const SET_CLIENT_SURVEYS_PARAMS = `${MODULE_NAME}/SET_CLIENT_SURVEYS_PARAMS`
export const SET_CLIENT_SURVEYS_CURRENT_PAGE = `${MODULE_NAME}/SET_CLIENT_SURVEYS_CURRENT_PAGE`

export const SAVE_CAMPAIGN_GALLERY_REQUEST = `${MODULE_NAME}/SAVE_CAMPAIGN_GALLERY_REQUEST`
export const SAVE_CAMPAIGN_GALLERY_SUCCESS = `${MODULE_NAME}/SAVE_CAMPAIGN_GALLERY_SUCCESS`
export const SAVE_CAMPAIGN_GALLERY_FAILURE = `${MODULE_NAME}/SAVE_CAMPAIGN_GALLERY_FAILURE`

export const FETCH_CAMPAIGN_GALLERY_REQUEST = `${MODULE_NAME}/FETCH_CAMPAIGN_GALLERY_REQUEST`
export const FETCH_CAMPAIGN_GALLERY_SUCCESS = `${MODULE_NAME}/FETCH_CAMPAIGN_GALLERY_SUCCESS`
export const FETCH_CAMPAIGN_GALLERY_FAILURE = `${MODULE_NAME}/FETCH_CAMPAIGN_GALLERY_FAILURE`
