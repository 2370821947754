import React, { useEffect, useState } from 'react'
import { Col, Form, IconsCommon, Modal, QButton, QInput, QSelect, Row } from 'quantum_components'

interface Props {
  visible: boolean
  onCancel: () => void
  onSubmit: (reason: string) => void
  defaultReasons: string[]
}

const RejectBillingModal = ({ visible, onCancel, onSubmit, defaultReasons }: Props) => {
  const [defaultReason, setDefaultReason] = useState<string | null>(null)
  const [alternativeReason, setAlternativeReason] = useState<string | null>(null)

  const reasonOptions: { label: string; value: string | null }[] = [
    ...defaultReasons.map((reason: string) => ({ label: reason, value: reason })),
    { label: 'Other', value: null },
  ]

  useEffect(() => {
    setAlternativeReason(null)
  }, [defaultReason])

  useEffect(() => {
    if (alternativeReason) {
      setDefaultReason(null)
    }
  }, [alternativeReason])

  const submitHandler = () => {
    if (defaultReason) {
      onSubmit(defaultReason)
      return
    }

    if (alternativeReason) {
      onSubmit(alternativeReason)
      return
    }

    onSubmit('')
  }

  const hasReason = defaultReason || alternativeReason?.trim()
  const showAlternativeReasonInput = defaultReason === null
  return (
    <Modal
      wrapClassName="qu-modal"
      closeIcon={<IconsCommon.IconClose />}
      title="Reject"
      visible={visible}
      onCancel={onCancel}
      footer={
        <Row align="middle" justify="end">
          <Col>
            <QButton className="qu-button-outline mr-16" size="small" onClick={onCancel}>
              Cancel
            </QButton>
          </Col>
          <Col>
            <QButton disabled={!hasReason} className="w-100" type="primary" size="small" onClick={submitHandler}>
              Submit
            </QButton>
          </Col>
        </Row>
      }
    >
      <Form layout="vertical">
        <Form.Item colon={false} label={<b>Rejection Reason</b>}>
          <QSelect
            className="full-width"
            showSearch
            size="large"
            placeholder="Select a reason for rejection"
            help="This will be visible to the client"
            value={defaultReason}
            onChange={(reason: string) => setDefaultReason(reason)}
            options={reasonOptions}
          />
        </Form.Item>

        {showAlternativeReasonInput && (
          <Form.Item className="mb-0" colon={false}>
            <QInput
              size="large"
              placeholder="Please enter the reason"
              maxLength={255}
              value={alternativeReason}
              onChange={(event: any) => setAlternativeReason(event.target.value)}
            />
          </Form.Item>
        )}
      </Form>
    </Modal>
  )
}

export default RejectBillingModal
