import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { AxiosResponse } from "axios";
import moment from "moment";
import first from "lodash/first";
import cloneDeep from "lodash/cloneDeep";

import { requestHttp, urls } from "src/api";
import { FORMATS } from "src/constants";
import { UPLOAD_ENTITY_KEYS } from "src/constants/upload";
import { BRIEF_PAYMENT_STATUS, BRIEF_UPLOAD_TYPES } from "src/constants/brief";
import { getFileNameFromUrl } from "src/utils";
import { getResponseErrorMessage } from "src/helpers";
import { upload } from "src/modules/core/core.actions";
import IAction from "src/interfaces/IAction";
import IUploadFile from "src/interfaces/IUploadFile";

import {
  IBillingsListElement,
  IBillListElement,
  IBillListResponse,
  IBillPaymentUploadTypes,
  IBillTableParams,
  IBillingsPaymentUploadTypes,
  IPartnerRequestListResponse,
  IOnlinePaymentsListResponse,
  IOnlinePaymentsList,
} from "./bills.types";
import * as CONSTANTS from "./bills.constants";
import { IBriefRequestListResponse } from "../brief/brief.types";
import { IRequest } from "../partner/requests/request.types";
import { REQUEST_STATUS } from "../../constants/request";

export const billListRequest = (): IAction => ({
  type: CONSTANTS.FETCH_BILLS_REQUEST,
});

export const billListSuccess = (billList: {
  results: IBillListElement[];
  total: number;
}): IAction => ({
  type: CONSTANTS.FETCH_BILLS_SUCCESS,
  payload: { billList },
});

export const billListFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_BILLS_FAILURE,
  payload: { error },
});

export const requestListRequest = (): IAction => ({
  type: CONSTANTS.FETCH_REQUESTS_REQUEST,
});

export const requestListSuccess = (requestList: {
  results: IBillingsListElement[];
  total: number;
}): IAction => ({
  type: CONSTANTS.FETCH_REQUESTS_SUCCESS,
  payload: { requestList },
});

export const requestListFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_REQUESTS_FAILURE,
  payload: { error },
});

export const onlinePaymentsListRequest = (): IAction => ({
  type: CONSTANTS.FETCH_ONLINE_PAYMENTS_REQUEST,
});

export const onlinePaymentsListSuccess = (
  onlinePaymentsList: IOnlinePaymentsList
): IAction => ({
  type: CONSTANTS.FETCH_ONLINE_PAYMENTS_SUCCESS,
  payload: { onlinePaymentsList },
});

export const onlinePaymentsListFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_ONLINE_PAYMENTS_FAILURE,
  payload: { error },
});

export const setBillListParams = (params: IBillTableParams): IAction => ({
  type: CONSTANTS.SET_BILL_LIST_PARAMS,
  payload: { params },
});

export const setCurrentPage = (page: number): IAction => ({
  type: CONSTANTS.SET_CURRENT_PAGE,
  payload: { page },
});

export const fetchBillList = (
  params: IBillTableParams
): ThunkAction<
  Promise<AxiosResponse<IBillListResponse>>,
  {},
  {},
  AnyAction
> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse<IBillListResponse>> => {
  try {
    dispatch(billListRequest());
    const response = await requestHttp.get<IBillListResponse>(
      urls.getBillListUrl(),
      {
        params,
      }
    );
    const {
      content: { bills, total },
    } = response.data.data;

    const billList = bills.results.map<IBillListElement>((bill) => ({
      key: bill.payment.id,
      assignedUsers: bill.assignedUsers,
      version: bill?.version,
      brief: {
        name: bill.name,
        campaignName: bill.campaignName,
        image: first(bill.productImages) ?? null,
        version: bill.version,
      },
      payment: {
        ...bill.payment,
        po: {
          isUploading: false,
          uploadingError: "",
          isRemoving: false,
          removingError: "",
          files:
            bill.payment.po?.map((url) => ({
              name: getFileNameFromUrl(url),
              status: "done",
              url,
            })) ?? [],
        },
        bankTransfer: {
          isUploading: false,
          uploadingError: "",
          isRemoving: false,
          removingError: "",
          files:
            bill.payment.bankTransfer?.map((url) => ({
              name: getFileNameFromUrl(url),
              status: "done",
              url,
            })) ?? [],
        },
        createdAt: moment(bill.payment.createdAt).format(
          FORMATS.DATE_LL_FORMAT
        ),
        updatedAt: moment(bill.payment.createdAt).format(
          FORMATS.DATE_LL_FORMAT
        ),
        poEnabled: bill.owner.poEnabled,
      },
      isAccepting: false,
      acceptanceError: "",
      isRejecting: false,
      rejectionError: "",
    }));

    dispatch(billListSuccess({ results: billList, total: total }));
    return response;
  } catch (error) {
    dispatch(billListFailure(getResponseErrorMessage(error)));
    return error;
  }
};

export const fetchRequestList = (
  params: IBillTableParams,
  partnerId?: string | number
): ThunkAction<
  Promise<
    AxiosResponse<IPartnerRequestListResponse | IBriefRequestListResponse>
  >,
  {},
  {},
  AnyAction
> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<
  AxiosResponse<IPartnerRequestListResponse | IBriefRequestListResponse>
> => {
  try {
    dispatch(requestListRequest());

    const url = partnerId
      ? urls.getPartnerRequestsListUrl(partnerId)
      : urls.getAllRequestUrl();
    const response = await requestHttp.get<
      IPartnerRequestListResponse | IBriefRequestListResponse
    >(url, {
      params: { ...params, status: [REQUEST_STATUS.ACCEPTED] },
    });
    const { content } = response.data.data;

    // @ts-ignore
    const results: IRequest[] = content.requests? content.requests: content.results;
    const total = content.total;

    const billingsList = results.map<IBillingsListElement>((request) => ({
      ...request,
      key: request.id,
      lastApprovedDate: moment(request.lastApprovedDate).format(
        FORMATS.DATE_LL_FORMAT
      ),
      payment: request.payment
        ? {
            ...request.payment,
            bankTransfer: {
              isUploading: false,
              uploadingError: "",
              isRemoving: false,
              removingError: "",
              files: request.payment?.bankTransfer?.length
                ? request.payment.bankTransfer.map((url) => ({
                    name: getFileNameFromUrl(url),
                    status: "done",
                    url,
                  }))
                : [],
            },
            invoice: {
              isUploading: false,
              uploadingError: "",
              isRemoving: false,
              removingError: "",
              files: request.payment?.invoice?.length
                ? [
                    {
                      name: getFileNameFromUrl(request.payment.invoice[0]),
                      status: "done",
                      url: request.payment.invoice[0],
                    },
                  ]
                : [],
            },
          }
        : null,
    }));

    dispatch(requestListSuccess({ results: billingsList, total }));
    return response;
  } catch (error) {
    dispatch(requestListFailure(getResponseErrorMessage(error)));
    return error;
  }
};

export const fetchOnlinePaymentsList = (
  params: IBillTableParams
): ThunkAction<
  Promise<AxiosResponse<IOnlinePaymentsListResponse>>,
  {},
  {},
  AnyAction
> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse<IOnlinePaymentsListResponse>> => {
  try {
    dispatch(onlinePaymentsListRequest());
    const response = await requestHttp.get<IOnlinePaymentsListResponse>(
      urls.getOnlinePaymentsUrl(),
      {
        params,
      }
    );
    const {
      content: { payments },
    } = response.data.data;

    dispatch(onlinePaymentsListSuccess(payments));
    return response;
  } catch (error) {
    dispatch(onlinePaymentsListFailure(getResponseErrorMessage(error)));
    return error;
  }
};

export const setParamsAndFetch = (
  params: IBillTableParams,
  type: "requests" | "bills" | "onlinePayments" = "bills",
  partnerId?: string | number
): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  dispatch(setBillListParams(params));

  if (type === "bills") {
    dispatch(fetchBillList(params));
    return;
  }

  if (type === "requests") {
    dispatch(fetchRequestList(params, partnerId));
    return;
  }

  if (type === "onlinePayments") {
    dispatch(fetchOnlinePaymentsList(params));
    return;
  }
};

export const uploadPaymentFile = ({
  bill,
  files,
  filesType,
}: {
  bill: IBillListElement;
  files: File[];
  filesType: IBillPaymentUploadTypes;
}): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    dispatch(fileUploadRequest({ bill, filesType }));

    const briefId = bill.payment.briefId;
    const response = await upload(
      urls.getBriefUploadUrl(),
      files,
      briefId,
      BRIEF_UPLOAD_TYPES.BRIEFS_SERVICE_IMAGES,
      UPLOAD_ENTITY_KEYS.BRIEF_ID
    );

    if (response.status === 201) {
      const {
        content: remoteServerUrls,
      }: { content: string[] } = response.data.data;
      const newBill = cloneDeep(bill);
      newBill.payment[filesType].files = [
        ...newBill.payment[filesType].files,
        ...remoteServerUrls.map<IUploadFile>((url: string) => ({
          name: getFileNameFromUrl(url),
          status: "done",
          url,
        })),
      ];

      await requestHttp.put(urls.getEditBillUrl(), {
        id: newBill.payment.id,
        po: newBill.payment.po.files.map(({ url }) => url),
        bankTransfer: newBill.payment.bankTransfer.files.map(({ url }) => url),
      });

      dispatch(fileUploadSuccess({ bill: newBill, filesType }));
    }
  } catch (error) {
    dispatch(fileUploadFailure({ bill, filesType, error }));
  }
};

export const fileUploadRequest = ({
  bill,
  filesType,
}: {
  bill: IBillListElement;
  filesType: IBillPaymentUploadTypes;
}): IAction => ({
  type: CONSTANTS.FILE_UPLOAD_REQUEST,
  payload: { bill, filesType },
});

export const fileUploadSuccess = ({
  bill,
  filesType,
}: {
  bill: IBillListElement;
  filesType: IBillPaymentUploadTypes;
}): IAction => ({
  type: CONSTANTS.FILE_UPLOAD_SUCCESS,
  payload: { bill, filesType },
});

export const fileUploadFailure = ({
  bill,
  filesType,
  error,
}: {
  bill: IBillListElement;
  filesType: IBillPaymentUploadTypes;
  error: string;
}): IAction => ({
  type: CONSTANTS.FILE_UPLOAD_FAILURE,
  payload: { bill, filesType, error },
});

export const removePaymentFile = ({
  bill,
  filesType,
}: {
  bill: IBillListElement;
  filesType: IBillPaymentUploadTypes;
}): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    dispatch(fileRemoveRequest({ bill, filesType }));

    const data = {
      briefId: String(bill.payment.briefId),
      entity: BRIEF_UPLOAD_TYPES.BRIEFS_SERVICE_IMAGES,
      urls: bill.payment[filesType].files.map(({ url }) => url),
    };
    const response = await requestHttp.delete(urls.getBriefRemoveMediaUrl(), {
      data,
    });

    if (response.status === 201) {
      const newBill = cloneDeep(bill);
      newBill.payment[filesType].files = [];

      await requestHttp.put(urls.getEditBillUrl(), {
        id: newBill.payment.id,
        po: newBill.payment.po.files.map(({ url }) => url),
        bankTransfer: newBill.payment.bankTransfer.files.map(({ url }) => url),
      });

      dispatch(fileRemoveSuccess({ bill: newBill, filesType }));
    }
  } catch (error) {
    dispatch(fileRemoveFailure({ bill, filesType, error }));
  }
};

export const fileRemoveRequest = ({
  bill,
  filesType,
}: {
  bill: IBillListElement;
  filesType: IBillPaymentUploadTypes;
}): IAction => ({
  type: CONSTANTS.FILE_REMOVE_REQUEST,
  payload: { bill, filesType },
});

export const fileRemoveSuccess = ({
  bill,
  filesType,
}: {
  bill: IBillListElement;
  filesType: IBillPaymentUploadTypes;
}): IAction => ({
  type: CONSTANTS.FILE_REMOVE_SUCCESS,
  payload: { bill, filesType },
});

export const fileRemoveFailure = ({
  bill,
  filesType,
  error,
}: {
  bill: IBillListElement;
  filesType: IBillPaymentUploadTypes;
  error: string;
}): IAction => ({
  type: CONSTANTS.FILE_REMOVE_FAILURE,
  payload: { bill, filesType, error },
});

export const uploadPartnerPaymentFile = ({
  bill,
  files,
  filesType,
}: {
  bill: IBillingsListElement;
  files: File[];
  filesType: IBillingsPaymentUploadTypes;
}): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    dispatch(partnerFileUploadRequest({ bill, filesType }));

    const briefId = bill.briefId;
    const isPublicFile = false;
    const response = await upload(
      urls.getBriefUploadUrl(),
      files,
      briefId,
      BRIEF_UPLOAD_TYPES.BRIEFS_SERVICE_IMAGES,
      UPLOAD_ENTITY_KEYS.BRIEF_ID,
      isPublicFile
    );

    if (response.status === 201) {
      const {
        content: remoteServerUrls,
      }: { content: string[] } = response.data.data;
      const newBill = cloneDeep(bill);
      newBill.payment![filesType].files = [
        ...newBill.payment![filesType].files,
        ...remoteServerUrls.map<IUploadFile>((url: string) => ({
          name: getFileNameFromUrl(url),
          status: "done",
          url,
        })),
      ];

      await requestHttp.put(urls.getEditBillUrl(), {
        id: newBill.payment?.id,
        [filesType]: newBill.payment![filesType].files.map(({ url }) => url),
      });

      if (filesType === "bankTransfer") {
        await requestHttp.put(urls.getEditBillUrl(), {
          id: newBill.payment?.id,
          status: BRIEF_PAYMENT_STATUS.PAID,
        });
      }

      dispatch(partnerFileUploadSuccess({ bill: newBill, filesType }));
    }
  } catch (error) {
    dispatch(partnerFileUploadFailure({ bill, filesType, error }));
  }
};

export const partnerFileUploadRequest = ({
  bill,
  filesType,
}: {
  bill: IBillingsListElement;
  filesType: IBillingsPaymentUploadTypes;
}): IAction => ({
  type: CONSTANTS.PARTNER_FILE_UPLOAD_REQUEST,
  payload: { bill, filesType },
});

export const partnerFileUploadSuccess = ({
  bill,
  filesType,
}: {
  bill: IBillingsListElement;
  filesType: IBillingsPaymentUploadTypes;
}): IAction => ({
  type: CONSTANTS.PARTNER_FILE_UPLOAD_SUCCESS,
  payload: { bill, filesType },
});

export const partnerFileUploadFailure = ({
  bill,
  filesType,
  error,
}: {
  bill: IBillingsListElement;
  filesType: IBillingsPaymentUploadTypes;
  error: string;
}): IAction => ({
  type: CONSTANTS.PARTNER_FILE_UPLOAD_FAILURE,
  payload: { bill, filesType, error },
});

export const removePartnerPaymentFile = ({
  bill,
  file,
  filesType,
}: {
  bill: IBillingsListElement;
  file: IUploadFile;
  filesType: IBillingsPaymentUploadTypes;
}): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    dispatch(partnerFileRemoveRequest({ bill, filesType }));

    const data = {
      briefId: String(bill.briefId),
      entity: BRIEF_UPLOAD_TYPES.BRIEFS_SERVICE_IMAGES,
      urls: bill.payment![filesType].files.map(({ url }) => url),
    };
    const response = await requestHttp.delete(urls.getBriefRemoveMediaUrl(), {
      data,
    });

    if (response.status === 201) {
      const newBill = cloneDeep(bill);
      newBill.payment![filesType].files = newBill.payment![
        filesType
      ].files.filter((oldFile) => oldFile.url !== file.url);

      await requestHttp.put(urls.getEditBillUrl(), {
        id: newBill.payment!.id,
        [filesType]: newBill.payment![filesType].files.map(({ url }) => url),
      });

      if (filesType === "bankTransfer") {
        await requestHttp.put(urls.getEditBillUrl(), {
          id: newBill.payment?.id,
          status: BRIEF_PAYMENT_STATUS.PENDING,
        });
      }

      dispatch(partnerFileRemoveSuccess({ bill: newBill, filesType }));
    }
  } catch (error) {
    dispatch(partnerFileRemoveFailure({ bill, filesType, error }));
  }
};

export const partnerFileRemoveRequest = ({
  bill,
  filesType,
}: {
  bill: IBillingsListElement;
  filesType: IBillingsPaymentUploadTypes;
}): IAction => ({
  type: CONSTANTS.PARTNER_FILE_REMOVE_REQUEST,
  payload: { bill, filesType },
});

export const partnerFileRemoveSuccess = ({
  bill,
  filesType,
}: {
  bill: IBillingsListElement;
  filesType: IBillingsPaymentUploadTypes;
}): IAction => ({
  type: CONSTANTS.PARTNER_FILE_REMOVE_SUCCESS,
  payload: { bill, filesType },
});

export const partnerFileRemoveFailure = ({
  bill,
  filesType,
  error,
}: {
  bill: IBillingsListElement;
  filesType: IBillingsPaymentUploadTypes;
  error: string;
}): IAction => ({
  type: CONSTANTS.PARTNER_FILE_REMOVE_FAILURE,
  payload: { bill, filesType, error },
});

export const acceptBill = (
  bill: IBillListElement
): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    dispatch(billAcceptRequest(bill));

    const poUploaded = Boolean(bill.payment.po.files.length);
    const bankTransferUploaded = Boolean(
      bill.payment.bankTransfer.files.length
    );
    const acceptStatus =
      poUploaded && !bankTransferUploaded
        ? BRIEF_PAYMENT_STATUS.PO_ISSUED
        : BRIEF_PAYMENT_STATUS.PAID;

    const payload:any = {
      id: bill.payment.id,
      status: acceptStatus,
    }

    if(bill.version == "2"){
      payload["version"]="2";
    }
        
    await requestHttp.put(urls.getEditBillUrl(), payload);

    const newBill = cloneDeep(bill);
    newBill.payment.status = acceptStatus;

    dispatch(billAcceptSuccess(newBill));
  } catch (error) {
    dispatch(billAcceptFailure({ bill, error }));
  }
};

export const billAcceptRequest = (bill: IBillListElement): IAction => ({
  type: CONSTANTS.BILL_ACCEPT_REQUEST,
  payload: { bill },
});

export const billAcceptSuccess = (bill: IBillListElement): IAction => ({
  type: CONSTANTS.BILL_ACCEPT_SUCCESS,
  payload: { bill },
});

export const billAcceptFailure = ({
  bill,
  error,
}: {
  bill: IBillListElement;
  error: string;
}): IAction => ({
  type: CONSTANTS.BILL_ACCEPT_FAILURE,
  payload: { bill, error },
});

export const rejectBill = ({
  bill,
  reason,
}: {
  bill: IBillListElement;
  reason: string;
}): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    dispatch(billRejectRequest(bill));

    const REJECTED_STATUS = BRIEF_PAYMENT_STATUS.REJECTED;

    const payload:any = {
      id: bill.payment.id,
      status: REJECTED_STATUS,
      reason,
    }

    if(bill.version == "2"){
      payload["version"]="2";
    }

    await requestHttp.put(urls.getEditBillUrl(), payload);

    const newBill = cloneDeep(bill);
    newBill.payment.status = REJECTED_STATUS;
    newBill.payment.reason = reason;

    dispatch(billRejectSuccess(newBill));
  } catch (error) {
    dispatch(billRejectFailure({ bill, error }));
  }
};

export const billRejectRequest = (bill: IBillListElement): IAction => ({
  type: CONSTANTS.BILL_REJECT_REQUEST,
  payload: { bill },
});

export const billRejectSuccess = (bill: IBillListElement): IAction => ({
  type: CONSTANTS.BILL_REJECT_SUCCESS,
  payload: { bill },
});

export const billRejectFailure = ({
  bill,
  error,
}: {
  bill: IBillListElement;
  error: string;
}): IAction => ({
  type: CONSTANTS.BILL_REJECT_FAILURE,
  payload: { bill, error },
});

export const refundOnlinePayment = (
  onlinePaymentId: number,
  amount: number,
  version?:string
): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    dispatch(refundOnlinePaymentRequest());

    let payload:any = {
      onlinePaymentId,
      amount
    };

    if(version === "2"){
      payload["version"]="2";
    }

    await requestHttp.post(urls.getRefundUrl(), payload);

    dispatch(refundOnlinePaymentSuccess());
  } catch (error) {
    dispatch(refundOnlinePaymentFailure(error));
  }
};

export const refundOnlinePaymentRequest = (): IAction => ({
  type: CONSTANTS.REFUND_ONLINE_PAYMENT_REQUEST,
  payload: {},
});

export const refundOnlinePaymentSuccess = (): IAction => ({
  type: CONSTANTS.REFUND_ONLINE_PAYMENT_SUCCESS,
  payload: {},
});

export const refundOnlinePaymentFailure = (error: string): IAction => ({
  type: CONSTANTS.REFUND_ONLINE_PAYMENT_FAILURE,
  payload: { error },
});
