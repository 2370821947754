export enum REFERRAL_STATUS {
  IN_PROGRESS = 'In Progress',
  COMPLETED = 'Completed',
  ACTIVE = 'Active',
}

export const referralStatusesLabel = {
  [REFERRAL_STATUS.ACTIVE]: {
    classStyle: 'pending',
    label: REFERRAL_STATUS.ACTIVE,
  },
  [REFERRAL_STATUS.IN_PROGRESS]: {
    classStyle: 'running',
    label: REFERRAL_STATUS.IN_PROGRESS,
  },
  [REFERRAL_STATUS.COMPLETED]: {
    classStyle: 'approved',
    label: REFERRAL_STATUS.COMPLETED,
  },
}

export const REFERRAL_STATUSES = [REFERRAL_STATUS.IN_PROGRESS, REFERRAL_STATUS.ACTIVE, REFERRAL_STATUS.COMPLETED]
