import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Col, IconsCommon, QButton, Modal, Empty, Upload } from 'quantum_components'

import ClientAnalyticsDistCharts from './ClientAnalyticsDistCharts'
import { getScreenPagePdf, togglePrintClass } from 'src/utils/getScreenPage'
import { CAMPAIGN_PRINT_ANALYTICS_ID, CAMPAIGN_UPLOAD_TYPES } from 'src/constants/campaign'
import ClientAnalyticsMediaBuy from './ClientAnalyticsMediaBuy'
import ClientAnalyticsTimeline from './ClientAnalyticsTimeline'
import { getCampaignDetails } from 'src/modules/campaign/campaign.selectors'
import { useSelector } from 'react-redux'
import { BRIEF_TYPES } from 'src/constants/brief'
import { validateApplicationWithFileSize } from 'src/helpers/validation'
import DialogModal from 'src/components/DialogModal/DialogModal'
import { useReduxDispatch } from 'src/helpers'
import { actions } from 'src/modules/campaign'
import { COLORS } from '../../../../../constants/colors'
import { useParams } from 'react-router'
import { FORMATS } from 'src/constants'
import moment from 'moment'
import { ReactComponent as OpenIcon } from 'src/assets/icons/open-icon.svg'
import { PERMISSIONS } from 'src/constants/permission'
import { hasPermission } from 'src/helpers/permission'
import { getCurrentUserPermissionActions } from 'src/modules/core/core.selectors'

const checkIcon = <IconsCommon.IconCheck color={COLORS.LIGHT_GREEN} className="check_report_icon" />
const downloadIcon = <IconsCommon.IconDownload className="download_report_icon" />
const deleteIcon = <IconsCommon.IconDelete className="delete_report_icon" />

const ClientAnalytics = () => {
  const { i18n } = useTranslation()
  const { t } = useTranslation(['translationCampaign', 'translationCommon'])
  const { id } = useParams<{ id: any }>()
  const dispatch = useReduxDispatch()

  const campaignDetails = useSelector(getCampaignDetails)
  const permissions = useSelector(getCurrentUserPermissionActions)
  // const [generatingPdf, setGeneratingPdf] = useState<boolean>(false)
  const [finalizedUploads, setFinalizedUploads] = useState<File[]>([])
  const [uploads, setUploads] = useState<File[]>([])
  const [isModal, setIsModal] = useState<boolean>(false)
  const [deleteModal, setDeleteModal] = useState({
    visible: false,
    link: '',
  })
  const [uploadButtonLabel, setUploadButtonLabel] = useState({
    isDisable: false,
    text: t('details.Upload Report'),
  })

  useEffect(() => {
    if (!uploads.length) {
      return
    }

    const validatedFiles = uploads.filter((file: File) => {
      return validateApplicationWithFileSize(file)
    })

    if (!validatedFiles.length || uploads.length !== validatedFiles.length) {
      setUploads([])
      setFinalizedUploads([])
      return
    }

    setFinalizedUploads(validatedFiles)
    setIsModal(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploads])

  // const handleDownload = async () => {
  //   setGeneratingPdf(true)
  //   togglePrintClass(true)
  //   const markup = document.getElementById(CAMPAIGN_PRINT_ANALYTICS_ID)
  //   if (markup) {
  //     const pdf = await getScreenPagePdf(markup)
  //     pdf.save('Campaign Report.pdf')
  //   }

  //   setGeneratingPdf(false)
  //   togglePrintClass(false)
  // }

  const multipleUploadProps = {
    multiple: true,
    accept: '.pdf',
    showUploadList: false,
  }

  const openDeleteModal = (srcLink: string) => {
    setDeleteModal({
      visible: true,
      link: srcLink,
    })
  }

  const handleDeleteModalOkay = async () => {
    if (campaignDetails) {
      await dispatch(
        actions.deleteCampaignReport(
          [deleteModal.link],
          CAMPAIGN_UPLOAD_TYPES.CAMPAIGN_REPORT_FILES,
          campaignDetails?.brief.id
        )
      )
      await dispatch(actions.fetchCampaignDetails(id))
      setDeleteModal({
        visible: false,
        link: '',
      })
    }
  }

  const handleClickReport = (type: string, ele: any) => {
    type === 'view'
      ? window.open(ele.briefs_campaign_report_files, '_blank')
      : openDeleteModal(ele.briefs_campaign_report_files)
  }

  const dummyRequest = () => { }

  return (
    <React.Fragment>
      <Row gutter={20}>
        <Col xs={24} sm={16}>
          {campaignDetails?.brief?.briefTypes?.includes(BRIEF_TYPES.PRODUCT_SAMPLE) && (
            <div className={'mb-20'}>
              <ClientAnalyticsDistCharts />
            </div>
          )}
          {campaignDetails?.brief?.briefTypes?.includes(BRIEF_TYPES.MEDIA_BUYING) && (
            <div className={'mb-20'}>
              <ClientAnalyticsMediaBuy />
            </div>
          )}
        </Col>
        <Col xs={24} sm={8}>
          <div className="timeline">
            <div className="timeline__head">
              <h5 className="timeline__title">{t('details.reports')}</h5>
            </div>

            {campaignDetails &&
              campaignDetails?.brief?.campaignReportfilesUrls &&
              campaignDetails?.brief?.campaignReportfilesUrls.length > 0 ? (
              campaignDetails?.brief?.campaignReportfilesUrls.map((ele, index) => (
                <div className="reports_listing" key={ele.id} onClick={() => handleClickReport('view', ele)}>
                  {checkIcon}
                  <span>Report {index + 1}</span>
                  <div>
                    <a>
                      <OpenIcon className="open_report_icon" />
                    </a>
                    {/* <a href={ele.briefs_campaign_report_files} target="_blank" download>
                    {downloadIcon}
                  </a> */}
                    {hasPermission(permissions, PERMISSIONS.CAMPAIGN_EDIT_REPORTS) && <a
                      onClick={e => {
                        e.stopPropagation()
                        handleClickReport('delete', ele)
                      }}
                    >
                      {deleteIcon}
                    </a>}
                  </div>
                  <br />
                  <span className="date_span">Uploaded on {moment(ele.createdAt).format(FORMATS.DATE_LL_FORMAT)}</span>
                </div>
              ))
            ) : (
              <div style={{ marginBottom: '20px' }}>
                <Empty description={<span>No Data</span>} />
              </div>
            )}
          </div>
          <Upload
            className="uploadReport"
            {...multipleUploadProps}
            beforeUpload={(file, fileList) => {
              setUploads(fileList)
            }}
            customRequest={dummyRequest}
          >
            <QButton
              block
              className="qu-button-soft mt-20 justify-between"
              disabled={
                hasPermission(permissions, PERMISSIONS.CAMPAIGN_EDIT_REPORTS) ? uploadButtonLabel.isDisable : true
              }
            >
              {uploadButtonLabel.text} <IconsCommon.IconUpload />
            </QButton>
          </Upload>
          {campaignDetails?.brief?.briefTypes?.includes(BRIEF_TYPES.PRODUCT_SAMPLE) && <ClientAnalyticsTimeline />}
          {/* <QButton
            loading={generatingPdf}
            disabled={generatingPdf}
            onClick={handleDownload}
            block
            className="qu-button-soft mt-20 justify-between"
            data-html2canvas-ignore
          >
            Download Report <IconsCommon.IconDownload />
          </QButton> */}
        </Col>
      </Row>

      <DialogModal
        modalOpen={isModal}
        question={t('details.Upload Campaign Report')}
        description={t('details.All uploaded file(s) will be sent to client. Make sure that all information shared is correct.')}
        submitButtonText={t('common.Cancel', { ns: 'translationCommon' })}
        onClose={() => {
          setIsModal(false)
          setUploads([])
          setFinalizedUploads([])
        }}
        cancelButtonText={t('common.Confirm', { ns: 'translationCommon' })}
        onProceed={async () => {
          setIsModal(false)
          if (campaignDetails) {
            setUploadButtonLabel({
              isDisable: true,
              text: t('details.Uploading') + '...',
            })
            await dispatch(
              actions.uploadCampaignReports(
                finalizedUploads,
                CAMPAIGN_UPLOAD_TYPES.CAMPAIGN_REPORT_FILES,
                campaignDetails?.brief.id
              )
            )
            await dispatch(actions.fetchCampaignDetails(id))
            setUploadButtonLabel({
              isDisable: false,
              text: t('details.Upload Report'),
            })
            setUploads([])
            setFinalizedUploads([])
          }
        }}
        revert
      />

      <Modal
        visible={deleteModal.visible}
        onOk={() => handleDeleteModalOkay()}
        onCancel={() => setDeleteModal({ visible: false, link: '' })}
        okText={t('common.OKAY', { ns: 'translationCommon' })}
        cancelText={t('common.Cancel', { ns: 'translationCommon' })}
      >
        <p>{t('details.Do you want to delete this record?')}</p>
      </Modal>
    </React.Fragment>
  )
}

export default ClientAnalytics
