import { IState } from './referral.types'
import * as CONSTANTS from './referral.constants'

const initialState: IState = {
  referralListLoading: true,
  referralList: {
    results: [],
    total: 0,
  },
  pages: {
    totalPages: 1,
    currentPage: 1,
  },
  couponsLoading: false,
  coupons: [],
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case CONSTANTS.FETCH_REFERRALS_REQUEST:
      return {
        ...state,
        referralListLoading: true,
      }
    case CONSTANTS.FETCH_REFERRALS_SUCCESS:
      const referralsList = action.payload

      return {
        ...state,
        referralList: referralsList,
        referralListLoading: false,
      }
    case CONSTANTS.FETCH_REFERRALS_FAILURE:
      return {
        ...state,
        referralListLoading: false,
      }

    case CONSTANTS.SET_CURRENT_PAGE:
      return {
        ...state,
        pages: {
          ...state.pages,
          currentPage: action.payload.page,
        },
      }

    case CONSTANTS.FETCH_COUPONS_REQUEST:
      return { ...state, couponsLoading: true }
    case CONSTANTS.FETCH_COUPONS_SUCCESS:
      return { ...state, couponsLoading: false, coupons: action.payload }
    case CONSTANTS.FETCH_COUPONS_FAILURE:
      return { ...state, couponsLoading: false }
    default:
      return state
  }
}
