import React, { useEffect } from 'react'
import MainLayout from 'src/components/Layouts/MainLayout'
import AppRouter from '../../../router'
import { useSelector } from 'react-redux'
import { getProgress, getShowProgress } from '../core.selectors'
import { QProgress } from 'quantum_components'
import { useTranslation } from 'react-i18next'

const App = () => {
  const progress = useSelector(getProgress)
  const showProgress = useSelector(getShowProgress)
  const {i18n} = useTranslation()

  useEffect(()=>{
    document.dir = i18n.dir()
  },[i18n,i18n.language])

  return (
    <React.Fragment>
      {showProgress && (
        <div className="progress_container">
          <QProgress strokeColor="#56c0fd" percent={progress} showInfo={false} />
        </div>
      )}
      <AppRouter>{(content: any, routeProps: any) => <MainLayout {...routeProps}>{content}</MainLayout>}</AppRouter>
    </React.Fragment>
  )
}

export default App
