import React, { useEffect, useState } from "react";
import { QButton, QInput, QSelect } from "quantum_components";
import { BRIEF_UPLOAD_TYPES, MEDIA_TYPES } from "src/constants/brief";
import lodash from "lodash";
import { getFileNameFromUrl } from "src/utils";
import UploadComponent from "src/components/UploadComponent";
import { upload } from "src/modules/core/core.actions";
import { getMediaTypesUploadUrl } from "src/api/urls";
import { useSelector } from "react-redux";
import { getCurrentUser } from "src/modules/core/core.selectors";
import { PARTNER_UPLOAD_TYPES } from "src/constants/partner";
import { UPLOAD_ENTITY_KEYS } from "src/constants/upload";
import { useTranslation } from "react-i18next";

const ServiceV2Upload = ({ service, setService, setIsLoading }: any) => {
  const {t} = useTranslation(["translationServiceV2","translation"]);
    const {partnerProfileId} = useSelector(getCurrentUser)
  const [localFiles, setLocalFiles] = useState<any>([]);

  const filesMapper = () => {
    const list = lodash.isEmpty(service.previewImage)
      ? []
      : [
          {
            uid: 777 + "",
            name: getFileNameFromUrl(service.previewImage),
            status: "done",
            url: service.previewImage,
          },
        ];
    setLocalFiles(list);
  };

  useEffect(() => {
    filesMapper();
  }, [service]);

  const handleUpload = async (uploads: File[]) => {
    setIsLoading(true)
    const resp = await upload(getMediaTypesUploadUrl(),uploads,+partnerProfileId,PARTNER_UPLOAD_TYPES.PARTNER_PROFILE_IMAGES,UPLOAD_ENTITY_KEYS.PARTNER_ID,true,"2");
    const urls = lodash.get(resp,"data.data.content");
    if(urls?.length > 0){
        setService({...service,previewImage:urls[0]});
    }
    setIsLoading(false)
  };

  const handleRemove = () => {
    setService({...service,previewImage:null})
  };

  return (
    <>
      <UploadComponent
        files={localFiles}
        onChange={handleUpload}
        onRemove={handleRemove}
        heading={t("Upload Image")}
      />
    </>
  );
};

export default ServiceV2Upload;
