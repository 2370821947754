import { createSelector } from 'reselect'
import { IState, IBriefListElement, IBriefTableParams, IBrief } from './draftBriefs.types'
import { MODULE_NAME } from './draftBriefs.constants'
import { SERVICE_NAMES_FOR_TABLE } from '../../../constants/brief'

const selectState = (state: { [MODULE_NAME]: IState }): IState => state[MODULE_NAME]

export const getBriefListLoading = createSelector(selectState, (state: IState): boolean => state.briefListLoading)

export const getBrief = createSelector(selectState, (state: IState): IBrief => state.brief)

export const getBriefList = createSelector(selectState, (state: IState): IBriefListElement[] => state.briefList.results)

export const getCardList = createSelector(selectState, (state: IState): any[] =>
  state.briefList.results.map(el => ({
    ...el,
    services: el.services?.map(service => ({
      title: service.title,
      startDate: service.title === SERVICE_NAMES_FOR_TABLE.PRODUCT ? service.productStartDate : service.mediaStartDate,
      duration: service.title === SERVICE_NAMES_FOR_TABLE.PRODUCT ? service.productDuration : service.mediaDuration,
      quantity: service.quantity && service.quantity,
    })),
    discountApplied: el.product.discountText,
  }))
)

export const getTotalBriefList = createSelector(selectState, (state: IState): number => state.briefList.total)

export const getBriefParams = createSelector(selectState, (state: IState): IBriefTableParams => state.params)

export const getPages = createSelector(
  selectState,
  (
    state: IState
  ): {
    totalPages: number
    currentPage: number
  } => state.pages
)
