import { IState, IServerError } from './core.types'
import * as CONSTANTS from './core.constants'
import IAction from 'src/interfaces/IAction'
import get from 'lodash/get'
import { CURRENCY } from '../../constants/currencies'

export const initialState: IState = {
  isAuthenticated: false,
  showProgress: false,
  progress: 0,
  isLoggingOut: false,
  isLoggingIn: false,
  isUploading: false,
  isDownloading: false,
  settingsLoading: false,
  constantsLoading: false,
  fileUrls: [],
  uploadError: '',
  downloadError: '',
  fetchCurrenciesError: '',
  serverError: null,
  constants: {},
  settings: null,
  currentUser: {
    id: null,
    firstName: '',
    lastName: '',
    companyName: '',
    companyWebsite: '',
    phone: '',
    email: '',
    role: '',
    roleId: null,
    partnerProfileId: '',
    partnerUserId: '',
    status: '',
    isBanned: '',
    profilePicture: '',
    partnerLogo: '',
    isApproved: false,
    workflowEnabled: false,
    completion: { public: 0, partner: 0, client: 0 },
    permissions: [],
    unreadNotificationCount: 0,
    partnerData: {
      bankDetails: {},
      logo: '',
      name: '',
      taxTreatment: '',
      vat: ''
    },
    version: ''
  },
  activeMenu: null,
  currentUserPermissions: [],
  currency: CURRENCY.SAR,
  currencyError: '',
  customNotification: null,
  isLoading: false,
  overduePayment: null,
  isPaymentLoading: false
}

export default (state: IState = initialState, action: IAction): IState => {
  switch (action.type) {
    case CONSTANTS.RESET_PROGRESS:
      return { ...state, showProgress: initialState.showProgress, progress: initialState.progress }
    case CONSTANTS.SET_PROGRESS:
      return { ...state, showProgress: true, progress: get(action, 'payload.progress') }

    case CONSTANTS.LOGOUT_REQUEST:
      return { ...state, isLoggingOut: true }
    case CONSTANTS.LOGOUT_SUCCESS:
      return initialState
    case CONSTANTS.LOGOUT_FAILURE:
      return { ...state, isLoggingOut: false }

    case CONSTANTS.SET_SERVER_ERROR:
      return { ...state, serverError: action.payload as IServerError }
    case CONSTANTS.RESET_SERVER_ERROR:
      return { ...state, serverError: initialState.serverError }

    case CONSTANTS.UPLOAD_REQUEST:
      return { ...state, isUploading: true }
    case CONSTANTS.UPLOAD_SUCCESS:
      return { ...state, isUploading: false, fileUrls: [...state.fileUrls, ...get(action, 'payload.fileUrls')] }
    case CONSTANTS.UPLOAD_FAILURE:
      return { ...state, isUploading: false, uploadError: action.error }

    case CONSTANTS.DOWNLOAD_REQUEST:
      return { ...state, isDownloading: true }
    case CONSTANTS.DOWNLOAD_SUCCESS:
      return { ...state, isDownloading: false }
    case CONSTANTS.DOWNLOAD_FAILURE:
      return { ...state, isDownloading: false, downloadError: action.error }

    case CONSTANTS.FETCH_CURRENCIES_SUCCESS:
      return { ...state, currency: get(action, 'payload.currency') }
    case CONSTANTS.FETCH_CURRENCIES_FAILURE:
      return { ...state, currencyError: action.error }

    case CONSTANTS.GET_CURRENT_USER_SUCCESS:
      return { ...state, currentUser: { ...state.currentUser, ...get(action, 'payload.user') }, currentUserPermissions: get(action, 'payload.permissions') }
    case CONSTANTS.GET_CURRENT_USER_FAILURE:
      return { ...state, currentUser: initialState.currentUser }

    case CONSTANTS.SET_ACTIVE_MENU:
      return { ...state, activeMenu: get(action, 'payload.key') }
    case CONSTANTS.CURRENT_USER_PERMISSION_SUCCESS:
      return { ...state, currentUserPermissions: get(action, 'payload.permissions') }
    case CONSTANTS.CURRENT_USER_PERMISSION_FAILURE:
      return { ...state, currentUserPermissions: initialState.currentUserPermissions }

    case CONSTANTS.CONSTANTS_REQUEST:
      return { ...state, constantsLoading: true }
    case CONSTANTS.CONSTANTS_SUCCESS:
      return {
        ...state,
        constants: get(action, 'payload.constants'),
        constantsLoading: false,
      }
    case CONSTANTS.CONSTANTS_FAILURE:
      return { ...state, constantsLoading: false }

    case CONSTANTS.SETTINGS_REQUEST:
      return { ...state, settingsLoading: true }
    case CONSTANTS.SETTINGS_SUCCESS:
      return {
        ...state,
        settings: get(action, 'payload.settings'),
        settingsLoading: false,
      }
    case CONSTANTS.SETTINGS_FAILURE:
      return { ...state, settingsLoading: false }

    case CONSTANTS.GET_UNREAD_CUSTOM_NOTIFICATION_REQUEST:
      return { ...state, isLoading: true }
    case CONSTANTS.GET_UNREAD_CUSTOM_NOTIFICATION_SUCCESS:
      return { ...state, customNotification: get(action, 'payload.customNotification'), isLoading: false }
    case CONSTANTS.GET_UNREAD_CUSTOM_NOTIFICATION_FAILURE:
      return { ...state, customNotification: initialState.customNotification, isLoading: false }

    case CONSTANTS.GET_SUBSCRIPTION_OVERDUE_PAYMENT_REQUEST:
      return { ...state, isPaymentLoading: true }
    case CONSTANTS.GET_SUBSCRIPTION_OVERDUE_PAYMENT_SUCCESS:
      return { ...state, overduePayment: get(action, 'payload.overduePayment'), isPaymentLoading: false }
    case CONSTANTS.GET_SUBSCRIPTION_OVERDUE_PAYMENT_FAILURE:
      return { ...state, overduePayment: initialState.overduePayment, isPaymentLoading: false }

    case CONSTANTS.PAY_SUBSCRIPTION_PAYMENT_REQUEST:
      return { ...state, isPaymentLoading: true }
    case CONSTANTS.PAY_SUBSCRIPTION_PAYMENT_SUCCESS:
      return { ...state, isPaymentLoading: false }
    case CONSTANTS.PAY_SUBSCRIPTION_PAYMENT_FAILURE:
      return { ...state, isPaymentLoading: false }

    default:
      return state
  }
}
