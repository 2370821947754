import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { AxiosResponse } from 'axios'
import IAction, { TName } from 'src/interfaces/IAction'

import * as CONSTANTS from './workflow.constants'
import { requestHttp, urls } from 'src/api'
import { getResponseErrorMessage } from 'src/helpers'

export const workflowRequest = (): IAction => ({
  type: CONSTANTS.WORKFLOW_REQUEST,
})

export const workflowEndRequest = (): IAction => ({
  type: CONSTANTS.WORKFLOW_END_REQUEST,
})

export const workflowSuccess = (data: any): IAction => ({
  type: CONSTANTS.WORKFLOW_SUCCESS,
  payload: data,
})

export const workflowDetailsSuccess = (data: any): IAction => ({
  type: CONSTANTS.WORKFLOW_DETAILS_SUCCESS,
  payload: data,
})


export const workflowFailure = (error: string): IAction => ({
  type: CONSTANTS.WORKFLOW_FAILURE,
  error,
})

export const createWorkflow = (workflow:any): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse> => {
  try {
    dispatch(workflowRequest())
    const response = await requestHttp.post(urls.createWorkflowUrl(),workflow)
    const { content } = response.data.data;
    dispatch(workflowEndRequest())
    return content;
  } catch (error) {
    dispatch(workflowFailure(getResponseErrorMessage(error)))
    return error
  }
}


export const getAllWorkflows = (): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse> => {
  try {
    dispatch(workflowRequest())
    const response = await requestHttp.get(urls.listWorkflowUrl())
    const { content } = response.data.data;
    dispatch(workflowSuccess(content))
    return content;
  } catch (error) {
    dispatch(workflowFailure(getResponseErrorMessage(error)))
    return error
  }
}

export const deleteWorkflow = (workflowId:number): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse> => {
  try {
    dispatch(workflowRequest())
    const response = await requestHttp.delete(urls.deleteWorkflowUrl(workflowId))
    const { content } = response.data.data;
    dispatch(workflowEndRequest())
    return content;
  } catch (error) {
    dispatch(workflowFailure(getResponseErrorMessage(error)))
    return error
  }
}
