export enum SURVEY_CHART_TYPE {
  VERTICAL = 'Vertical Bar Chart',
  HORIZONTAL = 'Horizontal Bar Chart',
  PIE = 'Pie Chart',
}

export const SURVEY_CHART_TYPE_LIST = [SURVEY_CHART_TYPE.VERTICAL, SURVEY_CHART_TYPE.HORIZONTAL, SURVEY_CHART_TYPE.PIE]

export const SURVEY_MAX_LEN = 6
export const SURVEY_MAX_QUESTION_LEN = 50
export const SURVEY_MAX_ANSWER_LEN = 10
export const PARTNER_ALL = 'All Partners'
export const CLIENT_SURVEY_PER_PAGE = 9
