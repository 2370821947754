import get from 'lodash/get'
import { IState, IAgeGroup } from './ageGroups.types'
import * as CONSTANTS from './ageGroups.constants'

const initialState: IState = {
  ageGroupsListLoading: true,
  ageGroupsUpdating: false,
  imageUploading: false,
  imageRemoving: false,
  ageGroupsList: [],
  ageGroupImage: '',
  createGroupError: '',
  editGroupError: '',
  deleteGroupError: '',
  imageUploadError: '',
  imageRemoveError: '',
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case CONSTANTS.FETCH_AGE_GROUPS_REQUEST:
      return {
        ...state,
        ageGroupsListLoading: true,
      }
    case CONSTANTS.FETCH_AGE_GROUPS_SUCCESS:
      const ageGroupsList = action.payload
      return {
        ...state,
        ageGroupsList,
        ageGroupsListLoading: false,
      }
    case CONSTANTS.FETCH_AGE_GROUPS_FAILURE:
      return {
        ...state,
        ageGroupsListLoading: false,
      }
    case CONSTANTS.CREATE_AGE_GROUP_REQUEST:
      return {
        ...state,
        ageGroupsUpdating: true,
        createGroupError: initialState.createGroupError,
      }
    case CONSTANTS.CREATE_AGE_GROUP_SUCCESS:
      return { ...state, ageGroupsUpdating: false }
    case CONSTANTS.CREATE_AGE_GROUP_FAILURE:
      return {
        ...state,
        ageGroupsUpdating: false,
        createGroupError: action.error,
      }
    case CONSTANTS.EDIT_AGE_GROUP_REQUEST:
      return {
        ...state,
        ageGroupsUpdating: true,
        editGroupError: initialState.editGroupError,
      }
    case CONSTANTS.EDIT_AGE_GROUP_SUCCESS:
      return { ...state, ageGroupsUpdating: false }
    case CONSTANTS.EDIT_AGE_GROUP_FAILURE:
      return {
        ...state,
        ageGroupsUpdating: false,
        editGroupError: action.error,
      }
    case CONSTANTS.DELETE_AGE_GROUP_REQUEST:
      return {
        ...state,
        ageGroupsUpdating: true,
        deleteGroupError: initialState.deleteGroupError,
      }
    case CONSTANTS.DELETE_AGE_GROUP_SUCCESS:
      return { ...state, ageGroupsUpdating: false }
    case CONSTANTS.DELETE_AGE_GROUP_FAILURE:
      return {
        ...state,
        ageGroupsUpdating: false,
        deleteGroupError: action.error,
      }
    case CONSTANTS.IMAGE_UPLOAD_REQUEST:
      return {
        ...state,
        imageUploading: true,
        imageUploadError: initialState.imageUploadError,
      }
    case CONSTANTS.IMAGE_UPLOAD_SUCCESS:
      return {
        ...state,
        ageGroupImage: get(action, 'payload.src'),
        imageUploading: false,
      }
    case CONSTANTS.IMAGE_UPLOAD_FAILURE:
      return {
        ...state,
        imageUploading: false,
        imageUploadError: action.error,
      }
    case CONSTANTS.IMAGE_REMOVE_REQUEST:
      return {
        ...state,
        imageRemoving: true,
        imageRemoveError: initialState.imageRemoveError,
      }
    case CONSTANTS.IMAGE_REMOVE_SUCCESS:
      return {
        ...state,
        ageGroupImage: '',
        imageRemoving: false,
      }
    case CONSTANTS.IMAGE_REMOVE_FAILURE:
      return {
        ...state,
        imageRemoving: false,
        imageRemoveError: action.error,
      }
    default:
      return state
  }
}
