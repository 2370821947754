export const MODULE_NAME = 'workflow'

/* actions */

export const WORKFLOW_REQUEST = `${MODULE_NAME}/WORKFLOW_REQUEST`
export const WORKFLOW_END_REQUEST = `${MODULE_NAME}/WORKFLOW_END_REQUEST`
export const WORKFLOW_SUCCESS = `${MODULE_NAME}/WORKFLOW_SUCCESS`
export const WORKFLOW_DETAILS_SUCCESS = `${MODULE_NAME}/DETAILS_`
export const WORKFLOW_FAILURE = `${MODULE_NAME}/WORKFLOW_FAILURE`

export const DEPARTMENT_LIST = [
    'Marketing',
    'Operations',
    'Sales',
    'Design',
    'IT',
    'Management',
    'Finance'
]