import React, { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { SearchOutlined } from '@ant-design/icons'
import {
  Col,
  IconsCommon,
  Modal,
  QButton,
  QInput,
  qnotification,
  QPagination,
  QSelect,
  QTable,
  Row,
} from 'quantum_components'
import EmailProposalForm from '../../../components/BriefView/EmailProposalForm.component'
import { useReduxDispatch } from 'src/helpers'
import { useSelector } from 'react-redux'
import { getCurrentUser } from 'src/modules/core/core.selectors'
import NOTIFICATION_TYPES from '../../../constants/notificationTypes'
import { actions } from '../invitingClient.module'
import {
  getInvitedClients,
  getPages,
  getRequestParams,
  getTotalRecordsCount,
  getInvitedClientsListLoading,
} from '../invitingClient.selectors'
import moment from 'moment'
import useDebounce from 'src/hooks/useDebounce'
import { INVITED_CLIENTS_STATUS } from 'src/constants/partner'
import { INVITED_CLIENTS_LABEL } from 'src/constants/partner'
import { t } from 'i18next'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'


const InvitingClientContainer = () => {
  const history = useHistory();

  useEffect(()=>{return history.push("/")},[])
  const { t }= useTranslation(['translationInviteClients'])
  const dispatch = useReduxDispatch()
  const pages = useSelector(getPages)
  const params = useSelector(getRequestParams)
  const getTotalCount = useSelector(getTotalRecordsCount)
  const tableLoading = useSelector(getInvitedClientsListLoading)

  const [modalVisible, setModalVisible] = useState(false)
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 500)

  const currentUser: any = useSelector(getCurrentUser)
  const invitedClients = useSelector(getInvitedClients)

  useEffect(() => {
    dispatch(actions.setParamsAndFetch({ ...params, search: debouncedSearch, offset: 0 }))
    dispatch(actions.setCurrentPage(1))
  }, [debouncedSearch])

  const handleChangeSearch = (e: any) => {
    setSearch(e.currentTarget.value)
  }

  const handleChangePage = async (page: number, pageSize: number) => {
    await dispatch(actions.setCurrentPage(page))
    await dispatch(actions.setParamsAndFetch({ ...params, offset: (page - 1) * pageSize, limit: pageSize }))
  }

  const onToggleModal = () => {
    setModalVisible(!modalVisible)
  }

  const onInviteClientFinish = async (data: any) => {
    let inviteClientData = {
      email: data.emails,
      message: data.message,
      cc_me: data.cc,
    }
    let response: any = await dispatch(actions.inviteClient(inviteClientData))
    if (response == 201) {
      setModalVisible(!modalVisible)
      dispatch(actions.setParamsAndFetch({ ...params }))
    }
  }

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    const sortingParams = {
      direction: sorter.order.slice(0, -3),
      sortField: colKey[sorter.columnKey],
    }
    const newParams = {
      ...params,
      ...sortingParams,
    }
    dispatch(actions.setParamsAndFetch(newParams))
  }

  const handleChangeSelect = async (status: string[]) => {
    await dispatch(actions.setParamsAndFetch({ ...params, offset: 0, status: status }))
  }

  const copyCodeToClipboard = () => {
    var input: any = document.body.appendChild(document.createElement('input'))
    input.value = currentUser.inviteClientLink
    input.focus()
    input.select()
    document.execCommand('copy')
    input.parentNode.removeChild(input)
    qnotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      message: 'Success',
      description: 'Link copied successfully!!',
    })
  }

  const statusFilterOptions = [
    {
      label: t('Pending'),
      value: 'Pending',
    },
    {
      label: t('Joined'),
      value: 'JOINED',
    },
    {
      label: t('Existing User'),
      value: 'EXISTING_USER',
    },
  ]

  const colKey: any = {
    email: 'email',
    updated_at: 'updated_at',
  }

  const columns = [
    {
      title: t('Email'),
      dataIndex: 'email',
      key: 'email',
      width: '50%',
      sorter: true,
    },
    {
      title: t('Invited Date'),
      dataIndex: 'updated_at',
      key: 'updated_at',
      width: '30%',
      sorter: true,
      render: (updated_at: any) => updated_at && <span>{moment(updated_at).format('MMM Do YYYY, h:mm a')}</span>,
    },
    {
      title: t('Status'),
      dataIndex: 'status',
      key: 'status',
      width: '20%',
      render: (status: string) => (
        <div>
          <span className={`status-label ${status == INVITED_CLIENTS_STATUS.PENDING && 'pending'}
                                         ${status == INVITED_CLIENTS_STATUS.JOINED && 'accepted'}`}
                                         >
              
              {t(INVITED_CLIENTS_LABEL[status])}
                                         
          </span>
        </div>
      ),
    },
  ]

  return (
    <div className="dashboard-layout">
      <div className="qt-header">
        <div className="qu-statistic-wrapper" style={{ marginBottom: 'unset' }}>
          <h1>{t('Invite Clients')}</h1>
        </div>
        <QButton onClick={onToggleModal} className="qu-button-soft" style={{ float: 'right' }}>
          {t('Invite a Client')}
        </QButton>

        <Modal
          wrapClassName="qu-modal"
          closeIcon={<IconsCommon.IconClose />}
          visible={modalVisible}
          onCancel={onToggleModal}
          width={450}
          title={<h3>{t('Invite a client to submit a brief to you')}</h3>}
          footer={null}
        >
          <EmailProposalForm isInviteClient={true} onSend={onInviteClientFinish} />
          <div className="ant-modal-header pl-0 pr-0" >
            <div className="ant-modal-title">
              <h3>{t('Share Link')}</h3>
            </div>
          </div>
          <Row justify="space-between" align="middle" className="pt-10">
            <Col md={17}>
              <span style={{ fontSize: '15px' }}>{currentUser?.inviteClientLink}</span>
              <br />
              <small>{t('Anyone on the internet with this link can submit a brief to you')}</small>
            </Col>
            <Col md={7} style={{ textAlign: 'center' }}>
              <a onClick={copyCodeToClipboard} style={{ fontSize: '15px' }}>
                <strong>{t('Copy Link')}</strong>
              </a>
            </Col>
          </Row>
        </Modal>
      </div>

      <Row className="qt-header qt-header-campaigns" justify="space-between">
        <Col className="qt-search">
          <QInput
            className={`${isMobile && 'full-width'}`}
            value={search}
            onChange={handleChangeSearch}
            placeholder={t('Search')}
            size="large"
            prefix={<SearchOutlined />}
          />
        </Col>
        <Col className="qt-filter">
          <QSelect
            showArrow
            maxTagTextLength={8}
            maxTagCount={2}
            onChange={handleChangeSelect}
            mode="multiple"
            size="large"
            options={statusFilterOptions}
            placeholder={t('Filter by Status')}
          />
        </Col>
      </Row>

      <div className="qt-body">
        <QTable
          //@ts-ignore
          loading={tableLoading}
          //@ts-ignore
          onChange={handleTableChange}
          sortDirections={['ascend', 'descend', 'ascend']}
          columns={columns}
          dataSource={invitedClients}
          rowClassName="q-table"
          className="cursorPointer"
        />
      </div>
      <div className="qt-footer">
        <QPagination
          size="small"
          defaultCurrent={1}
          current={pages.currentPage}
          total={getTotalCount}
          onChange={handleChangePage}
          showSizeChanger
        />
      </div>
    </div>
  )
}

export default InvitingClientContainer
