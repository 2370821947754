import React from 'react'
import { Row, Col } from 'antd'
import { IBriefResponse } from '../../brief.types'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { getFirstProductImage } from '../../brief.selectors'
import { useTranslation } from 'react-i18next'

interface Props {
  briefView: IBriefResponse
  onEdit: () => void
  viewMode?: boolean
}

const BriefProduct = ({ briefView, onEdit, viewMode }: Props) => {
  const {t} = useTranslation(['translationABrief'])
  const firstImage = useSelector(getFirstProductImage)

  return (
    <div className="qu-view-card m-10">
      <div className="qu-card-header">
        <h4>{t('product_brief')}</h4>
        {!viewMode && <span onClick={onEdit}>{t('edit')}</span>}
      </div>
      <div className="product-brief">
        <div className="qu-card-item-image mb-25">
          <Row>
            <div className="image pr-10">
              <img src={firstImage} alt="product" />
            </div>
            <div className="description">
              <h5>{briefView.name}</h5>
              <p>{briefView.productType}</p>
            </div>
          </Row>
        </div>
        <Row gutter={16}>
          <Col span={12}>
            <div className="data-view mb-15">
              <div className="data-view-label">{t('format')}</div>
              <div className="data-view-value">{briefView.distributionItem}</div>
            </div>
          </Col>
          <Col span={12}>
            <div className="data-view mb-15">
              <div className="data-view-label">{t('qty')}</div>
              <div className="data-view-value">{briefView.quantity}</div>
            </div>
          </Col>
          <Col span={12}>
            <div className="data-view mb-15">
              <div className="data-view-label">{t('desired_start_date')}</div>
              <div className="data-view-value">
                {' '}
                {briefView.briefStartDate ? moment(briefView.briefStartDate).format('LL') : t('none')}{' '}
              </div>
            </div>
          </Col>
          <Col span={12}>
            <div className="data-view mb-15">
              <div className="data-view-label">{t('duration')}</div>
              <div className="data-view-value">{briefView.duration || t('none')}</div>
            </div>
          </Col>
          <Col span={24}>
            <div className="data-view mb-15">
              <div className="data-view-label">{t('instructions')}</div>
              <div className="data-view-value">{briefView.instructions || t('none')} </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default BriefProduct
