import React, { useEffect, useState } from "react";
import {
  Dropdown,
  IconsCommon,
  Menu,
  QButton,
  QMenuItem,
  QTable,
  Switch,
} from "quantum_components";
import i18n from "src/i18n";
import { FORMATS, LOCALIZATION_LANGUAGES } from "src/constants";
import moment from "moment";
import {
  CloseOutlined,
  CheckOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import DialogModal from "src/components/DialogModal/DialogModal";
import { deleteServicesV2 } from "../servicesV2.actions";
import ServiceV2Tags from "./ServiceV2Tags";
import { BANNERS_BY_MEDIA_TYPE } from "src/constants/brief";
import { useTranslation } from "react-i18next";

const ServiceV2Table = ({
  servicesDetails,
  setEditService,
  refreshParent,
  setIsLoading,
}: any) => {
  const {t} = useTranslation(["translationServiceV2","translation"]);
  const [showDel, setShowDel] = useState(false);
  const [delId, setDelId] = useState(-1);

  const serviceTypes = Object.keys(BANNERS_BY_MEDIA_TYPE).map((key) => {
    return {
      key: key,
      value: BANNERS_BY_MEDIA_TYPE[key].description,
      image: BANNERS_BY_MEDIA_TYPE[key].image,
    };
  });

  useEffect(() => {
    if (delId < 0) return;
    setShowDel(true);
  }, [delId]);

  const menu = (service: any) => (
    <Menu
      className={`user-menu-list light-grey-disabled ${
        i18n.language === LOCALIZATION_LANGUAGES.ARABIC ? "html-rtl" : ""
      }`}
    >
      <QMenuItem key={1} onClick={() => setEditService(service)} name={t("Edit")} />
      <QMenuItem key={2} onClick={() => setDelId(service.id)} name={t("Delete")} />
    </Menu>
  );

  const columns = [
    {
      title: t("Preview"),
      dataIndex: "previewImage",
      key: "previewImage",
      width: "10%",
      align:"center",
      fixed:"left",
      render: (element: any, row: any) => {
        const image = serviceTypes.find(st=>st.key === row?.name)?.image
        const custom = serviceTypes.find(st=>st.key === "Custom Media Service")?.image;
        return (
          <img
            src={image ?? custom}
            className="h-50 full-width table-preview-img"
          />
        );
      },
    },
    {
      title: t("Type"),
      dataIndex: "name",
      key: "name",
      width: "15%",
      render: (name: any, row: any) => {
        return (
          <>
            <div className="m-0">{t(`services.media-buy.types.${name}`,name,{ns:"translation"})}</div>
            {row?.createdAt && (
              <div>
                <small>
                  {moment(row?.createdAt).format(FORMATS.DATE_LL_FORMAT)}
                </small>
              </div>
            )}
          </>
        );
      },
    },
    {
      title: t("Customized Name"),
      dataIndex: "subName",
      key: "subName",
      width: "35%",
      render: (element: any, row: any) => {
        return (
          <>
            <div className="m-0">{`${row.subName || row.name}`}</div>
            {row.description && <small>{row.description}</small>}
          </>
        );
      },
    },
    {
      title: t("Dimensions"),
      dataIndex: "null",
      key: "null",
      render: (element: any, row: any) => {
        const dimensions =
          row.width && row.height ? `${row.width}w X ${row.height}h` : t("Dynamic");
        return <p className="m-0">{`${dimensions}`}</p>;
      },
    },
    {
      title: t("Price"),
      dataIndex: "null",
      key: "null",
      render: (element: any, row: any) => {
        const price = Number(
          row?.pricePerExecution
        );
        let execType = row?.executionType;
        return (
          <>
            {execType !== "Per Metric" && <div className="m-0">{price}&nbsp;SAR</div>}
            {execType === "Per Metric" && <div className="m-0">{t("CPC")}: {row?.pricePerMetric?.cpc}&nbsp;SAR</div>} 
            {execType === "Per Metric" && <div className="m-0">{t("CPM")}: {row?.pricePerMetric?.cpm}&nbsp;SAR</div>} 
            <div>
              <small>{t(execType)}</small>
            </div>
          </>
        );
      },
    },
    {
      title: t("Visible"),
      dataIndex: "isHide",
      key: "isHide",
      render: (element: any, row: any) => {
        return (
          <>
            <div>
              {element == false && (
                <span>
                  <CheckOutlined
                    title={t("Service visible to advertisers")}
                    style={{ color: "green" }}
                  />
                </span>
              )}
              {element == true && (
                <span>
                  <CloseOutlined
                    title={t("Service hidden from advertisers")}
                    style={{ color: "red" }}
                  />
                </span>
              )}
            </div>

            <div onClick={(e) => e.stopPropagation()}>
              <Dropdown
                overlay={menu(row)}
                trigger={["click"]}
                placement="bottomRight"
              >
                <div className="q-table_menu">
                  <IconsCommon.IconEllipsisV />
                </div>
              </Dropdown>
            </div>
          </>
        );
      },
    },
  ];

  const deleteService = async () => {
    setIsLoading(true);
    await deleteServicesV2(delId);
    setShowDel(false);
    setDelId(-1);
    setIsLoading(false);

    await refreshParent();
  };

  const handleOnRowClick = (service:any) => ({
    onClick: (event:any) => {
      setEditService(service)
    }
  })

  return (
    <>
      <div className="pb-10">
        <QTable
          //@ts-ignore
          onRow={handleOnRowClick}
          columns={columns}
          dataSource={servicesDetails}
          loading={false}
          scroll={{ x: "auto" }}
        />
      </div>
      <DialogModal
        modalOpen={showDel}
        question={t("Are you sure?")}
        description={t("Action will delete this service")}
        submitButtonText={t("Cancel")}
        cancelButtonText={t("Delete Service")}
        onClose={() => {
          setShowDel(false);
          setDelId(-1);
        }}
        onProceed={() => {
          deleteService();
        }}
        revert
      />
    </>
  );
};

export default ServiceV2Table;
