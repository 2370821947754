export const MODULE_NAME = 'supportTickets'

/* actions */

export const FETCH_SUPPORT_TICKETS_REQUEST = `${MODULE_NAME}/FETCH_SUPPORT_TICKETS_REQUEST`
export const FETCH_SUPPORT_TICKETS_SUCCESS = `${MODULE_NAME}/FETCH_SUPPORT_TICKETS_SUCCESS`
export const FETCH_SUPPORT_TICKETS_FAILURE = `${MODULE_NAME}/FETCH_SUPPORT_TICKETS_FAILURE`
export const ACTION_SUPPORT_TICKET_REQUEST = `${MODULE_NAME}/ACTION_SUPPORT_TICKET_REQUEST`
export const ACTION_SUPPORT_TICKET_SUCCESS = `${MODULE_NAME}/ACTION_SUPPORT_TICKET_SUCCESS`

export const SET_CURRENT_PAGE = `${MODULE_NAME}/SET_CURRENT_PAGE`
