import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { snakeCase } from "lodash";
import {
  Col,
  QMetricsProgress,
  Row,
  Card,
  QBarChartSimple,
  Empty,
  QPieChart,
  QAreaChartStacked,
} from "quantum_components";
import {
  CAMPAIGN_CALCULATED_METRICS,
  CAMPAIGN_METRICS,
  CAMPAIGN_METRICS_NAMES,
} from "../../constants/campaign";
import {
  calculateGrowthRate,
  calculateRate,
  calculateRateDateRange,
} from "../../helpers/campaign";
import { ICampaignMetrics } from "../../modules/campaign/campaign.types";
import { LOCALIZATION_LANGUAGES, FORMATS } from "src/constants";
import moment from "moment";

interface IProps {
  selectedMetrics: CAMPAIGN_METRICS[];
  mediaType: string;
  details: ICampaignMetrics;
  isPreview?: boolean;
  headerFilter?: any;
  dateRange?: [] | null;
}
const MediaBuyMetrics = ({
  selectedMetrics,
  mediaType,
  details,
  isPreview,
  headerFilter,
  dateRange,
}: IProps) => {
  const { i18n } = useTranslation();
  const { t } = useTranslation([
    "translationCampaign",
    "translation",
    "translationCommon",
  ]);
  const [metrics, setMetrics] = useState([]);
  const [genders, setGenders] = useState([]);
  const [ageGroups, setAgeGroups] = useState([]);
  const [cities, setCities] = useState([]);
  const [areaChartData, setAreaChartData] = useState<any>([]);

  const calculatedMetrics = CAMPAIGN_CALCULATED_METRICS.filter(
    (element) => selectedMetrics && selectedMetrics.includes(element)
  );

  const showCalculatedMetric = (metric: CAMPAIGN_METRICS) => {
    return selectedMetrics.includes(metric);
  };

  useEffect(() => {
    if (details) {
      if (!dateRange) {
        const metricsArray: any = new Array();
        details?.impressions &&
          metricsArray.push({
            name: "Impressions",
            value: details?.impressions >= 0 ? details?.impressions : 0,
          });
        // details?.reach && metricsArray.push({ name: 'Reach', value: details?.reach })
        details?.clicks &&
          metricsArray.push({
            name: "Clicks",
            value: details?.clicks >= 0 ? details?.clicks : 0,
          });
        details?.conversions &&
          metricsArray.push({
            name: "Conversions",
            value: details?.conversions >= 0 ? details?.conversions : 0,
          });
        setMetrics(metricsArray);
      }

      var impressionsCount = 0;
      if (dateRange) {
        calculateAreaData();
        for (const [key, value] of Object.entries(details?.impressions)) {
          impressionsCount = value;
        }
      }

      const citiesArray: any = new Array();
      const ageGroupsArray: any = new Array();
      const gendersArray: any = new Array();

      if (
        dateRange &&
        Object.getOwnPropertyNames(details.impressions).length &&
        impressionsCount
      ) {
        details?.cities &&
          Object.keys(details?.cities).map((key: any) => {
            citiesArray.push({
              name: key,
              value: Math.round(
                (details?.cities[key] * 10 * impressionsCount) / 100
              ),
            });
          });

        details?.ageGroups &&
          Object.keys(details?.ageGroups).map((key: any) => {
            ageGroupsArray.push({
              name: key,
              value: Math.round(
                (details?.ageGroups[key] * 10 * impressionsCount) / 100
              ),
            });
          });

        details?.genders &&
          Object.keys(details?.genders).map((key: any) => {
            gendersArray.push({
              name: key,
              value: Math.round(
                (details?.genders[key] * 10 * impressionsCount) / 100
              ),
            });
          });
      } else if (!dateRange) {
        details?.cities &&
          Object.keys(details?.cities).map((key: any) => {
            citiesArray.push({
              name: key,
              value: Math.round(
                (details?.cities[key] * 10 * details?.impressions) / 100
              ),
            });
          });

        details?.ageGroups &&
          Object.keys(details?.ageGroups).map((key: any) => {
            ageGroupsArray.push({
              name: key,
              value: Math.round(
                (details?.ageGroups[key] * 10 * details?.impressions) / 100
              ),
            });
          });

        details?.genders &&
          Object.keys(details?.genders).map((key: any) => {
            gendersArray.push({
              name: key,
              value: Math.round(
                (details?.genders[key] * 10 * details?.impressions) / 100
              ),
            });
          });
      }

      setCities(citiesArray);
      setAgeGroups(ageGroupsArray);
      setGenders(gendersArray);
    }
  }, [details]);

  const calculateAreaData = async () => {
    const finalDataChart: any = [];

    // since impressions, clicks and conversions on details will have the same amount of key values so we can plot them in the same array
    Object.keys(details?.impressions).forEach((key, index) => {
      // @ts-ignore
      // console.log(index, key, details.impressions[key], details.clicks[key]);
      finalDataChart.push({
        name: moment(key).format(FORMATS.DATE_FORMAT),
        // @ts-ignore
        firstLine: details.clicks[key] >= 0 ? details.clicks[key] : 0,
        //@ts-ignore
        secondLine: details.impressions[key] >= 0 ? details.impressions[key] : 0,
        //@ts-ignore
        thirdLine: details.conversions[key] >= 0 ? details.conversions[key] : 0,
      });
    });

    setAreaChartData(finalDataChart);
    return;

    for (const [key, value] of Object.entries(details?.impressions)) {
      finalDataChart.push({
        name: key,
        firstLine: -1,
        secondLine: value,
        // thirdLine: value,
      });
    }

    // for (const [key, value] of Object.entries(details?.reach)) {
    //   const findedValue: any = finalDataChart.findIndex(
    //     (ele: any) => moment(ele.name).format(FORMATS.DATE_TIME_FORMAT) === moment(key).format(FORMATS.DATE_TIME_FORMAT)
    //   )
    //   if (findedValue >= 0) {
    //     finalDataChart[findedValue].secondLine = value
    //   } else {
    //     if (finalDataChart.length > 0) {
    //       for (let i = 0; i < finalDataChart.length; i++) {
    //         if (moment(finalDataChart[i].name).format(FORMATS.DATE_TIME_FORMAT) > moment(key).format(FORMATS.DATE_TIME_FORMAT)) {
    //           finalDataChart.splice(i, 0, { name: key, firstLine: 0, secondLine: value })
    //           // finalDataChart.splice(i, 0, { name: key, firstLine: 0, secondLine: value, thirdLine: 0 })
    //           break
    //         } else if (finalDataChart.length === i + 1) {
    //           finalDataChart.push({ name: key, firstLine: 0, secondLine: value })
    //           // finalDataChart.push({ name: key, firstLine: 0, secondLine: value, thirdLine: 0 })
    //           break
    //         }
    //       }
    //     } else {
    //       finalDataChart.push({ name: key, firstLine: 0, secondLine: value })
    //       // finalDataChart.push({ name: key, firstLine: 0, secondLine: value, thirdLine: 0 })
    //     }
    //   }
    // }
    for (const [key, value] of Object.entries(details?.clicks)) {
      const findedValue: any = finalDataChart.findIndex(
        (ele: any) =>
          moment(ele.name).format(FORMATS.DATE_TIME_FORMAT) ===
          moment(key).format(FORMATS.DATE_TIME_FORMAT)
      );
      if (findedValue >= 0) {
        finalDataChart[findedValue].firstLine = value;
      } else {
        if (finalDataChart.length > 0) {
          for (let i = 0; i < finalDataChart.length; i++) {
            if (
              moment(finalDataChart[i].name).format(FORMATS.DATE_TIME_FORMAT) >
              moment(key).format(FORMATS.DATE_TIME_FORMAT)
            ) {
              finalDataChart.splice(i, 0, {
                name: key,
                firstLine: value,
                secondLine: 0,
              });
              // finalDataChart.splice(i, 0, { name: key, firstLine: value, secondLine: 0, thirdLine: 0 })
              break;
            } else if (finalDataChart.length === i + 1) {
              finalDataChart.push({
                name: key,
                firstLine: value,
                secondLine: 0,
              });
              // finalDataChart.push({ name: key, firstLine: value, secondLine: 0, thirdLine: 0 })
              break;
            }
          }
        } else {
          finalDataChart.push({ name: key, firstLine: value, secondLine: 0 });
          // finalDataChart.push({ name: key, firstLine: value, secondLine: 0, thirdLine: 0 })
        }
      }
    }
    // finalDataChart.map((ele: any) => {
    //   ele.name = moment(ele.name).format(FORMATS.DATE_FORMAT);
    //   ele.firstLine = ele.firstLine === -1 ? 0 : ele.firstLine;
    //   ele.secondLine = ele.secondLine === -1 ? 0 : ele.secondLine;
    // });
    setAreaChartData(finalDataChart);
  };

  // console.log(areaChartData)

  return (
    <div className="dashboard-media full-width">
      <Card className="qu-chart-card qu-metrics-card mb-20">
        <div className="card-header">
          <h3>
            {t("services.media-buy.types." + snakeCase(mediaType), mediaType, {
              ns: "translation",
            })}
          </h3>
        </div>

        {(dateRange ? !!areaChartData.length : !!metrics.length) && (
          <>
            <h3 className="qu-chart-label" style={{ padding: "16px 24px" }}>
              <strong>{t("details.metrics")}</strong>
            </h3>
            <div className="grid-row grid-row--jcc">
              <div className="grid-col">
                {(dateRange ? areaChartData.length : metrics.length) ? (
                  dateRange ? (
                    <QAreaChartStacked
                      height={300}
                      data={areaChartData}
                      labels={["Clicks", "Impressions", "Conversions"]}
                    />
                  ) : (
                    // <QAreaChartStacked height={300} data={areaChartData} labels={['Clicks', 'Reach', 'Impressions']} />
                    <QBarChartSimple className="h-300" data={metrics} />
                  )
                ) : (
                  <div className="qu-chart-empty h-300">
                    <Empty
                      description={
                        <span>
                          {t("common.No_Data", { ns: "translationCommon" })}
                        </span>
                      }
                    />
                  </div>
                )}
              </div>
            </div>
          </>
        )}
        {!areaChartData.length && !metrics.length && (
          <div className="qu-chart-empty h-300">
            <Empty
              description={
                <span>{t("common.No_Data", { ns: "translationCommon" })}</span>
              }
            />
          </div>
        )}

        {!!cities.length && !!ageGroups.length && !!genders.length && (
          <h3 className="qu-chart-label" style={{ padding: "16px 24px" }}>
            <strong>{t("details.demographics")}</strong>
          </h3>
        )}
        {!!cities.length && (
          <div className="grid-row grid-row--jcc">
            <div className="grid-col">
              {cities.length ? (
                <>
                  <h3
                    className="qu-chart-label"
                    style={{
                      padding:
                        i18n.language === LOCALIZATION_LANGUAGES.ARABIC
                          ? "8px 24px 0 0"
                          : "0 0 8px 24px",
                    }}
                  >
                    {t("details.cities")}
                  </h3>
                  <QBarChartSimple
                    className="h-300"
                    data={cities}
                    isImpressions={true}
                  />
                </>
              ) : (
                <>
                  <h3
                    className="qu-chart-label"
                    style={{
                      padding:
                        i18n.language === LOCALIZATION_LANGUAGES.ARABIC
                          ? "8px 24px 0 0"
                          : "0 0 8px 24px",
                    }}
                  >
                    {t("details.cities")}
                  </h3>
                  <div className="qu-chart-empty h-300">
                    <Empty
                      description={
                        <span>
                          {t("common.No_Data", { ns: "translationCommon" })}
                        </span>
                      }
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        )}

        {!!ageGroups.length && (
          <div className="grid-row grid-row--jcc">
            <div className="grid-col">
              {ageGroups.length ? (
                <>
                  <h3
                    className="qu-chart-label"
                    style={{
                      padding:
                        i18n.language === LOCALIZATION_LANGUAGES.ARABIC
                          ? "8px 24px 0 0"
                          : "0 0 8px 24px",
                    }}
                  >
                    {t("details.age_groups")}
                  </h3>
                  <QBarChartSimple
                    className="h-300"
                    data={ageGroups}
                    isImpressions={true}
                  />
                </>
              ) : (
                <>
                  <h3
                    className="qu-chart-label"
                    style={{
                      padding:
                        i18n.language === LOCALIZATION_LANGUAGES.ARABIC
                          ? "8px 24px 0 0"
                          : "0 0 8px 24px",
                    }}
                  >
                    {t("details.age_groups")}
                  </h3>
                  <div className="qu-chart-empty h-300">
                    <Empty
                      description={
                        <span>
                          {t("common.No_Data", { ns: "translationCommon" })}
                        </span>
                      }
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        )}

        {!!genders.length && (
          <div className="grid-row grid-row--jcc">
            <div className="grid-col">
              {genders.length ? (
                <>
                  <h3
                    className="qu-chart-label"
                    style={{
                      padding:
                        i18n.language === LOCALIZATION_LANGUAGES.ARABIC
                          ? "8px 24px 0 0"
                          : "0 0 8px 24px",
                    }}
                  >
                    {t("details.genders")}
                  </h3>
                  <QBarChartSimple
                    className="h-300"
                    data={genders}
                    isImpressions={true}
                  />
                </>
              ) : (
                <>
                  <h3
                    className="qu-chart-label"
                    style={{
                      padding:
                        i18n.language === LOCALIZATION_LANGUAGES.ARABIC
                          ? "8px 24px 0 0"
                          : "0 0 8px 24px",
                    }}
                  >
                    {t("details.genders")}
                  </h3>
                  <div className="qu-chart-empty h-300">
                    <Empty
                      description={
                        <span>
                          {t("common.No_Data", { ns: "translationCommon" })}
                        </span>
                      }
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </Card>

      {calculatedMetrics.length > 0 && (
        <div className="qu-chart-card">
          <div className="card-header">
            <h3>{t("details.rates")}</h3>
          </div>
          <div className="card-body">
            <Row gutter={16} justify="space-around">
              {showCalculatedMetric(CAMPAIGN_METRICS.GROWTH_RATE) && (
                <Col xs={24} md={6}>
                  <QMetricsProgress
                    className="mv-15"
                    value={calculateGrowthRate(
                      details[CAMPAIGN_METRICS.BEFORE_REVENUE],
                      details[CAMPAIGN_METRICS.AFTER_REVENUE]
                    )}
                    title={t(
                      CAMPAIGN_METRICS_NAMES[CAMPAIGN_METRICS.GROWTH_RATE]
                    )}
                  />
                </Col>
              )}
              {showCalculatedMetric(CAMPAIGN_METRICS.CTR) && (
                <Col xs={24} md={6}>
                  <QMetricsProgress
                    className="mv-15"
                    value={calculateRateDateRange(
                      details[CAMPAIGN_METRICS.CLICKS],
                      details[CAMPAIGN_METRICS.IMPRESSIONS]
                    )}
                    title={t(CAMPAIGN_METRICS_NAMES[CAMPAIGN_METRICS.CTR])}
                  />
                </Col>
              )}
              {showCalculatedMetric(CAMPAIGN_METRICS.CR) && (
                <Col xs={24} md={6}>
                  <QMetricsProgress
                    className="mv-15"
                    value={calculateRate(
                      details[CAMPAIGN_METRICS.ORDERS],
                      details[CAMPAIGN_METRICS.CLICKS]
                    )}
                    title={t(CAMPAIGN_METRICS_NAMES[CAMPAIGN_METRICS.CR])}
                  />
                </Col>
              )}
              {showCalculatedMetric(CAMPAIGN_METRICS.ER) && (
                <Col xs={24} md={6}>
                  <QMetricsProgress
                    className="mv-15"
                    value={calculateRate(
                      details[CAMPAIGN_METRICS.INTERACTIONS],
                      details[CAMPAIGN_METRICS.FOLLOWERS]
                    )}
                    title={t(CAMPAIGN_METRICS_NAMES[CAMPAIGN_METRICS.ER])}
                  />
                </Col>
              )}
            </Row>
          </div>
        </div>
      )}
    </div>
  );
};

export default MediaBuyMetrics;
