import React, { useState } from 'react'
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import { QInput } from 'quantum_components'
interface Props {
  hasError?: boolean
  errorText?: string
  value?: string
  placeholder?: string
  onSelectHandler?(address: any): void
  onChange?(address: any): void
}
const LocationSearchInput: React.FC<Props> = ({
  hasError,
  onSelectHandler = () => null,
  onChange = () => null,
  placeholder,
  value,
  errorText,
}) => {
  const [address, setAddress] = useState(value)
  const searchOptions = {
    types: ['address'],
  }
  const handleChange = (addr: string) => {
    setAddress(addr)
    onChange(addr)
  }
  const handleSelect = (addr: string) => {
    setAddress(addr)
    geocodeByAddress(addr)
      .then(results => getLatLng(results[0]))
      .then(latLng => onSelectHandler(addr))
  }
  return (
    <PlacesAutocomplete value={address} onChange={handleChange} searchOptions={searchOptions} onSelect={handleSelect}>
      {({ getInputProps, suggestions, getSuggestionItemProps }) => (
        <div className={`autocomplete-dropdown-container ${hasError ? 'error' : ''}`}>
          <QInput
            {...getInputProps({
              placeholder,
              className: 'location-search-input',
            })}
            size="large"
          />
          <div className="autocomplete-dropdown">
            {suggestions.map((suggestion: any) => {
              const className = suggestion.active ? 'suggestion-item active' : 'suggestion-item'
              return (
                <div {...getSuggestionItemProps(suggestion, { className })} key={suggestion.index}>
                  <span>{suggestion.description}</span>
                </div>
              )
            })}
          </div>
          {hasError && errorText && <span className="error-text">{errorText}</span>}
        </div>
      )}
    </PlacesAutocomplete>
  )
}
export default LocationSearchInput
