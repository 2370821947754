import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../workflow.actions";
import {
  Row,
  Col,
  QButton,
  QSelect,
  Form,
  QTable,
} from "quantum_components";
import { getDataLoading, getAllWorkflows } from "../workflow.selectors";
import {
  DeleteTwoTone,
} from "@ant-design/icons";
import DialogModal from "src/components/DialogModal/DialogModal";
import { fetchEmployeesList } from "src/modules/brief/brief.actions";
import { DEPARTMENT_LIST } from "../workflow.constants";
import { LOCALIZATION_LANGUAGES } from "src/constants";
import { getCurrentUser, getCurrentUserPermissionActions } from "src/modules/core/core.selectors";
import { hasPermission } from "src/helpers/permission";
import { PERMISSIONS } from "src/constants/permission";

const Workflow = () => {
  const { i18n } = useTranslation()
  const { t } = useTranslation(['translationWorkflow', 'translationCommon'])
  const [form] = Form.useForm();
  const [initialValues, setInitialValues] = useState<any>({});
  const [employees, setEmployees] = useState<any>([]);
  const [departments, setDepartments] = useState<any>([]);
  const isLoading = useSelector(getDataLoading)
  const workflows = useSelector(getAllWorkflows)
  const permissions = useSelector(getCurrentUserPermissionActions)

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [activeWorkflowID, setActiveWorkflowID] = useState(-1);

  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      setEmployees(await dispatch(fetchEmployeesList(200)));

      await dispatch(actions.getAllWorkflows())

      setDepartments(
        DEPARTMENT_LIST.map((dept: string) => {
          return { label: t(dept), value: dept };
        })
      );
    })();
  }, []);

  const handleOnFinish = async (data: any) => {
    await dispatch(actions.createWorkflow(data))
    form.resetFields()

    await dispatch(actions.getAllWorkflows())
  };

  const columns = [
    {
      title: t('Department'),
      dataIndex: "departmentName",
      key: "departmentName",
      width: "45%",
      fixed: "left",
    },
    {
      title: t('Employee'),
      dataIndex: "employee",
      key: "employee",
      width: "45%",
      fixed: "left",
      render: (text: string, row: any) => {
        return row.employee?.firstName + ' ' + row.employee?.lastName
      }
    },
    {
      title: t('Action'),
      dataIndex: "actions",
      key: "actions",
      width: "10%",
      fixed: "left",
      render: (text: string, record: any) => {
        return (
          <div style={{ textAlign: 'center' }}>
            <span>
              {hasPermission(permissions, PERMISSIONS.DELETE_WORKFLOW) &&
                <DeleteTwoTone
                  style={{ fontSize: "20px", }}
                  onClick={() => {
                    deleteWrapper(record.id);
                  }}
                ></DeleteTwoTone>}
            </span>
          </div>
        );
      },
    },
  ];

  const deleteWrapper = async (id: number) => {
    setActiveWorkflowID(id);
    setShowDeleteModal(true);
  };

  const handleModalClose = () => {
    setShowDeleteModal(false);
    setActiveWorkflowID(-1);
  };

  const handleModalProceed = async () => {
    if (activeWorkflowID === -1) return;
    await dispatch(actions.deleteWorkflow(activeWorkflowID));
    handleModalClose();
    await dispatch(actions.getAllWorkflows());
  };


  return (
    <>
      <h2>{t('Workflow')}</h2>

      <div className="mt-20">
        <h5>{t('Create Workflow Element')}</h5>
        <Form
          className="mt-10"
          layout="vertical"
          initialValues={initialValues}
          form={form}
          onFinish={handleOnFinish}
        >
          <Row gutter={24} justify={"start"}>
            <Col xs={8} sm={8} md={8}>
              <Form.Item
                label={t('Employee')}
                name="employeeId"
                rules={[{ required: true, message: t('Employee is required') }]}
                validateTrigger={["onChange", "onBlur"]}
              >
                <QSelect
                  disabled={!hasPermission(permissions, PERMISSIONS.CREATE_WORKFLOW)}
                  size="large"
                  showArrow
                  placeholder={t('Select Employee')}
                  options={employees}
                />
              </Form.Item>
            </Col>
            <Col xs={8} sm={8} md={8}>
              <Form.Item
                label={t('Department')}
                name="departmentName"
                rules={[{ required: true, message: t('Department is required') }]}
                validateTrigger={["onChange", "onBlur"]}
              >
                <QSelect
                  disabled={!hasPermission(permissions, PERMISSIONS.CREATE_WORKFLOW)}
                  size="large"
                  showArrow
                  placeholder={t('Select Department')}
                  options={departments}
                />
              </Form.Item>
            </Col>
            <Col xs={8} sm={8} md={8}>
              <QButton
                className="text-capitalize mt-20"
                type="primary"
                htmlType="submit"
                disabled={isLoading || !hasPermission(permissions, PERMISSIONS.CREATE_WORKFLOW)}
              >
                {t('common.Save_Updates', { ns: 'translationCommon' })}
              </QButton>
            </Col>
          </Row>
        </Form>
        <div className="form__hr"></div>
      </div>

      <div className="pt-15 pb-20">
        <h5 className="mb-10">{t('Saved Workflow Elements')}</h5>
        <QTable columns={columns} dataSource={workflows} loading={isLoading} />
      </div>

      <DialogModal
        modalOpen={showDeleteModal}
        question={t('Are you sure?')}
        description={t('This action will delete this workflow element')}
        submitButtonText={t('common.Cancel', { ns: 'translationCommon' })}
        cancelButtonText={t('common.Delete', { ns: 'translationCommon' })}
        onClose={handleModalClose}
        onProceed={handleModalProceed}
        revert
      />
    </>
  );
};

export default Workflow;
