import React, { ReactElement, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useReduxDispatch } from 'src/helpers'
import { QTable, Dropdown, QMenuItem, Menu, IconsCommon } from 'quantum_components'
import { useSelector } from 'react-redux'

import { getCampaignPartnerDistribution, getCampaignSettings, getCampaignUpdating } from '../../../campaign.selectors'
import { ICampaignPartner, ICampaignDistribution, ICampaignDistributionEdit } from '../../../campaign.types'
import { Modal } from 'antd'
import { MODAL_TEXT } from '../../../../../constants/modal'
import { actions } from '../../../campaign.module'
import { FORMATS } from '../../../../../constants'
import moment from 'moment'
import CampaignEditDistModal from './CampaignEditDistModal'
import { ICity } from '../../../../brief/brief.types'
import { CAMPAIGN_STATUS } from '../../../../../constants/campaign'
import { getIsoDate } from '../../../../../helpers/datesHelper'

interface IProps {
  partner: ICampaignPartner
  cities: ICity[]
  fetchInfo: (partnerId: number) => void
}

export default function CampaignDistTable({ partner, cities, fetchInfo }: IProps): ReactElement {
  const dispatch = useReduxDispatch()
  const timelane = useSelector(getCampaignPartnerDistribution)
  const campaignUpdating = useSelector(getCampaignUpdating)
  const initData = useSelector(getCampaignSettings)
  const editFormRef = useRef()
  const { id } = useParams<{id: any}>()

  const [deleteModal, setDeleteModal] = useState<boolean>(false)
  const [editModal, setEditModal] = useState<boolean>(false)
  const [targetTimeline, setTargetTimeline] = useState<ICampaignDistribution | null>(null)
  const [tableKey, setTableKey] = useState(1)

  const isCompleted = initData.campaignStatus === CAMPAIGN_STATUS.COMPLETED

  const timelaneColums = [
    {
      title: 'Created Date',
      dataIndex: 'date',
      key: 'date',
      width: '40%',
      render: (date: string) => <div>{moment(date).format(FORMATS.DATE_LL_FORMAT)}</div>,
    },
    {
      title: 'City',
      dataIndex: 'cityName',
      key: 'cityName',
      width: '30%',
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      width: '30%',
      render: (quantity: number, data: ICampaignDistribution) => {
        return (
          <div>
            <span>{quantity}</span>
            {!isCompleted && (
              <Dropdown overlay={menu(data)} trigger={['click']} placement="bottomRight">
                <div className="q-table_menu">
                  <IconsCommon.IconEllipsisV />
                </div>
              </Dropdown>
            )}
          </div>
        )
      },
    },
  ]

  const prepareData = (timeline: ICampaignDistribution) => {
    const newTimeline = {
      ...timeline,
      date: getIsoDate(timeline.date),
    }

    return {
      partnerId: partner.id,
      data: [newTimeline],
    } as ICampaignDistributionEdit
  }

  const handleUpdate = async () => {
    if (!targetTimeline || campaignUpdating) return
    if (editFormRef?.current) {
      // @ts-ignore
      await editFormRef.current.updateCart()
    }
    hideEditModal()

    const data = prepareData(targetTimeline)
    await dispatch(actions.campaignSaveDistribution(id, data))
    fetchInfo(partner.id)
    setTableKey(Math.floor(Math.random() * 1000 + 1))
  }

  const handleDelete = async () => {
    if (!targetTimeline || campaignUpdating) return
    hideDeleteModal()

    const data = prepareData(targetTimeline)
    await dispatch(actions.deleteDistribution(id, data))
    fetchInfo(partner.id)
    setTableKey(Math.floor(Math.random() * 1000 + 1))
  }

  const showDeleteModal = (timeline: ICampaignDistribution) => {
    setDeleteModal(true)
    setTargetTimeline(timeline)
  }
  const hideDeleteModal = () => {
    setDeleteModal(false)
    setTargetTimeline(null)
    setTableKey(Math.floor(Math.random() * 1000 + 1))
  }
  const showEditModal = (timeline: ICampaignDistribution) => {
    setEditModal(true)
    setTargetTimeline(timeline)
  }
  const hideEditModal = () => {
    setEditModal(false)
    setTargetTimeline(null)
    setTableKey(Math.floor(Math.random() * 1000 + 1))
  }

  const menu = (timeline: ICampaignDistribution) => (
    <Menu className="user-menu-list">
      <QMenuItem key={0} onClick={() => showEditModal(timeline)} name="Edit" icon={<IconsCommon.IconEdit />} />
      <Menu.Divider />
      <QMenuItem key={1} onClick={() => showDeleteModal(timeline)} name="Delete" icon={<IconsCommon.IconDelete />} />
    </Menu>
  )

  const handleTimelineChange = (timeline: ICampaignDistribution) => {
    setTargetTimeline(timeline)
  }

  return (
    <React.Fragment>
      <h5 className="pb-10">Campaign History</h5>
      <QTable columns={timelaneColums} dataSource={timelane} loading={false} />
      <Modal
        key={tableKey}
        wrapClassName="qu-modal"
        title={<h3>Edit</h3>}
        visible={editModal}
        onOk={handleUpdate}
        onCancel={hideEditModal}
      >
        {targetTimeline && (
          <CampaignEditDistModal
            ref={editFormRef}
            cities={cities}
            timeline={targetTimeline}
            onChange={handleTimelineChange}
          />
        )}
      </Modal>
      <Modal
        key={tableKey + 1}
        wrapClassName="qu-modal"
        title={<h3>Delete</h3>}
        visible={deleteModal}
        onOk={handleDelete}
        onCancel={hideDeleteModal}
      >
        {MODAL_TEXT.ARE_YOU_SURE}
      </Modal>
    </React.Fragment>
  )
}
