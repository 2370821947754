export enum CAMPAIGN_STATUS {
  ONGOING = 'ONGOING',
  COMPLETED = 'COMPLETED',
  ALL = 'ALL',
}

export const CAMPAIGN_STATUS_LIST = [
  CAMPAIGN_STATUS.ONGOING,
  CAMPAIGN_STATUS.COMPLETED
]

export const campaignStatusesLabel = {
  [CAMPAIGN_STATUS.COMPLETED]: {
    classStyle: 'completed',
    label: 'campaign-statuses-array.COMPLETED',
  },
  [CAMPAIGN_STATUS.ONGOING]: {
    classStyle: 'ongoing',
    label: 'campaign-statuses-array.ONGOING',
  },
  [CAMPAIGN_STATUS.ALL]: {
    classStyle: '',
    label: 'campaign-statuses-array.ALL',
  },
}

export const CAMPAIGN_PRINT_ANALYTICS_ID = 'campaign_print_analytics_area'
export const CAMPAIGN_PRINT_SURVEY = 'campaign_print_survey'

export enum CAMPAIGN_METRICS {
  IMPRESSIONS = 'impressions',
  REACH = 'reach',
  CLICKS = 'clicks',
  CONVERSIONS = 'conversions',
  ORDERS = 'orders',
  BEFORE_REVENUE = 'beforeRevenue',
  AFTER_REVENUE = 'afterRevenue',
  GENDERS = 'genders',
  AGE_GROUPS = 'ageGroups',
  CITIES = 'cities',
  INTERACTIONS = 'interactions',
  FOLLOWERS = 'followers',
  GROWTH_RATE = 'growthRate',
  CTR = 'clickThroughRate',
  CR = 'conversionRate',
  ER = 'engagementRate',
}

export const CAMPAIGN_METRICS_NAMES: { [key: string]: string } = {
  [CAMPAIGN_METRICS.IMPRESSIONS]: 'metrics.Of impressions',
  [CAMPAIGN_METRICS.CLICKS]: 'metrics.Of clicks',
  [CAMPAIGN_METRICS.ORDERS]: 'metrics.Of orders',
  [CAMPAIGN_METRICS.BEFORE_REVENUE]: 'metrics.Before ad Revenue',
  [CAMPAIGN_METRICS.AFTER_REVENUE]: 'metrics.After ad Revenue',
  [CAMPAIGN_METRICS.GENDERS]: 'metrics.Genders',
  [CAMPAIGN_METRICS.AGE_GROUPS]: 'metrics.Age Groups',
  [CAMPAIGN_METRICS.INTERACTIONS]: 'metrics.SM Total interactions',
  [CAMPAIGN_METRICS.FOLLOWERS]: 'metrics.SM Followers',
  [CAMPAIGN_METRICS.GROWTH_RATE]: 'metrics.Growth Rate',
  [CAMPAIGN_METRICS.CTR]: 'metrics.Click Through Rate',
  [CAMPAIGN_METRICS.CR]: 'metrics.Conversion Rate',
  [CAMPAIGN_METRICS.ER]: 'metrics.Engagement Rate',
}

export const CAMPAIGN_METRICS_INPUTS = [
  CAMPAIGN_METRICS.IMPRESSIONS,
  CAMPAIGN_METRICS.CLICKS,
  CAMPAIGN_METRICS.ORDERS,
  CAMPAIGN_METRICS.BEFORE_REVENUE,
  CAMPAIGN_METRICS.AFTER_REVENUE,
  CAMPAIGN_METRICS.INTERACTIONS,
  CAMPAIGN_METRICS.FOLLOWERS,
]

export const CAMPAIGN_METRICS_LIST = [
  CAMPAIGN_METRICS.IMPRESSIONS,
  CAMPAIGN_METRICS.CLICKS,
  CAMPAIGN_METRICS.ORDERS,
  CAMPAIGN_METRICS.BEFORE_REVENUE,
  CAMPAIGN_METRICS.AFTER_REVENUE,
  CAMPAIGN_METRICS.GENDERS,
  CAMPAIGN_METRICS.AGE_GROUPS,
  CAMPAIGN_METRICS.INTERACTIONS,
  CAMPAIGN_METRICS.FOLLOWERS,
  CAMPAIGN_METRICS.GROWTH_RATE,
  CAMPAIGN_METRICS.CTR,
  CAMPAIGN_METRICS.CR,
  CAMPAIGN_METRICS.ER,
]

export const CAMPAIGN_CALCULATED_METRICS = [
  CAMPAIGN_METRICS.GROWTH_RATE,
  CAMPAIGN_METRICS.CTR,
  CAMPAIGN_METRICS.CR,
  CAMPAIGN_METRICS.ER,
]

export const CAMPAIGN_TIMELINES_LIMIT = 30
export const GALLERY_PER_PAGE = 8

export enum CAMPAIGN_PARTNERS {
  ALL_PARTNERS = 'ALL_PARTNERS',
  QUANTUM_TEAM = 'QUANTUM_TEAM',
}

export const CAMPAIGN_PARTNERS_NAMES: { [key: string]: string } = {
  [CAMPAIGN_PARTNERS.ALL_PARTNERS]: 'All Partners',
  [CAMPAIGN_PARTNERS.QUANTUM_TEAM]: 'Quantum Team',
}

export enum CAMPAIGN_UPLOAD_TYPES {
  CAMPAIGN_REPORT_FILES = 'campaign_report_files',
}
