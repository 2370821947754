import IAction from '../../interfaces/IAction'
import * as CONSTANTS from './dashboard.constants'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { requestHttp, urls } from '../../api'
import { IDashboardData, IDashboardFilter, IDashboardFilterParams } from './dashboard.types'
import { CHART_TYPES } from '../../constants/dashboard'
import { InitIDateRangeParams } from './dashboard.reducer'
import { cloneDeep } from 'lodash'
import { ICity, ICountry } from '../brief/brief.types'

export const setDashboardParams = (params?: IDashboardFilterParams): IAction => ({
  type: CONSTANTS.SET_DASHBOARD_PARAMS,
  payload: { params },
})

export const resetDashboard = (): IAction => ({
  type: CONSTANTS.RESET_DASHBOARD_STATE,
})

export const setParamsAndFetchChart = (
  params: IDashboardFilterParams,
  commonFilters = false
): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  await dispatch(setDashboardParams(params))
  await dispatch(fetchChartData(params, commonFilters))
}

export const fetchChartDataRequest = (): IAction => ({
  type: CONSTANTS.FETCH_DASHBOARD_REQUEST,
})

export const fetchChartDataSuccess = (data: IDashboardData): IAction => ({
  type: CONSTANTS.FETCH_DASHBOARD_SUCCESS,
  payload: { data },
})

export const fetchChartDataFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_DASHBOARD_FAILURE,
  error,
})

export const fetchChartData = (
  filter: IDashboardFilterParams,
  commonFilters = false
): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    let newParams: any = cloneDeep(filter)
    if (commonFilters) {
      for (let key in newParams) {
        if (newParams[key]?.before || newParams[key]?.after) {
          if (key === CHART_TYPES.CAMPAIGN_COMPLETION) {
            newParams[key] = []
          }
        } else {
          delete newParams[key]
        }
      }
    }

    const params = commonFilters ? { graphs: newParams } : getChartParams(filter)

    dispatch(fetchChartDataRequest())
    const response = await requestHttp.post(urls.getDashboardChartDataUrl(), params)
    const { content } = response.data.data

    dispatch(fetchChartDataSuccess(content))
  } catch (error) {
    dispatch(fetchChartDataFailure(error))
  }
}

const getChartParams = (filter: IDashboardFilterParams) => {
  const filterType = filter.type
  const initDate = cloneDeep(InitIDateRangeParams)

  let params = {
    graphs: {
      [CHART_TYPES.SIGNUP_REQUESTS_CLIENT]: initDate,
      [CHART_TYPES.SIGNUP_REQUESTS_PARTNER]: initDate,
      [CHART_TYPES.APPROVED_CLIENTS]: initDate,
      [CHART_TYPES.APPROVED_PARTNERS]: initDate,
      [CHART_TYPES.SAMPLE_DISTRIBUTION_ACTIVE]: initDate,
      [CHART_TYPES.SAMPLE_DISTRIBUTION_COMPLETE]: initDate,
      [CHART_TYPES.TRANSACTIONS]: initDate,
      [CHART_TYPES.BRIEFS]: initDate,
      [CHART_TYPES.BRIEF_CONVERSION]: initDate,
      [CHART_TYPES.CAMPAIGNS]: initDate,
      [CHART_TYPES.REVENUE]: initDate,
      [CHART_TYPES.CAMPAIGN_TYPE]: initDate,
      [CHART_TYPES.ACTIVE_USERS]: initDate,
      [CHART_TYPES.CAMPAIGN_COMPLETION]: [],
    },
  } as IDashboardFilter

  if (filterType) {
    // @ts-ignore
    delete filter.type
    if (filterType === CHART_TYPES.SIGNUP_REQUESTS_CLIENT) {
      const filterDate = {
        before: filter[filterType].before,
        after: filter[filterType].after,
      }
      params.graphs = {
        [CHART_TYPES.SIGNUP_REQUESTS_CLIENT]: filterDate,
        [CHART_TYPES.SIGNUP_REQUESTS_PARTNER]: filterDate,
      }
    } else if (filterType === CHART_TYPES.SAMPLE_DISTRIBUTION_ACTIVE) {
      const sampleFilter = { ...filter[filterType] }
      if ((!sampleFilter.cityNames || !sampleFilter.cityNames.length) && filter.countries.length > 0) {
        sampleFilter.cityNames = filter.countries.map((c: ICountry) => c.city.map((city: ICity) => city.name)).flat()
      }
      params.graphs = {
        [CHART_TYPES.SAMPLE_DISTRIBUTION_ACTIVE]: sampleFilter,
        [CHART_TYPES.SAMPLE_DISTRIBUTION_COMPLETE]: sampleFilter,
      }
    } else if (filterType === CHART_TYPES.CAMPAIGNS) {
      params.graphs = {
        [CHART_TYPES.CAMPAIGNS]: filter[filterType],
        [CHART_TYPES.BRIEF_CONVERSION]: filter[filterType],
      }
    } else if (filterType === CHART_TYPES.CAMPAIGN_COMPLETION) {
      params.graphs = {
        [CHART_TYPES.CAMPAIGN_COMPLETION]: { campaignIds: filter[CHART_TYPES.CAMPAIGN_COMPLETION].campaignIds },
      }
    } else if (filterType === CHART_TYPES.REVENUE) {
      params.graphs = {
        [CHART_TYPES.REVENUE]: {
          ...filter[filterType],
          isTaxesIncluded: !!filter[filterType].isTaxesIncluded,
        },
      }
    } else {
      params.graphs = { [filterType]: filter[filterType] }
    }
  }

  return params
}
