import React, { ReactElement } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getRequestList, getRequestListLoading } from '../request.selectors'
import RequestTableComponent from '../../../../components/requestsTable/RequestTable.component'

export default function RequestListContainer(): ReactElement {
  const requestList = useSelector(getRequestList)
  const requestListLoading = useSelector(getRequestListLoading)
  const { id } = useParams<{id: any}>()

  return <RequestTableComponent briefId={id} requestList={requestList} loading={requestListLoading} hideSearch />
}
