import { createSelector } from 'reselect'
import { MODULE_NAME } from './roles.constants'
import { IState, IRole, IPermission } from './roles.types'

const selectState = (state: { [MODULE_NAME]: any }) => state[MODULE_NAME]

export const getIsRolesListLoading = createSelector(selectState, (state: IState): boolean => state.rolesListLoading)

export const getUpdatingRoles = createSelector(selectState, (state: IState): boolean => state.updatingRoles)

export const getRolesList = createSelector(selectState, (state: IState): IRole[] =>
  state.rolesList.results.map((role: IRole) => {
    return {
      ...role,
      key: role.id,
    }
  })
)

export const getRolesTotal = createSelector(selectState, (state: IState): number => state.rolesList.total)

export const getRoleDetails = createSelector(selectState, (state: IState): IRole => state.roleDetails)

export const getPermissionsList = createSelector(selectState, (state: IState): IPermission[] => state.permissionsList)

export const getPages = createSelector(
  selectState,
  (
    state: IState
  ): {
    totalPages: number
    currentPage: number
  } => state.pages
)
