import { createSelector } from 'reselect'

import { IBillingsListElement, IBillListElement, IBillTableParams, IOnlinePayment, IState } from './bills.types'
import { MODULE_NAME } from './bills.constants'

const selectState = (state: { [MODULE_NAME]: IState }): IState => state[MODULE_NAME]

export const getBillParams = createSelector(selectState, (state: IState): IBillTableParams => state.params)

export const getBillList = createSelector(selectState, (state: IState): IBillListElement[] => state.billList.results)
export const getBillListLoading = createSelector(selectState, (state: IState): boolean => state.billListLoading)
export const getTotalBillList = createSelector(selectState, (state: IState): number => state.billList.total)

export const getRequestList = createSelector(
  selectState,
  (state: IState): IBillingsListElement[] => state.requestList.results
)
export const getRequestListLoading = createSelector(selectState, (state: IState): boolean => state.requestListLoading)
export const getTotalRequestList = createSelector(selectState, (state: IState): number => state.requestList.total)

export const getOnlinePaymentsList = createSelector(
  selectState,
  (state: IState): IOnlinePayment[] => state.onlinePaymentsList.results
)
export const getOnlinePaymentsListLoading = createSelector(
  selectState,
  (state: IState): boolean => state.onlinePaymentsListLoading
)
export const getTotalOnlinePaymentsList = createSelector(
  selectState,
  (state: IState): number => state.onlinePaymentsList.total
)

export const getRefundPending = createSelector(selectState, (state: IState): boolean => state.refundPending)
export const getRefundError = createSelector(selectState, (state: IState): string => state.refundError)

export const getPages = createSelector(
  selectState,
  (
    state: IState
  ): {
    totalPages: number
    currentPage: number
  } => state.pages
)
