import React, { FC } from 'react'
import { QRadio, Radio } from 'quantum_components'
import { GENDER } from '../../../../../../constants/brief'
import { useTranslation } from 'react-i18next'

interface IProps {
  value: GENDER[]
  onChange: (value: GENDER[]) => void
}

const GenderFocusInput: FC<IProps> = ({ value = [], onChange }) => {
  const {t} = useTranslation(['translationProfile'])
  const handleRadio = (e: any) => {
    const { value } = e.target
    onChange([value])
  }

  return (
    <Radio.Group className="qu-radio-group" name="genderFocus" value={value[0]} onChange={handleRadio}>
      <QRadio value="Male">{t("profile.partner.customer_and_locale.male")}</QRadio>
      <QRadio value="Female">{t("profile.partner.customer_and_locale.female")}</QRadio>
      <QRadio value="Both">{t("profile.partner.customer_and_locale.both")}</QRadio>
    </Radio.Group>
  )
}

export default GenderFocusInput
