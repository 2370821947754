const MODULE_NAME = 'admin/users'

export const getClientsListUrl = (): string => `${MODULE_NAME}/clients-list`
export const getAdServerClientsListUrl = (): string => `${MODULE_NAME}/ad-server-clients-list`
export const getPartnersListUrl = (): string => `${MODULE_NAME}/partners-list`
export const getUserBanUrl = (id: number): string => `${MODULE_NAME}/ban/${id}`
export const getUserUnbanUrl = (id: number): string => `${MODULE_NAME}/unban/${id}`

export const getAdminCreatClientUrl = (): string => `${MODULE_NAME}/create-client`
export const getPublishersListUrl = (): string => `${MODULE_NAME}/publishers-list`
