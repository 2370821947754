import React, { ReactElement } from 'react'
import { Col, Row } from 'quantum_components'

import CampaignGalleryForm from './CampaignGallery.form'
import CampaignGalleryList from './CampaignGalleryList'

interface IProps {
  disabledButton: boolean
}
export default function CampaignEditGallery({ disabledButton }: IProps): ReactElement {
  return (
    <div className="qu-chart-card">
      <Row gutter={0}>
        <Col xs={24} md={8}>
          <CampaignGalleryForm disabledButton={disabledButton} />
        </Col>
        <Col xs={24} md={16}>
          <CampaignGalleryList />
        </Col>
      </Row>
    </div>
  )
}
