import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useReduxDispatch } from '../../../helpers'
import { Col, Row, Radio, QAlert } from 'quantum_components'
import { useSelector } from 'react-redux'
import { getActiveTab, getBriefView, getCart } from '../brief.selectors'
import { BRIEF_PAYMENT_STATUS, BRIEF_STATUS, BRIEF_STEPS, MEDIA_TYPES } from 'src/constants/brief'
import * as actions from '../brief.actions'
import { TABS_NAMES } from '../../../constants/tabs'
import { PERMISSIONS } from '../../../constants/permission'
import { REQUEST_STATUS } from '../../../constants/request'
import { RequestListContainer } from '../../partner/requests'
import { hasPermission } from '../../../helpers/permission'
import { rejectedItems } from '../../../helpers/cart'
import { getCurrentUser, getCurrentUserPermissionActions } from '../../core/core.selectors'

import BriefEditSummaryComponent from '../../../components/BriefView/BriefEditSummary.component'

import { IBriefPayment, ICartItem, ICartServiceItem } from '../brief.types'
import { useTranslation } from 'react-i18next'

const BriefView = () => {
  const { t } = useTranslation(['translationABrief'])
  const history = useHistory()
  const dispatch = useReduxDispatch()
  const currentUser = useSelector(getCurrentUser)
  const briefView = useSelector(getBriefView)
  const cart = useSelector(getCart)
  const activeTab = useSelector(getActiveTab)
  const permissions = useSelector(getCurrentUserPermissionActions)
  const { id } = useParams<{ id: any }>()

  const paymentStepEnabled = briefView.owner?.paymentEnabled;

  const rejected = rejectedItems(cart.additional.items)

  const briefPaymentStatus = briefView.payment?.status
  const isPaymentRejected = briefPaymentStatus === BRIEF_PAYMENT_STATUS.REJECTED
  const isPaymentInReview = briefView.payment?.inReview


  const fetchSettings = async () => {
    dispatch(actions.fetchBriefSettings())
  }

  useEffect(() => {
    fetchSettings()
  }, [])

  useEffect(() => {
    dispatch(actions.fetchViewBriefDetails(id))
    dispatch(actions.fetchCart(id))
  }, [id])

  const setCurrentStep = (step: BRIEF_STEPS) => {
    if (window.location.pathname.includes('/invitedClients/briefs')) history.push(`/invitedClients/briefs/edit/${id}`)
    else history.push(`/briefs/edit/${id}`)
    dispatch(actions.setNeedCheckLastStep(false))
    dispatch(actions.setCurrentStep(step))
  }

  const setActivePartner = async (partnerId: number) => {
    await dispatch(actions.fetchPartnerDetails(id, partnerId))
    await dispatch(actions.setActiveSinglePartner(partnerId))
  }

  const openPartner = async (partnerId: number) => {
    setCurrentStep(BRIEF_STEPS.PARTNER)
    await setActivePartner(partnerId)
  }

  const handleChangeTab = (e: any) => {
    dispatch(actions.setActiveTab(e.target.value))
  }

  const getRejectedMessage = (companyName: string, type: string, rejectionReason: string | undefined) => {
    const reason = rejectionReason ? ` ${t(`due_to`)} “${rejectionReason}”` : ''

    return (
      <>
        {`${companyName} rejected ${type}${reason}. `}
        <span className={'button--link'} onClick={() => history.push(`/briefs/rejected/${id}`)}>
          {t(`find_another_partner`)}
        </span>{' '}
        {t(`and_submit_your_brief`)}.
      </>
    )
  }

  const getPaymentRejectionMessage = (payment: IBriefPayment) => {
    const fileType = payment.bankTransfer ? t(`bank_receipt`) : t(`po`)
    const reason = t(`${payment.reason}`)
    return t(`payment_rejection`, { type: fileType, reason })
  }

  return (
    <React.Fragment>
      <Row gutter={16}>
        <Col span={8}>
          <Radio.Group className="account-selector qu-radio-group full-width" buttonStyle="solid" value={activeTab}>
            <Radio.Button
              disabled={!hasPermission(permissions, PERMISSIONS.BRIEF_VIEW)}
              className="qu-radio-button qu-radio-blue"
              value={TABS_NAMES.BRIEF}
              onChange={handleChangeTab}
            >
              <span className="text-uppercase">{t(`brief`)}</span>
            </Radio.Button>
            <Radio.Button
              disabled={!hasPermission(permissions, PERMISSIONS.REQUEST_DETAILS)}
              className="qu-radio-button qu-radio-blue"
              value={TABS_NAMES.REQUESTS}
              onChange={handleChangeTab}
            >
              <span className="text-uppercase">{t(`requests`)}</span>
            </Radio.Button>
          </Radio.Group>
        </Col>
      </Row>

      {briefView?.status === BRIEF_STATUS.PENDING && briefView?.payment?.status === BRIEF_PAYMENT_STATUS.PENDING &&
        <Row gutter={20} className={'pt-20 action-needed-alerts'}>
          <Col span={24}>
            <QAlert description={
              <>
                <strong>{t('Action Needed:')} </strong>
                <span>{t('Approve/Reject this campaign request from the Requests Tab')}</span>
              </>
            } type="warning" className="centered" />
          </Col>
        </Row>}

      {briefView?.payment?.status === BRIEF_PAYMENT_STATUS.PENDING && (briefView?.payment?.bankTransfer || briefView?.payment?.po) &&
        <Row gutter={20} className={'pt-20 action-needed-alerts'}>
          <Col span={24}>
            <QAlert description={
              <>
                <strong>{t('Action Needed:')} </strong>
                <span>{t('Approve/Reject the payment request from the Brief Tab')}</span>
              </>
            } type="warning" className="centered" />
          </Col>
        </Row>}

      {(briefView?.payment?.status === BRIEF_PAYMENT_STATUS.PAID || briefView?.payment?.status === BRIEF_PAYMENT_STATUS.PO_ISSUED) && currentUser?.workflowEnabled && (!briefView.workflow || !briefView.workflow.length) &&
        <Row gutter={20} className={'pt-20 action-needed-alerts'}>
          <Col span={24}>
            <QAlert description={
              <>
                <strong>{t('Action Needed:')} </strong>
                <span>{t('Assign Workflow to this campaign from the Requests Tab')}</span>
              </>
            } type="warning" className="centered" />
          </Col>
        </Row>}

      {activeTab === TABS_NAMES.BRIEF && (
        <div className="qu-brief-view mb-40">
          {rejected.map((item: ICartItem) => {
            const distributions = item.services.filter(
              (service: ICartServiceItem) =>
                service.type === MEDIA_TYPES.DISTRIBUTION && service.requestStatus === REQUEST_STATUS.REJECTED
            )
            const mediaBuys = item.services.filter(
              (service: ICartServiceItem) =>
                service.type !== MEDIA_TYPES.DISTRIBUTION && service.requestStatus === REQUEST_STATUS.REJECTED
            )

            return (
              <React.Fragment key={item.partnerId}>
                {!isPaymentRejected && distributions.length > 0 && (
                  <Row gutter={20} className={'pt-10'}>
                    <Col span={24}>
                      <QAlert
                        message={getRejectedMessage(item.companyName, 'Distribution', distributions[0].rejectionReason)}
                        type="warning"
                        className="centered"
                      />
                    </Col>
                  </Row>
                )}
                {!isPaymentRejected && mediaBuys.length > 0 && (
                  <Row gutter={20} className={'pt-10'}>
                    <Col span={24}>
                      <QAlert
                        message={getRejectedMessage(item.companyName, 'Media Buy', mediaBuys[0].rejectionReason)}
                        type="warning"
                        className="centered"
                      />
                    </Col>
                  </Row>
                )}
              </React.Fragment>
            )
          })}

          {isPaymentRejected && !isPaymentInReview && (
            <Row gutter={20} className={'pt-10'}>
              <Col span={24}>
                <QAlert message={getPaymentRejectionMessage(briefView.payment)} type="warning" className="centered" />
              </Col>
            </Row>
          )}

          <BriefEditSummaryComponent
            briefView={briefView}
            cart={cart}
            setCurrentStep={setCurrentStep}
            openPartner={openPartner}
            showReviewPaymentSection={paymentStepEnabled}
            hidePaymentSection={!paymentStepEnabled}
            hideProposalButtons
          />
        </div>
      )}

      {activeTab === TABS_NAMES.REQUESTS && (
        <div className="mt-30">
          <RequestListContainer />
        </div>
      )}
    </React.Fragment>
  )
}

export default BriefView
