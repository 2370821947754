import { createSelector } from 'reselect'
import {
  IChartDataObjectDateValue,
  IChartDataObjectNameKey,
  IChartDataObjectNameValue,
  IDashboardData,
  IDashboardFilterParams,
  IDashboardParams,
  IState,
} from './dashboard.types'
import { MODULE_NAME } from './dashboard.constants'
import { CHART_TYPES } from '../../constants/dashboard'
import moment from 'moment'
import { FORMATS } from '../../constants'

const selectState = (state: { [MODULE_NAME]: IState }): IState => state[MODULE_NAME]

export const getParams = createSelector(selectState, (state: IState): IDashboardFilterParams => state.params)

export const getChartData = createSelector(selectState, (state: IState): IDashboardData | null => state.chartData)

export const getChartDataLoading = createSelector(selectState, (state: IState): boolean => state.chartLoading)

export const getSignUpChartClientData = createSelector(selectState, (state: IState): IChartDataObjectNameKey[] => {
  if (state.chartData) {
    const data = state.chartData[CHART_TYPES.SIGNUP_REQUESTS_CLIENT]
    if (!data) return []
    const mapped = Object.keys(data).map((key: string) => ({
      name: moment(key).format(FORMATS.DATE_LL_FORMAT),
      //@ts-ignore
      key: data[key],
    }))

    return mapped
  }

  return []
})

export const getSignUpChartPartnerData = createSelector(selectState, (state: IState): IChartDataObjectNameKey[] => {
  if (state.chartData) {
    const data = state.chartData[CHART_TYPES.SIGNUP_REQUESTS_PARTNER]
    if (!data) return []
    const mapped = Object.keys(data).map((key: string) => ({
      name: moment(key).format(FORMATS.DATE_LL_FORMAT),
      //@ts-ignore
      key: data[key],
    }))

    return mapped
  }

  return []
})

export const getPartnersChartData = createSelector(selectState, (state: IState): IChartDataObjectNameKey[] => {
  if (state.chartData) {
    const data = state.chartData[CHART_TYPES.APPROVED_PARTNERS]
    if (!data) return []
    const mapped = Object.keys(data).map((key: string) => ({
      name: moment(key).format(FORMATS.DATE_LL_FORMAT),
      //@ts-ignore
      key: data[key],
    }))

    return mapped
  }

  return []
})

export const getClientsChartData = createSelector(selectState, (state: IState): IChartDataObjectNameKey[] => {
  if (state.chartData) {
    const data = state.chartData[CHART_TYPES.APPROVED_CLIENTS]

    if (!data) return []
    const mapped = Object.keys(data).map((key: string) => ({
      name: moment(key).format(FORMATS.DATE_LL_FORMAT),
      //@ts-ignore
      key: data[key],
    }))

    return mapped
  }

  return []
})

export const getDistActiveChartData = createSelector(selectState, (state: IState): IChartDataObjectNameKey[] => {
  if (state.chartData) {
    const data = state.chartData[CHART_TYPES.SAMPLE_DISTRIBUTION_ACTIVE]

    if (!data) return []
    const mapped = data.map((item: IChartDataObjectDateValue) => ({
      name: moment(item.date).format(FORMATS.DATE_LL_FORMAT),
      key: item.value ? +item.value : 0,
    }))

    return mapped
  }

  return []
})

export const getDistCompleteChartData = createSelector(selectState, (state: IState): IChartDataObjectNameKey[] => {
  if (state.chartData) {
    const data = state.chartData[CHART_TYPES.SAMPLE_DISTRIBUTION_COMPLETE]

    if (!data) return []
    const mapped = data.map((item: IChartDataObjectDateValue) => ({
      name: moment(item.date).format(FORMATS.DATE_LL_FORMAT),
      key: item.value ? +item.value : 0,
    }))

    return mapped
  }

  return []
})

export const getRevenueChartData = createSelector(selectState, (state: IState): IChartDataObjectNameValue[] => {
  if (state.chartData) {
    const data = state.chartData[CHART_TYPES.REVENUE]

    if (!data) return []
    const mapped = data.map((item: IChartDataObjectDateValue) => ({
      name: moment(item.date).format(FORMATS.DATE_LL_FORMAT),
      value: item.value ? item.value : 0,
    }))

    return mapped
  }

  return []
})

export const getBriefChartData = createSelector(selectState, (state: IState): IChartDataObjectNameValue[] => {
  if (state.chartData) {
    const data = state.chartData[CHART_TYPES.BRIEFS]

    if (!data) return []
    const mapped = data.map((item: IChartDataObjectDateValue) => ({
      name: moment(item.date).format(FORMATS.DATE_LL_FORMAT),
      value: item.value ? item.value : 0,
    }))

    return mapped
  }

  return []
})

export const getCampaignChartData = createSelector(selectState, (state: IState): IChartDataObjectNameValue[] => {
  if (state.chartData) {
    const data = state.chartData[CHART_TYPES.CAMPAIGNS]

    if (!data) return []
    const mapped = data.map((item: IChartDataObjectDateValue) => ({
      name: moment(item.date).format(FORMATS.DATE_LL_FORMAT),
      value: item.value ? item.value : 0,
    }))

    return mapped
  }

  return []
})

export const getTransactionChartData = createSelector(selectState, (state: IState): IChartDataObjectNameKey[] => {
  if (state.chartData) {
    const data = state.chartData[CHART_TYPES.TRANSACTIONS]

    if (!data) return []
    const mapped = data.map((item: IChartDataObjectDateValue) => ({
      name: moment(item.date).format(FORMATS.DATE_LL_FORMAT),
      key: item.value ? item.value : 0,
    }))

    return mapped
  }

  return []
})

export const getActiveUsersChartData = createSelector(selectState, (state: IState): IChartDataObjectNameValue[] => {
  if (state.chartData) {
    const data = state.chartData[CHART_TYPES.ACTIVE_USERS]

    if (!data) return []
    const mapped = [
      { name: 'Admin', value: data.admin },
      { name: 'Client', value: data.client },
      { name: 'Partner', value: data.partner },
    ]
    return mapped
  }

  return []
})

export const getConvertedCampaignsChartData = createSelector(
  selectState,
  (state: IState): IChartDataObjectNameValue[] => {
    if (state.chartData) {
      const data = state.chartData[CHART_TYPES.BRIEF_CONVERSION]

      if (!data) return []
      const mapped = [
        { name: 'Total', value: data.allBriefs },
        { name: 'Converted', value: data.briefsToCampaigns },
      ]
      return mapped
    }

    return []
  }
)

export const getServiceTypeChartData = createSelector(selectState, (state: IState): IChartDataObjectNameValue[] => {
  if (state.chartData) {

    const data = state.chartData[CHART_TYPES.CAMPAIGN_TYPE]

    if (!data) return []

    let mapped = data.data.map((d:any)=>{
      return {name:d.name,value:d.count}
    })

    return mapped
  }

  return []
})

export const getCampaignCompletionChartData = createSelector(
  selectState,
  (state: IState): IChartDataObjectNameValue[] => {
    if (state.chartData) {
      const data = state.chartData[CHART_TYPES.CAMPAIGN_COMPLETION]

      if (!data) return []
      const mapped = [
        { name: 'In time', value: data.inTime },
        { name: 'Not in time', value: data.notInTime },
      ]
      return mapped
    }

    return []
  }
)
