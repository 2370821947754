import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import LanguageSwitcher from "src/components/LanguageSwitcher";
import i18n from "src/i18n";
import { getSAASPartnerDetails } from "src/modules/core/core.actions";
import { ReactComponent as Qlogo } from "../../../assets/quantum-logo.svg";

interface Props {
  children: React.ReactNode;
}

const AuthLayout = ({ children }: Props) => {
  const dispatch = useDispatch();
  const [partner, setPartner] = useState<any>({});

  useEffect(() => {
    const details = async () => {
      const data = await dispatch(getSAASPartnerDetails());
      setPartner(data);
    };
    details();
  }, []);

  return (
    <div className={`layout auth ${i18n.dir() === 'rtl'?'html-rtl':''}`}>
      <div className="auth-section">
        <div className="auth-section-header">
          {/* <Qlogo /> */}
          <div className="qu-logo-new-container">
            <img
              src={
                partner?.publicProfile?.companyLogo
              }
              className="qu-logo-new"
            />
          </div>
        </div>
        {children}
      </div>
      
    </div>
  );
};

export default AuthLayout;
