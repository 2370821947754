import { createSelector } from 'reselect'
import { MODULE_NAME } from './workflow.constants'
import { IState } from './workflow.types'

const selectState = (state: { [MODULE_NAME]: any }) => state[MODULE_NAME]

export const getDataLoading = createSelector(
  selectState,
  (state: IState): boolean => state.loading
)

export const getAllWorkflows = createSelector(selectState, (state: IState): any => state.data)
