import React, { useEffect, useState } from 'react'
import { QFileUploader, qnotification } from 'quantum_components'

import { ALERT_BRIEF_UPLOAD_MAX } from 'src/constants/alerts'
import IUploadFile from 'src/interfaces/IUploadFile'
import ILocalFile from 'src/interfaces/ILocalFile'
import NOTIFICATION_TYPES from 'src/constants/notificationTypes'
import { validateApplication, validateImage, validateVideo } from 'src/helpers/validation'
import { useTranslation } from 'react-i18next'

interface Props {
  className?: string
  fileName?: string
  files: IUploadFile[] | any
  onChange?: (file: File[]) => void
  onRemove?: (file: ILocalFile | IUploadFile) => void
  onView?: (file: ILocalFile | IUploadFile) => void
  maxCount?: number
  viewOnly?: boolean
  viewOnlyText?: string
  isUploading?: boolean
  isRemoving?: boolean
  multiple?: boolean
  disabled?: boolean
  alternativeFileView?: boolean
  showRemoveButton?: boolean
  showPreview?: boolean
  disableView?: boolean
  disableRemove?: boolean
  caption?: string[]
  heading?: string
  accept?: string
  listType?: string
  isVideo?: boolean
  imageFormat?: string
  defaultFileList?: any[]
  isTestahel?: boolean
  dragAndDrop?: boolean
}

const UploadComponent = ({
  className = '',
  fileName = '',
  files,
  onChange = () => { },
  onRemove = () => { },
  onView = () => { },
  maxCount = 1,
  viewOnly = false,
  viewOnlyText = 'View',
  isUploading = false,
  isRemoving = false,
  multiple = false,
  disabled = false,
  alternativeFileView = false,
  showRemoveButton = true,
  showPreview = true,
  disableView = false,
  disableRemove = false,
  dragAndDrop = true,
  imageFormat = '',
  caption = [],
  heading = 'Upload Image',
  accept = '.jpg, .jpeg, .png, .gif',
  listType = 'picture',
  isVideo = false,
  defaultFileList = [],
  isTestahel = false,
}: Props) => {
  const { t } = useTranslation(['translationProfile'])
  caption = caption ?? [t(`profile.public.maximum_dimension`), t(`profile.public.max_file_size`)]
  // heading = heading ? heading : t(`profile.public.upload_image`)
  heading = "Upload Media"

  const [uploads, setUploads] = useState<File[]>([])
  let filesCache = [] as File[]

  useEffect(() => {
    if (!uploads.length) {
      return
    }

    const validatedFiles = uploads.filter((file: File) => {
      if (isVideo) {
        return validateVideo(file, fileName)
      }

      const generalFileType = file.type.split('/')[0]
      switch (generalFileType) {
        case 'video':
          return validateVideo(file, fileName)
        case 'image':
          return validateImage(file)
        case 'application':
          return validateApplication(file)
        default:
          return true
      }
    })

    if (!validatedFiles.length) {
      return
    }

    const sliced = validatedFiles.slice(0, maxCount - files.length)

    if (isTestahel) {
      if (!sliced.length) {
        qnotification({ type: NOTIFICATION_TYPES.ERROR, message: t(`profile.public.max_upload_count_err`, { maxCount }) })
      } else if (uploads.length !== sliced.length) {
        qnotification({ type: NOTIFICATION_TYPES.ERROR, message: t(`profile.public.max_upload_count_err`, { maxCount }) })
      }
      onChange(validatedFiles)
    } else {
      if (!sliced.length) {
        qnotification({ type: NOTIFICATION_TYPES.ERROR, message: t(`profile.public.max_upload_count_err`, { maxCount }) })
        return
      }
      onChange(sliced)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploads])

  const handleChange = (info: any) => {
    if (info.file.status === 'removed') {
      return
    }

    filesCache = [...filesCache, info.file]

    setUploads(filesCache)
  }

  const handleRemove = (file: IUploadFile) => {
    onRemove(file)
  }

  const getProps = () => {
    const multipleUploadProps = {
      fileName,
      accept,
      listType,
      fileList: files,
      dragAndDrop,
      viewOnly,
      viewOnlyText,
      showUploadList: dragAndDrop
        ? {
          removeIcon: <span>{t(`profile.public.remove`)}</span>,
        }
        : false,
      multiple,
      maxCount,
      onChange: handleChange,
      onRemove: handleRemove,
      beforeUpload: () => false,
      onView,
      isUploading,
      isRemoving,
      disabled,
      alternativeFileView,
      showRemoveButton,
      showPreview,
      disableView,
      disableRemove,
      caption,
      heading,
      defaultFileList,
    }

    const singleUploadProps = {
      fileName,
      accept,
      dragAndDrop,
      viewOnly,
      viewOnlyText,
      fileList: files,
      image: files[0],
      showUploadList: false,
      multiple,
      maxCount,
      onChange: handleChange,
      onRemove: handleRemove,
      beforeUpload: () => false,
      onView,
      isUploading,
      isRemoving,
      disabled,
      alternativeFileView,
      showRemoveButton,
      showPreview,
      disableView,
      disableRemove,
      caption,
      heading,
      imageFormat,
    }
    return multiple ? multipleUploadProps : singleUploadProps
  }

  const uploadProps = getProps()

  // @ts-ignore
  return <QFileUploader className={className} {...uploadProps} />
}

export default UploadComponent
