export const MODULE_NAME = 'core'

/* actions */
export const SET_PROGRESS = `${MODULE_NAME}/SET_PROGRESS`
export const RESET_PROGRESS = `${MODULE_NAME}/RESET_PROGRESS`

export const LOGOUT_REQUEST = `${MODULE_NAME}/LOGOUT_REQUEST`
export const LOGOUT_SUCCESS = `${MODULE_NAME}/LOGOUT_SUCCESS`
export const LOGOUT_FAILURE = `${MODULE_NAME}/LOGOUT_FAILURE`

export const SET_SERVER_ERROR = `${MODULE_NAME}/SET_SERVER_ERROR`
export const RESET_SERVER_ERROR = `${MODULE_NAME}/RESET_SERVER_ERROR`

export const UPLOAD_REQUEST = `${MODULE_NAME}/UPLOAD_REQUEST`
export const UPLOAD_SUCCESS = `${MODULE_NAME}/UPLOAD_SUCCESS`
export const UPLOAD_FAILURE = `${MODULE_NAME}/UPLOAD_FAILURE`

export const DOWNLOAD_REQUEST = `${MODULE_NAME}/DOWNLOAD_REQUEST`
export const DOWNLOAD_SUCCESS = `${MODULE_NAME}/DOWNLOAD_SUCCESS`
export const DOWNLOAD_FAILURE = `${MODULE_NAME}/DOWNLOAD_FAILURE`

export const FETCH_CURRENCIES_SUCCESS = `${MODULE_NAME}/FETCH_CURRENCIES_SUCCESS`
export const FETCH_CURRENCIES_FAILURE = `${MODULE_NAME}/FETCH_CURRENCIES_FAILURE`

export const GET_CURRENT_USER_REQUEST = `${MODULE_NAME}/GET_CURRENT_USER_REQUEST`
export const GET_CURRENT_USER_SUCCESS = `${MODULE_NAME}/GET_CURRENT_USER_SUCCESS`
export const GET_CURRENT_USER_FAILURE = `${MODULE_NAME}/GET_CURRENT_USER_FAILURE`

export const CURRENT_USER_PERMISSION_REQUEST = `${MODULE_NAME}/CURRENT_USER_PERMISSION_REQUEST`
export const CURRENT_USER_PERMISSION_SUCCESS = `${MODULE_NAME}/CURRENT_USER_PERMISSION_SUCCESS`
export const CURRENT_USER_PERMISSION_FAILURE = `${MODULE_NAME}/CURRENT_USER_PERMISSION_FAILURE`

export const SET_ACTIVE_MENU = `${MODULE_NAME}/SET_ACTIVE_MENU`

export const CONSTANTS_REQUEST = `${MODULE_NAME}/CONSTANTS_REQUEST`
export const CONSTANTS_SUCCESS = `${MODULE_NAME}/CONSTANTS_SUCCESS`
export const CONSTANTS_FAILURE = `${MODULE_NAME}/CONSTANTS_FAILURE`

export const SETTINGS_REQUEST = `${MODULE_NAME}/SETTINGS_REQUEST`
export const SETTINGS_SUCCESS = `${MODULE_NAME}/SETTINGS_SUCCESS`
export const SETTINGS_FAILURE = `${MODULE_NAME}/SETTINGS_FAILURE`

export const GET_UNREAD_CUSTOM_NOTIFICATION_REQUEST = `${MODULE_NAME}/GET_UNREAD_CUSTOM_NOTIFICATION_REQUEST`
export const GET_UNREAD_CUSTOM_NOTIFICATION_SUCCESS = `${MODULE_NAME}/GET_UNREAD_CUSTOM_NOTIFICATION_SUCCESS`
export const GET_UNREAD_CUSTOM_NOTIFICATION_FAILURE = `${MODULE_NAME}/GET_UNREAD_CUSTOM_NOTIFICATION_FAILURE`

export const GET_SUBSCRIPTION_OVERDUE_PAYMENT_REQUEST = `${MODULE_NAME}/GET_SUBSCRIPTION_OVERDUE_PAYMENT_REQUEST`
export const GET_SUBSCRIPTION_OVERDUE_PAYMENT_SUCCESS = `${MODULE_NAME}/GET_SUBSCRIPTION_OVERDUE_PAYMENT_SUCCESS`
export const GET_SUBSCRIPTION_OVERDUE_PAYMENT_FAILURE = `${MODULE_NAME}/GET_SUBSCRIPTION_OVERDUE_PAYMENT_FAILURE`

export const PAY_SUBSCRIPTION_PAYMENT_REQUEST = `${MODULE_NAME}/PAY_SUBSCRIPTION_PAYMENT_REQUEST`
export const PAY_SUBSCRIPTION_PAYMENT_SUCCESS = `${MODULE_NAME}/PAY_SUBSCRIPTION_PAYMENT_SUCCESS`
export const PAY_SUBSCRIPTION_PAYMENT_FAILURE = `${MODULE_NAME}/PAY_SUBSCRIPTION_PAYMENT_FAILURE`
