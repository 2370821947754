import React, { ReactElement } from 'react'
import { IBrief } from '../../brief.types'
import BriefSummary from './Brief.summary'
import { Popover } from 'quantum_components'

export interface IProps {
  brief: IBrief
}

export default function PopoverSummary({ brief }: IProps): ReactElement {
  const listIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M7,5H21V7H7V5M7,13V11H21V13H7M4,4.5A1.5,1.5 0 0,1 5.5,6A1.5,1.5 0 0,1 4,7.5A1.5,1.5 0 0,1 2.5,6A1.5,1.5 0 0,1 4,4.5M4,10.5A1.5,1.5 0 0,1 5.5,12A1.5,1.5 0 0,1 4,13.5A1.5,1.5 0 0,1 2.5,12A1.5,1.5 0 0,1 4,10.5M7,19V17H21V19H7M4,16.5A1.5,1.5 0 0,1 5.5,18A1.5,1.5 0 0,1 4,19.5A1.5,1.5 0 0,1 2.5,18A1.5,1.5 0 0,1 4,16.5Z"
      />
    </svg>
  )

  return (
    <Popover
      overlayClassName="qu-summary-popover"
      content={<BriefSummary brief={brief} />}
      placement="bottomRight"
      trigger="click"
      arrowPointAtCenter
    >
      <div className="qu-summary-button">{listIcon}</div>
    </Popover>
  )
}
