import React, { ReactElement, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getBriefSettings } from "../../brief.selectors";
import {
  Col,
  Form,
  QButton,
  QCheckbox,
  QRadio,
  QSelect,
  Row,
} from "quantum_components";
import { Select } from "antd";
import {
  BRIEF_STEPS,
  BRIEF_STEPS_NAMES,
  GENDER,
  TARGETING_TOGGLES,
} from "../../../../constants/brief";
import {
  IBrief,
  ICity,
  ICountry,
  IOptionForSelect,
  ITargetingStep,
  ITargettingToggle,
} from "../../brief.types";
import { isMobile } from "react-device-detect";

import { ReactComponent as LogOut } from "src/assets/icons/logout-icon-alt.svg";
// import PopoverSummary from "../components/Brief.PopoverSummary";
// import { AgeGroupCard } from "../components/Brief.AgeGroupCard";
// import { first } from "lodash";
// import {
//   filterCountries,
//   validateCountriesAndCities,
// } from "../../../../helpers/validation";
import { useTranslation } from "react-i18next";
// import { LOCALIZATION_LANGUAGES } from "src/constants";

// interface TargetingErrors {
//   gender: string;
//   country: string;
//   ageGroup: string;
// }

// interface IAgeGroup {
//   value: string;
//   label: string;
//   sublabel: string;
//   img: string;
// }

// type CheckboxFields = "gender" | "country" | "ageGroup";

interface IProps {
  brief: IBrief;
  targetingFields: ITargetingStep;
  onChange: (targetingFields: ITargetingStep) => void;
  onFinishLater: () => void;
  disabledFinishLater: boolean;
}

export default function TargetingForm({
  brief,
  targetingFields,
  onChange,
  onFinishLater,
  disabledFinishLater,
}: IProps): ReactElement {
  // const { i18n } = useTranslation();
  const [newFields,setNewFields] = useState<any>({})
  useEffect(()=>{
    if(!targetingFields) return
    const updatedFields:any = {...targetingFields};

    const newCustomTargets:any = {}
    
    settings.targetingKeys.forEach((tk:any)=>{
      newCustomTargets[tk.name] = updatedFields.customTargets[tk.name] ?? undefined
    })

    setNewFields({...updatedFields,customTargets:newCustomTargets});
  },[targetingFields])

  const { t } = useTranslation(["translationABrief", "translationProfile"]);
  const settings = useSelector(getBriefSettings);
  const [showOverlay, setShowOverlay] = useState(true);

  const overlay = () => {
    return (
      <>
        <div className="overlay-container">
          <div className="overlay-content">
            <QButton
              className="qu-button-outline"
              disabled={false}
              onClick={() => {
                return;
              }}
              type="primary"
            >
              {t(`coming_soon`)}
            </QButton>
          </div>
        </div>
      </>
    );
  };

  useEffect(() => {
    if (settings?.targetingKeys?.length < 1) {
      setShowOverlay(true);
    }else{
      setShowOverlay(false);
    }
  }, [settings]);

  const handleSelectChange = (values:string[],key:string) => {
    // const customTargets:any = {...targetingFields.customTargets,[key]:values};
    // const fields = {...targetingFields,customTargets}
    // onChange(fields)

    let updatedValues:any = [];
    if(values.includes('Select All') && values.includes('Un-select All')){
      updatedValues = values.filter(v=>v!=='Select All' && v!=='Un-select All')
    }else if(values.includes('Select All')){
      updatedValues = settings.targetingKeys.filter((a:any)=>a.name===key)[0].options
    }
    else if(values.includes('Un-select All')){
      updatedValues = []
    }else{
      updatedValues = values
    }
    
    const customTargets: any = {
      ...newFields.customTargets,
      [key]: updatedValues,
    };
    const fields = { ...newFields, customTargets};
    
    onChange(fields);
  }

  return (
    <>
      {showOverlay && overlay()}

      <Form layout="vertical" hideRequiredMark>
        <div className={`form form-brief form--horizontal`}>
          <div className="form__head">
            <div className="form__number">
              {isMobile ? (
                <span>{`2. ${
                  "stepper." + BRIEF_STEPS_NAMES[BRIEF_STEPS.TARGETING]
                }`}</span>
              ) : (
                "2"
              )}
            </div>
            <h3>{t(`who_do_you_want_to_target`)}</h3>
          </div>
          <div className="form__cont position_relative">
            {settings.targetingKeys.map((targetingKey: any) => {
              return (
                <>
                  <Row gutter={16} align="middle" key={targetingKey.name}>
                    <Col xs={24} sm={24} md={10}>
                      <label htmlFor={targetingKey.name} className="form-label">
                        {targetingKey.name}
                      </label>
                    </Col>
                    <Col xs={24} sm={24} md={14}>
                    <QSelect
                    className="w-100-percent"
                          value={
                            // @ts-ignore
                            targetingFields?.customTargets && targetingFields?.customTargets[targetingKey.name]
                          }
                          // defaultValue={targetingFields?.customTargets && targetingFields?.customTargets[targetingKey.name]}
                          onChange={(values:string[])=>handleSelectChange(values,targetingKey.name)}
                          showSearch
                          showArrow
                          maxTagTextLength={14}
                          maxTagCount={2}
                          mode="multiple"
                          size="large"
                          placeholder={`select ${targetingKey.name}`}
                          options={[{
                            id: 'select_all',
                            label: t('Select All'),
                            value: 'Select All',
                          },{
                            id: 'un_select_all',
                            label: t('Un-select All'),
                            value: 'Un-select All',
                          },...targetingKey?.options?.map((option:any)=>{
                            return {
                              id:option,
                              label:option,
                              value:option
                            }
                          })]}
                        />
                    </Col>
                  </Row>
                  <hr className="form__hr" />
                </>
              );
            })}
          </div>
          {isMobile && (
            <div className="form-brief-footer">
              <QButton
                disabled={disabledFinishLater}
                onClick={onFinishLater}
                className="qu-button-outline mt-30 full-width"
              >
                {t(`save_and_finish_later`)} <LogOut className="ml-10" />
              </QButton>
            </div>
          )}
        </div>
      </Form>
    </>
  );
}

// const [form] = Form.useForm()
// const [errors, setErrors] = useState<TargetingErrors>({ gender: '', country: '', ageGroup: '' } as TargetingErrors)
// const [countries, setCountries] = useState<ICountry[]>([])
// const [cityError, setCityError] = useState<boolean>(false)

// const [showGender, setShowGender] = useState<boolean>(false);
// const [showAgeGroup, setShowAgeGroup] = useState<boolean>(false);
// const [showLocation, setShowLocation] = useState<boolean>(false);

// const { Option, OptGroup } = Select

// useEffect(() => {
//   if (settings.targetingToggles.length > 0) {
//     setShowGender(
//       settings.targetingToggles.filter((item: ITargettingToggle) => {
//         return item.control_name === TARGETING_TOGGLES.GENDER;
//       })[0].control_toggle
//     );
//     setShowAgeGroup(
//       settings.targetingToggles.filter((item: ITargettingToggle) => {
//         return item.control_name === TARGETING_TOGGLES.AGE_GROUP;
//       })[0].control_toggle
//     );
//     setShowLocation(
//       settings.targetingToggles.filter((item: ITargettingToggle) => {
//         return item.control_name === TARGETING_TOGGLES.LOCATION;
//       })[0].control_toggle
//     );
//   }
// }, [settings]);

// useEffect(() => {
//   form.setFieldsValue(targetingFields)
// })

// useEffect(() => {
//   const filtered = filterCountries(targetingFields.targetCountries, settings.countries)
//   setCountries(filtered)
// }, [targetingFields])

// const handleGender = (e: any) => {
//   const fields = { ...targetingFields }
//   const { id } = e.target
//   fields.targetFocus = [id]
//   validateCheckboxes(fields.targetFocus, 'gender')
//   onChange(fields)
// }

// const validateCheckboxes = (items: any[], fieldName: CheckboxFields) => {
//   const name = fieldName === 'ageGroup' ? 'age group' : fieldName
//   const newErrors = { ...errors }
//   const msg = items.length > 0 ? '' : t(`min_target`, { name })
//   newErrors[fieldName] = msg
//   setErrors(newErrors)
// }

// const handleCountry = (e: any) => {
//   const fields = { ...targetingFields }
//   const { id } = e.target

//   const countries = fields.targetCountries
//   countries.includes(id) ? countries.splice(countries.indexOf(id), 1) : countries.push(id)

//   const countriesObj = settings.countries.filter((c: ICountry) => countries.includes(c.name))
//   if (!countriesObj.length) {
//     fields.targetCities = []
//   }

//   const selectedCountryCities = countriesObj.map((c: ICountry) => c.city.map((c: ICity) => c.name)).flat()
//   fields.targetCities = fields.targetCities.filter((c: string) => selectedCountryCities.includes(c))

//   validateCheckboxes(countries, 'country')
//   const isValid = validateCountriesAndCities(fields, settings.countries)
//   setCityError(!isValid)

//   onChange(fields)
// }

// const handleCityChange = (values: string[], cities: any[]) => {
//   const fields = { ...targetingFields }

//   const countries = cities.map(c => c.country)
//   const uniqueCountries = countries.filter((v, i, a) => a.indexOf(v) === i)

//   fields.targetCities = values
//   fields.targetCountries = uniqueCountries

//   validateCheckboxes(fields.targetCountries, 'country')
//   const isValid = validateCountriesAndCities(fields, settings.countries)
//   setCityError(!isValid)

//   onChange(fields)
// }

// const handleAgeGroup = (e: any, name: string) => {
//   const fields = { ...targetingFields }
//   const items = fields.targetAgeGroups
//   items.includes(name) ? items.splice(items.indexOf(name), 1) : items.push(name)
//   validateCheckboxes(items, 'ageGroup')

//   onChange(fields)
// }

// const handleSegmentsChange = (values: string[]) => {
//   const fields = { ...targetingFields }
//   fields.targetSegments = values
//   onChange(fields)
// }

// const segments: IOptionForSelect[] = settings.targetSegments.map((type: any) => {
//   return { id: 'targetSegments', label: type.name, value: type.name }
// })

// const ageGroups = () => {
//   const reducer = (s: any) => {
//     const value = s.from + (s.to ? '-' + s.to + ' ' + s.name : '+ ' + s.name)
//     const sublabel = s.from + (s.to ? '-' + s.to : '+')
//     const label = i18n.language === LOCALIZATION_LANGUAGES.ARABIC ? s.name_ar : s.name
//     const img = s.src

//     return { label, sublabel, value, img } as IAgeGroup
//   }

//   return settings.ageGroups.map((group: any) => reducer(group))
// }

// const showOverlay = !showGender && !showAgeGroup && !showLocation

// return (
//   <Form
//     form={form}
//     layout="vertical"
//     hideRequiredMark
//     initialValues={targetingFields}
//   >
//     <div className={`form form-brief form--horizontal`}>
//       <div className="form__head">
//         <div className="form__number">
//           {isMobile ? (
//             <span>{`2. ${'stepper.' + BRIEF_STEPS_NAMES[BRIEF_STEPS.TARGETING]}`}</span>
//           ) : (
//             "2"
//           )}
//         </div>
//         <h3>{t(`who_do_you_want_to_target`)}</h3>
//       </div>
//       <PopoverSummary brief={brief} />
//       <div className="form__cont position_relative">
//         {showOverlay && (
//           <div className="overlay-container">
//             <div className="overlay-content">
//               <QButton
//                 className="qu-button-outline"
//                 disabled={false}
//                 onClick={() => {
//                   return;
//                 }}
//                 type="primary"
//               >
//                 {t(`coming_soon`)}
//               </QButton>
//             </div>
//           </div>
//         )}

//         {(showGender || showOverlay) && (
//           <>
//             <Row gutter={16} align="middle">
//               <Col xs={24} sm={24} md={10}>
//                 <label htmlFor="targetFocus" className="form-label">
//                   {t(`gender_focus`)}
//                 </label>
//               </Col>
//               <Col xs={24} sm={24} md={14}>
//                 <Row gutter={16} align="middle">
//                   <Col flex="auto">
//                     <Form.Item className="mb-0" name={GENDER.FEMALE}>
//                       <QRadio
//                         className="mr-0"
//                         onChange={handleGender}
//                         checked={
//                           first(targetingFields.targetFocus) === GENDER.FEMALE
//                         }
//                       >
//                         {t(GENDER.FEMALE)}
//                       </QRadio>
//                     </Form.Item>
//                   </Col>
//                   <Col flex="auto">
//                     <Form.Item className="mb-0" name={GENDER.MALE}>
//                       <QRadio
//                         className="mr-0"
//                         onChange={handleGender}
//                         checked={
//                           first(targetingFields.targetFocus) === GENDER.MALE
//                         }
//                       >
//                         {t(GENDER.MALE)}
//                       </QRadio>
//                     </Form.Item>
//                   </Col>
//                   <Col flex="auto">
//                     <Form.Item className="mb-0" name={GENDER.BOTH}>
//                       <QRadio
//                         className="mr-0"
//                         onChange={handleGender}
//                         checked={
//                           first(targetingFields.targetFocus) === GENDER.BOTH
//                         }
//                       >
//                         {t(GENDER.BOTH)}
//                       </QRadio>
//                     </Form.Item>
//                   </Col>
//                   {/* {errors.gender && <div className="text-red font-size-12 mt-5 mb-10">{errors.gender}</div>} */}
//                 </Row>
//               </Col>
//             </Row>
//             <hr className="form__hr" />
//           </>
//         )}

//         {(showAgeGroup || showOverlay) && (
//           <>
//             <Row gutter={16}>
//               <Col xs={24} sm={24} md={10}>
//                 <label htmlFor="ageGroups" className="form-label">
//                   {t(`age_groups`)}
//                 </label>
//                 {/* {errors.ageGroup && <div className="text-red font-size-12 mt-5 mb-10">{errors.ageGroup}</div>} */}
//               </Col>
//               <Col xs={24} sm={24} md={14}>
//                 <Row gutter={16} justify={"center"} align="middle">
//                   {ageGroups().map((group: IAgeGroup) => {
//                     const isChecked = targetingFields.targetAgeGroups.includes(group.value);
//                     return (
//                       <AgeGroupCard
//                         key={group.value}
//                         checked={isChecked}
//                         label={group.label}
//                         sublabel={group.sublabel}
//                         img={group.img}
//                         onChange={(e: any) => handleAgeGroup(e, group.value)}
//                       />
//                     );
//                   })}
//                 </Row>
//               </Col>
//             </Row>
//             <hr className="form__hr" />
//           </>
//         )}

//         {/* <Row gutter={16} align="middle">
//           <Col xs={24} sm={24} md={10}>
//             <label htmlFor="targetSegments" className="form-label">
//               * Segments
//             </label>
//           </Col>
//           <Col xs={24} sm={24} md={14}>
//             <Form.Item
//               className="mb-0"
//               name="targetSegments"
//               rules={[
//                 {
//                   required: true,
//                   message: 'Please choose at least one target segment.',
//                 },
//               ]}
//               validateTrigger={['onBlur', 'onChange']}
//             >
//               <QSelect
//                 value={targetingFields.targetSegments}
//                 onChange={handleSegmentsChange}
//                 showSearch
//                 showArrow
//                 maxTagTextLength={14}
//                 maxTagCount={2}
//                 mode="multiple"
//                 size="large"
//                 placeholder="Select Segments"
//                 options={segments}
//                 filterOption={(input: string, option: IOptionForSelect) =>
//                   option.label.toLowerCase().includes(input.toLocaleLowerCase())
//                 }
//               />
//             </Form.Item>
//           </Col>
//         </Row>
//         <hr className="form__hr" /> */}

//         {(showLocation || showOverlay) && (
//           <>
//             <Row gutter={16}>
//               <Col xs={24} sm={24} md={10}>
//                 <label htmlFor="targetFocus" className="form-label mt-5">
//                   {t(`country`)}
//                 </label>
//               </Col>
//               <Col xs={24} sm={24} md={14}>
//                 <Row gutter={16} align="middle">
//                   {settings.countries.map((country: ICountry) => {
//                     return (
//                       country.name === "Saudi Arabia" && (
//                         <Col key={country.id} xs={24}>
//                           <Form.Item className="mb-0" name={country.name}>
//                             <QCheckbox
//                               checked={targetingFields.targetCountries.includes(
//                                 country.name
//                               )}
//                               onChange={handleCountry}
//                               className="test-class"
//                             >
//                               {`${i18n.language === LOCALIZATION_LANGUAGES.ARABIC && country.name_ar ? country.name_ar : country.name}`}
//                             </QCheckbox>
//                           </Form.Item>
//                         </Col>
//                       )
//                     );
//                   })}
//                   {/* {errors.country && <div className="text-red font-size-12 mt-5 mb-10">{errors.country}</div>} */}
//                 </Row>
//               </Col>
//             </Row>
//             <hr className="form__hr" />
//             <Row gutter={16} align="middle">
//               <Col xs={24} sm={24} md={10}>
//                 <label htmlFor="targetSegments" className="form-label">
//                   {t(`cities`)}
//                 </label>
//               </Col>
//               <Col xs={24} sm={24} md={14}>
//                 <Form.Item className="mb-0" name="targetCities">
//                   <QSelect
//                     value={targetingFields.targetCities}
//                     onChange={handleCityChange}
//                     showSearch
//                     showArrow
//                     mode="multiple"
//                     size="large"
//                     placeholder={t(`select_cities`)}
//                   >
//                     {countries.map((country: ICountry) => {
//                       return (
//                         <OptGroup key={country.id} label={country.name}>
//                           {country.city.map((city: ICity) => {
//                             return (
//                               city.country_id >= 0 && (
//                                 <Option
//                                   key={city.id}
//                                   country={country.name}
//                                   value={city.name}
//                                 >
//                                   {`${i18n.language === LOCALIZATION_LANGUAGES.ARABIC && city.name_ar ? city.name_ar : city.name}`}
//                                 </Option>
//                               )
//                             );
//                           })}
//                         </OptGroup>
//                       );
//                     })}
//                   </QSelect>
//                 </Form.Item>
//                 {cityError && (
//                   <div className="text-red font-size-12 mt-5 mb-10">
//                     {t(`city_validation`)}
//                   </div>
//                 )}
//               </Col>
//             </Row>
//             <hr className="form__hr" />
//           </>
//         )}

//         {isMobile && (
//           <div className="form-brief-footer">
//             <QButton
//               disabled={disabledFinishLater}
//               onClick={onFinishLater}
//               className="qu-button-outline mt-30 full-width"
//             >
//               {t(`save_and_finish_later`)} <LogOut className="ml-10" />
//             </QButton>
//           </div>
//         )}
//       </div>
//     </div>
//   </Form>
// )

// }
