export const MODULE_NAME = 'users'

/* actions */

export const FETCH_USERS_REQUEST = `${MODULE_NAME}/FETCH_USERS_REQUEST`
export const FETCH_USERS_SUCCESS = `${MODULE_NAME}/FETCH_USERS_SUCCESS`
export const FETCH_USERS_FAILURE = `${MODULE_NAME}/FETCH_USERS_FAILURE`

export const FETCH_PUBLISHERS_REQUEST = `${MODULE_NAME}/FETCH_PUBLISHERS_REQUEST`
export const FETCH_PUBLISHERS_SUCCESS = `${MODULE_NAME}/FETCH_PUBLISHERS_SUCCESS`
export const FETCH_PUBLISHERS_FAILURE = `${MODULE_NAME}/FETCH_PUBLISHERS_FAILURE`

export const BAN_USER_REQUEST = `${MODULE_NAME}/BAN_USER_REQUEST`
export const BAN_USER_SUCCESS = `${MODULE_NAME}/BAN_USER_SUCCESS`
export const BAN_USER_FAILURE = `${MODULE_NAME}/BAN_USER_FAILURE`

export const PAYMENT_METHODS_REQUEST = `${MODULE_NAME}/PAYMENT_METHODS_REQUEST`
export const PAYMENT_METHODS_SUCCESS = `${MODULE_NAME}/PAYMENT_METHODS_SUCCESS`
export const PAYMENT_METHODS_FAILURE = `${MODULE_NAME}/PAYMENT_METHODS_FAILURE`

export const DISABLE_PAYMENT_USER_REQUEST = `${MODULE_NAME}/DISABLE_PAYMENT_USER_REQUEST`
export const DISABLE_PAYMENT_USER_SUCCESS = `${MODULE_NAME}/DISABLE_PAYMENT_USER_SUCCESS`
export const DISABLE_PAYMENT_USER_FAILURE = `${MODULE_NAME}/DISABLE_PAYMENT_USER_FAILURE`

export const UNBAN_USER_REQUEST = `${MODULE_NAME}/UNBAN_USER_REQUEST`
export const UNBAN_USER_SUCCESS = `${MODULE_NAME}/UNBAN_USER_SUCCESS`
export const UNBAN_USER_FAILURE = `${MODULE_NAME}/UNBAN_USER_FAILURE`

export const ENABLE_PAYMENT_USER_REQUEST = `${MODULE_NAME}/ENABLE_PAYMENT_USER_REQUEST`
export const ENABLE_PAYMENT_USER_SUCCESS = `${MODULE_NAME}/ENABLE_PAYMENT_USER_SUCCESS`
export const ENABLE_PAYMENT_USER_FAILURE = `${MODULE_NAME}/ENABLE_PAYMENT_USER_FAILURE`

export const ALLOW_UPLOAD_PO_REQUEST = `${MODULE_NAME}/ALLOW_UPLOAD_PO_REQUEST`
export const ALLOW_UPLOAD_PO_SUCCESS = `${MODULE_NAME}/ALLOW_UPLOAD_PO_SUCCESS`
export const ALLOW_UPLOAD_PO_FAILURE = `${MODULE_NAME}/ALLOW_UPLOAD_PO_FAILURE`

export const RESTRICT_UPLOAD_PO_REQUEST = `${MODULE_NAME}/RESTRICT_UPLOAD_PO_REQUEST`
export const RESTRICT_UPLOAD_PO_SUCCESS = `${MODULE_NAME}/RESTRICT_UPLOAD_PO_SUCCESS`
export const RESTRICT_UPLOAD_PO_FAILURE = `${MODULE_NAME}/RESTRICT_UPLOAD_PO_FAILURE`

export const SET_CURRENT_USER = `${MODULE_NAME}/SET_CURRENT_USER`

export const FETCH_PARTNER_SETTINGS_SUCCESS = `${MODULE_NAME}/FETCH_PARTNER_SETTINGS_SUCCESS`
export const FETCH_PARTNER_SETTINGS_FAILURE = `${MODULE_NAME}/FETCH_PARTNER_SETTINGS_FAILURE`

export const FETCH_PARTNER_PROPERTIES_REQUEST = `${MODULE_NAME}/FETCH_PARTNER_PROPERTIES_REQUEST`
export const FETCH_PARTNER_PROPERTIES_SUCCESS = `${MODULE_NAME}/FETCH_PARTNER_PROPERTIES_SUCCESS`
export const FETCH_PARTNER_PROPERTIES_FAILURE = `${MODULE_NAME}/FETCH_PARTNER_PROPERTIES_FAILURE`

export const RESET_PARTNER_PROPERTIES = `${MODULE_NAME}/RESET_PARTNER_PROPERTIES`
export const RESET_USERS_STATE = `${MODULE_NAME}/RESET_USERS_STATE`

export const SET_CURRENT_PAGE = `${MODULE_NAME}/SET_CURRENT_PAGE`
