import React, { ReactElement, useEffect, useState } from 'react'
import { Form, QButton, QDatePicker, QInput, qnotification, QSelect } from 'quantum_components'
import { FORMATS } from 'src/constants'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { getCampaignPartnerOnlyDistribution, getUploadingCampaignTimelineImages } from '../../../campaign.selectors'
import { ICampaignPartner, ICampaignTimeline, ICampaignTimelineTable } from '../../../campaign.types'
import { MEDIA_GROUP_TYPE } from '../../../../../constants/brief'
import { VALIDATION_MESSAGE } from '../../../../../constants/validationMessages'
import { actions } from '../../../campaign.module'
import { useParams } from 'react-router-dom'
import { getIsoDate } from '../../../../../helpers/datesHelper'
import { validateRasterImage } from '../../../../../helpers/validation'
import { filesUrlsMapper } from '../../../../../helpers/files'
import SimpleUploadComponent from '../../../../../components/SimpleUploadComponent'
import NOTIFICATION_TYPES from '../../../../../constants/notificationTypes'
import { ALERT_BRIEF_UPLOAD_MAX } from '../../../../../constants/alerts'
import DialogModal from 'src/components/DialogModal/DialogModal'
import { CAMPAIGN_PARTNERS, CAMPAIGN_PARTNERS_NAMES } from '../../../../../constants/campaign'

const MAX_TIMELINE_IMAGES_COUNT = 2

interface IProps {
  disabledButton: boolean
  timeline?: ICampaignTimelineTable
  closeModal?: () => void
}

export default function CampaignEditTimelineForm({ disabledButton, timeline, closeModal }: IProps): ReactElement {
  const dispatch = useDispatch()
  const partners = useSelector(getCampaignPartnerOnlyDistribution)
  const imagesUploading = useSelector(getUploadingCampaignTimelineImages)
  const [files, setFiles] = useState<any>([])
  const [showModal, setShowModal] = useState<boolean>(false)

  const [form] = Form.useForm()
  const { id } = useParams<{id: any}>()

  useEffect(() => {
    if (!timeline) return

    form.setFieldsValue({
      title: timeline.title,
      date: moment(timeline.date),
      partner: timeline.partnerId,
    })
  }, [])

  useEffect(() => {
    if (timeline && timeline.media.length > 0) {
      const uploads = filesUrlsMapper(timeline.media)
      setFiles(uploads)
    } else {
      setFiles([])
    }
  }, [timeline])

  const partnerOptions = partners.map((partner: ICampaignPartner) => {
    const distributionType = partner.requestTypes.includes(MEDIA_GROUP_TYPE.PRODUCT_SAMPLE)
      ? MEDIA_GROUP_TYPE.PRODUCT_SAMPLE
      : MEDIA_GROUP_TYPE.PRINTED_INSERT
    return { label: `${partner.companyName} - ${distributionType}`, value: partner.id }
  })

  const resetAndClose = () => {
    form.resetFields()
    setFiles([])
    closeModal && closeModal()
  }

  const onFinish = async (values: any) => {
    const newFiles = files.filter((f: any) => f.originFileObj).map((f: any) => f.originFileObj)
    if (newFiles.length == 0) {
      setShowModal(true)
      return
    }
    await createNewTimeline()
    resetAndClose()
  }

  const onFileChange = (info: any) => {
    let uploaded = [] as any
    let newFiles = [] as any
    info.fileList.forEach((f: any) => (f.originFileObj ? newFiles.push(f) : uploaded.push(f)))

    if (uploaded.length === MAX_TIMELINE_IMAGES_COUNT) {
      qnotification({ type: NOTIFICATION_TYPES.ERROR, message: ALERT_BRIEF_UPLOAD_MAX(MAX_TIMELINE_IMAGES_COUNT) })
      return
    }

    const validatedFiles = newFiles.filter((f: any) => validateRasterImage(f))
    if (!validatedFiles.length) return
    const merged = [...uploaded, ...validatedFiles]
    const sliced = merged.slice(0, MAX_TIMELINE_IMAGES_COUNT)

    setFiles(sliced)
  }

  const handleRemove = async (file: any) => {
    const updated = [...files]
    if (file.url) {
      await dispatch(actions.removeCampaignTimelineImage(file.url))
      await dispatch(actions.fetchCampaignTimeline(id))
    }

    updated.splice(files.indexOf(file), 1)
    setFiles(updated)
  }

  const modalQuestion = 'Timeline Record will be saved to Campaign without an attached image.'
  const buttonText = 'Confirm'

  const closeDialogModal = () => {
    setShowModal(false)
  }

  const proceedComplete = async () => {
    await createNewTimeline()
    closeDialogModal()
  }

  const createNewTimeline = async () => {
    const data = {
      id: timeline ? timeline.key : undefined,
      title: form.getFieldsValue().title,
      startedDate: getIsoDate(form.getFieldsValue().date),
      partnerId: form.getFieldsValue().partner || CAMPAIGN_PARTNERS.QUANTUM_TEAM,
      campaignId: +id,
    } as ICampaignTimeline
    const newFiles = files.filter((f: any) => f.originFileObj).map((f: any) => f.originFileObj)

    await dispatch(actions.saveCampaignTimeline(id, data, newFiles))
    await dispatch(actions.fetchCampaignTimeline(id))

    resetAndClose()
  }

  return (
    <div>
      <Form form={form} onFinish={onFinish} className="p-16">
        <Form.Item
          name="title"
          rules={[{ required: true, max: 255, whitespace: true, message: VALIDATION_MESSAGE.TITLE }]}
          validateTrigger={['onChange', 'onBlur']}
        >
          <QInput min={0} placeholder="Enter Title" size="large" />
        </Form.Item>
        <Form.Item name="date" rules={[{ required: true, message: VALIDATION_MESSAGE.DATE }]}>
          <QDatePicker format={FORMATS.DATE_LL_FORMAT} className="full-width" size="large" placeholder="Select Date" />
        </Form.Item>
        <Form.Item name="partner" validateTrigger={['onChange', 'onBlur']}>
          <QSelect
            className="full-width"
            options={[
              { label: CAMPAIGN_PARTNERS_NAMES[CAMPAIGN_PARTNERS.QUANTUM_TEAM], value: CAMPAIGN_PARTNERS.QUANTUM_TEAM },
              ...partnerOptions,
            ]}
            size="large"
            placeholder="Select Partner"
            showArrow
          />
        </Form.Item>
        <Form.Item className="mb-30" name="images">
          <SimpleUploadComponent
            multiple
            files={files}
            onChange={onFileChange}
            onRemove={handleRemove}
            isUploading={imagesUploading}
          />
        </Form.Item>

        <QButton
          disabled={disabledButton || imagesUploading}
          loading={imagesUploading}
          className="full-width"
          type="primary"
          htmlType="submit"
        >
          Save
        </QButton>
      </Form>
      <DialogModal
        modalOpen={showModal}
        question={modalQuestion}
        description={''}
        submitButtonText={buttonText}
        cancelButtonText={'Cancel'}
        onClose={proceedComplete}
        onProceed={closeDialogModal}
      />
    </div>
  )
}
