export const MODULE_NAME = 'dashboard'
/* actions */

export const RESET_DASHBOARD_STATE = `${MODULE_NAME}/RESET_DASHBOARD_STATE`
export const SET_DASHBOARD_PARAMS = `${MODULE_NAME}/SET_DASHBOARD_PARAMS`

export const FETCH_DASHBOARD_REQUEST = `${MODULE_NAME}/FETCH_DASHBOARD_REQUEST`
export const FETCH_DASHBOARD_SUCCESS = `${MODULE_NAME}/FETCH_DASHBOARD_SUCCESS`
export const FETCH_DASHBOARD_FAILURE = `${MODULE_NAME}/FETCH_DASHBOARD_FAILURE`


