import { IState } from './supportTickets.types'
import * as CONSTANTS from './supportTickets.constants'
import { get } from 'lodash'

const initialState: IState = {
  supportTicketsListLoading: false,
  supportTicketsList: {
    results: [],
    total: 0,
  },
  pages: {
    totalPages: 1,
    currentPage: 1,
  },
  supportTicketActionLoading: false
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case CONSTANTS.FETCH_SUPPORT_TICKETS_REQUEST:
      return {
        ...state,
        supportTicketsListLoading: true,
      }
    case CONSTANTS.FETCH_SUPPORT_TICKETS_SUCCESS:
      const supportTickets = action.payload
      return {
        ...state,
        supportTicketsList: supportTickets,
        supportTicketsListLoading: false,
      }
    case CONSTANTS.FETCH_SUPPORT_TICKETS_FAILURE:
      return {
        ...state,
        supportTicketsListLoading: false,
      }
    case CONSTANTS.ACTION_SUPPORT_TICKET_REQUEST:
      return {
        ...state,
        supportTicketActionLoading: true,
      }
    case CONSTANTS.ACTION_SUPPORT_TICKET_SUCCESS:
      return {
        ...state,
        supportTicketActionLoading: false,
      }
    case CONSTANTS.SET_CURRENT_PAGE:
      return {
        ...state,
        pages: {
          ...state.pages,
          currentPage: get(action, 'payload.page'),
        },
      }
    default:
      return state
  }
}
