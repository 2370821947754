// @ts-nocheck
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { isMobile } from 'react-device-detect'
import { Col, Row } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { Dropdown, IconsCommon, Menu, QInput, QMenuItem, QPagination, QSelect, QTable } from 'quantum_components'
import moment from 'moment'
import first from 'lodash/first'

import useDebounce from 'src/hooks/useDebounce'
import { FORMATS } from 'src/constants'
import { DEFAULT_BILL_TABLE_PARAMS } from 'src/constants/bill'
import { BRIEF_PAYMENT_STATUS, BRIEF_PAYMENT_STATUS_NAMES, briefPaymentStatuses } from 'src/constants/brief'
import { PERMISSIONS } from 'src/constants/permission'

import { useReduxDispatch } from 'src/helpers'
import { hasPermission } from 'src/helpers/permission'
import { renderRoundedNumber } from 'src/helpers/currency'
import { getCurrentUserPermissionActions } from 'src/modules/core/core.selectors'

import { IOnlinePayment, IRefund, ISelectInputOption } from '../bills.types'
import {
  getBillParams,
  getOnlinePaymentsList,
  getOnlinePaymentsListLoading,
  getPages,
  getRefundPending,
  getTotalOnlinePaymentsList,
} from '../bills.selectors'
import * as actions from '../bills.actions'

import SpinnerCenter from 'src/components/Spinner/spinner'
import RefundModal from './RefundModal'
import { useTranslation } from 'react-i18next'

const OnlinePayments = () => {
  const { i18n } = useTranslation()
  const { t } = useTranslation(['translationSettings', 'translation', 'translationCommon'])
  const history = useHistory()
  const dispatch = useReduxDispatch()
  const permissions = useSelector(getCurrentUserPermissionActions)
  const onlinePaymentsList = useSelector(getOnlinePaymentsList)
  const onlinePaymentsTotal = useSelector(getTotalOnlinePaymentsList)
  const onlinePaymentsListLoading = useSelector(getOnlinePaymentsListLoading)
  const params = useSelector(getBillParams)
  const pages = useSelector(getPages)
  const refundPending = useSelector(getRefundPending)

  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 500)

  const [activeOnlinePayment, setActiveOnlinePayment] = useState<IOnlinePayment | null>(null)
  const [availableAmountForRefund, setAvailableAmountForRefund] = useState<IOnlinePayment | null>(null)

  const [refundModalVisible, setRefundModalVisible] = useState<boolean>(false)
  const toggleRefundModal = () => setRefundModalVisible(() => !refundModalVisible)

  useEffect(() => {
    if (!refundModalVisible) {
      setActiveOnlinePayment(null)
    }
  }, [refundModalVisible])

  useEffect(() => {
    if (activeOnlinePayment) {
      const refundsSumInCents = activeOnlinePayment.refunds.reduce((acc, refund) => acc + refund.amount, 0)
      const availableAmountForRefundInCents = activeOnlinePayment.amount - refundsSumInCents
      setAvailableAmountForRefund(availableAmountForRefundInCents / 100)
    }
  }, [activeOnlinePayment])

  useEffect(() => {
    const newParams = { ...params, search: debouncedSearch, offset: 0, limit: 10 }
    dispatch(actions.setParamsAndFetch({ ...newParams }, 'onlinePayments'))
    dispatch(actions.setCurrentPage(1))

    return () => dispatch(actions.setBillListParams(DEFAULT_BILL_TABLE_PARAMS))
  }, [debouncedSearch])

  const briefRefundStatusOptions: ISelectInputOption[] = [
    BRIEF_PAYMENT_STATUS.DECLINED,
    BRIEF_PAYMENT_STATUS.PAID,
    BRIEF_PAYMENT_STATUS.PARTIAL_REFUND,
    BRIEF_PAYMENT_STATUS.REFUND,
  ].map((paymentStatus: BRIEF_PAYMENT_STATUS) => ({
    label: t(BRIEF_PAYMENT_STATUS_NAMES[paymentStatus], { ns: 'translation' }),
    value: paymentStatus,
  }))

  const columns = [
    {
      title: t('settings.billings.Product'),
      dataIndex: '',
      key: 'product',
      width: '15%',
      fixed: 'left',
      sorter: true,
      render: ({ payment: { brief },version }: IOnlinePayment) => {
        const briefImage = first(brief.allImages.filter(image => image.entity === 'briefs_product_images'))?.src ?? null
        return (
          <div className="q-table-product">
            <div className="q-table-product_thumb">
              <img src={briefImage ?? 'https://via.placeholder.com/50x50'} alt={brief.campaignName ?? ''} />
            </div>
            <div className="q-table-product_info">
              <h3 className="q-table-product_title">{brief.campaignName || 'Unnamed Brief'}</h3>
              <div className="q-table-product_created">
                {t('common.created', { ns: 'translationCommon' })} {moment(brief.createdAt).format(FORMATS.DATE_LL_FORMAT)}
              </div>
            </div>
          </div>
        )
      },
    },
    {
      title: t('settings.billings.Amount'),
      dataIndex: '',
      key: 'amount',
      width: '12%',
      render: ({ amount: amountInCents, currency }: IOnlinePayment) => {
        return (
          <div onClick={(event: React.MouseEvent<HTMLDivElement>) => event.stopPropagation()}>
            {renderRoundedNumber(amountInCents / 100, currency)}
          </div>
        )
      },
    },
    {
      title: t('brief.proposal.date', { ns: 'translation' }),
      dataIndex: '',
      key: 'date',
      width: '8%',
      render: ({ createdAt }: IOnlinePayment) => {
        return (
          <div onClick={(event: React.MouseEvent<HTMLDivElement>) => event.stopPropagation()}>
            {moment(createdAt).format(FORMATS.DATE_LL_FORMAT)}
          </div>
        )
      },
    },
    {
      title: t('settings.billings.Refunded'),
      dataIndex: 'refunds',
      key: 'refunded',
      width: '19%',
      render: (refunds: IRefund[]) => {
        return (
          <div onClick={(event: React.MouseEvent<HTMLDivElement>) => event.stopPropagation()}>
            {refunds.length ? (
              refunds.map(({ createdAt, amount: amountInCents, currency }) => {
                return (
                  <div>
                    {moment(createdAt).format(FORMATS.DATE_TIME_FORMAT)}
                    <span className="text-bold"> | </span>
                    {renderRoundedNumber(amountInCents / 100, currency)}
                  </div>
                )
              })
            ) : (
              <span>-</span>
            )}
          </div>
        )
      },
    },
    {
      title: t('settings.billings.Payment Status'),
      dataIndex: '',
      key: 'paymentStatus',
      width: '10%',
      fixed: 'right',
      render: (onlinePayment: IOnlinePayment) => {
        const paymentStatus = onlinePayment.payment.status
        const paymentStatusClass = briefPaymentStatuses[paymentStatus]?.classStyle ?? ''
        const paymentStatusLabel = briefPaymentStatuses[paymentStatus]?.label ?? ''
        const version = onlinePayment?.version;
        return (
          <div>
            {paymentStatus ? (
              <span className={`status-label ${paymentStatusClass}`}>{t(paymentStatusLabel, { ns: 'translation' })}</span>
            ) : (
              <span>-</span>
            )}

            <div onClick={(event: React.MouseEvent<HTMLDivElement>) => event.stopPropagation()}>
              <Dropdown overlay={getAdditionalOptionsMenu(onlinePayment)} trigger={['click']} placement="bottomRight">
                <div className="q-table_menu">
                  <IconsCommon.IconEllipsisV />
                </div>
              </Dropdown>
            </div>
          </div>
        )
      },
    },
  ]

  const getAdditionalOptionsMenu = (onlinePayment: IOnlinePayment) => {
    return (
      <Menu className="user-menu-list">
        <QMenuItem
          key={0}
          icon={<IconsCommon.IconView />}
          name={t('common.View', { ns: 'translationCommon' })}
          onClick={() => handleView(onlinePayment.payment.brief.id,onlinePayment?.version)}
        />
        <QMenuItem
          key={1}
          icon={<IconsCommon.IconCheck />}
          name={t('brief.payment-statuses-array.REFUND', { ns: 'translation' })}
          disabled={
            !hasPermission(permissions, PERMISSIONS.INITIATE_REFUNDS) ||
            [BRIEF_PAYMENT_STATUS.REFUND, BRIEF_PAYMENT_STATUS.PROCESSING].includes(onlinePayment.payment.status)
          }
          onClick={() => {
            setActiveOnlinePayment(onlinePayment)
            toggleRefundModal()
          }}
        />
      </Menu>
    )
  }

  const handleOnRowClick = (onlinePayment: IOnlinePayment,rowIndex:Number) => ({
    onClick: () => {
      try {
        document.getElementsByClassName('q-table_menu')[rowIndex].click();  
      } catch (error) {
      } 
    }
    // handleView(onlinePayment.payment.brief.id),
  })

  const handleView = (briefId: number,version?:string) => {
    if(version == "2"){
      history.push(`/briefs/v3/edit/${briefId}`)
    }else{
      history.push(`/briefs/view/${briefId}`)
    }
    
  }

  const handleChangeSearch = e => {
    setSearch(e.currentTarget.value)
  }

  const handleChangeSelect = (activePaymentStatuses: string[]) => {
    dispatch(
      actions.setParamsAndFetch({ ...params, offset: 0, paymentStatus: activePaymentStatuses }, 'onlinePayments')
    )
  }

  const handleChangePage = (page, pageSize) => {
    dispatch(actions.setCurrentPage(page))
    dispatch(actions.setParamsAndFetch({ ...params, offset: (page - 1) * pageSize, limit: pageSize }, 'onlinePayments'))
  }

  const handleTableChange = (pagination, filters, sorter) => {
    const colKey = {
      product: 'name',
    }
    const sortingParams = {
      direction: sorter.order.slice(0, -3),
      sortField: colKey[sorter.columnKey],
    }

    dispatch(
      actions.setParamsAndFetch(
        {
          ...params,
          ...sortingParams,
        },
        'onlinePayments'
      )
    )
  }

  const handleRefundSubmit = async (amount: number) => {

    if (refundPending) {
      return
    }

    try {
      const onlinePaymentId = activeOnlinePayment.id
      const version = activeOnlinePayment?.version;
      await dispatch(actions.refundOnlinePayment(onlinePaymentId, amount, version))

      toggleRefundModal()
      dispatch(actions.setParamsAndFetch(params, 'onlinePayments'))
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div className="qu-brief-list pb-20">
      {refundPending && <SpinnerCenter text="Processing refund..." />}

      <Row className={`${isMobile && 'pb-15'}`} justify={isMobile ? 'center' : 'space-between'}>
        <Col className="qt-search">
          <QInput
            className={`${isMobile && 'full-width'}`}
            value={search}
            onChange={handleChangeSearch}
            placeholder={t('settings.billings.Search')}
            size="large"
            prefix={<SearchOutlined />}
          />
        </Col>
        {!isMobile && (
          <Col className="qt-filter">
            <QSelect
              showArrow
              maxTagTextLength={8}
              maxTagCount={2}
              value={params.paymentStatus}
              onChange={handleChangeSelect}
              mode="multiple"
              size="large"
              placeholder={t('settings.billings.Filter by status')}
              options={briefRefundStatusOptions}
            />
          </Col>
        )}
      </Row>

      <div className="pt-15 pb-30">
        <QTable
          onRow={handleOnRowClick}
          onChange={handleTableChange}
          sortDirections={['ascend', 'descend', 'ascend']}
          loading={{
            spinning: onlinePaymentsListLoading,
            indicator: <div className="loader loader-small" />,
          }}
          columns={columns}
          dataSource={onlinePaymentsList}
          scroll={{ x: 990 }}
          rowClassName="q-table cursorPointer"
        />
      </div>

      <Row justify={isMobile ? 'center' : 'end'} className={`${isMobile && 'pt-15 mb-30'}`}>
        <QPagination
          size="default"
          defaultCurrent={1}
          current={pages.currentPage}
          total={onlinePaymentsTotal}
          onChange={handleChangePage}
        />
      </Row>

      {refundModalVisible && (
        <RefundModal
          availableAmountForRefund={availableAmountForRefund}
          currency={activeOnlinePayment?.currency}
          onSubmit={handleRefundSubmit}
          onCancel={() => {
            if (refundPending) {
              return
            }

            toggleRefundModal()
          }}
        />
      )}
    </div>
  )
}

export default OnlinePayments
