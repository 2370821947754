import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { AxiosResponse } from 'axios'
import IAction from 'src/interfaces/IAction'

import * as CONSTANTS from './releaseNotes.constants'
import { requestHttp, urls } from 'src/api'
import { getResponseErrorMessage } from 'src/helpers'

export const resetReleaseNotesState = (): IAction => ({
    type: CONSTANTS.RESET_RELEASE_NOTES,
})

export const releaseNotesRequest = (): IAction => ({
    type: CONSTANTS.FETCH_RELEASE_NOTES_REQUEST,
})

export const releaseNotesSuccess = (releaseNotes: any): IAction => ({
    type: CONSTANTS.FETCH_RELEASE_NOTES_SUCCESS,
    payload: releaseNotes,
})

export const releaseNotesFailure = (error: string): IAction => ({
    type: CONSTANTS.FETCH_RELEASE_NOTES_FAILURE,
    error,
})

export const getReleaseNotes = (): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<any> => {
    try {
        dispatch(releaseNotesRequest())
        const response = await requestHttp.get(urls.getReleaseNotesUrl())
        const { content } = response.data.data
        dispatch(releaseNotesSuccess(content))
        return response
    } catch (error) {
        dispatch(releaseNotesFailure(getResponseErrorMessage(error)))
        return error
    }
}
