import { PERMISSIONS } from './permission'

export enum MENU_ITEMS {

  PRODUCTS='products',
  PUBLISHERS='publishers',

  TARGETING_KEYS='targetingKeys',
  NATIVE_TEMPLATES='nativeTemplates',

  WORKFLOW = 'workflow',
  DASHBOARD = 'dashboard',
  REQUESTS = 'requests',
  REQUESTS_PARTNERS = 'requestsPartners',
  REQUESTS_CLIENTS = 'requestsClients',

  CAMPAIGNS = 'campaigns',
  COMPLETED_CAMPAIGNS = 'completedCampaigns',

  CLIENTS = 'clients',
  ALL_CLIENTS = 'allClients',
  ADD_CLIENT = 'addClient',
  INVITE_CLIENT = 'inviteClient',
  PARTNERS = 'partners',

  SERVICES = 'services',
  ADD_SERIVCE = 'addService',
  MANAGE_SERVICES = 'manageServices',

  CALENDAR = 'calendar',

  PROMOTIONS = 'promotions',

  BRIEFS = 'briefs',
  TESTAHEL_BRIEFS = 'testahel-brief',
  BRIEFS_ALL = 'briefsAll',
  REQUESTS_ALL = 'requestsAll',
  BRIEFS_CREATE = 'briefsCreate',
  DEMOGRAPHICS = 'demographics',
  DEMOGRAPHICS_AGE_GROUPS = 'demographicsAgeGroups',
  DEMOGRAPHICS_SEGMENTS = 'demographicsSegments',
  EMPLOYEES = 'employees',
  EMPLOYEES_ALL = 'employeesAll',
  EMPLOYEES_ROLES = 'employeesRoles',
  ACTIVITIES = 'activities',
  DISCOUNT_CODES = 'discountCodes',
  SUBSCRIPTION_HISTORY = 'subscriptionHistory',
  WALLET = 'wallet',
  SUPPORT_TICKETS = 'supportTickets',
  LEADS = 'leads',
  ADMIN_SETTINGS = 'adminSettings',
  REFERRALS = 'referrals',
  CREATE_CLIENT = 'createClient',
  INVITED_CLIENTS = 'invitedClients',
  INVITED_SIGNUPS = 'clientsInvited',
  INVITED_CLIENTS_BRIEFS = 'briefsInvited',
  INVITED_CLIENTS_WITH_BRIEFS = 'invitedClients/briefs',
  UPDATE_PASSWORD = 'updatePassword',
  LOGOUT = 'logOut',
  BILLINGS = 'billings',
  MARKETPLACE = 'marketplace',
  PRIVACY_POLICY = 'privacyPolicy',
  DISCLAIMER = 'disclaimer',
  RELEASE_NOTES = 'releaseNotes',
}

export const PERMISSION_MENU: { [key: string]: PERMISSIONS } = {

  [MENU_ITEMS.DASHBOARD]: PERMISSIONS.DEFAULT,
  [MENU_ITEMS.LOGOUT]: PERMISSIONS.DEFAULT,
  [MENU_ITEMS.WORKFLOW]: PERMISSIONS.MANAGE_WORKFLOW,
  [MENU_ITEMS.CAMPAIGNS]: PERMISSIONS.CAMPAIGNS_ALL,
  [MENU_ITEMS.COMPLETED_CAMPAIGNS]: PERMISSIONS.CAMPAIGNS_ALL,
  [MENU_ITEMS.REQUESTS_ALL]: PERMISSIONS.REQUEST_ALL,
  [MENU_ITEMS.BRIEFS]: PERMISSIONS.BRIEFS_ALL,
  [MENU_ITEMS.BRIEFS_ALL]: PERMISSIONS.BRIEFS_ALL,
  [MENU_ITEMS.BRIEFS_CREATE]: PERMISSIONS.BRIEF_CREATE_AND_UPDATE,
  [MENU_ITEMS.REQUESTS_PARTNERS]: PERMISSIONS.SIGN_UP_REQUESTS,
  [MENU_ITEMS.REQUESTS_CLIENTS]: PERMISSIONS.SIGN_UP_REQUESTS,
  [MENU_ITEMS.REQUESTS]: PERMISSIONS.SIGN_UP_REQUESTS,
  [MENU_ITEMS.CLIENTS]: PERMISSIONS.CLIENT_VIEW,
  [MENU_ITEMS.CREATE_CLIENT]: PERMISSIONS.CREATE_CLIENT,
  [MENU_ITEMS.ALL_CLIENTS]: PERMISSIONS.CLIENT_VIEW,
  [MENU_ITEMS.ADD_CLIENT]: PERMISSIONS.CLIENT_CREATE,
  [MENU_ITEMS.INVITE_CLIENT]: PERMISSIONS.INVITE_CLIENT,
  [MENU_ITEMS.PARTNERS]: PERMISSIONS.PARTNER_VIEW_PROFILE,
  [MENU_ITEMS.SERVICES]: PERMISSIONS.MANAGE_SERVICES,
  [MENU_ITEMS.ADD_SERIVCE]: PERMISSIONS.CREATE_CUSTOM_MEDIA,
  [MENU_ITEMS.MANAGE_SERVICES]: PERMISSIONS.VIEW_CUSTOM_MEDIA,
  [MENU_ITEMS.CALENDAR]: PERMISSIONS.MANAGE_CALENDAR,
  [MENU_ITEMS.PROMOTIONS]: PERMISSIONS.PROMOTION_VIEW,
  [MENU_ITEMS.DEMOGRAPHICS]: PERMISSIONS.DEMOGRAPHICS,
  [MENU_ITEMS.DEMOGRAPHICS_AGE_GROUPS]: PERMISSIONS.DEMOGRAPHICS,
  [MENU_ITEMS.DEMOGRAPHICS_SEGMENTS]: PERMISSIONS.DEMOGRAPHICS,
  [MENU_ITEMS.EMPLOYEES]: PERMISSIONS.EMPLOYEES_ALL,
  [MENU_ITEMS.EMPLOYEES_ALL]: PERMISSIONS.EMPLOYEES_ALL,
  [MENU_ITEMS.EMPLOYEES_ROLES]: PERMISSIONS.ROLES_AND_PERMISSIONS,
  [MENU_ITEMS.ACTIVITIES]: PERMISSIONS.ACTIVITIES,
  [MENU_ITEMS.DISCOUNT_CODES]: PERMISSIONS.DISCOUNT_CODES,
  [MENU_ITEMS.SUBSCRIPTION_HISTORY]: PERMISSIONS.VIEW_SUBSCRIPTION_HISTORY,
  [MENU_ITEMS.WALLET]: PERMISSIONS.WALLET_MANAGEMENT,
  [MENU_ITEMS.SUPPORT_TICKETS]: PERMISSIONS.SUPPORT_TICKETS_MODULE_ACCESS,
  [MENU_ITEMS.LEADS]: PERMISSIONS.LEADS,
  [MENU_ITEMS.ADMIN_SETTINGS]: PERMISSIONS.ADMIN_SETTINGS,
  [MENU_ITEMS.UPDATE_PASSWORD]: PERMISSIONS.CHANGE_PASSWORD,
  [MENU_ITEMS.REFERRALS]: PERMISSIONS.VIEW_REFERRAL_PROGRAM,
  [MENU_ITEMS.INVITED_CLIENTS]: PERMISSIONS.VIEW_INVITED_REQUEST_LISTING,
  [MENU_ITEMS.INVITED_SIGNUPS]: PERMISSIONS.VIEW_INVITED_REQUEST_LISTING,
  [MENU_ITEMS.INVITED_CLIENTS_BRIEFS]: PERMISSIONS.VIEW_EXCLUSIVE_BRIEFS_LISTINGS,
  [MENU_ITEMS.BILLINGS]: PERMISSIONS.BILLS_ALL,
  [MENU_ITEMS.PRIVACY_POLICY]: PERMISSIONS.VIEW_PRIVACY_POLICY,
  [MENU_ITEMS.DISCLAIMER]: PERMISSIONS.VIEW_DISCLAIMER,
  [MENU_ITEMS.RELEASE_NOTES]: PERMISSIONS.VIEW_RELEASE_NOTES,
  [MENU_ITEMS.TARGETING_KEYS]: PERMISSIONS.VIEW_TARGETING_KEYS,
  [MENU_ITEMS.NATIVE_TEMPLATES]: PERMISSIONS.BRIEF_CREATE_AND_UPDATE,
  [MENU_ITEMS.PRODUCTS]: PERMISSIONS.PRODUCTS_MANAGEMENT,
  [MENU_ITEMS.PUBLISHERS]: PERMISSIONS.PRODUCTS_MANAGEMENT,
}
