import React, { useEffect, useState } from 'react'
import { useReduxDispatch } from 'src/helpers'
import { fetchCurrency, getUser } from 'src/modules/core/core.actions'
import getIsLoggedIn from 'src/helpers/getIsLoggedIn'
import PartnerLayout from '../PartnerLayout'
import { useLocation, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getCurrency, getCurrentUser, getCurrentUserPermissionActions } from 'src/modules/core/core.selectors'
import { PATHS } from '../../../constants'

interface Props {
  children: React.ReactNode
}

const MainLayout = ({ children }: Props) => {
  const dispatch = useReduxDispatch()
  const location = useLocation()
  const history = useHistory()
  const user = useSelector(getCurrentUser)
  const permissions = useSelector(getCurrentUserPermissionActions)
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
  const [path, setPath] = useState<string | null>(null)

  useEffect(() => {
    dispatch(getUser())

    if (location.pathname !== PATHS.ROOT) {
      setPath(location.pathname)
    }
  }, [])

  useEffect(() => {
    if (!user.id) return
    dispatch(fetchCurrency())
  }, [user])
  useEffect(() => {
    if (!permissions.length || !path) return

    history.push(path)
    setPath(null)
  }, [permissions])

  const fetchUser = async () => {
    const isLoggedIn = await getIsLoggedIn()

    setIsAuthenticated(isLoggedIn)
  }

  useEffect(() => {
    fetchUser()
  })

  const renderLayout = (children: React.ReactNode) => {
    if (!isAuthenticated) {
      return children
    }

    return <PartnerLayout>{children}</PartnerLayout>
  }

  return <>{renderLayout(children)}</>
}

export default MainLayout
