// @ts-nocheck
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { isMobile } from 'react-device-detect'
import { Col, Row } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { Dropdown, IconsCommon, Menu, QInput, QMenuItem, QPagination, QSelect, QTable } from 'quantum_components'

import { getFileNameFromUrl } from 'src/utils'
import useDebounce from 'src/hooks/useDebounce'
import { PERMISSIONS } from 'src/constants/permission'
import { DEFAULT_BILL_TABLE_PARAMS } from 'src/constants/bill'
import { BRIEF_PAYMENT_STATUS, BRIEF_PAYMENT_STATUS_NAMES, briefPaymentStatuses } from 'src/constants/brief'
import { getCurrentUserPermissionActions } from 'src/modules/core/core.selectors'
import * as coreActions from 'src/modules/core/core.actions'
import { useReduxDispatch } from 'src/helpers'
import { hasPermission } from 'src/helpers/permission'
import IUploadFile from 'src/interfaces/IUploadFile'

import { IBillingsListElement, IBillingsPayment, IBillingsPaymentUploadTypes, ISelectInputOption } from '../bills.types'
import { getBillParams, getPages, getRequestList, getRequestListLoading, getTotalRequestList } from '../bills.selectors'
import * as actions from '../bills.actions'

import UploadComponent from 'src/components/UploadComponent'

interface Props {
  partnerId?: string | number
  canUploadInvoice?: boolean
  canUploadBankTransfer?: boolean
}

const PartnerBillings = ({ partnerId, canUploadInvoice = false, canUploadBankTransfer = false }: Props) => {
  const history = useHistory()
  const dispatch = useReduxDispatch()
  const permissions = useSelector(getCurrentUserPermissionActions)
  const requestList = useSelector(getRequestList)
  const totalRequestList = useSelector(getTotalRequestList)
  const requestListLoading = useSelector(getRequestListLoading)
  const params = useSelector(getBillParams)
  const pages = useSelector(getPages)

  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 500)

  const briefPaymentStatusOptions: ISelectInputOption[] = [BRIEF_PAYMENT_STATUS.PENDING, BRIEF_PAYMENT_STATUS.PAID].map(
    (paymentStatus: BRIEF_PAYMENT_STATUS) => ({
      label: BRIEF_PAYMENT_STATUS_NAMES[paymentStatus],
      value: paymentStatus,
    })
  )

  useEffect(() => {
    const newParams = { ...params, search: debouncedSearch, offset: 0, limit: 10 }
    dispatch(actions.setParamsAndFetch({ ...newParams }, 'requests', partnerId))
    dispatch(actions.setCurrentPage(1))

    return () => dispatch(actions.setBillListParams(DEFAULT_BILL_TABLE_PARAMS))
  }, [debouncedSearch])

  const menu = (request: IBillingsListElement) => {
    return (
      <Menu className="user-menu-list">
        <QMenuItem
          key={0}
          icon={<IconsCommon.IconView />}
          name="View"
          onClick={() => handleView({ briefId: request.briefId, requestId: request.id })}
        />
      </Menu>
    )
  }

  const columns = [
    {
      title: 'Product',
      dataIndex: '',
      key: 'product',
      width: '25%',
      fixed: 'left',
      sorter: true,
      render: (request: IBillingsListElement) => {
        return (
          <div className="q-table-product">
            <div className="q-table-product_thumb">
              <img
                src={request.productImageUrl[0] ? request.productImageUrl[0] : 'https://via.placeholder.com/50x50'}
                alt={request.productName || ''}
              />
            </div>
            <div className="q-table-product_info">
              <h3 className="q-table-product_title">{request.productName || 'Unnamed Request'}</h3>
              <div className="q-table-product_created">
                <span>Received {request.lastApprovedDate}</span>
              </div>
            </div>
          </div>
        )
      },
    },
    {
      title: 'PO',
      dataIndex: 'payment',
      key: 'po',
      width: '17%',
      render: (payment: IBillingsPayment | null) => {
        return (
          <div onClick={(event: React.MouseEvent<HTMLDivElement>) => event.stopPropagation()}>
            <UploadComponent
              viewOnly
              alternativeFileView
              dragAndDrop={false}
              files={payment?.purchaseOrders?.map(({ po: url }) => ({ url, name: getFileNameFromUrl(url) })) ?? []}
              disableView={!hasPermission(permissions, PERMISSIONS.PARTNER_BILL_VIEW_DETAILS)}
              onView={async file => {
                const response: any = await dispatch(coreActions.downloadImage(file.url))
                const url = URL.createObjectURL(response.data)

                window.open(url, '_blank')
                URL.revokeObjectURL(url)
              }}
            />
          </div>
        )
      },
    },
    {
      title: 'Invoice',
      dataIndex: 'payment',
      key: 'invoice',
      width: '18%',
      render: (payment: IBillingsPayment | null, billing: IBillingsListElement) => {
        const paymentPaid = payment?.status === BRIEF_PAYMENT_STATUS.PAID
        const filesType: IBillingsPaymentUploadTypes = 'invoice'

        const isUploading = payment ? payment[filesType].isUploading : false
        const isRemoving = payment ? payment[filesType].isRemoving : false
        const isProcessing = isUploading || isRemoving
        return (
          <div onClick={(event: React.MouseEvent<HTMLDivElement>) => event.stopPropagation()}>
            {canUploadInvoice ? (
              <UploadComponent
                dragAndDrop={false}
                accept={'.pdf, .jpg, .jpeg, .png'}
                caption={['You can upload 1 file', 'Accepted file formats are: pdf, png, jpg, jpeg']}
                files={payment?.invoice.files ?? []}
                disabled={
                  !hasPermission(permissions, PERMISSIONS.PARTNER_BILL_VIEW_DETAILS) || isProcessing || !payment
                }
                disableView={!hasPermission(permissions, PERMISSIONS.PARTNER_BILL_VIEW_DETAILS)}
                showPreview={hasPermission(permissions, PERMISSIONS.PARTNER_BILL_VIEW_DETAILS)}
                showRemoveButton={hasPermission(permissions, PERMISSIONS.PARTNER_BILL_VIEW_DETAILS) && !paymentPaid}
                isUploading={isUploading}
                isRemoving={isRemoving}
                onChange={(files: File[]) => handleUpload({ bill: billing, files, filesType })}
                onRemove={file => handleRemove({ bill: billing, file, filesType })}
                onView={async file => {
                  const response: any = await dispatch(coreActions.downloadImage(file.url))
                  const url = URL.createObjectURL(response.data)

                  window.open(url, '_blank')
                  URL.revokeObjectURL(url)
                }}
              />
            ) : (
              <UploadComponent
                viewOnly
                alternativeFileView
                dragAndDrop={false}
                files={payment?.invoice.files ?? []}
                disableView={!hasPermission(permissions, PERMISSIONS.PARTNER_BILL_VIEW_DETAILS)}
                onView={async file => {
                  const response: any = await dispatch(coreActions.downloadImage(file.url))
                  const url = URL.createObjectURL(response.data)

                  window.open(url, '_blank')
                  URL.revokeObjectURL(url)
                }}
              />
            )}
          </div>
        )
      },
    },
    {
      title: 'Bank Transfer',
      dataIndex: 'payment',
      key: 'bankTransfer',
      width: '19%',
      render: (payment: IBillingsPayment | null, billing: IBillingsListElement) => {
        const filesType: IBillingsPaymentUploadTypes = 'bankTransfer'
        const isPaymentStatusPaid = payment?.status === BRIEF_PAYMENT_STATUS.PAID

        const invoiceUploaded = payment?.invoice.files.length

        const isUploading = payment ? payment[filesType].isUploading : false
        const isRemoving = payment ? payment[filesType].isRemoving : false
        const isProcessing = isUploading || isRemoving
        return payment ? (
          <div onClick={(event: React.MouseEvent<HTMLDivElement>) => event.stopPropagation()}>
            {!isPaymentStatusPaid && canUploadBankTransfer ? (
              <UploadComponent
                multiple
                dragAndDrop={false}
                maxCount={3}
                accept={'.pdf, .jpg, .jpeg, .png'}
                caption={['You can upload up to 3 files', 'Accepted file formats are: pdf, png, jpg, jpeg']}
                files={payment?.bankTransfer.files ?? []}
                disabled={
                  !hasPermission(permissions, PERMISSIONS.PARTNER_BILL_VIEW_DETAILS) ||
                  !payment ||
                  !invoiceUploaded ||
                  isProcessing
                }
                disableView={!hasPermission(permissions, PERMISSIONS.PARTNER_BILL_VIEW_DETAILS)}
                showPreview={hasPermission(permissions, PERMISSIONS.PARTNER_BILL_VIEW_DETAILS)}
                showRemoveButton={hasPermission(permissions, PERMISSIONS.PARTNER_BILL_VIEW_DETAILS)}
                isUploading={isUploading}
                isRemoving={isRemoving}
                onChange={(files: File[]) => handleUpload({ bill: billing, files, filesType })}
                onRemove={file => handleRemove({ bill: billing, file, filesType })}
                onView={async file => {
                  const response: any = await dispatch(coreActions.downloadImage(file.url))
                  const url = URL.createObjectURL(response.data)

                  window.open(url, '_blank')
                  URL.revokeObjectURL(url)
                }}
              />
            ) : (
              <UploadComponent
                viewOnly
                dragAndDrop={false}
                files={payment?.bankTransfer.files ?? []}
                showPreview={hasPermission(permissions, PERMISSIONS.PARTNER_BILL_VIEW_DETAILS)}
                disableView={!hasPermission(permissions, PERMISSIONS.PARTNER_BILL_VIEW_DETAILS)}
                onView={async file => {
                  const response: any = await dispatch(coreActions.downloadImage(file.url))
                  const url = URL.createObjectURL(response.data)

                  window.open(url, '_blank')
                  URL.revokeObjectURL(url)
                }}
              />
            )}
          </div>
        ) : (
          <span>-</span>
        )
      },
    },
    {
      title: 'Payment Status',
      dataIndex: '',
      key: 'paymentStatus',
      width: '19%',
      fixed: 'right',
      render: (request: IBillingsListElement) => {
        const paymentStatusClass = briefPaymentStatuses[request.payment?.status]?.classStyle ?? ''
        const paymentStatusLabel = briefPaymentStatuses[request.payment?.status]?.label ?? ''
        return (
          <div>
            {request.payment ? (
              <span className={`status-label ${paymentStatusClass}`}>{paymentStatusLabel}</span>
            ) : (
              <span>-</span>
            )}

            <div onClick={(event: React.MouseEvent<HTMLDivElement>) => event.stopPropagation()}>
              <Dropdown overlay={menu(request)} trigger={['click']} placement="bottomRight">
                <div className="q-table_menu">
                  <IconsCommon.IconEllipsisV />
                </div>
              </Dropdown>
            </div>
          </div>
        )
      },
    },
  ]

  const handleUpload = ({
    bill,
    files,
    filesType,
  }: {
    bill: IBillingsListElement
    files: File[]
    filesType: IBillingsPaymentUploadTypes
  }) => {
    dispatch(actions.uploadPartnerPaymentFile({ bill, files, filesType }))
  }

  const handleRemove = ({
    bill,
    file,
    filesType,
  }: {
    bill: IBillingsListElement
    file: IUploadFile
    filesType: IBillingsPaymentUploadTypes
  }) => {
    dispatch(actions.removePartnerPaymentFile({ bill, file, filesType }))
  }

  const handleChangeSearch = e => {
    setSearch(e.currentTarget.value)
  }

  const handleChangeSelect = (activePaymentStatuses: string[]) => {
    dispatch(
      actions.setParamsAndFetch({ ...params, offset: 0, paymentStatus: activePaymentStatuses }, 'requests', partnerId)
    )
  }

  const handleChangePage = (page, pageSize) => {
    dispatch(actions.setCurrentPage(page))
    dispatch(
      actions.setParamsAndFetch({ ...params, offset: (page - 1) * pageSize, limit: pageSize }, 'requests', partnerId)
    )
  }

  const handleTableChange = (pagination, filters, sorter) => {
    const colKey = {
      product: 'name',
    }
    const sortingParams = {
      direction: sorter.order.slice(0, -3),
      sortField: colKey[sorter.columnKey],
    }

    dispatch(
      actions.setParamsAndFetch(
        {
          ...params,
          ...sortingParams,
        },
        'requests',
        partnerId
      )
    )
  }

  const handleView = ({ briefId, requestId }: { briefId: number; requestId: number }) => {
    history.push(`/briefs/${briefId}/requests/${requestId}`)
  }

  const handleOnRowClick = (request: IBillingsListElement) => ({
    onClick: () => handleView({ briefId: request.briefId, requestId: request.id }),
  })

  return (
    <div className="qu-brief-list pb-20">
      <Row className={`${isMobile && 'pb-15'}`} justify={isMobile ? 'center' : 'space-between'}>
        <Col className="qt-search">
          <QInput
            className={`${isMobile && 'full-width'}`}
            value={search}
            onChange={handleChangeSearch}
            placeholder="Search"
            size="large"
            prefix={<SearchOutlined />}
          />
        </Col>
        {!isMobile && (
          <Col className="qt-filter">
            <QSelect
              showArrow
              maxTagTextLength={8}
              maxTagCount={2}
              value={params.paymentStatus}
              onChange={handleChangeSelect}
              mode="multiple"
              size="large"
              placeholder="Filter by status"
              options={briefPaymentStatusOptions}
            />
          </Col>
        )}
      </Row>

      <div className="pt-15 pb-30">
        <QTable
          onRow={handleOnRowClick}
          onChange={handleTableChange}
          sortDirections={['ascend', 'descend', 'ascend']}
          loading={{
            spinning: requestListLoading,
            indicator: <div className="loader loader-small" />,
          }}
          columns={columns}
          dataSource={requestList}
          scroll={{ x: 990 }}
          rowClassName="q-table cursorPointer"
        />
      </div>

      <Row justify={isMobile ? 'center' : 'end'} className={`${isMobile && 'pt-15 mb-30'}`}>
        <QPagination
          size="default"
          defaultCurrent={1}
          current={pages.currentPage}
          total={totalRequestList}
          onChange={handleChangePage}
        />
      </Row>
    </div>
  )
}

export default PartnerBillings
