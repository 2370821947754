import React, { forwardRef, ReactElement, useEffect, useImperativeHandle } from 'react'
import { Col, Row, Form, QInput, QDatePicker, QSelect } from 'quantum_components'
import { ICampaignDistribution } from '../../../campaign.types'
import { FORMATS, REGEXES } from '../../../../../constants'
import moment from 'moment'
import { ICity, IOptionForSelect } from '../../../../brief/brief.types'
import { VALIDATION_MESSAGE } from '../../../../../constants/validationMessages'

interface IProps {
  cities: ICity[]
  timeline: ICampaignDistribution
  onChange: (timeline: ICampaignDistribution) => void
}

const CampaignEditDistModal = forwardRef(
  ({ cities, timeline, onChange }: IProps, ref): ReactElement => {
    const [form] = Form.useForm()

    useImperativeHandle(ref, () => ({
      updateCart() {
        return handleSubmitForm()
      },
    }))

    const handleSubmitForm = () => {
      return form.validateFields()
    }

    useEffect(() => {
      const date = timeline.date ? moment(timeline.date) : ''
      const quantity = timeline.quantity
      const city = timeline.cityName
      const data = { date, city, quantity }
      form.setFieldsValue(data)
    }, [timeline])

    const handleDatePicker = (date: moment.Moment) => {
      const updated = { ...timeline }
      updated.date = date ? moment(date).format(FORMATS.DATE_LONG_FORMAT) : ''

      onChange(updated)
    }

    const handleCity = (cityId: string, city: IOptionForSelect) => {
      const updated = { ...timeline }
      updated.cityId = +cityId
      updated.cityName = city.label

      onChange(updated)
    }

    const handleQuantity = (e: any) => {
      const updated = { ...timeline }
      updated.quantity = +e.target.value

      onChange(updated)
    }

    const cityOptions: IOptionForSelect[] = cities.map((city: ICity) => {
      return { label: city.name, value: `${city.id}` }
    })

    return (
      <Form form={form} layout="vertical" hideRequiredMark onFinish={handleSubmitForm}>
        <Row gutter={20}>
          <Col span={8}>
            <Form.Item label="Date" name="date" rules={[{ required: true }]} validateTrigger={['onBlur', 'onChange']}>
              <QDatePicker
                format={FORMATS.DATE_LL_FORMAT}
                onChange={handleDatePicker}
                className="full-width"
                size="large"
                placeholder="Select Date"
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="City" name="city">
              <QSelect onChange={handleCity} size="large" options={cityOptions} placeholder="Select City" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Quantity"
              name="quantity"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  pattern: REGEXES.POSITIVE_NUMBERS_REGEX,
                  message: VALIDATION_MESSAGE.MORE_THAN_ZERO,
                },
              ]}
              validateTrigger={['onBlur', 'onChange']}
            >
              <QInput onChange={handleQuantity} size="large" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }
)

export default CampaignEditDistModal
