import React, { Fragment, ReactElement, useState } from 'react'
import BriefCartSummary from '../../modules/brief/containers/components/Brief.CartSummary'
import { IBriefResponse, ICartList } from '../../modules/brief/brief.types'
import { Col, QAlert, Row, ServicesSummary } from 'quantum_components'
import BriefViewSummary from '../../modules/brief/containers/components/Brief.View.Summary'
import { isMobile } from 'react-device-detect'
import BriefSwitch from '../../modules/brief/containers/components/Brief.Switch'
import BriefQuotation from '../../modules/brief/containers/briefPayment/Brief.Quotation'
import ServiceCard from '../../modules/brief/containers/components/Brief.Service'
import { BRIEF_PAYMENT_STATUS, BRIEF_PRINT_ID, BRIEF_STATUS, BRIEF_STEPS, BRIEF_TYPES } from '../../constants/brief'
import BriefProduct from '../../modules/brief/containers/components/Brief.Product'
import BriefMedia from '../../modules/brief/containers/components/Brief.Media'
import BriefTargeting from '../../modules/brief/containers/components/Brief.Targeting'
import BriefAdditional from '../../modules/brief/containers/components/Brief.Additional'
import { canEditBrief, canPartiallyEditBrief } from '../../helpers/rules'
import { useSelector } from 'react-redux'
import { getCurrentUserPermissionActions } from '../../modules/core/core.selectors'
import { hasPermission } from '../../helpers/permission'
import { PERMISSIONS } from '../../constants/permission'
import { BILL_REJECTION_REASONS } from '../../constants/bill'
import BriefReviewPayment from 'src/modules/brief/containers/components/Brief.ReviewPayment'
import { IBillPaymentUploadTypes } from '../../modules/bills/bills.types'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

interface IProps {
  briefView: IBriefResponse
  cart: ICartList
  setCurrentStep: (step: BRIEF_STEPS) => void
  openPartner: (id: number) => void
  rejection?: boolean
  hideProposalButtons?: boolean
  hidePaymentSection?: boolean
  showReviewPaymentSection?: boolean
  hideSubmitPaymentButton?: boolean
  onPaymentFilesChange?: (files: { files: File[]; filesType: IBillPaymentUploadTypes }[]) => void
}

export default function BriefEditSummaryComponent({
  briefView,
  cart,
  setCurrentStep,
  openPartner,
  rejection,
  hideProposalButtons = false,
  hidePaymentSection = false,
  showReviewPaymentSection = false,
  hideSubmitPaymentButton = false,
  onPaymentFilesChange,
}: IProps): ReactElement {
  const [showPartner, setShowPartner] = useState(false)
  const { t } = useTranslation(['translationABrief', 'translation'])
  const history = useHistory();

  const changeSwitch = (value: any) => setShowPartner(value.target.value)
  const isProductSample = briefView.briefTypes?.includes(BRIEF_TYPES.PRODUCT_SAMPLE)
  const isMediaBuying = briefView.briefTypes?.includes(BRIEF_TYPES.MEDIA_BUYING)
  const permissions = useSelector(getCurrentUserPermissionActions)

  const canReviewPayment = [BRIEF_PAYMENT_STATUS.PENDING, BRIEF_PAYMENT_STATUS.PO_ISSUED].includes(
    briefView.payment?.status
  )
  const canUpdateBrief = hasPermission(permissions, PERMISSIONS.BRIEF_CREATE_AND_UPDATE)

  const hideBriefEdit = !canEditBrief(briefView.status) || !canUpdateBrief
  const hideCartEdit = (!rejection && canPartiallyEditBrief(briefView.status)) || !canUpdateBrief

  return isMobile ? (
    <Fragment>
      <BriefSwitch onChange={changeSwitch} value={showPartner} />
      {briefView.status === BRIEF_STATUS.MISSING_INFO &&
        window.location.pathname.includes('/briefs/view') &&
        <QAlert
          message={
            <a onClick={() => history.push(`/users/clients/profile/${briefView.owner.id}`)}>{t("brief.incomplete-client-profile.Fill in required details in your profile in order to proceed with this Campaign", { ns: 'translation' })}</a>
          }
          type="warning"
          className="centered pt-10"
        />}
      {showPartner ? (
        <BriefCartSummary
          setCurrentStep={setCurrentStep}
          onEdit={openPartner}
          cart={cart}
          viewMode={hideCartEdit}
          hidePaymentSection={hidePaymentSection}
          hideProposalButtons={hideProposalButtons}
          hideSubmitPaymentButton={hideSubmitPaymentButton}
          onPaymentFilesChange={onPaymentFilesChange}
        />
      ) : (
        <Fragment>
          <ServiceCard
            isProductSample={isProductSample}
            isMediaBuying={isMediaBuying}
            onEdit={() => setCurrentStep(BRIEF_STEPS.TYPE)}
            viewMode={hideBriefEdit}
          />
          <BriefProduct
            briefView={briefView}
            onEdit={() => setCurrentStep(BRIEF_STEPS.PRODUCT)}
            viewMode={hideBriefEdit}
          />
          <BriefMedia
            briefView={briefView}
            onEdit={() => setCurrentStep(BRIEF_STEPS.MEDIA_BUY)}
            viewMode={hideBriefEdit}
          />
          <BriefTargeting
            briefView={briefView}
            onEdit={() => setCurrentStep(BRIEF_STEPS.TARGETING)}
            viewMode={hideBriefEdit}
          />
          <BriefAdditional
            briefView={briefView}
            onEdit={() => setCurrentStep(BRIEF_STEPS.SERVICES)}
            viewMode={hideBriefEdit}
          />
        </Fragment>
      )}
    </Fragment>
  ) : (
    <Row gutter={20} className="pt-20 pb-30" id={BRIEF_PRINT_ID}>
      {briefView.status === BRIEF_STATUS.MISSING_INFO && window.location.pathname.includes('/briefs/view') && <Col span={24}>
        <QAlert
          message={
            <a onClick={() => history.push(`/users/clients/profile/${briefView.owner.id}`)}>{t("brief.incomplete-client-profile.Fill in required details in your profile in order to proceed with this Campaign", { ns: 'translation' })}</a>
          }
          type="warning"
          className="centered pb-20"
        />
      </Col>}
      <Col span={16}>
        <BriefViewSummary briefView={briefView} setCurrentStep={setCurrentStep} viewMode={hideBriefEdit} />
      </Col>
      <Col span={8}>
        {showReviewPaymentSection && canReviewPayment && (
          <>
            <ServicesSummary title={t(`review_payment`)} />
            <BriefReviewPayment payment={briefView.payment} defaultReasons={BILL_REJECTION_REASONS} />
          </>
        )}
        <BriefCartSummary
          onEdit={openPartner}
          cart={cart}
          viewMode={hideCartEdit}
          setCurrentStep={setCurrentStep}
          hidePaymentSection={hidePaymentSection}
          hideProposalButtons={hideProposalButtons}
          hideSubmitPaymentButton={hideSubmitPaymentButton}
          onPaymentFilesChange={onPaymentFilesChange}
        />
      </Col>


      {!isEmpty(cart?.additional.items) &&
        (
          // <div style={{width: "100%", position: "absolute", opacity: 0, pointerEvents:"none"}}>
          <Col span={24} id="brief_quotation_print_area" hidden>
            <BriefQuotation briefView={briefView} cart={cart} />
          </Col>
          // </div>
        )
      }
    </Row>
  )
}
