import { IRequest, IRequestTable } from '../modules/partner/requests/request.types'
import moment from 'moment'
import { FORMATS } from '../constants'
import { CURRENCY } from '../constants/currencies'
import { renderRoundedNumber } from './currency'
import { DIALOG_MODALS, MEDIA_GROUP_TYPE } from '../constants/brief'

export const requestsToTable = (requests: IRequest[], currency: CURRENCY): IRequestTable[] => {
  if (!requests) {
    return []
  }

  return requests.map((request: IRequest) => {
    const startDate =
      request.type !== MEDIA_GROUP_TYPE.PRODUCT_SAMPLE && request.type !== MEDIA_GROUP_TYPE.PRINTED_INSERT
        ? request.startMediaDate
          ? moment(request.startMediaDate).format(FORMATS.DATE_LL_FORMAT)
          : '-'
        : request.startDate
        ? moment(request.startDate).format(FORMATS.DATE_LL_FORMAT)
        : '-'

    return {
      key: request.id,
      product: {
        image: request.productImageUrl ? request.productImageUrl[0] : null,
        title: request.productName,
        received: request.lastApprovedDate ? moment(request.lastApprovedDate).format(FORMATS.DATE_LL_FORMAT) : '-',
      },
      service: {
        title: request.type,
        startDate: startDate,
        duration: request.duration,
        revenue: renderRoundedNumber(request.revenue, currency),
      },
      partner: {
        partnerCompanyName: request.partner.companyName,
        partnerFirstName: request.partner.firstName,
        partnerLastName: request.partner.lastName,
      },
      status: request.status,
      payment: request.payment,
    } as IRequestTable
  })
}

export const getModalData = (modal?: DIALOG_MODALS | null) => {
  if (modal === DIALOG_MODALS.ASSIGN_PARTNER) {
    return {
      question: 'assign_to_partner',
      description: 'assign_request_to_a_partner',
      submitButtonText: 'no_cancel',
      cancelButtonText: 'yes_proceed',
    }
  }

  if (modal === DIALOG_MODALS.OVERRIDE_PAYMENT) {
    return {
      question: 'override_payment',
      description: 'do_you_want_to_override_payment',
      submitButtonText: 'no_cancel',
      cancelButtonText: 'yes_proceed',
    }
  }

  if (modal === DIALOG_MODALS.CREATE_CAMPAIGN) {
    return {
      question: 'create_new_campaign',
      description: 'add_this_request_and_create_new_campaign',
      submitButtonText: 'no_cancel',
      cancelButtonText: 'yes_proceed',
    }
  }

  if (modal === DIALOG_MODALS.COMPLETE_CAMPAIGN) {
    return {
      question: 'complete_this_campaign',
      description: 'you_will_not_be_able_to_start_the_campaign_again',
      submitButtonText: 'no_cancel',
      cancelButtonText: 'yes_proceed',
    }
  }

  return {
    question: 'proceed_action_confirm',
    description: `cannot_undo_confirm`,
    submitButtonText: 'no_cancel',
    cancelButtonText: 'yes_proceed',
  }
}
