import React, { ReactElement, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useReduxDispatch } from 'src/helpers'
import { Col, Form, QButton, QDatePicker, QInput, QSelect, Row } from 'quantum_components'
import { useSelector } from 'react-redux'
import { FORMATS } from 'src/constants'

import { validateMessages } from '../../../../../helpers/validationMessage'
import { ICampaignPartner, ICampaignDistribution } from '../../../campaign.types'
import { ICity } from '../../../../brief/brief.types'
import {
  getCampaignPartnerOnlyDistribution,
  getCampaignQuantityInfo,
  getCampaignSettings,
} from '../../../campaign.selectors'
import { actions } from '../../../campaign.module'
import moment from 'moment'
import { MEDIA_GROUP_TYPE } from '../../../../../constants/brief'
import { getIntersectionCities } from '../../../../../helpers/campaign'
import { getIsoDate } from '../../../../../helpers/datesHelper'
import { hasPermission } from '../../../../../helpers/permission'
import { getCurrentUserPermissionActions } from '../../../../core/core.selectors'
import { PERMISSIONS } from '../../../../../constants/permission'
import CampaignDistTable from './CampaignDistTable'

interface IProps {
  disabledButton: boolean
}

export default function CampaignEditDistForm({ disabledButton }: IProps): ReactElement {
  const dispatch = useReduxDispatch()
  const initialData = useSelector(getCampaignSettings)
  const distributionPartners = useSelector(getCampaignPartnerOnlyDistribution)
  const info = useSelector(getCampaignQuantityInfo)
  const permissions = useSelector(getCurrentUserPermissionActions)
  const [formCity] = Form.useForm()
  const { id } = useParams<{id: any}>()

  const [partner, setPartner] = useState<ICampaignPartner | undefined>(undefined)

  const [formData, setFormData] = useState({})
  const [cities, setCities] = useState<ICity[]>([])

  useEffect(() => {
    if (!partner) {
      return
    }
    setCities(getIntersectionCities(initialData.briefCities, partner.cities))
    const data = buildFormData()
    setFormData(data)

    fetchInfo(partner.id)
  }, [partner])

  const fetchInfo = async (partnerId: number) => {
    await dispatch(actions.fetchCampaignQuantityInfo(id, partnerId))
    await dispatch(actions.fetchCampaignDistribution(id, partnerId))
    await dispatch(actions.fetchCampaignSettings(id))
  }

  const buildFormData = async () => {
    const reducer = () => (acc: {}, city: ICity) => {
      const field = city.name

      formCity.setFieldsValue({ [field]: null })
      // @ts-ignore
      acc[field] = null
      return acc
    }
    formCity.setFieldsValue({ date: moment() })

    return cities.reduce(reducer(), {})
  }

  const handleOnFinish = async (values: any) => {
    const notEmptyCities = Object.keys(values).filter((city: string) => !!values[city] && city !== 'date')
    if (!partner || !notEmptyCities.length) return

    const data = notEmptyCities.map((cityName: string) => {
      const city = cities.find((city: ICity) => city.name === cityName)
      return {
        cityId: city?.id,
        quantity: values[cityName],
        date: getIsoDate(values.date),
      } as ICampaignDistribution
    })

    const timeLane = {
      partnerId: partner.id,
      data: data,
    }

    await dispatch(actions.campaignSaveDistribution(id, timeLane))
    setFormData(buildFormData())
    await dispatch(actions.fetchCampaignSettings(id))
    await fetchInfo(partner.id)
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target

    if (value === '' || value === null || value === undefined) {
      return
    }
    let num = +value
    if (num < 0) {
      num = 0
    }

    formCity.setFieldsValue({ [id]: num })
  }

  const onSelectPartner = (partnerId: number) => {
    const targetPartner = initialData.partnerList.find((partner: ICampaignPartner) => partner.id === partnerId)
    setPartner(targetPartner)
  }

  const partnerOptions = distributionPartners.map((partner: ICampaignPartner) => {
    const distributionType = partner.requestTypes.includes(MEDIA_GROUP_TYPE.PRODUCT_SAMPLE)
      ? MEDIA_GROUP_TYPE.PRODUCT_SAMPLE
      : MEDIA_GROUP_TYPE.PRINTED_INSERT
    return { label: `${partner.companyName} - ${distributionType}`, value: partner.id }
  })

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  }

  const renderInput = (name: string) => {
    return (
      <Form.Item label={name} name={name} validateTrigger={['onChange', 'onBlur']}>
        <QInput
          disabled={disabledButton}
          onChange={handleInputChange}
          type="number"
          min={0}
          placeholder="Enter number"
          size="large"
        />
      </Form.Item>
    )
  }

  return (
    <div>
      <Row className="pt-10 brief-list-header" justify="end">
        {/*<h2 className="pb-10">Edit Timeline</h2>*/}
        <h5>
          Campaign Total Quantity: <span className="text-primary">{initialData.totalQuantity}</span>
        </h5>
      </Row>
      <Row gutter={24} className="mb-40" justify={'start'}>
        <Col xs={24} sm={24} md={8}>
          <QSelect
            disabled={!hasPermission(permissions, PERMISSIONS.CAMPAIGN_EDIT_DISTRIBUTION)}
            showSearch
            size="large"
            style={{ width: '100%' }}
            placeholder="Select Partner"
            onChange={onSelectPartner}
            options={partnerOptions}
          />
        </Col>
        {!!partner && (
          <Col xs={24} sm={24} md={16}>
            <Row className="mt-8" justify="space-between" align="middle">
              <div className="font-size-16">
                Assigned Quantity: <b>{info.assigned}</b>
              </div>
              <div className="font-size-16">
                Distributed Quantity: <b>{info.distributed}</b>
              </div>
              <div className="font-size-16">
                Remaining Quantity: <b>{info.remaining < 0 ? 0 : info.remaining}</b>
              </div>
            </Row>
          </Col>
        )}
      </Row>

      {partner && (
        <Form
          className="mb-40"
          {...layout}
          form={formCity}
          hideRequiredMark
          validateMessages={validateMessages}
          initialValues={formData}
          onFinish={handleOnFinish}
        >
          <Row gutter={24} justify={'start'}>
            <Col xs={24} sm={24} md={8}>
              <h5 className="pb-10">Timeline</h5>
              <Form.Item label="Date" name="date" rules={[{ required: true }]} validateTrigger={['onBlur', 'onChange']}>
                <QDatePicker
                  disabled={disabledButton}
                  format={FORMATS.DATE_LL_FORMAT}
                  className="full-width"
                  size="large"
                  placeholder="Select Date"
                />
              </Form.Item>
              <h5 className="pb-10">Cities</h5>
              <Row gutter={16} justify={'start'}>
                {cities.map((city: ICity) => (
                  <Col key={city.id} xs={24} sm={24} md={24}>
                    {renderInput(city.name)}
                  </Col>
                ))}
                <Col xs={24} sm={24} md={24}>
                  <QButton
                    disabled={disabledButton}
                    className={`full-width ${disabledButton ? 'qu-button-outline' : ''}`}
                    type="primary"
                    htmlType="submit"
                    block
                  >
                    Save
                  </QButton>
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={16}>
              <CampaignDistTable partner={partner} cities={cities} fetchInfo={fetchInfo} />
            </Col>
          </Row>
        </Form>
      )}
    </div>
  )
}
