import { t } from "i18next";
import {
  Col,
  Dropdown,
  Form,
  IconsCommon,
  Menu,
  Modal,
  QButton,
  QInput,
  QMenuItem,
  qnotification,
  QSelect,
  QTextArea,
  Row,
  Table,
} from "quantum_components";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SpinnerCenter from "src/components/Spinner/spinner";
import { PERMISSIONS } from "src/constants/permission";
import { hasPermission } from "src/helpers/permission";
import i18n from "src/i18n";
import { getCurrentUserPermissionActions } from "src/modules/core/core.selectors";
import {
  createNativeTemplate,
  deleteNativeTemplate,
  editNativeTemplate,
  getAllNativeTemplates,
} from "../nativeTemplates.actions";
import {
  getDataLoading,
  nativeTemplatesList,
} from "../nativeTemplates.selectors";
import { ReactComponent as CloseIcon } from "src/assets/icons/close-icon.svg";
import { NOTIFICATION_TYPES } from "src/constants";
import { useTranslation } from "react-i18next";
import { Divider, Tag, Tooltip } from "antd";
import DialogModal from "src/components/DialogModal/DialogModal";
import {PushpinOutlined} from "@ant-design/icons"

const trimString = (text: string) => {
  const length = 30;
  if (text.length < length) return text;
  return text.substring(0, length) + "...";
};

const NativeTemplates = () => {
  const { t } = useTranslation(["translationNativeTemplates"]);
  const [modalOpen, setModalOpen] = useState(false);

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [initialValues, setInitialValues] = useState({
    name: "",
    rawHtml: "",
    rawJS:"",
    variables: [],
  });

  const nativeTemplateList = useSelector(nativeTemplatesList);
  const isLoading = useSelector(getDataLoading);
  const permissions = useSelector(getCurrentUserPermissionActions);
  const [isEditMode, setIsEditMode] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [selectedId, setSelectedId] = useState(-1);

  const handleMenuAction = (action: string, record: any) => {
    switch (action) {
      case "EDIT":
        setIsEditMode(true);
        setSelectedId(record.id);
        setShowModal(true);
        form.setFieldsValue({
          name: record.name,
          rawHtml: record.rawHtml,
          rawJS: record.rawJS,
          variables: record.variables,
        });
        break;
      case "DELETE":
        setSelectedId(record.id);
        setModalOpen(true);
        break;
      default:
        break;
    }
  };

  const menu = (record: any) => (
    <Menu
      className={`user-menu-list ${i18n.dir() === "rtl" ? "html-rtl" : ""}`}
    >
      {hasPermission(permissions, PERMISSIONS.EDIT_TARGETING_KEYS) && (
        <QMenuItem
          key={1}
          onClick={() => handleMenuAction("EDIT", record)}
          name={t(`Edit`)}
          icon={<IconsCommon.IconEdit />}
        />
      )}
      {hasPermission(permissions, PERMISSIONS.DELETE_TARGETING_KEYS) && (
        <QMenuItem
          key={2}
          onClick={() => handleMenuAction("DELETE", record)}
          name={t(`Delete`)}
          icon={<IconsCommon.IconDelete />}
        />
      )}
    </Menu>
  );

  const columns = [
    {
      title: t("Name"),
      dataIndex: "name",
      key: "name",
      width: "20%",
      fixed: "left",
      render: (name:any,record:any) => {
        return <div>
          {
            record.isPersistent && <PushpinOutlined className="mr-5"/>
          }
          {name}
        </div>
      }
    },
    {
      title: t("HTML"),
      dataIndex: "rawHtml",
      key: "rawHtml",
      width: "40%",
      fixed: "left",
      render: (rawHtml:any,record:any) => {
        return <div>
          {
            rawHtml.length == 90 ? rawHtml:rawHtml.substring(0,90)+"..."
          }
        </div>
      }
    },
    {
      title: t("Variables"),
      dataIndex: "variables",
      key: "variables",
      width: "30%",
      fixed: "left",
      render: (ops: any, record: any) => {
        return (
          <>
            {record.variables.map((variable: any) => {
              return (
                <div>
                  {variable.name} <b>({variable.placeholder})</b>
                </div>
              );
            })}
          </>
        );
      },
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: "10%",
      fixed: "right",
      render: (ops: [string], record: any) => {
        return (
          !record.isPersistent && (
            <div onClick={(e) => e.stopPropagation()}>
              <Dropdown
                overlay={menu(record)}
                trigger={["click"]}
                placement="bottomRight"
              >
                <div className={`q-table_menu`}>
                  <IconsCommon.IconEllipsisV />
                </div>
              </Dropdown>
            </div>
          )
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(getAllNativeTemplates());
  }, []);

  const resetForm = () => {
    form.resetFields(["name", "rawHtml","rawJS", "variables"]);
    setIsEditMode(false);
    setSelectedId(-1);
    setShowModal(false);
  };

  const deleteNativeTemplateById = async (nativeTemplateId: number) => {
    await dispatch(deleteNativeTemplate(nativeTemplateId));
    await dispatch(getAllNativeTemplates());
  };

  const handleOnFinish = async (data: any) => {
    const filledOptions = data.variables;

    if (filledOptions.length < 1) {
      qnotification({
        type: NOTIFICATION_TYPES.ERROR,
        message: t("Atleast 1 valid variable is required"),
      });
      return;
    }

    const updatedData = { ...data };

    if (isEditMode) {
      await dispatch(editNativeTemplate({ ...updatedData, id: selectedId }));
    } else {
      await dispatch(createNativeTemplate(updatedData));
    }
    await dispatch(getAllNativeTemplates());
    resetForm();
  };

  return (
    <>
      <div
        className={`flexRowContainer ${i18n.dir() === "rtl" ? "html-rtl" : ""}`}
      >
        <div>
          <h2>{t("Native Templates")}</h2>
          <p className="p--xs">
            {t(
              "You can specify different templates as they would show on your platform to use them in an Ad Campaign"
            )}
          </p>
        </div>

        <QButton
          type="dashed"
          htmlType="button"
          className="qu-button-soft"
          onClick={() => setShowModal(true)}
          disabled={isLoading}
        >
          {t(`Add New`)}
        </QButton>
      </div>

      <section>
        <Table
          className="mt-20"
          dataSource={nativeTemplateList}
          pagination={false}
          // @ts-ignore
          columns={columns}
          loading={isLoading}
        />
      </section>

      <DialogModal
        modalOpen={modalOpen}
        question={t(`Are you sure?`)}
        description={t(`This resource will be removed`)}
        submitButtonText={t(`cancel`)}
        cancelButtonText={t(`confirm`)}
        onClose={() => {
          setSelectedId(-1);
          setModalOpen(false);
        }}
        onProceed={() => {
          deleteNativeTemplateById(selectedId);
          setModalOpen(false);
          setSelectedId(-1);
        }}
        revert
      />

      <Modal
        wrapClassName={`qu-modal ${i18n.dir() === "rtl" ? "html-rtl" : ""}`}
        width={700}
        title={<h4>{isEditMode ? t("Edit") : t("Add New")}</h4>}
        centered={true}
        visible={showModal}
        closable={true}
        onCancel={() => resetForm()}
        footer={null}
      >
        <section className="mb-20">
          <Form
            className="mt-10"
            layout="vertical"
            initialValues={initialValues}
            form={form}
            onFinish={handleOnFinish}
            validateMessages={{
              required: t("Required field"),
            }}
            autoComplete={"off"}
          >
            <Row gutter={24} justify={"start"}>
              <Col xs={24} sm={24} md={24}>
                <Form.Item
                  label={t("Name")}
                  name="name"
                  rules={[{ required: true, message: t("Required Field") }]}
                  validateTrigger={["onChange", "onBlur"]}
                >
                  <QInput placeholder={t("Enter here")} size="large" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24} justify={"start"}>
              <Col xs={24} sm={24} md={24}>
                <Form.Item
                  label={t("HTML")}
                  name="rawHtml"
                  rules={[{ required: true, message: t("Required Field") }]}
                  validateTrigger={["onChange", "onBlur"]}
                  help={t("variable_placeholder_help")}
                >
                  <QTextArea
                    id="html_text_area"
                    placeholder={t("Enter here")}
                    size="large"
                    rows={7}
                    className="form-textarea"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24} justify={"start"}>
              <Col xs={24} sm={24} md={24}>
                <Form.Item
                  label={t("Javascript (Optional)")}
                  name="rawJS"
                  rules={[{ required: false, message: t("Required Field") }]}
                  validateTrigger={["onChange", "onBlur"]}
                  help={t("All JS should be written without the <script></script> tags. e.g. let a=1; b=2; ...")}
                >
                  <QTextArea
                    id="js_text_area"
                    placeholder={t("Enter here")}
                    size="large"
                    rows={7}
                    className="form-textarea"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24} justify={"start"}>
              <Col xs={24} sm={24} md={24}>
                <div>
                  <label>
                    <b>{t("Variables")}</b>
                  </label>
                </div>

                <Form.List name="variables">
                  {(fields, { add, remove }) => {
                    return (
                      <div key={"a"}>
                        <div
                          className="flexRowContainer mb-10"
                          style={{ gap: "10px" }}
                        >
                          <QButton
                            className="qu-button-outline mb-5 mt-5"
                            type="primary"
                            size="small"
                            htmlType="button"
                            onClick={() => add()}
                          >
                            {t("Add variable")}
                          </QButton>
                        </div>

                        <div key={"b"} className="PPField__inputList">
                          {fields.map((field, index) => (
                            <div key={field.key}>
                              <Divider>
                                {t("Variable")} # {index + 1}
                              </Divider>
                              <Form.Item
                                name={[index, "name"]}
                                label={t("Name")}
                                rules={[{ required: true }]}
                              >
                                <QInput
                                  className="form-textarea input-with-close-icon targeting-key-input"
                                  placeholder={t("Enter here")}
                                />
                              </Form.Item>
                              <Form.Item
                                name={[index, "placeholder"]}
                                label={t("Placeholder")}
                                rules={[{ required: true }]}
                                help={t(
                                  "Write the exact key name that you want to replace in the HTML content, EXAMPLE: [%test_key_name%]"
                                )}
                              >
                                <QInput
                                  className="form-textarea input-with-close-icon targeting-key-input"
                                  placeholder={t("Enter here")}
                                />
                              </Form.Item>
                              <Form.Item
                                label={t("Field Type")}
                                name={[index, "type"]}
                                rules={[{ required: true }]}
                              >
                                <QSelect
                                  showArrow
                                  maxTagTextLength={8}
                                  maxTagCount={2}
                                  // onChange={handleChangeSelect}
                                  size="large"
                                  placeholder={t("Select variable type")}
                                  options={[
                                    {
                                      label: t("Text"),
                                      value: "text",
                                    },
                                    {
                                      label: t("Number"),
                                      value: "number",
                                    },
                                  ]}
                                />
                              </Form.Item>
                              <Form.Item
                                label={
                                  <>
                                    <div className="mt-0">
                                      {t("Map to Product Field")}{" "}
                                      <Tooltip
                                        data-html="true"
                                        title={t(
                                          "Mapping Template variables to your Products fields allows your advertisers to see an automatic ad preview upon choosing a product during Campaign Creation."
                                        )}
                                      >
                                        <span
                                          style={{
                                            marginLeft: "5px",
                                            marginRight: "5px",
                                            fontWeight: "bold",
                                            fontSize: "15px",
                                            color: "#aaaaaa",
                                            cursor: "pointer",
                                          }}
                                        >
                                          ⓘ
                                        </span>
                                      </Tooltip>
                                    </div>
                                  </>
                                }
                                name={[index, "mappedProductField"]}
                                rules={[{ required: false }]}
                              >
                                <QSelect
                                  showArrow
                                  size="large"
                                  placeholder={t("Please select")}
                                  options={[
                                    {
                                      label: t("No Mapping"),
                                      value: "",
                                    },
                                    {
                                      label: t("Name"),
                                      value: "name",
                                    },
                                    {
                                      label: t("Description"),
                                      value: "description",
                                    },
                                    {
                                      label: t("Image Link"),
                                      value: "imageLink",
                                    },
                                    {
                                      label: t("Price"),
                                      value: "price",
                                    },
                                    {
                                      label: t("Product Link"),
                                      value: "productLink",
                                    },
                                    {
                                      label: t("Category"),
                                      value: "category",
                                    },
                                  ]}
                                />
                              </Form.Item>
                              {fields.length > 1 ? (
                                <QButton
                                  htmlType="button"
                                  type="primary"
                                  size="small"
                                  className="qu-button-outline"
                                  style={{ borderColor: "red", color: "red" }}
                                  onClick={() => remove(field.name)}
                                >
                                  {t("Remove")}
                                </QButton>
                              ) : null}
                            </div>
                          ))}
                        </div>
                      </div>
                    );
                  }}
                </Form.List>
              </Col>
            </Row>
            <Row gutter={24} justify={"start"} className="mt-10">
              <Col xs={24} sm={24} md={24}>
                <QButton
                  className="text-capitalize mx-auto"
                  type="primary"
                  htmlType="submit"
                  disabled={isLoading}
                >
                  {isEditMode ? t("Update") : t("Save")}
                </QButton>
              </Col>
            </Row>
          </Form>
        </section>
      </Modal>
    </>
  );
};
export default NativeTemplates;
