import IAction from '../../../interfaces/IAction'
import * as CONSTANTS from './profile.constants'
import get from 'lodash/get'
import { CLIENT_STEPS_EDIT } from './containers/ProfileClient.container'
import {
  CLIENT_STEPS,
  BASIC_INFO_INITIAL_VALUES,
  PRODUCT_TYPE_INITIAL_VALUES,
  TARGET_AUDIENCE_INITIAL_VALUES,
} from '../../../constants/clientProfile'
import { createObj } from '../../../helpers/createObjectWithOldFields'
export interface IClient {
  filledSteps: CLIENT_STEPS[]
  currentStep: CLIENT_STEPS
  fetchSettingsError: {}
  settingsLoading: boolean
  settings: {}
  imagesUploading: boolean
  imagesUploadingError: string
  imagesRemoving: boolean
  imagesRemovingError: string
  profilePicture: string
  fetchClientInfoError: {}
  clientInfo: {}
  filledData: {}
  clientInfoLoading: boolean
  profileUpdating: boolean
  percent: number
}

export const initialState = {
  filledSteps: [],
  currentStep: CLIENT_STEPS_EDIT[0],
  filledData: {
    ...BASIC_INFO_INITIAL_VALUES,
    ...PRODUCT_TYPE_INITIAL_VALUES,
    ...TARGET_AUDIENCE_INITIAL_VALUES,
  },
  fetchSettingsError: {},
  settingsLoading: false,
  settings: {},
  imagesUploading: false,
  imagesUploadingError: '',
  imagesRemoving: false,
  imagesRemovingError: '',
  profilePicture: '',
  clientInfo: {},
  fetchClientInfoError: '',
  clientInfoLoading: false,
  profileUpdating: false,
  percent: 0,
}

export default (state: IClient = initialState, action: IAction): IClient => {
  switch (action.type) {
    case CONSTANTS.SET_CURRENT_STEP:
      const currentStep = get(action, ['payload', 'step'])
      return { ...state, currentStep }
    case CONSTANTS.SET_FILLED:
      const filledList = get(action, ['payload', 'step'])
      return { ...state, filledSteps: [...state.filledSteps, filledList] }
    case CONSTANTS.SET_STEP_DATA:
      const filledStep = get(action, ['payload', 'stepData'])
      return { ...state, filledData: { ...state.filledData, ...filledStep } }
    case CONSTANTS.FETCH_CLIENT_SETTINGS_REQUEST:
      return {
        ...state,
        fetchSettingsError: initialState.fetchSettingsError,
        settingsLoading: true,
      }
    case CONSTANTS.FETCH_CLIENT_SETTINGS_SUCCESS:
      return {
        ...state,
        settings: get(action, 'payload.settings'),
        settingsLoading: false,
      }
    case CONSTANTS.FETCH_CLIENT_SETTINGS_FAILURE:
      return { ...state, fetchSettingsError: action.error, settingsLoading: false }

    case CONSTANTS.IMAGE_UPLOAD_REQUEST:
      return { ...state, imagesUploading: true }
    case CONSTANTS.IMAGE_UPLOAD_SUCCESS:
      return {
        ...state,
        filledData: {
          ...state.filledData,
          profilePicture: get(action, 'payload.res')[0],
        },
        imagesUploading: false,
      }
    case CONSTANTS.IMAGE_UPLOAD_FAILURE:
      return { ...state, imagesUploadingError: action.error, imagesUploading: false }

    case CONSTANTS.IMAGE_REMOVE_REQUEST:
      return { ...state, imagesRemoving: true }
    case CONSTANTS.IMAGE_REMOVE_SUCCESS:
      return {
        ...state,
        profilePicture: get(action, 'payload.brief'),
        imagesRemoving: false,
      }
    case CONSTANTS.IMAGE_REMOVE_FAILURE:
      return { ...state, imagesRemovingError: action.error, imagesRemoving: false }
    case CONSTANTS.FETCH_CLIENT_INFO_REQUEST:
      return {
        ...state,
        fetchClientInfoError: initialState.fetchClientInfoError,
        clientInfoLoading: true,
      }
    case CONSTANTS.FETCH_CLIENT_INFO_SUCCESS:
      const clientInfo = get(action, 'payload.clientInfo')
      return {
        ...state,
        clientInfo,
        filledData: createObj(initialState.filledData, clientInfo),
        clientInfoLoading: false,
      }
    case CONSTANTS.FETCH_CLIENT_INFO_FAILURE:
      return { ...state, fetchClientInfoError: action.error, clientInfoLoading: false }

    case CONSTANTS.CREATE_CLIENT_REQUEST:
      return { ...state, profileUpdating: true }
    case CONSTANTS.CREATE_CLIENT_SUCCESS:
      return { ...state, profileUpdating: false }
    case CONSTANTS.CREATE_CLIENT_FAILURE:
      return { ...state, profileUpdating: false }

    case CONSTANTS.SET_PERCENT:
      const percent = get(action, 'payload.percent') ? get(action, 'payload.percent') : 0
      return { ...state, percent }
    default:
      return state
  }
}
