import { createSelector } from 'reselect'
import { MODULE_NAME } from './subscriptionHistory.constants'
import { IState, ISubscription, ISubscriptionHistory } from './subscriptionHistory.types'

const selectState = (state: { [MODULE_NAME]: any }) => state[MODULE_NAME]

export const getIsSubscriptionHistoryListLoading = createSelector(
  selectState,
  (state: IState): boolean => state.subscriptionHistoryListLoading
)

export const getLatestSubscription = createSelector(selectState, (state: IState): ISubscription | null =>
  state.subscriptionHistoryList.latestSubscription
)

export const getSubscriptionHistoryList = createSelector(selectState, (state: IState): ISubscriptionHistory[] =>
  state.subscriptionHistoryList.results.map((subscriptionHistory: ISubscriptionHistory, index: number) => {
    return {
      ...subscriptionHistory,
      key: index,
    }
  })
)

export const getSubscriptionHistoryTotal = createSelector(
  selectState,
  (state: IState): number => state.subscriptionHistoryList.total
)

export const getPages = createSelector(
  selectState,
  (
    state: IState
  ): {
    totalPages: number
    currentPage: number
  } => state.pages
)
