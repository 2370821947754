import { createSelector } from 'reselect'
import { MODULE_NAME } from './referral.constants'
import { IState, IReferral, IReferralTable, ICoupon } from './referral.types'
import moment from 'moment'
import { FORMATS } from '../../constants'

const selectState = (state: { [MODULE_NAME]: any }) => state[MODULE_NAME]

export const getIsReferralListLoading = createSelector(
  selectState,
  (state: IState): boolean => state.referralListLoading
)

export const getReferralList = createSelector(selectState, (state: IState): IReferralTable[] =>
  state.referralList.results.map((referral: IReferral) => {
    return {
      ...referral,
      key: referral.id,
      userFullName: `${referral.user.firstName} ${referral.user.lastName}`,
      redeemed: referral.isRedeemed ? 'Yes' : 'No',
      startedDate: referral.startedDate ? moment(referral.startedDate).format(FORMATS.DATE_LL_FORMAT) : '-',
    }
  })
)

export const getReferralTotal = createSelector(selectState, (state: IState): number => state.referralList.total)

export const getPages = createSelector(
  selectState,
  (
    state: IState
  ): {
    totalPages: number
    currentPage: number
  } => state.pages
)

export const getCoupons = createSelector(selectState, (state: IState): ICoupon[] => state.coupons)
