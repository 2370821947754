import { IState } from './releaseNotes.types'
import * as CONSTANTS from './releaseNotes.constants'

const initialState: IState = {
  releaseNotes: null,
  releaseNotesActionLoading: false,
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case CONSTANTS.RESET_RELEASE_NOTES:
      return initialState
    case CONSTANTS.FETCH_RELEASE_NOTES_REQUEST:
      return {
        ...state,
        releaseNotesActionLoading: true,
      }
    case CONSTANTS.FETCH_RELEASE_NOTES_SUCCESS:
      return {
        ...state,
        releaseNotes: action.payload,
        releaseNotesActionLoading: false,
      }
    case CONSTANTS.FETCH_RELEASE_NOTES_FAILURE:
      return {
        ...state,
        releaseNotesActionLoading: false,
      }
    default:
      return state
  }
}
