import * as CONSTANTS from "./users.constants";
import { IState, IUserListElement } from "./users.types";

const initialState: IState = {
  userListLoading: true,
  publishersListLoading: false,
  usersList: {
    results: [],
    total: 0,
  },
  publishersList: [],
  pages: {
    totalPages: 1,
    currentPage: 1,
  },
  currentUser: null,
  partnerPropertiesLoading: false,
  partnerProperties: {
    id: null,
    userId: null,
    capability: null,
    industries: [],
    genderFocus: {},
    ageGroups: {},
    segments: {},
    logisticInsertRates: {},
    logisticSampleRates: {},
    isExclusive: false,
    isMarketplaceShown: false,
    hasDynamicSamplePricing: false,
    hasDistributionTypePricing: false,
    hasSampleProductTypePricing: false,
    additional: {
      stickerCost: null,
    },
  },
  partnerSettings: {
    capabilities: [],
    industries: [],
    segments: [],
    genderFocus: [],
    ageGroups: [],
    samples: [],
    rejectionReasons: [],
  },
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case CONSTANTS.RESET_USERS_STATE:
      return initialState;

    case CONSTANTS.FETCH_USERS_REQUEST:
      return {
        ...state,
        userListLoading: true,
      };
    case CONSTANTS.FETCH_PUBLISHERS_REQUEST:
      return {
        ...state,
        publishersListLoading: true,
      };
    case CONSTANTS.FETCH_PUBLISHERS_FAILURE:
      return {
        ...state,
        publishersListLoading: false,
      };
    case CONSTANTS.BAN_USER_REQUEST:
      return {
        ...state,
        userListLoading: true,
      };
    case CONSTANTS.ENABLE_PAYMENT_USER_REQUEST:
      return {
        ...state,
        userListLoading: true,
      };
    case CONSTANTS.DISABLE_PAYMENT_USER_REQUEST:
      return {
        ...state,
        userListLoading: true,
      };
    case CONSTANTS.ENABLE_PAYMENT_USER_SUCCESS:
      return {
        ...state,
        usersList: {
          results: state.usersList.results.map((e: IUserListElement) => {
            if (e.id === action.payload.userId) {
              return { ...e, paymentEnabled: true, bankTransfer: action.payload.data.bankTransfer, purchaseOrder: action.payload.data.purchaseOrder, onlinePayment: action.payload.data.onlinePayment };
            }
            return e;
          }),
          total: state.usersList.total,
        },
        userListLoading: false,
      };
    case CONSTANTS.DISABLE_PAYMENT_USER_SUCCESS:
      const disablePaymentUserID = action.payload;
      return {
        ...state,
        usersList: {
          results: state.usersList.results.map((e: IUserListElement) => {
            if (e.id === disablePaymentUserID) {
              return { ...e, paymentEnabled: false };
            }
            return e;
          }),
          total: state.usersList.total,
        },
        userListLoading: false,
      };
    case CONSTANTS.BAN_USER_SUCCESS:
      const bannedUserId = action.payload;
      return {
        ...state,
        usersList: {
          results: state.usersList.results.map((e: IUserListElement) => {
            if (e.id === bannedUserId) {
              return { ...e, isBanned: true };
            }
            return e;
          }),
          total: state.usersList.total,
        },
        userListLoading: false,
      };
    case CONSTANTS.BAN_USER_FAILURE:
      return {
        ...state,
        userListLoading: false,
      };
    case CONSTANTS.ENABLE_PAYMENT_USER_FAILURE:
      return {
        ...state,
        userListLoading: false,
      };
    case CONSTANTS.DISABLE_PAYMENT_USER_FAILURE:
      return {
        ...state,
        userListLoading: false,
      };
    case CONSTANTS.UNBAN_USER_REQUEST:
      return {
        ...state,
        userListLoading: true,
      };
    case CONSTANTS.UNBAN_USER_SUCCESS:
      const unbannedUserId = action.payload;
      return {
        ...state,
        usersList: {
          results: state.usersList.results.map((e: IUserListElement) => {
            if (e.id === unbannedUserId) {
              return {
                ...e,
                isBanned: false,
              };
            }
            return e;
          }),
          total: state.usersList.total,
        },
        userListLoading: false,
      };
    case CONSTANTS.UNBAN_USER_FAILURE:
      return {
        ...state,
        userListLoading: false,
      };

    case CONSTANTS.ALLOW_UPLOAD_PO_REQUEST:
      return {
        ...state,
        userListLoading: true,
      };

    case CONSTANTS.ALLOW_UPLOAD_PO_SUCCESS: {
      const userId = action.payload;
      return {
        ...state,
        usersList: {
          results: state.usersList.results.map((user: IUserListElement) => {
            if (user.id === userId) {
              return {
                ...user,
                poEnabled: true,
              };
            }
            return user;
          }),
          total: state.usersList.total,
        },
        userListLoading: false,
      };
    }

    case CONSTANTS.ALLOW_UPLOAD_PO_FAILURE:
      return {
        ...state,
        userListLoading: false,
      };

    case CONSTANTS.RESTRICT_UPLOAD_PO_REQUEST:
      return {
        ...state,
        userListLoading: true,
      };

    case CONSTANTS.RESTRICT_UPLOAD_PO_SUCCESS: {
      const userId = action.payload;
      return {
        ...state,
        usersList: {
          results: state.usersList.results.map((user: IUserListElement) => {
            if (user.id === userId) {
              return {
                ...user,
                poEnabled: false,
              };
            }
            return user;
          }),
          total: state.usersList.total,
        },
        userListLoading: false,
      };
    }

    case CONSTANTS.RESTRICT_UPLOAD_PO_FAILURE:
      return {
        ...state,
        userListLoading: false,
      };

    case CONSTANTS.FETCH_USERS_SUCCESS:
      const { usersList, infinite } = action.payload;
      let list = { ...state.usersList };
      if (infinite) {
        list.results = [...list.results, ...usersList.results];
        list.total = usersList.total;
      } else {
        list = usersList;
      }
      return {
        ...state,
        usersList: list,
        userListLoading: false,
      };
    case CONSTANTS.FETCH_USERS_FAILURE:
      return { ...state, userListLoading: false };

    case CONSTANTS.FETCH_PUBLISHERS_SUCCESS:
      const { publishersList } = action.payload;
      return {
        ...state,
        publishersList,
        publishersListLoading: false,
      };

    case CONSTANTS.SET_CURRENT_USER:
      const user = action.payload;
      return {
        ...state,
        currentUser: user,
      };

    case CONSTANTS.FETCH_PARTNER_PROPERTIES_REQUEST:
      return {
        ...state,
        partnerPropertiesLoading: true,
      };
    case CONSTANTS.FETCH_PARTNER_PROPERTIES_SUCCESS:
      const partnerProperties = action.payload;
      return {
        ...state,
        partnerProperties,
        partnerPropertiesLoading: false,
      };
    case CONSTANTS.FETCH_PARTNER_PROPERTIES_FAILURE:
      return { ...state, partnerPropertiesLoading: false };
    case CONSTANTS.RESET_PARTNER_PROPERTIES:
      return { ...state, partnerProperties: initialState.partnerProperties };

    case CONSTANTS.FETCH_PARTNER_SETTINGS_SUCCESS:
      const partnerSettings = action.payload;
      return {
        ...state,
        partnerSettings,
      };
    case CONSTANTS.FETCH_PARTNER_SETTINGS_FAILURE:
      return { ...state };
    case CONSTANTS.SET_CURRENT_PAGE:
      return {
        ...state,
        pages: {
          ...state.pages,
          currentPage: action.payload.page,
        },
      };
    case CONSTANTS.PAYMENT_METHODS_REQUEST:
      return { ...state, userListLoading: true };
    case CONSTANTS.PAYMENT_METHODS_SUCCESS:
      return {
        ...state,
        usersList: {
          results: state.usersList.results.map((e: IUserListElement) => {
            if (e.id === action.payload.userId) {
              return { ...e, bankTransfer: action.payload.bankTransfer, purchaseOrder: action.payload.purchaseOrder, onlinePayment: action.payload.onlinePayment };
            }
            return e;
          }),
          total: state.usersList.total,
        },
        userListLoading: false,
      };
    case CONSTANTS.PAYMENT_METHODS_FAILURE:
      return { ...state, userListLoading: false };
    default:
      return state;
  }
};
