import React from 'react'
import { Row, Col } from 'quantum_components'
import { IBriefResponse } from '../../brief.types'
import { useSelector } from 'react-redux'
import { getBriefSettings, getServiceImage } from '../../brief.selectors'
import { useTranslation } from 'react-i18next'

interface Props {
  briefView: IBriefResponse
  onEdit: () => void
  viewMode?: boolean
}

const BriefAdditional = ({ briefView, onEdit, viewMode }: Props) => {
  const serviceImage = useSelector(getServiceImage)
  const briefSettings = useSelector(getBriefSettings)
  const {t} = useTranslation(['translationABrief'])

  return (
    <div className="qu-view-card m-10">
      <div className="qu-card-header">
        <h4>{t('additional_info')}</h4>
        {!viewMode && <span onClick={onEdit}>{t('edit')}</span>}
      </div>
      <Row gutter={16}>
        <Col span={12}>
          <div className="data-view mb-15">
            <div className="data-view-label">{t('packaging_type')}</div>
            <div className="data-view-value">{briefView.servicePackageType?.join(', ') || t('none')}</div>
          </div>
        </Col>
        <Col span={12}>
          <div className="data-view mb-15">
            <div className="data-view-label">{t('temprature_condition')}</div>
            <div className="data-view-value">{briefView.serviceTemperature || t('none')}</div>
          </div>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <div className="data-view mb-15">
            <div className="data-view-label">{t('samples_per_bundle')}</div>
            <div className="data-view-value">{briefView.servicePackagingQuantity || t('none')}</div>
          </div>
        </Col>
        <Col span={12}>
          <div className="data-view mb-15">
            <div className="data-view-label">{t('copacking_needs')}</div>
            <div className="data-view-value">
              {briefSettings?.copackingPrices?.filter(ele => ele.id == briefView.copackingPriceId).length > 0
                ? briefSettings?.copackingPrices?.filter(ele => ele.id == briefView.copackingPriceId)[0].name
                : t('none')}
            </div>
          </div>
        </Col>
        <Col span={12}>
          <div className="data-view mb-15">
            <div className="data-view-label">{t('printing_needs')}</div>
            <div className="data-view-value">
              {briefSettings?.printingPrices?.filter(ele => ele.id == briefView.printingPriceId).length > 0
                ? briefSettings?.printingPrices?.filter(ele => ele.id == briefView.printingPriceId)[0].name
                : t('none')}
            </div>
          </div>
        </Col>
      </Row>
      <div className="additional-info mb-15">
        <ul className="p p--sm cart-summary__ulist mb-0">
          <li>{t('sample_is_liquid')}</li>
          <li className="disabled">{t('include_coupon')}</li>
          <li className="disabled">{t('require_insert')}</li>
          <li className="disabled">{t('require_copacking')}</li>
          <li className="disabled">{t('require_printing')}</li>
        </ul>
      </div>
      <div className="qu-view-card-image">
        <img src={serviceImage} alt="product" />
      </div>
    </div>
  )
}
export default BriefAdditional
