import React, { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SearchOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import { useReduxDispatch } from 'src/helpers'
import { isMobile } from 'react-device-detect'
import * as actions from 'src/modules/partner/requests/request.actions'
import {
  Dropdown,
  IconsCommon,
  Menu,
  QInput,
  QMenuItem,
  QPagination,
  QTable,
  RequestCardItem,
} from 'quantum_components'
import { useSelector } from 'react-redux'
import { getPages, getRequestParams, getTotalRequestList } from 'src/modules/partner/requests/request.selectors'
import useDebounce from 'src/hooks/useDebounce'
import {
  IRequestTable,
  IRequestTableProduct,
  IRequestTablePartner,
  IRequestTableService,
} from 'src/modules/partner/requests/request.types'
import {
  LOCALIZATION_LANGUAGES,
} from "src/constants";
import { REQUEST_STATUS, requestStatusesLabel } from 'src/constants/request'
import { BRIEF_PAYMENT_STATUS, MEDIA_GROUP_TYPE, briefPaymentStatuses } from 'src/constants/brief'
import { ReactComponent as EyeIcon } from '../../assets/icons/eye-icon.svg'
import { hasPermission } from '../../helpers/permission'
import { PERMISSIONS } from '../../constants/permission'
import { getCurrentUserPermissionActions } from '../../modules/core/core.selectors'
import { IBillListElementPaymentDto } from '../../modules/bills/bills.types'

interface IProps {
  briefId: number
  requestList: IRequestTable[]
  loading: boolean
  hideSearch?: boolean
  hidePagination?: boolean
}
export default function RequestTableComponent({
  briefId,
  requestList,
  loading,
  hideSearch,
  hidePagination,
}: IProps): ReactElement {
  const { i18n } = useTranslation()
  const { t } = useTranslation(['translationCampaign', 'translationCommon'])
  const history = useHistory()
  const dispatch = useReduxDispatch()
  const params = useSelector(getRequestParams)
  const totalRequestList = useSelector(getTotalRequestList)
  const pages = useSelector(getPages)
  const permissions = useSelector(getCurrentUserPermissionActions)

  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 500)

  const tableLoading = {
    spinning: loading,
    indicator: <div className="loader loader-small" />,
  }

  useEffect(() => {
    dispatch(actions.setParamsAndFetch(briefId, { ...params, search: debouncedSearch, offset: 0, limit: 10 }))
    dispatch(actions.setCurrentPage(1))
  }, [debouncedSearch])

  const handleChangeSearch = (e: any) => {
    setSearch(e.currentTarget.value)
  }

  const handleChangePage = async (page: number, pageSize: number) => {
    await dispatch(actions.setCurrentPage(page))
    await dispatch(actions.setParamsAndFetch(briefId, { ...params, offset: (page - 1) * pageSize, limit: pageSize }))
  }

  const handleView = async (requestId: number) => {
    history.push(`/briefs/${briefId}/requests/${requestId}`)
  }

  const menu = (id: number) => (
    <Menu className={`user-menu-list ${i18n.language === LOCALIZATION_LANGUAGES.ARABIC ? 'html-rtl' : ''}`}>
      {hasPermission(permissions, PERMISSIONS.REQUEST_DETAILS) && (
        <QMenuItem key={0} onClick={() => handleView(id)} name={t('common.View', {ns: 'translationCommon'})} icon={<EyeIcon />} />
      )}
    </Menu>
  )

  const columns = [
    {
      title: t('requests.Product'),
      dataIndex: 'product',
      key: 'product',
      width: '25%',
      fixed: 'left',
      render: (product: IRequestTableProduct, record: IRequestTable) => {
        return (
          <div className="q-table-product">
            <div className="q-table-product_thumb">
              {product.image && <img src={product.image} alt={product.title} />}
            </div>
            <div className="q-table-product_info">
              <h3 className="q-table-product_title">{product.title || ''}</h3>
              <div className="q-table-product_created">
                <span className={record.status === REQUEST_STATUS.REVIEW_NOW ? 'text-primary' : ''}>
                  {t('common.Received', {ns: 'translationCommon'})} {product.received}
                </span>
              </div>
            </div>
          </div>
        )
      },
    },
    {
      title: t('requests.Start Date'),
      dataIndex: 'service',
      key: 'service',
      width: '15%',
      render: (service: IRequestTableService) => (
        <div>
          <div>{service.startDate}</div>
        </div>
      ),
    },
    {
      title: t('requests.Revenue'),
      dataIndex: 'service',
      key: 'service',
      width: '15%',
      render: (service: IRequestTableService) => (
        <span className="font-size-14 font-weight-600">{service.revenue}</span>
      ),
    },
    {
      title: t('requests.Status'),
      dataIndex: 'status',
      key: 'status',
      width: '13%',
      render: (status: REQUEST_STATUS, record: IRequestTable) => {
        return (
          <div>
            <span className={`status-label ${requestStatusesLabel[status] && requestStatusesLabel[status].classStyle}`}>
              {requestStatusesLabel[status] ? t(requestStatusesLabel[status].label) : t(requestStatusesLabel['REVIEW NOW'].label)}
            </span>

            {/* QUA-1449, QP-492 - prevent event bubbling to onRowClick */}
            <div onClick={e => e.stopPropagation()}>
              <Dropdown overlay={menu(record.key)} trigger={['click']} placement="bottomRight">
                <div className="q-table_menu">
                  <IconsCommon.IconEllipsisV />
                </div>
              </Dropdown>
            </div>
          </div>
        )
      },
    },
  ]

  const handleOnRowClick = (request: IRequestTable) => ({
    onClick: (event: any) => history.push(`/briefs/${briefId}/requests/${request.key}`),
  })

  return (
    <div>
      <div className="qt">
        <div className="qu-statistic-wrapper">
          <h1>{t('requests.Requests')}</h1>
        </div>
        {!hideSearch && (
          <div className="qt-header">
            <div className="qt-search">
              <QInput
                className={`${isMobile && 'full-width'}`}
                value={search}
                onChange={handleChangeSearch}
                placeholder="Search"
                size="large"
                prefix={<SearchOutlined />}
              />
            </div>
          </div>
        )}
        {isMobile ? (
          <div className="m-30">
            <ul className="qu-request-list">
              {requestList.map((item: IRequestTable) => (
                <RequestCardItem
                  key={item.key}
                  data={item}
                  onClick={() => handleView(item.key)}
                  paymentStatus={briefPaymentStatuses}
                />
              ))}
            </ul>
          </div>
        ) : (
          <div className="qt-body">
            <QTable
              //@ts-ignore
              onRow={handleOnRowClick}
              //@ts-ignore
              loading={tableLoading}
              columns={columns}
              dataSource={requestList}
              scroll={{ x: 990 }}
              rowClassName="q-table cursorPointer"
            />
          </div>
        )}
        {!hidePagination && (
          <div className="qt-footer">
            <QPagination
              size="small"
              defaultCurrent={1}
              current={pages.currentPage}
              total={totalRequestList}
              onChange={handleChangePage}
            />
          </div>
        )}
      </div>
    </div>
  )
}
