import React, { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { snakeCase } from 'lodash'
import { Col, Form, IconsCommon, Modal, QButton, QInput, qnotification, QSelect, Row } from 'quantum_components'
import { useDispatch, useSelector } from 'react-redux'
import {
  getCampaignMediaBuyData,
  getCampaignMediaBuyFilter,
  getCampaignMetrics,
  getCampaignPartnerOnlyMediaBuy,
  getCampaignPartners,
} from '../../../campaign.selectors'
import {
  ICampaignMediaBuyData,
  ICampaignMediaBuyFilter,
  ICampaignMetrics,
  ICampaignPartner,
} from '../../../campaign.types'
import { GENDER, LOGISTIC_RATES, MEDIA_GROUP_TYPE } from '../../../../../constants/brief'
import {
  CAMPAIGN_CALCULATED_METRICS,
  CAMPAIGN_METRICS,
  CAMPAIGN_METRICS_INPUTS,
  CAMPAIGN_METRICS_NAMES,
} from '../../../../../constants/campaign'
import { IOptionForSelect } from '../../../../brief/brief.types'
import { REGEXES, LOCALIZATION_LANGUAGES, NOTIFICATION_TYPES } from '../../../../../constants'
import { VALIDATION_MESSAGE } from '../../../../../constants/validationMessages'
import MediaBuyMetrics from '../../../../../components/MediaBuyMetrics/MediaBuyMetrics'
import { getConstantAgeGroups, getConstantsGenders } from '../../../../core/core.selectors'
import { actions } from '../../../campaign.module'
import { useParams } from 'react-router-dom'
import { calculateGrowthRate, calculateRate, subtypeNamesByPartner } from '../../../../../helpers/campaign'
import SpinnerCenter from '../../../../../components/Spinner/spinner'

const CampaignEditMediaBuy = () => {
  const { i18n } = useTranslation()
  const { t } = useTranslation(['translationCampaign', 'translationCommon', 'translationABrief'])
  const dispatch = useDispatch()

  // const mediaBuyPartners = useSelector(getCampaignPartnerOnlyMediaBuy)
  const mediaBuyPartners = useSelector(getCampaignPartners)

  useEffect(() => {
    if (mediaBuyPartners.length < 1) return
    setPartner(mediaBuyPartners[0])
  }, [mediaBuyPartners])

  const metrics = useSelector(getCampaignMetrics)
  const filters = useSelector(getCampaignMediaBuyFilter)
  const mediaBuyList = useSelector(getCampaignMediaBuyData)

  const settingsAgeGroups = useSelector(getConstantAgeGroups)
  const settingsGenders = useSelector(getConstantsGenders)

  const [form] = Form.useForm()
  const [partner, setPartner] = useState<ICampaignPartner | undefined>(undefined)
  const [isRemoteCampaign, setIsRemoteCampaign] = useState(false)
  const [mediaType, setMediaType] = useState<string>('')
  const [selectedMetrics, setSelectedMetrics] = useState<CAMPAIGN_METRICS[]>([])
  const [showPreview, setShowPreview] = useState<boolean>(false)
  const [formData, setFormData] = useState<ICampaignMetrics | undefined>(undefined)
  const [loadingAnalytics, setLoadingAnalytics] = useState<boolean>(false)

  const { id } = useParams<{id: any}>()

  useEffect(() => {
    removeBoth()
  }, [settingsGenders])

  useEffect(() => {
    fetchMediaBuy()

    if (mediaType) {
      fillForm()
      fillSelectedMetrics()
    }
  }, [partner, mediaType])

  const fillSelectedMetrics = () => {
    const filter = filters.find((f: ICampaignMediaBuyFilter) => f.mediaType === mediaType)
    const metrics = filter && filter.metrics ? filter.metrics : []
    setSelectedMetrics(metrics)
  }

  useEffect(() => {
    if (!mediaType) return
    fillForm()
  }, [mediaBuyList])

  useEffect(() => {
    if (!mediaType) return
    fillSelectedMetrics()
  }, [filters])

  const fetchMediaBuy = async () => {
    if (!partner) return

    setLoadingAnalytics(true)
    await dispatch(actions.fetchCampaignMediaBuyFilter(id, partner.id))
    await dispatch(actions.fetchCampaignMediaBuy(id, partner.id))
    setLoadingAnalytics(false)
  }

  const fillForm = async () => {
    form.resetFields()
    const mediaBuy = mediaBuyList.find((data: ICampaignMediaBuyData) => data.mediaType === mediaType)
    setIsRemoteCampaign(mediaBuy?.remoteCampaign)
    if (!mediaBuy) return

    const metrics = { ...mediaBuy.metrics }
    if (!metrics) return

    if (metrics[CAMPAIGN_METRICS.GENDERS]) {
      form.setFieldsValue(metrics[CAMPAIGN_METRICS.GENDERS])
    }

    if (metrics[CAMPAIGN_METRICS.AGE_GROUPS]) {
      const reducer = (acc: {}, key: string) => {
        const name = 'ageGroup_' + key
        //@ts-ignore
        acc[name] = metrics[CAMPAIGN_METRICS.AGE_GROUPS][key]
        return acc
      }

      const ageGroups = Object.keys(metrics[CAMPAIGN_METRICS.AGE_GROUPS]).reduce(reducer, {})
      await form.setFieldsValue(ageGroups)
    }

    form.setFieldsValue(metrics)
  }

  const removeBoth = () => {
    settingsGenders.splice(settingsGenders.indexOf(GENDER.BOTH), 1)
  }

  const noFilters = !partner || !mediaType || !selectedMetrics.length

  const saveFilter = async () => {
    if (noFilters) return
    const filter = { partnerId: partner!.id, mediaType, metrics: selectedMetrics } as ICampaignMediaBuyFilter

    await dispatch(actions.saveCampaignMediaBuyFilter(id, filter))
  }

  const getGenders = (values: any) => {
    const hasGender = Object.keys(values).some((k: string) => k === GENDER.MALE || k === GENDER.FEMALE)
    if (!hasGender) return
    const genders = {
      [GENDER.MALE]: values[GENDER.MALE],
      [GENDER.FEMALE]: values[GENDER.FEMALE],
    }
    delete values[GENDER.MALE]
    delete values[GENDER.FEMALE]

    return genders
  }

  const prepareForSave = (values: any) => {
    const ageGroupsKeys = Object.keys(values).filter((key: string) => key.includes('ageGroup'))

    const reducer = (acc: {}, key: string) => {
      const name = key.split('_')[1]
      //@ts-ignore
      acc[name] = values[key]
      delete values[key]
      return acc
    }

    const ageGroups = ageGroupsKeys.reduce(reducer, {})
    const genders = getGenders(values)

    const metrics = {
      ...values,
      [CAMPAIGN_METRICS.AGE_GROUPS]: ageGroupsKeys.length > 0 ? ageGroups : undefined,
      [CAMPAIGN_METRICS.GENDERS]: genders,
    } as ICampaignMetrics

    return { partnerId: partner!.id, mediaType, metrics } as ICampaignMediaBuyData
  }

  const saveMetrics = async (values: any) => {
    const data = prepareForSave(values)
    await dispatch(actions.saveCampaignMediaBuy(id, data))
    if (partner) {
      await dispatch(actions.fetchCampaignMediaBuy(id, partner.id))
    }
  }

  const onFinish = (values: any) => {
    const resp = selectedMetrics.map(ele => MetricsValidation(ele, values))
    if (selectedMetrics.length === resp.filter(Boolean).length) {
      saveFilter()
      saveMetrics(values)
    }
  }

  const handleMetricInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target

    const quantity = +value > LOGISTIC_RATES.BIGGEST ? LOGISTIC_RATES.BIGGEST : +value
    const num = value ? quantity : null
    form.setFieldsValue({ [name]: num })
  }

  const calculateGender = (field: GENDER, value: number) => {
    if (field === GENDER.MALE) {
      form.setFieldsValue({ [GENDER.FEMALE]: 10 - value })
    }

    if (field === GENDER.FEMALE) {
      form.setFieldsValue({ [GENDER.MALE]: 10 - value })
    }
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target

    if (value === '' || value === null || value === undefined) {
      return
    }

    let num = +value
    if (num < 0) {
      num = 0
    }
    if (num > 10) {
      num = 10
    }

    if (id === GENDER.MALE || id === GENDER.FEMALE) {
      calculateGender(id, num)
    }

    form.setFieldsValue({ [id]: num })
  }

  const onSelectPartner = (partnerId: number) => {
    const targetPartner = mediaBuyPartners.find((partner: ICampaignPartner) => partner.id === partnerId)
    setPartner(targetPartner)
  }

  const onSelectMetrics = (metrics: CAMPAIGN_METRICS[]) => {
    setSelectedMetrics(metrics)
  }

  const onSelectMediaType = (media: string) => {
    setMediaType(media)
  }

  const partnerOptions = mediaBuyPartners.map((partner: ICampaignPartner) => {
    return { label: `${partner.companyName} - ${MEDIA_GROUP_TYPE.MEDIA_BUY}`, value: partner.id }
  })


  const mediaSubtypeNames = partner ? subtypeNamesByPartner(partner.mediaBuyTypes) : []
  const mediaSubTypeOptions = mediaSubtypeNames.map((type: string): IOptionForSelect => ({ label: t('services.media-buy.types.' + snakeCase(type), type, { ns: 'translation' }), value: type }))

  const metricsOptions = metrics.map(
    (metric: CAMPAIGN_METRICS): IOptionForSelect => {
      return { label: t(CAMPAIGN_METRICS_NAMES[metric]), value: metric }
    }
  )

  const renderInput = (name: string, type?: 'ageGroup') => (
    <Col key={name} xs={24} md={12}>
      <Row gutter={16}>
        <Col flex="220px">
          <div className="font-size-16 mt-5 tc--light">{t('metrics.' + name)}</div>
        </Col>
        <Col flex="0 1 auto">
          <Form.Item
            name={type ? `${type}_${name}` : name}
            rules={[{ required: true, message: t(VALIDATION_MESSAGE.BETWEEN_ZERO_TEN, { ns: 'translationABrief' }) }]}
            validateTrigger={['onChange', 'onBlur']}
          >
            <QInput onChange={handleInputChange} type="number"
              placeholder={t('edit.Enter number')}
              size="large"
            />
          </Form.Item>
        </Col>
      </Row>
    </Col>
  )

  const calculation = (name: CAMPAIGN_METRICS, values: ICampaignMetrics) => {
    if (name === CAMPAIGN_METRICS.GROWTH_RATE) {
      if (!values[CAMPAIGN_METRICS.BEFORE_REVENUE] || !values[CAMPAIGN_METRICS.AFTER_REVENUE]) {
        return 'Before Revenue and After Revenue are required'
      }
      const GR = calculateGrowthRate(values[CAMPAIGN_METRICS.BEFORE_REVENUE], values[CAMPAIGN_METRICS.AFTER_REVENUE])
      return GR + '%'
    }

    if (name === CAMPAIGN_METRICS.CTR) {
      if (!Number.isInteger(values[CAMPAIGN_METRICS.CLICKS]) || !Number.isInteger(values[CAMPAIGN_METRICS.IMPRESSIONS]) ) {
        return 'Clicks and Impressions are required'
      }
      const CTR = calculateRate(values[CAMPAIGN_METRICS.CLICKS], values[CAMPAIGN_METRICS.IMPRESSIONS])
      return CTR > 0 ? CTR + '%' : '0%'
    }

    if (name === CAMPAIGN_METRICS.CR) {
      if (!Number.isInteger(values[CAMPAIGN_METRICS.ORDERS]) || !Number.isInteger(values[CAMPAIGN_METRICS.CLICKS])) {
        return 'Orders and Click are required'
      }
      const CR = calculateRate(values[CAMPAIGN_METRICS.ORDERS], values[CAMPAIGN_METRICS.CLICKS])
      return CR + '%'
    }

    if (name === CAMPAIGN_METRICS.ER) {
      if (!values[CAMPAIGN_METRICS.INTERACTIONS] || !values[CAMPAIGN_METRICS.FOLLOWERS]) {
        return 'Interactions and Followers are required'
      }
      const ER = calculateRate(values[CAMPAIGN_METRICS.INTERACTIONS], values[CAMPAIGN_METRICS.FOLLOWERS])
      return ER + '%'
    }

    return ''
  }

  const MetricsValidation = (name: CAMPAIGN_METRICS, values: ICampaignMetrics) => {
    if (name === CAMPAIGN_METRICS.GROWTH_RATE) {
      if (!values[CAMPAIGN_METRICS.BEFORE_REVENUE] || !values[CAMPAIGN_METRICS.AFTER_REVENUE]) {
        qnotification({ type: NOTIFICATION_TYPES.ERROR, message: 'Before Revenue and After Revenue are required' })
        return false
      }
    }

    if (name === CAMPAIGN_METRICS.CTR) {
      if (!Number.isInteger(values[CAMPAIGN_METRICS.CLICKS]) || !Number.isInteger(values[CAMPAIGN_METRICS.IMPRESSIONS]) ) {
        qnotification({ type: NOTIFICATION_TYPES.ERROR, message: 'Clicks and Impressions are required' })
        return false
      }
    }

    if (name === CAMPAIGN_METRICS.CR) {
      if (!Number.isInteger(values[CAMPAIGN_METRICS.ORDERS]) || !Number.isInteger(values[CAMPAIGN_METRICS.CLICKS]) ) {
        qnotification({ type: NOTIFICATION_TYPES.ERROR, message: 'Orders and Click are required' })
        return false
      }
    }

    if (name === CAMPAIGN_METRICS.ER) {
      if (!values[CAMPAIGN_METRICS.INTERACTIONS] || !values[CAMPAIGN_METRICS.FOLLOWERS]) {
        qnotification({ type: NOTIFICATION_TYPES.ERROR, message: 'Interactions and Followers are required' })
        return false
      }
    }

    return true
  }

  const renderRate = (name: CAMPAIGN_METRICS, metrics: ICampaignMetrics) => {
    const description = calculation(name, metrics)

    return (
      <Col className="mb-10" key={name} xs={24} md={12}>
        <Row gutter={16}>
          <Col xs={24} md={8}>
            <div className="font-size-16 mt-5 tc--light">{t(CAMPAIGN_METRICS_NAMES[name])}</div>
          </Col>
          <Col xs={24} md={16}>
            <div className="font-size-16 mt-5">
              <b>{description}</b>
            </div>
          </Col>
        </Row>
      </Col>
    )
  }

  const calculatedMetrics = CAMPAIGN_CALCULATED_METRICS.filter(element => selectedMetrics.includes(element))

  const renderCalculatedMetrics = () => {
    const mediaBuy = mediaBuyList.find((data: ICampaignMediaBuyData) => data.mediaType === mediaType)
    if (!mediaBuy) return null

    const metrics = { ...mediaBuy.metrics }
    if (!metrics) return null

    return (
      <Fragment>
        <hr className="form__hr" />
        <Row className="mb-15" gutter={16}>
          {calculatedMetrics.map((metric: CAMPAIGN_METRICS) => renderRate(metric, metrics))}
        </Row>
      </Fragment>
    )
  }

  const metricInput = (metric: CAMPAIGN_METRICS) => {
    if (!selectedMetrics.includes(metric)) {
      return null
    }

    return (
      <Col key={metric} xs={24} md={12}>
        <Row gutter={16}>
          <Col flex="220px">
            <div className="font-size-16 mt-5 tc--light">{t(CAMPAIGN_METRICS_NAMES[metric])}</div>
              {([CAMPAIGN_METRICS.IMPRESSIONS, CAMPAIGN_METRICS.CLICKS].includes(metric) && isRemoteCampaign) && <span
                style={{
                  fontSize: '11px',
                }}
              >
                {t(`edit.${metric}_disabled`)}
              </span>}
          </Col>
          <Col flex="0 1 auto">
            <Form.Item
              name={metric}
              rules={[
                {
                  required: true,
                  whitespace: true,
                  pattern: REGEXES.NUMBERS_REGEX,
                  message: t(VALIDATION_MESSAGE.MORE_THAN_ZERO, { ns: 'translationABrief' }),
                },
              ]}
              validateTrigger={['onChange', 'onBlur']}
            >
              <QInput
                disabled={[CAMPAIGN_METRICS.IMPRESSIONS, CAMPAIGN_METRICS.CLICKS].includes(metric) && isRemoteCampaign}
                name={metric}
                onChange={handleMetricInput}
                type="number"
                min={0}
                placeholder={t('edit.Enter number')}
                size="large"
              />
            </Form.Item>
          </Col>
        </Row>
      </Col>
    )
  }

  const handlePreview = async () => {
    const values = await form.getFieldsValue()
    const data = prepareForSave(values)
    setFormData(data.metrics)
    setShowPreview(true)
  }

  const disabledPreview = !selectedMetrics.length
  const title = !selectedMetrics.length ? t('edit.Please select Metrics') : t('edit.Selected Metrics')

  const disableMetrics = !partner || !mediaType
  const disableMediaType = !partner

  return (
    <div className="full-width">
      {loadingAnalytics && (
        <SpinnerCenter
          text={t('details.Loading Analytics')}
        />
      )}
      <div className="qu-chart-card mb-15">
        <div className="p-16">
          <h2 className="mb-20">{t('edit.Edit Metrics')}</h2>
          <Row className="mb-15" gutter={16} align="middle">
            <Col xs={24} md={6} style={{ display: 'none' }}>
              <QSelect
                className="full-width mb-15"
                onChange={onSelectPartner}
                options={partnerOptions}
                value={partnerOptions.length > 0 ? partnerOptions[0].value : ''}
                size="large"
                placeholder={t('edit.Select Partner')}
                showArrow
              />
            </Col>
            <Col xs={24} md={6}>
              <QSelect
                disabled={disableMediaType}
                className="full-width mb-15"
                onChange={onSelectMediaType}
                options={mediaSubTypeOptions}
                size="large"
                placeholder={t('edit.Media Type')}
              />
            </Col>
            <Col xs={24} md={12}>
              <QSelect
                disabled={disableMetrics}
                value={selectedMetrics}
                className="full-width mb-15"
                maxTagTextLength={10}
                maxTagCount={3}
                mode="multiple"
                onChange={onSelectMetrics}
                options={metricsOptions}
                size="large"
                placeholder={t('edit.Select Metrics')}
                showArrow
              />
            </Col>
          </Row>
          <h3 className="full-width mb-15">{title}</h3>
          <Form form={form} onFinish={onFinish}>
            <Row className="mb-15" gutter={16}>
              {CAMPAIGN_METRICS_INPUTS.map((metric: CAMPAIGN_METRICS) => metricInput(metric))}
            </Row>
            {selectedMetrics.includes(CAMPAIGN_METRICS.GENDERS) && (
              <Fragment>
                <hr className="form__hr" />
                <Row className="mb-15" gutter={16}>
                  {settingsGenders.map((gender: string) => renderInput(gender))}
                </Row>
              </Fragment>
            )}
            {selectedMetrics.includes(CAMPAIGN_METRICS.AGE_GROUPS) && (
              <Fragment>
                <hr className="form__hr" />
                <Row className="mb-15" gutter={16}>
                  {settingsAgeGroups.map((group: string) => renderInput(group, 'ageGroup'))}
                </Row>
              </Fragment>
            )}
            {calculatedMetrics.length > 0 && renderCalculatedMetrics()}
            <Row gutter={16} justify="space-between">
              <Col xs={24} md={4}>
                <QButton disabled={noFilters} className="full-width" type="primary" htmlType="submit">
                  {t('common.Save', { ns: 'translationCommon' })}
                </QButton>
              </Col>
              <Col xs={24} md={4}>
                <QButton className="qu-button-outline full-width" onClick={handlePreview} disabled={disabledPreview}>
                  {t('common.Preview', { ns: 'translationCommon' })}
                </QButton>
              </Col>
            </Row>
          </Form>
        </div>
      </div>

      <Modal
        wrapClassName="qu-modal qu-modal-details"
        closeIcon={<IconsCommon.IconClose />}
        visible={showPreview}
        onCancel={() => setShowPreview(false)}
        footer={null}
      >
        {showPreview && formData && (
          <div className="full-width p-20">
            <h1 className="text-center mb-20">{t("Preview", { ns: "translationCommon" })}</h1>
            <MediaBuyMetrics selectedMetrics={selectedMetrics} mediaType={mediaType} details={formData} />
          </div>
        )}
      </Modal>
    </div>
  )
}
export default CampaignEditMediaBuy
