import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AxiosResponse } from 'axios'
import { AnyAction } from 'redux'
import { requestHttp, urls } from '../../api'
import { getResponseErrorMessage } from '../../helpers'
import IAction from '../../interfaces/IAction'
import * as CONSTANTS from './campaign.constants'
import {
  ICampaignDistribution,
  ICampaignDetails,
  ICampaignDetailsResponse,
  ICampaignList,
  ICampaignListResponse,
  ICampaignProps,
  ICampaignQuantityInfo,
  ICampaignSettings,
  ICampaignSurvey,
  ICampaignTableParams,
  ICampaignDistributionEdit,
  ICampaignDistAnalyticsFilter,
  ICampaignAnalyticsDistData,
  ICampaignAnalyticsInfo,
  ICampaignMediaBuyData,
  ICampaignMediaBuyFilter,
  ICampaignAnalyticsMediaBuyData,
  ICampaignTimeline,
  ICampaignTimelineList,
  IClientSurveysParams,
  IClientSurveysList,
  ICampaignGallery,
  ICampaignGalleryList,
  ICampaignGalleryParams,
} from './campaign.types'
import history from '../../utils/history'
import { PATHS, STATUS_CODES } from '../../constants'
import {
  getClientCampaignAnalyticsDistDataUrl,
  getClientCampaignAnalyticsInfoUrl,
  getClientCampaignAnalyticsMediaBuyDataUrl,
} from '../../api/urls'
import { cloneDeep } from 'lodash'
import { ISurveyInitState } from './campaign.reducer'
import { upload } from '../core/core.actions'
import { CAMPAIGN_UPLOAD_TYPES } from 'src/constants/campaign'
import { UPLOAD_ENTITY_KEYS } from 'src/constants/upload'

export const resetCampaignState = (): IAction => ({
  type: CONSTANTS.RESET_CAMPAIGN_STATE,
})

export const campaignListRequest = (): IAction => ({
  type: CONSTANTS.FETCH_CAMPAIGNS_REQUEST,
})

export const campaignListSuccess = (campaignList: ICampaignList): IAction => ({
  type: CONSTANTS.FETCH_CAMPAIGNS_SUCCESS,
  payload: { campaignList },
})

export const campaignListFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_CAMPAIGNS_FAILURE,
  error,
})

export const setCurrentPage = (page: number): IAction => ({
  type: CONSTANTS.SET_CURRENT_PAGE,
  payload: { page },
})

export const setTimelineCurrentPage = (page: number): IAction => ({
  type: CONSTANTS.SET_TIMELINE_CURRENT_PAGE,
  payload: { page },
})

export const setCampaignListParams = (params: ICampaignTableParams): IAction => ({
  type: CONSTANTS.SET_CAMPAIGN_LIST_PARAMS,
  payload: { params },
})

export const campaignUpdatingRequest = (): IAction => ({
  type: CONSTANTS.UPDATE_CAMPAIGN_REQUEST,
})

export const campaignUpdatingSuccess = (): IAction => ({
  type: CONSTANTS.UPDATE_CAMPAIGN_SUCCESS,
})

export const campaignUpdatingFailure = (error: string): IAction => ({
  type: CONSTANTS.UPDATE_CAMPAIGN_FAILURE,
  error,
})

export const setParamsAndFetch = (
  briefId: number,
  params: ICampaignTableParams
): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  await dispatch(setCampaignListParams(params))
  await dispatch(fetchCampaignList(briefId, params))
}

export const fetchCampaignList = (
  briefId: number,
  filter: ICampaignTableParams
): ThunkAction<Promise<AxiosResponse<ICampaignListResponse>>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse<ICampaignListResponse>> => {
  try {
    const params = { briefId, ...filter }
    dispatch(campaignListRequest())
    const response = await requestHttp.get<ICampaignListResponse>(urls.getCampaignsListUrl(), { params })
    const { content } = response.data.data

    const result = {
      results: content.campaigns.results,
      total: content.campaigns.total,
      stats: content.stats,
    } as ICampaignList

    dispatch(campaignListSuccess(result))
    return response
  } catch (error) {
    dispatch(campaignListFailure(getResponseErrorMessage(error)))
    return error
  }
}

export const fetchDetailsCampaign = (): IAction => ({
  type: CONSTANTS.FETCH_CAMPAIGN_DETAILS_REQUEST,
})

export const campaignDetailsSuccess = (details: ICampaignDetails): IAction => ({
  type: CONSTANTS.FETCH_CAMPAIGN_DETAILS_SUCCESS,
  payload: { details },
})

export const campaignDetailsFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_CAMPAIGN_DETAILS_FAILURE,
  error,
})

export const fetchCampaignDetails = (
  id: number,
): ThunkAction<Promise<AxiosResponse<ICampaignDetailsResponse>>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse<ICampaignDetailsResponse>> => {
  try {
    dispatch(fetchDetailsCampaign())
    const response = await requestHttp.get<ICampaignDetailsResponse>(urls.getCampaignDetailsUrl(id))
    const { content } = response.data.data

    dispatch(campaignDetailsSuccess(content))
    return response
  } catch (error) {
    dispatch(campaignDetailsFailure(getResponseErrorMessage(error)))
    return error
  }
}

export const upsertCampaign = (requestId: number): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    dispatch(campaignUpdatingRequest())
    const response = await requestHttp.post(urls.getCampaignUpsertUrl(requestId))
    const { content } = response.data.data
    dispatch(campaignUpdatingSuccess())
    history.push(`/campaigns/${content.campaignId}`)
  } catch (error) {
    dispatch(campaignUpdatingFailure(error))
    return error
  }
}

export const completeCampaign = (id: number): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    dispatch(campaignUpdatingRequest())
    const response = await requestHttp.get(urls.getCampaignCompleteUrl(id))
    dispatch(campaignUpdatingSuccess())
    if (response.status === STATUS_CODES.BASE_SUCCESS) {
      history.push(PATHS.CAMPAIGNS)
    }
  } catch (error) {
    dispatch(campaignUpdatingFailure(error))
    return error
  }
}

export const campaignSettingsRequest = (): IAction => ({
  type: CONSTANTS.FETCH_CAMPAIGN_SETTINGS_REQUEST,
})

export const campaignSettingsSuccess = (settings: ICampaignSettings): IAction => ({
  type: CONSTANTS.FETCH_CAMPAIGN_SETTINGS_SUCCESS,
  payload: { settings },
})

export const campaignSettingsFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_CAMPAIGN_SETTINGS_FAILURE,
  error,
})

export const fetchCampaignSettings = (id: number): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    dispatch(campaignSettingsRequest())

    const response = await requestHttp.get(urls.getCampaignInitialUrl(id))
    if (response.status === STATUS_CODES.BASE_SUCCESS) {
      const { content } = response.data.data

      dispatch(campaignSettingsSuccess(content))
    }
  } catch (error) {
    dispatch(campaignSettingsFailure(error))
    return error
  }
}

export const campaignDistributionRequest = (): IAction => ({
  type: CONSTANTS.FETCH_CAMPAIGN_DISTRIBUTION_REQUEST,
})

export const campaignDistributionSuccess = (settings: ICampaignDistribution[]): IAction => ({
  type: CONSTANTS.FETCH_CAMPAIGN_DISTRIBUTION_SUCCESS,
  payload: settings,
})

export const campaignDistributionFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_CAMPAIGN_DISTRIBUTION_FAILURE,
  error,
})

export const fetchCampaignDistribution = (
  campaignId: number,
  partnerId: number
): ThunkAction<Promise<ICampaignDistribution[]>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<ICampaignDistribution[]> => {
  try {
    dispatch(campaignDistributionRequest())

    const response = await requestHttp.get(urls.getCampaignsDistributionUrl(campaignId, partnerId))
    const { content } = response.data.data

    dispatch(campaignDistributionSuccess(content))
    return content
  } catch (error) {
    dispatch(campaignDistributionFailure(error))
    return error
  }
}

export const campaignSaveDistribution = (
  id: number,
  data: ICampaignDistributionEdit
): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    dispatch(campaignUpdatingRequest())
    await requestHttp.post(urls.getCampaignsDistributionUpsertUrl(id), data)
    dispatch(campaignUpdatingSuccess())
  } catch (error) {
    dispatch(campaignUpdatingFailure(error))
    return error
  }
}

export const campaignPropsRequest = (): IAction => ({
  type: CONSTANTS.FETCH_CAMPAIGN_PROPS_REQUEST,
})

export const campaignPropsSuccess = (props: ICampaignProps): IAction => ({
  type: CONSTANTS.FETCH_CAMPAIGN_PROPS_SUCCESS,
  payload: { props },
})

export const campaignPropsFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_CAMPAIGN_PROPS_FAILURE,
  error,
})

export const fetchCampaignProps = (
  campaignId: number
): ThunkAction<Promise<ICampaignProps>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<ICampaignProps> => {
  try {
    dispatch(campaignPropsRequest())

    const response = await requestHttp.get(urls.getCampaignsPropsUrl(campaignId))
    const { content } = response.data.data

    dispatch(campaignPropsSuccess(content))
    return content
  } catch (error) {
    dispatch(campaignPropsFailure(error))
    return error
  }
}

export const campaignSaveProps = (
  id: number,
  data: ICampaignProps
): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    dispatch(campaignUpdatingRequest())
    await requestHttp.post(urls.getCampaignsPropsUpsertUrl(id), data)
    dispatch(campaignUpdatingSuccess())
  } catch (error) {
    dispatch(campaignUpdatingFailure(error))
    return error
  }
}

export const campaignInfoSuccess = (info: ICampaignQuantityInfo): IAction => ({
  type: CONSTANTS.FETCH_CAMPAIGNS_QUANTITY_INFO_SUCCESS,
  payload: { info },
})

export const campaignInfoFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_CAMPAIGNS_QUANTITY_INFO_FAILURE,
  error,
})

export const fetchCampaignQuantityInfo = (
  campaignId: number,
  partnerId: number
): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    const response = await requestHttp.get(urls.getCampaignsQuantityInfoUrl(campaignId, partnerId))
    if (response.status === STATUS_CODES.BASE_SUCCESS) {
      const { content } = response.data.data

      dispatch(campaignInfoSuccess(content))
      return content
    }
  } catch (error) {
    dispatch(campaignInfoFailure(error))
    return error
  }
}

export const deleteDistribution = (
  campaignId: number,
  data: ICampaignDistributionEdit
): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    dispatch(campaignUpdatingRequest())
    await requestHttp.delete(urls.getCampaignDistributionDeleteUrl(campaignId), { data })

    dispatch(campaignUpdatingSuccess())
  } catch (error) {
    dispatch(campaignUpdatingFailure(error))
    return error
  }
}

export const campaignAnalyticsInfoRequest = (): IAction => ({
  type: CONSTANTS.FETCH_CAMPAIGN_ANALYTICS_INFO_REQUEST,
})

export const campaignAnalyticsInfoSuccess = (info: ICampaignAnalyticsInfo): IAction => ({
  type: CONSTANTS.FETCH_CAMPAIGN_ANALYTICS_INFO_SUCCESS,
  payload: { info },
})

export const campaignAnalyticsInfoFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_CAMPAIGN_ANALYTICS_INFO_FAILURE,
  error,
})

export const fetchCampaignAnalyticsInfo = (id: number): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    dispatch(campaignAnalyticsInfoRequest())

    const response = await requestHttp.get(getClientCampaignAnalyticsInfoUrl(id))
    const { content } = response.data.data

    dispatch(campaignAnalyticsInfoSuccess(content))
  } catch (error) {
    dispatch(campaignAnalyticsInfoFailure(getResponseErrorMessage(error)))
  }
}

export const campaignAnalyticsDataRequest = (): IAction => ({
  type: CONSTANTS.FETCH_CAMPAIGN_ANALYTICS_DATA_REQUEST,
})

export const campaignAnalyticsDataSuccess = (data: ICampaignAnalyticsDistData): IAction => ({
  type: CONSTANTS.FETCH_CAMPAIGN_ANALYTICS_DATA_SUCCESS,
  payload: { data },
})

export const campaignAnalyticsDataFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_CAMPAIGN_ANALYTICS_DATA_FAILURE,
  error,
})

export const fetchCampaignAnalyticsData = (
  id: number,
  params: ICampaignDistAnalyticsFilter
): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    dispatch(campaignAnalyticsDataRequest())

    const response = await requestHttp.get(getClientCampaignAnalyticsDistDataUrl(id), { params })
    const { content } = response.data.data

    dispatch(campaignAnalyticsDataSuccess(content))
  } catch (error) {
    dispatch(campaignAnalyticsDataFailure(getResponseErrorMessage(error)))
  }
}

export const campaignAnalyticsMediaBuyRequest = (): IAction => ({
  type: CONSTANTS.FETCH_CAMPAIGN_ANALYTICS_MEDIA_BUY_REQUEST,
})

export const campaignAnalyticsMediaBuySuccess = (data: ICampaignAnalyticsMediaBuyData | null): IAction => ({
  type: CONSTANTS.FETCH_CAMPAIGN_ANALYTICS_MEDIA_BUY_SUCCESS,
  payload: { data },
})

export const campaignAnalyticsMediaBuyFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_CAMPAIGN_ANALYTICS_MEDIA_BUY_FAILURE,
  error,
})

export const fetchCampaignAnalyticsMediaBuy = (
  id: number,
  params: ICampaignMediaBuyFilter
): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    dispatch(campaignAnalyticsMediaBuyRequest())

    const response = await requestHttp.get(getClientCampaignAnalyticsMediaBuyDataUrl(id), { params })
    const { content } = response.data.data
    dispatch(campaignAnalyticsMediaBuySuccess(content))
  } catch (error) {
    dispatch(campaignAnalyticsMediaBuyFailure(getResponseErrorMessage(error)))
  }
}

export const setDistAnalyticsFilter = (filter: ICampaignDistAnalyticsFilter): IAction => ({
  type: CONSTANTS.SET_DIST_ANALYTICS_FILTER,
  payload: { filter },
})

export const setMediaBuyAnalyticsFilter = (filter: ICampaignMediaBuyFilter): IAction => ({
  type: CONSTANTS.SET_MEDIA_BUY_ANALYTICS_FILTER,
  payload: { filter },
})

export const campaignSurveyRequest = (): IAction => ({
  type: CONSTANTS.FETCH_CAMPAIGN_SURVEY_REQUEST,
})

export const campaignSurveySuccess = (surveyList: ICampaignSurvey[]): IAction => ({
  type: CONSTANTS.FETCH_CAMPAIGN_SURVEY_SUCCESS,
  payload: { surveyList },
})

export const campaignSurveyFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_CAMPAIGN_SURVEY_FAILURE,
  error,
})

export const fetchCampaignSurveyList = (campaignId: number): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    dispatch(campaignSurveyRequest())
    const response = await requestHttp.get(urls.getCampaignsSurveyUrl(campaignId))

    if (response.status === STATUS_CODES.BASE_SUCCESS) {
      const { content } = response.data.data
      const surveys = content.length > 0 ? content : [cloneDeep(ISurveyInitState)]

      dispatch(campaignSurveySuccess(surveys))
      return content
    }
  } catch (error) {
    dispatch(campaignSurveyFailure(error))
    return error
  }
}

export const saveCampaignSurveyRequest = (): IAction => ({
  type: CONSTANTS.SAVE_CAMPAIGN_SURVEY_REQUEST,
})

export const saveCampaignSurveySuccess = (surveyList: ICampaignSurvey[]): IAction => ({
  type: CONSTANTS.SAVE_CAMPAIGN_SURVEY_SUCCESS,
  payload: { surveyList },
})

export const saveCampaignSurveyFailure = (error: string): IAction => ({
  type: CONSTANTS.SAVE_CAMPAIGN_SURVEY_FAILURE,
  error,
})

export const saveCampaignSurvey = (surveys: ICampaignSurvey[]): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    dispatch(saveCampaignSurveyRequest())
    const response = await requestHttp.post(urls.getSaveCampaignsSurveyUrl(), { surveys })

    if (response.status === STATUS_CODES.BASE_SUCCESS) {
      const { content } = response.data.data
      dispatch(saveCampaignSurveySuccess(content[0]))
    }
  } catch (error) {
    dispatch(saveCampaignSurveyFailure(error))
  }
}

export const deleteCampaignSurvey = (
  campaignId: number,
  surveyId: number
): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    await requestHttp.delete(urls.getDeleteCampaignsSurveyUrl(surveyId))
  } catch (error) {}
}

export const fetchCampaignMediaBuyFilterRequest = (): IAction => ({
  type: CONSTANTS.FETCH_CAMPAIGN_MEDIA_BUY_FILTER_REQUEST,
})

export const fetchCampaignMediaBuyFilterSuccess = (filter: ICampaignMediaBuyFilter[]): IAction => ({
  type: CONSTANTS.FETCH_CAMPAIGN_MEDIA_BUY_FILTER_SUCCESS,
  payload: { filter },
})

export const fetchCampaignMediaBuyFilterFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_CAMPAIGN_MEDIA_BUY_FILTER_FAILURE,
  error,
})

export const fetchCampaignMediaBuyFilter = (
  campaignId: number,
  partnerId: number
): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    dispatch(fetchCampaignMediaBuyFilterRequest())

    const response = await requestHttp.get(urls.getEditCampaignsMediaBuyFilterUrl(campaignId, partnerId))
    if (response.status === STATUS_CODES.BASE_SUCCESS) {
      const { content } = response.data.data
      dispatch(fetchCampaignMediaBuyFilterSuccess(content))
    }
  } catch (error) {
    dispatch(fetchCampaignMediaBuyFilterFailure(error))
  }
}

export const saveCampaignMediaBuyFilterRequest = (): IAction => ({
  type: CONSTANTS.SAVE_CAMPAIGN_MEDIA_BUY_FILTER_REQUEST,
})

export const saveCampaignMediaBuyFilterSuccess = (filter: ICampaignMediaBuyFilter): IAction => ({
  type: CONSTANTS.SAVE_CAMPAIGN_MEDIA_BUY_FILTER_SUCCESS,
  payload: { filter },
})

export const saveCampaignMediaBuyFilterFailure = (error: string): IAction => ({
  type: CONSTANTS.SAVE_CAMPAIGN_MEDIA_BUY_FILTER_FAILURE,
  error,
})

export const saveCampaignMediaBuyFilter = (
  campaignId: number,
  filter: ICampaignMediaBuyFilter
): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    dispatch(saveCampaignMediaBuyFilterRequest())
    const response = await requestHttp.post(urls.getSaveCampaignsMediaBuyFilterUrl(campaignId), filter)
    if (response.status === STATUS_CODES.BASE_SUCCESS) {
      const { content } = response.data.data
      dispatch(saveCampaignMediaBuyFilterSuccess(content))
    }
  } catch (error) {
    dispatch(saveCampaignMediaBuyFilterFailure(error))
  }
}

export const fetchCampaignMediaBuyRequest = (): IAction => ({
  type: CONSTANTS.FETCH_CAMPAIGN_MEDIA_BUY_REQUEST,
})

export const fetchCampaignMediaBuySuccess = (data: ICampaignMediaBuyData[]): IAction => ({
  type: CONSTANTS.FETCH_CAMPAIGN_MEDIA_BUY_SUCCESS,
  payload: { data },
})

export const fetchCampaignMediaBuyFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_CAMPAIGN_MEDIA_BUY_FAILURE,
  error,
})

export const fetchCampaignMediaBuy = (
  campaignId: number,
  partnerId: number
): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    dispatch(fetchCampaignMediaBuyRequest())
    const response = await requestHttp.get(urls.getEditCampaignsMediaBuyUrl(campaignId, partnerId))
    if (response.status === STATUS_CODES.BASE_SUCCESS) {
      const { content } = response.data.data

      dispatch(fetchCampaignMediaBuySuccess(content))
    }
  } catch (error) {
    dispatch(fetchCampaignMediaBuyFailure(error))
  }
}

export const saveCampaignMediaBuyRequest = (): IAction => ({
  type: CONSTANTS.SAVE_CAMPAIGN_MEDIA_BUY_REQUEST,
})

export const saveCampaignMediaBuySuccess = (surveyList: ICampaignSurvey[]): IAction => ({
  type: CONSTANTS.SAVE_CAMPAIGN_MEDIA_BUY_SUCCESS,
  payload: { surveyList },
})

export const saveCampaignMediaBuyFailure = (error: string): IAction => ({
  type: CONSTANTS.SAVE_CAMPAIGN_MEDIA_BUY_FAILURE,
  error,
})

export const saveCampaignMediaBuy = (
  campaignId: number,
  data: ICampaignMediaBuyData
): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    dispatch(saveCampaignMediaBuyRequest())
    const response = await requestHttp.post(urls.getSaveCampaignsMediaBuyUrl(campaignId), data)
    if (response.status === STATUS_CODES.BASE_SUCCESS) {
      const { content } = response.data.data
      dispatch(saveCampaignMediaBuySuccess(content))
    }
  } catch (error) {
    dispatch(saveCampaignMediaBuyFailure(error))
  }
}

export const fetchCampaignTimelineRequest = (): IAction => ({
  type: CONSTANTS.FETCH_CAMPAIGN_TIMELINE_REQUEST,
})

export const fetchCampaignTimelineSuccess = (list: ICampaignTimelineList): IAction => ({
  type: CONSTANTS.FETCH_CAMPAIGN_TIMELINE_SUCCESS,
  payload: { list },
})

export const fetchCampaignTimelineFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_CAMPAIGN_TIMELINE_FAILURE,
  error,
})

export const fetchCampaignTimeline = (
  campaignId: number,
  params: any = { offset: 0, limit: 10 }
): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    dispatch(fetchCampaignTimelineRequest())
    const response = await requestHttp.get(urls.getCampaignsTimelineListUrl(campaignId), { params })
    if (response.status === STATUS_CODES.BASE_SUCCESS) {
      const { content } = response.data.data

      dispatch(fetchCampaignTimelineSuccess(content))
    }
  } catch (error) {
    dispatch(fetchCampaignTimelineFailure(error))
  }
}

export const uploadTimelineImagesRequest = (): IAction => ({
  type: CONSTANTS.UPLOAD_CAMPAIGN_TIMELINE_IMAGES_REQUEST,
})

export const uploadTimelineImagesSuccess = (): IAction => ({
  type: CONSTANTS.UPLOAD_CAMPAIGN_TIMELINE_IMAGES_SUCCESS,
})

export const uploadTimelineImagesFailure = (error: string): IAction => ({
  type: CONSTANTS.SAVE_CAMPAIGN_TIMELINE_FAILURE,
  error,
})

export const uploadCampaignTimelineImages = (
  timelineId: number,
  files: File[]
): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    dispatch(uploadTimelineImagesRequest())
    const uploadUrl = urls.getCampaignsTimelineUploadUrl(timelineId)
    await upload(uploadUrl, files)

    dispatch(uploadTimelineImagesSuccess())
  } catch (error) {
    dispatch(uploadTimelineImagesFailure(error))
  }
}

export const saveCampaignTimelineRequest = (): IAction => ({
  type: CONSTANTS.SAVE_CAMPAIGN_TIMELINE_REQUEST,
})

export const saveCampaignTimelineSuccess = (timeline: ICampaignTimeline): IAction => ({
  type: CONSTANTS.SAVE_CAMPAIGN_TIMELINE_SUCCESS,
  payload: { timeline },
})

export const saveCampaignTimelineFailure = (error: string): IAction => ({
  type: CONSTANTS.SAVE_CAMPAIGN_TIMELINE_FAILURE,
  error,
})

export const saveCampaignTimeline = (
  campaignId: number,
  data: ICampaignTimeline,
  files: File[]
): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    dispatch(saveCampaignTimelineRequest())
    const response = await requestHttp.post(urls.getCampaignsTimelineSaveUrl(), data)

    if (response.status === STATUS_CODES.BASE_SUCCESS) {
      const { content } = response.data.data
      dispatch(saveCampaignTimelineSuccess(content))
      if (files.length > 0) {
        await dispatch(uploadCampaignTimelineImages(content.id, files))
      }
    }
  } catch (error) {
    dispatch(saveCampaignTimelineFailure(error))
  }
}

export const deleteCampaignTimeline = (id: number): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    dispatch(campaignUpdatingRequest())
    await requestHttp.delete(urls.getCampaignsTimelineDeleteUrl(id))

    dispatch(campaignUpdatingSuccess())
  } catch (error) {
    dispatch(campaignUpdatingFailure(error))
    return error
  }
}

export const removeCampaignTimelineImage = (url: string): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    const data = { src: url }
    dispatch(campaignUpdatingRequest())
    await requestHttp.delete(urls.getCampaignsTimelineUploadDeleteUrl(), { data })

    dispatch(campaignUpdatingSuccess())
  } catch (error) {
    dispatch(campaignUpdatingFailure(error))
    return error
  }
}

export const setClientSurveysCurrentPage = (page: number): IAction => ({
  type: CONSTANTS.SET_CLIENT_SURVEYS_CURRENT_PAGE,
  payload: { page },
})

export const setClientSurveysParams = (params: IClientSurveysParams): IAction => ({
  type: CONSTANTS.SET_CLIENT_SURVEYS_PARAMS,
  payload: { params },
})

export const setParamsAndFetchSurveys = (
  campaignId: number,
  params: IClientSurveysParams
): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  await dispatch(setClientSurveysParams(params))
  await dispatch(fetchClientSurveys(campaignId, params))
}

export const clientSurveysRequest = (): IAction => ({
  type: CONSTANTS.FETCH_CLIENT_SURVEYS_REQUEST,
})

export const clientSurveysSuccess = (surveys: IClientSurveysList): IAction => ({
  type: CONSTANTS.FETCH_CLIENT_SURVEYS_SUCCESS,
  payload: { surveys },
})

export const clientSurveysFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_CLIENT_SURVEYS_FAILURE,
  error,
})

export const fetchClientSurveys = (
  campaignId: number,
  params: IClientSurveysParams
): ThunkAction<Promise<AxiosResponse<ICampaignListResponse>>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse<ICampaignListResponse>> => {
  try {
    dispatch(clientSurveysRequest())
    const response = await requestHttp.get(urls.getClientSurveysUrl(campaignId), { params })
    const { content } = response.data.data

    dispatch(clientSurveysSuccess(content))
    return response
  } catch (error) {
    dispatch(clientSurveysFailure(getResponseErrorMessage(error)))
    return error
  }
}

export const saveCampaignGalleryRequest = (): IAction => ({
  type: CONSTANTS.SAVE_CAMPAIGN_GALLERY_REQUEST,
})

export const saveCampaignGallerySuccess = (gallery: ICampaignGallery): IAction => ({
  type: CONSTANTS.SAVE_CAMPAIGN_GALLERY_SUCCESS,
  payload: { gallery },
})

export const saveCampaignGalleryFailure = (error: string): IAction => ({
  type: CONSTANTS.SAVE_CAMPAIGN_GALLERY_FAILURE,
  error,
})

const getGalleryFormData = (data: ICampaignGallery, files: File[], partnerIds?: number[]) => {
  const bodyFormData = new FormData()

  Object.keys(data).forEach((field: string) => {
    //@ts-ignore
    const value = data[field]
    if (value) {
      bodyFormData.append(field, value)
    }
  })

  partnerIds &&
    partnerIds.forEach((partnerId: number | string) => {
      bodyFormData.append('partnerIds[]', partnerId.toString())
    })

  files.forEach((file: File) => {
    bodyFormData.append('files', file)
  })

  return bodyFormData
}

export const saveCampaignGallery = (
  data: ICampaignGallery,
  files: File[],
  partnerIds?: number[]
): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    dispatch(saveCampaignGalleryRequest())

    const bodyFormData = getGalleryFormData(data, files, partnerIds)
    const config = { multipartFormData: true, skipDownloadProgress: true }

    const response = await requestHttp.post(urls.getCampaignsGallerySaveUrl(), bodyFormData, config)
    const { content } = response.data.data

    dispatch(saveCampaignGallerySuccess(content))
  } catch (error) {
    dispatch(saveCampaignGalleryFailure(error))
  }
}
export const setCampaignGalleryParams = (params: ICampaignGalleryParams): IAction => ({
  type: CONSTANTS.SET_CAMPAIGN_GALLERY_PARAMS,
  payload: { params },
})

export const setParamsAndFetchGallery = (
  campaignId: number,
  params: ICampaignGalleryParams
): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  await dispatch(setCampaignGalleryParams(params))
  await dispatch(fetchCampaignGallery(campaignId, params))
}

export const fetchCampaignGalleryRequest = (): IAction => ({
  type: CONSTANTS.FETCH_CAMPAIGN_GALLERY_REQUEST,
})

export const fetchCampaignGallerySuccess = (list: ICampaignGalleryList): IAction => ({
  type: CONSTANTS.FETCH_CAMPAIGN_GALLERY_SUCCESS,
  payload: { list },
})

export const fetchCampaignGalleryFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_CAMPAIGN_GALLERY_FAILURE,
  error,
})

export const fetchCampaignGallery = (
  campaignId: number,
  params: ICampaignGalleryParams
): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    dispatch(fetchCampaignGalleryRequest())
    const response = await requestHttp.get(urls.getCampaignsGalleryListUrl(campaignId), { params })
    if (response.status === STATUS_CODES.BASE_SUCCESS) {
      const { content } = response.data.data
      dispatch(fetchCampaignGallerySuccess(content))
    }
  } catch (error) {
    dispatch(fetchCampaignGalleryFailure(error))
  }
}

export const removeCampaignGalleryImage = (url: string): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    const data = { src: url }
    dispatch(campaignUpdatingRequest())
    await requestHttp.delete(urls.getCampaignsGalleryRemoveImageUrl(), { data })

    dispatch(campaignUpdatingSuccess())
  } catch (error) {
    dispatch(campaignUpdatingFailure(error))
    return error
  }
}

export const setGalleryCurrentPage = (page: number): IAction => ({
  type: CONSTANTS.SET_GALLERY_CURRENT_PAGE,
  payload: { page },
})

export const uploadCampaignReports = (
  files: File[],
  entity: CAMPAIGN_UPLOAD_TYPES,
  briefId: number
): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    await upload(urls.postReportFiles(), files, briefId, entity, UPLOAD_ENTITY_KEYS.BRIEF_ID)
  } catch (error) {
    dispatch(uploadTimelineImagesFailure(error))
  }
}

export const deleteCampaignReport = (
  files: string[],
  entity: CAMPAIGN_UPLOAD_TYPES,
  briefId: number
): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    const data = {
      urls: files,
      briefId: String(briefId),
      entity: entity,
    }
    await requestHttp.delete(urls.deleteReportFiles(), { data })
  } catch (error) {
    dispatch(uploadTimelineImagesFailure(error))
  }
}
