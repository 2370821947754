export enum SUPPORT_TICKET_STATUS {
  PENDING = 'PENDING',
  IN_REVIEW = 'IN REVIEW',
  RESOLVED = 'RESOLVED',
  REJECTED = 'REJECTED',
}

export const supportTicketsStatuses = {
  [SUPPORT_TICKET_STATUS.IN_REVIEW]: {
    classStyle: 'approved',
    label: 'In Review',
  },
  [SUPPORT_TICKET_STATUS.PENDING]: {
    classStyle: 'pending',
    label: 'Pending',
  },
  [SUPPORT_TICKET_STATUS.RESOLVED]: {
    classStyle: 'ongoing',
    label: 'Resolved',
  },
  [SUPPORT_TICKET_STATUS.REJECTED]: {
    classStyle: 'pending',
    label: 'Rejected',
  }
}
