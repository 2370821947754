import React, { useEffect, useState } from "react";
import { Menu } from "quantum_components";
import { isMobile } from "react-device-detect";
import { useHistory, useLocation } from "react-router-dom";
import { PATHS } from "src/constants";
import * as actions from "src/modules/core/core.actions";
import { useReduxDispatch } from "src/helpers";
import { useSelector } from "react-redux";
import {
  getActiveMenu,
  getCurrentUser,
  getCurrentUserPermissionActions,
} from "src/modules/core/core.selectors";
import { MENU_ITEMS, PERMISSION_MENU } from "src/constants/menu";
import { activeMenuItem, activeMenuOpen } from "src/helpers/sideMenu";
import { hasPermission } from "src/helpers/permission";
import { useTranslation } from "react-i18next";
import {
  HomeOutlined,
  UsergroupAddOutlined,
  WalletOutlined,
  CustomerServiceOutlined,
  FormOutlined,
  AppstoreAddOutlined,
  DownOutlined,
  RightOutlined,
  BarcodeOutlined,
  FolderAddOutlined
} from "@ant-design/icons";

interface MenuItem {
  key: MENU_ITEMS;
  title: string;
  path: string;
  badgeCount?: string | number;
  badgeClass?: string;
  submenu?: MenuItem[];
  callback?: () => void;
  icon?: any;
  isBottomItem?: boolean;
}

const SidebarMenu = () => {
  const { t } = useTranslation(["translationPlatform"]);
  const history = useHistory();
  const dispatch = useReduxDispatch();
  const activeKey = useSelector(getActiveMenu);
  const permissions = useSelector(getCurrentUserPermissionActions);
  const location = useLocation();
  const currentUser = useSelector(getCurrentUser);

  useEffect(() => {
    const menuItem = activeMenuItem(location.pathname, location.search);
    dispatch(actions.setActiveMenu(menuItem));
  }, [location.pathname, location.search]);

  const menuTop: MenuItem[] = [
    {
      key: MENU_ITEMS.DASHBOARD,
      title: t(`menu.dashboard`),
      path: PATHS.ROOT,
      icon: <HomeOutlined style={{ fontSize: "16px" }} />,
    },
    {
      key: MENU_ITEMS.PRODUCTS,
      title: t(`menu.products`),
      path: PATHS.PRODUCTS,
      icon: <BarcodeOutlined style={{ fontSize: "16px" }} />,
    },
    {
      key: MENU_ITEMS.BRIEFS,
      title: t(`menu.campaigns`),
      path: PATHS.BRIEF,
      icon: <AppstoreAddOutlined style={{ fontSize: "16px" }} />,
    },
    {
      key: MENU_ITEMS.SERVICES,
      title: t(`menu.services`),
      path: PATHS.SERVICES,
      icon: <FormOutlined style={{ fontSize: "16px" }} />,
    },
    {
      key: MENU_ITEMS.PUBLISHERS,
      title: t(`menu.publishers`),
      path: PATHS.PUBLISHERS,
      icon: <FolderAddOutlined style={{ fontSize: "16px" }} />,
    },
    {
      key: MENU_ITEMS.CLIENTS,
      title: t(`menu.clients`),
      path: PATHS.CLIENTS,
      icon: <UsergroupAddOutlined style={{ fontSize: "16px" }} />,
    },
    // {
    //   key: MENU_ITEMS.WALLET,
    //   title: t(`menu.wallet`),
    //   path: PATHS.WALLET,
    //   icon: <WalletOutlined style={{ fontSize: "16px" }} />,
    // },

    // {
    //   key: MENU_ITEMS.SUPPORT_TICKETS,
    //   title: t(`menu.needHelp`),
    //   path: PATHS.SUPPORT_TICKETS,
    //   icon: <CustomerServiceOutlined style={{ fontSize: "16px" }} />,
    //   isBottomItem: true,
    // },
  ];

  const menuUser = [
    {
      key: MENU_ITEMS.UPDATE_PASSWORD,
      title: t(`update_password`),
      path: PATHS.UPDATE_PASSWORD,
    },
    {
      key: MENU_ITEMS.LOGOUT,
      title: t(`menu.logout`),
      path: PATHS.ROOT,
      callback: () => logout(),
    },
  ];

  const onRedirect = (url: string) => {
    history.push(url);
  };

  const logout = () => dispatch(actions.logout());

  const handleClick = (item: MenuItem, subItem?: MenuItem) => {
    if (subItem) {
      item.callback ? item.callback() : onRedirect(subItem.path);
    } else {
      item.callback ? item.callback() : onRedirect(item.path);
    }
  };

  // submenu keys of first level
  const rootSubmenuKeys = [MENU_ITEMS.SERVICES];

  const activeMenu = activeMenuOpen(location.pathname, location.search);
  const [openKeys, setOpenKeys] = useState([activeMenu]);

  const onOpenChange = (keys: any) => {
    const latestOpenKey = keys.find((key: any) => openKeys.indexOf(key) === -1);
    if (latestOpenKey && rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const hasMenuPermission = (item: MenuItem) => {
    if (!item.submenu)
      return hasPermission(permissions, PERMISSION_MENU[item.key]);
    return item.submenu.some((subItem: MenuItem) =>
      hasPermission(permissions, PERMISSION_MENU[subItem.key])
    );
  };

  const renderMenuItem = (item: MenuItem) => {
    const disabled = !hasMenuPermission(item);
    if (item.submenu) {
      return (
        <Menu.SubMenu
          disabled={disabled}
          key={item.key}
          // onTitleClick={() => partnerDefault(item.title)}
          title={
            <>
              <span>
                {item.icon} {item.title}
              </span>{" "}
              <span className="submenu-icon">
                {openKeys.includes(item.key) ? (
                  <RightOutlined />
                ) : (
                  <DownOutlined />
                )}
              </span>
            </>
          }
        >
          {item.submenu.map((subItem: MenuItem) => (
            <Menu.Item
              key={`${item.title}-${subItem.title}`}
              disabled={
                !hasPermission(permissions, PERMISSION_MENU[subItem.key])
              }
              onClick={() => handleClick(item, subItem)}
              className={activeKey === subItem.key ? "active" : ""}
            >
              <div className="full-width">{subItem.title}</div>
            </Menu.Item>
          ))}
        </Menu.SubMenu>
      );
    }
    return (
      <Menu.Item
        disabled={disabled}
        onClick={() => handleClick(item)}
        className={`${activeKey === item.key ? "active" : ""} ${
          item.isBottomItem ? "bottomItem" : ""
        }`}
        key={item.key}
      >
        <div className="full-width">
          {item.icon} {item.title}
        </div>
      </Menu.Item>
    );
  };

  return (
    <>
      <div className="qu-logo-new-container">
        {currentUser?.partnerData?.logo && (
          <img
            src={currentUser.partnerData.logo}
            className="qu-logo-new"
            onClick={() => history.push(PATHS.ROOT)}
          />
        )}
      </div>

      <Menu
        className="sidebar-menu"
        mode="inline"
        defaultOpenKeys={[activeMenu]}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
      >
        {menuTop.map((item: MenuItem) => renderMenuItem(item))}
      </Menu>
    </>
  );
};

export default SidebarMenu;
