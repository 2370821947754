import moment from 'moment'
import { createSelector } from 'reselect'
import { FORMATS } from '../../constants'
import { MODULE_NAME } from './activities.constants'
import { IState, IActivity } from './activities.types'

const selectState = (state: { [MODULE_NAME]: any }) => state[MODULE_NAME]

export const getIsActivitiesLoading = createSelector(selectState, (state: IState): boolean => state.activitiesLoading)

export const getActivities = createSelector(selectState, (state: IState): IActivity[] =>
  state.activities.results.map((activity: IActivity) => {
    return {
      ...activity,
      key: activity.id,
      createdAt: moment(activity.createdAt).format(FORMATS.DATE_TIME_FORMAT),
    }
  })
)

export const getActivitiesTotal = createSelector(selectState, (state: IState): number => state.activities.total)

export const getPages = createSelector(
  selectState,
  (
    state: IState
  ): {
    totalPages: number
    currentPage: number
  } => state.pages
)
