import React, { ReactElement } from 'react'
import { Col, Row } from 'quantum_components'
import { IBriefPartnerDetails, IPartnerDetailsCountry } from '../../modules/brief/brief.types'
import { useTranslation } from 'react-i18next'

interface Props {
  details: IBriefPartnerDetails
}
export default function DetailsAbout({ details }: Props): ReactElement {
  const { t } = useTranslation(['translationProfile'])
  const highlightsAndMetric = [...details.highlights, ...details.metricsMarketing]

  return (
    <div className="qu-details-list mb-35">
      <div className="list-item">
        <Row gutter={16}>
          <Col xs={24} sm={24} md={6}>
            <div className="list-item-title">{t(`profile.public.locations`)}</div>
          </Col>
          <Col xs={24} sm={24} md={18}>
            {details.locations?.map((location: IPartnerDetailsCountry) => (
              <div key={location.name} className="list-item-row">
                <div className="list-item-label">{location.name}</div>
                <div className="list-item-value">{location.cities.join(', ')}</div>
              </div>
            ))}
          </Col>
        </Row>
      </div>
      {details.highlights && (
        <div className="list-item">
          <Row gutter={16}>
            <Col xs={24} sm={24} md={6}>
              <div className="list-item-title">{t(`profile.public.highlights`)}</div>
            </Col>
            <Col xs={24} sm={24} md={18}>
              <div className="pills-container">
                {details.highlights.map((highlight: string, index) => {
                  return <p className="pill" key={index}>{highlight}</p>
                })}
              </div>
              {/* <ul className="list-item-column">
                {details.highlights?.map((highlight: string, i: number) => (
                  <li key={i}>
                    <span>{highlight}</span>
                  </li>
                ))}
              </ul> */}
            </Col>
          </Row>
        </div>
      )}
      {details.metricsMarketing && (
        <div className="list-item">
          <Row gutter={16}>
            <Col xs={24} sm={24} md={6}>
              <div className="list-item-title">{t('profile.public.metrics')}</div>
            </Col>
            <Col xs={24} sm={24} md={18}>
              <div className="pills-container">
                {details.metricsMarketing.map((metric: string, i: number) => {
                  return <p className="pill" key={i}>{metric}</p>
                })}
              </div>
            </Col>
          </Row>
        </div>
      )}
    </div>
  )
}
