import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Radio } from 'quantum_components'
import { TABS_NAMES } from 'src/constants/tabs'
import PayoutListing from '../components/PayoutList'
import WalletListing from '../components/WalletList'
import { renderRoundedNumber } from 'src/helpers/currency'
import { CURRENCY } from 'src/constants/currencies'
import { getWalletDetails } from '../wallet.selectors'

const WalletList = () => {
  const { t } = useTranslation(['translationWallet'])

  const walletDetails = useSelector(getWalletDetails)

  const [activeTab, setActiveTab] = useState(TABS_NAMES.WALLET)

  return (
    <>
      <div className="qu-statistic-wrapper">
        <h1>{t('Wallet')}</h1>
        <ul className="qu-statistic-list">
          <li className="qu-statistic-item">
            <span className="qu-statistic-value">{renderRoundedNumber(Number(walletDetails?.balance), CURRENCY.SAR) === '-' ? "0.00 SAR" : renderRoundedNumber(Number(walletDetails?.balance), CURRENCY.SAR) }</span>
            <span className="qu-statistic-name">{t('Total Balance')}</span>
          </li>
          <li className="qu-statistic-item">
            <span className="qu-statistic-value">{renderRoundedNumber(walletDetails?.totalPaidOut, CURRENCY.SAR)}</span>
            <span className="qu-statistic-name">{t('Total Paid Out')}</span>
          </li>
          <li className="qu-statistic-item">
            <span className="qu-statistic-value">{renderRoundedNumber(walletDetails?.totalRevenue, CURRENCY.SAR)}</span>
            <span className="qu-statistic-name">{t('Total Revenue')}</span>
          </li>
        </ul>
      </div>

      <Radio.Group className="qu-radio-panel mb-24" buttonStyle="solid" value={activeTab} onChange={(event: any) => setActiveTab(event.target.value)} style={{width: "175px"}}>
        <Radio.Button
          className="qu-radio-button"
          value={TABS_NAMES.WALLET}
        >
          <span className="text-uppercase">{t('Wallet')}</span>
        </Radio.Button>
        <Radio.Button
          className="qu-radio-button"
          value={TABS_NAMES.PAYOUTS}
        >
          <span className="text-uppercase">{t('Payouts')}</span>
        </Radio.Button>
      </Radio.Group>

      {activeTab === TABS_NAMES.WALLET && <WalletListing />}

      {activeTab === TABS_NAMES.PAYOUTS && <PayoutListing />}
    </>
  )
}

export default WalletList
