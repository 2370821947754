export enum REQUEST_STATUS {
  REVIEW_NOW = 'REVIEW NOW',
  REJECTED = 'REJECTED',
  EXPIRED = 'EXPIRED',
  ACCEPTED = 'ACCEPTED',
  PENDING = 'PENDING',
}

export enum REQUEST_ACTIONS {
  REJECT = 'REJECT',
  APPROVE = 'APPROVE',
  PENDING = 'PENDING',
  EXTEND = 'EXTEND',
  ADMIN_REJECT = 'ADMIN_REJECT',
  ADMIN_APPROVE = 'ADMIN_APPROVE',
}

export const requestStatusesLabel = {
  [REQUEST_STATUS.REVIEW_NOW]: {
    classStyle: 'review',
    label: 'requests-status.REVIEW NOW',
  },
  [REQUEST_STATUS.REJECTED]: {
    classStyle: 'rejected',
    label: 'requests-status.REJECTED',
  },
  [REQUEST_STATUS.EXPIRED]: {
    classStyle: 'expired',
    label: 'requests-status.EXPIRED',
  },
  [REQUEST_STATUS.ACCEPTED]: {
    classStyle: 'approved',
    label: 'requests-status.ACCEPTED',
  },
  [REQUEST_STATUS.PENDING]: {
    classStyle: 'pending',
    label: 'requests-status.PENDING',
  },
}

export const REQUEST_STATUS_NAMES: { [key: string]: string } = {
  [REQUEST_STATUS.REVIEW_NOW]: 'Review Now',
  [REQUEST_STATUS.EXPIRED]: 'Expired',
  [REQUEST_STATUS.PENDING]: 'Pending',
  [REQUEST_STATUS.REJECTED]: 'Rejected',
  [REQUEST_STATUS.ACCEPTED]: 'Accepted',
}

export const REQUEST_STATUS_LIST = [
  REQUEST_STATUS.REVIEW_NOW,
  REQUEST_STATUS.EXPIRED,
  REQUEST_STATUS.PENDING,
  REQUEST_STATUS.REJECTED,
  REQUEST_STATUS.ACCEPTED,
]
export const REQUEST_AWAITING_DAYS = 7
