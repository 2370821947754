import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Form, InfoCard, PublicProfileField, QCheckbox, QSelect, Row } from 'quantum_components'
import { Select } from 'antd'

import { useReduxDispatch } from 'src/helpers'
import { actions } from '../../../partnerProfile.module'
import { getForm, getPartnerProfileSettings } from '../../../profile.selectors'
import { debounce, pick } from 'lodash'

import { ICity, ICountry, IForm } from '../../../profile.types'

import '../../../styles.scss'
import { CUSTOMERS_AND_LOCALE_VALUES } from 'src/constants/profile'
import AgeGroupCardInput from '../../components/CustomInputs/AgeGroupCardInput'
import GenderFocusInput from '../../components/CustomInputs/GenderFocusInput'
import SegmentsInput from '../../components/CustomInputs/SegmentsInput'
import { useTranslation } from 'react-i18next'

interface IProps {
  setError: (stepNmae: string, value: boolean) => void
}

export const StepThreeContainer: FC<IProps> = () => {
  const {t} = useTranslation(['translationProfile'])
  const [formRef] = Form.useForm()
  const dispatch = useReduxDispatch()
  const settings = useSelector(getPartnerProfileSettings)
  const partnerForm = useSelector(getForm)
  const stepFields = pick(partnerForm, Object.values(CUSTOMERS_AND_LOCALE_VALUES))

  const partnerGenders = stepFields.partnerGenders ? stepFields.partnerGenders : []
  const partnerAgeGroups = stepFields.partnerAgeGroups ? stepFields.partnerAgeGroups : []
  const partnerTargetSegments = stepFields.partnerTargetSegments ? stepFields.partnerTargetSegments : []

  const initialValues = { partnerGenders, partnerAgeGroups, partnerTargetSegments }

  let newCountries:ICountry[] = [];

  useEffect(() => {
    dispatch(
      actions.setForm(
        pick({ partnerAgeGroups, partnerTargetSegments, partnerGenders }, [
          'partnerAgeGroups',
          'partnerTargetSegments',
          'partnerGenders',
        ])
      )
    )
  }, [])

  const [form, setForm] = useState<IForm>({
    ...partnerForm,
    countries: partnerForm.countries ? partnerForm.countries : [],
    cities: partnerForm.cities ? partnerForm.cities : [],
  })

  const { Option, OptGroup } = Select

  const [countries, setCountries] = useState<ICountry[]>([])

  useEffect(() => {
    setCountries(settings.countries)
  }, [settings.countries])

  useEffect(() => {
    const selectedCountries = form.countries
    const filtered = !selectedCountries.length
      ? settings.countries
      : settings.countries.filter((c: ICountry) => selectedCountries.includes(c.name))

    setCountries(filtered)
  }, [form])

  const handleChangeCountries = (event: any) => {
    const newForm = { ...form }
    const { id } = event.target

    newForm.countries.includes(id)
      ? newForm.countries.splice(newForm.countries.indexOf(id), 1)
      : newForm.countries.push(id)

    const countriesObj = settings.countries.filter((c: ICountry) => newForm.countries.includes(c.name))
    if (!countriesObj.length) {
      newForm.cities = []
    }

    const selectedCountryCities = countriesObj.map((c: ICountry) => c.city.map((c: ICity) => c.name)).flat()
    newForm.cities = newForm.cities.filter((c: string) => selectedCountryCities.includes(c))

    formRef.setFieldsValue({ cities: newForm.cities })
    setForm(newForm)
    dispatch(
      actions.setForm({
        ...partnerForm,
        countries: newForm.countries.length > 0 ? newForm.countries : [],
        cities: newForm.cities.length > 0 ? newForm.cities : [],
      })
    )
  }

  const handleChangeCities = (values: string[], cities: any[]) => {
    const newForm = { ...form }

    const countries = cities.map(c => c.country)
    const uniqueCountries = countries.filter((v, i, a) => a.indexOf(v) === i)

    newForm.cities = values
    newForm.countries = uniqueCountries

    setForm(newForm)
    dispatch(
      actions.setForm({
        ...partnerForm,
        countries: newForm.countries.length > 0 ? newForm.countries : [],
        cities: newForm.cities.length > 0 ? newForm.cities : [],
      })
    )
  }

  const checkboxesCountries = () => {
    if (!settings.countries) {
      return []
    }

    
    newCountries = settings.countries.filter(item=>item.name === 'Saudi Arabia');

    return newCountries.map((country: ICountry) => (
      <Form.Item className="mb-0" name={country.name} key={country.id}>
        <QCheckbox
          checked={form.countries.includes(country.name)}
          className="test-class"
          onChange={handleChangeCountries}
        >
          {t(`profile.partner.customer_and_locale.countries-list.${country.name}`)}
        </QCheckbox>
      </Form.Item>
    ))
  }

  const onValuesChange = (changedValues: any, values: typeof initialValues) => {
    dispatch(actions.setForm(pick({ ...values }, ['partnerAgeGroups', 'partnerTargetSegments', 'partnerGenders'])))
  }

  const delayedQuery = useCallback(
    //@ts-ignore
    debounce((c, a) => onValuesChange(c, a), 500),
    []
  )

  return (
    <div className="third_content">
      <Form form={formRef} initialValues={initialValues} onValuesChange={delayedQuery}>
        <InfoCard headerLeft={<h3>{t("profile.partner.customer_and_locale.customer_and_locale")}</h3>}>
          <PublicProfileField
            description={{ title: t("profile.partner.customer_and_locale.what_gender_does_your_store_focus") }}
            input={
              <Form.Item className="mb-0 full-width" name="partnerGenders">
                {/* @ts-ignore */}
                <GenderFocusInput />
              </Form.Item>
            }
          />
          <PublicProfileField
            description={{ title: t("profile.partner.customer_and_locale.what_agegroups_do_you_target") }}
            input={
              <>
                <Row gutter={8} align="middle">
                  <Form.Item noStyle name="partnerAgeGroups">
                    {/* @ts-ignore */}
                    <AgeGroupCardInput />
                  </Form.Item>
                </Row>
              </>
            }
          />
          {/* <PublicProfileField
            description={{
              title: 'What segments do you target?',
            }}
            input={
              <Form.Item className="mb-0" name="partnerTargetSegments"> */}
                {/* @ts-ignore */}
                {/* <SegmentsInput />
              </Form.Item>
            }
          /> */}
          <PublicProfileField
            description={{
              title: t("profile.partner.customer_and_locale.what_countries_do_you_serve"),
            }}
            input={<>{checkboxesCountries()}</>}
          />
          <PublicProfileField
            description={{
              title: <span>{t("profile.partner.customer_and_locale.what_cities_do_you_serve")}</span>,
            }}
            input={
              <Form.Item className="mb-0" name="cities">
                <QSelect
                  defaultValue={form.cities}
                  mode="multiple"
                  size="large"
                  showArrow
                  showSearch
                  placeholder={t("profile.partner.customer_and_locale.select_cities")}
                  onChange={handleChangeCities}
                >
                  {newCountries.map((country: ICountry) => {
                    return (
                      <OptGroup key={country.id} label={country.name}>
                        {country.city.map((city: ICity) => {
                          return (
                            <Option key={city.id} country={country.name} value={city.name}>
                              {city.name}
                            </Option>
                          )
                        })}
                      </OptGroup>
                    )
                  })}
                </QSelect>
              </Form.Item>
            }
          />
        </InfoCard>
      </Form>
    </div>
  )
}
