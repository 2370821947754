export const MODULE_NAME = 'bill'
/* actions */

export const FETCH_BILLS_REQUEST = `${MODULE_NAME}/FETCH_BILLS_REQUEST`
export const FETCH_BILLS_SUCCESS = `${MODULE_NAME}/FETCH_BILLS_SUCCESS`
export const FETCH_BILLS_FAILURE = `${MODULE_NAME}/FETCH_BILLS_FAILURE`

export const FETCH_REQUESTS_REQUEST = `${MODULE_NAME}/FETCH_REQUESTS_REQUEST`
export const FETCH_REQUESTS_SUCCESS = `${MODULE_NAME}/FETCH_REQUESTS_SUCCESS`
export const FETCH_REQUESTS_FAILURE = `${MODULE_NAME}/FETCH_REQUESTS_FAILURE`

export const FETCH_ONLINE_PAYMENTS_REQUEST = `${MODULE_NAME}/FETCH_ONLINE_PAYMENTS_REQUEST`
export const FETCH_ONLINE_PAYMENTS_SUCCESS = `${MODULE_NAME}/FETCH_ONLINE_PAYMENTS_SUCCESS`
export const FETCH_ONLINE_PAYMENTS_FAILURE = `${MODULE_NAME}/FETCH_ONLINE_PAYMENTS_FAILURE`

export const SET_BILL_LIST_PARAMS = `${MODULE_NAME}/SET_BILL_LIST_PARAMS`

export const SET_CURRENT_PAGE = `${MODULE_NAME}/SET_CURRENT_PAGE`

export const FILE_UPLOAD_REQUEST = `${MODULE_NAME}/FILE_UPLOAD_REQUEST`
export const FILE_UPLOAD_SUCCESS = `${MODULE_NAME}/FILE_UPLOAD_SUCCESS`
export const FILE_UPLOAD_FAILURE = `${MODULE_NAME}/FILE_UPLOAD_FAILURE`

export const FILE_REMOVE_REQUEST = `${MODULE_NAME}/FILE_REMOVE_REQUEST`
export const FILE_REMOVE_SUCCESS = `${MODULE_NAME}/FILE_REMOVE_SUCCESS`
export const FILE_REMOVE_FAILURE = `${MODULE_NAME}/FILE_REMOVE_FAILURE`

export const PARTNER_FILE_UPLOAD_REQUEST = `${MODULE_NAME}/PARTNER_FILE_UPLOAD_REQUEST`
export const PARTNER_FILE_UPLOAD_SUCCESS = `${MODULE_NAME}/PARTNER_FILE_UPLOAD_SUCCESS`
export const PARTNER_FILE_UPLOAD_FAILURE = `${MODULE_NAME}/PARTNER_FILE_UPLOAD_FAILURE`

export const PARTNER_FILE_REMOVE_REQUEST = `${MODULE_NAME}/PARTNER_FILE_REMOVE_REQUEST`
export const PARTNER_FILE_REMOVE_SUCCESS = `${MODULE_NAME}/PARTNER_FILE_REMOVE_SUCCESS`
export const PARTNER_FILE_REMOVE_FAILURE = `${MODULE_NAME}/PARTNER_FILE_REMOVE_FAILURE`

export const BILL_ACCEPT_REQUEST = `${MODULE_NAME}/BILL_ACCEPT_REQUEST`
export const BILL_ACCEPT_SUCCESS = `${MODULE_NAME}/BILL_ACCEPT_SUCCESS`
export const BILL_ACCEPT_FAILURE = `${MODULE_NAME}/BILL_ACCEPT_FAILURE`

export const BILL_REJECT_REQUEST = `${MODULE_NAME}/BILL_REJECT_REQUEST`
export const BILL_REJECT_SUCCESS = `${MODULE_NAME}/BILL_REJECT_SUCCESS`
export const BILL_REJECT_FAILURE = `${MODULE_NAME}/BILL_REJECT_FAILURE`

export const REFUND_ONLINE_PAYMENT_REQUEST = `${MODULE_NAME}/REFUND_ONLINE_PAYMENT_REQUEST`
export const REFUND_ONLINE_PAYMENT_SUCCESS = `${MODULE_NAME}/REFUND_ONLINE_PAYMENT_SUCCESS`
export const REFUND_ONLINE_PAYMENT_FAILURE = `${MODULE_NAME}/REFUND_ONLINE_PAYMENT_FAILURE`
