import React, { FC, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";

import {
  InfoCard,
  PublicProfileField,
  QInput,
  QSelect,
  Form,
  Radio,
  QRadio,
} from "quantum_components";

import "../../../styles.scss";

import { getForm, getPartnerProfileSettings } from "../../../profile.selectors";
import { actions } from "../../../partnerProfile.module";
import { useReduxDispatch } from "src/helpers";
import { REGEXES } from "src/constants";
import {
  BANK_INFORMATION_VALUES,
  GENERAL_COMPANY_INFO,
  GENERAL_COMPANY_INFO_VALUES,
  PARTNER_STEPS,
  SALES_INFORMATION,
  SALES_INFORMATION_VALUES,
} from "src/constants/profile";
import { SOCIAL_TYPES } from "src/constants/partner";
import { debounce, pick } from "lodash";
import { useTranslation } from "react-i18next";

interface IProps {
  setError: (stepName: string, value: boolean) => void;
}

export const StepTwoContainer: FC<IProps> = ({ setError }) => {
  const { t } = useTranslation(["translationProfile", "translationDashboard"]);
  const [form] = Form.useForm();
  const dispatch = useReduxDispatch();
  const settings = useSelector(getPartnerProfileSettings);
  const partnerForm = useSelector(getForm);

  // console.log(partnerForm)
  const stepFields = pick(partnerForm, Object.values(SALES_INFORMATION_VALUES));
  const bankFields = pick(
    partnerForm.bankDetails,
    Object.values(BANK_INFORMATION_VALUES)
  );

  const initialValues = {
    ...stepFields,
    ...bankFields,
    productCategories: stepFields.productCategories
      ? stepFields.productCategories
      : [],
  };

  const selectProductCategories = () => {
    if (settings.industries) {
      return settings.industries.map((elem: string) => ({
        label: t(`dashboard.industry-options.${elem}`, elem, {
          ns: "translationDashboard",
        }),
        value: elem,
      }));
    } else {
      return [];
    }
  };

  const selectCurrencies = () => {
    if (settings.currencies) {
      return settings.currencies.map((elem: string) => ({
        label: t(
          `profile.partner.sales_info.primary-store-currencies.${elem}`,
          elem
        ),
        value: elem,
      }));
    } else {
      return [];
    }
  };

  const onValuesChange = (
    changedValue: any,
    allValues: typeof initialValues
  ) => {
    const newMetrics = {
      ...stepFields.metrics,
      ...Object.keys(allValues.metrics || {}).reduce((acc, item) => {
        //@ts-ignore
        return {
          ...acc,
          //@ts-ignore
          [item]: allValues.metrics[item] ? allValues.metrics[item] : "",
        };
      }, {}),
    };

    const newBankFields = {
      ...bankFields,
      ...Object.keys(bankFields).reduce((acc, item) => {
        return {
          ...acc,
          //@ts-ignore
          [item]: allValues[item] ? allValues[item] : "",
        };
      }, {}),
    };

    let formValues = {
      ...allValues,
      amountOfOrders: allValues.amountOfOrders
        ? Number(allValues.amountOfOrders)
        : null,
      bankDetails: newBankFields,
      costOfOrder: allValues.costOfOrder ? Number(allValues.costOfOrder) : null,
      metrics: newMetrics,

      webhookUrl:
        allValues.customInvoicing === "NO" ? "" : allValues.webhookUrl,
      webhookAuth:
        allValues.customInvoicing === "NO" ? "" : allValues.webhookAuth,
    };

    dispatch(actions.setForm(formValues));
  };

  const delayedQuery = useCallback(
    //@ts-ignore
    debounce((c, a) => onValuesChange(c, a), 500),
    []
  );

  const validation = async (rule: any, v: string | number | null) => {
    const value = v === null ? "" : `${v}`;

    // if(rule.field ===  SALES_INFORMATION.VAT){
    //   if((value.length !== 15 || !RegExp(REGEXES.NUMBERS_REGEX).test(value))){
    //     return await Promise.reject(
    //       t("profile.partner.sales_info.invalid_vat")
    //     );
    //   }
    //   return await Promise.resolve();
    // }

    if (rule.field === SALES_INFORMATION.CR) {
      if (value.length !== 10 || !RegExp(REGEXES.NUMBERS_REGEX).test(value)) {
        return await Promise.reject(t("profile.partner.sales_info.invalid_cr"));
      }
      return await Promise.resolve();
    }
    // if (rule.field === SALES_INFORMATION.WEBHOOK_URL) {
    //   if(!value) return await Promise.resolve()

    //   if (!RegExp(REGEXES.URL).test(value)) {
    //     return await Promise.reject(
    //       t("profile.partner.sales_info.invalid_url")
    //     );
    //   }

    //   return await Promise.resolve();
    // }
    if (rule.field === SALES_INFORMATION.National_Address) {
      if (!value) {
        return await Promise.reject(
          t("profile.partner.sales_info.invalid_national_address")
        );
      }
      return await Promise.resolve();
    }

    if (
      rule.field !== SALES_INFORMATION.VAT &&
      Number(value) > LIMIT_FOR_NUMBERS.MAX
    ) {
      return await Promise.reject(
        t("profile.partner.sales_info.max_number_limit_reached")
      );
    }
    if (value.length && !RegExp(REGEXES.AMOUNT_REGEX).test(value)) {
      return await Promise.reject(
        t("profile.partner.sales_info.invalid_amount")
      );
    }
    return await Promise.resolve();
  };
  const LIMIT_FOR_NUMBERS = {
    MIN: 0,
    MAX: 1000000000,
  };

  const validateFields = async () => {
    try {
      await form.validateFields();
      setError(PARTNER_STEPS.SALES, false);
    } catch (error) {
      setError(PARTNER_STEPS.SALES, true);
    }
  };

  useEffect(() => {
    validateFields();
  }, [partnerForm]);

  return (
    <div className="second_content">
      <InfoCard
        headerLeft={
          <h3>{t("profile.partner.sales_info.sales_information")}</h3>
        }
      >
        <Form
          form={form}
          onValuesChange={delayedQuery}
          initialValues={initialValues}
        >
          <PublicProfileField
            description={{
              title: (
                <span>
                  {t(
                    "profile.partner.sales_info.what_product_categories_do_you_sell"
                  )}
                </span>
              ),
            }}
            input={
              <Form.Item
                name={
                  SALES_INFORMATION_VALUES[SALES_INFORMATION.PRODUCT_CATEGORIES]
                }
              >
                <QSelect
                  style={{ width: "100%" }}
                  mode="multiple"
                  size="large"
                  placeholder={t(
                    "profile.partner.sales_info.select_product_categories"
                  )}
                  options={selectProductCategories()}
                />
              </Form.Item>
            }
          />
          <PublicProfileField
            description={{
              title: (
                <span>
                  {t(
                    "profile.partner.sales_info.average_number_of_monthly_online_traffic"
                  )}
                </span>
              ),
            }}
            input={
              <Form.Item
                name={[
                  GENERAL_COMPANY_INFO_VALUES[
                    GENERAL_COMPANY_INFO.SOCIAL_MEDIA_URLS
                  ],
                  SOCIAL_TYPES.LOOKS,
                ]}
                rules={[
                  {
                    message: t("profile.partner.sales_info.invalid_amount"),
                    pattern: REGEXES.AMOUNT_REGEX,
                  },
                ]}
              >
                <QInput
                  placeholder={t(
                    "profile.partner.sales_info.enter_monthly_visitors"
                  )}
                  size="large"
                  type="number"
                />
              </Form.Item>
            }
          />
          <PublicProfileField
            description={{
              title: (
                <span>
                  {t(
                    "profile.partner.sales_info.average_number_of_monthly_customers"
                  )}
                </span>
              ),
            }}
            input={
              <>
                <Form.Item
                  name={[
                    GENERAL_COMPANY_INFO_VALUES[
                      GENERAL_COMPANY_INFO.SOCIAL_MEDIA_URLS
                    ],
                    SOCIAL_TYPES.LEADS,
                  ]}
                  rules={[
                    {
                      message: t("profile.partner.sales_info.invalid_amount"),
                      pattern: REGEXES.AMOUNT_REGEX,
                    },
                  ]}
                >
                  <QInput
                    placeholder={t(
                      "profile.partner.sales_info.enter_monthly_customers"
                    )}
                    size="large"
                    type="number"
                  />
                </Form.Item>
                <p>
                  {t("profile.partner.sales_info.visible_fields_help_text")}
                </p>
              </>
            }
          />

          <PublicProfileField
            description={{
              title: t("profile.partner.sales_info.primary_store_currency"),
            }}
            input={
              <Form.Item
                name={
                  SALES_INFORMATION_VALUES[
                    SALES_INFORMATION.PRIMARY_STORE_CURRENCY
                  ]
                }
              >
                <QSelect
                  placeholder={t(
                    "profile.partner.sales_info.select_a_currency"
                  )}
                  options={selectCurrencies()}
                  size="large"
                  style={{ width: "100%" }}
                  disabled={true}
                />
              </Form.Item>
            }
          />
          <PublicProfileField
            description={{
              title: t("profile.partner.sales_info.tax_treatment"),
            }}
            input={
              <>
                <Form.Item name="taxTreatment">
                  <Radio.Group className="qu-radio-group">
                    <QRadio value={"VAT Registered"}>
                      {t("profile.partner.sales_info.tax_type_vat")}
                    </QRadio>
                    <QRadio value={"Non-VAT Registered"}>
                      {t("profile.partner.sales_info.tax_type_non_vat")}
                    </QRadio>
                  </Radio.Group>
                </Form.Item>
              </>
            }
          />
          {partnerForm[SALES_INFORMATION.TAX_TREATMENT] ===
            "VAT Registered" && (
            <PublicProfileField
              description={{
                title: t("profile.partner.sales_info.vat_registration_number"),
              }}
              input={
                <Form.Item
                  rules={[
                    { required: true, message:t("profile.required") },
                    {
                      message: t("profile.partner.sales_info.invalid_vat"),
                      pattern: REGEXES.FIFTEEN_DIGIT,
                    },
                  ]}
                  name={SALES_INFORMATION_VALUES[SALES_INFORMATION.VAT]}
                >
                  <QInput
                    placeholder={t(
                      "profile.partner.sales_info.enter_vat_registration_number"
                    )}
                    type="number"
                    size="large"
                  />
                </Form.Item>
              }
            />
          )}

          <PublicProfileField
            description={{
              title: t("profile.partner.sales_info.cr"),
            }}
            input={
              <Form.Item
                rules={[
                  {
                    validator: async (rule, value: string) =>
                      await validation(rule, value),
                  },
                ]}
                name={SALES_INFORMATION_VALUES[SALES_INFORMATION.CR]}
              >
                <QInput
                  placeholder={t("profile.partner.sales_info.cr")}
                  type="number"
                  size="large"
                />
              </Form.Item>
            }
          />

          <PublicProfileField
            description={{
              title: t("profile.partner.sales_info.national_address"),
            }}
            input={
              <Form.Item
                rules={[
                  {
                    validator: async (rule, value: string) =>
                      await validation(rule, value),
                  },
                ]}
                name={
                  SALES_INFORMATION_VALUES[SALES_INFORMATION.National_Address]
                }
              >
                <QInput
                  placeholder={t("profile.partner.sales_info.enter_here")}
                  size="large"
                />
              </Form.Item>
            }
          />

          <PublicProfileField
            description={{
              title: t("profile.partner.sales_info.bank_account_currency"),
            }}
            input={
              <Form.Item name="account_currency">
                <QSelect
                  placeholder={t(
                    "profile.partner.sales_info.select_a_currency"
                  )}
                  options={selectCurrencies()}
                  size="large"
                  style={{ width: "100%" }}
                  disabled={true}
                />
              </Form.Item>
            }
          />
          <PublicProfileField
            description={{
              title: t("profile.partner.sales_info.bank_name"),
            }}
            input={
              <Form.Item name="bank_name">
                <QInput
                  placeholder={t("profile.partner.sales_info.enter_here")}
                  type="text"
                  size="large"
                />
              </Form.Item>
            }
          />
          <PublicProfileField
            description={{
              title: t("profile.partner.sales_info.bank_account_number"),
            }}
            input={
              <Form.Item name="account_number">
                <QInput
                  placeholder={t("profile.partner.sales_info.enter_here")}
                  type="text"
                  size="large"
                />
              </Form.Item>
            }
          />
          <PublicProfileField
            description={{
              title: t("profile.partner.sales_info.address"),
            }}
            input={
              <Form.Item name="address">
                <QInput
                  placeholder={t("profile.partner.sales_info.enter_here")}
                  type="text"
                  size="large"
                />
              </Form.Item>
            }
          />
          <PublicProfileField
            description={{
              title: t("profile.partner.sales_info.bank_address"),
            }}
            input={
              <Form.Item name="bank_address">
                <QInput
                  placeholder={t("profile.partner.sales_info.enter_here")}
                  type="text"
                  size="large"
                />
              </Form.Item>
            }
          />
          <PublicProfileField
            description={{
              title: t("profile.partner.sales_info.iban"),
            }}
            input={
              <Form.Item name="iban_number">
                <QInput
                  placeholder={t("profile.partner.sales_info.enter_here")}
                  type="text"
                  size="large"
                />
              </Form.Item>
            }
          />
          <PublicProfileField
            description={{
              title: t("profile.partner.sales_info.swift_code"),
            }}
            input={
              <Form.Item name="swift_code">
                <QInput
                  placeholder={t("profile.partner.sales_info.enter_here")}
                  type="text"
                  size="large"
                />
              </Form.Item>
            }
          />


          {/* <PublicProfileField
            description={{
              title: t("profile.partner.sales_info.custom_invoicing"),
              subTitle: t("profile.partner.sales_info.custom_invoicing_help"),
            }}
            input={
              <>
                <Form.Item
                  name={
                    SALES_INFORMATION_VALUES[SALES_INFORMATION.CUSTOM_INVOICING]
                  }
                >
                  <Radio.Group className="qu-radio-group">
                    <QRadio value={"YES"}>
                      {t("profile.partner.sales_info.custominvoicing_yes")}
                    </QRadio>
                    <QRadio value={"NO"}>
                      {t("profile.partner.sales_info.custominvoicing_no")}
                    </QRadio>
                  </Radio.Group>
                </Form.Item>
              </>
            }
          />
          <PublicProfileField
            description={{
              title: t("profile.partner.sales_info.webhook_url"),
              subTitle: t("profile.partner.sales_info.webhook_url_help"),
            }}
            input={
              <Form.Item
                name={SALES_INFORMATION_VALUES[SALES_INFORMATION.WEBHOOK_URL]}
                rules={[
                  {
                    validator: async (rule, value: string) => await validation(rule, value),
                  },
                ]}
                validateTrigger={['onChange', 'onBlur']}
              >
                <QInput
                  placeholder={t("profile.partner.sales_info.enter_here")}
                  type="text"
                  size="large"
                />
              </Form.Item>
            }
          />
          <PublicProfileField
            description={{
              title: t("profile.partner.sales_info.webhook_auth"),
              subTitle: t("profile.partner.sales_info.webhook_auth_help"),
            }}
            input={
              <Form.Item
                name={SALES_INFORMATION_VALUES[SALES_INFORMATION.WEBHOOK_AUTH]}
              >
                <QInput
                  placeholder={t("profile.partner.sales_info.enter_here")}
                  type="text"
                  size="large"
                />
              </Form.Item>
            }
          /> */}
        </Form>
      </InfoCard>
    </div>
  );
};
