import React, { ReactElement, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useReduxDispatch } from 'src/helpers'
import { QTable, Dropdown, QMenuItem, Menu, IconsCommon, QPagination } from 'quantum_components'
import { useSelector } from 'react-redux'
import {
  getCampaignSettings,
  getCampaignTimelineList,
  getCampaignTimelineListLoading,
  getTimelinePages,
  getTotalCampaignTimelineList,
} from '../../../campaign.selectors'
import { ICampaignPartner, ICampaignTimelineTable } from '../../../campaign.types'
import { Modal } from 'antd'
import { actions } from '../../../campaign.module'
import { FORMATS } from '../../../../../constants'
import moment from 'moment'
import { CAMPAIGN_PARTNERS, CAMPAIGN_PARTNERS_NAMES, CAMPAIGN_STATUS } from '../../../../../constants/campaign'
import { getFileNameFromUrl } from '../../../../../utils'
import CampaignEditTimelineForm from './CampaignEditTimeline.form'

interface IProps {
  disabledButton: boolean
}

export default function CampaignTimelineTable({ disabledButton }: IProps): ReactElement {
  const dispatch = useReduxDispatch()

  const timelineList = useSelector(getCampaignTimelineList)
  const loading = useSelector(getCampaignTimelineListLoading)
  const initData = useSelector(getCampaignSettings)
  const pages = useSelector(getTimelinePages)
  const totalTimelineList = useSelector(getTotalCampaignTimelineList)

  const [editModal, setEditModal] = useState<boolean>(false)
  const [deleteModal, setDeleteModal] = useState<boolean>(false)
  const [activeTimeline, setActiveTimeline] = useState<ICampaignTimelineTable | null>(null)
  const [tableKey, setTableKey] = useState(1)
  const { id } = useParams<{id: any}>()

  const isCompleted = initData.campaignStatus === CAMPAIGN_STATUS.COMPLETED

  useEffect(() => {
    fetchTimelineList()
  }, [])

  const fetchTimelineList = async () => {
    await dispatch(actions.fetchCampaignTimeline(id))
  }

  const handleDelete = async () => {
    if (!activeTimeline) return
    await dispatch(actions.deleteCampaignTimeline(activeTimeline.key))
    fetchTimelineList()

    setActiveTimeline(null)
    setDeleteModal(false)
    setTableKey(Math.floor(Math.random() * 1000 + 1))
  }

  const handleEditModalOpen = (timeline: ICampaignTimelineTable) => {
    setActiveTimeline(timeline)
    setEditModal(true)
  }

  const handleEditModalClose = () => {
    setEditModal(false)
    setActiveTimeline(null)
  }

  const handleDeleteModalOpen = (timeline: ICampaignTimelineTable) => {
    setActiveTimeline(timeline)
    setDeleteModal(true)
  }

  const handleDeleteModalClose = () => {
    setDeleteModal(false)
    setActiveTimeline(null)
    setTableKey(Math.floor(Math.random() * 1000 + 1))
  }

  const handleChangePage = async (page: number, pageSize: number) => {
    await dispatch(actions.setTimelineCurrentPage(page))
    await dispatch(actions.fetchCampaignTimeline(id, { offset: (page - 1) * pageSize, limit: pageSize }))
  }

  const menu = (timeline: ICampaignTimelineTable) => (
    <Menu className="user-menu-list">
      <QMenuItem key={0} onClick={() => handleEditModalOpen(timeline)} name="Edit" icon={<IconsCommon.IconEdit />} />
      <Menu.Divider />
      <QMenuItem
        key={1}
        onClick={() => handleDeleteModalOpen(timeline)}
        name="Delete"
        icon={<IconsCommon.IconDelete />}
      />
    </Menu>
  )

  const timelineColumns = [
    {
      title: 'Created',
      dataIndex: 'date',
      key: 'date',
      width: '20%',
      render: (date: string) => <div>{moment(date).format(FORMATS.DATE_LL_FORMAT)}</div>,
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: '20%',
    },
    {
      title: 'Partner',
      dataIndex: 'partner',
      key: 'partner',
      width: '20%',
      render: (partner: ICampaignPartner) => (
        <span>{partner.companyName || CAMPAIGN_PARTNERS_NAMES[CAMPAIGN_PARTNERS.QUANTUM_TEAM]}</span>
      ),
    },
    {
      title: 'Media',
      dataIndex: 'media',
      key: 'media',
      width: '38%',
      render: (media: string[]) => (
        <ul className="timeline_images">
          {media.map((url: string) => (
            <li className="timeline__photo" key={url}>
              <img src={url} alt={url} />
            </li>
          ))}
        </ul>
      ),
    },
    {
      title: '',
      key: 'action',
      width: '2%',
      render: (timeline: ICampaignTimelineTable) => {
        return (
          <div style={{ margin: '0 -16px' }}>
            {!isCompleted && (
              <Dropdown overlay={menu(timeline)} trigger={['click']} placement="bottomRight">
                <div className="q-table_menu">
                  <IconsCommon.IconEllipsisV />
                </div>
              </Dropdown>
            )}
          </div>
        )
      },
    },
  ]

  return (
    <div className="p-16">
      <QTable columns={timelineColumns} dataSource={timelineList} loading={loading} />
      <div className="qt-footer">
        <QPagination
          size="default"
          defaultCurrent={1}
          current={pages.currentPage}
          total={totalTimelineList}
          onChange={handleChangePage}
          showSizeChanger
        />
      </div>
      {editModal && (
        <Modal
          wrapClassName="qu-modal"
          title={<h3>Edit {activeTimeline?.title}</h3>}
          visible={editModal}
          onCancel={handleEditModalClose}
          footer={false}
        >
          {activeTimeline && (
            <CampaignEditTimelineForm
              disabledButton={disabledButton}
              timeline={activeTimeline}
              closeModal={handleEditModalClose}
            />
          )}
        </Modal>
      )}
      <Modal key={tableKey} title="" visible={deleteModal} onOk={handleDelete} onCancel={handleDeleteModalClose}>
        <p>
          Do you want to delete <strong> {activeTimeline?.title} </strong>?
        </p>
      </Modal>
    </div>
  )
}
