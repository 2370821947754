import { createSelector } from 'reselect'
import { MODULE_NAME } from './wallet.constants'
import { IPayout, IState, IWallet, IWalletHistory } from './wallet.types'

const selectState = (state: { [MODULE_NAME]: any }) => state[MODULE_NAME]

export const getIsWalletListLoading = createSelector(
  selectState,
  (state: IState): boolean => state.walletListLoading
)

export const getWalletDetails = createSelector(selectState, (state: IState): IWallet =>
  state.walletDetails
)

export const getWalletHistoryList = createSelector(selectState, (state: IState): IWalletHistory[] =>
  state.walletDetails.history.results.map((wallet: IWalletHistory, index: number) => {
    return {
      ...wallet,
      key: index,
    }
  })
)

export const getWalletTotal = createSelector(
  selectState,
  (state: IState): number => state.walletDetails.history.total
)

export const getPages = createSelector(
  selectState,
  (
    state: IState
  ): {
    totalPages: number
    currentPage: number
  } => state.pages
)

export const getIsPayoutsListLoading = createSelector(selectState, (state: IState): boolean => state.payoutsListLoading)

export const getPayoutsList = createSelector(selectState, (state: IState): IPayout[] =>
  state.payoutsList.results.map((payout: IPayout, index: number) => {
    return {
      ...payout,
      key: index,
    }
  })
)

export const getPayoutsTotal = createSelector(selectState, (state: IState): number => state.payoutsList.total)

export const getPayoutsPages = createSelector(selectState, (state: IState): { totalPages: number; currentPage: number } => state.payoutsPages)
