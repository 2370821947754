import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { AxiosResponse } from 'axios'
import IAction, { TName } from 'src/interfaces/IAction'

import * as CONSTANTS from './promotions.constants'
import { requestHttp, urls } from 'src/api'
import { getResponseErrorMessage } from 'src/helpers'

export const promotionRequest = (): IAction => ({
  type: CONSTANTS.PROMOTIONS_REQUEST,
})

export const promotionEndRequest = (): IAction => ({
  type: CONSTANTS.PROMOTIONS_END_REQUEST,
})

export const promotionSuccess = (data: any): IAction => ({
  type: CONSTANTS.PROMOTIONS_SUCCESS,
  payload: data,
})

export const promotionDetailsSuccess = (data: any): IAction => ({
  type: CONSTANTS.PROMOTIONS_DETAILS_SUCCESS,
  payload: data,
})


export const promotionFailure = (error: string): IAction => ({
  type: CONSTANTS.PROMOTIONS_FAILURE,
  error,
})

export const createPromotion = (promotion:any): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse> => {
  try {
    dispatch(promotionRequest())
    const response = await requestHttp.post(urls.createPromotion(),promotion)
    const { content } = response.data.data;
    dispatch(promotionEndRequest())
    return content;
  } catch (error) {
    dispatch(promotionFailure(getResponseErrorMessage(error)))
    return error
  }
}

export const editPromotion = (promotion:any): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse> => {
  try {
    dispatch(promotionRequest())
    const response = await requestHttp.put(urls.editPromotion(),promotion)
    const { content } = response.data.data;
    dispatch(promotionEndRequest())
    return content;
  } catch (error) {
    dispatch(promotionFailure(getResponseErrorMessage(error)))
    return error
  }
}

export const getAllPromotions = (queryParams:string): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse> => {
  try {
    dispatch(promotionRequest())
    const response = await requestHttp.get(urls.getAllPromotions(queryParams))
    const { content } = response.data.data;
    dispatch(promotionSuccess({data:content.results,rootTotal:content.total}))
    return content;
  } catch (error) {
    dispatch(promotionFailure(getResponseErrorMessage(error)))
    return error
  }
}

export const getPromotionDetails = (promotionId:number): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse> => {
  try {
    dispatch(promotionRequest())
    const response = await requestHttp.get(urls.getPromotionDetailsUrl(promotionId))
    const { content } = response.data.data;
    dispatch(promotionEndRequest())
    return content;
  } catch (error) {
    dispatch(promotionFailure(getResponseErrorMessage(error)))
    return error
  }
}

export const deletePromotion = (promotionId:number): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse> => {
  try {
    dispatch(promotionRequest())
    const response = await requestHttp.delete(urls.deletePromotionUrl(promotionId))
    const { content } = response.data.data;
    dispatch(promotionEndRequest())
    return content;
  } catch (error) {
    dispatch(promotionFailure(getResponseErrorMessage(error)))
    return error
  }
}

export const togglePromotionStatus = (id:number,isEnabled:boolean): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse> => {
  try {
    dispatch(promotionRequest())
    const response = await requestHttp.patch(urls.togglePromotionStatus(),{id,isEnabled})
    const { content } = response.data.data;
    dispatch(promotionEndRequest())
    return content;
  } catch (error) {
    dispatch(promotionFailure(getResponseErrorMessage(error)))
    return error
  }
}

export const upload = (userId:string,files:File[]): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse> => {
  try {
    dispatch(promotionRequest())
    const bodyFormData = new FormData()

    files.forEach((file: File) => {
      bodyFormData.append('files', file)
    })
    bodyFormData.append('isPublic', String(true))

    const config = {
      multipartFormData: true,
      skipDownloadProgress: true,
    }

    const uploadUrl = urls.getPublicProfileUploadUrl(userId)
    const response = await requestHttp.post<any>(uploadUrl, bodyFormData, config)
    dispatch(promotionEndRequest())
    return response
  } catch (error) {
    dispatch(promotionFailure(getResponseErrorMessage(error)))
    return error
  }
}


// export const upload = async (
//   userId: string,
//   files: File[],
// ) => {
//   try {
//     const bodyFormData = new FormData()

//     files.forEach((file: File) => {
//       bodyFormData.append('files', file)
//     })
//     bodyFormData.append('isPublic', String(true))

//     const config = {
//       multipartFormData: true,
//       skipDownloadProgress: true,
//     }

//     const uploadUrl = urls.getPublicProfileUploadUrl(userId)
//     const response = await requestHttp.post<any>(uploadUrl, bodyFormData, config)

//     return response
//   } catch (error) {
//     return error
//   }
// }
