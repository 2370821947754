import React, { useEffect, ReactElement, useState, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import { QButton, qnotification, QSelect, Row } from "quantum_components";
import { Select } from "antd";
import { debounce } from "lodash";

import { useReduxDispatch } from "src/helpers";
import { useSelector } from "react-redux";
import {
  getTotalUsersList,
  getUsersList,
  getUsersListLoading,
} from "src/modules/users/users.selectors";
import {
  IUserListElement,
  IUsersListParams,
} from "src/modules/users/users.types";
import { actions as userActions } from "src/modules/users";
import { ROLES, PATHS } from "src/constants";
import { BRIEF_TYPES, BRIEF_STEPS } from "src/constants/brief";
import SpinnerComponent from "src/components/Spinner/SpinnerComponent";
import { BriefCreateContainer, actions as briefActions } from "../brief.module";
// import { actions as testahelBriefActions } from '../../testahelBox/testahelBox.module'
import { getClientId, getBrief } from "../brief.selectors";
import { IOptionForSelect } from "../brief.types";
import { useTranslation } from "react-i18next";

const CLIENTS_LIMIT = 20;

export default function BriefWrapperContainer(): ReactElement {
  const { t } = useTranslation(["translationABrief","translationV2"]);
  const { Option } = Select;
  const dispatch = useReduxDispatch();
  const brief = useSelector(getBrief);
  const clients = useSelector(getUsersList) as IUserListElement[];
  const total = useSelector(getTotalUsersList);
  const loading = useSelector(getUsersListLoading);
  const selectedClientId = useSelector(getClientId);
  const history = useHistory();

  const [client, setClient] = useState(-1);
  const [isSku, setIsSku] = useState(0);

  const [params, setParams] = useState({
    offset: 0,
    limit: CLIENTS_LIMIT,
    sort: "",
    search: "",
    role: ROLES.CLIENT,
    infinite: true,
    isAdServerClient: true,
  });

  const hasMore = clients.length < total;

  useEffect(() => {
    dispatch(userActions.resetUsers());
    dispatch(userActions.fetchAdServerUsersList(params));
  }, [dispatch]);

  const handleOnSelect = async (value: number) => {
    setClient(value);

    await dispatch(briefActions.setClientId(value));

    // await handleSave();
  };

  const handleFocus = async () => {
    if (clients.length >= CLIENTS_LIMIT) return;

    const userList = { results: [], total: 0 };
    const defaultParams = {
      offset: 0,
      limit: CLIENTS_LIMIT,
      sort: "",
      search: "",
      role: ROLES.CLIENT,
      infinite: true,
      isAdServerClient: true,
    } as IUsersListParams;
    await dispatch(userActions.usersListSuccess(userList));
    await dispatch(userActions.fetchAdServerUsersList(defaultParams));
  };

  const handleSearch = debounce((search: string) => {
    const params = search
      ? ({
          search,
          offset: 0,
          limit: 100,
          role: ROLES.CLIENT,
          infinite: false,
          isAdServerClient: true,
        } as IUsersListParams)
      : ({
          offset: 0,
          limit: CLIENTS_LIMIT,
          sort: "",
          search: "",
          role: ROLES.CLIENT,
          infinite: true,
          isAdServerClient: true,
        } as IUsersListParams);
    dispatch(userActions.fetchAdServerUsersList(params));
  }, 300);

  const handleScroll = async (e: any) => {
    if (!hasMore) return;
    const { target } = e;

    if (
      !loading &&
      target.scrollTop + target.offsetHeight === target.scrollHeight
    ) {
      target.scrollTo(0, target.scrollHeight);
      const newParams = { ...params, offset: params.offset + CLIENTS_LIMIT };

      await dispatch(userActions.fetchAdServerUsersList(newParams));
      setParams(newParams);
    }
  };

  const clientOptions = clients.map((client) => ({
    value: client.id,
    label: `${client.fullname} - ${client.companyName}`,
  }));

  const handleSelectCampaignType = (campaignType: any) => {
    setIsSku(campaignType);
  };

  const handleSave = async () => {
    if(client == -1){
      qnotification({message:t("Please select a client first"),type:"error"}); 
      return;
    }

    // support only v2 campaigns now
    await dispatch(briefActions.createBriefV3());

    // if (isSku == 0) {
    //   let updatedBrief = { ...brief };
    //   updatedBrief[BRIEF_STEPS.TYPE].briefTypes = [BRIEF_TYPES.MEDIA_BUYING];
    //   await dispatch(briefActions.createBrief(updatedBrief));
    // } else {
    //   await dispatch(briefActions.createBriefV3());
    // }
  };

  return (
    <div className="admin_brief_create" style={{ width: "75vw" }}>
      <h2 className="pb-10">{t("admin_create_campaign")}</h2>
      <Row align="middle">
        <h5>{t("select_a_client")}</h5>
      </Row>
      <Row className="mb-10" align="middle">
        <QSelect
          placeholder={t("please_select_a_client")}
          onFocus={handleFocus}
          onPopupScroll={handleScroll}
          onChange={handleOnSelect}
          size="large"
          style={{ width: 400 }}
          optionFilterProp="children"
          onSearch={handleSearch}
          showSearch
        >
          {clientOptions.map((client: IOptionForSelect) => (
            <Option key={client.value} value={client.value}>
              {client.label}
            </Option>
          ))}
          {loading && (
            <Option
              kye={"Spinner"}
              value={"spinner"}
              style={{ textAlign: "center" }}
            >
              <SpinnerComponent />
            </Option>
          )}
        </QSelect>
      </Row>

      {/* <Row align="middle">
        <h5>{t("Select Campaign Type",{ns:'translationV2'})}</h5>
      </Row>
      <Row>
        <QSelect
          placeholder={t("Campaign Type")}
          value={isSku}
          onChange={handleSelectCampaignType}
          size="large"
          style={{ width: 400 }}
          options={[
            { label: t("Media Buying",{ns:"translationV2"}), value: 0 },
            { label: t("SKU Uplifting",{ns:"translationV2"}), value: 1 },
          ]}
        ></QSelect>
      </Row> */}
      <QButton
        type="primary"
        htmlType="button"
        size="small"
        onClick={handleSave}
      >
        {t("Create Campaign",{ns:"translationV2"})}
      </QButton>
      {/*{!!selectedClientId && (
        <div className="brief-container-wrapper">
          <BriefCreateContainer />
        </div>
      )}*/}
    </div>
  );
}
