import { IState } from './servicesV2.types'
import * as CONSTANTS from './servicesV2.constants'

const initialState: IState = {
  privacyPolicy: null,
  privacyPolicyActionLoading: false,
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case CONSTANTS.RESET_PRIVACY_POLICY:
      return initialState
    case CONSTANTS.FETCH_PRIVACY_POLICY_REQUEST:
      return {
        ...state,
        privacyPolicyActionLoading: true,
      }
    case CONSTANTS.FETCH_PRIVACY_POLICY_SUCCESS:
      return {
        ...state,
        privacyPolicy: action.payload,
        privacyPolicyActionLoading: false,
      }
    case CONSTANTS.FETCH_PRIVACY_POLICY_FAILURE:
      return {
        ...state,
        privacyPolicyActionLoading: false,
      }
    default:
      return state
  }
}
