import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { AxiosResponse } from 'axios'
import IAction, { TName } from 'src/interfaces/IAction'

import * as CONSTANTS from './nativeTemplates.constants'
import { requestHttp, urls } from 'src/api'
import { getResponseErrorMessage } from 'src/helpers'

export const nativeTemplateRequest = (): IAction => ({
  type: CONSTANTS.NATIVE_TEMPLATES_REQUEST,
})

export const nativeTemplateEndRequest = (): IAction => ({
  type: CONSTANTS.NATIVE_TEMPLATES_END_REQUEST,
})

export const nativeTemplateSuccess = (data: any): IAction => ({
  type: CONSTANTS.NATIVE_TEMPLATES_SUCCESS,
  payload: data,
})

export const targetingKeyDetailsSuccess = (data: any): IAction => ({
  type: CONSTANTS.NATIVE_TEMPLATES_DETAILS_SUCCESS,
  payload: data,
})

export const nativeTemplateFailure = (error: string): IAction => ({
  type: CONSTANTS.NATIVE_TEMPLATES_FAILURE,
  error,
})

export const createNativeTemplate = (nativeTemplate:any): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse> => {
  try {
    dispatch(nativeTemplateRequest())
    const response = await requestHttp.post(urls.getCreateNativeTemplateUrl(),nativeTemplate)
    const { content } = response.data.data;
    dispatch(nativeTemplateEndRequest())
    return content;
  } catch (error) {
    dispatch(nativeTemplateFailure(getResponseErrorMessage(error)))
    return error
  }
}

export const editNativeTemplate = (nativeTemplate:any): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse> => {
  try {
    dispatch(nativeTemplateRequest())
    const response = await requestHttp.put(urls.getEditNativeTemplateUrl(),nativeTemplate)
    const { content } = response.data.data;
    dispatch(nativeTemplateEndRequest())
    return content;
  } catch (error) {
    dispatch(nativeTemplateFailure(getResponseErrorMessage(error)))
    return error
  }
}

export const getAllNativeTemplates = (): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse> => {
  try {
    dispatch(nativeTemplateRequest())
    const response = await requestHttp.get(urls.getNativeTemplatesListUrl()+'?offset=0&limit=500&sortField=id&direction=desc')
    const { content } = response.data.data;
    dispatch(nativeTemplateSuccess({data:content.results,rootTotal:content.total}))
    return content;
  } catch (error) {
    dispatch(nativeTemplateFailure(getResponseErrorMessage(error)))
    return error
  }
}

export const getAllNativeTemplatesAsync = async() => {
  try {
    const response = await requestHttp.get(urls.getNativeTemplatesListUrl()+'?offset=0&limit=500&sortField=id&direction=desc')
    const { content } = response.data.data;
    return content;
  } catch (error) {
    return error
  }
}

// export const getTargetingKeyDetails = (targetingKeyId:number): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (
//   dispatch: ThunkDispatch<{}, {}, AnyAction>
// ): Promise<AxiosResponse> => {
//   try {
//     dispatch(nativeTemplateRequest())
//     const response = await requestHttp.get(urls.getTargetingKeyDetailsUrl(targetingKeyId))
//     const { content } = response.data.data;
//     dispatch(nativeTemplateEndRequest())
//     return content;
//   } catch (error) {
//     dispatch(nativeTemplateFailure(getResponseErrorMessage(error)))
//     return error
//   }
// }

export const deleteNativeTemplate = (nativeTemplateId:number): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse> => {
  try {
    dispatch(nativeTemplateRequest())
    const response = await requestHttp.delete(urls.getDeleteNativeTemplateUrl(nativeTemplateId))
    const { content } = response.data.data;
    dispatch(nativeTemplateEndRequest())
    return content;
  } catch (error) {
    dispatch(nativeTemplateFailure(getResponseErrorMessage(error)))
    return error
  }
}

// export const toggleTargetingKeyStatus = (id:number,isEnabled:boolean): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (
//   dispatch: ThunkDispatch<{}, {}, AnyAction>
// ): Promise<AxiosResponse> => {
//   try {
//     dispatch(nativeTemplateRequest())
//     const response = await requestHttp.patch(urls.toggleTargetingKeyStatus(),{id,isEnabled})
//     const { content } = response.data.data;
//     dispatch(nativeTemplateEndRequest())
//     return content;
//   } catch (error) {
//     dispatch(nativeTemplateFailure(getResponseErrorMessage(error)))
//     return error
//   }
// }


