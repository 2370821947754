export const MODULE_NAME = 'subscriptionHistory'

/* actions */

export const RESET_SUBSCRIPTION_HISTORY_STATE = `${MODULE_NAME}/RESET_SUBSCRIPTION_HISTORY_STATE`

export const FETCH_SUBSCRIPTION_HISTORY_REQUEST = `${MODULE_NAME}/FETCH_SUBSCRIPTION_HISTORY_REQUEST`
export const FETCH_SUBSCRIPTION_HISTORY_SUCCESS = `${MODULE_NAME}/FETCH_SUBSCRIPTION_HISTORY_SUCCESS`
export const FETCH_SUBSCRIPTION_HISTORY_FAILURE = `${MODULE_NAME}/FETCH_SUBSCRIPTION_HISTORY_FAILURE`

export const CANCEL_SUBSCRIPTION_REQUEST = `${MODULE_NAME}/CANCEL_SUBSCRIPTION_REQUEST`
export const CANCEL_SUBSCRIPTION_SUCCESS = `${MODULE_NAME}/CANCEL_SUBSCRIPTION_SUCCESS`
export const CANCEL_SUBSCRIPTION_FAILURE = `${MODULE_NAME}/CANCEL_SUBSCRIPTION_FAILURE`

export const SET_CURRENT_PAGE = `${MODULE_NAME}/SET_CURRENT_PAGE`
