import React, { ReactElement } from 'react'
import { UpdatePasswordForm } from 'quantum_components'
import { useReduxDispatch } from '../../../../helpers'
import { actions } from '../updatePassword.module'
import { useHistory } from 'react-router-dom'
import { PATHS } from 'src/constants'

export default function UpdatePasswordContainer(): ReactElement {
  const dispatch = useReduxDispatch()
  const history = useHistory()

  const submit = async ({ old, _new, repeat }: any) => {
    const { status } = await dispatch(
      actions.updatePassword({ currentPassword: old, newPassword: _new, confirmNewPassword: repeat })
    )
    if (status === 200) history.push(PATHS.ROOT)
  }
  return <UpdatePasswordForm onSubmit={submit} />
}
