import React from 'react'
import { QSelect, Row, Col, Radio, QRadio } from 'quantum_components'
import {
  TARGET_TYPE,
  TARGET_AUDIENCE_VALUES as VALUES,
  TARGET_AUDIENCE_INITIAL_VALUES,
} from 'src/constants/clientProfile'

import { MediaTypeCard } from '../components/MediaTypeCard'
import { useSelector } from 'react-redux'
import { useReduxDispatch } from '../../../../../helpers'
import { actions } from '../../clientProfile.module'

import { getSettings, getFilledData } from '../../profile.selectors'
import { createObj } from '../../../../../helpers/createObjectWithOldFields'
import { first } from 'lodash'
import { AgeGroupCard } from 'src/modules/brief/containers/components/Brief.AgeGroupCard'
import { useTranslation } from 'react-i18next'
import i18n from 'src/i18n'
import { LOCALIZATION_LANGUAGES } from 'src/constants'

const genders = ['Male', 'Female', 'Both']

const getOptions = (options: string[]) =>
  options.map(el => ({
    label: el,
    value: el,
  }))

export function ClientsTargetBody() {
  const { t } = useTranslation(['translationClient', 'translationProfile'])
  const dispatch = useReduxDispatch()
  const { segments, ageGroups } = useSelector(getSettings) as any
  const filledData = useSelector(getFilledData)

  const stepData = createObj(TARGET_AUDIENCE_INITIAL_VALUES, filledData)

  const changeStepValue = (field: string, value: any) =>
    dispatch(
      actions.setStepData({
        ...stepData,
        [field]: value,
      })
    )

  const onChangeSelectHandler = (field: string) => (value: any) => changeStepValue(field, value)

  const handleMediaType = (e: MouseEvent, name: string) => {
    const items = stepData[VALUES[TARGET_TYPE.AGE_GROUPS]]
    items.includes(name) ? items.splice(items.indexOf(name), 1) : items.push(name)

    changeStepValue(VALUES[TARGET_TYPE.AGE_GROUPS], items)
  }

  const getAgeGroups = () => {
    const reducer = (s: any) => {
      const value = s.from + (s.to ? '-' + s.to + ' ' + s.name : '+ ' + s.name)
      const sublabel = s.from + (s.to ? '-' + s.to : '+')
      const label = i18n.language === LOCALIZATION_LANGUAGES.ARABIC ? s.name_ar : s.name
      const img = s.src

      return { label, sublabel, value, img } as any
    }

    return ageGroups.map((group: any) => reducer(group))
  }

  return (
    <div className={`form form-brief form--horizontal mb-20`}>
      <div className="form__head">
        <h3>{t(`clients.profile.target_audience.header`)}</h3>
      </div>
      <div className="form__cont">
        <Row gutter={16} align="middle">
          <Col xs={24} sm={24} md={10}>
            <label htmlFor="distributeQuantity" className="form-label">
              {t(`clients.profile.target_audience.gender_focus`)}
            </label>
          </Col>
          <Col xs={24} sm={24} md={14}>
            <Radio.Group
              name={VALUES[TARGET_TYPE.GENDER_FOCUS]}
              value={first(stepData[VALUES[TARGET_TYPE.GENDER_FOCUS]])}
              onChange={e => changeStepValue(VALUES[TARGET_TYPE.GENDER_FOCUS], [e.target.value])}
            >
              {genders.map((gender: string) => (
                <QRadio key={gender} value={gender}>
                  {t(`profile.partner.customer_and_locale.${gender.toLowerCase()}`, gender, { ns: 'translationProfile' })}
                </QRadio>
              ))}
            </Radio.Group>
          </Col>
        </Row>
        <hr className="form__hr" />
        <Row gutter={16}>
          <Col xs={24} sm={24} md={10}>
            <label htmlFor="ageGroups" className="form-label">
              {t(`clients.profile.target_audience.age_groups`)}
            </label>
          </Col>
          <Col xs={24} sm={24} md={14}>
            <Row gutter={16} justify="center">
              {getAgeGroups().map((group: any) => {
                const isChecked = stepData[VALUES[TARGET_TYPE.AGE_GROUPS]].includes(group.value)
                // return <AgeGroupCard key={name.name} checked={isChecked} name={name} onChange={handleMediaType} dataType="ageGroups" />
                return (
                  <AgeGroupCard
                    key={group.value}
                    checked={isChecked}
                    label={group.label}
                    sublabel={group.sublabel}
                    img={group.img}
                    onChange={(e: any) => handleMediaType(e, group.value)}
                  />
                )
              })}
            </Row>
          </Col>
        </Row>
        {/* <hr className="form__hr" />
        <Row gutter={16} align="middle">
          <Col xs={24} sm={24} md={10}>
            <label htmlFor="targetedSegments" className="form-label">
              {t(`clients.profile.target_audience.segments`)}
            </label>
          </Col>
          <Col xs={24} sm={24} md={14}>
            <QSelect
              showArrow
              maxTagTextLength={8}
              maxTagCount={2}
              value={stepData[VALUES[TARGET_TYPE.TARGETED_SEGMENTS]]}
              onChange={onChangeSelectHandler(VALUES[TARGET_TYPE.TARGETED_SEGMENTS])}
              mode="multiple"
              size="large"
              placeholder={t(`clients.profile.target_audience.select_type_of_the_product`)}
              options={getOptions(segments)}
              className="full-width"
            />
          </Col>
        </Row> */}
      </div>
    </div>
  )
}
