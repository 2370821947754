import { createSelector } from 'reselect'
import { MODULE_NAME } from './ageGroups.constants'
import { IAgeGroup } from './ageGroups.types'

const selectState = (state: { [MODULE_NAME]: any }) => state[MODULE_NAME]

export const getIsAgeGroupsListLoading = createSelector(selectState, (state): boolean => state.ageGroupsListLoading)

export const getAgeGroupsList = createSelector(selectState, state =>
  state.ageGroupsList.map((group: IAgeGroup) => {
    return {
      ...group,
      key: group.name,
    }
  })
)

export const getUploadedImage = createSelector(selectState, state => state.ageGroupImage)

export const getIsImageUploading = createSelector(selectState, (state): boolean => state.imageUploading)

export const getAgeGroupsUpdating = createSelector(selectState, (state): boolean => state.ageGroupsUpdating)
