import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { AxiosResponse } from 'axios'
import IAction from 'src/interfaces/IAction'

import * as CONSTANTS from './wallet.constants'
import { requestHttp, urls } from 'src/api'
import { getResponseErrorMessage } from 'src/helpers'
import { IPayout, IPayoutsListParams, IWallet, IWalletParams } from './wallet.types'
import { upload } from '../core/core.actions'
import { UPLOAD_ENTITY_KEYS } from 'src/constants/upload'
import { PAYOUT_UPLOAD_TYPES } from 'src/constants/payouts'

export const resetWalletState = (): IAction => ({
    type: CONSTANTS.RESET_WALLET_STATE,
})

export const walletListRequest = (): IAction => ({
    type: CONSTANTS.FETCH_WALLET_REQUEST,
})

export const walletListSuccess = (walletDetails: IWallet): IAction => ({
    type: CONSTANTS.FETCH_WALLET_SUCCESS,
    payload: walletDetails,
})

export const walletListFailure = (error: string): IAction => ({
    type: CONSTANTS.FETCH_WALLET_FAILURE,
    error,
})

export const setCurrentPage = (page: number): IAction => ({
    type: CONSTANTS.SET_CURRENT_PAGE,
    payload: { page },
})

export const fetchWalletList = (params: IWalletParams): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse> => {
    try {
        dispatch(walletListRequest())
        const response = await requestHttp.get(urls.getWalletListUrl(), { params })
        const { content } = response.data.data
        dispatch(walletListSuccess(content))
        return response
    } catch (error: any) {
        dispatch(walletListFailure(getResponseErrorMessage(error)))
        return error
    }
}

export const setPayoutsCurrentPage = (page: number): IAction => ({
    type: CONSTANTS.SET_PAYOUTS_CURRENT_PAGE,
    payload: { page },
})

export const payoutsListRequest = (): IAction => ({
    type: CONSTANTS.FETCH_PAYOUTS_REQUEST,
})

export const payoutsListSuccess = (payouts: { results: IPayout[]; total: number }): IAction => ({
    type: CONSTANTS.FETCH_PAYOUTS_SUCCESS,
    payload: payouts,
})

export const payoutsListFailure = (error: string): IAction => ({
    type: CONSTANTS.FETCH_PAYOUTS_FAILURE,
    error,
})

export const fetchPayoutsList = (params: IPayoutsListParams): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<any> => {
    try {
        dispatch(payoutsListRequest())
        const response = await requestHttp.get(urls.getPayoutsListUrl(), { params })
        const { content } = response.data.data
        dispatch(payoutsListSuccess({
            results: content.payouts,
            total: content.total
        }))
        return response
    } catch (error: any) {
        dispatch(payoutsListFailure(getResponseErrorMessage(error)))
        return error
    }
}

export const payoutsLoadingRequest = (): IAction => ({
    type: CONSTANTS.PAYOUTS_LOADING_REQUEST,
})

export const payoutsLoadingSuccess = (): IAction => ({
    type: CONSTANTS.PAYOUTS_LOADING_SUCCESS,
})

export const uploadPayoutInvoice = (id: number, files: File[]): ThunkAction<void, {}, {}, AnyAction> => async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<any> => {
    try {
        if (!id) { return }
        dispatch(payoutsLoadingRequest())

        const uploadUrl = urls.uploadPayoutInvoiceUrl()
        const response = await upload(uploadUrl, files, id, PAYOUT_UPLOAD_TYPES.PAYOUT_INVOICE, UPLOAD_ENTITY_KEYS.PAYOUT_ID)

        dispatch(payoutsLoadingSuccess())
        return response
    } catch (error) {
        dispatch(payoutsLoadingSuccess())
    }
}

export const validatePartnerRequiredFields =
  (): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
      try {
        const response = await requestHttp.get(urls.getValidatePartnerRequiredFields())
        const { content } = response.data.data
        return content
      } catch (error) {
        console.log(error)
      }
    }
