import BrandStoreBanner from '../assets/icons/banner-types/brand-store.svg'
import CashbackOffersBanner from '../assets/icons/banner-types/cashback-offer.svg'
import FreeDeliveryBanner from '../assets/icons/banner-types/free-delivery.svg'
import HomePageBannersBanner from '../assets/icons/banner-types/home-page-banners.svg'
import PopupWindowBanner from '../assets/icons/banner-types/popup-window.svg'
import SurveyBanner from '../assets/icons/banner-types/survey.svg'
import PriceOffPromoBanner from '../assets/icons/banner-types/price-off-promotion.svg'
import PushNotificationsBanner from '../assets/icons/banner-types/push-notifications.svg'
import FeedBanner from '../assets/icons/banner-types/feed.svg'
import SkuUpliftingBanner from '../assets/icons/banner-types/sku-uplifting.svg'
import SocialMediaBanner from '../assets/icons/banner-types/social-media-ads.svg'

export enum BRIEF_STEPS {
  TYPE = 'BRIEF_TYPE',
  CUSTOMIZED = 'BRIEF_CUSTOMIZED_BOX',
  PRODUCT = 'BRIEF_PRODUCT',
  TARGETING = 'BRIEF_TARGETING',
  SERVICES = 'BRIEF_SERVICES',
  MEDIA_BUY = 'BRIEF_MEDIA_BUY',
  CONSUMER_RESEARCH = 'BRIEF_CONSUMER_RESEARCH',
  PARTNER = 'BRIEF_PARTNER',
  UPLOAD = 'BRIEF_UPLOAD',
  SUMMARY = 'SUMMARY',
}

export const CREATE_BRIEF_STEPS: BRIEF_STEPS[] = [
  BRIEF_STEPS.TYPE,
  BRIEF_STEPS.CUSTOMIZED,
  BRIEF_STEPS.PRODUCT,
  BRIEF_STEPS.TARGETING,
  BRIEF_STEPS.SERVICES,
  BRIEF_STEPS.MEDIA_BUY,
  BRIEF_STEPS.CONSUMER_RESEARCH,
  BRIEF_STEPS.PARTNER,
  BRIEF_STEPS.UPLOAD,
]

export const REJECTED_BRIEF_STEPS: BRIEF_STEPS[] = [BRIEF_STEPS.PARTNER, BRIEF_STEPS.UPLOAD, BRIEF_STEPS.SUMMARY]

export const BRIEF_STEPS_NAMES: { [key: string]: string } = {
  [BRIEF_STEPS.TYPE]: 'Brief Type',
  [BRIEF_STEPS.CUSTOMIZED]: 'Customized Box',
  [BRIEF_STEPS.PRODUCT]: 'Product Brief',
  [BRIEF_STEPS.TARGETING]: 'Targeting',
  [BRIEF_STEPS.SERVICES]: 'Services',
  [BRIEF_STEPS.MEDIA_BUY]: 'Media Buy',
  [BRIEF_STEPS.CONSUMER_RESEARCH]: 'Consumer Research',
  [BRIEF_STEPS.PARTNER]: 'Partners',
  [BRIEF_STEPS.UPLOAD]: 'Upload',
  [BRIEF_STEPS.SUMMARY]: 'Review & Submit',
}

export enum BRIEF_STATUS {
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
  DRAFT = 'DRAFT',
  APPROVED = 'APPROVED',
  EXPIRED = 'EXPIRED',
  PARTIALLY_REJECTED = 'PARTIALLY_REJECTED',
  PARTIALLY_APPROVED = 'PARTIALLY_APPROVED',
  NO_RESPONSE = 'NO_RESPONSE',
  PENDING_APPROVAL = 'PENDING APPROVAL',
  PENDING_ACCOUNT_APPROVAL = 'PENDING_ACCOUNT_APPROVAL',
  SELECT_PARTNERS = 'SELECT_PARTNERS',
}

export const BRIEF_STATUS_NAMES: { [key: string]: string } = {
  [BRIEF_STATUS.DRAFT]: 'Draft',
  [BRIEF_STATUS.PENDING]: 'Pending',
  [BRIEF_STATUS.APPROVED]: 'Approved',
  [BRIEF_STATUS.REJECTED]: 'Rejected',
}

export const BRIEF_STATUS_LIST = [
  BRIEF_STATUS.DRAFT,
  BRIEF_STATUS.PENDING,
  BRIEF_STATUS.APPROVED,
  BRIEF_STATUS.REJECTED,
]

export const briefStatusesLabel = {
  [BRIEF_STATUS.PENDING]: {
    classStyle: 'pending',
    label: 'pending',
  },
  [BRIEF_STATUS.PENDING_ACCOUNT_APPROVAL]: {
    classStyle: 'partially running account_approval',
    label: 'pending account approval',
  },
  [BRIEF_STATUS.SELECT_PARTNERS]: {
    classStyle: 'partially running',
    label: 'select partners',
  },
  [BRIEF_STATUS.DRAFT]: {
    classStyle: 'running',
    label: 'draft',
  },
  [BRIEF_STATUS.APPROVED]: {
    classStyle: 'approved',
    label: 'approved',
  },
  [BRIEF_STATUS.PARTIALLY_APPROVED]: {
    classStyle: 'partially approved',
    label: 'partially approved',
  },
  [BRIEF_STATUS.REJECTED]: {
    classStyle: 'rejected',
    label: 'rejected',
  },
  [BRIEF_STATUS.PENDING_APPROVAL]: {
    classStyle: 'partially rejected',
    label: 'pending approval',
  },
  [BRIEF_STATUS.PARTIALLY_REJECTED]: {
    classStyle: 'partially rejected',
    label: 'partially rejected',
  },
  [BRIEF_STATUS.NO_RESPONSE]: {
    classStyle: 'rejected',
    label: 'no response',
  },
  [BRIEF_STATUS.EXPIRED]: {
    classStyle: 'completed',
    label: 'expired',
  },
}

export enum BRIEF_STEP_STATUS {
  CURRENT = 'current',
  PASSED = 'passed',
  DISABLED = 'disabled',
  DEFAULT = '',
}

export enum BRIEF_TYPES {
  PRODUCT_SAMPLE = 'PRODUCT_SAMPLE',
  CUSTOMIZED_TESTAHEL_BOX = 'CUSTOMIZED_TESTAHEL_BOX',
  MEDIA_BUYING = 'MEDIA_BUYING',
  CONSUMER_RESEARCH = 'CONSUMER_RESEARCH',
}

export enum GENDER {
  MALE = 'Male',
  FEMALE = 'Female',
  BOTH = 'Both',
}

export enum MARKETING_OBJECTIVES {
  BRAND_AWARENESS = 'Brand Awareness',
  REACH = 'Reach',
  LEAD_GENERATION = 'Lead Generation',
  VIDEO_VIEW = 'Video View',
  USER_ENGAGEMENT = 'User Engagement',
  BRAND_RECOGNITION = 'Brand Recognition',
  DRIVE_SALES = 'Drive Sales',
}

export enum MEDIA_TYPES {
  HOME_BANNERS = 'Banner',
  PRICE_OFF_PROMO = 'Price Off Promotion',
  POPUP_WINDOW = 'Popup Window',
  BRAND_STORE = 'Brand Store (Brand Bundle)',
  PUSH_NOTIFICATIONS = 'Push Notifications',
  FEED = 'Feed',
  SURVEY = 'Survey',
  CASHBACK = 'Cashback Offers',
  SOCIAL_MEDIA = 'Paid SM campaign',
  SKU = 'SKU Uplifting',
  FREE_DELIVERY = 'Free Delivery',
  DISTRIBUTION = 'Distribution',
}

export const BANNERS_BY_MEDIA_TYPE: { [key: string]: { image: any; description: string } } = {
  [MEDIA_TYPES.BRAND_STORE]: {
    image: BrandStoreBanner,
    description: '',
  },
  [MEDIA_TYPES.CASHBACK]: {
    image: CashbackOffersBanner,
    description: 'Offer cash back incentives to buyers of your product.',
  },
  [MEDIA_TYPES.FREE_DELIVERY]: {
    image: FreeDeliveryBanner,
    description: '',
  },
  [MEDIA_TYPES.HOME_BANNERS]: {
    image: HomePageBannersBanner,
    description: 'The largest and most prominent piece of real estate on a website.',
  },
  [MEDIA_TYPES.POPUP_WINDOW]: {
    image: PopupWindowBanner,
    description: 'An ad that appears either in a modal or popup window after visiting a page.',
  },
  [MEDIA_TYPES.SURVEY]: {
    image: SurveyBanner,
    description: 'Conduct research by allowing visitors to complete a brief survey.',
  },
  [MEDIA_TYPES.PRICE_OFF_PROMO]: {
    image: PriceOffPromoBanner,
    description: 'An ad that appears either in a modal or popup window after visiting a page.',
  },
  [MEDIA_TYPES.PUSH_NOTIFICATIONS]: {
    image: PushNotificationsBanner,
    description: 'Trigger a push notification with an ad either in an app or via SMS.',
  },
  [MEDIA_TYPES.FEED]: {
    image: FeedBanner,
    description: 'Advertise with video on a partner’s platform feed.',
  },
  [MEDIA_TYPES.SKU]: {
    image: SkuUpliftingBanner,
    description: 'Promote an individual product listing prominently on a website.',
  },
  [MEDIA_TYPES.SOCIAL_MEDIA]: {
    image: SocialMediaBanner,
    description: 'Advertise on a partner’s social media feed or channel.',
  },
}

export enum ADDITIONAL_STEPS {
  MEDIA_BUY_DIALOG = 'MEDIA_BUY_DIALOG',
  PRODUCT_SAMPLE_DIALOG = 'PRODUCT_SAMPLE_DIALOG',
  SUMMARY = 'SUMMARY',
  FINAL_SUMMARY = 'FINAL_SUMMARY',
  CONGRATS = 'CONGRATS',
}

export enum BRIEF_UPLOAD_TYPES {
  BRIEFS_CUSTOMIZED_IMAGES = 'briefs_customized_images',
  BRIEFS_PRODUCT_IMAGES = 'briefs_product_images',
  BRIEFS_SERVICE_IMAGES = 'briefs_service_images',
  BRIEFS_UPLOAD_IMAGES = 'briefs_upload_materials_images',
}

export enum BRIEF_SORT_TYPE {
  BEST_MATCH = 'Best Match',
  PRICE = 'Price',
  AUDIENCE = 'Audience/Customer Size',
}

export enum MEDIA_TYPES_DURATION {
  PER_SAMPLE = 'Per Sample',
  PER_INSERT = 'Per Insert',
  PER_DAY = 'Per Day',
  PER_WEEK = 'Per Week',
  PER_MONTH = 'Per Month',
  PER_PUSH = 'Per Push',
  PER_ACTIVITY = 'Per Activity',
}

export enum LOGISTIC_RATES {
  SMALLEST = 2500,
  BIGGEST = Number.MAX_SAFE_INTEGER,
}

export const MANAGEMENT_FEE_PERCENT = 7
export const TAXES_PERCENT = 15
export const DECIMAL_DIGIT = 4
export const PRODUCT_NAME_MIN = 3
export const PRODUCT_NAME_MAX = 50

export enum DISTRIBUTION_TYPE {
  PRODUCT_SAMPLE = 'Product Sample',
  PRINTED_INSERT = 'Printed Insert',
}

export enum MEDIA_GROUP_TYPE {
  MEDIA_BUY = 'Media Buy',
  PRODUCT_SAMPLE = 'Product Sample',
  PRINTED_INSERT = 'Printed Insert',
  CONSUMER_RESEARCH = 'Consumer Research',
  CUSTOMIZED_BOX = 'Customized Box',
}

export enum SERVICE_NAMES_FOR_TABLE {
  MEDIA = 'Media Buying',
  PRODUCT = 'Product Sample Distribution',
  INSERT = 'Printed Insert Distribution',
  EVENT = 'Event Sample Distribution',
  FOCUS = 'Consumer Research',
}

export enum DIALOG_MODALS {
  HAS_REJECTED_SERVICES = 'HAS_REJECTED_SERVICES',
  SAMPLE_ALLOCATED_MISMATCH = 'SAMPLE_ALLOCATED_MISMATCH',
  BUDGET_ALLOCATED_MISMATCH = 'BUDGET_ALLOCATED_MISMATCH',
  SAMPLE_BUDGET_ALLOCATED_MISMATCH = 'SAMPLE_BUDGET_ALLOCATED_MISMATCH',
  BLOCK_NEXT = 'BLOCK_NEXT',
  CANCEL = 'CANCEL',
  MEDIA_BUY = 'MEDIA_BUY',
  DISTRIBUTION = 'DISTRIBUTION',
  CRMEDIA = 'CRMEDIA',
}

export const MIN_LOGISTIC_PRICE_TEXT = 'Minimal Logistic Price'

export const KEEP_MODAL = 'KEEP_MODAL'

export enum PARTNER_STEP_BUTTONS {
  ADD_TO_CART = 'addToCart',
  NEXT = 'next',
  BACK = 'back',
}
export enum BRIEF_FORM_HEADER_TITLE {
  EDIT = 'Edit Consumer Research Brief',
  CREATE = 'Create Consumer Research Brief',
  REJECTION = 'Find New Partner',
}

export const BRIEF_PRINT_ID = 'brief_print_area'
export const MAIN_PRINT_ID = 'brief_main'

export const BRIEF_ADVERTISEMENT_LANGUAGES = {
  english: 'English',
  arabic: 'Arabic',
}

export enum COUNTRY_NAME {
  SAUDI_ARABIA = 'Saudi Arabia',
}

export enum CONSUMER_RESEARCH_MEDIA_TYPES {
  SURVEY = 'CR Survey',
  CHALLENGE = 'CR Challenge',
}
