import html2canvas from 'html2canvas'
import JSpdf from 'jspdf'
import { MAIN_PRINT_ID } from '../constants/brief'

const proposal1 = require('src/assets/ProposalImages/1.jpg')
const proposal2 = require('src/assets/ProposalImages/2.jpg')
const proposal3 = require('src/assets/ProposalImages/3.jpg')
const proposal4 = require('src/assets/ProposalImages/4.jpg')
const proposal5 = require('src/assets/ProposalImages/5.jpg')
const proposal6 = require('src/assets/ProposalImages/6.jpg')
const proposal7 = require('src/assets/ProposalImages/7.jpg')
const proposalEnd1 = require('src/assets/ProposalImages/end_1.jpg')
const proposalEnd2 = require('src/assets/ProposalImages/end_2.jpg')
const proposalEnd3 = require('src/assets/ProposalImages/end_3.jpg')
const proposalEnd4 = require('src/assets/ProposalImages/end_4.jpg')

const PROPOSAL_START_IMAGES: { [key: number]: any } = {
  1: proposal1,
  2: proposal2,
  3: proposal3,
  4: proposal4,
  5: proposal5,
  6: proposal6,
  7: proposal7,
}

const PROPOSAL_END_IMAGES: { [key: number]: any } = {
  1: proposalEnd1,
  2: proposalEnd2,
  3: proposalEnd3,
  4: proposalEnd4,
}

export const getScreenPageImage = (markup: HTMLElement) => {
  const options = {
    scrollX: 0,
    scrollY: -window.scrollY,
  }
  return html2canvas(markup, options).then((canvas: HTMLCanvasElement) => canvas.toDataURL('image/jpeg'))
}

export const saveScreenPageImage = (markup: HTMLElement) => {
  const saveAs = (uri: string, filename: string) => {
    let link = document.createElement('a')

    if (typeof link.download === 'string') {
      link.href = uri
      link.download = filename
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } else {
      window.open(uri)
    }
  }

  const options = {
    scrollX: 0,
    scrollY: -window.scrollY,
  }

  return html2canvas(markup, options).then((canvas: HTMLCanvasElement) => {
    const imageData = canvas.toDataURL('image/jpeg')
    saveAs(imageData, 'brief.jpg')
  })
}

export const getScreenPagePdf = (markup: HTMLElement) => {
  const options = {
    scrollX: 0,
    scrollY: -window.scrollY,
    useCORS: true,
    allowTaint: true,
    imageTimeout: 0,
  }

  return html2canvas(markup, options).then((canvas: HTMLCanvasElement) => {
    const imgData = canvas.toDataURL('image/jpeg')
    const imgWidth = 210
    const pageHeight = 295
    const imgHeight = (canvas.height * imgWidth) / canvas.width

    const pdf = new JSpdf()
    let heightLeft = imgHeight
    let position = 1
    pdf.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight)

    heightLeft -= pageHeight
    while (heightLeft >= 0) {
      position += heightLeft - imgHeight
      pdf.addPage()
      pdf.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight)
      heightLeft -= pageHeight
    }

    return pdf
  })
}

export const getBriefProposalPdf = (markup: HTMLElement) => {
  const options = {
    scrollX: 0,
    scrollY: -window.scrollY,
    useCORS: true,
    allowTaint: true,
    imageTimeout: 0,
  }

  return html2canvas(markup, options).then((canvas: HTMLCanvasElement) => {
    const imgData = canvas.toDataURL('image/jpeg')
    const imgWidth = 210
    const pageHeight = 295
    const imgHeight = (canvas.height * imgWidth) / canvas.width

    const pdf = new JSpdf()
    let heightLeft = imgHeight
    let position = 1

    for (let i = 0; i < 7; i++) {
      pdf.addImage(PROPOSAL_START_IMAGES[i + 1], 'JPEG', 0, 0, 210, 290)
      pdf.addPage()
    }

    pdf.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight)

    heightLeft -= pageHeight
    while (heightLeft >= 0) {
      position += heightLeft - imgHeight
      pdf.addPage()
      pdf.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight)
      heightLeft -= pageHeight
    }

    pdf.addPage()

    for (let i = 0; i < 4; i++) {
      pdf.addImage(PROPOSAL_END_IMAGES[i + 1], 'JPEG', 0, 0, 210, 290)
      if (i < 3) {
        pdf.addPage()
      }
    }

    return pdf
  })
}

//new
export const getBriefQuotationPdf = (markup: HTMLElement, lang: string, index?: number) => {
  let hideDivs = document.querySelectorAll('div[hidden]')
  if (index) {
    hideDivs.length > 0 && hideDivs[index].removeAttribute('hidden')
  } else {
    hideDivs.length > 0 && hideDivs[0].removeAttribute('hidden')
  }

  const options = {
    scrollX: 0,
    scrollY: -window.scrollY,
    useCORS: true,
    allowTaint: true,
    imageTimeout: 0,
    // quality: 4,
    scale: 1,
  }

  return html2canvas(markup, options).then((canvas: HTMLCanvasElement) => {

    const imgData = canvas.toDataURL('image/jpeg', 1.0)
    const imgWidth = 215
    const pageHeight = 295
    const imgHeight = (canvas.height * imgWidth) / canvas.width

    const pdf = new JSpdf("p", "mm", "a4")
    let heightLeft = imgHeight
    let position = 0

    // console.log(imgData)
    pdf.addImage(imgData, 'JPEG', -2, position, imgWidth, imgHeight)

    let page = 2

    heightLeft -= pageHeight
    while (heightLeft >= 0) {
      // if (page == 2) {
      //   pdf.addPage()
      //   pdf.addImage(lang === 'ar' ? proposalNew2ar : proposalNew2, 'JPEG', 0, 0, 210, 290)
      // } else {
      position = heightLeft - imgHeight
      pdf.addPage()
      pdf.addImage(imgData, 'JPEG', -2, position, imgWidth, imgHeight)
      heightLeft -= pageHeight
      // }
      // page++
    }

    // if (page == 5) {
    //   pdf.addPage()
    //   pdf.addImage(lang === 'ar' ? proposalNew5ar : proposalNew5, 'JPEG', 0, 0, 210, 290)
    // }

    if (index) {
      hideDivs.length > 0 && hideDivs[index].setAttribute('hidden', 'true')
    } else {
      hideDivs.length > 0 && hideDivs[0].setAttribute('hidden', 'true')
    }

    return pdf
  })
}

export const togglePrintClass = (value: boolean) => {
  const el = document.getElementById(MAIN_PRINT_ID)
  return value ? el?.classList.add('print_main') : el?.classList.remove('print_main')
}

export const toggleReportPrintClass = (value: boolean) => {
  const el = document.getElementById('new_brief_print_area')
  // const el = document.getElementById(MAIN_PRINT_ID)
  return value ? el?.classList.add('print_main') : el?.classList.remove('print_main')
}

export const getReportPdf = (markup: HTMLElement, lang: string) => {
  // let hideDivs = document.querySelectorAll('div[hidden]')
  // hideDivs[0].removeAttribute('hidden')

  const options = {
    scrollX: 0,
    scrollY: -window.scrollY,
    // scrollY: 0,
    useCORS: true,
    allowTaint: true,
    imageTimeout: 0,
    quality: 20,
    dpi: 300, // Set to 300 DPI
    scale: 3, // Adjusts your resolution
  }

  return html2canvas(markup, options).then((canvas: HTMLCanvasElement) => {
    const imgData = canvas.toDataURL('image/jpeg')
    const imgWidth = 210
    const pageHeight = 295
    const imgHeight = (canvas.height * imgWidth) / canvas.width

    const pdf = new JSpdf()
    let heightLeft = imgHeight
    let position = -1
    pdf.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight)

    heightLeft -= pageHeight

    while (heightLeft >= 22) {
      position = heightLeft - imgHeight
      pdf.addPage()
      pdf.addImage(imgData, 'JPEG', 0, position - 1, imgWidth, imgHeight - 18)
      heightLeft -= pageHeight
    }

    // while (heightLeft >= 0) {
    //   if (page == 2) {
    //     pdf.addPage()
    //     pdf.addImage(lang === 'ar' ? proposalNew2ar : proposalNew2, 'JPEG', 0, 0, 210, 290)
    //   } else {
    //     position = heightLeft - imgHeight
    //     pdf.addPage()
    //     pdf.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight)
    //     heightLeft -= pageHeight
    //   }
    //   page++
    // }

    // if (page == 5) {
    //   pdf.addPage()
    //   pdf.addImage(lang === 'ar' ? proposalNew5ar : proposalNew5, 'JPEG', 0, 0, 210, 290)
    // }

    // hideDivs[0].setAttribute('hidden', 'true')
    return pdf
  })
}
