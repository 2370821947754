import React, { ReactElement, useState } from 'react'
import { DIALOG_MODALS } from '../../../../../constants/brief'
import { QButton } from 'quantum_components'
import { getModalData } from '../../../../../helpers/request'
import DialogModal from '../../../../../components/DialogModal/DialogModal'
import { useTranslation } from 'react-i18next'

interface IProps {
  disableComplete: boolean
  handleComplete: () => void
  buttonText: string
}

export default function CompleteCampaignButton({ disableComplete, buttonText, handleComplete }: IProps): ReactElement {
  const [dialogModal, setDialogModal] = useState<DIALOG_MODALS | null>(null)
  const {t} = useTranslation(['translationABrief'])

  const handleDialogModal = (type: DIALOG_MODALS) => {
    setDialogModal(type)
  }

  const closeModal = () => {
    setDialogModal(null)
  }

  const modalData = getModalData(dialogModal)
  
  return (
    <>
      <QButton
        disabled={disableComplete}
        type={'primary'}
        onClick={() => handleDialogModal(DIALOG_MODALS.COMPLETE_CAMPAIGN)}
        className={'qu-button-outline-green full-width'}
      >
        {buttonText}
      </QButton>

      {dialogModal && (
        <DialogModal
          modalOpen={!!dialogModal}
          question={t(modalData.question)}
          description={t(modalData.description)}
          submitButtonText={t(modalData.submitButtonText)}
          cancelButtonText={t(modalData.cancelButtonText)}
          onClose={closeModal}
          onProceed={handleComplete}
          revert
        />
      )}
    </>
  )
}
