import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { AxiosResponse } from 'axios'

import IAction from 'src/interfaces/IAction'
import { requestHttp, urls } from 'src/api'
import { IRequestsListParams, IRequestsList } from './invitedClients.types'
import * as CONSTANTS from './invitedClients.constants'
import { getResponseErrorMessage } from 'src/helpers'

export const requestsListRequest = (): IAction => ({
  type: CONSTANTS.FETCH_REQUESTS_REQUEST,
})

export const requestsListSuccess = (requestsList: IRequestsList): IAction => ({
  type: CONSTANTS.FETCH_REQUESTS_SUCCESS,
  payload: requestsList,
})

export const requestsListFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_REQUESTS_FAILURE,
  error,
})

export const approveRequestRequest = (): IAction => ({
  type: CONSTANTS.APPROVE_REQUEST_REQUEST,
})

export const approveRequestSuccess = (requestId: number) => ({
  type: CONSTANTS.APPROVE_REQUEST_SUCCESS,
  payload: requestId,
})

export const approveRequestFailure = (error: string): IAction => ({
  type: CONSTANTS.APPROVE_REQUEST_FAILURE,
  error,
})

export const declineRequestRequest = (): IAction => ({
  type: CONSTANTS.DECLINE_REQUEST_REQUEST,
})

export const declineRequestSuccess = (requestId: number) => ({
  type: CONSTANTS.DECLINE_REQUEST_SUCCESS,
  payload: requestId,
})

export const declineRequestFailure = (error: string): IAction => ({
  type: CONSTANTS.DECLINE_REQUEST_FAILURE,
  error,
})

export const fetchRequestsList = (
  params: IRequestsListParams
): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse> => {
  try {
    dispatch(requestsListRequest())

    const response = await requestHttp.get(urls.getInvitedClientsRequestsUrl(), { params })

    const { content } = response.data.data

    dispatch(requestsListSuccess(content))
    return response
  } catch (error) {
    dispatch(requestsListFailure(getResponseErrorMessage(error)))
    return error
  }
}

export const approveRequest = (id: number): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse> => {
  try {
    dispatch(approveRequestRequest())

    const response = await requestHttp.patch(`${urls.getApproveRequestUrl(id)}`)
    dispatch(approveRequestSuccess(id))
    return response
  } catch (error) {
    dispatch(approveRequestFailure(getResponseErrorMessage(error)))
    return error
  }
}

export const declineRequest = (id: number): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse> => {
  try {
    dispatch(declineRequestRequest())

    const response = await requestHttp.patch(`${urls.getDeclineRequestUrl(id)}`)
    dispatch(declineRequestSuccess(id))
    return response
  } catch (error) {
    dispatch(declineRequestFailure(getResponseErrorMessage(error)))
    return error
  }
}
