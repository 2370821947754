import React, { ReactElement } from 'react'
import { Col, QSelectCard, Row, qnotification } from 'quantum_components'
import { BRIEF_TYPES } from '../../../../constants/brief'

import imageSample from '../../../../assets/choose_distribution@2x.png'
import imageMedia from '../../../../assets/choose_media@2x.png'

import imageResearch from '../../../../assets/choose_focus_group@2x.jpg'
import { NOTIFICATION_TYPES } from 'src/constants'
import { useParams } from 'react-router'

interface IProps {
  briefTypes: BRIEF_TYPES[]
  onChange: (briefTypes: BRIEF_TYPES[]) => void
}

export default function BriefTypeForm({ briefTypes, onChange }: IProps): ReactElement {
  const { id } = useParams<{id: any}>()

  const updateBrief = (type: BRIEF_TYPES) => {
    const newBriefTypes = [...briefTypes]
    newBriefTypes.includes(type) ? newBriefTypes.splice(briefTypes.indexOf(type), 1) : newBriefTypes.push(type)

    return newBriefTypes
  }

  const handleProductSample = () => {
    const changedBriefType = updateBrief(BRIEF_TYPES.PRODUCT_SAMPLE)

    if (changedBriefType.indexOf(BRIEF_TYPES.CONSUMER_RESEARCH) > -1) {
      changedBriefType.splice(changedBriefType.indexOf(BRIEF_TYPES.CONSUMER_RESEARCH), 1)
    }

    onChange(changedBriefType)
  }

  const handleMediaBuying = () => {
    const changedBriefType = updateBrief(BRIEF_TYPES.MEDIA_BUYING)

    if (changedBriefType.indexOf(BRIEF_TYPES.CONSUMER_RESEARCH) > -1) {
      changedBriefType.splice(changedBriefType.indexOf(BRIEF_TYPES.CONSUMER_RESEARCH), 1)
    }
    onChange(changedBriefType)
  }

  const handleConsumerResearch = () => {
    if (id) {
      qnotification({
        type: NOTIFICATION_TYPES.INFO,
        message:
          'Current brief type cannot be changed to Consumer Research. You can create a new brief for Consumer Research services.',
      })
      return
    }
    const changedBriefType = updateBrief(BRIEF_TYPES.CONSUMER_RESEARCH)

    if (changedBriefType.indexOf(BRIEF_TYPES.PRODUCT_SAMPLE) > -1) {
      changedBriefType.splice(changedBriefType.indexOf(BRIEF_TYPES.PRODUCT_SAMPLE), 1)
    }

    if (changedBriefType.indexOf(BRIEF_TYPES.MEDIA_BUYING) > -1) {
      changedBriefType.splice(changedBriefType.indexOf(BRIEF_TYPES.MEDIA_BUYING), 1)
    }

    onChange(changedBriefType)
  }

  const isExists = (type: BRIEF_TYPES) => {
    return briefTypes.includes(type)
  }

  return (
    <div className="qu-brief-content brief-step brief-step-0">
      <div className="brief-step-header">
        <h1>What services are you interested in?</h1>
        <p>
          You can select one or both options (Sample Distribution,
          <br />
          Media buying). Consumer Research is a standalone feature.
        </p>
      </div>
      <Row gutter={16} justify="center">
        <Col sm={24} md={8}>
          <QSelectCard
            checked={isExists(BRIEF_TYPES.PRODUCT_SAMPLE)}
            onChange={handleProductSample}
            title="Product Sample & Insert Distribution"
            text="Build brand awareness and loyalty with targeted samples and inserts."
            cardType="checkbox"
            inputName="card"
            cardImage={imageSample}
          />
        </Col>
        <Col sm={24} md={8}>
          <QSelectCard
            checked={isExists(BRIEF_TYPES.MEDIA_BUYING)}
            onChange={handleMediaBuying}
            title="Media Buying"
            text="Increase sales through display ads and ecommerce store placement."
            cardType="checkbox"
            inputName="card2"
            cardImage={imageMedia}
          />
        </Col>
        <Col sm={24} md={8}>
          <QSelectCard
            checked={isExists(BRIEF_TYPES.CONSUMER_RESEARCH)}
            onChange={handleConsumerResearch}
            title="Consumer Research"
            text="Connect your brand directly to consumers via interactive surveys and dedicated boxes."
            cardType="checkbox"
            inputName="card3"
            cardImage={imageResearch}
          />
        </Col>
      </Row>
    </div>
  )
}
