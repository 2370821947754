import { QButton, QInput, QSelect } from "quantum_components";
import React, { useEffect, useState } from "react";
import SpinnerCenter from "src/components/Spinner/spinner";
import { MEDIA_TYPES } from "src/constants/brief";
import { getServicesV2List } from "../servicesV2.actions";
import ServiceV2Create from "./ServiceV2Create";
import ServiceV2HeaderFilters from "./ServiceV2Filters";
import ServiceV2Header from "./ServiceV2Header";
import ServiceV2Table from "./ServiceV2Table";

const ServicesV2 = () => {
  const [isLoading,setIsLoading] = useState(false);
  const [showTypesModal, setShowTypesModal] = useState(false);
  const [servicesDetails, setServicesDetails] = useState([]);
  const [filteredServicesDetails, setFilteredServicesDetails] = useState([]);
  const [editService,setEditService] = useState({});

  const getServicesList = async() => {
    setIsLoading(true)
    const data = await getServicesV2List();
    if(data.results && data.results.length > 0){
      setServicesDetails(data.results)
      setFilteredServicesDetails(data.results)
    }
    setIsLoading(false)
  }

  useEffect(()=>{
    getServicesList()
  },[]);

  return (
    <div className="services-v2">
      {isLoading && <SpinnerCenter />}
      <ServiceV2Header />
      <ServiceV2HeaderFilters setShowTypesModal={setShowTypesModal} servicesDetails={servicesDetails} setFilteredServicesDetails={setFilteredServicesDetails} />
      <ServiceV2Table setIsLoading={setIsLoading} refreshParent={getServicesList} setEditService={setEditService} servicesDetails={filteredServicesDetails} />
      <ServiceV2Create
        setIsLoading={setIsLoading}
        showTypesModal={showTypesModal}
        setShowTypesModal={setShowTypesModal}
        refreshParent={getServicesList}
        editService={editService}
        setEditService={setEditService}
      />
    </div>
  );
};

export default ServicesV2;
