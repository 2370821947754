import React, { useMemo } from 'react'
import { Router, Route, Switch } from 'react-router-dom'
import { GuardProvider, GuardedRoute } from 'react-router-guards'
import getRoutes from './routes'
import NotFound from '../modules/core/containers/NotFound'
import requireLogin from './guards/requireLogin'
import history from '../utils/history'
import requirePermission from './guards/requirePermission'

const GLOBAL_GUARDS = [requireLogin, requirePermission]

interface Props {
  children: any
}

const AppRouter = ({ children }: Props) => {
  const routes = useMemo(() => getRoutes(), [])
  return (
    <Router history={history}>
      <GuardProvider guards={GLOBAL_GUARDS} loading="Loading..." error={NotFound}>
        <Route
          render={routeProps =>
            children(
              <Switch>
                {routes.map(({ component, error, exact, ignoreGlobal, loading, meta, path }, i) => (
                  <GuardedRoute
                    key={i}
                    component={component}
                    exact={exact}
                    error={error}
                    ignoreGlobal={ignoreGlobal}
                    loading={loading}
                    meta={meta}
                    path={path}
                  />
                ))}
              </Switch>,
              routeProps
            )
          }
        />
      </GuardProvider>
    </Router>
  )
}

export default AppRouter
