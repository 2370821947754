import React, { ReactElement, useEffect } from 'react'
import { BRIEF_STEPS } from '../../../../constants/brief'
import { Col, Row } from 'quantum_components'

import { useSelector } from 'react-redux'
import { getBriefView, getCart } from '../../brief.selectors'
import * as actions from '../../brief.actions'
import * as coreActions from "src/modules/core/core.actions";
import { useReduxDispatch } from '../../../../helpers'
import BriefEditSummaryComponent from 'src/components/BriefView/BriefEditSummary.component'
import { useTranslation } from 'react-i18next'

interface IProps {
  briefId: number
  setCurrentStep: (step: BRIEF_STEPS) => void
  onFinishLater: () => void
  rejection?: boolean
}

export default function FinalSummary({ briefId, setCurrentStep, onFinishLater, rejection }: IProps): ReactElement {
  const {t} = useTranslation(['translationABrief'])
  const dispatch = useReduxDispatch()
  const briefView = useSelector(getBriefView)
  const cart = useSelector(getCart)

  useEffect(() => {
    dispatch(actions.fetchViewBriefDetails(briefId))
    dispatch(actions.fetchCart(briefId))
    dispatch(coreActions.getUser())
  }, [briefId])

  const openPartner = async (id: number) => {
    setCurrentStep(BRIEF_STEPS.PARTNER)
    await dispatch(actions.fetchPartnerDetails(briefId, id))
    await dispatch(actions.setActiveSinglePartner(id))
  }

  return (
    <div className="qu-brief-content brief-step">
      <div className="brief-step-header">
        <h1>{t(`review_and_submit_brief`)}</h1>
        <p>
          {t(`review_submit_help`)}
          <a onClick={onFinishLater}> {t(`save_and_finish_this_later`)}</a>
        </p>
      </div>
      <Row gutter={16} align="middle" justify="center">
        <Col xs={24} sm={24}>
          <BriefEditSummaryComponent
            briefView={briefView}
            cart={cart}
            hidePaymentSection={true}
            setCurrentStep={setCurrentStep}
            hideProposalButtons={true}
            openPartner={openPartner}
            rejection={rejection}
          />
        </Col>
      </Row>
    </div>
  )
}
