import React, { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useReduxDispatch } from '../../../helpers'
import {
  getIsEmployeesListLoading,
  getEmployeesList,
  getEmployeesTotal,
  getPages,
  getEmployeesUpdating,
} from '../employees.selectors'
import { IEditModalState, IDeleteModalState, IEmployeeTable } from '../employees.types'
import useDebounce from '../../../hooks/useDebounce'
import * as actions from '../employees.actions'
import * as roleActions from '../../roles/roles.actions'

import { Tooltip } from 'antd'
import { Modal, Form, Row, Col, QButton, QTable, QInput, QSelect, QPagination } from 'quantum_components'
import { SearchOutlined, PlusOutlined, IdcardTwoTone, EditTwoTone, DeleteTwoTone } from '@ant-design/icons'
import { IRole, IRolesListParams } from '../../roles/roles.types'
import { IOptionForSelect } from '../../brief/brief.types'
import { getRolesList } from '../../roles/roles.selectors'
import { validateMessages } from '../../../helpers/validationMessage'
import { getCurrentUserPermissionActions } from '../../core/core.selectors'
import { hasPermission } from '../../../helpers/permission'
import { PERMISSIONS } from '../../../constants/permission'

const EmployeesList = () => {
  const { i18n } = useTranslation()
  const { t } = useTranslation(['translationEmployees', 'translationCommon'])
  const dispatch = useReduxDispatch()
  const employeesList = useSelector(getEmployeesList)
  const rolesList = useSelector(getRolesList)
  const employeesTotal = useSelector(getEmployeesTotal)
  const employeesListIsLoading = useSelector(getIsEmployeesListLoading)
  const pages = useSelector(getPages)
  const updatingEmployee = useSelector(getEmployeesUpdating)
  const permissions = useSelector(getCurrentUserPermissionActions)

  const [form] = Form.useForm()

  const [search, setSearch] = useState('')
  const initModalState = {
    visible: false,
    id: 0,
    firstName: '',
    lastName: '',
    fullName: '',
    email: '',
    jobTitle: '',
    roleId: null,
  }

  const [editModal, setEditModal] = useState<IEditModalState>({ ...initModalState })

  const [deleteModal, setDeleteModal] = useState<IDeleteModalState>({
    visible: false,
    id: 0,
    fullName: '',
  })

  const [params, setParams] = useState({
    currentPage: 1,
    limit: 10,
    offset: 0,
    direction: 'DESC',
    sortField: 'createdAt',
  })

  const debouncedSearch = useDebounce(search, 500)

  const fetchEmployees = (queryParams = params) => {
    dispatch(actions.fetchEmployeesList({ ...queryParams, search: debouncedSearch }))
  }

  const fetchRoles = () => {
    const params = {
      currentPage: 1,
      limit: 1000,
      offset: 0,
      direction: 'DESC',
      sortField: 'createdAt',
      search: '',
    } as IRolesListParams

    dispatch(roleActions.fetchRolesList(params))
  }

  useEffect(() => {
    if (hasPermission(permissions, PERMISSIONS.ROLES_AND_PERMISSIONS)) {
      fetchRoles()
    }
  }, [])

  useEffect(() => {
    fetchEmployees()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch])

  // @ts-ignore
  const handleTableChange = (pagination, filters, sorter) => {
    const sortingParams = {
      direction: sorter.order.slice(0, -3),
      sortField: sorter.columnKey,
    }
    const newParams = {
      ...params,
      ...sortingParams,
    }
    setParams(newParams)
    fetchEmployees(newParams)
  }

  const handleChangeSearch = (event: any) => {
    setSearch(event.currentTarget.value)
  }

  const handleChangePage = (page: number, pageSize: number) => {
    dispatch(actions.setCurrentPage(page))

    dispatch(
      actions.fetchEmployeesList({
        ...params,
        offset: (page - 1) * params.limit,
        currentPage: page,
        search: debouncedSearch,
        limit: pageSize,
      })
    )
  }

  const handleEditModalOpen = (employee?: IEmployeeTable) => {
    if (employee) {
      const employeeData = {
        id: employee.id,
        firstName: employee.firstName,
        lastName: employee.lastName,
        fullName: employee.fullName,
        email: employee.email,
        jobTitle: employee.jobTitle,
        roleId: employee.employeeRole && employee.employeeRole.id,
      }

      form.setFieldsValue(employeeData)
      setEditModal({ ...employeeData, visible: true })
    } else {
      form.resetFields()
      setEditModal({ ...editModal, visible: true })
    }
  }

  const handleEditModalClose = () => {
    setEditModal({ ...initModalState })
  }

  const handleOnValuesChange = (changedValue: any) => {
    form.setFieldsValue(changedValue)
  }

  const handleOnFinish = async (data: any) => {
    if (updatingEmployee) return

    if (editModal.id) {
      await dispatch(actions.editEmployee({ ...data, id: editModal.id }))
    } else {
      await dispatch(actions.createEmployee(data))
    }

    fetchEmployees(params)
    handleEditModalClose()
  }

  const onCheck = async () => {
    try {
      const values = await form.validateFields()
      handleOnFinish(values)
    } catch (error) {
      return error
    }
  }

  const handleDeleteClick = (employee: IEmployeeTable) => {
    setDeleteModal({
      visible: true,
      id: employee.id,
      fullName: employee.fullName,
    })
  }

  const handleDeleteModalClose = () => {
    setDeleteModal({ visible: false, id: 0, fullName: '' })
  }

  const handleDeleteEmployee = async () => {
    if (updatingEmployee) return
    await dispatch(actions.deleteEmployee(deleteModal.id))
    setDeleteModal({
      visible: false,
      id: 0,
      fullName: '',
    })

    fetchEmployees(params)
  }

  const renderModalTitle = () => {
    if (editModal.fullName) {
      return (
        <>
          {t('common.Update', {ns: 'translationCommon'})}
          <strong> {editModal.fullName} </strong>
        </>
      )
    }
    return t('manage.Add New Employee')
  }

  const initialFormData = {
    firstName: editModal.firstName,
    lastName: editModal.lastName,
    fullName: editModal.fullName,
    email: editModal.email,
    jobTitle: editModal.jobTitle,
    roleId: editModal.roleId,
  }

  const columns = [
    {
      title: t('manage.Full Name'),
      dataIndex: 'user',
      key: 'user',
      fixed: 'left',
      sorter: true,
      width: '30%',
      render: (text: string, record: IEmployeeTable) => {
        return <p className="mb-0">{record.fullName}</p>
      },
    },
    {
      title: t('manage.Email'),
      dataIndex: 'email',
      key: 'email',
      sorter: true,
      width: '25%',
    },
    {
      title: t('manage.Job Title'),
      dataIndex: 'jobTitle',
      key: 'jobTitle',
      sorter: true,
      width: '15%',
    },
    {
      title: t('manage.Role'),
      dataIndex: 'roleName',
      key: 'roleName',
      sorter: true,
      width: '12%',
    },
    {
      title: t('manage.Actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: '18%',
      render: (text: string, record: IEmployeeTable) => {
        return (
          <div>
            {record.employeeRole && hasPermission(permissions, PERMISSIONS.ROLES_AND_PERMISSIONS) && (
              <Tooltip title={t('manage.Edit Role')}>
                <Link to={`/roles/edit/${record.employeeRole.id}`} style={{ fontSize: '20px', marginRight: '20px' }}>
                  <IdcardTwoTone />
                </Link>
              </Tooltip>
            )}
            {hasPermission(permissions, PERMISSIONS.EMPLOYEE_UPDATE) && (
              <Tooltip title={t('manage.Edit Employee')}>
                <EditTwoTone
                  style={{ fontSize: '20px', marginRight: '20px' }}
                  onClick={() => handleEditModalOpen(record)}
                />
              </Tooltip>
            )}
            {hasPermission(permissions, PERMISSIONS.EMPLOYEE_DELETE) && (
              <Tooltip title={t('manage.Delete Employee')}>
                <DeleteTwoTone style={{ fontSize: '20px' }} onClick={() => handleDeleteClick(record)} />
              </Tooltip>
            )}
          </div>
        )
      },
    },
  ]

  const rolesOptions: IOptionForSelect[] = rolesList
    ? rolesList.map((role: IRole) => ({ label: role.name, value: role.id! }))
    : []

  return (
    <>
      <h2 className="pt-10 pb-10">{t('manage.Employees')}</h2>
      <Row justify="space-between">
        <Col className="qt-search">
          <QInput
            value={search}
            onChange={handleChangeSearch}
            placeholder={t('common.Search', {ns: 'translationCommon'})}
            size="large"
            prefix={<SearchOutlined />}
          />
        </Col>
        <Col>
          <Row align="middle" gutter={30}>
            <Col>
              <h5>{t('manage.Total available employees')}: {employeesTotal}</h5>
            </Col>
            <Col>
              <QButton
                type="primary"
                disabled={!hasPermission(permissions, PERMISSIONS.EMPLOYEE_CREATE)}
                onClick={() => handleEditModalOpen()}
              >
                {t('manage.Add new')}
                <PlusOutlined style={{ fontSize: '18px' }} />
              </QButton>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="pt-15 pb-30">
        <QTable
          columns={columns}
          dataSource={employeesList}
          loading={employeesListIsLoading}
          scroll={{ x: 990 }}
          // @ts-ignore
          onChange={handleTableChange}
          sortDirections={['ascend', 'descend', 'ascend']}
        />
      </div>
      <Row justify="end">
        <QPagination
          size="small"
          defaultCurrent={1}
          hideOnSinglePage
          current={pages.currentPage}
          total={employeesTotal}
          onChange={handleChangePage}
        />
      </Row>

      <Modal title={renderModalTitle()} visible={editModal.visible} 
        onOk={onCheck} 
        onCancel={handleEditModalClose}
        okText={t('common.OKAY', {ns: 'translationCommon'})}
        cancelText={t('common.Cancel', {ns: 'translationCommon'})}
      >
        <Form
          layout="vertical"
          hideRequiredMark
          form={form}
          initialValues={initialFormData}
          validateMessages={validateMessages}
          onFinish={onCheck}
          onValuesChange={handleOnValuesChange}
        >
          <Form.Item
            label={t('manage.First name')}
            name="firstName"
            rules={[{ required: true, whitespace: true }]}
            validateTrigger={['onChange', 'onBlur']}
          >
            <QInput size="large" />
          </Form.Item>
          <Form.Item
            label={t('manage.Last name')}
            name="lastName"
            rules={[{ required: true, whitespace: true }]}
            validateTrigger={['onChange', 'onBlur']}
          >
            <QInput size="large" />
          </Form.Item>
          <Form.Item
            label={t('manage.Email')}
            name="email"
            rules={[{ required: true, max: 255, type: 'email', whitespace: true }]}
            validateTrigger={['onChange', 'onBlur']}
          >
            <QInput disabled={!!editModal.id} type="email" size="large" />
          </Form.Item>
          <Form.Item
            label={t('manage.Job title')}
            name="jobTitle"
            rules={[{ required: true, whitespace: true }]}
            validateTrigger={['onChange', 'onBlur']}
          >
            <QInput size="large" />
          </Form.Item>
          <Form.Item 
            label={t('manage.Role')}
            name="roleId" rules={[{ required: true }]} validateTrigger={['onChange', 'onBlur']}>
            <QSelect
              showSearch
              size="large"
              placeholder={t('manage.Select role')}
              style={{ width: '100%' }}
              options={rolesOptions}
            />
          </Form.Item>
        </Form>
      </Modal>

      <Modal title="" visible={deleteModal.visible} 
        onOk={handleDeleteEmployee} 
        onCancel={handleDeleteModalClose}
        okText={t('common.OKAY', {ns: 'translationCommon'})}
        cancelText={t('common.Cancel', {ns: 'translationCommon'})}
      >
        <p>{t('manage.Do you want to delete', {employee: deleteModal.fullName})}
        </p>
      </Modal>
    </>
  )
}

export default EmployeesList
