export const MODULE_NAME = 'calendar'

/* actions */

export const FETCH_CALENDAR_REQUEST = `${MODULE_NAME}/FETCH_CALENDAR_REQUEST`
export const FETCH_CALENDAR_SUCCESS = `${MODULE_NAME}/FETCH_CALENDAR_SUCCESS`
export const FETCH_CALENDAR_FAILURE = `${MODULE_NAME}/FETCH_CALENDAR_FAILURE`

export const FETCH_CALENDAR_SERVICES_REQUEST = `${MODULE_NAME}/FETCH_CALENDAR_SERVICES_REQUEST`
export const FETCH_CALENDAR_SERVICES_SUCCESS = `${MODULE_NAME}/FETCH_CALENDAR_SERVICES_SUCCESS`
export const FETCH_CALENDAR_SERVICES_FAILURE = `${MODULE_NAME}/FETCH_CALENDAR_SERVICES_FAILURE`
