// @ts-nocheck

import { DECIMAL_DIGIT } from '../constants/brief'

export const getProperties = (name: string, properties: any) => {
  const props = {}

  if (!properties[name]) {
    return
  }

  Object.keys(properties[name]).forEach((key: string) => {
    props[key] = properties[name][key]
  })

  return props
}

export const getRatesProperties = (name: string, properties: any) => {
  const props = {}

  if (!properties[name]) {
    return
  }

  Object.keys(properties[name]).forEach((key: string) => {
    const value = properties[name][key] ? properties[name][key].toFixed(DECIMAL_DIGIT) : 0
    props[name + key] = value
  })

  return props
}
