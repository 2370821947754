import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Col,
  Row,
  Form,
  QButton,
  QInput,
  QTextArea,
  QSelect,
  QTable,
  QBackLink,
} from "quantum_components";
import { actions } from "../partnerProfile.module";
import {
  PARTNER_STEPS,
  PRICING_MODEL,
  PARTNER_CUSTOM_MEDIA_AVAILABLE_FIELDS,
  PARTNER_CUSTOM_MEDIA_SUB_TYPES,
} from "src/constants/profile";
import { useReduxDispatch } from "src/helpers";
import {
  getCurrentStep,
  getForm,
  getFormBoolean,
  getPartnerPartnerPercent,
  getProfileLoading,
  getAllCustomMediaServices,
} from "../profile.selectors";
import "../styles.scss";
import { isJSDocThisTag } from "typescript";
import { ICustomMediaType } from "../profile.types";
import { EditTwoTone, DeleteTwoTone } from "@ant-design/icons";
import DialogModal from "src/components/DialogModal/DialogModal";
import { getCurrentUser, getCurrentUserPermissionActions } from "src/modules/core/core.selectors";
import { checkPermission } from "src/utils";
import { PERMISSIONS } from "src/constants/permission";
import { hasPermission } from "src/helpers/permission";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const CustomServicePartnerContainer = () => {
  const {t} = useTranslation(['translationProfile'])
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [selectOptions, setSelectOptions] = useState([{}]);
  const [disabled, setDisabled] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const customMediaServices = useSelector(getAllCustomMediaServices);
  const [selectSubtypeOptions, setSelectSubtypeOptions] = useState([{}]);
  const iv: ICustomMediaType = {
    name: "",
    subtype: null,
    description: "",
    availableFields: ['DESCRIPTION','IMAGE_PREVIEW','WIDTH','HEIGHT','BANNER_NAME','PRICE_PER_DURATION','DURATION'],
  };
  const [activeServiceID,setActiveServiceID] = useState(-1)
  const permissions = useSelector(getCurrentUserPermissionActions)
  
  const hasCreatePerm = hasPermission(permissions,PERMISSIONS.CREATE_CUSTOM_MEDIA)
  const hasEditPerm = hasPermission(permissions,PERMISSIONS.UPDATE_CUSTOM_MEDIA)
  const hasDeletePerm = hasPermission(permissions,PERMISSIONS.DELETE_CUSTOM_MEDIA)

  useEffect(() => {
    dispatch(actions.fetchAllCustomMedia());
  }, []);

  useEffect(() => {
    setSelectOptions(
      PARTNER_CUSTOM_MEDIA_AVAILABLE_FIELDS.map((el: string) => {
        return { value: el, label: t(`profile.partner.custom_services.available-fields-options.${el}`,el)};
      })
    );
  }, [PARTNER_CUSTOM_MEDIA_AVAILABLE_FIELDS]);

  useEffect(() => {
    setSelectSubtypeOptions(
      PARTNER_CUSTOM_MEDIA_SUB_TYPES.map((el: string | null) => {
        if (!el) {
          return { value: el, label: t(`profile.public.service-type-options.None`)};
        } else {
          return { value: el, label: t(`profile.public.service-type-options.${el.replace(/_/g, " ")}`,el.replace(/_/g, " "))  };
        }
      })
    );
  }, [PARTNER_CUSTOM_MEDIA_SUB_TYPES]);

  const handleOnFinish = async (data: ICustomMediaType) => {
    if (isEditMode && activeServiceID == -1) return;

    setDisabled(true);

    if (isEditMode) {
      await dispatch(
        actions.updateCustomMediaType({ ...data, id: activeServiceID })
      );
      setActiveServiceID(-1)
      setIsEditMode(false);
    } else {
      await dispatch(actions.createCustomMediaType(data));
    }

    setShowForm(false);
    setDisabled(false);
    await dispatch(actions.fetchAllCustomMedia());
    cleanup();
  };

  const cleanup = () => {
    form.resetFields();
  };

  const toggleForm = () => {
    cleanup();
    if (showForm) setIsEditMode(false);
    setShowForm(!showForm);
  };

  const editService = (service: ICustomMediaType) => {
    setShowForm(true);
    setIsEditMode(true);
    setActiveServiceID(service.id||-1)
    form.setFieldsValue(service);
  };

  const deleteWrapper = async(id:number) => {
    setActiveServiceID(id)
    setShowDeleteModal(true)
  }

  const handleModalClose = () => {
    setShowDeleteModal(false)
    setActiveServiceID(-1)
  }

  const handleModalProceed = async () => {
    if(activeServiceID === -1) return
    await dispatch(actions.deleteCustomMediaType(activeServiceID))
    setShowDeleteModal(false);
    await dispatch(actions.fetchAllCustomMedia())
  }

  const columns = [
    {
      title: t(`profile.partner.custom_services.name`),
      dataIndex: "name",
      key: "name",
      width: "15%",
      fixed: "left",
    },
    {
      title: t(`profile.partner.custom_services.subtype`),
      dataIndex: "subtype",
      key: "subtype",
      width: "15%",
      fixed: "left",
      render: (text: string, record: ICustomMediaType) => {
        return text ? t(`profile.public.service-type-options.${text}`): "-";
      },
    },
    {
      title: t(`profile.partner.custom_services.description`),
      dataIndex: "description",
      key: "description",
      width: "20%",
      fixed: "left",
    },
    {
      title: t(`profile.partner.custom_services.selected_fields`),
      dataIndex: "availableFields",
      key: "availableFields",
      width: "40%",
      fixed: "left",
      render: (text: string, record: ICustomMediaType) => {
        return (
          <div>
            {record.availableFields.map((field) => {
              return (
                <span
                  className="customMediaServicesTablePills"
                  key={Math.random() * 1000}
                >
                  {t(`profile.partner.custom_services.available-fields-options.${field}`,field)}
                </span>
              );
            })}
          </div>
        );
      },
    },
    {
      title: t(`profile.partner.custom_services.actions`),
      dataIndex: "actions",
      key: "actions",
      width: "10%",
      fixed: "left",
      render: (text: string, record: ICustomMediaType) => {
        return (
          <div style={{textAlign:"center"}}>
            {hasEditPerm && <span>
              <EditTwoTone
                style={{ fontSize: "20px" }}
                onClick={() => editService(record)}
              ></EditTwoTone>
            </span>}
            {hasDeletePerm && <span>
              <DeleteTwoTone
                style={{ fontSize: "20px", marginLeft: "5px", marginRight:"5px" }}
                onClick={() => {
                  deleteWrapper(record.id||-1);
                }}
              ></DeleteTwoTone>
            </span>} 
          </div>
        );
      },
    },
  ];

  return (
    <>
      {!showForm &&
        <QBackLink
          title={t("Back")}
          onClick={() => history.push("/services")}
        />
      }
            
      <div className="flexRowContainer mt-20">
        <h2>
          {isEditMode
            ? t(`profile.partner.custom_services.edit_custom_service`)
            : showForm
            ? t(`profile.partner.custom_services.add_custom_service`)
            : t(`profile.partner.custom_services.custom_services`)}
        </h2>
        {!showForm && <QButton
          type="dashed"
          htmlType="button"
          className="qu-button-soft"
          onClick={toggleForm}
        >
          {t(`profile.partner.custom_services.create_new`)}
        </QButton>}
      </div>

      {showForm && (
        <div className="mt-20">
          <Form
            className="mt-10"
            layout="vertical"
            initialValues={iv}
            form={form}
            onFinish={handleOnFinish}
          >
            <div>
              <Row gutter={24} justify={"start"}>
                <Col xs={8} sm={8} md={8}>
                  <Form.Item
                    label={t(`profile.partner.custom_services.name`)}
                    name="name"
                    rules={[{ required: true }]}
                    validateTrigger={["onChange", "onBlur"]}
                  >
                    <QInput placeholder={t(`profile.partner.custom_services.enter_name`)} size="large" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} justify={"start"}>
                <Col xs={8} sm={8} md={8}>
                  <Form.Item
                    label={t(`profile.partner.custom_services.subtype`)}
                    name="subtype"
                    validateTrigger={["onChange", "onBlur"]}
                  >
                    <QSelect
                      size="large"
                      showArrow
                      placeholder={t(`profile.partner.custom_services.select_subtype`)}
                      options={selectSubtypeOptions}
                      // onChange={handleRoleSelect}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} justify={"start"}>
                <Col xs={8} sm={8} md={8}>
                  <Form.Item
                    label={t(`profile.partner.available_services.multi_form.description`)}
                    name="description"
                    rules={[{ required: true },{max:500,message:t('profile.Cannot exceed more than 500 characters')}]}
                    validateTrigger={["onChange", "onBlur"]}
                  >
                    <QTextArea
                      className="qu-textarea form-textarea"
                      cols="3"
                      rows="3"
                      placeholder={t(`profile.partner.available_services.multi_form.enter_a_description`)}
                      size="large"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} justify={"start"}>
                <Col xs={8} sm={8} md={8}>
                  <Form.Item
                    label={t(`profile.partner.custom_services.available_fields`)}
                    name="availableFields"
                    rules={[{ required: true }]}
                    validateTrigger={["onChange", "onBlur"]}
                  >
                    <QSelect
                      size="large"
                      showArrow
                      mode="multiple"
                      placeholder={t(`profile.partner.custom_services.select_available_fields`)}
                      maxTagCount={3}
                      maxTagTextLength={8}
                      options={selectOptions}
                      // onChange={handleRoleSelect}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} justify={"start"}>
                <Col xs={8} sm={8} md={8}>
                  <QButton
                    className="text-capitalize"
                    type="primary"
                    htmlType="submit"
                    disabled={disabled}
                  >
                    {isEditMode ? t(`profile.partner.custom_services.update`) : t(`profile.partner.custom_services.save`)}
                  </QButton>&nbsp;&nbsp;&nbsp;
                  <QButton
                    type="dashed"
                    htmlType="button"
                    className="qu-button-soft"
                    onClick={toggleForm}
                  >
                    {t(`profile.partner.custom_services.cancel`)}
                  </QButton>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
      )}

      <hr className="form__hr" />

      <div className="pt-15 pb-20">
        {!showForm && (
          <QTable columns={columns} dataSource={customMediaServices} />
        )}
      </div>

      <DialogModal
        modalOpen={showDeleteModal}
        question={"Are you sure?"}
        description={t(`profile.partner.custom_services.delete_custom_service`)}
        submitButtonText={t(`profile.partner.custom_services.cancel`)}
        cancelButtonText={t(`profile.partner.custom_services.delete`)}
        onClose={handleModalClose}
        onProceed={handleModalProceed}
        revert
      />
    </>
  );
};

export default CustomServicePartnerContainer;
