export enum TABS_NAMES {
  BRIEF = 'BRIEF',
  REQUESTS = 'REQUESTS',
  SURVEY = 'SURVEY',
  GALLERY = 'GALLERY',
  DISTRIBUTION = 'DISTRIBUTION',
  CLIENT_CHART = 'CLIENT_CHART',
  MEDIA_BUY = 'MEDIA_BUY',
  PARTNERS = 'PARTNERS',
  CLIENTS = 'CLIENTS',
  ONLINE_PAYMENTS = 'ONLINE_PAYMENTS',
  WALLET = 'WALLET',
  PAYOUTS = 'PAYOUTS',
}

export enum PARTNER_TABS {
  PARTNER_PROFILE = 'PARTNER_PROFILE',
  PUBLIC_PROFILE = 'PUBLIC_PROFILE',
  BILLINGS = 'BILLINGS',
}
