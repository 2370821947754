import React from 'react'
import { Collapse } from 'quantum_components'

import CampaignEditDistForm from './CampaignEditDist.form'
import CampaignEditPropsForm from './CampaignEditProps.form'
import CampaignEditTimeline from './CampaignEditTimeline'

interface IProps {
  disabledButton: boolean
}
const CampaignEditDistribution = ({ disabledButton }: IProps) => {
  return (
    <div className="full-width">
      <div className="qu-tree">
        <ul className="qu-tree-branch">
          <li className="qu-tree-leaf level-0">
            <div className="leaf-wrapper">
              <Collapse className="qu-tree-item" defaultActiveKey={['1']} accordion>
                <Collapse.Panel
                  key="1"
                  header={
                    <div className="qu-tree-header">
                      <h3>Edit Distribution</h3>
                    </div>
                  }
                >
                  <div className="p-16">
                    <CampaignEditDistForm disabledButton={disabledButton} />
                  </div>
                </Collapse.Panel>
                <Collapse.Panel
                  key="2"
                  header={
                    <div className="qu-tree-header">
                      <h3>Edit Timeline</h3>
                    </div>
                  }
                >
                  <CampaignEditTimeline disabledButton={disabledButton} />
                </Collapse.Panel>
                <Collapse.Panel
                  key="3"
                  header={
                    <div className="qu-tree-header">
                      <h3>Edit Properties</h3>
                    </div>
                  }
                >
                  <div className="p-16">
                    <CampaignEditPropsForm disabledButton={disabledButton} />
                  </div>
                </Collapse.Panel>
              </Collapse>
            </div>
          </li>
        </ul>
      </div>
    </div>
  )
}
export default CampaignEditDistribution
