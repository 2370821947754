const MODULE_NAME = 'admin'

export const getClientsRequestsUrl = (): string => `${MODULE_NAME}/users/clients-list`
export const getPartnersRequestsUrl = (): string => `${MODULE_NAME}/users/partners-list`
// export const getApproveRequestUrl = (id: number): string => `${MODULE_NAME}/requests/approve/${id}`
export const getApproveRequestUrl = (id: number): string => `${MODULE_NAME}/client/profile/activate/${id}`
export const getDeclineRequestUrl = (id: number): string => `${MODULE_NAME}/requests/decline/${id}`
export const getExtendRequestUrl = (id: number): string => `${MODULE_NAME}/requests/extend/${id}`
export const getOverrideRequestPaymentUrl = (id: number | string): string => `${MODULE_NAME}/requests/override/${id}`
export const getInvitedClientsRequestsUrl = (): string => `${MODULE_NAME}/invitedClients/clients`
