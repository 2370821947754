import get from 'lodash/get'
import { IState } from './employees.types'
import * as CONSTANTS from './employees.constants'

const initialState: IState = {
  employeesListLoading: true,
  employeesList: {
    results: [],
    total: 0,
  },
  createEmployeeError: '',
  editEmployeeError: '',
  deleteEmployeeError: '',
  pages: {
    totalPages: 1,
    currentPage: 1,
  },
  updatingEmployee: false,
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case CONSTANTS.FETCH_EMPLOYEES_REQUEST:
      return {
        ...state,
        employeesListLoading: true,
      }
    case CONSTANTS.FETCH_EMPLOYEES_SUCCESS:
      const employeesList = action.payload

      return {
        ...state,
        employeesList: employeesList,
        employeesListLoading: false,
      }
    case CONSTANTS.FETCH_EMPLOYEES_FAILURE:
      return {
        ...state,
        employeesListLoading: false,
      }
    case CONSTANTS.CREATE_EMPLOYEE_REQUEST:
      return {
        ...state,
        updatingEmployee: true,
        createEmployeeError: initialState.createEmployeeError,
      }
    case CONSTANTS.CREATE_EMPLOYEE_SUCCESS:
      return { ...state, updatingEmployee: false }
    case CONSTANTS.CREATE_EMPLOYEE_FAILURE:
      return {
        ...state,
        updatingEmployee: false,
        createEmployeeError: action.error,
      }
    case CONSTANTS.EDIT_EMPLOYEE_REQUEST:
      return {
        ...state,
        updatingEmployee: true,
        editEmployeeError: initialState.editEmployeeError,
      }
    case CONSTANTS.EDIT_EMPLOYEE_SUCCESS:
      return { ...state, updatingEmployee: false }
    case CONSTANTS.EDIT_EMPLOYEE_FAILURE:
      return {
        ...state,
        updatingEmployee: false,
        editEmployeeError: action.error,
      }
    case CONSTANTS.DELETE_EMPLOYEE_REQUEST:
      return { ...state, updatingEmployee: true }
    case CONSTANTS.DELETE_EMPLOYEE_SUCCESS:
      return { ...state, updatingEmployee: false }
    case CONSTANTS.DELETE_EMPLOYEE_FAILURE:
      return { ...state, updatingEmployee: false, deleteEmployeeError: action.error }

    case CONSTANTS.SET_CURRENT_PAGE:
      return {
        ...state,
        pages: {
          ...state.pages,
          currentPage: get(action, 'payload.page'),
        },
      }
    default:
      return state
  }
}
