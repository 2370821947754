import React from 'react'
import { Radio } from 'quantum_components'
import { useSelector } from 'react-redux'

import { PERMISSIONS } from 'src/constants/permission'
import { TABS_NAMES } from 'src/constants/tabs'
import { hasPermission } from 'src/helpers/permission'
import { getCurrentUserPermissionActions } from 'src/modules/core/core.selectors'
import { useTranslation } from 'react-i18next'

interface Props {
  onChange: (event: any) => void
  activeTab: TABS_NAMES
}
const BillingsTabs = ({ onChange, activeTab }: Props) => {
  const { t } = useTranslation(['translationSettings'])
  const permissions = useSelector(getCurrentUserPermissionActions)

  return (
    <Radio.Group className="qu-radio-panel mb-24" buttonStyle="solid" value={activeTab} onChange={onChange} style={{ width: "88px" }}>
      <Radio.Button
        className="qu-radio-button"
        disabled={!hasPermission(permissions, PERMISSIONS.CLIENT_BILLS_ALL)}
        value={TABS_NAMES.CLIENTS}
      >
        <span className="text-uppercase">{t('settings.billings.Clients')}</span>
      </Radio.Button>
      {/* <Radio.Button
        className="qu-radio-button"
        disabled={!hasPermission(permissions, PERMISSIONS.PARTNER_BILLS_ALL)}
        value={TABS_NAMES.PARTNERS}
      >
        <span className="text-uppercase">Partners</span>
      </Radio.Button> */}
      {/* <Radio.Button
        className="qu-radio-button"
        disabled={!hasPermission(permissions, PERMISSIONS.VIEW_ONLINE_PAYMENTS)}
        value={TABS_NAMES.ONLINE_PAYMENTS}
      >
        <span className="text-uppercase">{t('settings.billings.Online Payments')}</span>
      </Radio.Button> */}
    </Radio.Group>
  )
}

export default BillingsTabs
