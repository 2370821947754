import React, { ReactElement } from 'react'
// import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { FORMATS } from '../../../../constants'
import { renderRoundedNumber } from '../../../../helpers/currency'
import { IBriefResponse, ICartList } from '../../brief.types'

import Logo from 'src/assets/logo_email@2x.png'
import './styles.scss'
import { useTranslation } from 'react-i18next'

interface IProps {
  briefView: IBriefResponse
  cart: ICartList
}

export default function BriefQuotation({ briefView, cart }: IProps): ReactElement {
  // const { t } = useTranslation(['translation', 'translationCommon'])
  // console.log("BriefQuotation briefView", briefView)
  // console.log("BriefQuotation cart", cart)

  const { t } = useTranslation(['translationABrief', 'translationDashboard', 'translation'])

  return (
    <div className="payment-sheet-container flex-column hide-proposal" id="new_brief_print_area">
      <div className="sub-container sub-container-top flex-row flex-sb">
        <div className="flex-column">
          <div><img src={cart?.additional?.items[0].companyLogoUrl} className="payment-document-logo" /></div>
          <strong>{cart?.additional?.items[0].companyName}</strong>
          <span>{t("brief.payment.Address", { ns: 'translation' })}: {cart?.additional?.items[0].companyAddress || '-'}</span>
          <span>{t(`vat`)} {cart?.additional?.items[0].companyVat || '-'}</span>

          <div style={{ marginTop: '40px' }} className="flex-column">
            <span>{t(`bill_to`)}</span>
            <strong>{briefView?.owner?.companyName}</strong>
            <span>{t("brief.payment.Address", { ns: 'translation' })}: {briefView?.owner?.clientProfile?.location || '-'}</span>
            <span>{t("VAT")}: {briefView?.owner?.clientProfile?.vat || '-'}</span>
          </div>
        </div>
        <div className="heading">
          <h1>{t(`quotation`)}</h1>
          <strong style={{ color: '#5b6475', float: 'right' }}>{t(`qtn`)} {cart?.briefId}</strong>
        </div>
      </div>
      <div className="sub-container-mid flex-column">
        <div className="section" style={{ paddingTop: 0, marginTop: 0 }}>
          <table className="fees-breakdown">
            <tr>
              <th>{t(`services`)}</th>
              <th>{t(`rate`)}</th>
              <th>{t(`quantity`)}</th>
              <th>{t(`total`)}</th>
            </tr>
            {cart?.additional.items.map(partner => {
              return (
                <>

                  {partner.services.map(service => {
                    let logisticsRate = 0
                    let logisticsQty = 0
                    let logisticsTotal = 0

                    if (service.type === 'Distribution') {
                      logisticsQty = service.quantity || 0

                      //if insert
                      if (service.name === 'Printed Insert') {
                        logisticsRate = partner.logisticInsertRate
                        logisticsTotal = partner.logisticInsertPrice
                      }

                      //if sample
                      else if (service.name === 'Product Sample') {
                        logisticsRate = partner.logisticSampleRate
                        logisticsTotal = partner.logisticSamplePrice
                      }
                    }

                    return (
                      <>
                        <tr>
                          <td>{t('dashboard.service-types-labels.' + service.name, service.name, { ns: 'translationDashboard' })}</td>
                          <td>{renderRoundedNumber(service.amount, service.currency)}</td>
                          <td>{service.quantity}</td>
                          <td>{renderRoundedNumber(service.total, service.currency)}</td>
                        </tr>
                      </>
                    )
                  })}
                </>
              )
            })}

            <tr>
              <td></td>
              <td className="payment-label" colSpan={2}>
                {t(`services_total`)}
              </td>
              <td>{renderRoundedNumber(cart?.additional.servicesTotal, briefView?.owner?.currency)}</td>
            </tr>
            {briefView.remoteBriefId && cart?.additional.miscellaneous && cart?.additional.miscellaneous >0 &&
            <tr>
              <td></td>
              <td className="payment-label" colSpan={2}>
                {t(`misc_fee`, 'Misc. Fee')}
              </td>
              <td>{renderRoundedNumber(cart?.additional.miscellaneous, briefView?.owner?.currency)}</td>
            </tr>}
            {/*<tr>
              <td></td>
              <td className="payment-label" colSpan={2}>
                Management Fee ({cart?.additional.managementFeeRate}%)
              </td>
              <td>{renderRoundedNumber(cart?.additional.managementFee, briefView?.owner?.currency)}</td>
            </tr>*/}
            <tr>
              <td></td>
              <td className="payment-label" colSpan={2}>
                {t(`sub_total`)}
              </td>
              <td>{renderRoundedNumber(cart?.additional.subTotal, briefView?.owner?.currency)}</td>
            </tr>
            <tr>
              <td></td>
              <td className="payment-label" colSpan={2}>
                {t(`taxes`)}
              </td>
              <td>{renderRoundedNumber(cart?.additional.taxes, briefView?.owner?.currency)}</td>
            </tr>
            <tr>
              <td></td>
              <td className="payment-label" colSpan={2}>
                <strong>{t(`total`)}</strong>
              </td>
              <td>
                {renderRoundedNumber(cart?.additional.cartTotal, briefView?.owner?.currency)}
                <br />
                <small>{t(`plus_taxes`)}</small>
              </td>
            </tr>
          </table>
          <div className="flex-column pt-50 pl-10">
            <span style={{ fontSize: '18px' }}>{t("brief.payment.Notes", { ns: 'translation' })}</span><br />
            <span>{t("brief.payment.Payable_to", { ns: 'translation' })}:</span>
            <span>{t("brief.payment.Bank_Name", { ns: 'translation' })}: {cart?.additional?.items[0].companyBank?.bank_name || '-'}</span>
            <span>{t("brief.payment.Address", { ns: 'translation' })}: {cart?.additional?.items[0].companyBank?.bank_address || '-'}</span>
            <span>{t("brief.payment.Account_No", { ns: 'translation' })}: {cart?.additional?.items[0].companyBank?.account_number || '-'}</span>
            <span>{t("brief.payment.IBAN_No", { ns: 'translation' })}: {cart?.additional?.items[0].companyBank?.iban_number || '-'}</span>
            <span>{t("brief.payment.Swift_Code", { ns: 'translation' })}: {cart?.additional?.items[0].companyBank?.swift_code || '-'}</span>
            <span>{t("brief.payment.Currency", { ns: 'translation' })}: {cart?.additional?.items[0].companyBank?.account_currency || '-'}</span>
          </div>
        </div>
      </div>
      <hr />
    </div>
  )
}
