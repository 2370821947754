import { CURRENCY, CURRENCY_SYMBOLS, CURRENCIES } from '../constants/currencies'
import { IPartnerDetailsService } from '../modules/brief/brief.types'
import { IMediaBuyTypesElement } from '../modules/partner/partner-Profile/profile.types'

export const getLocaleCost = (amount: any) => {
  const num = +amount

  return num.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
}

export const calculateAverage = (prices: number[]) => {
  if (!prices.length) {
    return 0
  }

  const len = prices.length
  const sum = prices.reduce((a, b) => a + b, 0)

  return sum / len
}

export const calculateLowestValue = (prices: number[]) => {
  if (!prices.length) {
    return 0
  }

  return Math.min(...prices)
}

export const getDistributionPrices = (partner: any) => {
  let prices = []

  if (partner.isSampleDistribution) {
    prices.push(+partner.pricePerSample)
  }

  if (partner.isInsertDistribution) {
    prices.push(+partner.pricePerInsert)
  }

  return prices
}

export const getDynamicDistributionPrices = (partner: any) => {
  let prices = []

  if (partner.dynamicSamplePrices) {
    Object.entries(partner.dynamicSamplePrices).map(([key, value]: any) => {
      prices.push(+parseFloat(value))
    })
  }

  if (partner.isInsertDistribution) {
    prices.push(+partner.pricePerInsert)
  }

  return prices
}

export const getMediaBuyPrices = (services: IPartnerDetailsService[]): number[] => {
  let prices = [] as number[]

  services.forEach((service: IPartnerDetailsService) => {
    if (service.servicePriceForActivity) {
      prices.push(+service.servicePriceForActivity)
    }

    if (service.servicePriceForDuration) {
      prices.push(+service.servicePriceForDuration)
    }

    if (service.servicePriceForImpression) {
      prices.push(+service.servicePriceForImpression)
    }

    if (service.servicePriceForPush) {
      prices.push(+service.servicePriceForPush)
    }
  })

  return prices
}

export const getMediaBuyPricesForMetrics = (services: IPartnerDetailsService[]): number[] => {
  let prices = [] as number[]

  if(!services) return prices;

  services.forEach((service: IPartnerDetailsService) => {
    if (service.serviceCostPerImpression) {
      prices.push(+service.serviceCostPerImpression)
    }
  })

  return prices
}

export const getMediaBuyTypesPrices = (services: IMediaBuyTypesElement[]): number[] => {
  let prices = [] as number[]

  if(!services) return prices;

  services.forEach((service: IMediaBuyTypesElement) => {
    if (service.pricePerExecution) {
      prices.push(+service.pricePerExecution)
    }

    if (service.pricePerActivity) {
      prices.push(+service.pricePerActivity)
    }

    if (service.priceForDuration) {
      prices.push(+service.priceForDuration)
    }

    if (service.priceForImpression) {
      prices.push(+service.priceForImpression)
    }

    if (service.pricePerPush) {
      prices.push(+service.pricePerPush)
    }
  })

  return prices
}

export const getMediaBuyTypesPricesForMetrics = (services: IMediaBuyTypesElement[]): number[] => {
  let prices = [] as number[]

  services.forEach((service: IMediaBuyTypesElement) => {
    if (service.costPerImpression) {
      prices.push(+service.costPerImpression)
    }
  })

  return prices
}

export const getMediaBuyPrice = (service: IPartnerDetailsService): number => {
  if (service.servicePriceForActivity) {
    return +service.servicePriceForActivity
  }

  if (service.servicePriceForDuration) {
    return +service.servicePriceForDuration
  }

  if (service.servicePriceForImpression) {
    return +service.servicePriceForImpression
  }

  if (service.servicePriceForPush) {
    return +service.servicePriceForPush
  }

  return service.servicePrice ? service.servicePrice : 0
}

export const getMediaBuyPriceForMetrics = (service: IPartnerDetailsService): number => {
  if (service.serviceCostPerImpression) {
    return +service.serviceCostPerImpression
  }

  return 0
}

export const getMediaBuyPartnerPrice = (service: IMediaBuyTypesElement): number => {
  if (service.pricePerActivity) {
    return +service.pricePerActivity
  }

  if (service.priceForDuration) {
    return +service.priceForDuration
  }

  if (service.priceForImpression) {
    return +service.priceForImpression
  }

  if (service.pricePerPush) {
    return +service.pricePerPush
  }

  return 0
}

export const getMediaBuyPartnerPriceForMetrics = (service: IMediaBuyTypesElement): number => {
  if (service.costPerImpression) {
    return +service.costPerImpression
  }

  return 0
}

export const renderRoundedNumber = (amount: any, currency: CURRENCY): string => {
  return amount ? `${getLocaleCost(amount)} ${CURRENCY_SYMBOLS[currency]}` : '-'
}

export const currencyOptions = CURRENCIES.map((c: CURRENCY) => {
  return { label: c, value: c }
})

export const getLocaleCostWithoutDecimal = (amount: any) => {
  const num = +amount

  return num.toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
}

export const getLocaleCostWithoutCurrencyNDecimals = (amount: any) => {
  const num = +amount

  return num.toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
}
