export const MODULE_NAME = 'ageGroups'

/* actions */

export const FETCH_AGE_GROUPS_REQUEST = `${MODULE_NAME}/FETCH_AGE_GROUPS_REQUEST`
export const FETCH_AGE_GROUPS_SUCCESS = `${MODULE_NAME}/FETCH_AGE_GROUPS_SUCCESS`
export const FETCH_AGE_GROUPS_FAILURE = `${MODULE_NAME}/FETCH_AGE_GROUPS_FAILURE`

export const CREATE_AGE_GROUP_REQUEST = `${MODULE_NAME}/CREATE_AGE_GROUP_REQUEST`
export const CREATE_AGE_GROUP_SUCCESS = `${MODULE_NAME}/CREATE_AGE_GROUP_SUCCESS`
export const CREATE_AGE_GROUP_FAILURE = `${MODULE_NAME}/CREATE_AGE_GROUP_FAILURE`

export const EDIT_AGE_GROUP_REQUEST = `${MODULE_NAME}/EDIT_AGE_GROUP_REQUEST`
export const EDIT_AGE_GROUP_SUCCESS = `${MODULE_NAME}/EDIT_AGE_GROUP_SUCCESS`
export const EDIT_AGE_GROUP_FAILURE = `${MODULE_NAME}/EDIT_AGE_GROUP_FAILURE`

export const DELETE_AGE_GROUP_REQUEST = `${MODULE_NAME}/DELETE_AGE_GROUP_REQUEST`
export const DELETE_AGE_GROUP_SUCCESS = `${MODULE_NAME}/DELETE_AGE_GROUP_SUCCESS`
export const DELETE_AGE_GROUP_FAILURE = `${MODULE_NAME}/DELETE_AGE_GROUP_FAILURE`

export const IMAGE_UPLOAD_REQUEST = `${MODULE_NAME}/IMAGE_UPLOAD_REQUEST`
export const IMAGE_UPLOAD_SUCCESS = `${MODULE_NAME}/IMAGE_UPLOAD_SUCCESS`
export const IMAGE_UPLOAD_FAILURE = `${MODULE_NAME}/IMAGE_UPLOAD_FAILURE`

export const IMAGE_REMOVE_REQUEST = `${MODULE_NAME}/IMAGE_REMOVE_REQUEST`
export const IMAGE_REMOVE_SUCCESS = `${MODULE_NAME}/IMAGE_REMOVE_SUCCESS`
export const IMAGE_REMOVE_FAILURE = `${MODULE_NAME}/IMAGE_REMOVE_FAILURE`
