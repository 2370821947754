export enum GENDER {
  MALE = 'Male',
  FEMALE = 'Female',
}
export enum PARTNER_LABEL {
  ECOMMERCE = 'ECOMMERCE',
  EVENT = 'EVENT',
  GROUP = 'GROUP',
}
export enum SOCIAL_TYPES {
  LEADS = 'Leads',
  LOOKS = 'Looks',
  FACEBOOK = 'Facebook',
  INSTAGRAM = 'Instagram',
  TWITTER = 'Twitter',
}

export const SOCIAL_NAMES: { [key: string]: string } = {
  [SOCIAL_TYPES.LEADS]: 'Customers',
  [SOCIAL_TYPES.LOOKS]: 'Impressions',
  [SOCIAL_TYPES.FACEBOOK]: 'Facebook',
  [SOCIAL_TYPES.INSTAGRAM]: 'Instagram',
  [SOCIAL_TYPES.TWITTER]: 'Twitter',
}

export enum PARTNER_UPLOAD_TYPES {
  PARTNER_PROFILE_IMAGES = 'partner_profile_images',
}

export const INVITED_CLIENTS_STATUS: any = {
  JOINED: 'JOINED',
  PENDING: 'PENDING',
  EXISTING_USER: 'EXISTING USER',
}

export const INVITED_CLIENTS_LABEL: any = {
  JOINED: 'list.Joined',
  PENDING: 'list.Pending',
  EXISTING_USER: 'list.Existing User',
}


