import React, { useEffect, useState } from 'react'
import {
  QButton,
  Form,
  QInput,
  Radio,
  QRadio,
  Row,
  Col,
  QSelect,
  IconsCommon,
  qnotification,
  Modal,
  QCheckbox,
  QTextArea,
} from 'quantum_components'
import { NOTIFICATION_TYPES, REGEXES } from 'src/constants'
import { useSelector } from 'react-redux'
import { getForm, getPartnerProfileSettings } from 'src/modules/partner/partner-Profile/profile.selectors'
import { isMobile } from 'react-device-detect'
import ImageUploadField from '../ImageUploadField'
import { MEDIA_TYPES, MEDIA_TYPES_DURATION } from 'src/constants/brief'
import { FIELDS_MEDIA_TYPES, BANNER_SUB_TYPES, SKU_SUB_TYPES } from 'src/modules/partner/partner-Profile/profile.types'
import PreviewMediaTypes from '../PreviewMediaTypes'
import { renderRoundedNumber } from 'src/helpers/currency'
import { PRICING_MODEL } from 'src/constants/profile'
import { useTranslation } from 'react-i18next'
import { snakeCase, isEmpty, map } from 'lodash'
import { hasPermission } from 'src/helpers/permission'
import { PERMISSIONS } from 'src/constants/permission'
import { getCurrentUserPermissionActions } from 'src/modules/core/core.selectors'

interface Props {
  formData: any
  isChecked: boolean
  onFinish: (values: any, shouldDelete?: boolean) => void
}

const { IconArrowLeft } = IconsCommon

const RequiredAdspaceFields = [
  { key: 'subName', label: 'Title' },
  { key: 'duration', label: 'Duration' },
  { key: 'priceForDuration', label: 'Price for Duration' },
  { key: 'width', label: 'Width' },
  { key: 'height', label: 'Height' },
  { key: 'description', label: 'Description' },
  { key: 'avgImpressions', label: 'Average Impressions' },
];

export default function MultiForm({ formData, onFinish, isChecked }: Props) {
  const { t } = useTranslation(['translationProfile', 'translationDashboard', 'translation'])
  const { mediaBuyTypes, currency, mbPricingModel } = useSelector(getForm)
  const { mediaSubtypes } = useSelector(getPartnerProfileSettings)
  const permissions = useSelector(getCurrentUserPermissionActions)

  const selectedSubtypes = mediaBuyTypes.filter(type => type.name === formData.name) || []

  const [isShowPreview, setIsShowPreview] = useState(false)

  const [isCustomMediaType, setIsCustomMediaType] = useState(false)

  const settingsMediaType = mediaSubtypes.find(type => type.name === formData.name)
  const fieldsToCheck = settingsMediaType?.availableFields.map(f => f.name)

  const [types, setTypes] = useState(selectedSubtypes.map(subType => subType.subName) || [])
  const [forms, setForms] = useState(selectedSubtypes || [])

  const [tableKey, setTableKey] = useState(1)
  const [tableKey1, setTableKey1] = useState(1)
  const [mediaTypesDropdownOpen, setMediaTypesDropdownOpen] = useState<boolean>(false)
  const [customBannerDeleteModal, setCustomBannerDeleteModal] = useState({
    title: '',
    v: [],
    visible: false,
  })

  useEffect(() => {
    setIsCustomMediaType(Object.values(MEDIA_TYPES).find(el => el === formData.name) ? false : true)
  }, [formData])

  const handleOnFinish = (shouldDelete?: boolean) => async (values: any) => {

    // hard coding width and height to be 0 for any SKU type
    const updatedForms = [...forms].map((mb:any)=>{
      if(mb.name === MEDIA_TYPES.SKU){
        mb.width = 0;
        mb.height = 0;
      }
      return mb;
    });
    setForms(updatedForms);


    if (mbPricingModel === PRICING_MODEL.METRICS) {
      forms.map((ele: any) => {
        ele.dailyClicks = ele.dailyClicks ? ele.dailyClicks : null
        ele.costPerClick = ele.costPerClick ? ele.costPerClick : null
        ele.dailyImpressions = ele.dailyImpressions ? ele.dailyImpressions : null
        ele.costPerImpression = ele.costPerImpression ? ele.costPerImpression : null
        ele.dailyReach = ele.dailyReach ? ele.dailyReach : null
        ele.costPerReach = ele.costPerReach ? ele.costPerReach : null
      })
      setForms(forms)
    }

    //validation for adspace zone tag creation
    //@ts-ignore
    const missingMedia: any = {};
    const invalidatedFields: any = [];
    updatedForms.map((mb: any) => {
      if (mb.name === MEDIA_TYPES.HOME_BANNERS || mb.name === MEDIA_TYPES.SKU) {
        const missingFields: any = [];

        RequiredAdspaceFields.map(field => {
          
          if(['width','height'].includes(field.key) && (mb[field.key]==undefined || Number(mb[field.key]) < 0)){
            if (!missingFields.includes(field.label)) missingFields.push(t(`profile.${field.label}`));
          }

          if (['duration','priceForDuration','avgImpressions'].includes(field.key) && (mb[field.key]==undefined || Number(mb[field.key]) < 1)) {
            if (!missingFields.includes(field.label)) missingFields.push(t(`profile.${field.label}`));
          }

          if(['subName','description'].includes(field.key) && (mb[field.key]==undefined || String(mb[field.key]).length > 255 || String(mb[field.key]).length < 1)){
            if(!missingFields.includes(field.label)) missingFields.push(t(`profile.${field.label}`));
          }
        })
        
        //@ts-ignore
        if (missingFields.length) missingMedia[mb.subName] = missingFields;
      }
    })

    if (!isEmpty(missingMedia)) {
      const missingLength = Object.keys(missingMedia).length;
      Object.keys(missingMedia).map(key => {
        //@ts-ignore
        qnotification({ type: NOTIFICATION_TYPES.ERROR, message: `${key || t(`profile.Custom Space`)}: ${missingMedia[key].join(', ')} ${missingLength > 1 ? t('profile.are') : t('profile.is')} ${t('profile.required')}\n` })
      })
      return;
    }

    if (invalidatedFields.length > 0) {
      qnotification({ type: NOTIFICATION_TYPES.ERROR, message: `${invalidatedFields.join(', ')} ${t("profile.Cannot exceed more than 255 characters")}` })
      return;
    }

    if (onFinish && shouldDelete) onFinish({ name: formData.name }, shouldDelete)
    else if (onFinish) onFinish(updatedForms, false)
  }

  const [selectOptions, setSelectOptions] = useState<any>([])

  useEffect(() => {
    ; (async () => {
      const mediaTypeSelectOptions = settingsMediaType?.subNames?.length ? settingsMediaType?.subNames?.map(name => ({
        label: t('services.media-buy.types.' + snakeCase(name), name, { ns: 'translation' }),
        value: name,
      })) : []
      if (formData.name === MEDIA_TYPES.HOME_BANNERS || formData.name === MEDIA_TYPES.SKU || isCustomMediaType) {
        types.map((type: any) => {
          if (mediaTypeSelectOptions?.findIndex(ele => ele.label === type) === -1)
            mediaTypeSelectOptions?.push({
              label: t('services.media-buy.types.' + snakeCase(type), type, { ns: 'translation' }),
              value: type,
            })
        })
      }
      setSelectOptions(mediaTypeSelectOptions ? mediaTypeSelectOptions : [])
    })()
  }, [settingsMediaType, isCustomMediaType])

  const handleSetForms = (types: any[]) => {
    let newMediaTypes = [...forms]
    if (types.length > 1) {
      types.forEach((t: any) => {
        const idx = newMediaTypes.findIndex(({ subName }) => subName === t.subName)
        if (idx !== -1) {
          newMediaTypes[idx] = { ...newMediaTypes[idx], ...t }
          //@ts-ignore
          if (!newMediaTypes[idx].hasError) delete newMediaTypes[idx]['hasError']
        } else {
          if (!t.hasError) delete t['hasError']
          newMediaTypes.splice(0, 0, t)
        }
      })
      newMediaTypes = newMediaTypes.filter(
        ty => (types.map(s => s.subName).includes(ty.subName) && ty.name === types[0].name) || ty.name !== types[0].name
      )
    } else if (types.length === 1) {
      const idx = newMediaTypes.findIndex(({ subName }) => subName === types[0].subName)
      if (idx !== -1) {
        newMediaTypes[idx] = { ...newMediaTypes[idx], ...types[0] }
        //@ts-ignore
        if (!newMediaTypes[idx].hasError) delete newMediaTypes[idx]['hasError']
      } else {
        if (!types[0].hasError) delete types[0]['hasError']
        if (types[0].subName) newMediaTypes.splice(0, 0, types[0])
        else newMediaTypes = []
      }

      newMediaTypes = newMediaTypes.filter(
        ty => (ty.name === types[0].name && ty.subName === types[0].subName) || ty.name !== types[0].name
      )
    } else {
      newMediaTypes = newMediaTypes.filter(ty => ty.name !== types[0].name)
    }
    setForms(newMediaTypes)
  }

  const handleOnSelect = async (v: any) => {
    let difference = v.filter((x: any) => !types.includes(x))
    if (difference.length > 0) {
      setTypes([difference[0], ...types])
    } else {
      setTypes(v)
    }
    if (
      (formData.name === MEDIA_TYPES.HOME_BANNERS || formData.name === MEDIA_TYPES.SKU || isCustomMediaType) &&
      v.length < types.length &&
      types.filter((x: any) => !v.includes(x)).length > 0
    ) {
      setMediaTypesDropdownOpen(false)
      const unSelectItem: any = types.filter((x: any) => !v.includes(x))
      if (!Object.values(BANNER_SUB_TYPES).includes(unSelectItem[0])) {
        setCustomBannerDeleteModal({
          title: unSelectItem,
          v: v,
          visible: true,
        })
        return
      }
    }
    v.length
      ? difference.length > 0
        ? handleSetForms([difference[0], ...types].map((f: any) => ({ name: formData.name, subName: f })))
        : handleSetForms(v.map((f: any) => ({ name: formData.name, subName: f })))
      : handleSetForms([{ name: formData.name }])

    setTableKey(Math.floor(Math.random() * 1000 + 1))
  }

  const handleYesNo = async (yesOrNo: boolean) => {
    if (!types.includes('')) {
      if (yesOrNo) {
        const remainingOptions = selectOptions.filter((ele: any) => ele.value != customBannerDeleteModal.title)
        setSelectOptions(remainingOptions)
      }

      customBannerDeleteModal.v.length
        ? handleSetForms(customBannerDeleteModal.v.map((f: any) => ({ name: formData.name, subName: f })))
        : handleSetForms([{ name: formData.name }])

      setTypes(customBannerDeleteModal.v)

      setCustomBannerDeleteModal({
        title: '',
        v: [],
        visible: false,
      })
    } else {
      qnotification({
        type: NOTIFICATION_TYPES.ERROR,
        message: t(`profile.partner.available_services.multi_form.media_deselect_error`)
      })
      setCustomBannerDeleteModal({ ...customBannerDeleteModal, visible: false })
    }
    setTableKey(Math.floor(Math.random() * 1000 + 1))
    setTableKey1(Math.floor(Math.random() * 1000 + 1))
  }

  const createCustomBanner = async () => {
    if (selectOptions.length === 20) {
      qnotification({ type: NOTIFICATION_TYPES.ERROR, message: t(`profile.partner.available_services.multi_form.custom_limit_reached`, { name: formData.name }) })
      return
    }
    for (let i = 1; i <= 15; i++) {
      const filteredOptions = selectOptions.filter((ele: any) => ele.value === `Custom ${formData.name} ${i}`)
      if (filteredOptions.length === 0) {
        selectOptions.push({
          label: `Custom ${formData.name} ${i}`,
          value: `Custom ${formData.name} ${i}`,
        })
        break
      }
    }
    setSelectOptions(selectOptions)
    handleOnSelect([...types, selectOptions[selectOptions.length - 1].label])
    setTableKey(Math.floor(Math.random() * 1000 + 1))
    setTableKey1(Math.floor(Math.random() * 1000 + 1))
    setMediaTypesDropdownOpen(true)
  }

  const onChangeInputHandler = (subtype: any, field: string) => (event: any) =>
    handleSetForms(
      types.map(f =>
        f === subtype
          ? { name: formData.name, subName: f, [field]: event.target.value }
          : { name: formData.name, subName: f }
      )
    )

  const onChangeTitleHandler = (subtype: any, index: number) => (event: any) => {
    setMediaTypesDropdownOpen(false)

    if (types.includes(event.target.value)) {
      qnotification({ type: NOTIFICATION_TYPES.ERROR, message: t(`profile.partner.available_services.multi_form.duplicate_title`) })
      setForms(forms)
      setTableKey(Math.floor(Math.random() * 1000 + 1))
      return
    }

    selectOptions[selectOptions.findIndex((x: any) => x.value === subtype)].label = event.target.value
    selectOptions[selectOptions.findIndex((x: any) => x.value === subtype)].value = event.target.value
    setSelectOptions(selectOptions)

    types[types.indexOf(subtype)] = event.target.value

    forms[index].subName = event.target.value
    setForms(forms)
    handleSetForms(
      types.map(f =>
        f === event.target.value
          ? {
            name: formData.name,
            subName: event.target.value,
          }
          : { name: formData.name, subName: f }
      )
    )
    setTableKey1(Math.floor(Math.random() * 1000 + 1))
  }

  const validationFields = ['avgImpressions', 'pricePerActivity', 'priceForDuration', 'width', 'height']

  const onChangeInputWithValidation = (subtype: any, field: string, validator: RegExp) => (e: any) => {
    const value = String(e.currentTarget.value).trim()
    const hasError = validationFields.some(vfield => {
      const vObj = forms.find(form => form.subName === subtype)
      if (vfield === field) return !(RegExp(validator).test(value) || !value.length)
      //@ts-ignore
      return !(vObj[vfield] === undefined || RegExp(validator).test(vObj[vfield]) || !vObj[vfield]?.length)
    })

    handleSetForms(
      types.map(f =>
        f === subtype
          ? { hasError, name: formData.name, subName: f, [field]: e.currentTarget.value }
          : { name: formData.name, subName: f }
      )
    )
  }

  const onChangeCheckboxHandler = (subtype: any, field: string, value: string) => (event: any) => {
    const filteredFormData: any = forms.filter(ele => ele.subName === subtype)

    if (filteredFormData[0].marketingMetrics && filteredFormData[0].marketingMetrics.includes(value)) {
      filteredFormData[0].marketingMetrics.splice(filteredFormData[0].marketingMetrics.indexOf(value), 1)
    } else {
      if (filteredFormData[0].marketingMetrics) {
        filteredFormData[0].marketingMetrics.push(value)
      }
    }

    const fields: string[] = []
    if (value === 'Impression') {
      fields[0] = FIELDS_MEDIA_TYPES.DAILY_IMPRESSION
      fields[1] = FIELDS_MEDIA_TYPES.CPM
    } else if (value === 'Click') {
      fields[0] = FIELDS_MEDIA_TYPES.DAILY_CLICKS
      fields[1] = FIELDS_MEDIA_TYPES.CPC
    } else {
      fields[0] = FIELDS_MEDIA_TYPES.DAILY_REACH
      fields[1] = FIELDS_MEDIA_TYPES.CPR
    }

    handleSetForms(
      types.map(f =>
        f === subtype
          ? {
            name: formData.name,
            subName: f,
            [field]: filteredFormData[0].marketingMetrics || [value],
            [fields[0]]: '',
            [fields[1]]: '',
          }
          : { name: formData.name, subName: f }
      )
    )

    setTableKey(Math.floor(Math.random() * 1000 + 1))
  }


  return (
    <div className="qu-modal-media-types-content">
      <div>
        {!isShowPreview && (
          <>
            <Row gutter={16}>
              <Col xs={24} sm={24} md={24}>
                <div className="qu-modal-media-types-block">
                  <label htmlFor="subName" className="form-label mb-5">
                    {formData.name} {t(`profile.partner.available_services.multi_form.types`)}
                  </label>
                  <div className="mb-0">
                    <QSelect
                      key={tableKey1}
                      defaultOpen={mediaTypesDropdownOpen}
                      getPopupContainer={trigger => trigger.parentNode}
                      defaultValue={types}
                      maxTagTextLength={8}
                      maxTagCount={2}
                      value={types}
                      onChange={handleOnSelect}
                      mode="multiple"
                      size="large"
                      placeholder={t(`profile.partner.available_services.multi_form.media_subtype`)}
                      options={selectOptions}
                      style={{ minWidth: '300px' }}
                      disabled={!hasPermission(permissions, PERMISSIONS.UPDATE_SERVICES)}
                    />
                    {(formData.name === MEDIA_TYPES.HOME_BANNERS || formData.name === MEDIA_TYPES.SKU ||  isCustomMediaType) && (
                      <QButton
                        disabled={!hasPermission(permissions, PERMISSIONS.UPDATE_SERVICES)}
                        type="primary"
                        className={isMobile ? 'mt-10' : 'ml-10'}
                        style={{ backgroundColor: '#ff9800' }}
                        onClick={createCustomBanner}
                        size="small"
                      >
                        {t(`profile.partner.available_services.multi_form.add_custom`)} {formData.name}
                      </QButton>
                    )}
                  </div>
                </div>
              </Col>
            </Row>

            {types.map((type, index) => {
              const typeState = selectedSubtypes.find(({ subName }) => subName === type)
              const typeLocalState = forms.find(({ subName }) => subName === type)
              const preview: any = {
                mbPricingModel,
                name: typeLocalState?.name,
                description: typeLocalState?.description,
                url: typeState?.previewImage,
                avgImpressions: typeLocalState?.avgImpressions,
                duration: typeLocalState?.duration,
                pricePerActivity: typeLocalState?.pricePerActivity,
                priceForDuration: typeLocalState?.priceForDuration,
                priceForImpression: typeLocalState?.priceForImpression,
                pricePerPush: typeLocalState?.pricePerPush,
                minOrderValue: typeLocalState?.minOrderValue,
                subName: typeLocalState?.subName,
                dailyImpressions: typeLocalState?.dailyImpressions,
                CPM: typeLocalState?.costPerImpression
                  ? renderRoundedNumber(typeLocalState?.costPerImpression, currency)
                  : '',
                dailyClicks: typeLocalState?.dailyClicks,
                CPC: typeLocalState?.costPerClick ? renderRoundedNumber(typeLocalState?.costPerClick, currency) : '',
                dailyReach: typeLocalState?.dailyReach,
                CPR: typeLocalState?.costPerReach ? renderRoundedNumber(typeLocalState?.costPerReach, currency) : '',
                tag: typeLocalState?.adspace,
              }
              return (
                <div key={index}>
                  <hr className="form__hr" id={typeLocalState?.subName} />
                  <Row gutter={40}>
                    <Col xs={24} sm={24} md={12}>
                      <div className="qu-modal-media-types-block">
                        <Form
                          key={tableKey + (index + 1)}
                          name={typeLocalState?.subName}
                          initialValues={typeLocalState}
                          // requiredMark
                          hideRequiredMark={false}
                        >
                          <div className="qu-modal-media-types-block">
                            {!Object.values(BANNER_SUB_TYPES).includes(preview.subName) &&
                              (formData.name === MEDIA_TYPES.HOME_BANNERS || formData.name === MEDIA_TYPES.SKU || isCustomMediaType) && (
                                <>
                                  <label htmlFor="subName" className="form-label mb-5 label-required">
                                    {t(`profile.partner.available_services.multi_form.title`)}
                                  </label>
                                  <Form.Item className="mb-5" name="subName" rules={[{ required: true, message: t("profile.public.Required") }, { max: 255, message: t('Cannot exceed more than 255 characters') }]}>
                                    <QInput
                                      disabled={!hasPermission(permissions, PERMISSIONS.UPDATE_SERVICES)}
                                      value={typeLocalState?.subName}
                                      onChange={onChangeTitleHandler(type, index)}
                                      placeholder={t(`profile.partner.available_services.multi_form.enter_a_title`)}
                                      size="large"
                                    />
                                  </Form.Item>
                                </>
                              )}

                            <label htmlFor="description" className="form-label mb-5">
                              {t(`profile.partner.available_services.multi_form.description`)}
                            </label>
                            <Form.Item className="mb-5" name="description" rules={[{ max: 255, message: t('Cannot exceed more than 255 characters') }]} validateTrigger={['onChange', 'onBlur']} >
                            <QTextArea 
                              className="form-textarea" 
                              rows={5} 
                              disabled={!hasPermission(permissions, PERMISSIONS.UPDATE_SERVICES)}
                              value={typeLocalState?.description}
                              onChange={onChangeInputHandler(type, 'description')}
                              placeholder={t(`profile.partner.available_services.multi_form.enter_a_description`)}
                              rules={[{ max: 255, message: t('Cannot exceed more than 255 characters') }]}
                              validateTrigger={['onChange', 'onBlur']}
                              size="large"
                            />
                              {/* <QInput
                                disabled={!hasPermission(permissions, PERMISSIONS.UPDATE_SERVICES)}
                                value={typeLocalState?.description}
                                onChange={onChangeInputHandler(type, 'description')}
                                placeholder={t(`profile.partner.available_services.multi_form.enter_a_description`)}
                                rules={[{ max: 255, message: t('Cannot exceed more than 255 characters') }]}
                                validateTrigger={['onChange', 'onBlur']}
                                size="large"
                              /> */}
                            </Form.Item>
                            {/* @ts-ignore  */}
                            <ImageUploadField isDisabled={!hasPermission(permissions, PERMISSIONS.UPDATE_SERVICES)} formData={typeLocalState} />
                          </div>

                          {fieldsToCheck?.includes(FIELDS_MEDIA_TYPES.DURATION) &&
                            mbPricingModel === PRICING_MODEL.DURATION && (
                              <div className="qu-modal-media-types-block">
                                <h4 className="form-label mb-5 label-required">{t(`profile.partner.available_services.multi_form.duration`)}</h4>
                                <p className="p--xs tc--light mb-0">
                                  {t(`profile.partner.available_services.multi_form.duration_subtext`)}
                                </p>
                                <Form.Item className="mb-0" name="duration" rules={[{ required: true }]}>
                                  <Radio.Group
                                    disabled={!hasPermission(permissions, PERMISSIONS.UPDATE_SERVICES)}
                                    onChange={onChangeInputHandler(type, 'duration')}
                                    value={typeLocalState?.duration}
                                    className="qu-radio-group"
                                  // disabled={!!typeLocalState?.adspace}
                                  >
                                    <QRadio value={MEDIA_TYPES_DURATION.PER_DAY}>{t(`profile.public.duration-options.Per Day`)}</QRadio>
                                    <QRadio value={MEDIA_TYPES_DURATION.PER_WEEK}>{t(`profile.public.duration-options.Per Week`)}</QRadio>
                                    <QRadio value={MEDIA_TYPES_DURATION.PER_MONTH}>{t(`profile.public.duration-options.Per Month`)}</QRadio>
                                  </Radio.Group>
                                </Form.Item>
                              </div>
                            )}

                          <div className="qu-modal-media-types-block">
                            <Row gutter={20} className="mt-15">
                              {fieldsToCheck?.includes(FIELDS_MEDIA_TYPES.AVG_IMPRESSIONS) &&
                                mbPricingModel === PRICING_MODEL.DURATION && (
                                  <Col span={12}>
                                    <Form.Item
                                      key={type}
                                      className="mb-0"
                                      rules={[
                                        {
                                          message: t(`profile.partner.sales_info.invalid_amount`),
                                          pattern: REGEXES.AMOUNT_REGEX,
                                          required: true
                                        },
                                      ]}
                                      name="avgImpressions"
                                    >
                                      <QInput
                                        disabled={!hasPermission(permissions, PERMISSIONS.UPDATE_SERVICES)}
                                        value={typeLocalState?.avgImpressions}
                                        onChange={onChangeInputWithValidation(
                                          type,
                                          'avgImpressions',
                                          REGEXES.AMOUNT_REGEX
                                        )}
                                        type="number"
                                        placeholder={t(`profile.partner.available_services.multi_form.enter_value`)}
                                        size="large"
                                        rules={[{ required: true }]}
                                      />
                                    </Form.Item>
                                    <label htmlFor="avgImpressions" className="form-label form-label-secondary mt-5 label-required">
                                      {t(`profile.partner.available_services.multi_form.average_impressions`)}
                                    </label>
                                  </Col>
                                )}
                              {fieldsToCheck?.includes(FIELDS_MEDIA_TYPES.PRICE_DURATION) &&
                                mbPricingModel === PRICING_MODEL.DURATION && (
                                  <Col span={12}>
                                    <Form.Item
                                      className="mb-0"
                                      name="priceForDuration"
                                      rules={[
                                        {
                                          message: t(`profile.partner.sales_info.invalid_amount`),
                                          pattern: REGEXES.AMOUNT_REGEX,
                                          required: true
                                        },
                                      ]}
                                    >
                                      <QInput
                                        disabled={!hasPermission(permissions, PERMISSIONS.UPDATE_SERVICES)}
                                        value={typeLocalState?.priceForDuration}
                                        onChange={onChangeInputWithValidation(
                                          type,
                                          'priceForDuration',
                                          REGEXES.AMOUNT_REGEX
                                        )}
                                        type="number"
                                        placeholder={t(`profile.partner.available_services.multi_form.enter_value`)}
                                        size="large"
                                      // readOnly={!!typeLocalState?.adspace}
                                      // disabled={!!typeLocalState?.adspace}
                                      />
                                    </Form.Item>
                                    <label htmlFor="priceForDuration" className="form-label form-label-secondary mt-5 label-required">
                                      {t(`profile.partner.available_services.multi_form.price_for_duration`)}
                                    </label>
                                  </Col>
                                )}
                              {fieldsToCheck?.includes(FIELDS_MEDIA_TYPES.PRICE_ACTIVITY) &&
                                mbPricingModel === PRICING_MODEL.DURATION && (
                                  <Col span={12}>
                                    <Form.Item
                                      className="mb-0"
                                      name="pricePerActivity"
                                      rules={[
                                        {
                                          message: t(`profile.partner.sales_info.invalid_amount`),
                                          pattern: REGEXES.AMOUNT_REGEX,
                                        },
                                      ]}
                                    >
                                      <QInput
                                        disabled={!hasPermission(permissions, PERMISSIONS.UPDATE_SERVICES)}
                                        value={typeLocalState?.pricePerActivity}
                                        onChange={onChangeInputWithValidation(
                                          type,
                                          'pricePerActivity',
                                          REGEXES.AMOUNT_REGEX
                                        )}
                                        type="number"
                                        placeholder={t(`profile.partner.available_services.multi_form.enter_value`)}
                                        size="large"
                                      />
                                    </Form.Item>
                                    <label htmlFor="pricePerActivity" className="form-label form-label-secondary mt-5">
                                      {t(`profile.partner.available_services.multi_form.price_per_activity`)}
                                    </label>
                                  </Col>
                                )}
                              {fieldsToCheck?.includes(FIELDS_MEDIA_TYPES.PRICE_PUSH) &&
                                mbPricingModel === PRICING_MODEL.DURATION && (
                                  <Col span={12}>
                                    <Form.Item
                                      className="mb-0"
                                      name="pricePerPush"
                                      rules={[
                                        {
                                          message: t(`profile.partner.sales_info.invalid_amount`),
                                          pattern: REGEXES.AMOUNT_REGEX,
                                        },
                                      ]}
                                    >
                                      <QInput
                                        disabled={!hasPermission(permissions, PERMISSIONS.UPDATE_SERVICES)}
                                        value={typeLocalState?.pricePerPush}
                                        onChange={onChangeInputWithValidation(
                                          type,
                                          'pricePerPush',
                                          REGEXES.AMOUNT_REGEX
                                        )}
                                        type="number"
                                        placeholder={t(`profile.partner.available_services.multi_form.enter_value`)}
                                        size="large"
                                      />
                                    </Form.Item>
                                    <label htmlFor="pricePerPush" className="form-label form-label-secondary mt-5">
                                      {t(`profile.partner.available_services.multi_form.price_per_push`)}
                                    </label>
                                  </Col>
                                )}
                              {fieldsToCheck?.includes(FIELDS_MEDIA_TYPES.PRICE_IMPRESSION) &&
                                mbPricingModel === PRICING_MODEL.DURATION && (
                                  <Col span={12}>
                                    <Form.Item
                                      className="mb-0"
                                      name="priceForImpression"
                                      rules={[
                                        {
                                          message: t(`profile.partner.sales_info.invalid_amount`),
                                          pattern: REGEXES.AMOUNT_REGEX,
                                        },
                                      ]}
                                    >
                                      <QInput
                                        disabled={!hasPermission(permissions, PERMISSIONS.UPDATE_SERVICES)}
                                        value={typeLocalState?.priceForImpression}
                                        onChange={onChangeInputWithValidation(
                                          type,
                                          'priceForImpression',
                                          REGEXES.AMOUNT_REGEX
                                        )}
                                        type="number"
                                        placeholder={t(`profile.partner.available_services.multi_form.enter_value`)}
                                        size="large"
                                      />
                                    </Form.Item>
                                    <label
                                      htmlFor="priceForImpression"
                                      className="form-label form-label-secondary mt-5"
                                    >
                                      {t(`profile.partner.available_services.multi_form.price_for_impressions`)}
                                    </label>
                                  </Col>
                                )}
                            </Row>
                          </div>

                          {fieldsToCheck?.includes(FIELDS_MEDIA_TYPES.WIDTH) &&
                            fieldsToCheck?.includes(FIELDS_MEDIA_TYPES.HEIGHT) && (
                              <div className="qu-modal-media-types-block">
                                <h4 className="form-label mb-5">
                                  {typeLocalState?.name === MEDIA_TYPES.HOME_BANNERS ? 'Banner' : 'Image/Video'}{' '}
                                  {t(`profile.partner.available_services.multi_form.dimensions_in_pixels`)}
                                </h4>
                                {typeLocalState?.name === MEDIA_TYPES.HOME_BANNERS && (
                                  <p className="p--xs tc--light">
                                    {t(`profile.partner.available_services.multi_form.banner_upload_help_text`)}
                                  </p>
                                )}
                                <Row gutter={20}>
                                  <Col span={12}>
                                    <Form.Item
                                      className="mb-0"
                                      name="width"
                                      rules={[
                                        {
                                          message: t(`profile.partner.sales_info.invalid_amount`),
                                          pattern: REGEXES.AMOUNT_REGEX,
                                          required: true,
                                          max: 4
                                        },
                                      ]}
                                    >
                                      <QInput
                                        value={typeLocalState?.width ?? 0}
                                        onChange={onChangeInputWithValidation(type, 'width', REGEXES.AMOUNT_REGEX)}
                                        rules={[{ required: true, max: 4 }]}
                                        type="number"
                                        size="large"
                                        readOnly={!!typeLocalState?.adspace}
                                        disabled={!!typeLocalState?.adspace || !hasPermission(permissions, PERMISSIONS.UPDATE_SERVICES)}
                                      />
                                    </Form.Item>
                                    <label htmlFor="width" className="form-label form-label-secondary mt-5 label-required">
                                      {t(`profile.partner.available_services.multi_form.width`)}
                                    </label>
                                  </Col>
                                  <Col span={12}>
                                    <Form.Item
                                      className="mb-0"
                                      name="height"
                                      rules={[
                                        {
                                          message: t(`profile.partner.sales_info.invalid_amount`),
                                          pattern: REGEXES.AMOUNT_REGEX,
                                          required: true,
                                          max: 4
                                        },
                                      ]}
                                    >
                                      <QInput
                                        value={typeLocalState?.height ?? 0}
                                        onChange={onChangeInputWithValidation(type, 'height', REGEXES.AMOUNT_REGEX)}
                                        type="number"
                                        size="large"
                                        readOnly={!!typeLocalState?.adspace}
                                        disabled={!!typeLocalState?.adspace || !hasPermission(permissions, PERMISSIONS.UPDATE_SERVICES)}
                                      />
                                    </Form.Item>
                                    <label htmlFor="height" className="form-label form-label-secondary mt-5 label-required">
                                      {t(`profile.partner.available_services.multi_form.height`)}
                                    </label>
                                  </Col>
                                </Row>
                              </div>
                            )}

                          {fieldsToCheck?.includes(FIELDS_MEDIA_TYPES.AD_TITLE_CHARACTER_LIMIT) && (
                            <div className="qu-modal-media-types-block">
                              <h4 className="form-label mb-5">{t(`profile.partner.available_services.multi_form.ad_title_char_count`)}</h4>
                              <Row gutter={20}>
                                <Col xs={24} sm={24} md={12}>
                                  <Form.Item
                                    className="mb-0"
                                    name="adTitleCharacterLimit"
                                    rules={[
                                      {
                                        message: t(`profile.partner.available_services.multi_form.invalid_number`),
                                        pattern: REGEXES.POSITIVE_NUMBERS_REGEX,
                                      },
                                    ]}
                                  >
                                    <QInput
                                      disabled={!hasPermission(permissions, PERMISSIONS.UPDATE_SERVICES)}
                                      value={typeLocalState?.adTitleCharacterLimit}
                                      onChange={onChangeInputWithValidation(
                                        type,
                                        'adTitleCharacterLimit',
                                        REGEXES.POSITIVE_NUMBERS_REGEX
                                      )}
                                      placeholder={t(`profile.partner.available_services.multi_form.enter_value`)}
                                      type="number"
                                      size="large"
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </div>
                          )}

                          {fieldsToCheck?.includes(FIELDS_MEDIA_TYPES.AD_DESCRIPTION_CHARACTER_LIMIT) && (
                            <div className="qu-modal-media-types-block">
                              <h4 className="form-label mb-5">{t(`profile.partner.available_services.multi_form.ad_desc_char_count`)}</h4>
                              <Row gutter={20}>
                                <Col xs={24} sm={24} md={12}>
                                  <Form.Item
                                    className="mb-0"
                                    name="adDescriptionCharacterLimit"
                                    rules={[
                                      {
                                        message: t(`profile.partner.available_services.multi_form.invalid_number`),
                                        pattern: REGEXES.POSITIVE_NUMBERS_REGEX,
                                      },
                                    ]}
                                  >
                                    <QInput
                                      disabled={!hasPermission(permissions, PERMISSIONS.UPDATE_SERVICES)}
                                      value={typeLocalState?.adDescriptionCharacterLimit}
                                      onChange={onChangeInputWithValidation(
                                        type,
                                        'adDescriptionCharacterLimit',
                                        REGEXES.POSITIVE_NUMBERS_REGEX
                                      )}
                                      placeholder={t(`profile.partner.available_services.multi_form.enter_value`)}
                                      type="number"
                                      size="large"
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </div>
                          )}

                          {fieldsToCheck?.includes(FIELDS_MEDIA_TYPES.MARKETING_METRICS) &&
                            mbPricingModel === PRICING_MODEL.METRICS && (
                              <div className="qu-modal-media-types-block">
                                <h4 className="form-label mb-5">{t(`profile.partner.available_services.multi_form.marketing_metrics`)}</h4>
                                <Row gutter={10}>
                                  <Col xs={24} sm={24} md={8}>
                                    <Form.Item className="mb-0" name="Impression">
                                      <QCheckbox
                                        disabled={!hasPermission(permissions, PERMISSIONS.UPDATE_SERVICES)}
                                        checked={typeLocalState?.marketingMetrics?.includes('Impression')}
                                        className="test-class"
                                        onChange={onChangeCheckboxHandler(type, 'marketingMetrics', 'Impression')}
                                      >
                                        {t(`profile.partner.available_services.multi_form.impression`)}
                                      </QCheckbox>
                                    </Form.Item>
                                  </Col>

                                  <Col xs={24} sm={24} md={8}>
                                    <Form.Item className="mb-0" name="Click">
                                      <QCheckbox
                                        disabled={!hasPermission(permissions, PERMISSIONS.UPDATE_SERVICES)}
                                        checked={typeLocalState?.marketingMetrics?.includes('Click')}
                                        className="test-class"
                                        onChange={onChangeCheckboxHandler(type, 'marketingMetrics', 'Click')}
                                      >
                                        {t(`profile.partner.available_services.multi_form.click`)}
                                      </QCheckbox>
                                    </Form.Item>
                                  </Col>

                                  <Col xs={24} sm={24} md={8}>
                                    <Form.Item className="mb-0" name="Reach">
                                      <QCheckbox
                                        disabled={!hasPermission(permissions, PERMISSIONS.UPDATE_SERVICES)}
                                        checked={typeLocalState?.marketingMetrics?.includes('Reach')}
                                        className="test-class"
                                        onChange={onChangeCheckboxHandler(type, 'marketingMetrics', 'Reach')}
                                      >
                                        {t(`profile.partner.available_services.multi_form.reach`)}
                                      </QCheckbox>
                                    </Form.Item>
                                  </Col>
                                </Row>

                                <Row gutter={10} className="mb-10 mt-10">
                                  {fieldsToCheck?.includes(FIELDS_MEDIA_TYPES.DAILY_IMPRESSION) && (
                                    <Col xs={24} sm={24} md={8}>
                                      <Form.Item
                                        className="mb-0"
                                        name="dailyImpressions"
                                        rules={
                                          typeLocalState?.marketingMetrics?.includes('Impression')
                                            ? [
                                              {
                                                message: t(`profile.partner.available_services.multi_form.invalid_number`),
                                                pattern: REGEXES.POSITIVE_NUMBERS_REGEX,
                                              },
                                            ]
                                            : []
                                        }
                                      >
                                        <QInput
                                          disabled={!typeLocalState?.marketingMetrics?.includes('Impression') || !hasPermission(permissions, PERMISSIONS.UPDATE_SERVICES)}
                                          value={typeLocalState?.dailyImpressions}
                                          onChange={onChangeInputWithValidation(
                                            type,
                                            'dailyImpressions',
                                            REGEXES.POSITIVE_NUMBERS_REGEX
                                          )}
                                          type="number"
                                          placeholder={t(`profile.partner.available_services.multi_form.daily_impressions`)}
                                          size="large"
                                        />
                                      </Form.Item>
                                    </Col>
                                  )}

                                  {fieldsToCheck?.includes(FIELDS_MEDIA_TYPES.DAILY_CLICKS) && (
                                    <Col xs={24} sm={24} md={8}>
                                      <Form.Item
                                        className="mb-0"
                                        name="dailyClicks"
                                        rules={
                                          typeLocalState?.marketingMetrics?.includes('Click')
                                            ? [
                                              {
                                                message: t(`profile.partner.available_services.multi_form.invalid_number`),
                                                pattern: REGEXES.POSITIVE_NUMBERS_REGEX,
                                              },
                                            ]
                                            : []
                                        }
                                      >
                                        <QInput
                                          disabled={!typeLocalState?.marketingMetrics?.includes('Click') || !hasPermission(permissions, PERMISSIONS.UPDATE_SERVICES)}
                                          value={typeLocalState?.dailyClicks}
                                          onChange={onChangeInputWithValidation(
                                            type,
                                            'dailyClicks',
                                            REGEXES.POSITIVE_NUMBERS_REGEX
                                          )}
                                          type="number"
                                          placeholder={t(`profile.partner.available_services.multi_form.daily_clicks`)}
                                          size="large"
                                        />
                                      </Form.Item>
                                    </Col>
                                  )}

                                  {fieldsToCheck?.includes(FIELDS_MEDIA_TYPES.DAILY_REACH) && (
                                    <Col xs={24} sm={24} md={8}>
                                      <Form.Item
                                        className="mb-0"
                                        name="dailyReach"
                                        rules={
                                          typeLocalState?.marketingMetrics?.includes('Reach')
                                            ? [
                                              {
                                                message: t(`profile.partner.available_services.multi_form.invalid_number`),
                                                pattern: REGEXES.POSITIVE_NUMBERS_REGEX,
                                              },
                                            ]
                                            : []
                                        }
                                      >
                                        <QInput
                                          disabled={!typeLocalState?.marketingMetrics?.includes('Reach') || !hasPermission(permissions, PERMISSIONS.UPDATE_SERVICES)}
                                          value={typeLocalState?.dailyReach}
                                          onChange={onChangeInputWithValidation(
                                            type,
                                            'dailyReach',
                                            REGEXES.POSITIVE_NUMBERS_REGEX
                                          )}
                                          type="number"
                                          placeholder={t(`profile.partner.available_services.multi_form.daily_reach`)}
                                          size="large"
                                        />
                                      </Form.Item>
                                    </Col>
                                  )}
                                </Row>

                                <Row gutter={10}>
                                  {fieldsToCheck?.includes(FIELDS_MEDIA_TYPES.CPM) && (
                                    <Col xs={24} sm={24} md={8}>
                                      <Form.Item
                                        className="mb-0"
                                        name="costPerImpression"
                                        rules={
                                          typeLocalState?.marketingMetrics?.includes('Impression')
                                            ? [
                                              {
                                                message: t(`profile.partner.sales_info.invalid_amount`),
                                                pattern: REGEXES.AMOUNT_REGEX_WITH_TWO_DECIMAL,
                                              },
                                            ]
                                            : []
                                        }
                                      >
                                        <QInput
                                          disabled={!typeLocalState?.marketingMetrics?.includes('Impression') || !hasPermission(permissions, PERMISSIONS.UPDATE_SERVICES)}
                                          value={typeLocalState?.costPerImpression}
                                          onChange={onChangeInputWithValidation(
                                            type,
                                            'costPerImpression',
                                            REGEXES.AMOUNT_REGEX_WITH_TWO_DECIMAL
                                          )}
                                          type="number"
                                          placeholder={t(`profile.partner.available_services.multi_form.CPM`)}
                                          size="large"
                                        />
                                      </Form.Item>
                                    </Col>
                                  )}

                                  {fieldsToCheck?.includes(FIELDS_MEDIA_TYPES.CPC) && (
                                    <Col xs={24} sm={24} md={8}>
                                      <Form.Item
                                        className="mb-0"
                                        name="costPerClick"
                                        rules={
                                          typeLocalState?.marketingMetrics?.includes('Click')
                                            ? [
                                              {
                                                message: t(`profile.partner.sales_info.invalid_amount`),
                                                pattern: REGEXES.AMOUNT_REGEX_WITH_TWO_DECIMAL,
                                              },
                                            ]
                                            : []
                                        }
                                      >
                                        <QInput
                                          disabled={!typeLocalState?.marketingMetrics?.includes('Click') || !hasPermission(permissions, PERMISSIONS.UPDATE_SERVICES)}
                                          value={typeLocalState?.costPerClick}
                                          onChange={onChangeInputWithValidation(
                                            type,
                                            'costPerClick',
                                            REGEXES.AMOUNT_REGEX_WITH_TWO_DECIMAL
                                          )}
                                          type="number"
                                          placeholder={t(`profile.partner.available_services.multi_form.CPC`)}
                                          size="large"
                                        />
                                      </Form.Item>
                                    </Col>
                                  )}

                                  {fieldsToCheck?.includes(FIELDS_MEDIA_TYPES.CPR) && (
                                    <Col xs={24} sm={24} md={8}>
                                      <Form.Item
                                        className="mb-0"
                                        name="costPerReach"
                                        rules={
                                          typeLocalState?.marketingMetrics?.includes('Reach')
                                            ? [
                                              {
                                                message: t(`profile.partner.sales_info.invalid_amount`),
                                                pattern: REGEXES.AMOUNT_REGEX_WITH_TWO_DECIMAL,
                                              },
                                            ]
                                            : []
                                        }
                                      >
                                        <QInput
                                          disabled={!typeLocalState?.marketingMetrics?.includes('Reach') || !hasPermission(permissions, PERMISSIONS.UPDATE_SERVICES)}
                                          value={typeLocalState?.costPerReach}
                                          onChange={onChangeInputWithValidation(
                                            type,
                                            'costPerReach',
                                            REGEXES.AMOUNT_REGEX_WITH_TWO_DECIMAL
                                          )}
                                          type="number"
                                          placeholder={t(`profile.partner.available_services.multi_form.CPR`)}
                                          size="large"
                                        />
                                      </Form.Item>
                                    </Col>
                                  )}
                                </Row>
                              </div>
                            )}
                        </Form>
                      </div>
                    </Col>

                    {!isMobile && (
                      <Col xs={24} sm={24} md={12}>
                        <PreviewMediaTypes preview={preview} />
                      </Col>
                    )}
                  </Row>
                </div>
              )
            })}
          </>
        )}

        {!isShowPreview && (
          <div className="qu-modal-media-types-block qu-modal-media-types-footer mt-25">
            <QButton onClick={() => setIsShowPreview(true)} className="qu-button-soft mb-10">
              {t(`profile.partner.available_services.multi_form.show_preview`)}
            </QButton>
            <QButton
              className="mr-5"
              //@ts-ignore
              disabled={forms.some(e => e.hasError) || !hasPermission(permissions, PERMISSIONS.UPDATE_SERVICES)}
              type="primary"
              onClick={() => handleOnFinish()(formData.name)}
            >
              {t(`profile.partner.done`)}
            </QButton>
          </div>
        )}
      </div>

      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {isMobile &&
          isShowPreview &&
          types.map(type => {
            const typeState = selectedSubtypes.find(({ subName }) => subName === type)
            const typeLocalState = forms.find(({ subName }) => subName === type)
            const preview = {
              mbPricingModel,
              name: typeLocalState?.name,
              description: typeLocalState?.description,
              url: typeState?.previewImage,
              avgImpressions: typeLocalState?.avgImpressions,
              duration: typeLocalState?.duration,
              pricePerActivity: typeLocalState?.pricePerActivity,
              priceForDuration: typeLocalState?.priceForDuration,
              priceForImpression: typeLocalState?.priceForImpression,
              pricePerPush: typeLocalState?.pricePerPush,
              minOrderValue: typeLocalState?.minOrderValue,
              subName: typeLocalState?.subName,
              dailyImpressions: typeLocalState?.dailyImpressions,
              CPM: typeLocalState?.costPerImpression
                ? renderRoundedNumber(typeLocalState?.costPerImpression, currency)
                : '',
              dailyClicks: typeLocalState?.dailyClicks,
              CPC: typeLocalState?.costPerClick ? renderRoundedNumber(typeLocalState?.costPerClick, currency) : '',
              dailyReach: typeLocalState?.dailyReach,
              CPR: typeLocalState?.costPerReach ? renderRoundedNumber(typeLocalState?.costPerReach, currency) : '',
              tag: typeLocalState?.adspace,
            }
            return <PreviewMediaTypes preview={preview} />
          })}
        {isMobile && isShowPreview && (
          <QButton onClick={() => setIsShowPreview(false)} className="qu-button-outline" block>
            <IconArrowLeft className="mr-5" />
            {t(`profile.partner.available_services.multi_form.back`)}
          </QButton>
        )}
      </div>

      <Modal
        key={tableKey}
        wrapClassName="qu-modal"
        title={<h3>{t(`profile.partner.available_services.multi_form.are_you_sure`)}</h3>}
        visible={customBannerDeleteModal.visible}
        onOk={() => handleYesNo(true)}
        onCancel={() => handleYesNo(false)}
        footer={false}
      >
        <p className="mb-25">{t(`profile.partner.available_services.multi_form.delete_custom_banner`)}</p>
        <div className="grid-row grid-row--smg grid-row--jce">
          <div className="grid-col grid-col--auto">
            <QButton onClick={() => handleYesNo(true)} type="primary">
              {t(`profile.partner.available_services.multi_form.yes`)}
            </QButton>
          </div>
          <div className="grid-col grid-col--auto">
            <QButton className="qu-button-outline" onClick={() => handleYesNo(false)}>
              {t(`profile.partner.available_services.multi_form.no`)}
            </QButton>
          </div>
        </div>
      </Modal>
    </div>
  )
}
