import React, { ReactElement } from 'react'
import { IconsCommon, Modal, QButton } from 'quantum_components'
import { useSwipeable } from 'react-swipeable'
import { isMobile } from 'react-device-detect'

const closeIcon = <IconsCommon.IconClose />
const arrowIcon = <IconsCommon.IconArrowHalf />

interface IProps {
  modalOpen: boolean
  title: string
  imgUrl: string
  uploadedOn: string
  description: string
  onLeftClick: () => void
  onRightClick: () => void
  onDownload?: () => void
  downloading?: boolean
  onClose: () => void
  showLeftArrow: boolean
  showRightArrow: boolean
}

const GalleryImageModal = ({
  modalOpen = false,
  title,
  imgUrl,
  description,
  uploadedOn,
  onClose,
  onDownload,
  downloading,
  onLeftClick,
  onRightClick,
  showLeftArrow,
  showRightArrow,
}: IProps): ReactElement => {
  const swipeHandlers = useSwipeable({
    onSwipedLeft: onRightClick,
    onSwipedRight: onLeftClick,
  })
  return (
    <Modal
      wrapClassName="qu-modal gallery-modal"
      title={
        <div className="qu-preview-head">
          <h3>{title}</h3>
        </div>
      }
      width={700}
      closeIcon={closeIcon}
      visible={modalOpen}
      onCancel={onClose}
      footer={
        <>
          <p className="description">{description}</p>
          <p className="date">{uploadedOn}</p>
        </>
      }
    >
      <div className="preview-body" {...swipeHandlers}>
        <img className="preview-img" src={imgUrl} alt="img" />
        {onDownload && (
          <QButton
            disabled={downloading}
            loading={downloading}
            onClick={onDownload}
            className="qu-button download"
            type="primary"
          >
            <IconsCommon.IconDownload />
          </QButton>
        )}
        {!isMobile && (
          <div className="arrows">
            {showLeftArrow && (
              <div onClick={onLeftClick} className="arrow arrows-left">
                {arrowIcon}
              </div>
            )}

            {showRightArrow && (
              <div onClick={onRightClick} className="arrow arrows-right">
                {arrowIcon}
              </div>
            )}
          </div>
        )}
      </div>
    </Modal>
  )
}

export default GalleryImageModal
