/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import {
  Menu,
  QInput,
  QMenuItem,
  QPagination,
  QSelect,
  QTable,
  Row,
  IconsCommon,
  BillCardItem,
  Dropdown,
} from 'quantum_components'
import { useSelector } from 'react-redux'
import { Col } from 'antd'
import { SearchOutlined } from '@ant-design/icons'

import useDebounce from 'src/hooks/useDebounce'
import { useReduxDispatch } from 'src/helpers'
import { hasPermission } from 'src/helpers/permission'
import { getFileNameFromUrl } from 'src/utils'
import {
  BRIEF_PAYMENT_STATUS,
  BRIEF_PAYMENT_STATUS_LIST,
  BRIEF_PAYMENT_STATUS_NAMES,
  briefPaymentStatuses,
} from 'src/constants/brief'
import { PERMISSIONS } from 'src/constants/permission'
import { DEFAULT_BILL_TABLE_PARAMS } from 'src/constants/bill'
import { getCurrentUserPermissionActions } from 'src/modules/core/core.selectors'
import * as coreActions from 'src/modules/core/core.actions'

import * as actions from '../bills.actions'
import { getBillList, getBillListLoading, getBillParams, getPages, getTotalBillList } from '../bills.selectors'
import { IBillListElement, ISelectInputOption } from '../bills.types'

import UploadComponent from 'src/components/UploadComponent'

import { ReactComponent as EyeIcon } from 'src/assets/icons/eye-icon.svg'

const BillsList = () => {
  const { i18n } = useTranslation()
  const { t } = useTranslation(['translationSettings', 'translationCommon','translation','translationABrief'])
  const history = useHistory()
  const dispatch = useReduxDispatch()
  const permissions = useSelector(getCurrentUserPermissionActions)
  const billList = useSelector(getBillList)
  const totalBillList = useSelector(getTotalBillList)
  const billListLoading = useSelector(getBillListLoading)
  const params = useSelector(getBillParams)
  const pages = useSelector(getPages)
  const { id: userId } = useParams()

  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 500)

  const tableLoading = {
    spinning: billListLoading,
    indicator: <div className="loader loader-small" />,
  }

  useEffect(() => {
    const newParams = userId
      ? { ...params, userId, search: debouncedSearch, offset: 0, limit: 10 }
      : { ...params, search: debouncedSearch, offset: 0, limit: 10 }
    dispatch(actions.setParamsAndFetch({ ...newParams }))
    dispatch(actions.setCurrentPage(1))

    return () => dispatch(actions.setBillListParams(DEFAULT_BILL_TABLE_PARAMS))
  }, [debouncedSearch])

  const columns = [
    {
      title: t('settings.billings.Product'),
      dataIndex: 'brief',
      key: 'product',
      width: '25%',
      sorter: true,
      render: (brief, { payment }) => {
        return (
          <div className="q-table-product">
            <div className="q-table-product_thumb">
              <div className="q-table-product_thumb">{brief.image && <img src={brief.image} alt={brief.campaignName} />}</div>
            </div>
            <div className="q-table-product_info">
              <h3 className="q-table-product_title">{brief.campaignName || t('unnamed_brief',{ns:"translationABrief"})}</h3>
              <div className="q-table-product_created">{t('created',{ns:"translationABrief"})} {payment.createdAt}</div>
            </div>
          </div>
        )
      },
    },
    {
      title: t('settings.billings.Quotation'),
      dataIndex: 'payment',
      key: 'quotation',
      width: '17%',
      render: ({ quotation }) => {
        return (
          <div onClick={(event: React.MouseEvent<HTMLDivElement>) => event.stopPropagation()}>
            <UploadComponent
              viewOnly
              alternativeFileView
              dragAndDrop={false}
              files={quotation ? [{ url: quotation, name: getFileNameFromUrl(quotation) }] : []}
              disableView={!hasPermission(permissions, PERMISSIONS.CLIENT_BILL_VIEW_DETAILS)}
              onView={ file => {

                window.open(file.url, '_blank')
              }}
            />
          </div>
        )
      },
    },
    {
      title: t('settings.billings.Invoice'),
      dataIndex: 'payment',
      key: 'invoice',
      width: '17%',
      render: ({ invoice }) => {
        return (
          <div onClick={(event: React.MouseEvent<HTMLDivElement>) => event.stopPropagation()}>
            <UploadComponent
              viewOnly
              alternativeFileView
              dragAndDrop={false}
              files={invoice?.map(url => ({ url, name: getFileNameFromUrl(url) })) ?? []}
              disableView={!hasPermission(permissions, PERMISSIONS.CLIENT_BILL_VIEW_DETAILS)}
              onView={async file => {
                const response: any = await dispatch(coreActions.downloadImage(file.url))
                const url = URL.createObjectURL(response.data)

                window.open(url, '_blank')
                URL.revokeObjectURL(url)
              }}
            />
          </div>
        )
      },
    },
    {
      title: t('settings.billings.PO'),
      dataIndex: '',
      key: 'po',
      width: '18%',
      render: (bill: IBillListElement) => {
        return (
          <div onClick={(event: React.MouseEvent<HTMLDivElement>) => event.stopPropagation()}>
            <UploadComponent
              viewOnly
              dragAndDrop={false}
              files={bill.payment.po.files}
              showPreview={hasPermission(permissions, PERMISSIONS.CLIENT_BILL_VIEW_DETAILS)}
              disableView={!hasPermission(permissions, PERMISSIONS.CLIENT_BILL_VIEW_DETAILS)}
              onView={async file => {
                const response: any = await dispatch(coreActions.downloadImage(file.url))
                const url = URL.createObjectURL(response.data)

                window.open(url, '_blank')
                URL.revokeObjectURL(url)
              }}
            />
          </div>
        )
      },
    },
    {
      title: t('settings.billings.Bank Transfer'),
      dataIndex: '',
      key: 'bankTransfer',
      width: '19%',
      render: (bill: IBillListElement) => {
        return (
          <div onClick={(event: React.MouseEvent<HTMLDivElement>) => event.stopPropagation()}>
            <UploadComponent
              viewOnly
              dragAndDrop={false}
              files={bill.payment.bankTransfer.files}
              showPreview={hasPermission(permissions, PERMISSIONS.CLIENT_BILL_VIEW_DETAILS)}
              disableView={!hasPermission(permissions, PERMISSIONS.CLIENT_BILL_VIEW_DETAILS)}
              onView={async file => {
                const response: any = await dispatch(coreActions.downloadImage(file.url))
                const url = URL.createObjectURL(response.data)

                window.open(url, '_blank')
                URL.revokeObjectURL(url)
              }}
            />
          </div>
        )
      },
    },
    {
      title: t('settings.billings.Payment Status'),
      dataIndex: 'payment',
      key: 'paymentStatus',
      width: '19%',
      render: ({ status, briefId, onlinePayment, isPaidByWallet  }, rowData) => {
        const paymentStatusClass = briefPaymentStatuses[status]?.classStyle ?? ''
        const paymentStatusLabel = briefPaymentStatuses[status]?.label ?? ''
        return (
          <div>
            {status ? (
              <span className={`status-label ${paymentStatusClass}`}>{t(paymentStatusLabel,{ns:"translation"})}</span>
            ) : (
              <span>-</span>
            )}
            {status === BRIEF_PAYMENT_STATUS.PAID && (onlinePayment || isPaidByWallet) &&
            <span className="font-size-12 text-truncate tc--light">{t('brief.payment.pending_payout',{ ns: "translation" })}</span>}

            <div onClick={e => e.stopPropagation()}>
              <Dropdown overlay={menu(briefId, rowData.brief.version)} trigger={['click']} placement="bottomRight">
                <div className="q-table_menu">
                  <IconsCommon.IconEllipsisV />
                </div>
              </Dropdown>
            </div>
          </div>
        )
      },
    },
  ]

  const menu = (id: number, version: string) => (
    <Menu className="user-menu-list">
      <QMenuItem key={0} onClick={() => handleView(id, version)} name={t('view',{ns:"translationABrief"})} icon={<EyeIcon />} />
    </Menu>
  )

  const handleChangeSearch = e => {
    setSearch(e.currentTarget.value)
  }

  const handleChangeSelect = (activePaymentStatuses: string[]) => {
    dispatch(actions.setParamsAndFetch({ ...params, offset: 0, paymentStatus: activePaymentStatuses }))
  }

  const handleChangePage = (page, pageSize) => {
    dispatch(actions.setCurrentPage(page))
    dispatch(actions.setParamsAndFetch({ ...params, offset: (page - 1) * pageSize, limit: pageSize }))
  }

  const handleTableChange = (pagination, filters, sorter) => {
    const colKey = {
      product: 'name',
    }
    const sortingParams = {
      direction: sorter.order.slice(0, -3),
      sortField: colKey[sorter.columnKey],
    }

    dispatch(
      actions.setParamsAndFetch({
        ...params,
        ...sortingParams,
      })
    )
  }

  const handleView = (id, version) => {
    //v2
    if(version === "2") {
      history.push(`/briefs/v3/edit/${id}`);
    }
    //v1
    else{
      history.push(`/briefs/view/${id}`);

    }
  }

  const statusOptions: ISelectInputOption[] = BRIEF_PAYMENT_STATUS_LIST.map((opt: BRIEF_PAYMENT_STATUS) => {
    return { label: t(BRIEF_PAYMENT_STATUS_NAMES[opt],{ns:"translation"}), value: opt }
  })

  return (
    <div className="qu-brief-view">
      <div className="qu-brief-list pb-20">
        <Row
          className={`${isMobile ? 'pt-30 pl-15 pr-15' : 'pt-30 mb-15'} brief-list-header`}
          align="middle"
          justify="space-between"
        >
          <Col>
            <h3>{t('settings.billings.title')}</h3>
          </Col>
        </Row>
        <Row className={`${isMobile && 'pb-15'}`} justify={`${isMobile ? 'center' : 'space-between'}`}>
          <Col className="qt-search">
            <QInput
              className={`${isMobile && 'full-width'}`}
              value={search}
              onChange={handleChangeSearch}
              placeholder={t('settings.billings.Search')}
              size="large"
              prefix={<SearchOutlined />}
            />
          </Col>
          {!isMobile && (
            <Col className="qt-filter">
              <QSelect
                showArrow
                maxTagTextLength={8}
                maxTagCount={2}
                value={params.status}
                onChange={handleChangeSelect}
                mode="multiple"
                size="large"
                placeholder={t('settings.billings.Filter by status')}
                options={statusOptions}
              />
            </Col>
          )}
        </Row>
        {isMobile ? (
          <div className="pl-15 pr-15">
            <ul className="qu-request-list">
              {billList.map(bill => {
                return (
                  <BillCardItem
                    key={bill.key}
                    bill={bill}
                    onCardClick={() => handleView(bill.payment.briefId)}
                    onPayClick={() => handleView(bill.payment.briefId)}
                    paymentStatus={briefPaymentStatuses}
                    quotationNode={
                      <div onClick={(event: React.MouseEvent<HTMLDivElement>) => event.stopPropagation()}>
                        <UploadComponent
                          viewOnly
                          alternativeFileView
                          dragAndDrop={false}
                          files={
                            bill.payment.quotation
                              ? [{ url: bill.payment.quotation, name: getFileNameFromUrl(bill.payment.quotation) }]
                              : []
                          }
                          disableView={!hasPermission(permissions, PERMISSIONS.CLIENT_BILL_VIEW_DETAILS)}
                          onView={async file => {
                            const response: any = await dispatch(coreActions.downloadImage(file.url))
                            const url = URL.createObjectURL(response.data)

                            window.open(url, '_blank')
                            URL.revokeObjectURL(url)
                          }}
                        />
                      </div>
                    }
                    bankTransferNode={
                      <div onClick={(event: React.MouseEvent<HTMLDivElement>) => event.stopPropagation()}>
                        <UploadComponent
                          viewOnly
                          dragAndDrop={false}
                          files={bill.payment.bankTransfer.files}
                          showPreview={hasPermission(permissions, PERMISSIONS.CLIENT_BILL_VIEW_DETAILS)}
                          disableView={!hasPermission(permissions, PERMISSIONS.CLIENT_BILL_VIEW_DETAILS)}
                          onView={async file => {
                            const response: any = await dispatch(coreActions.downloadImage(file.url))
                            const url = URL.createObjectURL(response.data)

                            window.open(url, '_blank')
                            URL.revokeObjectURL(url)
                          }}
                        />
                      </div>
                    }
                    poNode={
                      <div onClick={(event: React.MouseEvent<HTMLDivElement>) => event.stopPropagation()}>
                        <UploadComponent
                          viewOnly
                          dragAndDrop={false}
                          files={bill.payment.po.files}
                          showPreview={hasPermission(permissions, PERMISSIONS.CLIENT_BILL_VIEW_DETAILS)}
                          disableView={!hasPermission(permissions, PERMISSIONS.CLIENT_BILL_VIEW_DETAILS)}
                          onView={async file => {
                            const response: any = await dispatch(coreActions.downloadImage(file.url))
                            const url = URL.createObjectURL(response.data)

                            window.open(url, '_blank')
                            URL.revokeObjectURL(url)
                          }}
                        />
                      </div>
                    }
                    invoiceNode={
                      <div onClick={(event: React.MouseEvent<HTMLDivElement>) => event.stopPropagation()}>
                        <UploadComponent
                          viewOnly
                          alternativeFileView
                          dragAndDrop={false}
                          files={bill.payment.invoice?.map(url => ({ url, name: getFileNameFromUrl(url) })) ?? []}
                          disableView={!hasPermission(permissions, PERMISSIONS.CLIENT_BILL_VIEW_DETAILS)}
                          onView={async file => {
                            const response: any = await dispatch(coreActions.downloadImage(file.url))
                            const url = URL.createObjectURL(response.data)

                            window.open(url, '_blank')
                            URL.revokeObjectURL(url)
                          }}
                        />
                      </div>
                    }
                  />
                )
              })}
            </ul>
          </div>
        ) : (
          <div className="pt-15 pb-30">
            <QTable
              onChange={handleTableChange}
              sortDirections={['ascend', 'descend', 'ascend']}
              loading={tableLoading}
              columns={columns}
              dataSource={billList}
              scroll={{ x: 990 }}
              rowClassName="q-table cursorPointer"
            />
          </div>
        )}

        <Row justify={`${isMobile ? 'center' : 'end'}`} className={`${isMobile && 'pt-15 mb-30'}`}>
          <QPagination
            className='force-ltr'
            size="default"
            defaultCurrent={1}
            current={pages.currentPage}
            total={totalBillList}
            onChange={handleChangePage}
          />
        </Row>
      </div>
    </div>
  )
}

export default BillsList
