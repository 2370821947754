import React, { Fragment, useState } from 'react'
import { useSelector } from 'react-redux'

import { TABS } from '../../profile.types'
import { PERMISSIONS } from 'src/constants/permission'
import { hasPermission } from 'src/helpers/permission'
import { getCurrentUserPermissionActions } from 'src/modules/core/core.selectors'

import SwitchProfiles from '../components/SwitchClientProfileInfo'
import ProfileClientContainer from '../ProfileClient.container'
import { BriefListContainer } from 'src/modules/brief'
import { BillsListContainer } from 'src/modules/bills'

const ProfilePage = () => {
  const permissions = useSelector(getCurrentUserPermissionActions)

  const [activeTab, setActiveTab] = useState(TABS.PROFILE)
  const changeSwitch = (value: any) => setActiveTab(value.target.value)
  return (
    <Fragment>
      <SwitchProfiles onChange={changeSwitch} activeTab={activeTab} />
      {activeTab === TABS.PROFILE && <ProfileClientContainer />}
      {activeTab === TABS.BRIEFS && <BriefListContainer />}
      {activeTab === TABS.BILLS && hasPermission(permissions, PERMISSIONS.CLIENT_BILLS_ALL) && <BillsListContainer />}
    </Fragment>
  )
}

export default ProfilePage
