import React from 'react'
import { useHistory } from 'react-router-dom'
import { QButton } from 'quantum_components'

import { PATHS } from 'src/constants'

import { ReactComponent as ImageCheck } from '../../../../assets/icons/check-icon.svg'
import { useTranslation } from 'react-i18next'
import { IBriefResponse } from '../../brief.types'
import { BRIEF_STATUS } from 'src/constants/brief'

interface IProps {
  approvedUser: boolean
  briefDetails: IBriefResponse
}

export default function SubmittedSuccessfully({ approvedUser, briefDetails }: IProps) {
  const { t } = useTranslation(['translationABrief'])
  const history = useHistory()
  const handleFinish = () => {
    if (window.location.pathname.includes('/invitedClients/briefs')) history.push(PATHS.INVITED_CLIENTS_BRIEFS)
    else history.push(PATHS.BRIEF)
  }

  const headerText = briefDetails?.status === BRIEF_STATUS.PENDING ? t('Campaign Submitted Successfully before payment!') : approvedUser ? t(`campaign_submitted`) : t(`campaign_created`)

  return (
    <div className="brief-step-success">
      <ImageCheck />
      <h1>{headerText}</h1>
      <QButton className="w-180" type="primary" onClick={handleFinish}>
        {t('okay')}
      </QButton>
    </div>
  )
}
