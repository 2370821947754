import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { AxiosResponse } from 'axios'
import { upload } from '../core/core.actions'
import IAction from 'src/interfaces/IAction'

import * as CONSTANTS from './ageGroups.constants'
import { STATUS_CODES } from '../../constants'
import { requestHttp, urls } from 'src/api'
import { IAgeGroup } from './ageGroups.types'
import { getResponseErrorMessage } from 'src/helpers'
import { UPLOAD_ENTITY_KEYS } from '../../constants/upload'

export const ageGroupsListRequest = (): IAction => ({
  type: CONSTANTS.FETCH_AGE_GROUPS_REQUEST,
})

export const ageGroupsListSuccess = (ageGroupsList: any): IAction => ({
  type: CONSTANTS.FETCH_AGE_GROUPS_SUCCESS,
  payload: ageGroupsList,
})

export const ageGroupsListFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_AGE_GROUPS_FAILURE,
  error,
})

export const createAgeGroupRequest = (): IAction => ({
  type: CONSTANTS.CREATE_AGE_GROUP_REQUEST,
})

export const createAgeGroupSuccess = (): IAction => ({
  type: CONSTANTS.CREATE_AGE_GROUP_SUCCESS,
})

export const createAgeGroupFailure = (error: string): IAction => ({
  type: CONSTANTS.CREATE_AGE_GROUP_FAILURE,
  error,
})

export const editAgeGroupRequest = (): IAction => ({
  type: CONSTANTS.EDIT_AGE_GROUP_REQUEST,
})

export const editAgeGroupSuccess = (group: IAgeGroup): IAction => ({
  type: CONSTANTS.EDIT_AGE_GROUP_SUCCESS,
  payload: { group },
})

export const editAgeGroupFailure = (error: string): IAction => ({
  type: CONSTANTS.EDIT_AGE_GROUP_FAILURE,
  error,
})

export const deleteAgeGroupRequest = (): IAction => ({
  type: CONSTANTS.DELETE_AGE_GROUP_REQUEST,
})

export const deleteAgeGroupSuccess = (): IAction => ({
  type: CONSTANTS.DELETE_AGE_GROUP_SUCCESS,
})

export const deleteAgeGroupFailure = (error: string): IAction => ({
  type: CONSTANTS.DELETE_AGE_GROUP_FAILURE,
  error,
})

export const imageUploadRequest = (): IAction => ({
  type: CONSTANTS.IMAGE_UPLOAD_REQUEST,
})

export const imageUploadSuccess = (src: string): IAction => ({
  type: CONSTANTS.IMAGE_UPLOAD_SUCCESS,
  payload: { src },
})

export const imageUploadFailure = (error: string): IAction => ({
  type: CONSTANTS.IMAGE_UPLOAD_FAILURE,
  error,
})

export const imageRemoveRequest = (): IAction => ({
  type: CONSTANTS.IMAGE_REMOVE_REQUEST,
})

export const imageRemoveSuccess = (): IAction => ({
  type: CONSTANTS.IMAGE_REMOVE_SUCCESS,
})

export const imageRemoveFailure = (error: string): IAction => ({
  type: CONSTANTS.IMAGE_REMOVE_FAILURE,
  error,
})

export const fetchAgeGroupsList = (): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse> => {
  try {
    dispatch(ageGroupsListRequest())

    const response = await requestHttp.get(urls.getAgeGroupsListUrl())
    const { content } = response.data.data

    dispatch(ageGroupsListSuccess(content))
    return response
  } catch (error) {
    dispatch(ageGroupsListFailure(getResponseErrorMessage(error)))
    return error
  }
}

export const createAgeGroup = (group: IAgeGroup, files: File[]): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    dispatch(createAgeGroupRequest())

    const response = await requestHttp.post(urls.getCreateAgeGroupUrl(), group)

    if (response.status === 201) {
      const newGroup = response.data.data.content

      if (files.length > 0) {
        await dispatch(uploadGroupImage(files, newGroup.id))
      }

      dispatch(createAgeGroupSuccess())
    }
  } catch (error) {
    dispatch(createAgeGroupFailure(getResponseErrorMessage(error)))
  }
}

export const editAgeGroup = (group: IAgeGroup): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    dispatch(editAgeGroupRequest())

    const response = await requestHttp.put(urls.getEditAgeGroupUrl(), group)

    if (response.status === STATUS_CODES.BASE_SUCCESS) {
      const updatedGroup = response.data.data.content
      dispatch(editAgeGroupSuccess(updatedGroup))
    }
  } catch (error) {
    dispatch(editAgeGroupFailure(getResponseErrorMessage(error)))
  }
}

export const deleteAgeGroup = (id: number): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse> => {
  try {
    dispatch(deleteAgeGroupRequest())
    const res = await requestHttp.delete(urls.getDeleteAgeGroupUrl(id))

    dispatch(deleteAgeGroupSuccess())
    return res
  } catch (error) {
    dispatch(deleteAgeGroupFailure(getResponseErrorMessage(error)))
    return error
  }
}

export const uploadGroupImage = (file: File[], ageGroupId: number): ThunkAction<any, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<any> => {
  try {
    dispatch(imageUploadRequest())

    const uploadUrl = urls.getAgeGroupImageUploadUrl()
    const response = await upload(uploadUrl, file, ageGroupId, undefined, UPLOAD_ENTITY_KEYS.AGE_GROUP_ID)

    if (response.status === 201) {
      const { content } = response.data.data

      dispatch(imageUploadSuccess(content[0]))
      return content[0]
    }
  } catch (error) {
    dispatch(imageUploadFailure(error))
  }
}

export const removeGroupImage = (id: number): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse> => {
  try {
    dispatch(imageRemoveRequest())

    const response = await requestHttp.delete(urls.getAgeGroupImage(id))

    if (response.status === 201) {
      dispatch(imageRemoveSuccess())
    }

    return response
  } catch (error) {
    dispatch(imageRemoveFailure(error))
    return error
  }
}
