import React, { useEffect, useState } from 'react'
import {
  Row,
  Col,
  QPieChart,
  QBarChartVertical,
  QBarChartSimple,
  QPagination,
  QSelect,
  IconsCommon,
  QButton,
  Empty,
} from 'quantum_components'

import { getScreenPagePdf } from 'src/utils/getScreenPage'
import { CAMPAIGN_PARTNERS, CAMPAIGN_PARTNERS_NAMES, CAMPAIGN_PRINT_SURVEY } from 'src/constants/campaign'
import { useDispatch, useSelector } from 'react-redux'
import { actions } from '../../../campaign.module'
import { useParams } from 'react-router-dom'
import {
  getCampaignAnalyticsInfo,
  getClientSurveysList,
  getClientSurveysListLoading,
  getClientSurveysPages,
  getClientSurveysParams,
  getTotalClientSurveysList,
} from '../../../campaign.selectors'
import { ICampaignPartner, IChartData, IClientSurvey, IClientSurveyAnswer } from '../../../campaign.types'
import { CLIENT_SURVEY_PER_PAGE, SURVEY_CHART_TYPE } from 'src/constants/survey'
import SpinnerComponent from 'src/components/Spinner/SpinnerComponent'

const ClientSurveys = () => {
  const dispatch = useDispatch()
  const { id } = useParams<{id: any}>()

  const params = useSelector(getClientSurveysParams)
  const pages = useSelector(getClientSurveysPages)
  const analyticsInfo = useSelector(getCampaignAnalyticsInfo)
  const surveyQuestions = useSelector(getClientSurveysList)
  const total = useSelector(getTotalClientSurveysList)
  const loading = useSelector(getClientSurveysListLoading)

  const [generatingPdf, setGeneratingPdf] = useState<boolean>(false)
  const [partnerIds, setPartnerIds] = useState<any[]>([])

  const partners = analyticsInfo ? analyticsInfo.partners : []

  useEffect(() => {
    dispatch(
      actions.setParamsAndFetchSurveys(id, {
        ...params,
        offset: 0,
        limit: CLIENT_SURVEY_PER_PAGE,
        partnerIds: partnerIds,
      })
    )
    dispatch(actions.setClientSurveysCurrentPage(1))
  }, [partnerIds])

  const handleDownload = async () => {
    setGeneratingPdf(true)

    const markup = document.getElementById(CAMPAIGN_PRINT_SURVEY)
    if (markup) {
      const pdf = await getScreenPagePdf(markup)
      pdf.save('Campaign Report.pdf')
    }

    setGeneratingPdf(false)
  }

  const handleChangePage = async (page: number, pageSize: number) => {
    await dispatch(
      actions.setParamsAndFetchSurveys(id, {
        ...params,
        offset: (page - 1) * pageSize,
        limit: pageSize,
        partnerIds: partnerIds,
      })
    )
    await dispatch(actions.setClientSurveysCurrentPage(page))
  }

  const renderChart = (chartType: SURVEY_CHART_TYPE, data: IChartData[]) => {
    if (chartType === SURVEY_CHART_TYPE.PIE) {
      return <QPieChart className="h-300" data={data} />
    }

    if (chartType === SURVEY_CHART_TYPE.VERTICAL) {
      return <QBarChartSimple className="h-300" data={data} />
    }

    if (chartType === SURVEY_CHART_TYPE.HORIZONTAL) {
      return <QBarChartVertical className="h-300" data={data} />
    }
  }

  const handlePartner = (partnerIds: any[]) => {
    setPartnerIds(partnerIds)
  }

  const partnerOptions = partners.map((partner: ICampaignPartner) => {
    return { label: partner.companyName, value: partner.id }
  })

  return (
    <div className="surveys">
      <Row gutter={20} className="mb-20" justify="space-between">
        <Col xs={24} sm={8}>
          <QSelect
            mode="multiple"
            maxTagCount={1}
            maxTagTextLength={8}
            style={{ width: 200 }}
            showArrow
            placeholder="Select Partner"
            options={[
              { label: CAMPAIGN_PARTNERS_NAMES[CAMPAIGN_PARTNERS.ALL_PARTNERS], value: CAMPAIGN_PARTNERS.ALL_PARTNERS },
              ...partnerOptions,
            ]}
            onChange={handlePartner}
            size="large"
          />
        </Col>
        <Col xs={24} sm={8}>
          <QButton
            loading={generatingPdf}
            disabled={generatingPdf}
            onClick={handleDownload}
            block
            className="qu-button-soft justify-between"
            data-html2canvas-ignore
          >
            Download Report <IconsCommon.IconDownload />
          </QButton>
        </Col>
      </Row>
      {loading ? (
        <SpinnerComponent />
      ) : !surveyQuestions.length ? (
        <div className="qu-chart-empty h-300">
          <Empty description="There are no surveys for this Partner" />
        </div>
      ) : (
        <Row gutter={20} id={CAMPAIGN_PRINT_SURVEY}>
          {surveyQuestions.map((question: IClientSurvey, index: number) => {
            const answers = question.sumAnswers
              .map((a: IClientSurveyAnswer) => {
                return { name: a.title, value: a.sumQuantity } as IChartData
              })
              .filter((a: IChartData) => !!a.value)
            return (
              <Col key={index} xs={24} sm={8}>
                <div className="qu-chart-card mb-20">
                  <div className="card-header">
                    <h3>{question.surveyTitle}</h3>
                  </div>
                  <div className="card-body">
                    <h3 className="qu-chart-label mb-10">{question.title}</h3>
                    {!answers.length ? (
                      <div className="qu-chart-empty h-300">
                        <Empty />
                      </div>
                    ) : (
                      renderChart(question.chartType, answers)
                    )}
                  </div>
                </div>
              </Col>
            )
          })}
        </Row>
      )}
      <div className="qt-footer">
        <QPagination
          size="small"
          defaultCurrent={1}
          current={pages.currentPage}
          total={total}
          defaultPageSize={CLIENT_SURVEY_PER_PAGE}
          onChange={handleChangePage}
        />
      </div>
    </div>
  )
}

export default ClientSurveys
