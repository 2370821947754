import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { AxiosResponse } from 'axios'

import { FORMATS } from 'src/constants'
import IAction from 'src/interfaces/IAction'
import { requestHttp, urls } from 'src/api'
import { IBriefListElement, IBriefListResponse, IBriefTableParams, IDeleteBriefResponse } from './draftBriefs.types'
import * as CONSTANTS from './draftBriefs.constants'
import { getResponseErrorMessage } from 'src/helpers'
import { BRIEF_STEPS, BRIEF_TYPES, DISTRIBUTION_TYPE, SERVICE_NAMES_FOR_TABLE } from '../../../constants/brief'
import { STATUS_CODES } from '../../../constants'
import downloadZipFile from 'src/helpers/downloadZipFile'
import moment from 'moment'
import first from 'lodash/first'

export const briefListRequest = (): IAction => ({
  type: CONSTANTS.FETCH_BRIEFS_REQUEST,
})

export const briefListSuccess = (briefList: { results: IBriefListElement[]; total: number }): IAction => ({
  type: CONSTANTS.FETCH_BRIEFS_SUCCESS,
  payload: { briefList },
})

export const briefListFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_BRIEFS_FAILURE,
  error,
})

export const setBriefListParams = (params: IBriefTableParams): IAction => ({
  type: CONSTANTS.SET_BRIEF_LIST_PARAMS,
  payload: { params },
})

export const setCurrentPage = (page: number): IAction => ({
  type: CONSTANTS.SET_CURRENT_PAGE,
  payload: { page },
})

export const fetchBriefList = (
  params: IBriefTableParams
): ThunkAction<Promise<AxiosResponse<IBriefListResponse>>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse<IBriefListResponse>> => {
  try {
    dispatch(briefListRequest())
    const response = await requestHttp.get<IBriefListResponse>(urls.getInvitedClientsBriefListUrl(), { params })
    const {
      content: { briefs, total },
    } = response.data.data

    const briefList = briefs.map(brief => {
      const productImage = first(brief.productImages) ? first(brief.productImages) : first(brief.allImages)?.src

      return {
        key: brief.id,
        thumb: productImage,
        exclusivePartnerCompanyName: brief.exclusivePartner.companyName,
        product: {
          productFirstImage: productImage,
          title: brief.name,
          created: moment(brief.createdAt).format(FORMATS.DATE_LL_FORMAT),
          isExistCouponCode: brief.isExistCouponCode,
          discountText: brief.discountText,
        },
        services: brief.briefTypes
          ? brief.briefTypes
              .sort()
              .reverse()
              .map((elem: string) => {
                const briefTitle =
                  elem === BRIEF_TYPES.MEDIA_BUYING
                    ? SERVICE_NAMES_FOR_TABLE.MEDIA
                    : brief.distributionItem === DISTRIBUTION_TYPE.PRINTED_INSERT
                    ? SERVICE_NAMES_FOR_TABLE.INSERT
                    : SERVICE_NAMES_FOR_TABLE.PRODUCT
                return {
                  title: briefTitle,
                  productStartDate: brief.briefStartDate
                    ? moment(brief.briefStartDate).format(FORMATS.DATE_LL_FORMAT)
                    : '',
                  mediaStartDate: brief.mediaStartDate
                    ? moment(brief.mediaStartDate).format(FORMATS.DATE_LL_FORMAT)
                    : '',
                  productDuration: brief.duration,
                  mediaDuration: brief.mediaDuration,
                  quantity: brief.quantity,
                }
              })
          : [],
        campaign: brief.campaign,
        status: { value: brief.status, id: brief.id },
        owner: brief.owner,
      } as IBriefListElement
    })

    dispatch(briefListSuccess({ results: briefList, total: total }))
    return response
  } catch (error) {
    dispatch(briefListFailure(getResponseErrorMessage(error)))
    return error
  }
}

export const setParamsAndFetch = (params: IBriefTableParams): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  dispatch(setBriefListParams(params))
  dispatch(fetchBriefList(params))
}

export const deleteBrief = (
  id: number,
  params: IBriefTableParams
): ThunkAction<Promise<AxiosResponse<IDeleteBriefResponse>>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse<IDeleteBriefResponse>> => {
  try {
    dispatch(deleteBriefRequest())
    const response = await requestHttp.delete<IDeleteBriefResponse>(urls.getDeleteBriefUrl(id))
    dispatch(deleteBriefSuccess())

    if (response.status === STATUS_CODES.BASE_SUCCESS) {
      dispatch(fetchBriefList(params))
    }

    return response
  } catch (error) {
    dispatch(deleteBriefFailure(getResponseErrorMessage(error)))
    return error
  }
}

export const deleteBriefRequest = (): IAction => ({ type: CONSTANTS.DELETE_BRIEF_REQUEST })
export const deleteBriefSuccess = (): IAction => ({ type: CONSTANTS.DELETE_BRIEF_SUCCESS })
export const deleteBriefFailure = (error: string): IAction => ({ type: CONSTANTS.DELETE_BRIEF_FAILURE, error })

export const fetchDownloadMedia = (briefId: number): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    dispatch(fetchDownloadMediaRequest())
    const response = await requestHttp.get<any>(urls.getDownloadMediaUrl(briefId), {
      skipDownloadProgress: false,
      responseType: 'blob',
    })
    if (response.status === STATUS_CODES.BASE_SUCCESS) {
      downloadZipFile(response)
      dispatch(fetchDownloadMediaSuccess())
    }
  } catch (error) {
    dispatch(fetchDownloadMediaFailure(getResponseErrorMessage(error)))
  }
}

export const fetchDownloadMediaRequest = (): IAction => ({ type: CONSTANTS.FETCH_DOWNLOAD_MEDIA_REQUEST })
export const fetchDownloadMediaSuccess = (): IAction => ({ type: CONSTANTS.FETCH_DOWNLOAD_MEDIA_SUCCESS })
export const fetchDownloadMediaFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_DOWNLOAD_MEDIA_FAILURE,
  error,
})

export const setCurrentStep = (step: BRIEF_STEPS): IAction => ({
  type: CONSTANTS.SET_CURRENT_STEP,
  payload: { step },
})

export const setNeedCheckLastStep = (check: boolean): IAction => ({
  type: CONSTANTS.SET_NEED_CHECK_LAST_STEP,
  payload: { check },
})
