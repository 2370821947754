export const MODULE_NAME = 'wallet'

/* actions */

export const RESET_WALLET_STATE = `${MODULE_NAME}/RESET_WALLET_STATE`

export const FETCH_WALLET_REQUEST = `${MODULE_NAME}/FETCH_WALLET_REQUEST`
export const FETCH_WALLET_SUCCESS = `${MODULE_NAME}/FETCH_WALLET_SUCCESS`
export const FETCH_WALLET_FAILURE = `${MODULE_NAME}/FETCH_WALLET_FAILURE`

export const SET_CURRENT_PAGE = `${MODULE_NAME}/SET_CURRENT_PAGE`

export const FETCH_PAYOUTS_REQUEST = `${MODULE_NAME}/FETCH_PAYOUTS_REQUEST`
export const FETCH_PAYOUTS_SUCCESS = `${MODULE_NAME}/FETCH_PAYOUTS_SUCCESS`
export const FETCH_PAYOUTS_FAILURE = `${MODULE_NAME}/FETCH_PAYOUTS_FAILURE`

export const SET_PAYOUTS_CURRENT_PAGE = `${MODULE_NAME}/SET_PAYOUTS_CURRENT_PAGE`

export const PAYOUTS_LOADING_REQUEST = `${MODULE_NAME}/PAYOUTS_LOADING_REQUEST`
export const PAYOUTS_LOADING_SUCCESS = `${MODULE_NAME}/PAYOUTS_LOADING_SUCCESS`
