import { IState } from "./calendar.types";
import * as CONSTANTS from "./calendar.constants";

const initialState: IState = {
  data: [],
  loading: false,
  services: [],
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case CONSTANTS.FETCH_CALENDAR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CONSTANTS.FETCH_CALENDAR_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case CONSTANTS.FETCH_CALENDAR_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CONSTANTS.FETCH_CALENDAR_SERVICES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CONSTANTS.FETCH_CALENDAR_SERVICES_SUCCESS:
      return {
        ...state,
        services: action.payload,
        loading: false,
      };
    case CONSTANTS.FETCH_CALENDAR_SERVICES_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
