import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useReduxDispatch } from '../../../helpers'
import moment from 'moment'
import {
  getIsDiscountCodesListLoading,
  getDiscountCodesList,
  getDiscountCodesTotal,
  getPages,
} from '../discountCodes.selectors'
import { IDeleteModalState, IViewInvitedClientsModalState } from '../discountCodes.types'
import useDebounce from '../../../hooks/useDebounce'
import * as actions from '../discountCodes.actions'

import { Input, Tooltip, Select, InputNumber } from 'antd'
import {
  Modal,
  Form,
  Row,
  Col,
  QButton,
  QTable,
  QInput,
  QSelect,
  QPagination,
  QTextArea,
  QRangePicker,
  IconsCommon,
  qnotification,
  Switch,
} from 'quantum_components'
import NOTIFICATION_TYPES from '../../../constants/notificationTypes'
import { SearchOutlined, PlusOutlined, EyeTwoTone, EditTwoTone, DeleteTwoTone } from '@ant-design/icons'
import { IOptionForSelect } from '../../brief/brief.types'
import { validateMessages } from '../../../helpers/validationMessage'
import { getCurrentUserPermissionActions, getCurrency } from '../../core/core.selectors'
import { hasPermission } from '../../../helpers/permission'
import { PERMISSIONS } from '../../../constants/permission'
import { FORMATS } from 'src/constants'
import { getIsoDate } from 'src/helpers/datesHelper'
import {
  DISCOUNT_CODE_STATUS,
  discountCodesStatusesLabel,
  discountCodeUsersStatusesLabel,
  DISCOUNT_CODE_USERS_STATUS,
} from '../../../constants/discountCodes'
const closeIcon = <IconsCommon.IconClose />

const DiscountCodesList = () => {
  const { i18n } = useTranslation()
  const { t } = useTranslation(['translationDiscounts', 'translationCommon'])
  const [form] = Form.useForm()
  const { Option } = Select

  const dispatch = useReduxDispatch()
  const discountCodesList = useSelector(getDiscountCodesList)
  const discountCodesTotal = useSelector(getDiscountCodesTotal)
  const discountCodesListIsLoading = useSelector(getIsDiscountCodesListLoading)
  const pages = useSelector(getPages)
  const permissions = useSelector(getCurrentUserPermissionActions)
  const currency = useSelector(getCurrency)

  const [search, setSearch] = useState('')
  const [currentSelectedCurrency, setCurrentSelectedCurrency] = useState('')
  const [discountSwitch, setDiscountSwitch] = useState(false)
  const [discountCodeId, setDiscountCodeId] = useState<number>(0)
  const [editModal, setEditModal] = useState<boolean>(false)
  const [clientsList, setClientsList] = useState<[]>([])
  const [statusFilter, setStatusFilter] = useState<string[]>([])
  const [deleteModal, setDeleteModal] = useState<IDeleteModalState>({
    visible: false,
    id: 0,
    discountCode: '',
  })
  const [currencyChangeModal, setCurrencyChangeModal] = useState({
    visible: false,
    currency: '',
    currencyType: '',
    data: {},
  })
  const [params, setParams] = useState({
    currentPage: 1,
    limit: 10,
    offset: 0,
    direction: 'desc',
    sortField: 'createdAt',
  })
  const [viewInvitedClientsModal, setViewInvitedClientsModal] = useState<IViewInvitedClientsModalState>({
    visible: false,
    discountCode: {},
    invitedClients: {},
    isLoading: false,
  })
  const [invitedClientsParams, setInvitedClientsParams] = useState({
    currentPage: 1,
    offset: 0,
    limit: 10,
    status: '',
  })
  const [selectedStatus, setSelectedStatus] = useState(null)

  const debouncedSearch = useDebounce(search, 500)
  const debouncedStatusFilter = useDebounce(statusFilter, 500)

  const fetchDiscountCodes = (queryParams = params) => {
    dispatch(
      actions.fetchDiscountCodesList({
        ...queryParams,
        search: debouncedSearch,
        status: debouncedStatusFilter,
      })
    )
  }

  const fetchDiscountCodesForFilters = (queryParams = params) => {
    dispatch(
      actions.fetchDiscountCodesList({
        ...queryParams,
        currentPage: 1,
        limit: 10,
        offset: 0,
        search: debouncedSearch,
        status: debouncedStatusFilter,
      })
    )
  }

  const handleChangeSelect = (status: string[]) => {
    dispatch(actions.setCurrentPage(1))
    setStatusFilter(status)
  }

  useEffect(() => {
    ; (async () => {
      if (hasPermission(permissions, PERMISSIONS.DISCOUNT_CODES_CREATE)) {
        let clientsListing: any = await dispatch(actions.fetchClientsList())
        setClientsList(clientsListing)
      }
    })()
  }, [])

  useEffect(() => {
    fetchDiscountCodesForFilters()
    dispatch(actions.setCurrentPage(1))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch, debouncedStatusFilter])

  // @ts-ignore
  const handleTableChange = (pagination, filters, sorter) => {
    const sortingParams = {
      direction: sorter.order.slice(0, -3),
      sortField: sorter.columnKey,
    }
    const newParams = {
      ...params,
      ...sortingParams,
    }
    setParams(newParams)
    fetchDiscountCodes(newParams)
  }

  const handleChangeSearch = (event: any) => {
    setSearch(event.currentTarget.value)
  }

  const handleChangePage = (page: number, pageSize: number) => {
    dispatch(actions.setCurrentPage(page))

    dispatch(
      actions.fetchDiscountCodesList({
        ...params,
        offset: (page - 1) * params.limit,
        currentPage: page,
        search: debouncedSearch,
        limit: pageSize,
        status: debouncedStatusFilter,
      })
    )

    setParams({
      ...params,
      offset: (page - 1) * params.limit,
      currentPage: page,
      limit: pageSize,
    })
  }

  const handleChangeSwitch = (e: any) => {
    setDiscountSwitch(e)
  }

  // add/edit discount code function which opens the modal and fill the values if edit button hits
  const handleEditModalOpen = (record: any = null) => {
    if (record) {
      if (record.status === 'EXPIRED') {
        qnotification({
          type: NOTIFICATION_TYPES.ERROR,
          message: 'Error',
          description: 'This discount code has expired and cannot be updated',
        })
      } else {
        setCurrencyChangeModal({
          ...currencyChangeModal,
          currency: record.currency,
          currencyType: record.type,
        })
        setDiscountCodeId(record.id)
        form.setFieldsValue({
          id: record.id,
          discountCode: record.discountCode,
          description: record.description,
          discountValue: {
            type: record.type,
            value: record.value,
          },
          clientIds: record.clientIds,
          inviteOnly: record.inviteOnly,
          status: record.status,
        })
        if (record.discountStartDate) {
          form.setFieldsValue({
            duration: [moment(record.discountStartDate), moment(record.discountEndDate)],
          })
        }
        handleChangeSwitch(record.inviteOnly)
        setEditModal(true)
      }
    } else {
      form.resetFields()
      form.setFieldsValue({
        status: 'ACTIVE',
      })
      handleChangeSwitch(false)
      setEditModal(true)
    }
  }

  const handleEditModalClose = async () => {
    await setEditModal(false)
    form.resetFields()
    form.setFieldsValue({
      status: 'ACTIVE',
    })
    setDiscountCodeId(0)
    handleChangeSwitch(false)
    setCurrentSelectedCurrency('')
  }

  // call the api function with the new/existing discount code data
  const handleOnFinish = async (data: any, currencyChangeFlag: any = null) => {
    if (discountCodeId > 0) {
      if (currencyChangeFlag != null) {
        data['changeCurrency'] = currencyChangeFlag
      }
      data['currency'] = currencyChangeModal.currency
      let status: any = await dispatch(actions.updateDiscountCode(data, discountCodeId))
      if (status == 200) {
        fetchDiscountCodes()
        handleEditModalClose()
        setCurrencyChangeModal({ visible: false, currency: '', currencyType: '', data: {} })
      }
    } else {
      let status: any = await dispatch(actions.createDiscountCode(data))
      if (status == 201) {
        fetchDiscountCodes()
        handleEditModalClose()
      }
    }
  }

  // handle the validations on submit the create/update discount code and if everything fines then call the handleOnFinish function for sending the data to the backend
  const onCheck = async () => {
    try {
      const values = await form.validateFields()

      let newDiscountCode: any = {
        discountCode: values.discountCode,
        description: values.description,
        value: values.discountValue.value,
        type: values.discountValue.type,
        inviteOnly: values.inviteOnly,
        status: values.status,
        clientIds: values.clientIds,
      }
      if (values.duration) {
        const discountStartDate = values.duration && getIsoDate(values.duration[0].startOf('day'))
        const discountEndDate = values.duration && getIsoDate(values.duration[1].endOf('day'))
        newDiscountCode['discountStartDate'] = discountStartDate
        newDiscountCode['discountEndDate'] = discountEndDate
      }
      if (
        discountCodeId > 0 &&
        currencyChangeModal.currency != currency &&
        currencyChangeModal.currencyType === 'FIXED'
      ) {
        setCurrencyChangeModal({
          ...currencyChangeModal,
          visible: true,
          data: newDiscountCode,
        })
      } else {
        handleOnFinish(newDiscountCode)
      }
    } catch (error) {
      return error
    }
  }

  // open the delete discount code modal
  const handleDeleteClick = (discount: any) => {
    setDeleteModal({
      visible: true,
      id: discount.id,
      discountCode: discount.discountCode,
    })
  }

  // close the delete discount code modal without deleting discount code
  const handleDeleteModalClose = () => {
    setDeleteModal({ visible: false, id: 0, discountCode: '' })
  }

  // delete the discount code from the database with the help of API
  const handleDeleteDiscountCode = async () => {
    await dispatch(actions.deleteDiscountCode(deleteModal.id))
    setDeleteModal({
      visible: false,
      id: 0,
      discountCode: '',
    })
    fetchDiscountCodes()
  }

  const handleViewClick = async (record: any) => {
    let clientsInvited: any = await dispatch(actions.fetchInvitedClientsList(record.id, invitedClientsParams))
    setViewInvitedClientsModal({
      visible: true,
      discountCode: record,
      invitedClients: clientsInvited,
      isLoading: false,
    })
  }

  const handleChangePageInvitedClients = async (page: number, pageSize: number) => {
    setViewInvitedClientsModal({
      ...viewInvitedClientsModal,
      isLoading: true,
    })
    let clientsInvited: any = await dispatch(
      actions.fetchInvitedClientsList(viewInvitedClientsModal.discountCode.id, {
        offset: (page - 1) * params.limit,
        limit: invitedClientsParams.limit,
        status: invitedClientsParams.status,
      })
    )
    setInvitedClientsParams({
      ...invitedClientsParams,
      currentPage: page,
      offset: (page - 1) * params.limit,
      limit: pageSize,
    })
    setViewInvitedClientsModal({
      ...viewInvitedClientsModal,
      invitedClients: clientsInvited,
      isLoading: false,
    })
  }

  const handleChangeSelectInvitedClients = async (status: string) => {
    setInvitedClientsParams({
      currentPage: 1,
      offset: 0,
      limit: 10,
      status: status,
    })
    setViewInvitedClientsModal({
      ...viewInvitedClientsModal,
      isLoading: true,
    })
    let clientsInvited: any = await dispatch(
      actions.fetchInvitedClientsList(viewInvitedClientsModal.discountCode.id, {
        offset: 0,
        limit: 10,
        status: status,
      })
    )
    setViewInvitedClientsModal({
      ...viewInvitedClientsModal,
      invitedClients: clientsInvited,
      isLoading: false,
    })
  }

  const invitedClientsColumns = [
    {
      title: t('Client'),
      dataIndex: 'email',
      key: 'email',
      fixed: 'left',
      width: '35%',
    },
    {
      title: t('Company'),
      dataIndex: 'companyName',
      key: 'companyName',
      width: '35%',
    },
    {
      title: t('Status'),
      dataIndex: 'status',
      key: 'status',
      width: '30%',
      render: (status: DISCOUNT_CODE_USERS_STATUS) => {
        return <div className={`status-label ${discountCodeUsersStatusesLabel[status].classStyle}`}>{t(status)}</div>
      },
    },
  ]

  const columns = [
    {
      title: t('Discount Code'),
      dataIndex: 'discountCode',
      key: 'discountCode',
      fixed: 'left',
      sorter: true,
      width: '18%',
    },
    {
      title: t('Description'),
      dataIndex: 'description',
      key: 'description',
      width: '24%',
    },
    {
      title: t('Value'),
      dataIndex: 'value',
      key: 'value',
      width: '10%',
      render: (text: string, record: any) => {
        return (
          <div>
            {Math.round(record.value * 100) / 100} {record.type == 'PERCENTAGE' ? '%' : currency}
          </div>
        )
      },
    },
    {
      title: t('Invite Only?'),
      dataIndex: 'inviteOnly',
      key: 'inviteOnly',
      width: '10%',
      render: (text: string, record: any) => {
        return <div>{record.inviteOnly ? t('common.Yes', { ns: 'translationCommon' }) : t('common.No', { ns: 'translationCommon' })}</div>
      },
    },
    {
      title: t('Duration'),
      dataIndex: 'discountStartDate',
      key: 'discountStartDate',
      width: '14%',
      render: (text: string, record: any) => {
        return record.discountStartDate ? (
          <div>
            {moment(record.discountStartDate).format(FORMATS.DATE_ALPHA_FORMAT)} -{' '}
            {moment(record.discountEndDate).format(FORMATS.DATE_ALPHA_FORMAT)}
          </div>
        ) : (
          <div>-</div>
        )
      },
    },
    {
      title: t('Status'),
      dataIndex: 'status',
      key: 'status',
      width: '12%',
      render: (status: DISCOUNT_CODE_STATUS) => {
        return <div className={`status-label ${discountCodesStatusesLabel[status].classStyle}`}>{t(status)}</div>
      },
    },
    {
      title: t('Actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: '12%',
      render: (text: string, record: any) => {
        return (
          <div style={{ textAlign: 'center' }}>
            {hasPermission(permissions, PERMISSIONS.DISCOUNT_CODES) && (
              <Tooltip title={t('View Users')}>
                <EyeTwoTone style={{ fontSize: '20px', marginRight: '3px', marginLeft: '3px' }} onClick={() => handleViewClick(record)} />
              </Tooltip>
            )}
            {hasPermission(permissions, PERMISSIONS.DISCOUNT_CODES_UPDATE) && (
              <Tooltip title={t('Edit Discount Code')}>
                <EditTwoTone
                  style={{ fontSize: '20px', marginRight: '3px', marginLeft: '3px' }}
                  onClick={() => {
                    handleEditModalOpen(record)
                    if (record.type === 'FIXED') setCurrentSelectedCurrency(` (${currency}) `)
                  }}
                />
              </Tooltip>
            )}
            {hasPermission(permissions, PERMISSIONS.DISCOUNT_CODES_DELETE) && (
              <Tooltip title={t('Delete Discount Code')}>
                <DeleteTwoTone style={{ fontSize: '20px', marginRight: '3px', marginLeft: '3px' }} onClick={() => handleDeleteClick(record)} />
              </Tooltip>
            )}
          </div>
        )
      },
    },
  ]

  const typesOptions: IOptionForSelect[] = [
    {
      label: t('Percentage'),
      value: 'PERCENTAGE',
    },
    {
      label: t('Fixed Amount'),
      value: 'FIXED',
    },
  ]

  const filterStatusOptions: IOptionForSelect[] = [
    {
      label: t('Active'),
      value: 'ACTIVE',
    },
    {
      label: t('Disabled'),
      value: 'DISABLED',
    },
    {
      label: t('Expired'),
      value: 'EXPIRED',
    },
  ]

  const newDiscountStatusOptions: IOptionForSelect[] = [
    {
      label: t('Active'),
      value: 'ACTIVE',
    },
    {
      label: t('Disabled'),
      value: 'DISABLED',
    },
  ]

  const invitedClientsStatusOptions: IOptionForSelect[] = [
    {
      label: t('Invited'),
      value: 'INVITED',
    },
    {
      label: t('Redeemed'),
      value: 'REDEEMED',
    },
    {
      label: t('Expired'),
      value: 'EXPIRED',
    },
  ]

  const setCurrentCurrency = (value: string) => {
    if (value === 'FIXED') {
      setCurrentSelectedCurrency(` (${currency}) `)
    } else {
      setCurrentSelectedCurrency('')
      if (form.getFieldValue('discountValue').value > 100) {
        form.setFieldsValue({
          discountValue: {
            value: 100,
            type: value,
          },
        })
      }
    }
  }

  const checkDiscountType = (value: any) => {
    if (form.getFieldValue('discountValue').type === 'PERCENTAGE' && value > 100) {
      form.setFieldsValue({
        discountValue: {
          value: 100,
          type: form.getFieldValue('discountValue').type,
        },
      })
    }
  }

  return (
    <>
      <h2 className="pt-10 pb-10">{t('Discount Codes')}</h2>
      <Row justify="space-between">
        <Col className="qt-search">
          <QInput
            value={search}
            onChange={handleChangeSearch}
            placeholder={t('common.Search', { ns: 'translationCommon' })}
            size="large"
            prefix={<SearchOutlined />}
          />
        </Col>
        <Col>
          <Row align="middle" gutter={30}>
            <Col className="qt-filter" style={{ width: '300px' }}>
              <QSelect
                size="large"
                mode="multiple"
                placeholder={t('common.Filter_by_status', { ns: 'translationCommon' })}
                showArrow
                showSearch
                maxTagCount={3}
                options={filterStatusOptions}
                onChange={handleChangeSelect}
              />
            </Col>
            <Col>
              <QButton
                type="primary"
                disabled={!hasPermission(permissions, PERMISSIONS.DISCOUNT_CODES_CREATE)}
                onClick={() => handleEditModalOpen()}
              >
                {t('Add New')} <PlusOutlined style={{ fontSize: '18px' }} />
              </QButton>
            </Col>
          </Row>
        </Col>
      </Row>

      <div className="pt-15 pb-30">
        <QTable
          columns={columns}
          dataSource={discountCodesList}
          loading={discountCodesListIsLoading}
          scroll={{ x: 990 }}
          // @ts-ignore
          onChange={handleTableChange}
          sortDirections={['ascend', 'descend', 'ascend']}
        />
      </div>
      <div className="qt-footer">
        <QPagination
          size="small"
          defaultCurrent={1}
          hideOnSinglePage
          current={pages.currentPage}
          total={discountCodesTotal}
          onChange={handleChangePage}
        />
      </div>

      <Modal
        wrapClassName="qu-modal"
        closeIcon={<IconsCommon.IconClose />}
        visible={editModal}
        onCancel={handleEditModalClose}
        width={450}
        title={form.getFieldValue('id') ? <h3>{t('Update Discount Code')}</h3> : <h3>{t('Add New Discount Code')}</h3>}
        onOk={onCheck}
        footer={null}
      >
        <Form layout="vertical" hideRequiredMark form={form} validateMessages={validateMessages} onFinish={onCheck}>
          <Form.Item
            label={t('Discount Code')}
            name="discountCode"
            rules={[{ required: true, whitespace: true }]}
            validateTrigger={['onChange', 'onBlur']}
          >
            <QInput size="large" />
          </Form.Item>

          <Form.Item
            label={t('Description')}
            name="description"
            rules={[{ required: true, whitespace: true }]}
            validateTrigger={['onChange', 'onBlur']}
          >
            <QTextArea rows={5} className="form-textarea" />
          </Form.Item>

          <Form.Item
            label={t('Value')}
            extra={t('The value should not be greater than 100 in the case of Percentage')}
          >
            <Input.Group className="qu-input-group" size="large" compact>
              <Form.Item
                name={['discountValue', 'value']}
                noStyle
                rules={[{ required: true, message: 'Value is required' }]}
                validateTrigger={['onChange', 'onBlur']}
              >
                <InputNumber min={1} style={{ width: '50%' }} size="large" precision={2} onChange={checkDiscountType} />
              </Form.Item>

              <Form.Item
                name={['discountValue', 'type']}
                noStyle
                rules={[{ required: true, message: 'Type is required' }]}
                validateTrigger={['onChange', 'onBlur']}
              >
                <QSelect
                  style={{ width: '50%' }}
                  size="large"
                  placeholder={t('Select Type')}
                  options={typesOptions}
                  onChange={setCurrentCurrency}
                />
              </Form.Item>
            </Input.Group>
          </Form.Item>

          <Form.Item label={t('Duration')} name="duration" validateTrigger={['onChange', 'onBlur']}>
            <QRangePicker placeholder={[t("Start date"), t("End date")]} format={FORMATS.DATE_FORMAT} className="full-width" size="large" />
          </Form.Item>

          <Form.Item label={t('User(s)')} name="clientIds">
            <QSelect
              size="large"
              mode="multiple"
              placeholder={t('Select user(s)')}
              showArrow
              showSearch
              maxTagCount={2}
              optionFilterProp="children"
              filterOption={(input: any, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {clientsList.map((c: any) => (
                <Option key={c.value} value={c.value}>
                  {c.label}
                </Option>
              ))}
            </QSelect>
          </Form.Item>

          <Form.Item label={t('Status')} name="status" rules={[{ required: true }]} validateTrigger={['onChange', 'onBlur']}>
            <QSelect size="large" placeholder="Select Status" options={newDiscountStatusOptions} />
          </Form.Item>

          <Row>
            <Form.Item name="inviteOnly" validateTrigger={['onChange', 'onBlur']} valuePropName="checked">
              <Switch onChange={handleChangeSwitch} />
            </Form.Item>
            <span className="discount-code-switch">
              {discountSwitch ? t('Invite-only discount code') : t('Public discount code')}
            </span>
          </Row>

          <div className="grid-row grid-row--jce">
            <div className="grid-col grid-col--auto">
              <QButton type="primary" block htmlType="submit">
                {form.getFieldValue('id') ? t('common.Update', { ns: 'translationCommon' }) : t('Create')}
              </QButton>
            </div>
          </div>
        </Form>
      </Modal>

      <Modal
        title=""
        visible={deleteModal.visible}
        onOk={handleDeleteDiscountCode}
        onCancel={handleDeleteModalClose}
        okText={t('common.OKAY', { ns: 'translationCommon' })}
        cancelText={t('common.Cancel', { ns: 'translationCommon' })}
      >
        <p>
          {t('Do you want to delete')}<strong> {deleteModal.discountCode} </strong>?
        </p>
      </Modal>

      <Modal
        wrapClassName="qu-modal"
        closeIcon={<IconsCommon.IconClose />}
        visible={viewInvitedClientsModal.visible}
        onCancel={() => {
          setViewInvitedClientsModal({
            visible: false,
            discountCode: {},
            invitedClients: {},
            isLoading: false,
          })
          setInvitedClientsParams({
            currentPage: 1,
            offset: 0,
            limit: 10,
            status: '',
          })
          setSelectedStatus(null)
        }}
        title={
          <div>
            <h3>{t('Discount Code')} - {viewInvitedClientsModal?.discountCode?.discountCode}</h3>
            <small>
              {viewInvitedClientsModal?.discountCode?.inviteOnly ? t('Invite-only discount code') : t('Public discount code')}
            </small>
          </div>
        }
        footer={null}
      >
        <Row align="middle" gutter={30} style={{ justifyContent: 'flex-end' }}>
          <Col className="qt-filter" style={{ width: '180px' }}>
            <QSelect
              value={selectedStatus}
              size="large"
              placeholder={t('common.Filter by status')}
              options={invitedClientsStatusOptions}
              onChange={(e: any) => {
                setSelectedStatus(e)
                handleChangeSelectInvitedClients(e)
              }}
            />
          </Col>
        </Row>

        <div className="pt-15 pb-30">
          <QTable
            loading={viewInvitedClientsModal?.isLoading}
            columns={invitedClientsColumns}
            dataSource={viewInvitedClientsModal?.invitedClients?.invitedClientsList}
          />
        </div>
        <Row justify="end">
          <QPagination
            size="small"
            defaultCurrent={1}
            hideOnSinglePage
            current={invitedClientsParams.currentPage}
            total={viewInvitedClientsModal?.invitedClients?.total}
            onChange={handleChangePageInvitedClients}
          />
        </Row>
      </Modal>

      <Modal
        wrapClassName="qu-modal"
        title={<h3>{'Your currency setting has changed!'}</h3>}
        closeIcon={closeIcon}
        visible={currencyChangeModal.visible}
        onCancel={() => {
          setCurrencyChangeModal({ visible: false, currency: '', currencyType: '', data: {} })
          handleEditModalClose()
        }}
        footer={false}
        width={400}
      >
        <div>
          <p className="mb-25">
            This discount code was created in {currencyChangeModal?.currency}, but your currenct currency is set to{' '}
            {currency}.
          </p>
          <div className="align-vertical text-center">
            <QButton
              className="w-300 mb-20"
              type="primary"
              onClick={() => {
                handleOnFinish(currencyChangeModal.data, true)
                setCurrencyChangeModal({ ...currencyChangeModal, visible: false })
              }}
            >
              <span className="mr-5">Change Discount Code To {currency} </span>
            </QButton>
            <button
              type="button"
              className="button button--link mb-10"
              onClick={() => {
                handleOnFinish(currencyChangeModal.data, false)
                setCurrencyChangeModal({ ...currencyChangeModal, visible: false })
              }}
            >
              Don't change the currency
            </button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default DiscountCodesList
