import { requestHttp, urls } from 'src/api'
import { STATUS_CODES } from 'src/constants'


export const getAllProductsList = async({offset,limit,direction,sortField,search}:any) => {
    try {
        const response = await requestHttp.get(urls.getListAllProductsUrl({offset,limit,direction,sortField,search}))
        const { content } = response.data.data
        return content
    } catch (error) {
        return error
    }
}

export const uploadProductsCSVFile = async(file:any) => {
    try {
        const formData = new FormData();
        formData.append('file',file);

        const config = {
            multipartFormData: true,
            skipDownloadProgress: true,
          }

        const response = await requestHttp.post(urls.getImportProductsCSVUrl(),formData,config)
        const { content } = response.data.data
        return content
    } catch (error) {
        return error
    }
}

