import { PATHS } from "src/constants";
import { MENU_ITEMS } from "../constants/menu";

const CAMPAIGN_PATHS = [
  PATHS.BRIEF,
  PATHS.CALENDAR,
  PATHS.CREATE_BRIEF,
  PATHS.BRIEF_VIEW,
  PATHS.BRIEF_EDIT,
  PATHS.BRIEF_EDIT_V3,
  PATHS.BRIEF_REJECTED,
];

const SERVICES_PATHS = [PATHS.SERVICES, PATHS.MANAGE_SERVICES];
const CLIENTS_PATHS = [PATHS.CLIENTS, PATHS.CREATE_CLIENT];

export const activeMenuItem = (path: string, search: string) => {
  // if (path === PATHS.WORKFLOW) {
  //   return MENU_ITEMS.WORKFLOW
  // }

  // if (path === PATHS.INVITE_CLIENT) {
  //   return MENU_ITEMS.INVITE_CLIENT
  // }

  // if (path === PATHS.DISCOUNT_CODES) {
  //   return MENU_ITEMS.DISCOUNT_CODES
  // }

  // if (path === PATHS.PROMOTIONS) {
  //   return MENU_ITEMS.PROMOTIONS
  // }

  if (CAMPAIGN_PATHS.includes(path)) {
    return MENU_ITEMS.BRIEFS;
  }

  if (CLIENTS_PATHS.includes(path)) {
    return MENU_ITEMS.CLIENTS;
  }

  if (SERVICES_PATHS.includes(path)) {
    return MENU_ITEMS.SERVICES;
  }

  if (path.includes(PATHS.SUPPORT_TICKETS)) {
    return MENU_ITEMS.SUPPORT_TICKETS;
  }

  if (path.includes(PATHS.BRIEF_EDIT_V3.split("/:")[0])) {
    return MENU_ITEMS.BRIEFS;
  }

  if (path === PATHS.WALLET) {
    return MENU_ITEMS.WALLET;
  }

  if (path === PATHS.PRODUCTS) {
    return MENU_ITEMS.PRODUCTS;
  }

  if (path === PATHS.PUBLISHERS) {
    return MENU_ITEMS.PUBLISHERS;
  }

  return MENU_ITEMS.DASHBOARD;
};

export const activeMenuOpen = (path: string, search: string) => {
  const userRequests = [MENU_ITEMS.REQUESTS_PARTNERS, MENU_ITEMS.CLIENTS];
  const briefs = [
    MENU_ITEMS.BRIEFS_CREATE,
    MENU_ITEMS.BRIEFS_ALL,
    MENU_ITEMS.REQUESTS_ALL,
  ];
  const demographic = [
    MENU_ITEMS.DEMOGRAPHICS_AGE_GROUPS,
    MENU_ITEMS.DEMOGRAPHICS_SEGMENTS,
  ];
  const employees = [MENU_ITEMS.EMPLOYEES_ROLES, MENU_ITEMS.EMPLOYEES_ALL];
  const invitedClients = [
    MENU_ITEMS.INVITED_SIGNUPS,
    MENU_ITEMS.INVITED_CLIENTS_BRIEFS,
  ];
  const services = [MENU_ITEMS.SERVICES, MENU_ITEMS.MANAGE_SERVICES];

  const subMenu = activeMenuItem(path, search);

  if (services.includes(subMenu)) {
    return MENU_ITEMS.SERVICES;
  }

  if (userRequests.includes(subMenu)) {
    return MENU_ITEMS.REQUESTS;
  }

  if (briefs.includes(subMenu)) {
    return MENU_ITEMS.BRIEFS;
  }

  if (demographic.includes(subMenu)) {
    return MENU_ITEMS.DEMOGRAPHICS;
  }

  if (employees.includes(subMenu)) {
    return MENU_ITEMS.EMPLOYEES;
  }

  if (invitedClients.includes(subMenu)) {
    return MENU_ITEMS.INVITED_CLIENTS;
  }

  return "";
};
