import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Col, Row } from 'quantum_components'
import { actions } from '../partnerProfile.module'
import { PARTNER_STEPS, PRICING_MODEL } from 'src/constants/profile'
import { useReduxDispatch } from 'src/helpers'
import {
  getCurrentStep,
  getForm,
  getFormBoolean,
  getPartnerPartnerPercent,
  getProfileLoading,
} from '../profile.selectors'
import PartnersProfileFooter from './Layout/profile-footer'
import PartnersProfileHeader from './Layout/profile-header'
import PartnersProfileSidebar from './Layout/profile-sidebar'
import '../styles.scss'
import { StepOneContainer } from './Layout/Steps/StepOne'
import { StepTwoContainer } from './Layout/Steps/StepTwo'
import { StepThreeContainer } from './Layout/Steps/StepThree'
import { StepFourContainer } from './Layout/Steps/StepFour'
import { getCurrentUser, getCurrentUserPermissionActions } from '../../../core/core.selectors'
import { useParams } from 'react-router-dom'
import { calculateProfilePercent } from 'src/helpers/profile'
import { hasPermission } from 'src/helpers/permission'
import { PERMISSIONS } from 'src/constants/permission'
import { getUser } from 'src/modules/core/core.actions'

export const PARTNER_STEPS_EDIT: PARTNER_STEPS[] = [
  PARTNER_STEPS.GENERAL,
  PARTNER_STEPS.SALES,
  PARTNER_STEPS.CUSTOMERS,
  // PARTNER_STEPS.AVAILABLE,
]

const ProfilePartnerContainer = () => {
  const currentStep = useSelector(getCurrentStep)
  const currentIndex = PARTNER_STEPS_EDIT.indexOf(currentStep)
  const dispatch = useReduxDispatch()
  const form = useSelector(getForm)
  const formBoolean = useSelector(getFormBoolean)
  const getHasUser = useSelector(getCurrentUser)
  const getPartnerPercent = useSelector(getPartnerPartnerPercent)
  const partnerLoading = useSelector(getProfileLoading)
  const permissions = useSelector(getCurrentUserPermissionActions)

  // const { id } = useParams<{id: any}>()
  const id = getHasUser.partnerUserId

  const fetchSettings = () => dispatch(actions.fetchPartnerProfileSettings())

  useEffect(() => {
    fetchSettings()
  }, [])

  useEffect(() => {
    if (!hasPermission(permissions, PERMISSIONS.PARTNER_VIEW_PROFILE)) return
    const data = dispatch(actions.fetchPartnerProfile(id))
    dispatch(actions.setForm({ data }))
  }, [getHasUser])

  useEffect(() => {
    dispatch(actions.setPartnerProfilePercent(calculateProfilePercent(formBoolean)))
  }, [formBoolean])

  const [errors, setErrors] = useState({
    [PARTNER_STEPS.GENERAL]: false,
    [PARTNER_STEPS.SALES]: false,
    [PARTNER_STEPS.CUSTOMERS]: false,
    [PARTNER_STEPS.AVAILABLE]: false,
  })

  const handleSetErrors = (stepName: string, value: boolean) => {
    setErrors({ ...errors, [stepName]: value })
  }

  const handleNext = () => {
    dispatch(actions.setCurrentStep(PARTNER_STEPS_EDIT[currentIndex + 1]))
  }

  const handleBack = () => {
    dispatch(actions.setCurrentStep(PARTNER_STEPS_EDIT[PARTNER_STEPS_EDIT.indexOf(currentStep) - 1]))
  }

  function getContent() {
    switch (currentIndex) {
      case 0:
        return <StepOneContainer setError={handleSetErrors} />
      case 1:
        return <StepTwoContainer setError={handleSetErrors} />
      case 2:
        return <StepThreeContainer setError={handleSetErrors} />
      // case 3:
      //   return <StepFourContainer setError={handleSetErrors} />
    }
  }

  async function saveUpdates() {

    await dispatch(actions.fetchPartnerProfileSave({ ...form, completion: getPartnerPercent }, id))
    await dispatch(getUser())
  }

  const cantUpdate = !hasPermission(permissions, PERMISSIONS.PARTNER_UPDATE_PROFILE)
  const disabledButton = Object.values(errors).some(e => e) || cantUpdate

  if (partnerLoading) return <p></p>
  return (
    <div className="partner-container">
      <div className="partner-container-content">
        <Row gutter={20}>
          <Col xs={24} sm={24} md={16}>
            <PartnersProfileHeader />
          </Col>
          <Col xs={24}>
            <Row gutter={20}>
              <Col xs={24} sm={24} md={16}>
                {getContent()}
              </Col>
              <Col xs={24} sm={24} md={8}>
                <PartnersProfileSidebar currentStep={currentStep} />
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={16}>
            <PartnersProfileFooter
              currentIndex={currentIndex}
              onBack={handleBack}
              onNext={handleNext}
              onFinishLater={() => saveUpdates()}
              disabled={disabledButton}
            />
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default ProfilePartnerContainer
