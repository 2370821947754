const MODULE_NAME = 'admin/brief'
// const MODULE_NAME = 'testahelBrief'

export const getCreateTestahelBriefUrl = (): string => `${MODULE_NAME}/create`

export const getEditTestahelBriefUrl = (): string => `${MODULE_NAME}/edit-step/`

export const getTestahelBriefListUrl = (): string => `${MODULE_NAME}/list`

export const getDeleteTestahelBriefUrl = (id: number): string => `${MODULE_NAME}/delete/${id}`

export const getTestahelBriefSettingsUrl = (): string => `${MODULE_NAME}/brief-settings`

export const getTestahelAgeGroupsUrl = (): string => `agegroups/list`

export const getTestahelTargetSegmentsUrl = (): string => `segments/list`

export const getTestahelViewBriefUrl = (id: number): string => `${MODULE_NAME}/view/${id}`

export const getTestahelBriefUploadUrl = (): string => `${MODULE_NAME}/upload`

export const getTestahelBriefRemoveMediaUrl = (): string => `${MODULE_NAME}/remove-media`

export const getTestahelPartnerFilterSettingsUrl = (): string => `${MODULE_NAME}/filter-settings`

export const getTestahelBriefRecommendedPartnersUrl = (): string => `${MODULE_NAME}/recommended-partners`

export const getTestahelBriefPartnerDetailsUrl = (briefId: number, partnerId: number): string =>
  `${MODULE_NAME}/${briefId}/partner/single/${partnerId}`

export const getTestahelBriefUploadMediaUrl = (briefId: number): string => `${MODULE_NAME}/media-buy-service/${briefId}`

export const getTestahelBriefApproveUrl = (briefId: number): string => `brief/final-approve/${briefId}`

export const getTestahelSendEmailProposal = (briefId: number): string => `${MODULE_NAME}/${briefId}/email-proposal`
