import { Col, QButton, QInput, QTable, Row, Upload } from "quantum_components";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import SpinnerCenter from "src/components/Spinner/spinner";
import { MEDIA_TYPES } from "src/constants/brief";
import { getAllProductsList, uploadProductsCSVFile } from "../products.actions";
import lodash from "lodash";
import { Pagination } from "antd";
import i18n from "src/i18n";
import DialogModal from "src/components/DialogModal/DialogModal";

const ProductsContainer = ({
  placement,
  state,
  setState,
  arrayIndex,
  setShowModal
}: any) => {
  const { t } = useTranslation(["translationProduct"]);
  const [isLoading, setIsLoading] = useState(false);
  const [productList, setProductList] = useState([]);
  const [search, setSearch] = useState("");
  const [debounce, setDebounce] = useState<any>(-1);
  const [selectedFile, setSelectedFile] = useState<any>({});
  const [showConfirm, setShowConfirm] = useState(false);


  const [pagination, setPagination] = useState<any>({
    offset: 0,
    limit: 10,
    direction: "desc",
    sortField: "id",
    search: "",
    total: 0,
  });

  const handleProductSelect = (product: any) => {
    let s = [...state];

    if (!product) {
      s[arrayIndex].activeProduct = {}
    } else {
      s[arrayIndex].activeProduct = { ...product }
    }

    // update active native template here as well 
    if (product) {
      // replace template variable values according to the selected product
      let values = {};
      s[arrayIndex]?.activeNativeTemplate?.variables?.forEach((variable: any, index: number) => {
        // console.log(variable)
        if (variable?.mappedProductField) {

          // console.log(variable?.mappedProductField,product[variable?.mappedProductField],JSON.stringify(product))
          // console.log(lodash.get(product,variable?.mappedProductField));

          const value = lodash.get(product, variable?.mappedProductField);

          if (variable?.mappedProductField === "productLink") {
            s[arrayIndex].redirectUrl = lodash.isArray(value) ? [...value].pop() : value;
          }
          // console.log(variable.placeholder, product[variable.mappedProductField])
          values = { ...values, [variable.placeholder]: lodash.isArray(value) ? [...value].pop() : value };


        } else {
          values = { ...values, [variable.placeholder]: "" };
        }
      });

      // const redirectLink = lodash.get(product,'productLink',[]);
      // console.log(redirectLink)
      // if(redirectLink.length > 0){
      //   s[arrayIndex].redirectUrl = redirectLink.pop();
      // }

      s[arrayIndex].activeNativeTemplate["values"] = values;
    }

    setState(s);

    if (product) {
      setShowModal(false);
    }
  }

  const columnsForPlacement = [
    {
      title: t("Product"),
      width: "60%",
      align: i18n.dir() === "ltr" ? "left" : "right",
      fixed: "left",
      render: (element: any, row: any) => {
        const { name, description, imageLink } = element;
        return (
          <Row gutter={24}>
            <Col md={4}>
              <img
                src={
                  imageLink
                    ? imageLink
                    : "https://placehold.co/250x200?text=No%20Image%20Yet"
                }
                className="h-50 full-width table-preview-img"
              />
            </Col>
            <Col md={20}>
              <div className="text-capitalize text-bold">{name}</div>
              <div className="text-capitalize">{description ? description.substring(0, 40) + "..." : "-"}</div>
            </Col>
          </Row>
        );
      },
    },
    {
      title: t("Price (SAR)"),
      dataIndex: "price",
      key: "price",
      width: "20%",
      align: i18n.dir() === "ltr" ? "left" : "right",
    },
    {
      title: t("Action"),
      dataIndex: null,
      key: null,
      width: "20%",
      align: "middle",
      render: (element: any, row: any) => {
        const { id } = lodash.get(state[arrayIndex], 'activeProduct', {});
        return <QButton htmlType="button" type="primary" size="small" onClick={() => {
          element?.id === id ? handleProductSelect(null) : handleProductSelect(element)
        }}>{(element?.id === id ? t("Unselect") : t("Select"))}</QButton>
      }
    },
  ]

  const columns = [
    {
      title: t("Product"),
      width: "40%",
      align: i18n.dir() === "ltr" ? "left" : "right",
      fixed: "left",
      sorter: true,
      render: (element: any, row: any) => {
        const { name, description, imageLink } = element;
        return (
          <Row gutter={24}>
            <Col md={4}>
              <img
                src={
                  imageLink
                    ? imageLink
                    : "https://placehold.co/250x200?text=No%20Image%20Yet"
                }
                className="h-50 full-width table-preview-img"
              />
            </Col>
            <Col md={20}>
              <div className="text-capitalize text-bold">{name}</div>
              <div className="text-capitalize">{description ?? "-"}</div>
            </Col>
          </Row>
        );
      },
    },
    {
      title: t("SKU ID"),
      dataIndex: "sku",
      key: "sku",
      width: "10%",
      align: i18n.dir() === "ltr" ? "left" : "right",
      sorter: true,
    },
    {
      title: t("Categories"),
      width: "15%",
      align: i18n.dir() === "ltr" ? "left" : "right",
      render: (element: any, row: any) => {
        const { category } = element;
        return (
          <>
            <div className="text-capitalize">
              {category && category.length > 0 ? category.join(", ") : "-"}
            </div>
          </>
        );
      },
    },
    {
      title: t("Sub Categories"),
      width: "15%",
      align: i18n.dir() === "ltr" ? "left" : "right",
      render: (element: any, row: any) => {
        const { subcategory } = element;
        return (
          <>
            <div className="text-capitalize">
              {subcategory && subcategory.length > 0 ? subcategory.join(", ") : "-"}
            </div>
          </>
        );
      },
    },
    {
      title: t("Price (SAR)"),
      dataIndex: "price",
      key: "price",
      width: "10%",
      align: i18n.dir() === "ltr" ? "left" : "right",
    },
    {
      title: t("Link"),
      width: "10%",
      align: i18n.dir() === "ltr" ? "left" : "right",
      render: (element: any, row: any) => {
        const { productLink } = element;
        return (
          <>
            <div className="text-capitalize">
              {productLink && productLink.length > 0
                ? productLink?.map((link: any, index: number) => {
                  const showLinkNumber = productLink.length > 1;
                  return (
                    <>
                      <a href={link} target="_blank" key={index}>
                        {t("Link")}
                        {showLinkNumber && <>&nbsp;{index + 1}</>}
                      </a>
                      &nbsp;
                    </>
                  );
                })
                : "-"}
            </div>
          </>
        );
      },
    },
  ];

  const fetchProductList = async (pagination: any) => {
    setIsLoading(true);
    const data = await getAllProductsList(pagination);
    setProductList(lodash.get(data, "results", []));
    setPagination({ ...pagination, total: lodash.get(data, "total", 0) });
    setIsLoading(false);
  };

  const resetProductList = async (pagination: any) => {
    setIsLoading(true);

    setProductList([]);
    setPagination({ ...pagination, total: 0 });
    setIsLoading(false);
  };

  useEffect(() => {
    if (placement) return;

    fetchProductList(pagination);
  }, [placement]);

  const resetPagination = () => {
    const defaultPagination: any = {
      offset: 0,
      limit: 10,
      direction: "desc",
      sortField: "id",
      search: "",
      total: 0,
    };
    setPagination(defaultPagination);
    return defaultPagination;
  };

  const handleTableChange = (page: any, filters: any, sorter: any) => {
    let column = sorter?.column?.title ?? "id";
    let direction = sorter?.order ?? "desc";

    if (column === "Product") {
      column = "name";
    } else if (column === "SKU ID") {
      column = "sku";
    }

    if (direction === "descend") {
      direction = "desc";
    } else {
      direction = "asc";
    }

    let existingPagination = { ...pagination, direction, sortField: column };

    setPagination(existingPagination);
    fetchProductList(existingPagination);
  };

  const handleOnChange = async (data: any) => {
    setSelectedFile(data?.file?.originFileObj);
    setShowConfirm(true);
  };

  const uploadFile = async () => {
    setIsLoading(true);
    await uploadProductsCSVFile(selectedFile);
    await fetchProductList(resetPagination());
    setIsLoading(false);
  }

  const handleChangePage = async (page: number, pageSize: number) => {
    const dp = { ...pagination };
    dp.offset = (page - 1) * dp.limit;
    await fetchProductList(dp);
  };

  useEffect(() => {
    if (!search && debounce < 0) return;
    if (debounce) clearTimeout(debounce);

    setDebounce(
      setTimeout(() => {
        let dp = resetPagination();
        dp.search = search;

        setPagination(dp);

        if (search.length == 0 && placement) {
          resetProductList(dp)
        } else {
          fetchProductList(dp);
        }
      }, 500)
    );
  }, [search]);

  return (
    <div className="services-v2">
      {isLoading && <SpinnerCenter />}

      {placement && (
        <Row justify="space-between" className="mt-20 mb-20">
          <Col>
            <h2>{t("Browse Products")}</h2>
            <p className="m-0">{t("Search for a product to advertise")}</p>
          </Col>
        </Row>
      )}

      {!placement && <Row justify="space-between" className="mt-20 mb-20">
        <Col>
          <h2>{t("Products")}</h2>
          <p className="m-0">
            {t(
              "Import all the product SKUs that you have in stock for your advertiser's SKU Uplifting, Cross Sell, or Product Recommendation Campaigns"
            )}
          </p>
        </Col>
      </Row>}

      <Row justify="space-between" align={"middle"} className="pb-10">
        <Col md={placement ? 24 : 12} className="flex-container-row-flex-start">
          <div className={placement ? "full-width" : ""}>
            <QInput
              placeholder={placement ? t("E.g. Milk") : t("Search by Product Name or SKU ID")}
              size="large"
              className={placement ? "full-width" : "w-400"}
              value={search}
              onChange={(e: any) => setSearch(e.target.value)}
            />
          </div>
        </Col>
        {!placement && <Col>
          <Upload
            onChange={(e: any) => handleOnChange(e)}
            multiple={false}
            customRequest={() => false}
            showUploadList={false}
            listType={"text"}
            maxCount={1}
            accept={".csv"}
          >
            <div>
              <QButton
                size="small"
                type="primary"
                htmlType="button"
                className="qu-button-outline xs-btn"
              >
                +&nbsp;{t("Import CSV")}
              </QButton>
            </div>
          </Upload>
          <div>
            <small>
              <a
                href="https://quantum-files-bucket-new.s3.me-south-1.amazonaws.com/qrm_assets/sample_products_list.csv"
                download={true}
              >
                {t("Download Sample File")}
              </a>
            </small>
          </div>
        </Col>}
      </Row>

      {((placement && productList.length > 0) || (!placement)) && <Row>
        <Col md={24}>
          <div className="pb-10">
            <QTable
              //@ts-ignore
              // onRow={handleOnRowClick}
              onChange={handleTableChange}
              columns={placement ? columnsForPlacement : columns}
              dataSource={productList}
              loading={false}
              scroll={{ x: "auto" }}
            />
          </div>
          <div className="qt-footer">
            <Pagination
              showSizeChanger={false}
              size="small"
              defaultCurrent={1}
              current={
                pagination?.offset / pagination?.limit == 0
                  ? 1
                  : pagination?.offset / pagination?.limit + 1
              }
              total={pagination?.total}
              defaultPageSize={pagination?.limit}
              onChange={handleChangePage}
            />
          </div>
        </Col>
      </Row>}

      <DialogModal
        modalOpen={showConfirm}
        question={t("Are you sure?")}
        description={t("Action will replace existing products.")}
        submitButtonText={t("Cancel")}
        cancelButtonText={t("Confirm")}
        onClose={() => {
          setShowConfirm(false);
          setSelectedFile({});
        }}
        onProceed={() => {
          setShowConfirm(false);
          uploadFile();
        }}
        revert
      />
    </div>
  );
};

export default ProductsContainer;
