import { CAMPAIGN_NAME_MAX, CAMPAIGN_NAME_MIN, PRODUCT_DESCRIPTION_MAX, PRODUCT_DESCRIPTION_MIN, PRODUCT_NAME_MAX, PRODUCT_NAME_MIN } from './brief'

export const VALIDATION_MESSAGE = {
  REQUIRED: 'validation.required',
  DISTRIBUTION_TYPE: 'Should be at least one distribution item.',
  PRODUCT_NAME: 'validation.product_name',
  PRODUCT_NAME_MIN_MAX: `validation.product_name_minmax`,
  MIN_QUANTITY: 'validation.min_quantity',
  OTHER_PRODUCT_TYPE: 'validation.other_product_type',
  DURATION: 'validation.duration',
  ADDITIONAL_INSTRUCTION: 'validation.additional_instruction',
  MORE_THAN_ZERO: 'validation.more_than_zero',
  EQUAL_OR_MORE_THAN_ZERO: 'validation.equal_or_more_than_zero',
  BETWEEN_ZERO_TEN: 'validation.between_zero_ten',
  WEBSITE_URL: 'validation.website_url',
  SURVEY_NAME: 'validation.survey_name',
  SURVEY_QUESTION_NAME: 'validation.survey_question_name',
  SURVEY_ANSWER_NAME: 'validation.survey_answer_name',
  QUANTITY: 'validation.quantity',
  CHART_TYPE: 'validation.chart_type',
  PARTNER: 'validation.partner',
  TITLE: 'validation.title',
  DESCRIPTION: 'validation.desc',
  DATE: 'validation.date',
  PASSWORD: 'validation.password',
  LOWER_AGE_REQUIRED: 'validation.lower_age_required',
  LOWER_AGE: 'validation.lower_age',
  UPPER_AGE_REQUIRED: 'validation.upper_age_required',
  UPPER_AGE: 'validation.upper_age',
  CUSTOMIZE_BOX_NAME: 'validation.customize_box_name',
  CUSTOMIZE_BOX_NAME_MIN_MAX: `validation.customize_box_name_min_max`,  
  PRODUCT_DESCRIPTION: 'validation.product_description',
  CAMPAIGN_NAME: 'validation.campaign_name',
  PRODUCT_DESCRIPTION_MIN_MAX: `validation.product_description_minmax`,
  CAMPAIGN_NAME_MIN_MAX: `validation.campaign_name_minmax`,
  PHONE_NUMBER: 'clients.phone_number',
}
