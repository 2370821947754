import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Col,
  Row,
  Form,
  QButton,
  QInput,
  QSelect,
  Radio,
  ServicesSummary,
  IconsCommon,
  Modal,
} from 'quantum_components'

import { PERMISSIONS } from 'src/constants/permission'
import { BRIEF_PAYMENT_STATUS } from 'src/constants/brief'

import { getFileNameFromUrl } from 'src/utils'
import { hasPermission } from 'src/helpers/permission'
import { useReduxDispatch } from 'src/helpers'
import { getCurrentUserPermissionActions } from 'src/modules/core/core.selectors'
import * as coreActions from 'src/modules/core/core.actions'

import * as actions from '../../brief.actions'
import { IBriefPayment } from '../../brief.types'

import UploadComponent from 'src/components/UploadComponent'
import { useTranslation } from 'react-i18next'
import i18n from 'src/i18n'

enum ACTIONS {
  ACCEPT = 'ACCEPT',
  REJECT = 'REJECT',
  PENDING = 'PENDING',
}

export interface Props {
  payment: IBriefPayment
  defaultReasons: string[]
}

const BriefReviewPayment = ({ payment, defaultReasons }: Props) => {
  const { t } = useTranslation(['translationABrief'])
  const dispatch = useReduxDispatch()
  const permissions = useSelector(getCurrentUserPermissionActions)

  const [defaultReason, setDefaultReason] = useState<string | null>(null)
  const [alternativeReason, setAlternativeReason] = useState<string | null>(null)

  const [confirmationModalVisible, setConfirmationModalVisible] = useState<boolean>(false)

  const [action, setAction] = useState<ACTIONS>(ACTIONS.PENDING)
  const isActionAccept = action === ACTIONS.ACCEPT
  const isActionReject = action === ACTIONS.REJECT
  const isActionPending = action === ACTIONS.PENDING

  const isBankTransferUploaded = Boolean(payment.bankTransfer?.length)
  const isPoUploaded = Boolean(payment.po?.length)

  const reasonOptions: { label: string; value: string | null }[] = [
    ...defaultReasons.map((reason: string) => ({ label: t(reason), value: reason })),
    { label: t(`other`), value: null },
  ]

  useEffect(() => {
    setAlternativeReason(null)
  }, [defaultReason])

  useEffect(() => {
    if (alternativeReason) {
      setDefaultReason(null)
    }
  }, [alternativeReason])

  const toggleConfirmationModal = () => setConfirmationModalVisible(() => !confirmationModalVisible)

  const handleActionChange = (event: any) => {
    const action: ACTIONS = event.target.value
    setAction(action)
  }

  const handleSubmit = async () => {
    if (payment.isAccepting || payment.isRejecting) {
      return
    }

    if (isActionAccept) {
      await dispatch(actions.acceptViewBriefPayment(payment))
    } else if (isActionReject) {
      const reason = defaultReason ? defaultReason : alternativeReason ? alternativeReason : ''
      await dispatch(actions.rejectViewBriefPayment({ payment, reason }))
    }

    setAction(ACTIONS.PENDING)
    toggleConfirmationModal()
  }

  const hasReason = defaultReason || alternativeReason?.trim()
  const isSubmitButtonDisabled = isActionPending || (isActionReject && !hasReason)
  const showAlternativeReasonInput = defaultReason === null
  return (
    <React.Fragment>
      <ServicesSummary>
        <div className="SS__category ph-15">
          <Row className="mb-8 mt-16" gutter={16} align="middle" justify="space-between">
            <Col span={12}>
              <span className="fw-500">{t(`quotation`)}</span>
            </Col>
            <Col>
              <UploadComponent
                viewOnly
                alternativeFileView
                dragAndDrop={false}
                files={
                  payment.quotation ? [{ url: payment.quotation, name: getFileNameFromUrl(payment.quotation) }] : []
                }
                disableView={!hasPermission(permissions, PERMISSIONS.CLIENT_BILL_VIEW_DETAILS) && !hasPermission(permissions, PERMISSIONS.MANAGE_PO_BANK_TRANSFER_PAYMENTS_FOR_BRIEFS)}
                onView={async file => {
                  // const response: any = await dispatch(coreActions.downloadImage(file.url))
                  // const url = URL.createObjectURL(response.data)

                  window.open(file.url, '_blank')
                  URL.revokeObjectURL(file.url)
                }}
              />
            </Col>
          </Row>

          <Row className="mb-8" gutter={16} align="middle" justify="space-between">
            <Col span={12}>
              <span className="fw-500">{t(`po`)}</span>
            </Col>
            <Col>
              <UploadComponent
                viewOnly
                dragAndDrop={false}
                files={payment.po?.map(url => ({ url, name: getFileNameFromUrl(url) })) ?? []}
                showPreview={hasPermission(permissions, PERMISSIONS.CLIENT_BILL_VIEW_DETAILS) || hasPermission(permissions, PERMISSIONS.MANAGE_PO_BANK_TRANSFER_PAYMENTS_FOR_BRIEFS)}
                disableView={!hasPermission(permissions, PERMISSIONS.CLIENT_BILL_VIEW_DETAILS) && !hasPermission(permissions, PERMISSIONS.MANAGE_PO_BANK_TRANSFER_PAYMENTS_FOR_BRIEFS)}
                onView={async file => {
                  const response: any = await dispatch(coreActions.downloadImage(file.url))
                  const url = URL.createObjectURL(response.data)

                  window.open(url, '_blank')
                  URL.revokeObjectURL(url)
                }}
              />
            </Col>
          </Row>

          <Row gutter={16} align="middle" justify="space-between">
            <Col span={12}>
              <span className="fw-500">{t(`bank_transfer`)}</span>
            </Col>
            <Col>
              <UploadComponent
                viewOnly
                dragAndDrop={false}
                files={payment.bankTransfer?.map(url => ({ url, name: getFileNameFromUrl(url) })) ?? []}
                showPreview={hasPermission(permissions, PERMISSIONS.CLIENT_BILL_VIEW_DETAILS) || hasPermission(permissions, PERMISSIONS.MANAGE_PO_BANK_TRANSFER_PAYMENTS_FOR_BRIEFS)}
                disableView={!hasPermission(permissions, PERMISSIONS.CLIENT_BILL_VIEW_DETAILS) && !hasPermission(permissions, PERMISSIONS.MANAGE_PO_BANK_TRANSFER_PAYMENTS_FOR_BRIEFS)}
                onView={async file => {
                  const response: any = await dispatch(coreActions.downloadImage(file.url))
                  const url = URL.createObjectURL(response.data)

                  window.open(url, '_blank')
                  URL.revokeObjectURL(url)
                }}
              />
            </Col>
          </Row>

          <Form layout="vertical">
            <div className="mv-20">
              <Radio.Group className="qu-radio-row" value={action} onChange={handleActionChange}>
                <Radio.Button
                  className="qu-radio-accept"
                  value={ACTIONS.ACCEPT}
                  disabled={
                    !hasPermission(permissions, PERMISSIONS.BILL_APPROVE) ||
                    payment.status === BRIEF_PAYMENT_STATUS.PO_ISSUED ||
                    (!isBankTransferUploaded && !isPoUploaded)
                  }
                >
                  {
                    i18n.dir() === 'ltr' && (
                      <div className="qu-radio-center full-height">
                        <IconsCommon.IconAccept />
                        <span>{t(`accept`)}</span>
                      </div>
                    )
                  }
                  {
                    i18n.dir() === 'rtl' && (
                      <div className="qu-radio-center full-height">
                        <span>{t(`accept`)}</span>
                        <IconsCommon.IconAccept />
                      </div>
                    )
                  }

                </Radio.Button>
                <Radio.Button
                  className="qu-radio-reject"
                  value={ACTIONS.REJECT}
                  disabled={
                    !hasPermission(permissions, PERMISSIONS.BILL_REJECT) ||
                    payment.status === BRIEF_PAYMENT_STATUS.PO_ISSUED ||
                    (!isBankTransferUploaded && !isPoUploaded)
                  }
                >
                  {i18n.dir() === 'ltr' && <div className="qu-radio-center full-height">
                    <IconsCommon.IconClose />
                    <span>{t(`reject`)}</span>
                  </div>}
                  {i18n.dir() === 'rtl' && <div className="qu-radio-center full-height">
                    <span>{t(`reject`)}</span>
                    <IconsCommon.IconClose />
                  </div>}
                </Radio.Button>
              </Radio.Group>
            </div>

            {isActionReject && (
              <React.Fragment>
                <Form.Item colon={false} label={<b>{t(`rejection_reason`)}</b>}>
                  <QSelect
                    className="full-width"
                    showSearch
                    size="large"
                    placeholder={t(`select_reason_for_rejection`)}
                    help={t(`this_will_be_visible_to_client`)}
                    value={defaultReason}
                    onChange={(reason: string) => setDefaultReason(reason)}
                    options={reasonOptions}
                  />
                </Form.Item>

                {showAlternativeReasonInput && (
                  <Form.Item colon={false}>
                    <QInput
                      size="large"
                      placeholder={t(`enter_reason`)}
                      maxLength={255}
                      value={alternativeReason}
                      onChange={(event: any) => setAlternativeReason(event.target.value)}
                    />
                  </Form.Item>
                )}
              </React.Fragment>
            )}
          </Form>
        </div>
      </ServicesSummary>

      <QButton
        onClick={toggleConfirmationModal}
        disabled={isSubmitButtonDisabled}
        className={`${isActionAccept ? 'qu-button-outline-green' : 'qu-button-outline-red'}  full-width mv-20`}
      >
        {t(`submit`)}
      </QButton>

      {(isActionAccept || isActionReject) && (
        <p className={`p--sm text-center ${isActionReject && 'text-red'}`}>
          {t(`client_notified_help`)}
        </p>
      )}

      <Modal
        wrapClassName="qu-modal"
        closeIcon={<IconsCommon.IconClose />}
        title={isActionAccept ? t(`accept`) : t(`reject`)}
        visible={confirmationModalVisible}
        onCancel={() => {
          if (payment.isAccepting || payment.isRejecting) {
            return
          }

          toggleConfirmationModal()
        }}
        footer={
          <Row align="middle" justify="end">
            <Col>
              <QButton
                disabled={payment.isAccepting || payment.isRejecting}
                className="qu-button-outline mr-16"
                size="small"
                onClick={() => {
                  if (payment.isAccepting || payment.isRejecting) {
                    return
                  }

                  toggleConfirmationModal()
                }}
              >
                {t(`cancel`)}
              </QButton>
            </Col>
            <Col>
              <QButton
                disabled={payment.isAccepting || payment.isRejecting}
                className="w-100"
                type="primary"
                size="small"
                onClick={handleSubmit}
              >
                {t(`accept`)}
              </QButton>
            </Col>
          </Row>
        }
      >
        <p>{t(`accept_reject_confirm`, { action: isActionAccept ? t('accept') : t('reject') })}</p>
      </Modal>
    </React.Fragment>
  )
}

export default BriefReviewPayment
