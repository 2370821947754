import React, { useEffect, useState } from 'react'
import { Row, Col, Empty, QSelect, QRangePicker, QButton } from 'quantum_components'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { snakeCase } from 'lodash'
import {
  getCampaignAnalyticsInfo,
  getCampaignAnalyticsMediaBuyData,
  getCampaignAnalyticsMediaBuyFilter,
  getCampaignDetails,
} from '../../../campaign.selectors'
import SpinnerCenter from '../../../../../components/Spinner/spinner'
import { ICartServiceItem, IOptionForSelect } from '../../../../brief/brief.types'
import { IAvailableCampaignMediaBuyData, ICampaignMediaBuyFilter, ICampaignPartner } from '../../../campaign.types'
import { useReduxDispatch } from 'src/helpers'
import { actions } from '../../../campaign.module'
import { useParams } from 'react-router-dom'
import MediaBuyMetrics from 'src/components/MediaBuyMetrics/MediaBuyMetrics'
import { subtypeNamesByPartner } from 'src/helpers/campaign'
import { FORMATS } from 'src/constants'
import { getDateFromToday, getIsoDate } from 'src/helpers/datesHelper'
import moment from 'moment'
import { DATE_PRESET } from 'src/constants/dashboard'

const ClientAnalyticsMediaBuy = () => {
  const { i18n } = useTranslation()
  const { t } = useTranslation(['translationCampaign', 'translation', 'translationCommon'])
  const dispatch = useReduxDispatch()
  const campaignDetails = useSelector(getCampaignDetails)
  const analyticsInfo = useSelector(getCampaignAnalyticsInfo)
  const mediaFilter = useSelector(getCampaignAnalyticsMediaBuyFilter)
  const mediaBuyData = useSelector(getCampaignAnalyticsMediaBuyData)

  const { id } = useParams<{ id: any }>()
  const [loadingAnalytics, setLoadingAnalytics] = useState(false)

  const [partner, setPartner] = useState<ICampaignPartner | undefined>(undefined)
  const [dateRange, changeDateRange] = useState<any>([moment(getIsoDate(getDateFromToday(DATE_PRESET.WEEK))), moment(getIsoDate(moment().endOf('day')))])

  useEffect(() => {
    setDefaultFilter()
  }, [analyticsInfo])

  const setDefaultFilter = () => {
    if (!analyticsInfo || !analyticsInfo.availableCampaignMediaBuyData.length) return
    const first = analyticsInfo.availableCampaignMediaBuyData[0]
    const firstPartnerId = first.partnerId
    const firstMediaType = first.mediaBuyTypeId
    const firstPartner = analyticsInfo.partners.find((partner: ICampaignPartner) => partner.id === firstPartnerId)

    const filter = { ...mediaFilter }
    filter.partnerId = firstPartnerId
    filter.mediaType = firstMediaType

    setFilterAndFetch(filter)
    setPartner(firstPartner)
  }

  const setFilterAndFetch = async (filter: ICampaignMediaBuyFilter) => {
    setLoadingAnalytics(true)

    await dispatch(actions.setMediaBuyAnalyticsFilter(filter))
    if (!filter.partnerId || !filter.mediaType) {
      await dispatch(actions.campaignAnalyticsMediaBuySuccess(null))
      setLoadingAnalytics(false)
      return
    }

    await dispatch(actions.fetchCampaignAnalyticsMediaBuy(id, filter))
    setLoadingAnalytics(false)
  }

  useEffect(() => {
    if (!analyticsInfo?.partners) return
    setPartner(analyticsInfo.partners[0])
  }, [analyticsInfo])

  const handlePartner = (id: number) => {
    const filter = { ...mediaFilter }
    filter.partnerId = id
    filter.mediaType = null

    setFilterAndFetch(filter)

    const targetPartner = analyticsInfo?.partners.find((partner: ICampaignPartner) => partner.id === id)
    setPartner(targetPartner)
  }

  const handleMediaType = (type: string) => {
    const filter = { ...mediaFilter }
    filter.mediaType = type

    setFilterAndFetch(filter)
  }

  const partnerOptions: IOptionForSelect[] = analyticsInfo
    ? analyticsInfo.partners.map((partner: ICampaignPartner) => ({ label: partner.companyName, value: partner.id }))
    : []

  const mediaSubtypeNames = partner ? subtypeNamesByPartner(partner.mediaBuyTypes) : []

  const mediaSubTypeOptions = mediaSubtypeNames.map(
    (type: string): IOptionForSelect => {
      // const icon = showBox(type)
      return { label: t('services.media-buy.types.' + snakeCase(type), type, { ns: 'translation' }), value: type }
    }
  )

  const mediaSubTypeOptionsFromCart = campaignDetails?.cart?.additional?.items.length ? campaignDetails?.cart?.additional?.items[0].services.map(
    (service: ICartServiceItem) => {
      return { label: t('services.media-buy.types.' + snakeCase(service.name), service.name, { ns: 'translation' }), value: service.name }
    }
  ) : []

  const handleDatePicker = (range: any) => {
    if (!range) return
    const start = range && getIsoDate(range[0].startOf('day'))
    const end = range && getIsoDate(range[1].endOf('day'))

    const filter = { ...mediaFilter }
    filter.beforeDate = start
    filter.afterDate = end

    setFilterAndFetch(filter)

    changeDateRange([moment(start), moment(end)])
  }

  const handleResetFilter = () => {
    const filter = { ...mediaFilter }
    delete filter.beforeDate
    delete filter.afterDate

    setFilterAndFetch(filter)
    changeDateRange(null)
  }

  return (
    <div>
      {loadingAnalytics && (
        <SpinnerCenter
          text={t('details.Loading Analytics')}
        />
      )}
      <h2 className="mv-10">{t('details.media_buy')}</h2>
      <Row className="mb-10" gutter={16} data-html2canvas-ignore>
        <Col span={24}>
          <p className="font-weight-600 mb-8">{t('details.media_ad_types')}:</p>
        </Col>
        <Col xs={24} sm={8} style={{ display: 'none' }}>
          <QSelect
            className="full-width mb-16"
            showArrow
            placeholder={t('details.select_partner')}
            value={analyticsInfo && analyticsInfo.partners.length > 0 ? analyticsInfo?.partners[0].id : ""}
            options={partnerOptions}
            onChange={handlePartner}
            size="large"
          />
        </Col>
        <Col xs={24} sm={8}>
          <QSelect
            className="full-width mb-16"
            showArrow
            placeholder={t('details.Select Media Type')}
            value={mediaFilter.mediaType}
            // options={mediaSubTypeOptions}
            options={mediaSubTypeOptionsFromCart}
            onChange={handleMediaType}
            size="large"
          />
        </Col>
      </Row>
      <Row className="mb-10" gutter={16} data-html2canvas-ignore>
        <Col span={24}>{t('common.Filter_by_date', { ns: 'translationCommon' })}:</Col>
        <Col xs={24} sm={8}>
          <QRangePicker
            value={dateRange}
            allowClear={false}
            format={FORMATS.DATE_FORMAT}
            onChange={handleDatePicker}
            className="full-width"
            size="large"
          />
        </Col>
        <Col xs={12} sm={6}>
          <QButton type="primary" size="small" onClick={handleResetFilter}>
            {t('common.Clear filter', { ns: 'translationCommon' })}
          </QButton>
        </Col>
      </Row>
      <div className="grid-row grid-row--jcc">
        <div className="grid-col">
          {mediaBuyData && mediaBuyData.metrics ? (
            <MediaBuyMetrics
              selectedMetrics={mediaBuyData.selectedMetrics}
              mediaType={mediaFilter.mediaType || ''}
              details={mediaBuyData.metrics}
              dateRange={dateRange}
            />
          ) : (
            <div className="qu-chart-empty qu-chart-card h-300">
              <Empty />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ClientAnalyticsMediaBuy
