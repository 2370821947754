import { createSelector } from 'reselect'
import {
  IState,
  IServerError,
  IConstants,
  ICurrentUser,
  ISettingsSubType,
  ISettings,
  IDashboardSettings,
  IDashboardConstants,
} from './core.types'
import { MODULE_NAME } from './core.constants'
import { MENU_ITEMS } from '../../constants/menu'
import { IPermission } from '../roles/roles.types'
import { PERMISSIONS } from '../../constants/permission'
import { GENDER } from '../../constants/brief'
import { CURRENCY } from '../../constants/currencies'

const selectState = (state: { [MODULE_NAME]: IState }): IState => state[MODULE_NAME]

export const getShowProgress = createSelector(selectState, (state: IState): boolean => state.showProgress)

export const getProgress = createSelector(selectState, (state: IState): number => state.progress)

export const getServerError = createSelector(selectState, (state: IState): IServerError | null => state.serverError)

export const getIsUploading = createSelector(selectState, (state: IState): boolean => state.isUploading)

export const getIsDownloading = createSelector(selectState, (state: IState): boolean => state.isDownloading)

export const getFileUrls = createSelector(selectState, (state: IState): string[] => state.fileUrls)

export const getCurrentUser = createSelector(selectState, (state: IState): ICurrentUser => state.currentUser)

export const getActiveMenu = createSelector(selectState, (state: IState): MENU_ITEMS | null => state.activeMenu)

export const getCurrentUserPermissions = createSelector(
  selectState,
  (state: IState): IPermission[] => state.currentUserPermissions
)

export const getCurrentUserPermissionActions = createSelector(selectState, (state: IState): string[] =>
  state.currentUserPermissions.map(p => p.action)
)

export const getConstants = createSelector(selectState, (state: IState): IConstants => state.constants)

export const getSettingsMediaSubTypes = createSelector(selectState, (state: IState): ISettingsSubType[] => {
  const metrics = state.constants.campaignMediaBuyConstants
  return metrics ? metrics.mediaSubtypes : []
})

export const getConstantAgeGroups = createSelector(selectState, (state: IState): string[] => {
  const metrics = state.constants.ageGroupsGendersConstants
  return metrics ? metrics.ageGroups : []
})

export const getConstantsGenders = createSelector(selectState, (state: IState): string[] => {
  const metrics = state.constants.ageGroupsGendersConstants
  return metrics ? metrics.genderFocus : []
})

export const getConstantDashboard = createSelector(selectState, (state: IState): IDashboardConstants | undefined => {
  return state.constants.adminDashboardConstants
})

export const getCurrency = createSelector(selectState, (state: IState): CURRENCY => state.currency)

export const getSettings = createSelector(selectState, (state: IState): ISettings | null => state.settings)
export const getSettingsLoading = createSelector(selectState, (state: IState): boolean => state.settingsLoading)

export const getDashboardSettings = createSelector(
  selectState,
  (state: IState): IDashboardSettings | null => state.settings?.adminDashboardSettings || null
)

export const getNotificationCount = createSelector(
  selectState,
  (state: IState): number => state.currentUser.unreadNotificationCount
)

export const getCustomNotification = createSelector(
  selectState,
  (state: IState): any => state.customNotification
)

export const getIsLoading = createSelector(
  selectState,
  (state: IState): any => state.isLoading
)

export const getSubscriptionOverduePayment = createSelector(
  selectState,
  (state: IState): any => state.overduePayment
)

export const getIsPaymentLoading = createSelector(
  selectState,
  (state: IState): any => state.isPaymentLoading
)
