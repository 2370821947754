import { createSelector } from 'reselect'
import { MODULE_NAME } from './users.constants'
import { IPartnerProperties, IPartnerSettings, IPublisherListElement, IUserListElement } from './users.types'

const selectState = (state: { [MODULE_NAME]: any }) => state[MODULE_NAME]

export const getUsersListLoading = createSelector(selectState, (state): boolean => state.userListLoading)

export const getUsersList = createSelector(selectState, state =>
  state.usersList.results.map((e: IUserListElement) => ({
    ...e,
    key: e.id,
    fullname: `${e.firstName} ${e.lastName}`,
  }))
)

export const getTotalUsersList = createSelector(selectState, (state): number => state.usersList.total)

export const getPublishers = createSelector(selectState, (state): IPublisherListElement[] => state.publishersList)

export const getCurrentUser = createSelector(selectState, (state): IUserListElement => state.currentUser)

export const getPartnerProperties = createSelector(selectState, (state): IPartnerProperties => state.partnerProperties)

export const getPartnerSettings = createSelector(selectState, (state): IPartnerSettings => state.partnerSettings)

export const getPartnerPropertiesLoading = createSelector(
  selectState,
  (state): IPartnerProperties => state.partnerPropertiesLoading
)

export const getPages = createSelector(
  selectState,
  (
    state
  ): {
    totalPages: number
    currentPage: number
  } => state.pages
)
