import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { AxiosResponse } from 'axios'
import IAction from 'src/interfaces/IAction'

import * as CONSTANTS from './privacyPolicy.constants'
import { requestHttp, urls } from 'src/api'
import { getResponseErrorMessage } from 'src/helpers'

export const resetPrivacyPolicyState = (): IAction => ({
    type: CONSTANTS.RESET_PRIVACY_POLICY,
})

export const privacyPolicyRequest = (): IAction => ({
    type: CONSTANTS.FETCH_PRIVACY_POLICY_REQUEST,
})

export const privacyPolicySuccess = (privacyPolicy: any): IAction => ({
    type: CONSTANTS.FETCH_PRIVACY_POLICY_SUCCESS,
    payload: privacyPolicy,
})

export const privacyPolicyFailure = (error: string): IAction => ({
    type: CONSTANTS.FETCH_PRIVACY_POLICY_FAILURE,
    error,
})

export const getPrivacyPolicy = (): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<any> => {
    try {
        dispatch(privacyPolicyRequest())
        const response = await requestHttp.get(urls.getPrivacyPolicyUrl())
        const { content } = response.data.data
        dispatch(privacyPolicySuccess(content))
        return response
    } catch (error) {
        dispatch(privacyPolicyFailure(getResponseErrorMessage(error)))
        return error
    }
}
