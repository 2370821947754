// @ts-nocheck
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { isMobile } from 'react-device-detect'
import { Col, Row } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import {
  Dropdown,
  IconsCommon,
  Menu,
  QInput,
  QMenuItem,
  BillCardItem,
  QPagination,
  QSelect,
  QTable,
} from 'quantum_components'

import useDebounce from 'src/hooks/useDebounce'
import { BILL_REJECTION_REASONS, DEFAULT_BILL_TABLE_PARAMS } from 'src/constants/bill'
import {
  BRIEF_PAYMENT_STATUS,
  BRIEF_PAYMENT_STATUS_LIST,
  BRIEF_PAYMENT_STATUS_NAMES,
  briefPaymentStatuses,
} from 'src/constants/brief'
import { PERMISSIONS } from 'src/constants/permission'

import { getFileNameFromUrl } from 'src/utils'
import { useReduxDispatch } from 'src/helpers'
import { hasPermission } from 'src/helpers/permission'
import { getCurrentUser, getCurrentUserPermissionActions } from 'src/modules/core/core.selectors'
import * as coreActions from 'src/modules/core/core.actions'

import { IBillListElement, IBillPayment, ISelectInputOption } from '../bills.types'
import { getBillList, getBillListLoading, getBillParams, getPages, getTotalBillList } from '../bills.selectors'
import * as actions from '../bills.actions'

import UploadComponent from 'src/components/UploadComponent'
import AcceptBillingModal from './AcceptBillingModal'
import RejectBillingModal from './RejectBillingModal'
import {
  LOCALIZATION_LANGUAGES
} from "src/constants";

const ClientBillings = () => {
  const { i18n } = useTranslation()
  const { t } = useTranslation(['translationSettings', 'translation', 'translationCommon'])
  const history = useHistory()
  const dispatch = useReduxDispatch()
  const currentUser = useSelector(getCurrentUser)
  const permissions = useSelector(getCurrentUserPermissionActions)
  const billList = useSelector(getBillList)
  
  const totalBillList = useSelector(getTotalBillList)
  const billListLoading = useSelector(getBillListLoading)
  const params = useSelector(getBillParams)
  const pages = useSelector(getPages)

  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 500)

  const [acceptModalVisible, setAcceptModalVisible] = useState<boolean>(false)
  const [rejectModalVisible, setRejectModalVisible] = useState<boolean>(false)

  const toggleAcceptModal = () => setAcceptModalVisible(() => !acceptModalVisible)
  const toggleRejectModal = () => setRejectModalVisible(() => !rejectModalVisible)

  const [activeBillId, setActiveBillId] = useState<number | null>(null)
  const activeBill: IBillListElement | null = billList.find(bill => bill.payment.id === activeBillId) ?? null

  useEffect(() => {
    if (!acceptModalVisible && !rejectModalVisible) {
      setActiveBillId(null)
    }
  }, [acceptModalVisible, rejectModalVisible])

  useEffect(() => {
    const newParams = { ...params, search: debouncedSearch, offset: 0, limit: 10 }
    dispatch(actions.setParamsAndFetch({ ...newParams }))
    dispatch(actions.setCurrentPage(1))

    return () => dispatch(actions.setBillListParams(DEFAULT_BILL_TABLE_PARAMS))
  }, [debouncedSearch])

  const briefPaymentStatusOptions: ISelectInputOption[] = BRIEF_PAYMENT_STATUS_LIST.map(
    (paymentStatus: BRIEF_PAYMENT_STATUS) => ({
      label: t(BRIEF_PAYMENT_STATUS_NAMES[paymentStatus], { ns: 'translation' }),
      value: paymentStatus,
    })
  )

  const columns = [
    {
      title: t('settings.billings.Product'),
      dataIndex: 'brief',
      key: 'product',
      width: '25%',
      fixed: 'left',
      sorter: true,
      render: (brief, { payment }) => {
        // console.log("brieflist columns",brief )
        return (
          <div className="q-table-product">
            <div className="q-table-product_thumb">
              <img src={brief.image ?? 'https://via.placeholder.com/50x50'} alt={brief.campaignName ?? ''} />
            </div>
            <div className="q-table-product_info">
              <h3 className="q-table-product_title">{brief.campaignName || 'Unnamed Brief'}</h3>
              <div className="q-table-product_created">{t('common.created', { ns: 'translationCommon' })} {payment.createdAt}</div>
            </div>
          </div>
        )
      },
    },
    {
      title: t('settings.billings.Quotation'),
      dataIndex: 'payment',
      key: 'quotation',
      width: '17%',
      render: ({ quotation }: IBillPayment, bill) => {
        return (
          <div onClick={(event: React.MouseEvent<HTMLDivElement>) => event.stopPropagation()}>
            <UploadComponent
              viewOnly
              viewOnlyText={t('common.View', { ns: 'translationCommon' })}
              alternativeFileView
              dragAndDrop={false}
              files={quotation ? [{ url: quotation, name: getFileNameFromUrl(quotation) }] : []}
              disableView={!canCurrentUserViewBill(bill)}
              onView={async file => {
                // const response: any = await dispatch(coreActions.downloadImage(file.url))
                // const url = URL.createObjectURL(response.data)

                window.open(file.url, '_blank')
                URL.revokeObjectURL(file.url)
              }}
            />
          </div>
        )
      },
    },
    {
      title: t('settings.billings.PO'),
      dataIndex: 'payment',
      key: 'po',
      width: '18%',
      render: ({ po }: IBillPayment, bill) => {
        return (
          <div onClick={(event: React.MouseEvent<HTMLDivElement>) => event.stopPropagation()}>
            <UploadComponent
              viewOnly
              viewOnlyText={t('common.View', { ns: 'translationCommon' })}
              alternativeFileView
              dragAndDrop={false}
              files={po.files}
              showPreview={canCurrentUserViewBill(bill)}
              disableView={!canCurrentUserViewBill(bill)}
              onView={async file => {
                const response: any = await dispatch(coreActions.downloadImage(file.url))
                const url = URL.createObjectURL(response.data)

                window.open(url, '_blank')
                URL.revokeObjectURL(url)
              }}
            />
          </div>
        )
      },
    },
    {
      title: t('settings.billings.Invoice'),
      dataIndex: 'payment',
      key: 'invoice',
      width: '17%',
      render: ({ invoice }: IBillPayment, bill) => {
        return (
          <div onClick={(event: React.MouseEvent<HTMLDivElement>) => event.stopPropagation()}>
            <UploadComponent
              viewOnly
              viewOnlyText={t('common.View', { ns: 'translationCommon' })}
              alternativeFileView
              dragAndDrop={false}
              files={invoice?.map(url => ({ url, name: getFileNameFromUrl(url) })) ?? []}
              disableView={!canCurrentUserViewBill(bill)}
              onView={async file => {
                const response: any = await dispatch(coreActions.downloadImage(file.url))
                const url = URL.createObjectURL(response.data)

                window.open(url, '_blank')
                URL.revokeObjectURL(url)
              }}
            />
          </div>
        )
      },
    },
    {
      title: t('settings.billings.Bank Transfer'),
      dataIndex: 'payment',
      key: 'bankTransfer',
      width: '19%',
      render: ({ bankTransfer }: IBillPayment, bill) => {
        return (
          <div onClick={(event: React.MouseEvent<HTMLDivElement>) => event.stopPropagation()}>
            <UploadComponent
              viewOnly
              viewOnlyText={t('common.View', { ns: 'translationCommon' })}
              alternativeFileView
              dragAndDrop={false}
              files={bankTransfer.files}
              showPreview={canCurrentUserViewBill(bill)}
              disableView={!canCurrentUserViewBill(bill)}
              onView={async file => {
                const response: any = await dispatch(coreActions.downloadImage(file.url))
                const url = URL.createObjectURL(response.data)

                window.open(url, '_blank')
                URL.revokeObjectURL(url)
              }}
            />
          </div>
        )
      },
    },
    {
      title: t('settings.billings.Payment Status'),
      dataIndex: '',
      key: 'paymentStatus',
      width: '19%',
      fixed: 'right',
      render: (bill: IBillListElement) => {
        const paymentStatusClass = briefPaymentStatuses[bill.payment.status]?.classStyle ?? ''
        const paymentStatusLabel = briefPaymentStatuses[bill.payment.status]?.label ?? ''
        return (
          <div>
            {bill.payment.status ? (
              <span className={`status-label ${paymentStatusClass}`}>{t(paymentStatusLabel, { ns: 'translation' })}</span>
            ) : (
              <span>-</span>
            )}

            <div onClick={(event: React.MouseEvent<HTMLDivElement>) => event.stopPropagation()}>
              <Dropdown overlay={getAdditionalOptionsMenu(bill)} trigger={['click']} placement="bottomRight">
                <div className="q-table_menu">
                  <IconsCommon.IconEllipsisV />
                </div>
              </Dropdown>
            </div>
          </div>
        )
      },
    },
  ]

  const getAdditionalOptionsMenu = (bill: IBillListElement) => {
    const briefId = bill.payment.briefId
    const billId = bill.payment.id

    const isBankTransferUploaded = Boolean(bill.payment.bankTransfer.files.length)
    const isPoUploaded = Boolean(bill.payment.po.files.length)
    const isActionsDisabled =
      (!isBankTransferUploaded && !isPoUploaded) ||
      [BRIEF_PAYMENT_STATUS.PAID, BRIEF_PAYMENT_STATUS.REJECTED, BRIEF_PAYMENT_STATUS.PO_ISSUED].includes(
        bill.payment.status
      )
    return (
      <Menu className={`user-menu-list ${i18n.language === LOCALIZATION_LANGUAGES.ARABIC ? 'html-rtl' : ''}`}>
        <QMenuItem key={0} icon={<IconsCommon.IconView />} name={t('common.View', { ns: 'translationCommon' })} onClick={() => handleView(briefId,bill?.version)} />
        <QMenuItem
          key={1}
          icon={<IconsCommon.IconCheck />}
          name={t('common.Accept', { ns: 'translationCommon' })}
          disabled={!hasPermission(permissions, PERMISSIONS.BILL_APPROVE) || isActionsDisabled}
          onClick={() => {
            setActiveBillId(billId)
            toggleAcceptModal()
          }}
        />
        <Menu.Divider />
        <QMenuItem
          key={2}
          icon={<IconsCommon.IconCrossCircle />}
          name={t('common.Reject', { ns: 'translationCommon' })}
          disabled={!hasPermission(permissions, PERMISSIONS.BILL_REJECT) || isActionsDisabled}
          onClick={() => {
            setActiveBillId(billId)
            toggleRejectModal()
          }}
        />
      </Menu>
    )
  }

  const canCurrentUserViewBill = (brief: IBillListElement) => {
    const canCurrentUserViewOnlyAssignedBriefsDetails = hasPermission(
      permissions,
      PERMISSIONS.CLIENT_BILL_VIEW_DETAILS_FOR_ASSIGNED_BRIEFS_ONLY
    )
    const isCurrentUserAssignedToBrief = brief.assignedUsers.some(({ id }) => id === currentUser.id)

    const canCurrentUserViewBriefDetails = hasPermission(permissions, PERMISSIONS.CLIENT_BILL_VIEW_DETAILS)
    if (canCurrentUserViewBriefDetails) {
      return canCurrentUserViewOnlyAssignedBriefsDetails ? isCurrentUserAssignedToBrief : true
    }

    return canCurrentUserViewOnlyAssignedBriefsDetails ? isCurrentUserAssignedToBrief : false
  }

  const handleChangeSearch = e => {
    setSearch(e.currentTarget.value)
  }

  const handleChangeSelect = (activePaymentStatuses: string[]) => {
    dispatch(actions.setParamsAndFetch({ ...params, offset: 0, paymentStatus: activePaymentStatuses }))
  }

  const handleChangePage = (page, pageSize) => {
    dispatch(actions.setCurrentPage(page))
    dispatch(actions.setParamsAndFetch({ ...params, offset: (page - 1) * pageSize, limit: pageSize }))
  }

  const handleTableChange = (pagination, filters, sorter) => {
    const colKey = {
      product: 'name',
    }
    const sortingParams = {
      direction: sorter.order.slice(0, -3),
      sortField: colKey[sorter.columnKey],
    }

    dispatch(
      actions.setParamsAndFetch({
        ...params,
        ...sortingParams,
      })
    )
  }

  const handleView = (briefId: number, version:string) => {
    if(version == "2"){
      history.push(`/briefs/v3/edit/${briefId}`)
    }else{
      history.push(`/briefs/view/${briefId}`)
    }
    
  }

  const handleOnRowClick = (bill: IBillListElement,rowIndex:Number) => ({
    onClick: () => {
      try {
        document.getElementsByClassName('q-table_menu')[rowIndex].click();  
      } catch (error) {
      } 
      // handleView(bill.payment.briefId)
    },
  })

  const handleBillingAccept = async () => {
    if (activeBill?.isAccepting) {
      return
    }

    await dispatch(actions.acceptBill(activeBill))

    toggleAcceptModal()
  }

  const handleBillingReject = async (reason: string) => {
    if (activeBill?.isRejecting) {
      return
    }

    await dispatch(actions.rejectBill({ bill: activeBill, reason }))

    toggleRejectModal()
  }

  return (
    <div className={`qu-brief-list pb-20  ${i18n.language === LOCALIZATION_LANGUAGES.ARABIC ? 'html-rtl' : ''}`}>
      <Row className={`${isMobile && 'pb-15'}`} justify={isMobile ? 'center' : 'space-between'}>
        <Col className="qt-search">
          <QInput
            className={`${isMobile && 'full-width'}`}
            value={search}
            onChange={handleChangeSearch}
            placeholder={t('settings.billings.Search')}
            size="large"
            prefix={<SearchOutlined />}
          />
        </Col>
        {!isMobile && (
          <Col className="qt-filter">
            <QSelect
              showArrow
              maxTagTextLength={8}
              maxTagCount={2}
              value={params.paymentStatus}
              onChange={handleChangeSelect}
              mode="multiple"
              size="large"
              placeholder={t('settings.billings.Filter by status')}
              options={briefPaymentStatusOptions}
            />
          </Col>
        )}
      </Row>
      {isMobile ? (
        <div className="pl-15 pr-15">
          <ul className="qu-request-list">
            {billList.map(bill => {
              return (
                <BillCardItem
                  key={bill.key}
                  bill={bill}
                  onCardClick={() => handleView(bill.payment.briefId,bill.version)}
                  onPayClick={() => handleView(bill.payment.briefId,bill.version)}
                  paymentStatus={briefPaymentStatuses}
                  quotationNode={
                    <div onClick={(event: React.MouseEvent<HTMLDivElement>) => event.stopPropagation()}>
                      <UploadComponent
                        viewOnly
                        alternativeFileView
                        dragAndDrop={false}
                        files={
                          bill.payment.quotation
                            ? [{ url: bill.payment.quotation, name: getFileNameFromUrl(bill.payment.quotation) }]
                            : []
                        }
                        disableView={!canCurrentUserViewBill(bill)}
                        onView={async file => {
                          const response: any = await dispatch(coreActions.downloadImage(file.url))
                          const url = URL.createObjectURL(response.data)

                          window.open(url, '_blank')
                          URL.revokeObjectURL(url)
                        }}
                      />
                    </div>
                  }
                  bankTransferNode={
                    <div onClick={(event: React.MouseEvent<HTMLDivElement>) => event.stopPropagation()}>
                      <UploadComponent
                        viewOnly
                        dragAndDrop={false}
                        files={bill.payment.bankTransfer.files}
                        showPreview={canCurrentUserViewBill(bill)}
                        disableView={!canCurrentUserViewBill(bill)}
                        onView={async file => {
                          const response: any = await dispatch(coreActions.downloadImage(file.url))
                          const url = URL.createObjectURL(response.data)

                          window.open(url, '_blank')
                          URL.revokeObjectURL(url)
                        }}
                      />
                    </div>
                  }
                  poNode={
                    <div onClick={(event: React.MouseEvent<HTMLDivElement>) => event.stopPropagation()}>
                      <UploadComponent
                        viewOnly
                        dragAndDrop={false}
                        files={bill.payment.po.files}
                        showPreview={canCurrentUserViewBill(bill)}
                        disableView={!canCurrentUserViewBill(bill)}
                        onView={async file => {
                          const response: any = await dispatch(coreActions.downloadImage(file.url))
                          const url = URL.createObjectURL(response.data)

                          window.open(url, '_blank')
                          URL.revokeObjectURL(url)
                        }}
                      />
                    </div>
                  }
                  invoiceNode={
                    <div onClick={(event: React.MouseEvent<HTMLDivElement>) => event.stopPropagation()}>
                      <UploadComponent
                        viewOnly
                        alternativeFileView
                        dragAndDrop={false}
                        files={bill.payment.invoice?.map(url => ({ url, name: getFileNameFromUrl(url) })) ?? []}
                        disableView={!canCurrentUserViewBill(bill)}
                        onView={async file => {
                          const response: any = await dispatch(coreActions.downloadImage(file.url))
                          const url = URL.createObjectURL(response.data)

                          window.open(url, '_blank')
                          URL.revokeObjectURL(url)
                        }}
                      />
                    </div>
                  }
                />
              )
            })}
          </ul>
        </div>
      ) : (
        <div className="pt-15 pb-30">
          <QTable
            onRow={handleOnRowClick}
            onChange={handleTableChange}
            sortDirections={['ascend', 'descend', 'ascend']}
            loading={{
              spinning: billListLoading,
              indicator: <div className="loader loader-small" />,
            }}
            columns={columns}
            dataSource={billList}
            scroll={{ x: 990 }}
            rowClassName="q-table cursorPointer"
          />
        </div>
      )}

      <Row justify={isMobile ? 'center' : 'end'} className={`${isMobile && 'pt-15 mb-30'}`}>
        <QPagination
          size="default"
          defaultCurrent={1}
          current={pages.currentPage}
          total={totalBillList}
          onChange={handleChangePage}
        />
      </Row>

      <AcceptBillingModal
        visible={acceptModalVisible}
        onSubmit={handleBillingAccept}
        onCancel={() => {
          if (activeBill?.isAccepting) {
            return
          }

          toggleAcceptModal()
        }}
      />

      <RejectBillingModal
        defaultReasons={BILL_REJECTION_REASONS}
        visible={rejectModalVisible}
        onSubmit={handleBillingReject}
        onCancel={() => {
          if (activeBill?.isRejecting) {
            return
          }

          toggleRejectModal()
        }}
      />
    </div>
  )
}

export default ClientBillings
