import React, { Fragment, ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useReduxDispatch } from 'src/helpers'
import { isMobile } from 'react-device-detect'
import * as actions from '../campaign.actions'
import { Col, QBackLink, QButton, Radio, Row } from 'quantum_components'
import { Tooltip } from "antd"
import { useSelector } from 'react-redux'
import {
  getCampaignAnalyticsDistFilter,
  getCampaignDetails,
  getCampaignDetailsLoading,
  getCampaignUpdating,
} from '../campaign.selectors'
import { BRIEF_PAYMENT_STATUS, BRIEF_PRINT_ID, BRIEF_TYPES } from '../../../constants/brief'
import BriefSwitch from '../../brief/containers/components/Brief.Switch'
import BriefCartSummary from '../../brief/containers/components/Brief.CartSummary'
import ServiceCard from '../../brief/containers/components/Brief.Service'
import BriefProduct from '../../brief/containers/components/Brief.Product'
import BriefMedia from '../../brief/containers/components/Brief.Media'
import BriefTargeting from '../../brief/containers/components/Brief.Targeting'
import BriefAdditional from '../../brief/containers/components/Brief.Additional'
import BriefViewSummary from '../../brief/containers/components/Brief.View.Summary'
import SpinnerCenter from '../../../components/Spinner/spinner'
import { daysPastFromToday, getDaysAgoText } from '../../../helpers/datesHelper'
import moment from 'moment'
import { FORMATS, PATHS } from '../../../constants'
import { TABS_NAMES } from '../../../constants/tabs'
import * as briefActions from '../../brief/brief.actions'
import { getActiveTab } from '../../brief/brief.selectors'
import RequestTableComponent from '../../../components/requestsTable/RequestTable.component'
import { requestsToTable } from '../../../helpers/request'
import { CAMPAIGN_PRINT_ANALYTICS_ID, CAMPAIGN_STATUS, CAMPAIGN_TIMELINES_LIMIT } from '../../../constants/campaign'
import DialogModal from '../../../components/DialogModal/DialogModal'
import ClientAnalytics from './components/Analytics/ClientAnalytics'
import { hasPermission } from '../../../helpers/permission'
import { getCurrency, getCurrentUserPermissionActions } from '../../core/core.selectors'
import { PERMISSIONS } from '../../../constants/permission'
import * as coreActions from '../../core/core.actions'
import { CORE_CONSTANTS } from '../../../constants/settings'
import { IRequest } from '../../partner/requests/request.types'
import ClientSurveys from './components/Survey/ClientSurveys'
import CompleteCampaignButton from './components/CompleteCampaign/CompleteCampaignButton'

export default function CampaignDetailsContainer(): ReactElement {
  const { i18n } = useTranslation()
  const { t } = useTranslation(['translationCampaign', 'translationCommon', 'translationABrief'])
  const history = useHistory()
  const dispatch = useReduxDispatch()
  const campaignDetails = useSelector(getCampaignDetails)
  const campaignDetailsLoading = useSelector(getCampaignDetailsLoading)
  const campaignUpdating = useSelector(getCampaignUpdating)
  const activeTab = useSelector(getActiveTab)
  const distFilter = useSelector(getCampaignAnalyticsDistFilter)
  const permissions = useSelector(getCurrentUserPermissionActions)
  const currency = useSelector(getCurrency)

  const location = useLocation();

  const [showSummary, setShowSummary] = useState(false)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [campaignCompleting, setCampaignCompleting] = useState(false)
  const { id } = useParams<{id: any}>();

  useEffect(() => {
    dispatch(actions.fetchCampaignDetails(id))
    fetchAnalytics()
    fetchSettings()
    dispatch(briefActions.fetchBriefSettings())

    return () => {
      dispatch(actions.resetCampaignState())
    }
  }, [])

  const fetchAnalytics = () => {
    // dispatch(actions.fetchCampaignAnalyticsData(id, distFilter))
    dispatch(actions.fetchCampaignAnalyticsInfo(id))
    dispatch(actions.fetchCampaignTimeline(id, { offset: 0, limit: CAMPAIGN_TIMELINES_LIMIT }))
  }

  const fetchSettings = () => {
    dispatch(coreActions.fetchConstants([CORE_CONSTANTS.CAMPAIGN_MEDIA_BUYS, CORE_CONSTANTS.AGE_GROUPS_GENDERS]))
  }

  const changeSwitch = (e: any) => {
    const { value } = e.target
    setShowSummary(value)
  }

  const handleChangeTab = (e: any) => {
    dispatch(briefActions.setActiveTab(e.target.value))
  }

  if (!campaignDetails || campaignDetailsLoading) {
    return <SpinnerCenter />
  }

  const requestList = requestsToTable(campaignDetails.requests, currency)

  const brief = campaignDetails.brief
  const cart = campaignDetails.cart
  const partnerIds = campaignDetails.requests.map((request: IRequest) => request.partnerId)

  const isProductSample = brief.briefTypes?.includes(BRIEF_TYPES.PRODUCT_SAMPLE)
  const isMediaBuying = brief.briefTypes?.includes(BRIEF_TYPES.MEDIA_BUYING)
  const isCompleted = campaignDetails.status === CAMPAIGN_STATUS.COMPLETED
  const disabledButton = isCompleted || campaignUpdating || !hasPermission(permissions, PERMISSIONS.CAMPAIGN_COMPLETE)
  const summaryTitle = `Received from ${campaignDetails.briefOwner?.companyName || ''}`

  const summarySubtitle = () => {
    const dateMoment = moment(campaignDetails.startedDate)
    const approvedDate = moment(campaignDetails.startedDate).format(FORMATS.DATE_LL_FORMAT)
    const days = daysPastFromToday(dateMoment)
    const text = getDaysAgoText(days)

    return `${approvedDate}. (${t(text, { ns: 'translationABrief', days })})`;
  }

  const handleBack = () => {
    history.push(`${PATHS.BRIEF}${location.search}`)
  }

  const handleComplete = async () => {
    // if (campaignDetails.totalQuantity !== campaignDetails.allocatedQuantity) {
    //   setShowModal(true)
    //   return
    // }

    setCampaignCompleting(true)
    await dispatch(actions.completeCampaign(id))
    setCampaignCompleting(false)
  }

  const proceedComplete = async () => {
    setCampaignCompleting(true)
    await dispatch(actions.completeCampaign(id))
    closeDialogModal()
  }

  const modalQuestion = 'Distributed quantity does not match the amount assigned, Close Campaign anyway?'
  const buttonText = 'Go Back & Update'

  const closeDialogModal = () => {
    setShowModal(false)
    setCampaignCompleting(false)
  }

  const handleEdit = (campaignId: number) => {
    history.push(`/campaigns/edit/${campaignId}`)
  }

  return (
    <div className="qu-brief-view pb-40">
      {campaignCompleting && (
        <SpinnerCenter
          text={campaignDetails.payment?.status === BRIEF_PAYMENT_STATUS.PO_ISSUED ? 'Generating Invoice…' : ''}
        />
      )}
      <QBackLink data-rtl="arrow" title={t('details.Back to Campaigns')} onClick={handleBack} />
      <Row className={'mt-16'} gutter={16} align="middle">
        <Col span={12}>
          <Radio.Group className="account-selector qu-radio-group full-width" buttonStyle="solid" value={activeTab}>
            <Radio.Button
              className="qu-radio-button qu-radio-white"
              value={TABS_NAMES.BRIEF}
              onChange={handleChangeTab}
            >
              <span className="text-uppercase full-width">{t('details.Brief')}</span>
            </Radio.Button>
            <Radio.Button
              disabled={!hasPermission(permissions, PERMISSIONS.REQUEST_ALL)}
              className="qu-radio-button qu-radio-white"
              value={TABS_NAMES.REQUESTS}
              onChange={handleChangeTab}
            >
              <span className="text-uppercase full-width">{t('details.requests')}</span>
            </Radio.Button>
            <Radio.Button
              className="qu-radio-button qu-radio-white"
              value={TABS_NAMES.CLIENT_CHART}
              onChange={handleChangeTab}
            >
              <span className="text-uppercase full-width">{t('details.analytics')}</span>
            </Radio.Button>
            {/* <Radio.Button
              className="qu-radio-button qu-radio-white"
              value={TABS_NAMES.SURVEY}
              onChange={handleChangeTab}
            >
              <span className="text-uppercase full-width">Surveys</span>
            </Radio.Button> */}
          </Radio.Group>
        </Col>
        {
          hasPermission(permissions, PERMISSIONS.CAMPAIGN_EDIT) &&
          <Col span={12}>
            <Row justify="end">
              <Col>
                {
                  campaignDetails.status === CAMPAIGN_STATUS.COMPLETED
                    ? <Tooltip title={t("details.Completed campaign cannot be edited", { ns: "translationCampaign" })}>
                      <button
                        disabled={campaignDetails.status === CAMPAIGN_STATUS.COMPLETED}
                        onClick={() => handleEdit(id)}
                        type="button"
                        className="button button--link edit-campaign-link mr-5"
                      >
                        {t('brief.header-title.EDIT', { ns: 'translationCommon' })}
                      </button>
                    </Tooltip>
                    : <button
                      onClick={() => handleEdit(id)}
                      type="button"
                      className="button button--link edit-campaign-link mr-5"
                    >
                      {t('brief.header-title.EDIT', { ns: 'translationCommon' })}
                    </button>
                }
              </Col>
            </Row>
          </Col>
        }
      </Row>
      {activeTab === TABS_NAMES.BRIEF && (
        <React.Fragment>
          {isMobile ? (
            <Fragment>
              <BriefSwitch onChange={changeSwitch} value={showSummary} />
              {showSummary ? (
                <Fragment>
                  <BriefCartSummary
                    setCurrentStep={() => null}
                    cart={cart}
                    onEdit={() => null}
                    viewMode
                    hidePaymentSection
                  />
                </Fragment>
              ) : (
                <Fragment>
                  <ServiceCard
                    isProductSample={isProductSample}
                    isMediaBuying={isMediaBuying}
                    onEdit={() => null}
                    viewMode
                  />
                  <BriefProduct briefView={brief} onEdit={() => null} viewMode />
                  <BriefMedia briefView={brief} onEdit={() => null} viewMode />
                  <BriefTargeting briefView={brief} onEdit={() => null} viewMode />
                  <BriefAdditional briefView={brief} onEdit={() => null} viewMode />
                </Fragment>
              )}
            </Fragment>
          ) : (
            <Row gutter={20} className="pt-20" id={BRIEF_PRINT_ID}>
              <Col span={16}>
                <BriefViewSummary
                  briefView={brief}
                  setCurrentStep={() => null}
                  title={summaryTitle}
                  subtitle={summarySubtitle()}
                  viewMode
                  partnerIds={partnerIds}
                />
              </Col>
              <Col span={8}>
                <BriefCartSummary
                  setCurrentStep={() => null}
                  cart={cart}
                  onEdit={() => null}
                  viewMode
                  hidePaymentSection
                  hideProposalButtons
                />
                <div className="mv-20" data-html2canvas-ignore>
                  <CompleteCampaignButton
                    disableComplete={disabledButton}
                    handleComplete={handleComplete}
                    buttonText={isCompleted ? t('details.Completed') : t('details.Complete Campaign')}
                  />
                </div>
              </Col>
            </Row>
          )}
        </React.Fragment>
      )}
      {activeTab === TABS_NAMES.REQUESTS && (
        <div className="mt-30">
          <RequestTableComponent
            requestList={requestList}
            loading={false}
            briefId={brief.id}
            hideSearch
            hidePagination
          />
        </div>
      )}
      {activeTab === TABS_NAMES.CLIENT_CHART && (
        <div className="mt-30" id={CAMPAIGN_PRINT_ANALYTICS_ID}>
          <ClientAnalytics />
        </div>
      )}
      {activeTab === TABS_NAMES.SURVEY && (
        <div className="mt-30">
          <ClientSurveys />
        </div>
      )}
      <DialogModal
        modalOpen={showModal}
        question={modalQuestion}
        description={''}
        submitButtonText={buttonText}
        cancelButtonText={'Proceed Anyways'}
        onClose={closeDialogModal}
        onProceed={proceedComplete}
      />
    </div>
  )
}
