import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { AxiosResponse } from 'axios'
import IAction from 'src/interfaces/IAction'

import * as CONSTANTS from './disclaimer.constants'
import { requestHttp, urls } from 'src/api'
import { getResponseErrorMessage } from 'src/helpers'

export const resetDisclaimerState = (): IAction => ({
    type: CONSTANTS.RESET_DISCLAIMER,
})

export const disclaimerRequest = (): IAction => ({
    type: CONSTANTS.FETCH_DISCLAIMER_REQUEST,
})

export const disclaimerSuccess = (disclaimer: any): IAction => ({
    type: CONSTANTS.FETCH_DISCLAIMER_SUCCESS,
    payload: disclaimer,
})

export const disclaimerFailure = (error: string): IAction => ({
    type: CONSTANTS.FETCH_DISCLAIMER_SUCCESS,
    error,
})

export const getDisclaimer = (): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<any> => {
    try {
        dispatch(disclaimerRequest())
        const response = await requestHttp.get(urls.getDisclaimerUrl())
        const { content } = response.data.data
        dispatch(disclaimerSuccess(content))
        return response
    } catch (error) {
        dispatch(disclaimerFailure(getResponseErrorMessage(error)))
        return error
    }
}
