import React, { useEffect, useState } from "react";

import { useReduxDispatch } from "../../../../helpers";
import * as actions from "../create-client.actions";
import {
  Modal,
  Form,
  Row,
  Col,
  QButton,
  QTable,
  QInput,
  IconsCommon,
  QButtonEye,
  QBackLink,
} from "quantum_components";
import { validateMessages } from "../../../../helpers/validationMessage";
import { ReactComponent as ArrowRight } from "../../../../assets/icons/arrow-right-icon.svg";
import { ReactComponent as ArrowLeft } from "../../../../assets/icons/arrow-left-icon.svg";

import { REGEXES, ROLES } from "src/constants";
import { VALIDATION_MESSAGE } from "src/constants/validationMessages";
import { getCurrentState } from "../create-client.selectors";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import i18n from "src/i18n";
import { useHistory } from "react-router-dom";

const formFields = {
  companyName: '',
  email: '',
  firstName: '',
  lastName: '',
  password: '',
  phone: '',
  username: ''
}

const CreateClient = () => {
  const {t} = useTranslation(['translationClient'])
  const history = useHistory();
  const dispatch = useReduxDispatch();
  const currentState = useSelector(getCurrentState)
  const [form] = Form.useForm();
  const [passwordIsVisible, setPasswordVisible] = useState<boolean>(false);

  const initialValues = { role: ROLES.CLIENT };

  const getPasswordInputType = (passwordIsVisible: boolean) => {
    return passwordIsVisible ? "text" : "password";
  };

  const handlePasswordVisibility = () => {
    setPasswordVisible(!passwordIsVisible);
  };

  const onFinish = (values: any) => {
    let formValues = { ...values, role: ROLES.CLIENT };
    dispatch(actions.createClient(formValues));
  };

  useEffect(()=>{
    if(currentState.error === 'no_error'){
      resetForm();
    }
  },[currentState.error])

  const resetForm = () => {
    Object.keys(formFields).forEach((el)=>{
      form.setFieldsValue({[el]:''})
    })
  }

  return (
    <div className="auth-form">
        <QBackLink
          title={t("Back")}
          onClick={() => history.push("/users/clients")}
        />
        <h1 className="font-size-26">{t(`clients.create_client`)}</h1>
        <Form
          form={form}
          layout="vertical"
          hideRequiredMark
          initialValues={initialValues}
          validateMessages={validateMessages}
          onFinish={onFinish}
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                label={t(`clients.company_name`)}
                name="companyName"
                rules={[{ required: true, max: 255, whitespace: true }]}
                validateTrigger={["onChange", "onBlur"]}
              >
                <QInput placeholder={t(`clients.your_company_name`)} size="large" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t(`clients.first_name`)}
                name="firstName"
                rules={[{ required: true, max: 255, whitespace: true }]}
                validateTrigger={["onChange", "onBlur"]}
              >
                <QInput placeholder={t(`clients.first_name`)} size="large" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t(`clients.last_name`)}
                name="lastName"
                rules={[{ required: true, max: 255, whitespace: true }]}
                validateTrigger={["onChange", "onBlur"]}
              >
                <QInput placeholder={t(`clients.last_name`)} size="large" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={t(`clients.username`)}
                name="username"
                rules={[{max: 255, min:8, pattern:/^[a-z_0-9]{8,255}$/,message:t("clients.username_validation")}]}
                // rules={[{ required: true, max: 255, whitespace: true }]}
                validateTrigger={["onChange", "onBlur"]}
              >
                <QInput
                  placeholder={t(`clients.your_username`)}
                  size="large"
                  type="text"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={t(`clients.company_email`)}
                name="email"
                rules={[
                  { required: true, max: 255, type: "email", whitespace: true },
                ]}
                validateTrigger={["onChange", "onBlur"]}
              >
                <QInput
                  placeholder={t(`clients.your_company_email_address`)}
                  size="large"
                  type="email"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={t(`clients.phone_number`)}
                name="phone"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    pattern: REGEXES.TEN_DIGIT,
                    message: t('clients.your_phone_number'),
                  },
                ]}
                // rules={[
                //   {
                //     required: true,
                //     whitespace: true,
                //     pattern: REGEXES.PHONE_BODY_REGEX,
                //     message: t(`clients.phone_validation`)
                //   },
                // ]}
                validateTrigger={["onChange", "onBlur"]}
              >
                <QInput placeholder={t(`clients.your_phone_number`)} size="large" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={t(`clients.password`)}
                name="password"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    pattern: REGEXES.PASSWORD,
                    message: t(`clients.password_validation`),
                  },
                ]}
                validateTrigger={["onChange", "onBlur"]}
              >
                <QInput
                  size="large"
                  placeholder={t(`clients.your_password`)}
                  style={{ paddingRight: 50 }}
                  type={getPasswordInputType(passwordIsVisible)}
                />
              </Form.Item>
              <QButtonEye
                onClick={handlePasswordVisibility}
                isVisible={passwordIsVisible}
              />
            </Col>
          </Row>
          <QButton
            onClick={onFinish}
            className="text-capitalize"
            type="primary"
            block
            htmlType="submit"
          >
            {t(`clients.create_account`)}
            {i18n.dir() ==='ltr'?<ArrowRight className="ml-5" />:<ArrowLeft className="mr-5" />}
            
          </QButton>
        </Form>
      </div>
  );
};

export default CreateClient;
