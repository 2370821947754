import { Col, Divider, Row, Tag, Radio } from "antd";
import { AgeGroupCard } from "src/modules/brief/containers/components/Brief.AgeGroupCard";
import lodash, { includes } from "lodash";
import React, { useEffect, useState } from "react";
import SpinnerCenter from "src/components/Spinner/spinner";
import { Modal, QSelect } from "quantum_components";
import { useTranslation } from "react-i18next";
import i18n from "src/i18n";

const V3TargetingComponent = ({
  briefSettings,
  state,
  setState,
  arrayIndex,
  canEdit,
  isLoading,
  setIsLoading,
}: any) => {
  const { t } = useTranslation(["translationV2"]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (lodash.has(briefSettings, "MINIMUM_DISTRIBUTION_LOGISTIC_COUNT")) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [briefSettings]);

  const handlePriorityChange = (priority: string) => {
    let ss = state.map((s: any, index: any) => {
      if (index === arrayIndex) {
        s.targetingPriority = priority;
      }
      return s;
    });

    setState(ss);
  };

  const handleTargetingChangeV2 = (selectedOption:string,control:string) => {
    let st = { ...state[arrayIndex].targeting };
    st = { ...st, [control]: selectedOption };

    let ss = state.map((s: any, index: any) => {
      if (index === arrayIndex) {
        s.targeting = st;
      }
      return s;
    });

    setState(ss);
  }

  const handleTargetingChange = (
    e: any,
    selectedTarget: any,
    selectedOption: string
  ) => {
    let st = { ...state[arrayIndex].targeting };

    if (lodash.has(st, selectedTarget.name)) {
      let values = st[selectedTarget.name];
      if (!values.includes(selectedOption)) {
        values.push(selectedOption);
      } else {
        lodash.pull(values, selectedOption);
      }
    } else {
      st = { ...st, [selectedTarget.name]: [selectedOption] };
    }

    let ss = state.map((s: any, index: any) => {
      if (index === arrayIndex) {
        s.targeting = st;
      }
      return s;
    });

    setState(ss);
    // setState(st);
  };

  const checkIsChecked = (key: any, value: any) => {
    if (!lodash.has(state[arrayIndex].targeting, key)) return false;

    if (!lodash.get(state[arrayIndex].targeting, key).includes(value))
      return false;

    return true;
  };

  return (
    <div>
      {(lodash.isEmpty(state[arrayIndex]?.targeting) ||
        Object.values(state[arrayIndex]?.targeting).every((o) =>
          lodash.isEmpty(o)
        )) && (
        <div>
          {t("No Keywords Yet")}{" "}
          <a href="#" onClick={() => canEdit && setShowModal(true)}>
            {t("Add Now.")}
          </a>
        </div>
      )}

      <div>
        {state[arrayIndex]?.targeting &&
          Object.keys(state[arrayIndex]?.targeting).map((key) => {
            {
              return state[arrayIndex].targeting[key].map(
                (t: string, index: number) => (
                  <Tag
                    key={index}
                    className="mb-5 tag-preview"
                    onClick={() => canEdit && setShowModal(true)}
                  >
                    {t}
                  </Tag>
                )
              );
            }
          })}
      </div>

      <Modal
        wrapClassName="qu-modal"
        visible={showModal}
        footer={false}
        closable={true}
        width={675}
        onCancel={(e) => {
          e.stopPropagation();
          setShowModal(false);
        }}
      >
        <div className="v3-section">
          <h3 className="mb-20">{t("Edit Targeting")}</h3>

          <div>
            <div>
              <h6 className="mb-0">{t("Targeting Priority")}</h6>
            </div>
            <div className={`${i18n.dir() === "rtl" ? "pl-30" : "pr-30"} mb-10`}>
                <div><small>{t("Casual: Match any one of the selected keywords")}</small></div>
                <div><small>{t("Strict: Match all selected keywords")}</small></div>
            </div>
            <div>
              <Radio.Group
                onChange={(e: any) => handlePriorityChange(e.target.value)}
                value={state[arrayIndex]?.targetingPriority}
              >
                <Radio value={"CASUAL"}>{t("Casual")}</Radio>
                <Radio value={"STRICT"}>{t("Strict")}</Radio>
              </Radio.Group>
            </div>
            <Divider></Divider>
          </div>

          {
            briefSettings?.targetingKeys?.map((key:any,index:number)=>{
              return (
                <>
                  <Row gutter={24} className={"mb-20"} key={index}>
                    <Col md={8} xs={24}>{key.name}</Col>
                    <Col md={16} xs={24}>
                    <QSelect
                        // disabled={targetingLogic.length < 1}
                        maxTagTextLength={10}
                        maxTagCount={2}
                        mode="multiple"
                        className="full-width"
                        placeholder={t("Please select")}
                        options={key.options.map((o:any)=>{return {label:o,value:o}})}
                        value={state[arrayIndex]["targeting"][key.name]}
                        onChange={(action: string) => handleTargetingChangeV2(action,key.name)}
                        size="large"
                        
                      />
                    </Col>
                  </Row>
                </>
              )
            })
          }

          {/* {briefSettings?.targetingKeys?.map((tk: any, index: number) => {
            return (
              <div key={tk.name}>
                <h6 key={index + 100} className="mt-20">
                  {tk.name}
                </h6>
                <Row key={index + 200} className="v3-targeting">
                  {tk.options.map((op: string, index2: number) => {
                    const isChecked = checkIsChecked(tk.name, op);
                    return (
                      <Col
                        key={index2 + 777}
                        sm={6}
                        md={6}
                        xs={12}
                        className="brief-v3-services-container"
                      >
                        <AgeGroupCard
                          key={op}
                          checked={isChecked}
                          label={op}
                          sublabel={""}
                          img={
                            "https://quantum-files-bucket-new.s3.me-south-1.amazonaws.com/briefs_product_images/3284/1686555853227-goal.png"
                          }
                          onChange={(e: any) =>
                            handleTargetingChange(e, tk, op)
                          }
                        />
                      </Col>
                    );
                  })}
                </Row>
              </div>
            );
          })} */}
        </div>
      </Modal>
    </div>
  );
};

export default React.memo(V3TargetingComponent);
