import React, { Fragment, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { PARTNER_TABS } from 'src/constants/tabs'
import { PERMISSIONS } from 'src/constants/permission'

import { hasPermission } from 'src/helpers/permission'
import { getCurrentUser, getCurrentUserPermissionActions } from 'src/modules/core/core.selectors'

import SwitchProfiles from './SwitchProfiles'
import PublicProfile from '../publicProfile/containers/PublicProfile.container'
import ProfilePartnerContainer from '../partner-Profile/containers/ProfilePartner.container'
import PartnerBillings from 'src/modules/bills/components/PartnerBillings'


const ProfilesPage = () => {
  const permissions = useSelector(getCurrentUserPermissionActions)
  const currentUser = useSelector(getCurrentUser);
  const id = currentUser.partnerUserId

  // console.log("current",currentUser)

  const [activeTab, setActiveTab] = useState(PARTNER_TABS.PARTNER_PROFILE)
  // const { id } = useParams<{id: any}>()
  
  return (
    <Fragment>
      <SwitchProfiles activeTab={activeTab} onChange={(event: any) => setActiveTab(event.target.value)} />
      {activeTab === PARTNER_TABS.PARTNER_PROFILE && <ProfilePartnerContainer />}
      {activeTab === PARTNER_TABS.PUBLIC_PROFILE && <PublicProfile />}
      {activeTab === PARTNER_TABS.BILLINGS && hasPermission(permissions, PERMISSIONS.PARTNER_BILLS_ALL) && (
        <PartnerBillings partnerId={id} canUploadInvoice />
      )}
    </Fragment>
  )
}

export default ProfilesPage
