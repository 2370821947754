export default {
  DATE_FORMAT: 'DD/MM/YYYY',
  DATE_LL_FORMAT: 'MMM Do, YYYY',
  DATE_MM_FORMAT: 'MMM Do',
  DATE_LONG_FORMAT: 'LL',
  DATE_TIME_FORMAT: 'MMM Do YYYY HH:mm',
  DATE_MILLISECONDS: 'x',
  DATE_ALPHA_FORMAT: 'Do MMM, YYYY',
  DATE_MMM_YYYY_FORMAT: 'MMM YYYY',
  DATE_DM_FORMAT: 'DD/MM',
}
