import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { AxiosResponse } from 'axios'
import IAction from 'src/interfaces/IAction'

import * as CONSTANTS from './segments.constants'
import { STATUS_CODES } from '../../constants'
import { requestHttp, urls } from 'src/api'
import { ISegment } from './segments.types'
import { getResponseErrorMessage } from 'src/helpers'

export const segmentsListRequest = (): IAction => ({
  type: CONSTANTS.FETCH_SEGMENTS_REQUEST,
})

export const segmentsListSuccess = (segmentsList: any): IAction => ({
  type: CONSTANTS.FETCH_SEGMENTS_SUCCESS,
  payload: segmentsList,
})

export const segmentsListFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_SEGMENTS_FAILURE,
  error,
})

export const createSegmentRequest = (): IAction => ({
  type: CONSTANTS.CREATE_SEGMENT_REQUEST,
})

export const createSegmentSuccess = (segment: ISegment): IAction => ({
  type: CONSTANTS.CREATE_SEGMENT_SUCCESS,
  payload: { segment },
})

export const createSegmentFailure = (error: string): IAction => ({
  type: CONSTANTS.CREATE_SEGMENT_FAILURE,
  error,
})

export const editSegmentRequest = (): IAction => ({
  type: CONSTANTS.EDIT_SEGMENT_REQUEST,
})

export const editSegmentSuccess = (segment: ISegment): IAction => ({
  type: CONSTANTS.EDIT_SEGMENT_SUCCESS,
  payload: { segment },
})

export const editSegmentFailure = (error: string): IAction => ({
  type: CONSTANTS.EDIT_SEGMENT_FAILURE,
  error,
})

export const deleteSegmentRequest = (): IAction => ({
  type: CONSTANTS.DELETE_SEGMENT_REQUEST,
})

export const deleteSegmentSuccess = (id: number): IAction => ({
  type: CONSTANTS.DELETE_SEGMENT_SUCCESS,
  payload: { id },
})

export const deleteSegmentFailure = (error: string): IAction => ({
  type: CONSTANTS.DELETE_SEGMENT_FAILURE,
  error,
})

export const fetchSegmentsList = (): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse> => {
  try {
    dispatch(segmentsListRequest())

    const response = await requestHttp.get(urls.getSegmentsListUrl())
    const { content } = response.data.data

    if (Array.isArray(content)) dispatch(segmentsListSuccess(content))
    else dispatch(segmentsListFailure('No Segments yet'))

    return response
  } catch (error) {
    dispatch(segmentsListFailure(getResponseErrorMessage(error)))

    return error
  }
}

export const createSegment = (segment: ISegment): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    dispatch(createSegmentRequest())

    const response = await requestHttp.post(urls.getCreateSegmentUrl(), segment)

    if (response.status === 201) {
      const newSegment = response.data.data.content
      dispatch(createSegmentSuccess(newSegment))
    }
  } catch (error) {
    dispatch(createSegmentFailure(getResponseErrorMessage(error)))
  }
}

export const editSegment = (segment: ISegment): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    dispatch(editSegmentRequest())

    const response = await requestHttp.put(urls.getEditSegmentUrl(), segment)

    if (response.status === STATUS_CODES.BASE_SUCCESS) {
      const updatedSegment = response.data.data.content
      dispatch(editSegmentSuccess(updatedSegment))
    }
  } catch (error) {
    dispatch(editSegmentFailure(getResponseErrorMessage(error)))
  }
}

export const deleteSegment = (id: number): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    dispatch(deleteSegmentRequest())

    const response = await requestHttp.delete(urls.getDeleteSegmentUrl(id))

    if (response.status === 201) {
      dispatch(deleteSegmentSuccess(id))
    }
  } catch (error) {
    dispatch(deleteSegmentFailure(getResponseErrorMessage(error)))
  }
}
