import React from 'react'
import { Col, Radio, Row } from 'quantum_components'
import { useSelector } from 'react-redux'

import { PERMISSIONS } from 'src/constants/permission'
import { hasPermission } from 'src/helpers/permission'
import { getCurrentUserPermissionActions } from 'src/modules/core/core.selectors'
import { TABS } from '../../profile.types'
import { useTranslation } from 'react-i18next'

interface Props {
  onChange: (v: any) => void
  activeTab: TABS
}
const SwitchClientProfileInfo = ({ onChange, activeTab }: Props) => {
const {t} = useTranslation(['translationClient'])
  const permissions = useSelector(getCurrentUserPermissionActions)

  return (
    <Row gutter={16} justify="center">
      <Col sm={24} md={8}>
        <div className="mb-5 mt-10">
          <Radio.Group defaultValue={activeTab} onChange={onChange} buttonStyle="solid">
            <Radio.Button className="qu-radio-button" value={TABS.PROFILE}>
              <span className="text-uppercase">{t(`clients.profile.title`)}</span>
            </Radio.Button>
            <Radio.Button
              disabled={!hasPermission(permissions, PERMISSIONS.BRIEFS_ALL)}
              className="qu-radio-button"
              value={TABS.BRIEFS}
            >
              <span className="text-uppercase">{t(`clients.campaigns`)}</span>
            </Radio.Button>
            <Radio.Button
              disabled={!hasPermission(permissions, PERMISSIONS.CLIENT_BILLS_ALL)}
              className="qu-radio-button"
              value={TABS.BILLS}
            >
              <span className="text-uppercase">{t(`clients.bills`)}</span>
            </Radio.Button>
          </Radio.Group>
        </div>
      </Col>
    </Row>
  )
}

export default SwitchClientProfileInfo
