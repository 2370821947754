export const MODULE_NAME = 'draftBriefs'
/* actions */

export const SET_CURRENT_STEP = `${MODULE_NAME}/SET_CURRENT_STEP`
export const SET_NEED_CHECK_LAST_STEP = `${MODULE_NAME}/SET_NEED_CHECK_LAST_STEP`

export const CREATE_BRIEF_REQUEST = `${MODULE_NAME}/CREATE_BRIEF_REQUEST`
export const CREATE_BRIEF_SUCCESS = `${MODULE_NAME}/CREATE_BRIEF_SUCCESS`
export const CREATE_BRIEF_FAILURE = `${MODULE_NAME}/CREATE_BRIEF_FAILURE`

export const FETCH_BRIEFS_REQUEST = `${MODULE_NAME}/FETCH_BRIEFS_REQUEST`
export const FETCH_BRIEFS_SUCCESS = `${MODULE_NAME}/FETCH_BRIEFS_SUCCESS`
export const FETCH_BRIEFS_FAILURE = `${MODULE_NAME}/FETCH_BRIEFS_FAILURE`

export const FETCH_BRIEF_SETTINGS_REQUEST = `${MODULE_NAME}/FETCH_BRIEF_SETTINGS_REQUEST`
export const FETCH_BRIEF_SETTINGS_SUCCESS = `${MODULE_NAME}/FETCH_BRIEF_SETTINGS_SUCCESS`
export const FETCH_BRIEFS_SETTINGS_FAILURE = `${MODULE_NAME}/FETCH_BRIEFS_SETTINGS_FAILURE`

export const IMAGE_UPLOAD_REQUEST = `${MODULE_NAME}/IMAGE_UPLOAD_REQUEST`
export const IMAGE_UPLOAD_SUCCESS = `${MODULE_NAME}/IMAGE_UPLOAD_SUCCESS`
export const IMAGE_UPLOAD_FAILURE = `${MODULE_NAME}/IMAGE_UPLOAD_FAILURE`

export const IMAGE_REMOVE_REQUEST = `${MODULE_NAME}/IMAGE_REMOVE_REQUEST`
export const IMAGE_REMOVE_SUCCESS = `${MODULE_NAME}/IMAGE_REMOVE_SUCCESS`
export const IMAGE_REMOVE_FAILURE = `${MODULE_NAME}/IMAGE_REMOVE_FAILURE`

export const SET_BRIEF_LIST_PARAMS = `${MODULE_NAME}/SET_BRIEF_LIST_PARAMS`

export const SET_CURRENT_PAGE = `${MODULE_NAME}/SET_CURRENT_PAGE`

export const DELETE_BRIEF_REQUEST = `${MODULE_NAME}/DELETE_BRIEF_REQUEST`
export const DELETE_BRIEF_SUCCESS = `${MODULE_NAME}/DELETE_BRIEF_SUCCESS`
export const DELETE_BRIEF_FAILURE = `${MODULE_NAME}/DELETE_BRIEF_FAILURE`

export const VIEW_BRIEF_REQUEST = `${MODULE_NAME}/VIEW_BRIEF_REQUEST`
export const VIEW_BRIEF_SUCCESS = `${MODULE_NAME}/VIEW_BRIEF_SUCCESS`
export const VIEW_BRIEF_FAILURE = `${MODULE_NAME}/VIEW_BRIEF_FAILURE`

export const RESET_BRIEF_STATE = `${MODULE_NAME}/RESET_BRIEF_STATE`

export const SET_PARTNER_FILTER = `${MODULE_NAME}/SET_PARTNER_FILTER`
export const RESET_PARTNER_FILTER = `${MODULE_NAME}/RESET_PARTNER_FILTER`

export const FETCH_PARTNER_FILTER_SETTINGS_REQUEST = `${MODULE_NAME}/FETCH_PARTNER_FILTER_SETTINGS_REQUEST`
export const FETCH_PARTNER_FILTER_SETTINGS_SUCCESS = `${MODULE_NAME}/FETCH_PARTNER_FILTER_SETTINGS_SUCCESS`
export const FETCH_PARTNER_FILTER_SETTINGS_FAILURE = `${MODULE_NAME}/FETCH_PARTNER_FILTER_SETTINGS_FAILURE`

export const FETCH_RECOMMENDED_PARTNERS_REQUEST = `${MODULE_NAME}/FETCH_RECOMMENDED_PARTNERS_REQUEST`
export const FETCH_RECOMMENDED_PARTNERS_SUCCESS = `${MODULE_NAME}/FETCH_RECOMMENDED_PARTNERS_SUCCESS`
export const FETCH_RECOMMENDED_PARTNERS_FAILURE = `${MODULE_NAME}/FETCH_RECOMMENDED_PARTNERS_FAILURE`

export const FETCH_PARTNER_DETAILS_REQUEST = `${MODULE_NAME}/FETCH_PARTNER_DETAILS_REQUEST`
export const FETCH_PARTNER_DETAILS_SUCCESS = `${MODULE_NAME}/FETCH_PARTNER_DETAILS_SUCCESS`
export const FETCH_PARTNER_DETAILS_FAILURE = `${MODULE_NAME}/FETCH_PARTNER_DETAILS_FAILURE`

export const FETCH_CART_REQUEST = `${MODULE_NAME}/FETCH_CART_REQUEST`
export const FETCH_CART_SUCCESS = `${MODULE_NAME}/FETCH_CART_SUCCESS`
export const FETCH_CART_FAILURE = `${MODULE_NAME}/FETCH_CART_FAILURE`

export const UPDATE_CART_REQUEST = `${MODULE_NAME}/UPDATE_CART_REQUEST`
export const UPDATE_CART_SUCCESS = `${MODULE_NAME}/UPDATE_CART_SUCCESS`
export const UPDATE_CART_FAILURE = `${MODULE_NAME}/UPDATE_CART_FAILURE`

export const SET_ACTIVE_SINGLE_PARTNER = `${MODULE_NAME}/SET_ACTIVE_SINGLE_PARTNER`
export const SET_TOTAL_QUANTITY = `${MODULE_NAME}/SET_TOTAL_QUANTITY`

export const FETCH_UPLOAD_SETTINGS_REQUEST = `${MODULE_NAME}/FETCH_UPLOAD_SETTINGS_REQUEST`
export const FETCH_UPLOAD_SETTINGS_SUCCESS = `${MODULE_NAME}/FETCH_UPLOAD_SETTINGS_SUCCESS`
export const FETCH_UPLOAD_SETTINGS_FAILURE = `${MODULE_NAME}/FETCH_UPLOAD_SETTINGS_FAILURE`

export const SET_SAMPLE_ALLOCATED_MODAL_PARTNER = `${MODULE_NAME}/SET_SAMPLE_ALLOCATED_MODAL_PARTNER`

export const IMAGE_UPLOAD_STEP_REQUEST = `${MODULE_NAME}/IMAGE_UPLOAD_STEP_REQUEST`
export const IMAGE_UPLOAD_STEP_SUCCESS = `${MODULE_NAME}/IMAGE_UPLOAD_STEP_SUCCESS`
export const IMAGE_UPLOAD_STEP_FAILURE = `${MODULE_NAME}/IMAGE_UPLOAD_STEP_FAILURE`

export const SET_CLIENT_ID = `${MODULE_NAME}/SET_CLIENT_ID`

export const SET_ACTIVE_TAB = `${MODULE_NAME}/SET_ACTIVE_TAB`
export const SET_REJECTION_FLOW = `${MODULE_NAME}/SET_REJECTION_FLOW`
export const SET_DIALOG_MODAL = `${MODULE_NAME}/SET_DIALOG_MODAL`

export const FETCH_BRIEF_REQUESTS_LIST_REQUEST = `${MODULE_NAME}/FETCH_BRIEF_REQUESTS_LIST_REQUEST`
export const FETCH_BRIEF_REQUESTS_LIST_SUCCESS = `${MODULE_NAME}/FETCH_BRIEF_REQUESTS_LIST_SUCCESS`
export const FETCH_BRIEF_REQUESTS_LIST_FAILURE = `${MODULE_NAME}/FETCH_BRIEF_REQUESTS_LIST_FAILURE`

export const SET_BRIEF_REQUESTS_LIST_PARAMS = `${MODULE_NAME}/SET_BRIEF_REQUESTS_LIST_PARAMS`

export const FETCH_DOWNLOAD_MEDIA_REQUEST = `${MODULE_NAME}/FETCH_DOWNLOAD_MEDIA_REQUEST`
export const FETCH_DOWNLOAD_MEDIA_SUCCESS = `${MODULE_NAME}/FETCH_DOWNLOAD_MEDIA_SUCCESS`
export const FETCH_DOWNLOAD_MEDIA_FAILURE = `${MODULE_NAME}/FETCH_DOWNLOAD_MEDIA_FAILURE`

export const EMAIL_PROPOSAL_REQUEST = `${MODULE_NAME}/EMAIL_PROPOSAL_REQUEST`
export const EMAIL_PROPOSAL_SUCCESS = `${MODULE_NAME}/EMAIL_PROPOSAL_SUCCESS`
export const EMAIL_PROPOSAL_FAILURE = `${MODULE_NAME}/EMAIL_PROPOSAL_FAILURE`
