import { createStore, Store, applyMiddleware, compose, combineReducers, Reducer } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import { persistStore, persistReducer, Persistor } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { reducer as rootReducer, MODULE_NAME as CORE_MODULE_NAME, initialState as coreInitialState } from 'src/modules/core'

import { reducer as signInReducer, MODULE_NAME as SIGN_IN_MODULE_NAME } from 'src/modules/registration/signIn'
import { reducer as usersReducer, MODULE_NAME as USERS_MODULE_NAME } from 'src/modules/users'
import { reducer as requestsReducer, MODULE_NAME as REQUESTS_MODULE_NAME } from 'src/modules/signUpRequests'
import { reducer as publicProfileReducer, MODULE_NAME as PUBLIC_PROFILE_MODULE_NAME } from 'src/modules/partner/publicProfile'
import { reducer as profileReducer, MODULE_NAME as PARTNER_PROFILE_MODULE_NAME } from 'src/modules/partner/partner-Profile'
import { reducer as clientProfileReducer, MODULE_NAME as CLIENT_PROFILE_MODULE_NAME } from 'src/modules/client/client-Profile'
import { reducer as briefReducer, MODULE_NAME as BRIEF_MODULE_NAME } from 'src/modules/brief'
import { reducer as briefReducerV3, MODULE_NAME as BRIEF_V3_MODULE_NAME } from 'src/modules/briefV3'
import { reducer as rolesReducer, MODULE_NAME as ROLES_MODULE_NAME } from 'src/modules/roles'
import { reducer as activitiesReducer, MODULE_NAME as ACTIVITIES_MODULE_NAME } from 'src/modules/activities'
import { reducer as ageGroupsReducer, MODULE_NAME as AGE_GROUPS_MODULE_NAME } from 'src/modules/ageGroups'
import { reducer as segmentsReducer, MODULE_NAME as SEGMENTS_MODULE_NAME } from 'src/modules/segments'
import { reducer as requestReducer, MODULE_NAME as REQUEST_MODULE_NAME } from 'src/modules/partner/requests'
import { reducer as notificationsReducer, MODULE_NAME as NOTIFICATIONS_MODULE_NAME } from 'src/modules/notifications'
import { reducer as campaignReducer, MODULE_NAME as CAMPAIGN_MODULE_NAME } from 'src/modules/campaign'
import { reducer as employeesReducer, MODULE_NAME as EMPLOYEES_MODULE_NAME } from 'src/modules/employees'
import { reducer as billReducer, MODULE_NAME as BILL_MODULE_NAME } from 'src/modules/bills'
import { reducer as referralsReducer, MODULE_NAME as REFERRAL_MODULE_NAME } from 'src/modules/referral'
import { reducer as dashboardReducer, MODULE_NAME as DASHBOARD_MODULE_NAME } from 'src/modules/dashboard'
import { reducer as discountCodesReducer, MODULE_NAME as DISCOUNT_CODES_MODULE_NAME } from 'src/modules/discountCodes'
import { reducer as supportTicketsReducer, MODULE_NAME as SUPPORT_TICKETS_MODULE_NAME } from 'src/modules/supportTickets'
import { reducer as leadsReducer, MODULE_NAME as LEADS_MODULE_NAME } from 'src/modules/leads'
import { reducer as adminSettingsReducer, MODULE_NAME as ADMIN_SETTINGS_MODULE_NAME } from 'src/modules/adminSettings'
import { reducer as createClientReducer, MODULE_NAME as CREATE_CLIENT_MODULE_NAME } from 'src/modules/client/create-client'
import { reducer as calendarReducer, MODULE_NAME as CALENDAR_MODULE_NAME } from 'src/modules/calendar'
import { reducer as promotionReducer, MODULE_NAME as PROMOTIONS_MODULE_NAME } from 'src/modules/promotions'
import { reducer as workflowReducer, MODULE_NAME as WORKFLOW_MODULE_NAME } from 'src/modules/workflow'
import { reducer as invitedClientsReducer, MODULE_NAME as INVITED_CLIENTS_MODULE_NAME } from 'src/modules/invitedClients/clientsRequests'
import { reducer as draftBriefsReducer, MODULE_NAME as DRAFT_BRIEFS_MODULE_NAME } from 'src/modules/invitedClients/InvitedBriefs'
// import { reducer as testahelBoxReducer, MODULE_NAME as TESTAHEL_BOX_MODULE_NAME } from 'src/modules/testahelBox'
import { reducer as marketplaceReducer, MODULE_NAME as MARKETPLACE_MODULE_NAME } from 'src/modules/client/marketplace'
import { reducer as invitingClientReducer, MODULE_NAME as PUBLIC_INVITE_CLIENT_NAME } from 'src/modules/invitingClient'
import { reducer as privacyPolicyReducer, MODULE_NAME as PRIVACY_POLICY_MODULE_NAME } from 'src/modules/privacyPolicy'
import { reducer as disclaimerReducer, MODULE_NAME as DISCLAIMER_MODULE_NAME } from 'src/modules/disclaimer'
import { reducer as releaseNotesReducer, MODULE_NAME as RELEASE_NOTES_MODULE_NAME } from 'src/modules/releaseNotes'
import { reducer as subscriptionHistoryReducer, MODULE_NAME as SUBSCRIPTION_HISTORY_MODULE_NAME } from 'src/modules/subscriptionHistory'
import { reducer as walletReducer, MODULE_NAME as WALLET_MODULE_NAME } from 'src/modules/wallet'
import { reducer as targetingKeysReducer, MODULE_NAME as TARGETING_KEYS_MODULE_NAME } from 'src/modules/targetingKeys'
import { reducer as nativeTemplatesReducer, MODULE_NAME as NATIVE_TEMPLATES_MODULE_NAME } from 'src/modules/nativeTemplates'
import { reducer as campaignV3Reducer, MODULE_NAME as CAMPAIGN_V3_MODULE_NAME } from 'src/modules/campaignV3'
import { reducer as servicesV2Reducer, MODULE_NAME as SERVICES_V2_MODULE_NAME } from 'src/modules/servicesV2'

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: [],
}

const createRootReducer = (): Reducer => {
  return combineReducers({
    [CORE_MODULE_NAME]: rootReducer,
    [SIGN_IN_MODULE_NAME]: signInReducer,
    [USERS_MODULE_NAME]: usersReducer,
    [REQUESTS_MODULE_NAME]: requestsReducer,
    [PUBLIC_PROFILE_MODULE_NAME]: publicProfileReducer,
    [PARTNER_PROFILE_MODULE_NAME]: profileReducer,
    [CLIENT_PROFILE_MODULE_NAME]: clientProfileReducer,
    [BRIEF_MODULE_NAME]: briefReducer,
    [BRIEF_V3_MODULE_NAME]: briefReducerV3,
    [ROLES_MODULE_NAME]: rolesReducer,
    [ACTIVITIES_MODULE_NAME]: activitiesReducer,
    [AGE_GROUPS_MODULE_NAME]: ageGroupsReducer,
    [SEGMENTS_MODULE_NAME]: segmentsReducer,
    [REQUEST_MODULE_NAME]: requestReducer,
    [NOTIFICATIONS_MODULE_NAME]: notificationsReducer,
    [CAMPAIGN_MODULE_NAME]: campaignReducer,
    [EMPLOYEES_MODULE_NAME]: employeesReducer,
    [REFERRAL_MODULE_NAME]: referralsReducer,
    [DASHBOARD_MODULE_NAME]: dashboardReducer,
    [DISCOUNT_CODES_MODULE_NAME]: discountCodesReducer,
    [SUPPORT_TICKETS_MODULE_NAME]: supportTicketsReducer,
    [LEADS_MODULE_NAME]: leadsReducer,
    [ADMIN_SETTINGS_MODULE_NAME]: adminSettingsReducer,
    [CREATE_CLIENT_MODULE_NAME]: createClientReducer,
    [INVITED_CLIENTS_MODULE_NAME]: invitedClientsReducer,
    [DRAFT_BRIEFS_MODULE_NAME]: draftBriefsReducer,
    // [TESTAHEL_BOX_MODULE_NAME]: testahelBoxReducer,
    [PUBLIC_INVITE_CLIENT_NAME]: invitingClientReducer,
    [BILL_MODULE_NAME]: billReducer,
    [MARKETPLACE_MODULE_NAME]: marketplaceReducer,
    [CALENDAR_MODULE_NAME]: calendarReducer,
    [PROMOTIONS_MODULE_NAME]: promotionReducer,
    [WORKFLOW_MODULE_NAME]: workflowReducer,
    [PRIVACY_POLICY_MODULE_NAME]: privacyPolicyReducer,
    [DISCLAIMER_MODULE_NAME]: disclaimerReducer,
    [RELEASE_NOTES_MODULE_NAME]: releaseNotesReducer,
    [SUBSCRIPTION_HISTORY_MODULE_NAME]: subscriptionHistoryReducer,
    [WALLET_MODULE_NAME]: walletReducer,
    [TARGETING_KEYS_MODULE_NAME]: targetingKeysReducer,
    [NATIVE_TEMPLATES_MODULE_NAME]: nativeTemplatesReducer,
    [CAMPAIGN_V3_MODULE_NAME]: campaignV3Reducer,
    [SERVICES_V2_MODULE_NAME]: servicesV2Reducer,
  })
}

const persistedReducer = persistReducer(persistConfig, createRootReducer())

export default (): { store: Store; persistor: Persistor } => {
  const composer: Function = process.env.NODE_ENV === 'development' ? composeWithDevTools : compose
  const store: Store = createStore(
    persistedReducer,
    {
      [CORE_MODULE_NAME]: coreInitialState,
    },
    composer(applyMiddleware(thunk))
  )
  const persistor = persistStore(store)

  return { store, persistor }
}
