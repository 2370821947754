import React, { forwardRef, Fragment, ReactElement, useEffect, useImperativeHandle, useState } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Col, Collapse, Form, Row, QButton, QInput, QSelect, IconsCommon, Modal } from 'quantum_components'
import { actions } from '../../../campaign.module'
import { useReduxDispatch } from 'src/helpers'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getCampaignPartners } from '../../../campaign.selectors'
import { ICampaignPartner, ICampaignSurvey, ISurveyAnswer, ISurveyQuestion } from '../../../campaign.types'
import { cloneDeep } from 'lodash'
import { ISurveyAnswerInitState, ISurveyQuestionInitState } from '../../../campaign.reducer'
import {
  PARTNER_ALL,
  SURVEY_CHART_TYPE,
  SURVEY_CHART_TYPE_LIST,
  SURVEY_MAX_ANSWER_LEN,
  SURVEY_MAX_QUESTION_LEN,
} from 'src/constants/survey'
import { VALIDATION_MESSAGE } from 'src/constants/validationMessages'
import { REGEXES } from 'src/constants'
import { LOGISTIC_RATES } from 'src/constants/brief'
import { CAMPAIGN_PARTNERS, CAMPAIGN_PARTNERS_NAMES } from '../../../../../constants/campaign'

interface IProps {
  surveyIndex: number
  survey: ICampaignSurvey
  surveys: ICampaignSurvey[]
  deleteSurvey: (index: number) => void
  setSurveys: (surveys: ICampaignSurvey[]) => void
}

const CampaignSurveyForm = forwardRef(
  ({ surveyIndex, survey, deleteSurvey, surveys, setSurveys }: IProps, ref): ReactElement => {
    const dispatch = useReduxDispatch()
    const partners = useSelector(getCampaignPartners)

    const [form] = Form.useForm()
    const { id } = useParams<{id: any}>()

    const [open, setOpen] = useState<boolean>(true)
    const [deleteModal, setDeleteModal] = useState<boolean>(false)
    const [partnerIds, setPartnerIds] = useState<any>([])

    useEffect(() => {
      initPartnerIds()
    }, [partners])

    useEffect(() => {
      if (!partnerIds?.length) {
        return
      }

      const newSurvey = cloneDeep(survey)
      newSurvey.isEqualAnswers = CAMPAIGN_PARTNERS.ALL_PARTNERS === newSurvey.partnerIds[0]
      newSurvey.partnerIds = partnerIds
      updateSurveys(newSurvey)
    }, [partnerIds])

    useEffect(() => {
      const values = formBuilder()
      form.setFieldsValue(values)
    })

    useImperativeHandle(ref, () => ({
      validateFields() {
        const isValid = form
          .validateFields()
          .then((values: any) => {
            setOpen(false)
            return true
          })
          .catch((errors: any) => {
            return false
          })

        return isValid
      },
    }))

    const initPartnerIds = () => {
      const newSurvey = { ...survey }
      newSurvey.partnerIds = partnerIds
      updateSurveys(newSurvey)

      setPartnerIds(survey.partnerIds)
    }

    const updateSurveys = (newSurvey: ICampaignSurvey) => {
      const newSurveys = [...surveys]
      newSurveys[surveyIndex] = newSurvey
      setSurveys(newSurveys)
    }

    const toggleCollapse = () => {
      setOpen(!open)
    }

    const handleStopPropagation = (e: any) => {
      e.stopPropagation()
    }

    const handleSurveyTitle = (e: any) => {
      const newSurvey = { ...survey }
      newSurvey.title = e.target.value

      updateSurveys(newSurvey)
    }

    const handlePartner = (partnerId: number | string) => {
      setPartnerIds([partnerId])
      form.setFieldsValue({ partner: partnerId })
    }

    const handleDeleteSurvey = async () => {
      if (survey.id) {
        await dispatch(actions.deleteCampaignSurvey(id, survey.id))
      }

      deleteSurvey(surveyIndex)
      setDeleteModal(false)
    }

    const handleDeleteModal = (e: any) => {
      handleStopPropagation(e)

      if (survey.id) {
        setDeleteModal(true)
        return
      }

      deleteSurvey(surveyIndex)
    }

    const handleDeleteModalClose = () => {
      setDeleteModal(false)
    }

    const handleAddQuestion = (e: any) => {
      handleStopPropagation(e)

      const newSurvey = { ...survey }
      const newQuestion = cloneDeep(ISurveyQuestionInitState)
      newSurvey.questions.push(newQuestion)

      updateSurveys(newSurvey)
      setOpen(true)
    }

    const handleDeleteQuestion = (e: any, questionIndex: number) => {
      handleStopPropagation(e)
      const newSurvey = { ...survey }
      newSurvey.questions.splice(questionIndex, 1)

      updateSurveys(newSurvey)
    }

    const handleQuestionTitle = (e: any, questionIndex: number) => {
      const newSurvey = { ...survey }
      newSurvey.questions[questionIndex].title = e.target.value

      updateSurveys(newSurvey)
    }

    const handleChartType = (type: SURVEY_CHART_TYPE, questionIndex: number) => {
      const newSurvey = { ...survey }
      newSurvey.questions[questionIndex].chartType = type

      updateSurveys(newSurvey)
    }

    const handleAddAnswer = (questionIndex: number) => {
      const newSurvey = { ...survey }
      const newAnswer = cloneDeep(ISurveyAnswerInitState)
      newSurvey.questions[questionIndex].answers.push(newAnswer)

      updateSurveys(newSurvey)
    }

    const handleDeleteAnswer = (e: any, questionIndex: number, answerIndex: number) => {
      handleStopPropagation(e)
      const newSurvey = { ...survey }
      newSurvey.questions[questionIndex].answers.splice(answerIndex, 1)

      updateSurveys(newSurvey)
    }

    const handleAnswerTitle = (e: any, questionIndex: number, answerIndex: number) => {
      const newSurvey = { ...survey }
      newSurvey.questions[questionIndex].answers[answerIndex].title = e.target.value

      updateSurveys(newSurvey)
    }

    const handleAnswerQuantity = (e: any, questionIndex: number, answerIndex: number) => {
      const newSurvey = { ...survey }
      const { value } = e.target

      const quantity = +value > LOGISTIC_RATES.BIGGEST ? LOGISTIC_RATES.BIGGEST : +value
      newSurvey.questions[questionIndex].answers[answerIndex].quantity = value ? quantity : null

      updateSurveys(newSurvey)
    }

    const partnerOptions = partners.map((partner: ICampaignPartner) => {
      return { label: partner.companyName, value: partner.id }
    })
    const chartTypeOptions = SURVEY_CHART_TYPE_LIST.map((type: SURVEY_CHART_TYPE) => {
      return { label: type, value: type }
    })

    const formBuilder = () => {
      const values = {
        surveyName: survey.title,
        partner: partnerIds[0],
      }

      const questionReducer = (acc: {}, question: ISurveyQuestion, questionIndex: number) => {
        const qname = `question_${questionIndex}`
        const qtype = `chartType_${questionIndex}`

        //@ts-ignore
        acc[qname] = survey.questions[questionIndex].title
        //@ts-ignore
        acc[qtype] = survey.questions[questionIndex].chartType

        question.answers.forEach((answer: ISurveyAnswer, i: number) => {
          const title = `answer_${questionIndex}_${i}`
          const quantity = `quantity_${questionIndex}_${i}`
          //@ts-ignore
          acc[title] = answer.title
          //@ts-ignore
          acc[quantity] = answer.quantity
        })

        return acc
      }

      const questions = survey.questions.reduce(questionReducer, {})

      return { ...values, ...questions }
    }

    const active = open ? [surveyIndex] : []

    return (
      <Fragment>
        <Form form={form} hideRequiredMark className="leaf-wrapper" initialValues={survey}>
          <Collapse className="qu-tree-item" activeKey={active} onChange={toggleCollapse} ghost>
            <Collapse.Panel
              key={surveyIndex}
              className="qu-tree-list"
              header={
                <div className="qu-tree-header vertical-top">
                  <div className="qu-header-left vertical-top">
                    <Form.Item
                      className="mb-0 mr-16"
                      name="surveyName"
                      rules={[{ required: true, message: VALIDATION_MESSAGE.SURVEY_NAME }]}
                      validateTrigger={['onChange', 'onBlur']}
                    >
                      <QInput
                        placeholder="Enter Survey Name"
                        size="large"
                        onChange={handleSurveyTitle}
                        onClick={handleStopPropagation}
                      />
                    </Form.Item>
                    <Form.Item
                      className="mb-0 mr-16"
                      name="partner"
                      rules={[{ required: true, message: VALIDATION_MESSAGE.PARTNER }]}
                      validateTrigger={['onChange', 'onBlur']}
                    >
                      <QSelect
                        style={{ width: 170 }}
                        showArrow
                        placeholder="Select Partner"
                        options={[
                          {
                            label: CAMPAIGN_PARTNERS_NAMES[CAMPAIGN_PARTNERS.ALL_PARTNERS],
                            value: CAMPAIGN_PARTNERS.ALL_PARTNERS,
                          },
                          ...partnerOptions,
                        ]}
                        onChange={handlePartner}
                        onClick={handleStopPropagation}
                        getPopupContainer={() => document.body}
                        size="large"
                      />
                    </Form.Item>
                    {survey.questions.length < SURVEY_MAX_QUESTION_LEN && (
                      <QButton className="qu-button-soft mb-0 mr-16" onClick={handleAddQuestion}>
                        Add Question
                        <PlusOutlined className="font-size-18" />
                      </QButton>
                    )}
                  </div>
                  <div className="qu-header-controls vertical-top">
                    <div className="qu-button-link line-height-32 mh-8 mt-10" onClick={handleDeleteModal}>
                      <IconsCommon.IconTrash />
                    </div>
                  </div>
                </div>
              }
            >
              {survey.questions.map((question: ISurveyQuestion, questionIndex: number) => {
                return (
                  <div key={questionIndex} className="qu-collapse-row">
                    <Row gutter={16}>
                      <Col sm={24} md={14}>
                        <Form.Item
                          name={`question_${questionIndex}`}
                          rules={[{ required: true, message: VALIDATION_MESSAGE.SURVEY_QUESTION_NAME }]}
                          validateTrigger={['onChange', 'onBlur']}
                        >
                          <QInput
                            placeholder="Enter Your Question"
                            size="large"
                            allowClear
                            onChange={(e: any) => handleQuestionTitle(e, questionIndex)}
                          />
                        </Form.Item>
                      </Col>
                      <Col sm={24} md={6}>
                        <Form.Item
                          className="mb-0 mr-16"
                          name={`chartType_${questionIndex}`}
                          rules={[{ required: true, message: VALIDATION_MESSAGE.CHART_TYPE }]}
                          validateTrigger={['onChange', 'onBlur']}
                        >
                          <QSelect
                            style={{ width: 170 }}
                            showArrow
                            placeholder="Select Chart Type"
                            options={chartTypeOptions}
                            getPopupContainer={() => document.body}
                            onChange={(type: SURVEY_CHART_TYPE) => handleChartType(type, questionIndex)}
                            onClick={handleStopPropagation}
                            size="large"
                          />
                        </Form.Item>
                      </Col>
                      <Col sm={24} md={3}>
                        {question.answers.length < SURVEY_MAX_ANSWER_LEN && (
                          <div onClick={() => handleAddAnswer(questionIndex)} className="qu-button-link mt-8">
                            <span className="font-size-14">Add Answer</span>
                          </div>
                        )}
                      </Col>
                      <Col sm={24} md={1}>
                        <div
                          className="line-height-10 qu-button-link mt-10 ml-8"
                          onClick={(e: any) => handleDeleteQuestion(e, questionIndex)}
                        >
                          <IconsCommon.IconTrash />
                        </div>
                      </Col>
                    </Row>
                    {question.answers.map((answer: ISurveyAnswer, answerIndex: number) => {
                      return (
                        <Row key={answerIndex} gutter={16}>
                          <Col sm={24} md={18}>
                            <Form.Item
                              name={`answer_${questionIndex}_${answerIndex}`}
                              rules={[{ required: true, message: VALIDATION_MESSAGE.SURVEY_ANSWER_NAME }]}
                              validateTrigger={['onBlur']}
                            >
                              <QInput
                                placeholder="Enter Your Answer"
                                size="large"
                                onChange={(e: any) => handleAnswerTitle(e, questionIndex, answerIndex)}
                                allowClear
                              />
                            </Form.Item>
                          </Col>
                          <Col sm={24} md={5}>
                            <Form.Item
                              name={`quantity_${questionIndex}_${answerIndex}`}
                              rules={[
                                {
                                  required: true,
                                  pattern: REGEXES.NUMBERS_REGEX,
                                  message: VALIDATION_MESSAGE.EQUAL_OR_MORE_THAN_ZERO,
                                },
                              ]}
                              validateTrigger={['onChange', 'onBlur']}
                            >
                              <QInput
                                placeholder="Quantity"
                                size="large"
                                type="number"
                                onChange={(e: any) => handleAnswerQuantity(e, questionIndex, answerIndex)}
                              />
                            </Form.Item>
                          </Col>
                          <Col sm={24} md={1}>
                            <div
                              className="line-height-10 qu-button-link mt-10 ml-8"
                              onClick={(e: any) => handleDeleteAnswer(e, questionIndex, answerIndex)}
                            >
                              <IconsCommon.IconTrash />
                            </div>
                          </Col>
                        </Row>
                      )
                    })}
                  </div>
                )
              })}
            </Collapse.Panel>
          </Collapse>
        </Form>
        <Modal title="" visible={deleteModal} onOk={handleDeleteSurvey} onCancel={handleDeleteModalClose}>
          <p>
            Do you want to delete <strong> {survey.title} </strong>?
          </p>
        </Modal>
      </Fragment>
    )
  }
)

export default CampaignSurveyForm
