import { ThunkDispatch, ThunkAction } from 'redux-thunk'
import { AnyAction } from 'redux'
import { AxiosResponse } from 'axios'
import { requestHttp, urls } from 'src/api'
import * as CONSTANTS from './core.constants'
import IAction from 'src/interfaces/IAction'
import { getResponseErrorMessage } from 'src/helpers'
import localStorage from 'redux-persist/es/storage'
import { LOCAL_STORAGE_KEYS, PATHS } from '../../constants'
import history from '../../utils/history'
import { BRIEF_UPLOAD_TYPES } from 'src/constants/brief'
import { UPLOAD_ENTITY_KEYS } from 'src/constants/upload'
import { IUploadResponse, ICurrenciesResponse } from './core.types'
import { MENU_ITEMS } from '../../constants/menu'
import { PARTNER_UPLOAD_TYPES } from 'src/constants/partner'
import { IPermission } from '../roles/roles.types'
import { CORE_CONSTANTS } from '../../constants/settings'
import { CURRENCY } from '../../constants/currencies'
import { BRIEF_UPLOAD_TYPES as TESTAHEL_BRIEF_UPLOAD_TYPES } from '../../constants/testahel_brief'
import { CAMPAIGN_UPLOAD_TYPES } from 'src/constants/campaign'
import { PAYOUT_UPLOAD_TYPES } from 'src/constants/payouts'

export const setActiveMenu = (key: MENU_ITEMS): IAction => ({
  type: CONSTANTS.SET_ACTIVE_MENU,
  payload: { key },
})

export const setProgress = (progress: number): IAction => ({
  type: CONSTANTS.SET_PROGRESS,
  payload: { progress },
})

export const resetProgress = (): IAction => ({
  type: CONSTANTS.RESET_PROGRESS,
})

export const logoutRequest = (): IAction => ({
  type: CONSTANTS.LOGOUT_REQUEST,
})
export const logoutSuccess = (): IAction => ({
  type: CONSTANTS.LOGOUT_SUCCESS,
})
export const logoutFailure = (): IAction => ({
  type: CONSTANTS.LOGOUT_FAILURE,
})

export const getUserRequest = (): IAction => ({
  type: CONSTANTS.GET_CURRENT_USER_REQUEST,
})

export const getUserSuccess = (user: any, permissions: IPermission[]): IAction => ({
  type: CONSTANTS.GET_CURRENT_USER_SUCCESS,
  payload: { user, permissions },
})

export const getUserFailure = (error: string): IAction => ({
  type: CONSTANTS.GET_CURRENT_USER_FAILURE,
  error,
})

export const logout = (): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    dispatch(logoutRequest())
    await localStorage.removeItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN)

    dispatch(logoutSuccess())
    dispatch(getUserFailure(''))
    history.push(PATHS.SIGN_IN)
  } catch (error) {
    dispatch(logoutFailure())
  }
}

export const upload = async (
  url: string,
  files: File[],
  entityId?: number,
  entity?: BRIEF_UPLOAD_TYPES | PARTNER_UPLOAD_TYPES | CAMPAIGN_UPLOAD_TYPES | PAYOUT_UPLOAD_TYPES,
  entityKey?: UPLOAD_ENTITY_KEYS,
  isPublicFile?: boolean,
  version?:string
) => {
  try {
    const bodyFormData = new FormData()

    files.forEach((file: File) => {
      bodyFormData.append('files', file)
    })

    entityKey && bodyFormData.append(entityKey, String(entityId))
    entity && bodyFormData.append('entity', entity)
    version && bodyFormData.append('version', version)

    // if(entity && entity === BRIEF_UPLOAD_TYPES.BRIEFS_QUOTATION_FILES && version){
    //   version && bodyFormData.append('isSku', 'true')
    // }else if(entity && entity !== BRIEF_UPLOAD_TYPES.BRIEFS_QUOTATION_FILES && version){
    //   version && bodyFormData.append('version', version)
    // }
    
    isPublicFile === false && bodyFormData.append('isPublic', String(isPublicFile))

    const config = {
      multipartFormData: true,
      skipDownloadProgress: true,
    }

    const response = await requestHttp.post<IUploadResponse>(url, bodyFormData, config)

    return response
  } catch (error) {
    return error
  }
}

export const uploadTeshahelMedia = async (
  url: string,
  files: File[],
  entityId?: number,
  entity?: TESTAHEL_BRIEF_UPLOAD_TYPES | PARTNER_UPLOAD_TYPES,
  entityKey?: UPLOAD_ENTITY_KEYS
) => {
  try {
    const bodyFormData = new FormData()

    files.forEach((file: File) => {
      bodyFormData.append('files', file)
    })

    entityKey && bodyFormData.append(entityKey, String(entityId))
    entity && bodyFormData.append('entity', entity)

    const config = {
      multipartFormData: true,
      skipDownloadProgress: true,
    }

    const response = await requestHttp.post<IUploadResponse>(url, bodyFormData, config)

    return response
  } catch (error) {
    return error
  }
}

export const userPermissionsSuccess = (permissions: IPermission[]): IAction => ({
  type: CONSTANTS.CURRENT_USER_PERMISSION_SUCCESS,
  payload: { permissions },
})

export const getUser = (): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
) => {
  try {
    const token = await localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN)
    if (!token) {
      return Promise.resolve()
    }

    dispatch(getUserRequest())
    const response = await requestHttp.get(urls.getCurrentUserUrl())

    if (response.status === 200) {
      const { content } = response.data.data
      await dispatch(getUserSuccess(content, content.permissions))
      // await dispatch(userPermissionsSuccess(content.permissions))
    }

    return response
  } catch (error) {
    dispatch(getUserFailure(getResponseErrorMessage(error)))
    dispatch(logout())
    return error
  }
}

export const setServerError = (data: string, type: 'text' | 'html' = 'text'): IAction => ({
  type: CONSTANTS.SET_SERVER_ERROR,
  payload: { data, type },
})

export const resetServerError = (): IAction => ({
  type: CONSTANTS.RESET_SERVER_ERROR,
})

export const fetchCurrencySuccess = (currency: CURRENCY): IAction => ({
  type: CONSTANTS.FETCH_CURRENCIES_SUCCESS,
  payload: { currency },
})

export const fetchCurrencyFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_CURRENCIES_FAILURE,
  error,
})

export const fetchCurrency = (): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    const response = await requestHttp.get<ICurrenciesResponse>(urls.getUserCurrency(), {
      skipDownloadProgress: true,
    })

    const { content } = response.data.data
    dispatch(fetchCurrencySuccess(content.currency))
  } catch (error) {
    dispatch(fetchCurrencyFailure(getResponseErrorMessage(error)))
  }
}

export const updateCurrency = (currency: CURRENCY): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse> => {
  try {
    const response = await requestHttp.patch<ICurrenciesResponse>(
      `${urls.updateUserCurrency()}?newCurrency=${currency}`
    )

    const { content } = response.data.data
    dispatch(fetchCurrencySuccess(content.currency))

    return response
  } catch (error) {
    dispatch(fetchCurrencyFailure(getResponseErrorMessage(error)))
    return error
  }
}

export const fetchConstantsRequest = (): IAction => ({
  type: CONSTANTS.CONSTANTS_REQUEST,
})

export const fetchConstantsSuccess = (constants: any): IAction => ({
  type: CONSTANTS.CONSTANTS_SUCCESS,
  payload: { constants },
})

export const fetchConstantsFailure = (error: string): IAction => ({
  type: CONSTANTS.CONSTANTS_FAILURE,
  error,
})

export const fetchConstants = (data?: CORE_CONSTANTS[]): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    fetchConstantsRequest()
    const params = { set: data }
    const response = await requestHttp.get(urls.getConstantsUrl(), { params })
    const { content } = response.data.data

    dispatch(fetchConstantsSuccess(content))
  } catch (error) {
    dispatch(fetchConstantsFailure(getResponseErrorMessage(error)))
  }
}

export const fetchSettingsRequest = (): IAction => ({
  type: CONSTANTS.SETTINGS_REQUEST,
})

export const fetchSettingsSuccess = (settings: any): IAction => ({
  type: CONSTANTS.SETTINGS_SUCCESS,
  payload: { settings },
})

export const fetchSettingsFailure = (error: string): IAction => ({
  type: CONSTANTS.SETTINGS_FAILURE,
  error,
})

export const fetchSettings = (data?: CORE_CONSTANTS[]): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    fetchSettingsRequest()
    const params = { set: data }
    const response = await requestHttp.get(urls.getSettingsUrl(), { params })
    const { content } = response.data.data

    dispatch(fetchSettingsSuccess(content))
  } catch (error) {
    dispatch(fetchSettingsFailure(getResponseErrorMessage(error)))
  }
}

export const downloadImage = (url: string): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<any> => {
  try {
    const response = await requestHttp.get(urls.getCoreImage(url), {
      skipDownloadProgress: false,
      responseType: 'blob',
    })

    return response
  } catch (error) {
    return error
  }
}

export const downloadImageAsync = async (url: string) => {
  try {
    const response = await requestHttp.get(urls.getCoreImage(url), {
      skipDownloadProgress: false,
      responseType: 'blob',
    })

    return response
  } catch (error) {
    return error
  }
}

export const getSAASPartnerDetails = (): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    const response = await requestHttp.get(urls.getSAASPartnerDetails())
    const { content } = response.data.data;
    return content
  } catch (error) {
    dispatch(logoutFailure())
  }
}

export const markAsReadCustomNotification = (record: any): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (): Promise<AxiosResponse> => {
  try {
    const response = await requestHttp.patch(urls.markAsReadCustomNotificationUrl(), record)
    return response
  } catch (error) {
    return error
  }
}

export const getUnreadCustomNotificationRequest = (): IAction => ({
  type: CONSTANTS.GET_UNREAD_CUSTOM_NOTIFICATION_REQUEST,
})

export const getUnreadCustomNotificationSuccess = (customNotification: any): IAction => ({
  type: CONSTANTS.GET_UNREAD_CUSTOM_NOTIFICATION_SUCCESS,
  payload: { customNotification },
})

export const getUnreadCustomNotificationFailure = (error: string): IAction => ({
  type: CONSTANTS.GET_UNREAD_CUSTOM_NOTIFICATION_FAILURE,
  error,
})

export const getUnreadCustomNotification = (): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<AxiosResponse> => {
  try {
    dispatch(getUnreadCustomNotificationRequest())
    const response = await requestHttp.get(urls.getUnreadCustomNotificationUrl())
    if (response.status === 200) {
      const { content } = response.data.data
      dispatch(getUnreadCustomNotificationSuccess(content))
    }
    return response
  } catch (error) {
    dispatch(getUnreadCustomNotificationFailure(getResponseErrorMessage(error)))
    return error
  }
}

export const getSubscriptionOverduePaymentRequest = (): IAction => ({
  type: CONSTANTS.GET_SUBSCRIPTION_OVERDUE_PAYMENT_REQUEST,
})

export const getSubscriptionOverduePaymentSuccess = (overduePayment: any): IAction => ({
  type: CONSTANTS.GET_SUBSCRIPTION_OVERDUE_PAYMENT_SUCCESS,
  payload: { overduePayment },
})

export const getSubscriptionOverduePaymentFailure = (error: string): IAction => ({
  type: CONSTANTS.GET_SUBSCRIPTION_OVERDUE_PAYMENT_FAILURE,
  error,
})

export const getSubscriptionOverduePayment = (): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<AxiosResponse> => {
  try {
    dispatch(getSubscriptionOverduePaymentRequest())
    const response = await requestHttp.get(urls.getSubscriptionOverduePaymentUrl())
    if (response.status === 201) {
      const { content } = response.data.data
      dispatch(getSubscriptionOverduePaymentSuccess(content))
    }
    return response
  } catch (error) {
    dispatch(getSubscriptionOverduePaymentFailure(getResponseErrorMessage(error)))
    return error
  }
}

export const paySubscriptionPaymentRequest = (): IAction => ({
  type: CONSTANTS.PAY_SUBSCRIPTION_PAYMENT_REQUEST,
})

export const paySubscriptionPaymentSuccess = (): IAction => ({
  type: CONSTANTS.PAY_SUBSCRIPTION_PAYMENT_SUCCESS,
})

export const paySubscriptionPaymentFailure = (error: string): IAction => ({
  type: CONSTANTS.PAY_SUBSCRIPTION_PAYMENT_FAILURE,
  error,
})

export const paySubscriptionPayment = (id: number, callOverdueAPI: boolean = true): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<AxiosResponse> => {
  try {
    dispatch(paySubscriptionPaymentRequest())
    const response = await requestHttp.post(urls.paySubscriptionPaymentUrl(), { id })
    if (response.status === 201) {
      dispatch(paySubscriptionPaymentSuccess())
      if (callOverdueAPI) dispatch(getSubscriptionOverduePayment())
    }
    return response
  } catch (error) {
    dispatch(paySubscriptionPaymentFailure(getResponseErrorMessage(error)))
    return error
  }
}

export const uploadGenericFile = async(file:any,entity:string) => {
  try {
      const formData = new FormData();
      formData.append('files',file);
      formData.append('entity',entity ?? "core");

      const config = {
          multipartFormData: true,
          skipDownloadProgress: true,
        }

      const response = await requestHttp.post(urls.getCoreMediaUploadUrl(),formData,config)
      const { content } = response.data.data
      return content
  } catch (error) {
      return error
  }
}
