import get from 'lodash/get'

import IAction from 'src/interfaces/IAction'
import { DEFAULT_BILL_TABLE_PARAMS } from 'src/constants/bill'
import { BRIEF_PAYMENT_STATUS } from 'src/constants/brief'

import {
  IState,
  IBillListElement,
  IBillPaymentUploadTypes,
  IBillingsListElement,
  IBillingsPaymentUploadTypes,
} from './bills.types'
import * as CONSTANTS from './bills.constants'

const initialState: IState = {
  billList: {
    results: [],
    total: 0,
  },
  fetchingBillListError: '',
  billListLoading: true,
  requestList: {
    results: [],
    total: 0,
  },
  fetchingRequestListError: '',
  requestListLoading: true,
  onlinePaymentsList: {
    results: [],
    total: 0,
  },
  fetchingOnlinePaymentsListError: '',
  onlinePaymentsListLoading: true,
  refundPending: false,
  refundError: '',
  pages: {
    totalPages: 1,
    currentPage: 1,
  },
  params: DEFAULT_BILL_TABLE_PARAMS,
}

export default (state: IState = initialState, action: IAction): IState => {
  switch (action.type) {
    case CONSTANTS.FETCH_BILLS_REQUEST:
      return {
        ...state,
        fetchingBillListError: '',
        billListLoading: true,
      }
    case CONSTANTS.FETCH_BILLS_SUCCESS:
      const billList = get(action, 'payload.billList')
      const totalPages = Math.ceil(billList.total / state.params.limit)
      return {
        ...state,
        billList,
        pages: { ...state.pages, totalPages },
        billListLoading: false,
      }
    case CONSTANTS.FETCH_BILLS_FAILURE:
      return { ...state, fetchingBillListError: get(action, 'payload.error'), billListLoading: false }

    case CONSTANTS.FETCH_REQUESTS_REQUEST:
      return {
        ...state,
        fetchingRequestListError: '',
        requestListLoading: true,
      }
    case CONSTANTS.FETCH_REQUESTS_SUCCESS: {
      const requestList = get(action, 'payload.requestList')
      const totalPages = Math.ceil(requestList.total / state.params.limit)
      return {
        ...state,
        requestList,
        pages: { ...state.pages, totalPages },
        requestListLoading: false,
      }
    }
    case CONSTANTS.FETCH_REQUESTS_FAILURE:
      return { ...state, fetchingRequestListError: get(action, 'payload.error'), requestListLoading: false }

    case CONSTANTS.FETCH_ONLINE_PAYMENTS_REQUEST:
      return {
        ...state,
        fetchingOnlinePaymentsListError: '',
        onlinePaymentsListLoading: true,
      }
    case CONSTANTS.FETCH_ONLINE_PAYMENTS_SUCCESS: {
      const onlinePaymentsList = get(action, 'payload.onlinePaymentsList')
      const totalPages = Math.ceil(onlinePaymentsList.total / state.params.limit)
      return {
        ...state,
        onlinePaymentsList,
        pages: { ...state.pages, totalPages },
        onlinePaymentsListLoading: false,
      }
    }
    case CONSTANTS.FETCH_ONLINE_PAYMENTS_FAILURE:
      return {
        ...state,
        fetchingOnlinePaymentsListError: get(action, 'payload.error'),
        onlinePaymentsListLoading: false,
      }

    case CONSTANTS.REFUND_ONLINE_PAYMENT_REQUEST:
      return {
        ...state,
        refundError: '',
        refundPending: true,
      }
    case CONSTANTS.REFUND_ONLINE_PAYMENT_SUCCESS: {
      return {
        ...state,
        refundPending: false,
      }
    }
    case CONSTANTS.REFUND_ONLINE_PAYMENT_FAILURE:
      return {
        ...state,
        refundError: get(action, 'payload.error'),
        refundPending: false,
      }

    case CONSTANTS.SET_BILL_LIST_PARAMS:
      return { ...state, params: get(action, 'payload.params') }

    case CONSTANTS.SET_CURRENT_PAGE:
      return { ...state, pages: { ...state.pages, currentPage: get(action, 'payload.page') } }

    case CONSTANTS.FILE_UPLOAD_REQUEST: {
      const filesType: IBillPaymentUploadTypes = get(action, 'payload.filesType')
      const updatedBill: IBillListElement = get(action, 'payload.bill')
      updatedBill.payment[filesType].isUploading = true
      updatedBill.payment[filesType].uploadingError = ''

      const updatedBillList = state.billList.results.map(bill => (bill.key === updatedBill.key ? updatedBill : bill))
      return { ...state, billList: { ...state.billList, results: updatedBillList } }
    }

    case CONSTANTS.FILE_UPLOAD_SUCCESS: {
      const filesType: IBillPaymentUploadTypes = get(action, 'payload.filesType')
      const updatedBill: IBillListElement = get(action, 'payload.bill')
      updatedBill.payment[filesType].isUploading = false

      const updatedBillList = state.billList.results.map(bill => (bill.key === updatedBill.key ? updatedBill : bill))
      return { ...state, billList: { ...state.billList, results: updatedBillList } }
    }

    case CONSTANTS.FILE_UPLOAD_FAILURE: {
      const filesType: IBillPaymentUploadTypes = get(action, 'payload.filesType')
      const updatedBill: IBillListElement = get(action, 'payload.bill')
      updatedBill.payment[filesType].isUploading = false
      updatedBill.payment[filesType].uploadingError = get(action, 'payload.error')

      const updatedBillList = state.billList.results.map(bill => (bill.key === updatedBill.key ? updatedBill : bill))
      return { ...state, billList: { ...state.billList, results: updatedBillList } }
    }

    case CONSTANTS.FILE_REMOVE_REQUEST: {
      const filesType: IBillPaymentUploadTypes = get(action, 'payload.filesType')
      const updatedBill: IBillListElement = get(action, 'payload.bill')
      updatedBill.payment[filesType].isRemoving = true
      updatedBill.payment[filesType].removingError = ''

      const updatedBillList = state.billList.results.map(bill => (bill.key === updatedBill.key ? updatedBill : bill))
      return { ...state, billList: { ...state.billList, results: updatedBillList } }
    }

    case CONSTANTS.FILE_REMOVE_SUCCESS: {
      const filesType: IBillPaymentUploadTypes = get(action, 'payload.filesType')
      const updatedBill: IBillListElement = get(action, 'payload.bill')
      updatedBill.payment[filesType].isRemoving = false

      const updatedBillList = state.billList.results.map(bill => (bill.key === updatedBill.key ? updatedBill : bill))
      return { ...state, billList: { ...state.billList, results: updatedBillList } }
    }

    case CONSTANTS.FILE_REMOVE_FAILURE: {
      const filesType: IBillPaymentUploadTypes = get(action, 'payload.filesType')
      const updatedBill: IBillListElement = get(action, 'payload.bill')
      updatedBill.payment[filesType].isRemoving = false
      updatedBill.payment[filesType].removingError = get(action, 'payload.error')

      const updatedBillList = state.billList.results.map(bill => (bill.key === updatedBill.key ? updatedBill : bill))
      return { ...state, billList: { ...state.billList, results: updatedBillList } }
    }

    case CONSTANTS.PARTNER_FILE_UPLOAD_REQUEST: {
      const filesType: IBillingsPaymentUploadTypes = get(action, 'payload.filesType')
      const updatedBill: IBillingsListElement = get(action, 'payload.bill')
      updatedBill.payment![filesType].isUploading = true
      updatedBill.payment![filesType].uploadingError = ''

      const updatedRequestList = state.requestList.results.map(bill =>
        bill.id === updatedBill.id ? updatedBill : bill
      )
      return { ...state, requestList: { ...state.requestList, results: updatedRequestList } }
    }

    case CONSTANTS.PARTNER_FILE_UPLOAD_SUCCESS: {
      const filesType: IBillingsPaymentUploadTypes = get(action, 'payload.filesType')
      const updatedBill: IBillingsListElement = get(action, 'payload.bill')
      updatedBill.payment![filesType].isUploading = false

      if (filesType === 'bankTransfer') {
        updatedBill.payment!.status = BRIEF_PAYMENT_STATUS.PAID
      }

      const updatedRequestList = state.requestList.results.map(bill =>
        bill.id === updatedBill.id ? updatedBill : bill
      )
      return { ...state, requestList: { ...state.requestList, results: updatedRequestList } }
    }

    case CONSTANTS.PARTNER_FILE_UPLOAD_FAILURE: {
      const filesType: IBillingsPaymentUploadTypes = get(action, 'payload.filesType')
      const updatedBill: IBillingsListElement = get(action, 'payload.bill')
      updatedBill.payment![filesType].isUploading = false
      updatedBill.payment![filesType].uploadingError = get(action, 'payload.error')

      const updatedRequestList = state.requestList.results.map(bill =>
        bill.id === updatedBill.id ? updatedBill : bill
      )
      return { ...state, requestList: { ...state.requestList, results: updatedRequestList } }
    }

    case CONSTANTS.PARTNER_FILE_REMOVE_REQUEST: {
      const filesType: IBillingsPaymentUploadTypes = get(action, 'payload.filesType')
      const updatedBill: IBillingsListElement = get(action, 'payload.bill')
      updatedBill.payment![filesType].isRemoving = true
      updatedBill.payment![filesType].removingError = ''

      const updatedRequestList = state.requestList.results.map(bill =>
        bill.id === updatedBill.id ? updatedBill : bill
      )
      return { ...state, requestList: { ...state.requestList, results: updatedRequestList } }
    }

    case CONSTANTS.PARTNER_FILE_REMOVE_SUCCESS: {
      const filesType: IBillingsPaymentUploadTypes = get(action, 'payload.filesType')
      const updatedBill: IBillingsListElement = get(action, 'payload.bill')
      updatedBill.payment![filesType].isRemoving = false

      if (filesType === 'bankTransfer') {
        updatedBill.payment!.status = BRIEF_PAYMENT_STATUS.PENDING
      }

      const updatedRequestList = state.requestList.results.map(bill =>
        bill.id === updatedBill.id ? updatedBill : bill
      )
      return { ...state, requestList: { ...state.requestList, results: updatedRequestList } }
    }

    case CONSTANTS.PARTNER_FILE_REMOVE_FAILURE: {
      const filesType: IBillingsPaymentUploadTypes = get(action, 'payload.filesType')
      const updatedBill: IBillingsListElement = get(action, 'payload.bill')
      updatedBill.payment![filesType].isRemoving = false
      updatedBill.payment![filesType].removingError = get(action, 'payload.error')

      const updatedRequestList = state.requestList.results.map(bill =>
        bill.id === updatedBill.id ? updatedBill : bill
      )
      return { ...state, requestList: { ...state.requestList, results: updatedRequestList } }
    }

    case CONSTANTS.BILL_ACCEPT_REQUEST: {
      const updatedBill: IBillListElement = get(action, 'payload.bill')
      updatedBill.isAccepting = true
      updatedBill.acceptanceError = ''

      const updatedBillList = state.billList.results.map(bill => (bill.key === updatedBill.key ? updatedBill : bill))
      return { ...state, billList: { ...state.billList, results: updatedBillList } }
    }

    case CONSTANTS.BILL_ACCEPT_SUCCESS: {
      const updatedBill: IBillListElement = get(action, 'payload.bill')
      updatedBill.isAccepting = false

      const updatedBillList = state.billList.results.map(bill => (bill.key === updatedBill.key ? updatedBill : bill))
      return { ...state, billList: { ...state.billList, results: updatedBillList } }
    }

    case CONSTANTS.BILL_ACCEPT_FAILURE: {
      const updatedBill: IBillListElement = get(action, 'payload.bill')
      updatedBill.isAccepting = false
      updatedBill.acceptanceError = get(action, 'payload.error')

      const updatedBillList = state.billList.results.map(bill => (bill.key === updatedBill.key ? updatedBill : bill))
      return { ...state, billList: { ...state.billList, results: updatedBillList } }
    }

    case CONSTANTS.BILL_REJECT_REQUEST: {
      const updatedBill: IBillListElement = get(action, 'payload.bill')
      updatedBill.isRejecting = true
      updatedBill.rejectionError = ''

      const updatedBillList = state.billList.results.map(bill => (bill.key === updatedBill.key ? updatedBill : bill))
      return { ...state, billList: { ...state.billList, results: updatedBillList } }
    }

    case CONSTANTS.BILL_REJECT_SUCCESS: {
      const updatedBill: IBillListElement = get(action, 'payload.bill')
      updatedBill.isRejecting = false

      const updatedBillList = state.billList.results.map(bill => (bill.key === updatedBill.key ? updatedBill : bill))
      return { ...state, billList: { ...state.billList, results: updatedBillList } }
    }

    case CONSTANTS.BILL_REJECT_FAILURE: {
      const updatedBill: IBillListElement = get(action, 'payload.bill')
      updatedBill.isRejecting = false
      updatedBill.rejectionError = get(action, 'payload.error')

      const updatedBillList = state.billList.results.map(bill => (bill.key === updatedBill.key ? updatedBill : bill))
      return { ...state, billList: { ...state.billList, results: updatedBillList } }
    }

    default:
      return state
  }
}
