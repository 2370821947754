const MODULE_NAME = 'admin/brief'

export const postCreateWorkflowUrl = (briefId:number): string => `${MODULE_NAME}/${briefId}/create-workflow`

export const patchProcessWorkflowUrl = (briefId:number,workflowId:number): string => `${MODULE_NAME}/${briefId}/update-workflow/${workflowId}`

export const getCreateBriefUrl = (): string => `${MODULE_NAME}/create`

export const getV3EditBriefUrl = (): string => `${MODULE_NAME}/edit`

export const getEditBriefUrl = (): string => `${MODULE_NAME}/edit-step/`

export const getBriefListUrl = (): string => `${MODULE_NAME}/list`

export const getDeleteBriefUrl = (id: number): string => `${MODULE_NAME}/delete/${id}`

export const getV3DeleteBriefUrl = (id: number): string => `${MODULE_NAME}/delete-sku/${id}`

export const getBriefSettingsUrl = (): string => `${MODULE_NAME}/brief-settings`

export const getViewBriefUrl = (id: number): string => `${MODULE_NAME}/view/${id}`

export const getViewBriefV3Url = (id: number): string => `${MODULE_NAME}/view-sku/${id}`

export const getOverrideBriefPaymentByAdminV3Url = (id: number): string => `${MODULE_NAME}/override-payment-sku/${id}`

export const getBriefUploadUrl = (): string => `${MODULE_NAME}/upload`

export const getBriefRemoveMediaUrl = (): string => `${MODULE_NAME}/remove-media`

export const getPartnerFilterSettingsUrl = (): string => `${MODULE_NAME}/filter-settings`

export const getBriefRecommendedPartnersUrl = (): string => `${MODULE_NAME}/recommended-partners`

export const getBriefPartnerDetailsUrl = (briefId: number, partnerId: number): string =>
  `${MODULE_NAME}/${briefId}/partner/single/${partnerId}` // TODO: for admin same route?

export const getBriefUploadMediaUrl = (briefId: number): string => `${MODULE_NAME}/media-buy-service/${briefId}`

export const getBriefApproveUrl = (briefId: number): string => `brief/final-approve/${briefId}`

export const getAllRequestUrl = (): string => `admin/requests/all`

export const getDownloadMediaUrl = (briefId: number): string => `${MODULE_NAME}/download-media/${briefId}`

export const getSendEmailProposal = (briefId: number): string => `${MODULE_NAME}/${briefId}/email-proposal`

export const saveAssignBriefToEmployeesUrl = (briefId: number): string => `${MODULE_NAME}/assign/${briefId}`

export const getInvitedClientsBriefListUrl = (): string => `admin/invitedClients/briefs`

export const getPotentialAudienceReachUrl = (briefId: number): string => `brief/get-audience/${briefId}`

export const getOnlinePaymentsUrl = (): string => `${MODULE_NAME}/online-payment/list`

export const getRefundUrl = (): string => `${MODULE_NAME}/payment/refund`
