import React, { ReactElement } from 'react'
import { Card } from 'antd'
import { useTranslation } from 'react-i18next'

export interface IProps {
  stepper: React.ReactNode
}

export function ClientsProfileHeader({ stepper }: IProps): ReactElement {
  const {t} = useTranslation(['translationClient'])
  return (
    <div className="partner-container-header pt-20">
      <h2>{t(`clients.profile.edit_profile`)}</h2>
      {stepper}
    </div>
  )
}
