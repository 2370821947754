import React, { useEffect, useState } from 'react'
import { ClientSidebar } from 'quantum_components'

import { useSelector } from 'react-redux'
import { getForm, getFormBoolean, getPartnerPartnerPercent } from '../../profile.selectors'

import {
  PARTNER_STEPS,
  AVAILABLE_SERVICES,
  GENERAL_COMPANY_INFO,
  SALES_INFORMATION,
  CUSTOMERS_AND_LOCALE,
  AVAILABLE_SERVICES_VALUES,
  BANK_INFORMATION,
} from '../../../../../constants/profile'
import { pickStepData } from 'src/helpers/profile'
import { isNumber, omit, pick } from 'lodash'
import { useTranslation } from 'react-i18next'

export interface IProps {
  currentStep: PARTNER_STEPS
}

const PROPS_BY_VALUES = {
  [AVAILABLE_SERVICES_VALUES[AVAILABLE_SERVICES.SAMPLE_DISTRIBUTION]]: [
    AVAILABLE_SERVICES_VALUES[AVAILABLE_SERVICES.SAMPLE_PRICE],
  ],
  [AVAILABLE_SERVICES_VALUES[AVAILABLE_SERVICES.INSERT_DISTRIBUTION]]: [
    AVAILABLE_SERVICES_VALUES[AVAILABLE_SERVICES.INSERT_PRICE],
  ],
  [AVAILABLE_SERVICES_VALUES[AVAILABLE_SERVICES.MEDIA_BUYING]]: [
    AVAILABLE_SERVICES_VALUES[AVAILABLE_SERVICES.MEDIA_BUYING_TYPES],
  ],
}
const NAMES_BY_VALUES = {
  [AVAILABLE_SERVICES_VALUES[AVAILABLE_SERVICES.SAMPLE_DISTRIBUTION]]: AVAILABLE_SERVICES.SAMPLE_DISTRIBUTION,
  [AVAILABLE_SERVICES_VALUES[AVAILABLE_SERVICES.SAMPLE_PRICE]]: AVAILABLE_SERVICES.SAMPLE_PRICE,
  [AVAILABLE_SERVICES_VALUES[AVAILABLE_SERVICES.INSERT_DISTRIBUTION]]: AVAILABLE_SERVICES.INSERT_DISTRIBUTION,
  [AVAILABLE_SERVICES_VALUES[AVAILABLE_SERVICES.INSERT_PRICE]]: AVAILABLE_SERVICES.INSERT_PRICE,
  [AVAILABLE_SERVICES_VALUES[AVAILABLE_SERVICES.WAREHOUSE_ADDRESS]]: AVAILABLE_SERVICES.WAREHOUSE_ADDRESS,
  [AVAILABLE_SERVICES_VALUES[AVAILABLE_SERVICES.MEDIA_BUYING]]: AVAILABLE_SERVICES.MEDIA_BUYING,
  [AVAILABLE_SERVICES_VALUES[AVAILABLE_SERVICES.MEDIA_BUYING_TYPES]]: AVAILABLE_SERVICES.MEDIA_BUYING_TYPES,
}

const checkValue = (e: any) => {
  if (typeof e === 'boolean') return true
  return (
    (isNumber(e) && !!e) ||
    (!!e && typeof e === 'object' && Object.values(e).some(i => !!`${i}`)) ||
    !!((e && e.length && !Array.isArray(e)) || (e && Array.isArray(e) && e.some(v => v)))
  )
}

export default function PartnersProfileSidebar({ currentStep }: IProps) {
  const {t} = useTranslation(['translationProfile'])
  const [unfilledSteps, setUnfilledSteps] = useState([''])
  const [filledSteps, setFilledSteps] = useState([''])

  const formBoolean = useSelector(getFormBoolean)
  const partnerForm = useSelector(getForm)
  const percent = useSelector(getPartnerPartnerPercent)

  const initialMetrics = {
    Leads: '',
    Looks: '',
    Facebook: '',
    Instagram: '',
    Twitter: '',
  }

  const getAvailableServiceFieldData = (): any => {
    const stepData = pick(partnerForm, Object.values(AVAILABLE_SERVICES_VALUES))
    let fieldToOmit = [] as string[]
    const checkedFields = Object.keys(stepData).reduce((acc, key) => {
      //@ts-ignore
      if (typeof stepData[key] === 'boolean' && !stepData[key]) {
        //@ts-ignore
        fieldToOmit.push(PROPS_BY_VALUES[key])
      }
      //@ts-ignore
      return { ...acc, [key]: stepData[key] }
    }, {})
    return omit(checkedFields, fieldToOmit)
  }

  const stepData =
    currentStep !== PARTNER_STEPS.AVAILABLE
      ? pickStepData({ ...partnerForm, metrics: { ...initialMetrics, ...partnerForm.metrics } }, currentStep)
      : getAvailableServiceFieldData()


  const description = t("profile.partner.profile_completion.help_text")
  const title = t("profile.partner.profile_completion.title")
  // remove me when refactorin filled/unfilled state steps, headers
  useEffect(() => {
    let newFilledSteps = ['']
    let newUnfilledSteps = ['']

    if (
      Object.values(formBoolean)
        .slice(0, 6)
        .every(elem => elem)
    ) {
      newFilledSteps = [...newFilledSteps, 'GENERAL_INFO']
    } else if (
      Object.values(formBoolean)
        .slice(0, 6)
        .some(elem => !elem)
    ) {
      newUnfilledSteps = [...newUnfilledSteps, 'GENERAL_INFO']
    }

    if (
      Object.values(formBoolean)
        .slice(6, 12)
        .every(elem => elem)
    ) {
      newFilledSteps = [...newFilledSteps, 'SALES_INFO']
    } else if (
      Object.values(formBoolean)
        .slice(6, 12)
        .some(elem => !elem)
    ) {
      newUnfilledSteps = [...newUnfilledSteps, 'SALES_INFO']
    }

    if (
      Object.entries(formBoolean)
        .slice(12, 17)
        .filter(elem=>elem[0] !== 'partnerTargetSegments')
        .every(elem => elem[1])
    ) {
      newFilledSteps = [...newFilledSteps, 'CUSTOMERS_LOCALE']
    } else if (
      Object.entries(formBoolean)
        .slice(12, 17)
        .filter(elem=>elem[0] !== 'partnerTargetSegments')
        .some(elem => !elem[1])
    ) {
      newUnfilledSteps = [...newUnfilledSteps, 'CUSTOMERS_LOCALE']
    }

    if (
      Object.values(formBoolean)
        .slice(17, 24)
        .every(elem => elem)
    ) {
      newFilledSteps = [...newFilledSteps, 'AVAILABLE_SERVICES']
    } else if (
      Object.values(formBoolean)
        .slice(17, 24)
        .some(elem => !elem)
    ) {
      newUnfilledSteps = [...newUnfilledSteps, 'AVAILABLE_SERVICES']
    }
    setFilledSteps(newFilledSteps.filter(elem => elem !== ''))
    setUnfilledSteps(newUnfilledSteps.filter(elem => elem !== ''))
  }, [formBoolean])

  // const Steps: string[] = Object.values(PARTNER_STEPS)
  const Steps: string[] = ['GENERAL_INFO','SALES_INFO','CUSTOMERS_LOCALE']

  const titles: string[] = [t("profile.partner.general_info.general_information"), t("profile.partner.sales_info.sales_information"), t("profile.partner.customer_and_locale.customer_and_locale"), 'Available Services']
  const PropertiesOfSteps: string[][] = [
    Object.values(GENERAL_COMPANY_INFO).map((el:string)=>t(`profile.partner.profile_completion.general-steps.${el}`)),
    Object.values({...SALES_INFORMATION,...BANK_INFORMATION}).map((el:string)=>t(`profile.partner.profile_completion.sales-steps.${el}`)),
    Object.values(CUSTOMERS_AND_LOCALE).map((el:string)=>t(`profile.partner.profile_completion.locale-steps.${el}`)),
    Object.values(pick(NAMES_BY_VALUES, Object.keys(getAvailableServiceFieldData()))),
  ]

  return (
    <div className="partner-container-sidebar">
      <ClientSidebar
        titles={titles}
        unfinishedSteps={unfilledSteps.filter(elem => elem !== '')}
        propertiesOfSteps={PropertiesOfSteps}
        currentStep={currentStep}
        title={title}
        description={description}
        //@ts-ignore
        filledPropertiesOfStep={Object.keys(stepData).map(e => checkValue(stepData[e]))}
        filledSteps={filledSteps.filter(elem => elem !== '')}
        filledStepsPropertiesLength={0}
        completion={percent}
        steps={Steps}
      />
    </div>
  )
}
