import { IForm, IFormBoolean } from 'src/modules/partner/partner-Profile/profile.types'
import { cloneDeep, isArray, isEmpty, omit, pick } from 'lodash'
import {
  AVAILABLE_SERVICES_INITIAL_VALUES,
  CUSTOMERS_AND_LOCALE_INITIAL_VALUES,
  GENERAL_COMPANY_INFO,
  GENERAL_COMPANY_INFO_INITIAL_VALUES,
  GENERAL_COMPANY_INFO_VALUES,
  PARTNER_STEPS,
  SALES_INFORMATION_INITIAL_VALUES,
} from 'src/constants/profile'
import { SOCIAL_TYPES } from 'src/constants/partner'

// remove me when refactorin filled/unfilled state steps
export const checkPartnerForm = (partnerForm: IForm): IFormBoolean => ({
  //General Info
  companyName: !!partnerForm.companyName,
  companyDescription: !!partnerForm.companyDescription,
  companyDescriptionAr: !!partnerForm.companyDescriptionAr,
  companyWebsite: !!partnerForm.companyWebsite,
  headquarterAddress: !!partnerForm.headquarterAddress,
  metrics: !!Object.values(omit(cloneDeep(partnerForm.metrics), ['Looks', 'Leads'])).join('').length,
  socialMediaLinks: !!Object.values(partnerForm.socialMediaLinks).join('').length,
  isAutomaticallyApprovedRequest:
    partnerForm.isAutomaticallyApprovedRequest || !partnerForm.isAutomaticallyApprovedRequest,
  //Sales Info
  productCategories: !isEmpty(partnerForm.productCategories),
  Looks: !!partnerForm.metrics && !!partnerForm.metrics.Looks,
  Leads: !!partnerForm.metrics && !!partnerForm.metrics.Leads,
  amountOfOrders: !!partnerForm.amountOfOrders,
  costOfOrder: !!partnerForm.costOfOrder,
  currency: !!partnerForm.currency,
  //Customers & Locale
  partnerGenders: !isEmpty(partnerForm.partnerGenders),
  partnerAgeGroups: !isEmpty(partnerForm.partnerAgeGroups),
  partnerTargetSegments: !isEmpty(partnerForm.partnerTargetSegments),
  countries: isArray(partnerForm.countries) && partnerForm.countries.length > 0,
  cities: isArray(partnerForm.cities) && partnerForm.cities.length > 0,
  //Available Service
  isSampleDistribution: partnerForm.isSampleDistribution || !partnerForm.isSampleDistribution,
  pricePerSample: !partnerForm.isSampleDistribution ? true : !!partnerForm.pricePerSample,
  isInsertDistribution: partnerForm.isInsertDistribution || !partnerForm.isInsertDistribution,
  pricePerInsert: !partnerForm.isInsertDistribution ? true : !!partnerForm.pricePerInsert,
  warehouseAddresses: isArray(partnerForm.warehouseAddresses) && partnerForm.warehouseAddresses.join('').length > 0,
  isMediaBuy: partnerForm.isMediaBuy || !partnerForm.isMediaBuy,
  account_currency: partnerForm.bankDetails.account_currency.length > 0,
  account_number: partnerForm.bankDetails.account_number.length > 0,
  address: partnerForm.bankDetails.address.length > 0,
  bank_address: partnerForm.bankDetails.bank_address.length > 0,
  bank_name: partnerForm.bankDetails.bank_name.length > 0,
  iban_number: partnerForm.bankDetails.iban_number.length > 0,
  swift_code: partnerForm.bankDetails.swift_code.length > 0
})

export const calculateProfilePercent = (form: IFormBoolean): number => {

  let updatedForm: any = { ...form }
  let newForm: any = {}

  // handling only basic info, sales and locale
  Object.keys(updatedForm).forEach(key => {
    if (!["partnerTargetSegments", "isSampleDistribution", "pricePerSample", "isInsertDistribution", "pricePerInsert", "warehouseAddresses", "isMediaBuy", "mediaBuyTypes"].includes(key)) {
      newForm[key] = updatedForm[key]
    }
  })

  const values = Object.values(newForm)
  const filled = values.filter(e => !!e)
  const percent = (filled.length / values.length) * 100
  return Math.ceil(percent)
}

export const DATA_BY_STEP = {
  [PARTNER_STEPS.GENERAL]: GENERAL_COMPANY_INFO_INITIAL_VALUES,
  [PARTNER_STEPS.SALES]: SALES_INFORMATION_INITIAL_VALUES,
  [PARTNER_STEPS.CUSTOMERS]: CUSTOMERS_AND_LOCALE_INITIAL_VALUES,
  [PARTNER_STEPS.AVAILABLE]: AVAILABLE_SERVICES_INITIAL_VALUES,
}

const METRICS_BY_STEP = {
  [PARTNER_STEPS.GENERAL]: [SOCIAL_TYPES.FACEBOOK, SOCIAL_TYPES.INSTAGRAM, SOCIAL_TYPES.TWITTER],
  [PARTNER_STEPS.SALES]: [SOCIAL_TYPES.LEADS, SOCIAL_TYPES.LOOKS],
} as { [key in PARTNER_STEPS]: string[] }

export const pickStepData = (partnerForm: IForm, currentStep: PARTNER_STEPS): Partial<IForm> => {
  const stepData = pick(partnerForm, Object.keys(DATA_BY_STEP[currentStep]))

  if (currentStep === PARTNER_STEPS.SALES) {
    delete stepData?.amountOfOrders
    delete stepData?.costOfOrder
  }

  if (Object.keys(METRICS_BY_STEP).includes(currentStep)) {
    if (currentStep === PARTNER_STEPS.SALES) {
      const bankData = pick(partnerForm.bankDetails, Object.keys(DATA_BY_STEP[currentStep]))
      const res = omit(
        { ...stepData, ...bankData, ...pick(stepData.metrics, METRICS_BY_STEP[currentStep]) },
        GENERAL_COMPANY_INFO_VALUES[GENERAL_COMPANY_INFO.SOCIAL_MEDIA_URLS]
      )
      return pick(res, Object.keys(DATA_BY_STEP[currentStep]))
    }
    //@ts-ignore
    return { ...stepData, metrics: pick(stepData.metrics, METRICS_BY_STEP[currentStep]) }
  }
  return stepData
}
