import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { AxiosResponse } from 'axios'
import IAction from 'src/interfaces/IAction'

import * as CONSTANTS from './leads.constants'
import { requestHttp, urls } from 'src/api'
import { ILead, ILeadsListParams } from './leads.types'
import { getResponseErrorMessage } from 'src/helpers'

export const leadsListRequest = (): IAction => ({
  type: CONSTANTS.FETCH_LEADS_REQUEST,
})

export const leadsListSuccess = (leadsList: {
  results: ILead[]
  total: number
  totalInvitedClient: number
  totalExistingUsers: number
}): IAction => ({
  type: CONSTANTS.FETCH_LEADS_SUCCESS,
  payload: leadsList,
})

export const leadsListFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_LEADS_FAILURE,
  error,
})

export const setCurrentPage = (page: number): IAction => ({
  type: CONSTANTS.SET_CURRENT_PAGE,
  payload: { page },
})

export const fetchLeadsList = (
  params: ILeadsListParams
): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse> => {
  try {
    dispatch(leadsListRequest())
    const response = await requestHttp.get(urls.getLeadsListUrl(), { params })
    const { content } = response.data.data
    dispatch(leadsListSuccess(content))
    return response
  } catch (error) {
    dispatch(leadsListFailure(getResponseErrorMessage(error)))
    return error
  }
}
