import get from 'lodash/get'

import { IRequestDetails, IState } from './request.types'
import * as CONSTANTS from './request.constants'
import IAction from 'src/interfaces/IAction'

const initialState: IState = {
  fetchingRequestListError: '',
  requestInfoError: '',
  requestDetailsError: '',
  requestListLoading: true,
  requestDetailsLoading: true,
  requestList: {
    results: [],
    total: 0,
  },
  pages: {
    totalPages: 1,
    currentPage: 1,
  },
  params: {
    offset: 0,
    limit: 10,
  },
  requestInfo: {
    total: 0,
    acceptance: 0,
    open: 0,
    revenueLost: 0,
  },

  requestDetails: null,
  requestExtendLoading: false,
  overrideRequestPaymentLoading: false,
  updatingRequest: false,
}

export default (state: IState = initialState, action: IAction): IState => {
  switch (action.type) {
    case CONSTANTS.RESET_REQUEST_STATE:
      return initialState
    case CONSTANTS.SET_CURRENT_PAGE:
      return { ...state, pages: { ...state.pages, currentPage: get(action, 'payload.page') } }
    case CONSTANTS.SET_REQUEST_LIST_PARAMS:
      return { ...state, params: get(action, 'payload.params') }

    case CONSTANTS.FETCH_REQUESTS_REQUEST:
      return {
        ...state,
        requestListLoading: true,
      }
    case CONSTANTS.FETCH_REQUESTS_SUCCESS:
      const requestList = get(action, 'payload.requestList')
      const totalPages = Math.ceil(requestList.total / state.params.limit)
      return {
        ...state,
        requestList,
        pages: { ...state.pages, totalPages },
        requestListLoading: false,
      }
    case CONSTANTS.FETCH_REQUESTS_FAILURE:
      return { ...state, fetchingRequestListError: action.error, requestListLoading: false }

    case CONSTANTS.FETCH_REQUESTS_INFO_SUCCESS:
      return {
        ...state,
        requestInfo: get(action, 'payload.info'),
      }
    case CONSTANTS.FETCH_REQUESTS_INFO_FAILURE:
      return { ...state, requestInfoError: action.error }

    case CONSTANTS.FETCH_REQUEST_DETAILS_REQUEST:
      return {
        ...state,
        requestDetailsLoading: true,
      }
    case CONSTANTS.FETCH_REQUEST_DETAILS_SUCCESS:
      return {
        ...state,
        requestDetails: get(action, 'payload.details'),
        requestDetailsLoading: false,
      }
    case CONSTANTS.FETCH_REQUEST_DETAILS_FAILURE:
      return { ...state, requestDetailsError: action.error, requestDetailsLoading: false }

    case CONSTANTS.EXTEND_REQUEST_REQUEST:
      return { ...state, requestExtendLoading: true }
    case CONSTANTS.EXTEND_REQUEST_SUCCESS:
      return { ...state, requestExtendLoading: false }
    case CONSTANTS.EXTEND_REQUEST_FAILURE:
      return { ...state, requestExtendLoading: false }

    case CONSTANTS.OVERRIDE_REQUEST_PAYMENT_REQUEST:
      return { ...state, overrideRequestPaymentLoading: true }
    case CONSTANTS.OVERRIDE_REQUEST_PAYMENT_SUCCESS:
      return {
        ...state,
        requestDetails: { ...(state.requestDetails as IRequestDetails), isPaymentOverridden: true, status: get(action, 'payload.status') },
        overrideRequestPaymentLoading: false,
      }
    case CONSTANTS.OVERRIDE_REQUEST_PAYMENT_FAILURE:
      return { ...state, overrideRequestPaymentLoading: false }

    case CONSTANTS.UPDATE_REQUEST_REQUEST:
      return { ...state, updatingRequest: true }
    case CONSTANTS.UPDATE_REQUEST_SUCCESS:
      return { ...state, updatingRequest: false }
    case CONSTANTS.UPDATE_REQUEST_FAILURE:
      return { ...state, updatingRequest: false }
    default:
      return state
  }
}
