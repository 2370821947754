import React, { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { useReduxDispatch } from 'src/helpers'
import { Col, QBackLink, QButton, Radio, Row } from 'quantum_components'
import { useSelector } from 'react-redux'
import { PATHS } from '../../../constants'
import { getActiveTab } from '../../brief/brief.selectors'
import * as briefActions from '../../brief/brief.actions'
import { TABS_NAMES } from '../../../constants/tabs'
import { actions } from '../campaign.module'
import DialogModal from '../../../components/DialogModal/DialogModal'
import { getCampaignDetails, getCampaignSettings, getCampaignUpdating } from '../campaign.selectors'
import { CAMPAIGN_STATUS } from '../../../constants/campaign'
import CampaignSurveyList from './components/Survey/CampaignSurveyList'
import { getCurrentUserPermissionActions } from '../../core/core.selectors'
import { hasPermission } from '../../../helpers/permission'
import { PERMISSIONS } from '../../../constants/permission'
import CampaignEditMediaBuy from './components/MediaBuy/CampaignEditMediaBuy'
import CampaignEditDistribution from './components/Distribution/CampaignEditDistribution'
import CampaignEditGallery from './components/Gallery/CampaignEditGallery'
import * as coreActions from '../../core/core.actions'
import { CORE_CONSTANTS } from '../../../constants/settings'
import CompleteCampaignButton from './components/CompleteCampaign/CompleteCampaignButton'

export default function CampaignEditContainer(): ReactElement {
  const { i18n } = useTranslation()
  const { t } = useTranslation(['translationCampaign', 'translationCommon'])
  const history = useHistory()
  const dispatch = useReduxDispatch()
  const { id } = useParams<{id: any}>()

  const activeTab = useSelector(getActiveTab)
  const initData = useSelector(getCampaignSettings)
  const permissions = useSelector(getCurrentUserPermissionActions)
  const campaignUpdating = useSelector(getCampaignUpdating)
  const [showModal, setShowModal] = useState<boolean>(false)
  const campaignDetails = useSelector(getCampaignDetails)

  const isCompleted = initData.campaignStatus === CAMPAIGN_STATUS.COMPLETED
  const disabledButton = isCompleted || campaignUpdating
  const disableComplete = isCompleted || campaignUpdating || !hasPermission(permissions, PERMISSIONS.CAMPAIGN_COMPLETE)

  const fetchData = async () => {
    await dispatch(actions.fetchCampaignDetails(id))
    await dispatch(actions.fetchCampaignSettings(id))
    await dispatch(actions.fetchCampaignProps(id))
  }
  const fetchSettings = () => {
    dispatch(coreActions.fetchConstants([CORE_CONSTANTS.CAMPAIGN_MEDIA_BUYS, CORE_CONSTANTS.AGE_GROUPS_GENDERS]))
  }

  useEffect(() => {
    fetchData()
    fetchSettings()
    dispatch(briefActions.setActiveTab(TABS_NAMES.MEDIA_BUY))

    return () => {
      dispatch(briefActions.setActiveTab(TABS_NAMES.BRIEF))
      dispatch(actions.resetCampaignState())
    }
  }, [])

  const handleChangeTab = (e: any) => {
    dispatch(briefActions.setActiveTab(e.target.value))
  }

  const handleBack = () => {
    history.push(PATHS.BRIEF)
  }

  const handleComplete = () => {
    // if (initData.totalQuantity !== initData.allocatedQuantity) {
    //   setShowModal(true)
    //   return
    // }

    proceedComplete()
  }

  const proceedComplete = async () => {
    await dispatch(actions.completeCampaign(id))
    closeDialogModal()
  }

  const modalQuestion = 'Distributed quantity does not match the amount assigned, Close Campaign anyway?'
  const buttonText = 'Go Back & Update'

  const closeDialogModal = () => {
    setShowModal(false)
  }

  return (
    <div className="qt pb-10">
      <div className={campaignDetails ? '' : 'mb-50'}>
        <QBackLink title={t('details.Back to Campaigns')} onClick={handleBack} />
        <Row className={'mt-16'} gutter={16} justify="space-between">
          <Col span={12}>
            <Radio.Group className="account-selector qu-radio-group full-width" buttonStyle="solid" value={activeTab}>
              {/* <Radio.Button
                disabled={!hasPermission(permissions, PERMISSIONS.CAMPAIGN_EDIT_DISTRIBUTION)}
                className="qu-radio-button qu-radio-white"
                value={TABS_NAMES.DISTRIBUTION}
                onChange={handleChangeTab}
              >
                <span className="full-width text-uppercase">Distribution</span>
              </Radio.Button> */}
              <Radio.Button
                disabled={!hasPermission(permissions, PERMISSIONS.CAMPAIGN_EDIT_MEDIA_BUY)}
                className="qu-radio-button qu-radio-white"
                value={TABS_NAMES.MEDIA_BUY}
                onChange={handleChangeTab}
              >
                <span className="full-width text-uppercase">{t('details.media_buy')}</span>
              </Radio.Button>
              {/* <Radio.Button
                disabled={!hasPermission(permissions, PERMISSIONS.CAMPAIGN_EDIT_SURVEY)}
                className="qu-radio-button qu-radio-white"
                value={TABS_NAMES.SURVEY}
                onChange={handleChangeTab}
              >
                <span className="full-width text-uppercase">Survey</span>
              </Radio.Button> */}
              <Radio.Button
                disabled={!hasPermission(permissions, PERMISSIONS.CAMPAIGN_EDIT_GALLERY)}
                className="qu-radio-button qu-radio-white"
                value={TABS_NAMES.GALLERY}
                onChange={handleChangeTab}
              >
                <span className="full-width text-uppercase">{t('details.gallery')}</span>
              </Radio.Button>
            </Radio.Group>
          </Col>
          <Col span={8}>
            <CompleteCampaignButton
              disableComplete={disableComplete}
              handleComplete={handleComplete}
              buttonText={isCompleted ? t('details.Completed') : t('details.Complete Campaign')}
            />
          </Col>
        </Row>
      </div>
      {campaignDetails && (
        <div className="m-15">
          <Row justify="space-between">
            <Col>
              <h3>
                {t('edit.campaign_from_company', {campaignName:campaignDetails?.brief.name, companyName:campaignDetails?.briefOwner.companyName})}
              </h3>
            </Col>
          </Row>
        </div>
      )}
      {activeTab === TABS_NAMES.DISTRIBUTION && <CampaignEditDistribution disabledButton={disabledButton} />}
      {activeTab === TABS_NAMES.SURVEY && <CampaignSurveyList />}
      {activeTab === TABS_NAMES.MEDIA_BUY && <CampaignEditMediaBuy />}
      {activeTab === TABS_NAMES.GALLERY && <CampaignEditGallery disabledButton={disabledButton} />}
      <DialogModal
        modalOpen={showModal}
        question={modalQuestion}
        description={''}
        submitButtonText={buttonText}
        cancelButtonText={'Proceed Anyways'}
        onClose={closeDialogModal}
        onProceed={proceedComplete}
      />
    </div>
  )
}
