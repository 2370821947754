import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationNativeTemplatesEN from './locales/en/native-templates.json'
import translationTargetingKeysEN from './locales/en/targeting-keys.json'
import translationEN from './locales/en/brief-translation.json'
import translationDashboardEN from './locales/en/dashboard-translation.json'
import translationReferEN from './locales/en/refer-translation.json'
import translationMarketplaceEN from './locales/en/marketplace-translation.json'
import translationCampaignEN from './locales/en/campaign-translation.json'
import translationUserEN from './locales/en/user-translation.json'
import translationPartnerEN from './locales/en/partner-translation.json'
import translationCommonEN from './locales/en/common.json'
import translationSettingsEN from './locales/en/adminsettings-translation.json'
import translationProfileEN from './locales/en/profile-translation.json'
import translationPromotionsEN from './locales//en/promotions-translation.json'
import translationClientEN from './locales/en/client-translation.json'
import translationWorkflowEN from './locales/en/workflow-translation.json'
import translationABriefEN from './locales/en/abrief-translation.json'
import translationDiscountsEN from './locales/en/discountcodes-translation.json'
import translationEmployeesEN from './locales/en/employees-translation.json'
import translationCalendarEN from './locales/en/calendar-translation.json'
import translationPlatformEN from './locales/en/platform-translation.json'
import translationInviteClientsEN from './locales/en/invite-clients.json'
import translationSupportTicketsEN from './locales/en/supporttickets-translation.json'
import translationSubscriptionHistoryEN from './locales/en/subscriptionhistory-translation.json'
import translationWalletEN from './locales/en/wallet-translation.json'
import translationV2EN from './locales/en/v2-translation.json'
import translationServiceV2EN from './locales/en/service-v2.json'
import translationProductEN from './locales/en/product-translation.json'
import translationDashboardV2EN from './locales/en/dashboard-v2.json'
import translationPublisherEN from './locales/en/publisher-translation.json'

import translationNativeTemplatesAR from './locales/ar/native-templates.json'
import translationTargetingKeysAR from './locales/ar/targeting-keys.json'
import translationAR from './locales/ar/brief-translation.json'
import translationDashboardAR from './locales/ar/dashboard-translation.json'
import translationReferAR from './locales/ar/refer-translation.json'
import translationMarketplaceAR from './locales/ar/marketplace-translation.json'
import translationCampaignAR from './locales/ar/campaign-translation.json'
import translationUserAR from './locales/ar/user-translation.json'
import translationPartnerAR from './locales/ar/partner-translation.json'
import translationCommonAR from './locales/ar/common.json'
import translationSettingsAR from './locales/ar/adminsettings-translation.json'
import translationProfileAR from './locales/ar/profile-translation.json'
import translationWorkflowAR from './locales/ar/workflow-translation.json'
import translationABriefAR from './locales/ar/abrief-translation.json'
import translationPromotionsAR from './locales/ar/promotions-translation.json'
import translationClientAR from './locales/ar/client-translation.json'
import translationDiscountsAR from './locales/ar/discountcodes-translation.json'
import translationEmployeesAR from './locales/ar/employees-translation.json'
import translationCalendarAR from './locales/ar/calendar-translation.json'
import translationPlatformAR from './locales/ar/platform-translation.json'
import translationInviteClientsAR from './locales/ar/invite-clients.json'
import translationSupportTicketsAR from './locales/ar/supporttickets-translation.json'
import translationSubscriptionHistoryAR from './locales/ar/subscriptionhistory-translation.json'
import translationWalletAR from './locales/ar/wallet-translation.json'
import translationV2AR from './locales/ar/v2-translation.json'
import translationServiceV2AR from './locales/ar/service-v2.json'
import translationProductAR from './locales/ar/product-translation.json'
import translationDashboardV2AR from './locales/ar/dashboard-v2.json'
import translationPublisherAR from './locales/ar/publisher-translation.json'

const resources = {
  en: {
    translation: translationEN,
    translationABrief: translationABriefEN,
    translationDashboard: translationDashboardEN,
    translationProfile: translationProfileEN,
    translationRefer: translationReferEN,
    translationMarketplace: translationMarketplaceEN,
    translationCampaign: translationCampaignEN,
    translationUser: translationUserEN,
    translationPartner: translationPartnerEN,
    translationCommon: translationCommonEN,
    translationSettings: translationSettingsEN,
    translationClient: translationClientEN,
    translationWorkflow: translationWorkflowEN,
    translationPromotions: translationPromotionsEN,
    translationDiscounts: translationDiscountsEN,
    translationEmployees: translationEmployeesEN,
    translationCalendar: translationCalendarEN,
    translationPlatform: translationPlatformEN,
    translationInviteClients: translationInviteClientsEN,
    translationSupportTickets: translationSupportTicketsEN,
    translationSubscriptionHistory: translationSubscriptionHistoryEN,
    translationWallet: translationWalletEN,
    translationTargetingKeys: translationTargetingKeysEN,
    translationNativeTemplates: translationNativeTemplatesEN,
    translationV2: translationV2EN,
    translationServiceV2: translationServiceV2EN,
    translationProduct: translationProductEN,
    translationDashboardV2: translationDashboardV2EN,
    translationPublisher: translationPublisherEN,
  },
  ar: {
    translation: translationAR,
    translationABrief: translationABriefAR,
    translationDashboard: translationDashboardAR,
    translationProfile: translationProfileAR,
    translationRefer: translationReferAR,
    translationMarketplace: translationMarketplaceAR,
    translationCampaign: translationCampaignAR,
    translationUser: translationUserAR,
    translationPartner: translationPartnerAR,
    translationCommon: translationCommonAR,
    translationSettings: translationSettingsAR,
    translationClient: translationClientAR,
    translationWorkflow: translationWorkflowAR,
    translationPromotions: translationPromotionsAR,
    translationDiscounts: translationDiscountsAR,
    translationEmployees: translationEmployeesAR,
    translationCalendar: translationCalendarAR,
    translationPlatform: translationPlatformAR,
    translationInviteClients: translationInviteClientsAR,
    translationSupportTickets: translationSupportTicketsAR,
    translationSubscriptionHistory: translationSubscriptionHistoryAR,
    translationWallet: translationWalletAR,
    translationTargetingKeys: translationTargetingKeysAR,
    translationNativeTemplates: translationNativeTemplatesAR,
    translationV2: translationV2AR,
    translationServiceV2: translationServiceV2AR,
    translationProduct: translationProductAR,
    translationDashboardV2: translationDashboardV2AR,
    translationPublisher: translationPublisherAR,
  }
};
//i18N Initialization

i18n.use(initReactI18next).init({
  resources,
  lng: "en", //default language
  // keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
