import React, { Fragment, ReactElement } from 'react'
import { Col, Row } from 'quantum_components'
import CampaignTimelineTable from './CampaignTimelineTable'
import CampaignEditTimelineForm from './CampaignEditTimeline.form'

interface IProps {
  disabledButton: boolean
}

export default function CampaignEditTimeline({ disabledButton }: IProps): ReactElement {
  return (
    <Fragment>
      <Row className="mb-30" gutter={24}>
        <Col xs={24} md={8}>
          <CampaignEditTimelineForm disabledButton={disabledButton} />
        </Col>
        <Col xs={24} md={16}>
          <CampaignTimelineTable disabledButton={disabledButton} />
        </Col>
      </Row>
    </Fragment>
  )
}
