import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useReduxDispatch } from '../../../helpers'
import {
  getIsWalletListLoading,
  getWalletHistoryList,
  getWalletTotal,
  getPages,
} from '../wallet.selectors'
import * as actions from '../wallet.actions'

import { QTable, QPagination, Row, Col, QInput } from 'quantum_components'
import { SearchOutlined, PlusOutlined, MinusOutlined, ArrowRightOutlined } from '@ant-design/icons'
import { getReadableDateWithTime } from 'src/helpers/datesHelper'
import { isMobile } from 'react-device-detect'
import SpinnerCenter from 'src/components/Spinner/spinner'
import { getLocaleCost } from 'src/helpers/currency'
import useDebounce from 'src/hooks/useDebounce'
import { IOptionForSelect } from 'src/modules/brief/brief.types'
import { IWalletHistory } from '../wallet.types'
import { TRANSACTION_TYPES } from 'src/constants/transactionTypes'

const WalletListing = () => {
  const { t } = useTranslation(['translationWallet', 'translationCommon'])

  const dispatch = useReduxDispatch()
  const walletHistoryList = useSelector(getWalletHistoryList)
  const walletTotal = useSelector(getWalletTotal)
  const walletListIsLoading = useSelector(getIsWalletListLoading)
  const pages = useSelector(getPages)

  const [search, setSearch] = useState('')
  const [transferTypeFilter, setTransferTypeFilter] = useState<string>('')
  const [params, setParams] = useState({
    currentPage: 1,
    limit: 10,
    offset: 0,
    direction: 'desc',
    sortField: 'createdAt',
  })

  const debouncedSearch = useDebounce(search, 500)
  const debouncedTransferTypeFilter = useDebounce(transferTypeFilter, 500)

  const transferTypeOptions: IOptionForSelect[] = [
    {
      label: t('Inflow'),
      value: 'inflow',
    },
    {
      label: t('Outflow'),
      value: 'outflow',
    },
    {
      label: t('Paid'),
      value: 'paid',
    },
  ]

  useEffect(() => {
    fetchWalletForFilters()
    dispatch(actions.setCurrentPage(1))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch, debouncedTransferTypeFilter])

  const fetchWallet = (queryParams = params) => {
    dispatch(
      actions.fetchWalletList({
        ...queryParams,
        search: debouncedSearch,
        transferType: debouncedTransferTypeFilter,
      })
    )
  }

  const fetchWalletForFilters = (queryParams = params) => {
    dispatch(
      actions.fetchWalletList({
        ...queryParams,
        search: debouncedSearch,
        transferType: debouncedTransferTypeFilter,
        currentPage: 1,
        limit: 10,
        offset: 0,
      })
    )
  }

  // @ts-ignore
  const handleTableChange = (pagination, filters, sorter) => {
    const sortingParams = {
      direction: sorter.order.slice(0, -3),
      sortField: sorter.columnKey,
    }
    const newParams = {
      ...params,
      ...sortingParams,
    }
    setParams(newParams)
    fetchWallet(newParams)
  }

  const handleChangePage = (page: number, pageSize: number) => {
    dispatch(actions.setCurrentPage(page))

    dispatch(
      actions.fetchWalletList({
        ...params,
        offset: (page - 1) * params.limit,
        currentPage: page,
        limit: pageSize,
        search: debouncedSearch,
        transferType: debouncedTransferTypeFilter,
      })
    )

    setParams({
      ...params,
      offset: (page - 1) * params.limit,
      currentPage: page,
      limit: pageSize,
    })
  }

  const handleChangeSearch = (event: any) => {
    setSearch(event.currentTarget.value)
  }

  const handleChangeSelect = (status: string) => {
    dispatch(actions.setCurrentPage(1))
    setTransferTypeFilter(status)
  }

  const columns = [
    {
      title: t('Title'),
      dataIndex: 'title',
      key: 'title',
      width: '60%',
    },
    {
      title: t('Amount'),
      dataIndex: 'amount',
      key: 'amount',
      width: '20%',
      render: (amount: string, data: IWalletHistory) => {
        return <span className={`${data.transactionType === TRANSACTION_TYPES.INFLOW ? 'inflow-amount' : data.transactionType === TRANSACTION_TYPES.OUTFLOW ? 'outflow-amount' : ''}`}>
          {
            data.transactionType === TRANSACTION_TYPES.INFLOW
              ? <PlusOutlined style={{ fontSize: '11px' }} />
              : data.transactionType === TRANSACTION_TYPES.OUTFLOW
                ? <MinusOutlined style={{ fontSize: '11px' }} />
                : <ArrowRightOutlined style={{ fontSize: '11px' }} />
          }
          {' ' + data.currency + ' ' + getLocaleCost(Number(amount))}
        </span>
      },
    },
    {
      title: t('Created Date'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      width: '20%',
      render: (createdAt: string) => {
        return getReadableDateWithTime(createdAt)
      },
    },
  ]

  return (
    <>
      {walletListIsLoading && <SpinnerCenter />}
      <Row justify="space-between">
        <Col className="qt-search">
          <QInput
            value={search}
            onChange={handleChangeSearch}
            placeholder={t('common.Search', { ns: 'translationCommon' })}
            size="large"
            prefix={<SearchOutlined />}
          />
        </Col>
        {/* below filter is to filter records by transaction type */}
        {/* <Col className="qt-filter">
          <QSelect
            size="large"
            allowClear={true}
            placeholder={t('Filter by Transfer Type')}
            showArrow
            options={transferTypeOptions}
            onChange={handleChangeSelect}
          />
        </Col> */}
      </Row>

      <div className="pt-15 pb-30">
        <QTable
          columns={columns}
          dataSource={walletHistoryList}
          scroll={{ x: 990 }}
          // @ts-ignore
          onChange={handleTableChange}
        />
      </div>
      <Row
        // @ts-ignore
        justify={`${isMobile ? "center" : "end"}`}
      >
        <QPagination
          className="force-ltr mb-30"
          size="default"
          defaultCurrent={1}
          current={pages.currentPage}
          total={walletTotal}
          onChange={handleChangePage}
          showSizeChanger
        />
      </Row>
    </>
  )
}

export default WalletListing
