export default {
  EMAIL_REGEX: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
  EMAIL_SEPARATE_COMMA_REGEX: /^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})[\W]*$/,
  PHONE_BODY_REGEX: /\d{9,13}$/i,
  FIFTEEN_DIGIT: /^[0-9]{15}$/,
  TEN_DIGIT: /^[0-9]{10}$/,
  IBAN: /^[A-Z]{2}(?:[ ]?[0-9]|[A-Z]|\s){15,34}$/,
  AMOUNT_REGEX: /^\d+(\.\d{0,4})?$/,
  ACCOUNT_NUMBER: /^.{1,34}$/,
  GIIN_NUMBER: /^.{16}$/,
  URL: /https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}/,
  PASSWORD: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{7,255}$/,
  POSITIVE_NUMBERS_REGEX: /^\d*[1-9]\d*$/,
  EMPTY_FIELD: /([^\s])/,
  NUMBERS_REGEX: /^\d*[0-9]\d*$/,
  AMOUNT_REGEX_WITH_TWO_DECIMAL: /^\d+(\.\d{0,2})?$/,
  PERCENTAGE_NUMBER: /^([0]?[1-9]?\d(\.\d\d?)?|100(\.00?)?)$/,
  ARABIC_CHARACTERS_NUMBERS_SPACES: /^[\u0621-\u064A\u0660-\u06690-9 ]+$/,
  POSITIVE_AMOUNT_REGEX_WITH_TWO_DECIMAL: /^(?=.*[1-9])[0-9]*[.,]?[0-9]{1,2}$/,
  BUDGET_AMOUNT_REGEX: /^\d+(\.\d{0,2})?$/,
  POSITIVE_NUMBERS_GREATER_THAN_999_REGEX: /^(?!(?:\d{1,3}|999)$)[0-9]\d+$/,
  REQUIRED_AND_MAX_255_CHARS: /^[\u0621-\u064A\u0660-\u0669a-zA-Z0-9\s\S\.\!\@\#\$\%\^\&\*\(\)\\]{1,255}$/
}
