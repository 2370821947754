import { createSelector } from 'reselect'
import moment from 'moment'
import { FORMATS } from '../../../constants'
import { MODULE_NAME } from './invitedClients.constants'
import { IRequestListElement } from './invitedClients.types'

const selectState = (state: { [MODULE_NAME]: any }) => state[MODULE_NAME]

export const getRequestsListLoading = createSelector(selectState, (state): boolean => state.requestListLoading)

export const getRequestsList = createSelector(selectState, state =>
  state.requestsList.results.map((e: IRequestListElement) => ({
    ...e,
    key: e.id,
    fullname: `${e.firstName} ${e.lastName}`,
    createdAt: moment(e.createdAt).format(FORMATS.DATE_TIME_FORMAT),
  }))
)

export const getTotalRequestsList = createSelector(selectState, (state): number => state.requestsList.total)
