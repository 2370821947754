import { createSelector } from 'reselect'
import { MODULE_NAME } from './profile.constants'
import { IPartner, IMediaTypePictures, ICustomMediaType } from './profile.types'
import { PARTNER_STEPS } from '../../../constants/profile'

const selectState = (state: { [MODULE_NAME]: IPartner }): IPartner => state[MODULE_NAME]
export const getUnfinishedStep = createSelector(selectState, state => state.unfinishedSteps)
export const getCurrentStep = createSelector(selectState, state => state.currentStep)
export const getFilledSteps = createSelector(selectState, (state): PARTNER_STEPS[] => state.filledSteps)
export const getLastFilledStep = createSelector(selectState, (state): PARTNER_STEPS => state.filledSteps.slice(-1)[0])
export const getForm = createSelector(selectState, state => state.form)
export const getPartnerProfileSettings = createSelector(selectState, state => state.settings)
export const getPartnerPartnerPercent = createSelector(selectState, state => state.partnerProfilePercent)
export const getProfileLoading = createSelector(selectState, state => state.fetchPartnerLoading)
export const getFormBoolean = createSelector(selectState, state => state.formBoolean)
export const getPublicProfile = createSelector(selectState, state => state.form.publicProfile)

export const getIsImagesUploading = createSelector(selectState, (state): boolean => state.imagesUploading)

export const getAllCustomMediaServices = createSelector(selectState, (state): ICustomMediaType[] => state.customMediaServices)

//@ts-ignore
export const getMediaTypeImages = createSelector(getForm, (form: IMediaTypePictures) =>
  //@ts-ignore
  form.mediaBuyTypes.map(({ name, subName, previewImage }) => ({ name, subName, previewImage: previewImage || '' }))
)
