import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { AxiosResponse } from 'axios'

import IAction from 'src/interfaces/IAction'
import { requestHttp, urls } from 'src/api'
import {
  IUsersListParams,
  IUsersList,
  IPartnerProperties,
  IPartnerSettings,
  IUserListElement,
} from './users.types'
import * as CONSTANTS from './users.constants'
import { ROLES, STATUS_CODES } from 'src/constants'
import { getResponseErrorMessage } from 'src/helpers'
import {
  getActivateClientAccountUrl,
  getPartnerSettingsUrl,
  getPropertiesByUserIdUrl,
  getSavePropertiesUrl,
  userGeneratedPasswordUrl,
} from '../../api/urls'

export const usersListRequest = (): IAction => ({
  type: CONSTANTS.FETCH_USERS_REQUEST,
})

export const usersListSuccess = (usersList: IUsersList, infinite?: boolean): IAction => ({
  type: CONSTANTS.FETCH_USERS_SUCCESS,
  payload: { usersList, infinite },
})

export const usersListFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_USERS_FAILURE,
  error,
})

export const publishersListRequest = (): IAction => ({
  type: CONSTANTS.FETCH_PUBLISHERS_REQUEST,
})

export const publishersListSuccess = (publishersList: IUserListElement[]): IAction => ({
  type: CONSTANTS.FETCH_PUBLISHERS_SUCCESS,
  payload: { publishersList },
})

export const publishersListFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_PUBLISHERS_FAILURE,
  error,
})

export const enablePaymentUserRequest = (): IAction => ({
  type: CONSTANTS.ENABLE_PAYMENT_USER_REQUEST,
})

export const enablePaymentUserSuccess = (userId: number, data: any) => ({
  type: CONSTANTS.ENABLE_PAYMENT_USER_SUCCESS,
  payload: { userId, data },
})

export const enablePaymentUserFailure = (error: string): IAction => ({
  type: CONSTANTS.ENABLE_PAYMENT_USER_FAILURE,
  error,
})

export const disablePaymentUserRequest = (): IAction => ({
  type: CONSTANTS.DISABLE_PAYMENT_USER_REQUEST,
})

export const disablePaymentUserSuccess = (userId: number) => ({
  type: CONSTANTS.DISABLE_PAYMENT_USER_SUCCESS,
  payload: userId,
})

export const disablePaymentUserFailure = (error: string): IAction => ({
  type: CONSTANTS.DISABLE_PAYMENT_USER_FAILURE,
  error,
})

export const banUserRequest = (): IAction => ({
  type: CONSTANTS.BAN_USER_REQUEST,
})

export const banUserSuccess = (userId: number) => ({
  type: CONSTANTS.BAN_USER_SUCCESS,
  payload: userId,
})

export const banUserFailure = (error: string): IAction => ({
  type: CONSTANTS.BAN_USER_FAILURE,
  error,
})

export const unbanUserRequest = (): IAction => ({
  type: CONSTANTS.UNBAN_USER_REQUEST,
})

export const unbanUserSuccess = (userId: number) => ({
  type: CONSTANTS.UNBAN_USER_SUCCESS,
  payload: userId,
})

export const unbanUserFailure = (error: string): IAction => ({
  type: CONSTANTS.UNBAN_USER_FAILURE,
  error,
})

export const allowUploadPoRequest = (): IAction => ({
  type: CONSTANTS.ALLOW_UPLOAD_PO_REQUEST,
})

export const allowUploadPoSuccess = (userId: number) => ({
  type: CONSTANTS.ALLOW_UPLOAD_PO_SUCCESS,
  payload: userId,
})

export const allowUploadPoFailure = (error: string): IAction => ({
  type: CONSTANTS.ALLOW_UPLOAD_PO_FAILURE,
  error,
})

export const restrictUploadPoRequest = (): IAction => ({
  type: CONSTANTS.RESTRICT_UPLOAD_PO_REQUEST,
})

export const restrictUploadPoSuccess = (userId: number) => ({
  type: CONSTANTS.RESTRICT_UPLOAD_PO_SUCCESS,
  payload: userId,
})

export const restrictUploadPoFailure = (error: string): IAction => ({
  type: CONSTANTS.RESTRICT_UPLOAD_PO_FAILURE,
  error,
})

export const setCurrentUser = (user: IUserListElement): IAction => ({
  type: CONSTANTS.SET_CURRENT_USER,
  payload: user,
})

export const partnerSettingsSuccess = (partnerSettings: IPartnerSettings): IAction => ({
  type: CONSTANTS.FETCH_PARTNER_SETTINGS_SUCCESS,
  payload: partnerSettings,
})

export const partnerSettingsFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_PARTNER_SETTINGS_FAILURE,
  error,
})

export const partnerPropertiesRequest = (): IAction => ({
  type: CONSTANTS.FETCH_PARTNER_PROPERTIES_REQUEST,
})

export const partnerPropertiesSuccess = (partnerProperties: IPartnerProperties): IAction => ({
  type: CONSTANTS.FETCH_PARTNER_PROPERTIES_SUCCESS,
  payload: partnerProperties,
})

export const partnerPropertiesFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_PARTNER_PROPERTIES_FAILURE,
  error,
})

export const resetProperties = (): IAction => ({
  type: CONSTANTS.RESET_PARTNER_PROPERTIES,
})

export const resetUsers = (): IAction => ({
  type: CONSTANTS.RESET_USERS_STATE,
})

export const setCurrentPage = (page: number): IAction => ({
  type: CONSTANTS.SET_CURRENT_PAGE,
  payload: { page },
})

export const paymentMethodsUpdateRequest = (): IAction => ({
  type: CONSTANTS.PAYMENT_METHODS_REQUEST,
})

export const paymentMethodsUpdateSuccess = (paymentMethods: any) => ({
  type: CONSTANTS.PAYMENT_METHODS_SUCCESS,
  payload: paymentMethods,
})

export const paymentMethodsUpdatebanUserFailure = (error: string): IAction => ({
  type: CONSTANTS.PAYMENT_METHODS_FAILURE,
  error,
})

export const fetchUsersList = (
  params: IUsersListParams
): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse> => {
    try {
      dispatch(usersListRequest())

      const getUrlMethod = params.role === ROLES.CLIENT ? urls.getClientsListUrl : urls.getPartnersListUrl
      const response = await requestHttp.get(getUrlMethod(), { params })

      const { content } = response.data.data
      dispatch(usersListSuccess(content, params.infinite))
      return response
    } catch (error) {
      dispatch(usersListFailure(getResponseErrorMessage(error)))
      return error
    }
  }

export const fetchAdServerUsersList = (
  params: IUsersListParams
): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse> => {
    try {
      dispatch(usersListRequest())

      const getUrlMethod = params.role === ROLES.CLIENT ? urls.getAdServerClientsListUrl : urls.getPartnersListUrl
      const response = await requestHttp.get(getUrlMethod(), { params })

      const { content } = response.data.data
      dispatch(usersListSuccess(content, params.infinite))
      return response
    } catch (error) {
      dispatch(usersListFailure(getResponseErrorMessage(error)))
      return error
    }
  }

export const banUser = (id: number): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse> => {
  try {
    dispatch(banUserRequest())

    const response = await requestHttp.patch(`${urls.getUserBanUrl(id)}`)
    dispatch(banUserSuccess(id))
    return response
  } catch (error) {
    dispatch(banUserFailure(getResponseErrorMessage(error)))
    return error
  }
}

export const allowPaymentStep = (userID: number): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse> => {
  try {
    dispatch(enablePaymentUserRequest())
    const response = await requestHttp.patch(`${urls.getEditClientUrl(userID)}`, { payment_enabled: true })
    dispatch(enablePaymentUserSuccess(userID, response.data.data.content))
    return response
  } catch (error) {
    dispatch(enablePaymentUserFailure(getResponseErrorMessage(error)))
    return error
  }
}

export const restrictPaymentStep = (userID: number): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse> => {
  try {
    dispatch(disablePaymentUserRequest())
    const response = await requestHttp.patch(`${urls.getEditClientUrl(userID)}`, { payment_enabled: false })
    dispatch(disablePaymentUserSuccess(userID))
    return response
  } catch (error) {
    dispatch(disablePaymentUserFailure(getResponseErrorMessage(error)))
    return error
  }
}


export const unbanUser = (id: number): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse> => {
  try {
    dispatch(unbanUserRequest())

    const response = await requestHttp.patch(`${urls.getUserUnbanUrl(id)}`)
    dispatch(unbanUserSuccess(id))
    return response
  } catch (error) {
    dispatch(unbanUserFailure(getResponseErrorMessage(error)))
    return error
  }
}

export const allowUploadPo = (userId: number): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse> => {
  try {
    dispatch(allowUploadPoRequest())

    const response = await requestHttp.patch(urls.getEditClientUrl(userId), { po_enabled: true })
    dispatch(allowUploadPoSuccess(userId))
    return response
  } catch (error) {
    dispatch(allowUploadPoFailure(getResponseErrorMessage(error)))
    return error
  }
}

export const restrictUploadPo = (userId: number): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse> => {
  try {
    dispatch(restrictUploadPoRequest())

    const response = await requestHttp.patch(urls.getEditClientUrl(userId), { po_enabled: false })
    dispatch(restrictUploadPoSuccess(userId))
    return response
  } catch (error) {
    dispatch(restrictUploadPoFailure(getResponseErrorMessage(error)))
    return error
  }
}

export const fetchPartnerSettings = (id = null): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse> => {
  try {
    const response = await requestHttp.get(getPartnerSettingsUrl())

    const { content } = response.data.data

    dispatch(fetchAgeGroups(content, id))
    return response
  } catch (error) {
    dispatch(partnerSettingsFailure(getResponseErrorMessage(error)))
    return error
  }
}

export const fetchAgeGroups = (settings: any, id = null): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    const response = await requestHttp.get(urls.getAgeGroupsListUrl())
    if (response.status === STATUS_CODES.BASE_SUCCESS) {
      const { content } = response.data.data
      let ageGroups: any = []
      content.map((ele: any) => {
        ageGroups.push(ele.from + (ele.to ? '-' + ele.to + ' ' + ele.name : '+ ' + ele.name))
      })
      settings.ageGroups = ageGroups
      dispatch(fetchTargetSegments(settings, id))
    }
  } catch (error) {
    dispatch(partnerSettingsFailure(getResponseErrorMessage(error)))
    return error
  }
}

export const fetchTargetSegments = (settings: any, id: any = null): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    const response = await requestHttp.get(urls.getSegmentsListUrl())
    if (response.status === STATUS_CODES.BASE_SUCCESS) {
      const { content } = response.data.data
      let segments = content.map((ele: any) => {
        return ele.name
      })
      settings.segments = segments
      dispatch(partnerSettingsSuccess(settings))
      if (id) dispatch(fetchPartnerProperties(id))
    }
  } catch (error) {
    dispatch(partnerSettingsFailure(getResponseErrorMessage(error)))
    return error
  }
}

export const fetchPartnerProperties = (
  userId: number
): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse> => {
    try {
      dispatch(partnerPropertiesRequest())

      const response = await requestHttp.get(getPropertiesByUserIdUrl(userId))

      const { content } = response.data.data

      dispatch(partnerPropertiesSuccess(content))
      return response
    } catch (error) {
      dispatch(partnerPropertiesFailure(getResponseErrorMessage(error)))
      return error
    }
  }

export const savePartnerProperties = (
  data: IPartnerProperties
): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse> => {
    try {
      const response = await requestHttp.post(getSavePropertiesUrl(), data)

      return response
    } catch (error) {
      return error
    }
  }

export const getUserGeneratedPassword = (): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse> => {
  try {
    const response = await requestHttp.get(userGeneratedPasswordUrl())
    const { content } = response.data.data
    return content
  } catch (error) {
    dispatch(partnerSettingsFailure(getResponseErrorMessage(error)))
    return error
  }
}

export const sendUserGeneratedPassword = (payload: {
  userId: number
  password: string
}): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse> => {
    try {
      const response = await requestHttp.patch(userGeneratedPasswordUrl(), payload)
      const { status } = response.data
      return status
    } catch (error) {
      dispatch(partnerSettingsFailure(getResponseErrorMessage(error)))
      return error
    }
  }

export const allowPaymentMethods = (userID: number, payload: any): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse> => {
  try {
    dispatch(paymentMethodsUpdateRequest())
    const response = await requestHttp.patch(`${urls.getEditClientUrl(userID)}`, payload)
    dispatch(paymentMethodsUpdateSuccess({
      userId: userID,
      ...payload
    }))
    return response
  } catch (error) {
    dispatch(paymentMethodsUpdatebanUserFailure(getResponseErrorMessage(error)))
    return error
  }
}


export const getPublishersList = (): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse> => {
  try {
    dispatch(publishersListRequest())
    const response = await requestHttp.get(urls.getPublishersListUrl() + "?offset=0&limit=200")
    const { content } = response.data.data
    dispatch(publishersListSuccess(content.results))
    return response
  } catch (error) {
    // @ts-ignore
    dispatch(publishersListFailure(getResponseErrorMessage(error)))
    // @ts-ignore
    return error
  }
}

export const activateClientAccount = (
  userId: number
): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse> => {
    try {
      dispatch(banUserRequest())
      const response = await requestHttp.patch(getActivateClientAccountUrl(userId))
      dispatch(banUserSuccess(userId))
      return response
    } catch (error) {
      dispatch(banUserFailure(getResponseErrorMessage(error)))
      return error
    }
  }
