import React, { ReactElement, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { BRIEF_STATUS, BRIEF_STEPS } from 'src/constants/brief'
import { useReduxDispatch } from 'src/helpers'
import { getBriefView, getCart } from '../../brief.selectors'
import * as actions from '../../brief.actions'

import BriefEditSummaryComponent from 'src/components/BriefView/BriefEditSummary.component'
import { IBillPaymentUploadTypes } from '../../../bills/bills.types'
import { useTranslation } from 'react-i18next'

interface IProps {
  briefId: number
  setCurrentStep: (step: BRIEF_STEPS) => void
  rejection?: boolean
  hideSubmitButton?: boolean
  onFilesChange?: (files: { files: File[]; filesType: IBillPaymentUploadTypes }[]) => void
}

export default function Payment({
  briefId,
  setCurrentStep,
  rejection,
  hideSubmitButton = false,
  onFilesChange,
}: IProps): ReactElement {
  const { t } = useTranslation(['translationABrief'])
  const dispatch = useReduxDispatch()
  const briefView = useSelector(getBriefView)
  const cart = useSelector(getCart)

  useEffect(() => {
    dispatch(actions.fetchViewBriefDetails(briefId))
    dispatch(actions.fetchCart(briefId))
  }, [briefId])

  const openPartner = async (id: number) => {
    setCurrentStep(BRIEF_STEPS.PARTNER)
    await dispatch(actions.fetchPartnerDetails(briefId, id))
    await dispatch(actions.setActiveSinglePartner(id))
  }

  return (
    <div className="qu-brief-content brief-step">
      <div className="brief-step-header">
        <h1>{briefView.status === BRIEF_STATUS.PENDING ? t('review_submit_before_payment') : t(`review_and_submit_payment`)}</h1>
        <p>{briefView.status === BRIEF_STATUS.PENDING ? t('review_description_before_payment') : t(`payment_help`)}</p>
      </div>
      <BriefEditSummaryComponent
        briefView={briefView}
        cart={cart}
        hideProposalButtons={true}
        setCurrentStep={setCurrentStep}
        openPartner={openPartner}
        rejection={rejection}
        onPaymentFilesChange={onFilesChange}
        hideSubmitPaymentButton={hideSubmitButton}
      />
    </div>
  )
}
