/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import React from 'react'
import { useSelector } from 'react-redux'
import { useReduxDispatch } from '../../../../../helpers'
import { actions } from '../../clientProfile.module'
import { Row, Col } from 'quantum_components'
import { PRODUCT_TYPE, PRODUCT_TYPE_VALUES as VALUES, PRODUCT_TYPE_INITIAL_VALUES } from 'src/constants/clientProfile'

import { MediaTypeCard } from '../components/MediaTypeCard'
import { getSettings, getFilledData } from '../../profile.selectors'
import { createObj } from '../../../../../helpers/createObjectWithOldFields'
import { useTranslation } from 'react-i18next'

export function ClientsProductBody() {
  const {t}= useTranslation(['translationClient','translationDashboard'])
  const dispatch = useReduxDispatch()
  const { productTypes } = useSelector(getSettings)
  const filledData = useSelector(getFilledData)

  const stepData = createObj(PRODUCT_TYPE_INITIAL_VALUES, filledData)

  const changeStepValue = (field, value) =>
    dispatch(
      actions.setStepData({
        ...stepData,
        [field]: value,
      })
    )

  const handleMediaType = (e: MouseEvent, name: string) => {
    const items = stepData[VALUES[PRODUCT_TYPE.PRODUCT_TYPES]]
    items.includes(name) ? items.splice(items.indexOf(name), 1) : items.push(name)

    changeStepValue(VALUES[PRODUCT_TYPE.PRODUCT_TYPES], items)
  }

  return (
    <div className={`form form-brief form--horizontal`}>
      <div className="form__head">
        <h3>{t(`clients.profile.product_type.header`)}</h3>
      </div>
      <div className="form__cont">
        <Row gutter={16}>
          <Col xs={24} sm={24} md={10}>
            <label htmlFor="productTypes" className="form-label mt-10">
              {t(`clients.profile.product_type.product_types`)}
            </label>
          </Col>
          <Col xs={24} sm={24} md={14} className="pb-10">
            <Row gutter={16} align="middle">
              {productTypes.map((name: string) => {
                const isChecked = stepData[VALUES[PRODUCT_TYPE.PRODUCT_TYPES]].includes(name)
                return <MediaTypeCard key={name} checked={isChecked} name={t(`${name}`,name,{ns:'translationDashboard'})} onChange={handleMediaType} />
              })}
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  )
}
