import { createSelector } from 'reselect'
import { MODULE_NAME } from './employees.constants'
import { IState, IEmployee, IEmployeeTable } from './employees.types'

const selectState = (state: { [MODULE_NAME]: any }) => state[MODULE_NAME]

export const getIsEmployeesListLoading = createSelector(
  selectState,
  (state: IState): boolean => state.employeesListLoading
)

export const getEmployeesList = createSelector(selectState, (state: IState): IEmployeeTable[] =>
  state.employeesList.results.map((employee: IEmployee) => {
    return {
      ...employee,
      key: employee.id,
      fullName: `${employee.firstName} ${employee.lastName}`,
      roleName: employee.employeeRole ? employee.employeeRole.name : '-',
    }
  })
)

export const getEmployeesTotal = createSelector(selectState, (state: IState): number => state.employeesList.total)

export const getPages = createSelector(
  selectState,
  (
    state: IState
  ): {
    totalPages: number
    currentPage: number
  } => state.pages
)

export const getEmployeesUpdating = createSelector(selectState, (state: IState): boolean => state.updatingEmployee)
