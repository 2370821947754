import { GUARD_META, PATHS } from '../../constants'
import getIsLoggedIn from '../../helpers/getIsLoggedIn'

const requireLogin = async (to: any, from: any, next: any) => {
  const isAuthenticated = await getIsLoggedIn()

  if (to.meta[GUARD_META.AUTH_ONLY] && !isAuthenticated) {
    next.redirect(PATHS.SIGN_IN)
  }
  if (!to.meta[GUARD_META.AUTH_ONLY] && isAuthenticated) {
    next.redirect(PATHS.ROOT)
  }

  next()
}

export default requireLogin
