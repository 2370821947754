import {
  ICampaign,
  ICampaignIMediaBuyTypeShort,
  ICampaignPartner,
  ICampaignTable,
  IChartData,
} from '../modules/campaign/campaign.types'
import moment from 'moment'
import { FORMATS } from '../constants'
import { MEDIA_GROUP_TYPE } from '../constants/brief'
import { ICity } from '../modules/brief/brief.types'
import { ISettingsSubType } from '../modules/core/core.types'
import { IMediaBuyTypesElement } from '../modules/partner/partner-Profile/profile.types'
import { CURRENCY } from '../constants/currencies'
import { renderRoundedNumber } from './currency'
import { IChartDataObjectNameKey } from '../modules/dashboard/dashboard.types'
import lodash from "lodash"

export const campaignsToTable = (campaigns: ICampaign[], currency: CURRENCY): ICampaignTable[] => {
  if (!campaigns) {
    return []
  }

  return campaigns.map((campaign: ICampaign) => {
    return {
      key: campaign.id,
      product: {
        title: campaign.productName,
        image: campaign.productImageUrls ? campaign.productImageUrls[0] : null,
        startDate: campaign.startedDate ? moment(campaign.startedDate).format(FORMATS.DATE_LL_FORMAT) : '-',
        isExistCouponCode: campaign.isExistCouponCode,
        discountText: campaign.discountText,
      },
      owner: {
        firstName: campaign.owner.firstName,
        lastName: campaign.owner.lastName,
        companyName: campaign.owner.companyName,
        email: campaign.owner.email,
        phone: campaign.owner.phone,
      },
      service: {
        types: campaign.types,
        productStartDate: campaign.productStartDate
          ? moment(campaign.productStartDate).format(FORMATS.DATE_LL_FORMAT)
          : '-',
        mediaStartDate: campaign.mediaStartDate ? moment(campaign.mediaStartDate).format(FORMATS.DATE_LL_FORMAT) : '-',
        mediaStartDateV2: lodash.get(campaign,'cart.additional.services',[]).length>0?moment(lodash.get(campaign,'cart.additional.services')[0].startDate).format(FORMATS.DATE_LL_FORMAT) : '-', 
        productDuration: campaign.productDuration,
        mediaDuration: campaign.productDuration,
        quantity: campaign.quantity,
        revenue: renderRoundedNumber(campaign.revenue, currency),
      },
      assignedUsers: campaign.assignedUsers,
      status: campaign.status,
      version: campaign.version
    } as ICampaignTable
  })
}

export const onlyDistributionPartners = (partners: ICampaignPartner[]) =>
  partners.filter(
    (p: ICampaignPartner) =>
      p.requestTypes.includes(MEDIA_GROUP_TYPE.PRODUCT_SAMPLE) ||
      p.requestTypes.includes(MEDIA_GROUP_TYPE.PRINTED_INSERT)
  )

export const onlyMediaBuyPartners = (partners: ICampaignPartner[]) =>
  partners.filter((p: ICampaignPartner) => p.requestTypes.includes(MEDIA_GROUP_TYPE.MEDIA_BUY))

export const getIntersectionCities = (briefCities: ICity[], partnerCities: ICity[]) =>
  briefCities.filter((city: ICity) => partnerCities.some((c: ICity) => city.id === c.id))

export const hasChartData = (data: IChartData[] | any) => {
  return !data.every((item: IChartData) => !item.value)
}

export const hasSomeChartData = (data: any[]) => {
  const condition = (item: any) => !!item.value || !!item.key || !!item.firstLine || !!item.secondLine
  return data.some(condition)
}

//'Growth Rate: ((After ad Revenue -Before ad Revenue )/Before ad Revenue )*100',
//'CTR (Click Through Rate):# of clicks/ # of impressions',
//'CR (Conversion Rate): # of orders/# of clicks',
//'Engagement Rate: SM Total interactions/SM Followers',

export const calculateGrowthRate = (beforeRevenue: number, afterRevenue: number): number => {
  if (!beforeRevenue || !afterRevenue) return 0
  const value = Math.round(((afterRevenue - beforeRevenue) / beforeRevenue) * 100)
  return value < 0 ? 0 : value
}

export const calculateRate = (a: number, b: number) => {
  if (!a || !b) return 0
  const value = Math.round((a / b) * 100)
  return value < 0 ? 0 : value
}

export const calculateRateDateRange = (a: any, b: any) => {
  if (!a || !b) return 0
  if (typeof a === 'object' && typeof b === 'object') {
    const valueA = Object.values(a).reduce((accumulator: any, value: any) => {
      return accumulator + value;
    }, 0)

    const valueB = Object.values(b).reduce((accumulator: any, value: any) => {
      return accumulator + value;
    }, 0)

    // Math.round
    const value = +Number((Number(valueA) / Number(valueB)) * 100).toFixed(1)
    return value < 0 ? 0 : value
  } else {
    const value = +Number((a / b) * 100).toFixed(1)
    return value < 0 ? 0 : value
  }
}

export const subtypeNames = (mediaSubTypes: ISettingsSubType[]) =>
  mediaSubTypes.map((subtype: ISettingsSubType) => (subtype.subNames ? subtype.subNames : subtype.name)).flat()

export const subtypeNamesByPartner = (mediaSubTypes: ICampaignIMediaBuyTypeShort[]) =>
  mediaSubTypes.map((subtype: ICampaignIMediaBuyTypeShort) => (subtype.subName ? subtype.subName : subtype.name))
