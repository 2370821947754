import { IState } from './disclaimer.types'
import * as CONSTANTS from './disclaimer.constants'

const initialState: IState = {
  disclaimer: null,
  disclaimerActionLoading: false,
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case CONSTANTS.RESET_DISCLAIMER:
      return initialState
    case CONSTANTS.FETCH_DISCLAIMER_REQUEST:
      return {
        ...state,
        disclaimerActionLoading: true,
      }
    case CONSTANTS.FETCH_DISCLAIMER_SUCCESS:
      return {
        ...state,
        disclaimer: action.payload,
        disclaimerActionLoading: false,
      }
    case CONSTANTS.FETCH_DISCLAIMER_FAILURE:
      return {
        ...state,
        disclaimerActionLoading: false,
      }
    default:
      return state
  }
}
