import get from 'lodash/get'
import { IState, ISegment } from './segments.types'
import * as CONSTANTS from './segments.constants'

const initialState: IState = {
  segmentsListLoading: true,
  segmentsList: [],
  createSegmentError: '',
  editSegmentError: '',
  deleteSegmentError: '',
  segmentsUpdating: false,
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case CONSTANTS.FETCH_SEGMENTS_REQUEST:
      return {
        ...state,
        segmentsListLoading: true,
      }
    case CONSTANTS.FETCH_SEGMENTS_SUCCESS:
      const segmentsList = action.payload
      return {
        ...state,
        segmentsList,
        segmentsListLoading: false,
      }
    case CONSTANTS.FETCH_SEGMENTS_FAILURE:
      return {
        ...state,
        segmentsListLoading: false,
      }
    case CONSTANTS.CREATE_SEGMENT_REQUEST:
      return {
        ...state,
        segmentsUpdating: true,
        createSegmentError: initialState.createSegmentError,
      }
    case CONSTANTS.CREATE_SEGMENT_SUCCESS:
      return {
        ...state,
        segmentsUpdating: false,
        segmentsList: [get(action, 'payload.segment'), ...state.segmentsList],
      }
    case CONSTANTS.CREATE_SEGMENT_FAILURE:
      return {
        ...state,
        segmentsUpdating: false,
        createSegmentError: action.error,
      }
    case CONSTANTS.EDIT_SEGMENT_REQUEST:
      return {
        ...state,
        segmentsUpdating: true,
        editSegmentError: initialState.editSegmentError,
      }
    case CONSTANTS.EDIT_SEGMENT_SUCCESS:
      const updatedSegments = state.segmentsList.map((segment: ISegment) => {
        return segment.id === get(action, 'payload.segment.id') ? get(action, 'payload.segment') : segment
      })
      return {
        ...state,
        segmentsUpdating: false,
        segmentsList: updatedSegments,
      }
    case CONSTANTS.EDIT_SEGMENT_FAILURE:
      return {
        ...state,
        segmentsUpdating: false,
        editSegmentError: action.error,
      }
    case CONSTANTS.DELETE_SEGMENT_REQUEST:
      return {
        ...state,
        segmentsUpdating: true,
        deleteSegmentError: initialState.deleteSegmentError,
      }
    case CONSTANTS.DELETE_SEGMENT_SUCCESS:
      const restSegments = state.segmentsList.filter((segment: ISegment) => {
        return segment.id !== get(action, 'payload.id')
      })
      return {
        ...state,
        segmentsUpdating: false,
        segmentsList: restSegments,
      }
    case CONSTANTS.DELETE_SEGMENT_FAILURE:
      return {
        ...state,
        segmentsUpdating: false,
        deleteSegmentError: action.error,
      }
    default:
      return state
  }
}
