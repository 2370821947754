import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { AxiosResponse } from 'axios'
import IAction, { TName } from 'src/interfaces/IAction'

import * as CONSTANTS from './adminSettings.constants'
import { requestHttp, urls } from 'src/api'
import { getResponseErrorMessage } from 'src/helpers'
import { ICopackingPrintingPayload, ITargetingToggles, IThemeData } from './adminSettings.types'
import { upload } from '../core/core.actions'

export const adminSettingsDataRequest = (): IAction => ({
  type: CONSTANTS.FETCH_ADMIN_SETTINGS_REQUEST,
})

export const adminSettingsDataSuccess = (data: any): IAction => ({
  type: CONSTANTS.FETCH_ADMIN_SETTINGS_SUCCESS,
  payload: data,
})

export const adminSettingsDataFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_ADMIN_SETTINGS_FAILURE,
  error,
})

export const fetchAdminSettings = (): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse> => {
  try {
    dispatch(adminSettingsDataRequest())
    const response = await requestHttp.get(urls.getAdminSettingsDatatUrl())
    const { content } = response.data.data
    dispatch(adminSettingsDataSuccess(content))
    return response
  } catch (error) {
    dispatch(adminSettingsDataFailure(getResponseErrorMessage(error)))
    return error
  }
}

export const editManagementFees = (fees: number): ThunkAction<void, {}, {}, AnyAction> => async (): Promise<
  AxiosResponse
> => {
  try {
    const response = await requestHttp.patch(urls.postManagementFeesUrl(), {
      fee: fees,
    })
    return response
  } catch (error) {
    return error
  }
}

export const editTargetingContols = (targetingToggles:ITargetingToggles[]): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse> => {
  try {
    const response = await requestHttp.patch(urls.patchTargetingControlsUrl(), {
      controls: targetingToggles,
    })
    return response
  } catch (error) {
    return error
  }
}

export const editThemeData = (themeData:IThemeData[]): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse> => {
  try {
    const response = await requestHttp.patch(urls.patchThemeDataUrl(), {
      controls: themeData,
    })
    dispatch(fetchAdminSettings())
    return response
  } catch (error) {
    return error
  }
}

export const updateWorkflow = (status:boolean): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse> => {
  try {
    const response = await requestHttp.patch(urls.updateWorkflowUrl(), {
      isEnabled: status,
    })
    dispatch(fetchAdminSettings())
    return response
  } catch (error) {
    return error
  }
}

export const uploadProductImage = (
  files: File[],
  name: string,
  id: string,
  controlObj: any
): ThunkAction<void, {}, {}, AnyAction> => async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
  try {
    // dispatch(imageUploadRequest())

    const uploadUrl = urls.getPublicProfileUploadUrl(id)

    const response = await upload(uploadUrl, files)

    if (response.status === 201) {
      const { content } = response.data.data
      // console.log(controlObj.id,controlObj.control_name,JSON.stringify(content))

      controlObj.control_value = content[0]

      await dispatch(editThemeData([controlObj]))

      return content

      // dispatch(imageUploadSuccess({ name, content }))
    }
  } catch (error) {
    // dispatch(imageUploadFailure(error))
    return error
  }
}

export const addAdminSettingRecord = (
  payload: ICopackingPrintingPayload
): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse> => {
  try {
    const response = await requestHttp.post(urls.postAdminSettingRecordUrl(), { ...payload })
    dispatch(fetchAdminSettings())
    return response
  } catch (error) {
    return error
  }
}

export const editAdminSettingRecord = (
  payload: ICopackingPrintingPayload,
  id: number
): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse> => {
  try {
    const response = await requestHttp.patch(urls.patchAdminSettingRecordUrl(id), { ...payload })
    dispatch(fetchAdminSettings())
    return response
  } catch (error) {
    return error
  }
}

export const deleteAdminSettingRecord = (id: number): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse> => {
  try {
    const response = await requestHttp.delete(urls.deleteAdminSettingRecordUrl(id))
    dispatch(fetchAdminSettings())
    return response
  } catch (error) {
    return error
  }
}
