import IStatusedMessage from 'src/interfaces/IStatusedMessage'
import {
  BRIEF_SORT_TYPE,
  BRIEF_STATUS,
  BRIEF_STEPS,
  BRIEF_TYPES,
  DIALOG_MODALS,
  DISTRIBUTION_TYPE,
  GENDER,
  MEDIA_TYPES,
  MEDIA_TYPES_DURATION,
} from '../../../constants/brief'
import moment from 'moment'
import { PARTNER_LABEL, SOCIAL_NAMES, SOCIAL_TYPES } from '../../../constants/partner'
import { CURRENCY } from '../../../constants/currencies'
import { TABS_NAMES } from '../../../constants/tabs'
import { REQUEST_STATUS } from '../../../constants/request'
import { IMediaBuyTypesElement } from '../../partner/partner-Profile/profile.types'
import { IRequest, IRequestTable } from '../../partner/requests/request.types'
import { IUserListElement } from '../../users/users.types'

export interface IState {
  createBriefError: string
  isSaving: boolean
  brief: IBrief
  currentStep: BRIEF_STEPS
  needCheckLastStep: boolean
  fetchingBriefListError: string

  briefListLoading: boolean
  briefList: IBriefList
  params: IBriefTableParams
  pages: {
    totalPages: number
    currentPage: number
  }

  fetchSettingsError: string
  settingsLoading: boolean
  settings: IBriefSettings
  viewBrief: IBriefResponse
  imagesUploading: boolean
  imagesUploadingError: string
  imagesRemoving: boolean
  imagesRemovingError: string
  partnerFilterSettingsError: string
  partnerFilterSettingsLoading: boolean
  partnerFilterSettings: IPartnerFilterSettings
  partnerFilter: IPartnerFilter
  serviceImages: string
  productImages: string
  fetchRecommendedPartnersLoading: boolean
  recommendedPartners: IBriefRecommendedPartner[]
  clientId: string | null
  fetchPartnerDetailsLoading: boolean
  partnerDetails: IBriefPartnerDetails | null
  fetchCartLoading: boolean
  updateCartLoading: boolean
  cart: ICartList
  activeSinglePartnerId: number | null
  totalQuantity: number
  uploadSettings: IUploadSettings[]
  uploadSettingsLoading: boolean
  sampleAllocatedModal: boolean
  uploadStepLoading: string[]
  activeTab: TABS_NAMES
  rejectionFlow: boolean
  dialogModal: DIALOG_MODALS | null
  requests: { results: IRequest[]; total: number }
  requestsParams: IBriefRequestTableParams
  downloadMediaLoading: boolean
  downloadMediaError: string
  sendProposalLoading: boolean
}

export interface IBriefResponse {
  id: number
  lastFilledStep: BRIEF_STEPS
  status: BRIEF_STATUS
  //BRIEF TYPE
  briefTypes: BRIEF_TYPES[]
  //PRODUCT BRIEF
  name: string
  quantity: number
  distributionItem: DISTRIBUTION_TYPE
  productType: string
  briefStartDate: string
  duration: string
  instructions?: string
  productImages: string[]

  //TARGETING
  targetFocus: GENDER[]
  targetAgeGroups: string[]
  targetSegments: string[]
  targetCountries: string[]
  targetCities: string[]
  targetCampaigns: string

  //SERVICES
  servicePackageType?: string[]
  serviceIsLiquid?: boolean
  serviceIncludingCoupon?: boolean
  serviceDesign?: boolean
  servicePrinting?: boolean
  servicePackaging?: boolean
  servicePackagingNeeds?: string
  servicePackagingQuantity?: number
  serviceImages: string[]
  hasFreeSample: boolean
  serviceTemperature: string

  //MEDIA
  mediaStartDate?: string
  mediaDuration?: string
  mediaTypes?: string[]

  uploadMaterial: IUploadPartner[]
  owner: IUserListElement
}

export interface IProductStep {
  name: string | null
  quantity: number | null
  distributionItem: DISTRIBUTION_TYPE | null
  productType: string | null
  briefStartDate: moment.Moment | string | null
  duration: string | null
  instructions: string | null
  productImages: string[]
  otherProductType: string | null
}

export const IProductInitState: IProductStep = {
  name: null,
  quantity: null,
  distributionItem: null,
  productType: null,
  briefStartDate: null,
  duration: null,
  instructions: null,
  productImages: [],
  otherProductType: null,
}

export interface ITargetingStep {
  targetFocus: GENDER[]
  targetAgeGroups: string[]
  targetSegments: string[]
  targetCountries: string[]
  targetCities: string[]
  targetCampaigns: string | null
}

export const ITargetingInitState: ITargetingStep = {
  targetFocus: [],
  targetAgeGroups: [],
  targetSegments: [],
  targetCountries: [],
  targetCities: [],
  targetCampaigns: null,
}

export interface IServicesStep {
  servicePackageType: string[]
  serviceIsLiquid: boolean
  serviceIncludingCoupon: boolean
  serviceDesign: boolean
  servicePrinting: boolean
  servicePackaging: boolean
  servicePackagingNeeds: string | null
  servicePackagingQuantity: number | null
  serviceImages: string[]
  hasFreeSample: boolean
  serviceTemperature: string | null
}

export const IServiceInitState: IServicesStep = {
  servicePackageType: [],
  serviceIsLiquid: false,
  serviceIncludingCoupon: false,
  serviceDesign: false,
  servicePrinting: false,
  servicePackaging: false,
  servicePackagingNeeds: null,
  servicePackagingQuantity: null,
  serviceImages: [],
  hasFreeSample: false,
  serviceTemperature: null,
}

export interface IMediaBuyStep {
  mediaStartDate: moment.Moment | string | null
  mediaDuration: string | null
  mediaTypes: string[]
}

export interface IPartnerStep {
  quantity: number
  hasFreeSample: boolean
}

export const IMediaBuyInitState: IMediaBuyStep = {
  mediaStartDate: null,
  mediaDuration: null,
  mediaTypes: [],
}

export interface IUploadPartner {
  partnerId: number
  mediaType: string
  mediaSubType: string | null
  imageTargetUrl: string | null
  link: string | null
  width: number
  height: number
}

export interface IUploadStep {
  uploadMaterial: IUploadPartner[]
}

export interface IBrief {
  id: number | null
  status: BRIEF_STATUS
  lastFilledStep: BRIEF_STEPS | null
  [BRIEF_STEPS.TYPE]: { briefTypes: BRIEF_TYPES[] }
  [BRIEF_STEPS.PRODUCT]: IProductStep
  [BRIEF_STEPS.TARGETING]: ITargetingStep
  [BRIEF_STEPS.SERVICES]: IServicesStep
  [BRIEF_STEPS.MEDIA_BUY]: IMediaBuyStep
  [BRIEF_STEPS.PARTNER]: IPartnerStep
  [BRIEF_STEPS.UPLOAD]: IUploadStep
  [BRIEF_STEPS.SUMMARY]: any
  owner: IUserListElement | null
}

export interface IBriefListResponse extends IStatusedMessage {
  data: {
    content: {
      briefs: IBriefListElementDto[]
      total: number
    }
  }
  message: string
}

export interface IBriefListElementDto {
  id: number
  name: string
  quantity: number
  briefStartDate: string
  createdAt?: string
  duration: string
  productImages: string[]
  mediaStartDate: string
  mediaDuration: string
  status: BRIEF_STATUS
  briefTypes: BRIEF_TYPES[]
  allImages?: IAllImagesElement[]
  campaign: IBriefCampaign
  owner: IBriefOwnerBase
  isExistCouponCode: boolean
  distributionItem: string
  exclusivePartner: { companyName: string }
  discountText: string | null
}
export interface IAllImagesElement {
  briefId: number
  createdAt: string
  entity: string
  id: number
  src: string
  updatedAt: string
}

export interface IBriefList {
  results: IBriefListElement[]
  total: number
}
export interface IBriefTableParams {
  userId: number
  offset: number
  limit: number
  direction?: 'asc' | 'desc'
  search?: string
  status?: string[]
  sortField?: string
}
export interface IBriefRequestTableParams {
  offset: number
  limit: number
  search?: string
  status?: string[]
}

export interface IBriefCampaign {
  id: number
}
export interface IBriefListElement {
  key: number
  thumb: string
  product: {
    title: string
    created: string
    isExistCouponCode: boolean
    discountText: string | null
  }
  services: serviceElement[]
  campaign: IBriefCampaign
  status: { value: BRIEF_STATUS; id: number }
}

export interface serviceElement {
  title: string
  productStartDate: string
  mediaStartDate: string
  productDuration: string
  mediaDuration: string
  quantity?: number
}

export interface ICity {
  id: number
  name: string
  name_ar: string
  country_id: number
}

export interface ICountry {
  id: number
  name: string
  name_ar: string
  city: ICity[]
}

export interface IBriefSettings {
  ageGroups: string[]
  distributionItems: string[]
  productTypes: string[]
  briefDurations: string[]
  targetSegments: string[]
  targetCampaigns: string[]
  packagingTypes: string[]
  mediaDurations: string[]
  mediaTypes: string[]
  countries: ICountry[]
  MINIMUM_DISTRIBUTION_LOGISTIC_COUNT: number
  temperature: string[]
}

export interface MediaSubtype {
  name: MEDIA_TYPES
  subtype: string
  availableFields: { lable: string; name: string }[]
  description: string
  subNames?: string[]
}

export interface IPartnerFilterSettings {
  categories: string[]
  pricePerUnit: string[]
  matches: string[]
  types: string[]
  sortBy: BRIEF_SORT_TYPE[]
  mediaSubtypes: MediaSubtype[]
}

export interface IPartnerFilter {
  categories: string[]
  pricePerUnit: string[]
  matches: string[]
  types: string[]
  sortBy: string
}

export interface ISocial {
  name: string
  counter: string
}

export interface IBriefRecommendedPartner {
  id: number
  userId: number
  mainImageUrl: string
  logoUrl: string
  name: string
  companyWebsite: string
  companyDescription: string
  companyDescriptionAr: string
  companyLabel: PARTNER_LABEL
  match: number
  isSampleDistribution: boolean
  pricePerSample: number
  isInsertDistribution: boolean
  pricePerInsert: number
  currency: CURRENCY
  social: ISocial[]
  links: any
  availableServices: IPartnerDetailsService[]
  other: boolean
}

export interface IPartnerDetailsService {
  serviceName: MEDIA_TYPES
  servicePrice: number | null
  serviceDuration: MEDIA_TYPES_DURATION
  serviceDescription: string
  serviceImpressions: number
  serviceImageUrl: string
  serviceClicks: number
  serviceSubName: string | null
  serviceMinOrderValue: string | null
  servicePriceForActivity: string | null
  servicePriceForDuration: string | null
  servicePriceForImpression: string | null
  servicePriceForPush: string | null
}

export interface IPartnerDetailsCountry {
  name: string
  cities: string[]
}
export interface IPartnerProps {
  stickerCost?: number
}

export interface LogisticRate {
  rate: number
  min: number
  max?: number
}

export interface IBriefPartnerDetails {
  id: number
  userId: number
  companyName: string
  companyWebsite: string
  companyShortDescription: string
  companyFullDescription: string
  companyLogoUrl: string
  companyBackgroundImageUrl: string
  match: number
  currency: CURRENCY
  sampleAllocated: number
  social: ISocial[]

  isSampleDistribution: boolean
  pricePerSample: number
  isInsertDistribution: boolean
  pricePerInsert: number
  availableServices: IPartnerDetailsService[]
  highlights: string[]
  metricsMarketing: string[]
  locations: IPartnerDetailsCountry[]

  logisticSampleRates: LogisticRate[]
  logisticInsertRates: LogisticRate[]

  partnerProperties: IPartnerProps
  metrics: {} //TODO
  matchDetails: string[]
}

export interface ICartServiceItem {
  name: string
  type: string
  amount: number
  currency: CURRENCY
  quantity: number
  total: number
  duration: MEDIA_TYPES_DURATION
  requestStatus?: REQUEST_STATUS
  rejectionReason?: string
}
export interface ICartItem {
  partnerId: number
  companyName: string
  companyLogoUrl: string
  companyWebsite: string
  logisticSampleRate: number
  logisticInsertRate: number
  logisticSamplePrice: number
  logisticInsertPrice: number
  stickerCost: number
  stickerPrice: number
  subTotal: number
  services: ICartServiceItem[]
  isApprovedByUser: boolean
}
export interface ICartList {
  briefId: number | null
  sampleAllocated?: number
  additional: {
    managementFee: number
    couponFee: number
    taxes: number
    cartTotal: number
    items: ICartItem[]
  }
  couponCode?: string
}

export interface IUploadSettings extends ICartItem {
  mediaTypes: IMediaBuyTypesElement[]
}

export interface IBriefOwnerBase {
  id: number
  companyName: string
  firstName: string
  lastName: string
}

export interface IDeleteBriefResponse extends IStatusedMessage {
  message: string
}
