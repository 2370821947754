import { Col, Divider, Row, Tag } from "antd";
import { AgeGroupCard } from "src/modules/brief/containers/components/Brief.AgeGroupCard";
import lodash from "lodash";
import React, { useEffect, useState, createRef, useRef } from "react";
import SpinnerCenter from "src/components/Spinner/spinner";
import { Modal, QButton, QInput, qnotification, QSelect, QTable } from "quantum_components";
import { useTranslation } from "react-i18next";
import { ProductContainer } from "src/modules/products";
import i18n from "src/i18n";

const V3ProductComponent = ({ briefSettings, state, setState, arrayIndex, canEdit, isLoading, setIsLoading, hasServerIntegration = false }: any) => {
  const { t } = useTranslation(['translationV2', "translationProduct"])
  const [showModal, setShowModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});

  useEffect(() => {
    if (lodash.get(briefSettings, "partnerProfileId") <= 0) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [briefSettings]);

  const columns = [
    {
      title: t("Product"),
      width: "70%",
      align: i18n.dir() === "ltr" ? "left" : "right",
      fixed: "left",
      render: (element: any, row: any) => {
        const { name, description, imageLink } = element;
        return (
          <Row gutter={24} align="middle">
            <Col md={5}>
              <img
                src={
                  imageLink
                    ? imageLink
                    : "https://placehold.co/250x200?text=No%20Image%20Yet"
                }
                className="h-50 full-width table-preview-img"
              />
            </Col>
            <Col md={19}>
              <div className="text-capitalize text-bold">{name}</div>
              <div className="text-capitalize">{description ? description.substring(0, 40) + "..." : "-"}</div>
            </Col>
          </Row>
        );
      },
    },
    {
      title: t("Price (SAR)"),
      dataIndex: "price",
      key: "price",
      width: "30%",
      align: i18n.dir() === "ltr" ? "left" : "right",
      render: (element: any, row: any) => {
        return <span>SAR {element}</span>
      }
    }
  ]

  const calculateCustomPricingForCart = async (data: any) => {
    const availableService = briefSettings?.availableServices?.find((service: any) => service.id === data[arrayIndex].id)
    if (availableService && availableService.categoryPrices && !!availableService.categoryPrices.length) {
      const categories = data[arrayIndex]?.activeProduct?.category
      const subcategories = data[arrayIndex]?.activeProduct?.subcategory
      await matchCategoriesAndSubcategories(data, availableService, availableService.categoryPrices, categories, subcategories)
    } else {
      setState(data)
    }
  }

  const matchCategoriesAndSubcategories = async (data: any, availableService: any, categoryPrices: any, categories: any, subcategories: any) => {
    let found = false; // Flag to indicate if a match is found
    const newData = data;
    for (const category of categories) {
      const categoryEntry = categoryPrices.find((entry: any) => entry.categoryName === category);

      if (categoryEntry) {
        subcategories.forEach((subcategory: any) => {
          if (categoryEntry.subCategory.includes(subcategory)) {
            if (newData[arrayIndex].durationType === 'Per Metric') {
              newData[arrayIndex].pricePerMetric.cpc = categoryEntry?.pricePerMetric?.CPC
              newData[arrayIndex].pricePerMetric.cpm = categoryEntry?.pricePerMetric?.CPM
              found = true; // Set the flag to true
              setState(newData)
              return;
            } else {
              newData[arrayIndex].basePrice = Number(categoryEntry?.pricePerExecution)
              newData[arrayIndex].total = 1 * Number(categoryEntry?.pricePerExecution)
              found = true; // Set the flag to true
              setState(newData)
              return;
            }
          }
        });
      }
    };

    if (availableService && !found) {
      newData[arrayIndex].pricePerMetric.cpc = availableService.servicePricePerMetric?.cpc ?? 0
      newData[arrayIndex].pricePerMetric.cpm = availableService.servicePricePerMetric?.cpm ?? 0
      newData[arrayIndex].basePrice = availableService.serviceExecutionType !== "Per Metric" ? Number(availableService.servicePricePerExecution) : 0
      newData[arrayIndex].total = availableService.serviceExecutionType !== "Per Metric" ? 1 * Number(availableService.servicePricePerExecution) : 0
      setState(newData)
    }
  };

  return (
    <>
      {/* {JSON.stringify(localActiveTemplate)} */}
      {hasServerIntegration && <div>
        {lodash.isEmpty(state[arrayIndex].activeProduct) &&
          <span className="h6">
            {t("Select a Product to Promote")}
          </span>
        }
        {" "}
        {!lodash.isEmpty(state[arrayIndex].activeProduct) && canEdit && <a onClick={() => setShowModal(true)}>{t("Edit.", { ns: "translationProduct" })}</a>}
      </div>}

      {lodash.isEmpty(state[arrayIndex].activeProduct) && (
        <div>
          {t("No product selected")}{" "}
          <a onClick={() => canEdit && setShowModal(true)}>
            {t("Select Now.")}
          </a>
        </div>
      )}

      {hasServerIntegration && !lodash.isEmpty(state[arrayIndex].activeProduct) && <QTable
        className="campaign-products-list"
        columns={columns}
        dataSource={[state[arrayIndex].activeProduct]}
        loading={false}
        scroll={{ x: "auto" }}
      />}

      {!hasServerIntegration && <div>
        {!lodash.isEmpty(state[arrayIndex].activeProduct) && lodash.has(state[arrayIndex].activeProduct, 'name') && (
          <>
            <Tag className="tag-preview" onClick={() => canEdit && setShowModal(true)}>{lodash.get(state[arrayIndex].activeProduct, 'name', 'N/A')}</Tag>
          </>
        )}
      </div>}

      <Modal
        wrapClassName="qu-modal"
        visible={showModal}
        footer={false}
        closable={true}
        width={675}
        onCancel={(e) => {
          e.stopPropagation();
          setShowModal(false);
        }}
      >
        <div className="v3-section">
          <ProductContainer placement={"campaignCreation"} setState={(data: any) => calculateCustomPricingForCart(data)} state={state} arrayIndex={arrayIndex} setShowModal={setShowModal}></ProductContainer>
        </div>
      </Modal>
    </>
  );
};

export default React.memo(V3ProductComponent);
