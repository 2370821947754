import { t } from "i18next";
import {
  Col,
  Dropdown,
  Form,
  IconsCommon,
  Menu,
  Modal,
  QButton,
  QInput,
  QMenuItem,
  qnotification,
  Row,
  Table,
} from "quantum_components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SpinnerCenter from "src/components/Spinner/spinner";
import { PERMISSIONS } from "src/constants/permission";
import { hasPermission } from "src/helpers/permission";
import i18n from "src/i18n";
import { getCurrentUserPermissionActions } from "src/modules/core/core.selectors";
import {
  createTargetingKey,
  deleteTargetingKey,
  editTargetingKey,
  getAllTargetingKeys,
} from "../targetingKeys.actions";
import { getDataLoading, targetingKeysList } from "../targetingKeys.selectors";
import { ReactComponent as CloseIcon } from "src/assets/icons/close-icon.svg";
import { NOTIFICATION_TYPES } from "src/constants";
import { useTranslation } from "react-i18next";

import moment from "moment";
import { getLocaleCost } from 'src/helpers/currency'

const trimString = (text: string) => {
  const length = 30;
  if (text.length < length) return text;
  return text.substring(0, length) + "...";
};

const TargetingKeys = () => {
  const { t } = useTranslation(["translationTargetingKeys"]);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [initialValues, setInitialValues] = useState({
    name: "",
    options: [],
  });
  const targetingList = useSelector(targetingKeysList);
  const isLoading = useSelector(getDataLoading);
  const permissions = useSelector(getCurrentUserPermissionActions);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedId, setSelectedId] = useState(-1);
  const [showModal, setShowModal] = useState(false);
  const [commaSeparatedField, setCommaSeparatedField] = useState("");

  const handleMenuAction = (action: string, record: any) => {
    switch (action) {
      case "EDIT":
        setIsEditMode(true);
        setSelectedId(record.id);
        setShowModal(true);
        form.setFieldsValue({ name: record.name, options: record.options });
        break;
      case "DELETE":
        deleteTargetingKeyById(record.id);
        break;
      default:
        break;
    }
  };

  const menu = (record: any) => (
    <Menu
      className={`user-menu-list ${i18n.dir() === "rtl" ? "html-rtl" : ""}`}
    >
      {hasPermission(permissions, PERMISSIONS.EDIT_TARGETING_KEYS) && (
        <QMenuItem
          key={1}
          onClick={() => handleMenuAction("EDIT", record)}
          name={t(`Edit`)}
          icon={<IconsCommon.IconEdit />}
        />
      )}
      {hasPermission(permissions, PERMISSIONS.DELETE_TARGETING_KEYS) && (
        <QMenuItem
          key={2}
          onClick={() => handleMenuAction("DELETE", record)}
          name={t(`Delete`)}
          icon={<IconsCommon.IconDelete />}
        />
      )}
    </Menu>
  );

  const columns = [
    {
      title: t("Name"),
      dataIndex: "name",
      key: "name",
      width: "50%",
      fixed: "left",
    },
    {
      title: t("Values"),
      dataIndex: "options",
      key: "options",
      width: "35%",
      fixed: "left",
      render: (ops: [string], record: any) => {
        // return trimString(ops.join(", "));
        return ops.join(", ");
      },
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: "15%",
      fixed: "right",
      render: (ops: [string], record: any) => {
        return (
          <div onClick={(e) => e.stopPropagation()}>
            <Dropdown
              overlay={menu(record)}
              trigger={["click"]}
              placement="bottomRight"
            >
              <div className={`q-table_menu`}>
                <IconsCommon.IconEllipsisV />
              </div>
            </Dropdown>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(getAllTargetingKeys());
  }, []);

  const resetForm = () => {
    form.resetFields(["name", "options"]);
    setIsEditMode(false);
    setSelectedId(-1);
    setShowModal(false);
    setCommaSeparatedField("");
  };

  const deleteTargetingKeyById = async (targetingKeyId: number) => {
    await dispatch(deleteTargetingKey(targetingKeyId));
    await dispatch(getAllTargetingKeys());
  };

  const handleOnFinish = async (data: any) => {
    const filledOptions = data.options.filter((op: string) => op.trim());
    const name = data.name.trim();

    if(!name){
      form.setFieldsValue({name:''});
      form.validateFields(['name'])
      return
    }

    if (filledOptions.length < 1) {
      qnotification({
        type: NOTIFICATION_TYPES.ERROR,
        message: t("Atleast 1 Valid option is required"),
      });
      return;
    }

    const updatedData = {...data};
    updatedData.options = Array.from(new Set(updatedData.options))

    if (isEditMode) {
      await dispatch(editTargetingKey(selectedId, updatedData));
    } else {
      await dispatch(createTargetingKey(updatedData));
    }
    await dispatch(getAllTargetingKeys());
    resetForm();
  };

  const addCommaSeparatedOptions = () => {
    const opsArray = commaSeparatedField.split(",").map((op:string)=>op.trim())
    if (opsArray.length > 0){
      form.setFieldsValue({options:opsArray});
    }
    setCommaSeparatedField("");
  }


  return (
    <>
      <div className={`flexRowContainer ${i18n.dir()==='rtl'?'html-rtl':''}`}>
        <div>
          <h2>{t('Targeting Keys')}</h2>
          <p className="p--xs">
            {t('You can specify different segments that can be used to target your audience')}
          </p>
        </div>

        <QButton
          type="dashed"
          htmlType="button"
          className="qu-button-soft"
          onClick={() => setShowModal(true)}
          disabled={isLoading}
        >
          {t(`Add New`)}
        </QButton>

      </div>

      <section>
        <Table
          className="mt-20"
          dataSource={targetingList}
          pagination={false}
          // @ts-ignore
          columns={columns}
          loading={isLoading}
        />
      </section>

      <Modal
        wrapClassName={`qu-modal ${i18n.dir() === 'rtl'?'html-rtl':''}`}
        width={700}
        title={<h4>{isEditMode ? t("Edit") : t("Add New")}</h4>}
        centered={true}
        visible={showModal}
        closable={true}
        onCancel={() => resetForm()}
        footer={null}
      >
        <section className="mb-20">
          <Form
            className="mt-10"
            layout="vertical"
            initialValues={initialValues}
            form={form}
            onFinish={handleOnFinish}
            validateMessages={{
              required: t("Required field"),
            }}
            autoComplete={"off"}
          >
            <Row gutter={24} justify={"start"}>
              <Col xs={24} sm={24} md={24}>
                <Form.Item
                  label={t("Name")}
                  name="name"
                  rules={[{ required: true, message: t("Required Field") }]}
                  validateTrigger={["onChange", "onBlur"]}
                >
                  <QInput placeholder={t("Enter here")} size="large" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24} justify={"start"}>
              <Col xs={24} sm={24} md={24}>
                <div><label>{t("Values")}</label></div>
                
                <Form.List name="options">
                  {(fields, { add, remove }) => {
                    return (
                      <div key={'a'}>
                        <div
                          className="flexRowContainer mb-10"
                          style={{ gap: "10px" }}
                        >
                          <QButton
                            className="qu-button-outline mb-5 mt-5"
                            type="primary"
                            size="small"
                            htmlType="button"
                            onClick={() => add()}
                          >
                            {t("Add option")}
                          </QButton>
                          <div>{t("or")}</div>
                          <QInput
                            placeholder={t("Enter comma separated values here")}
                            size="medium"
                            type="text"
                            value={commaSeparatedField}
                            onChange={(e:any)=>setCommaSeparatedField(e.currentTarget.value)}
                          />
                          <QButton
                            size="small"
                            type="primary"
                            htmlType="button"
                            className="qu-button-outline mb-5 mt-5"
                            onClick={()=>addCommaSeparatedOptions()}
                            disabled={commaSeparatedField.length < 1}
                          >
                            {t("Import")}
                          </QButton>
                        </div>

                        <div
                          key={'b'}
                          className="PPField__inputList"
                        >
                          {fields.map((field) => (
                            <div
                              key={field.key}
                              className="input"
                              style={{ position: "relative" }}
                            >
                              <Form.Item {...field} className="mb-0">
                                <QInput
                                  className="form-textarea input-with-close-icon targeting-key-input"
                                  placeholder={t("Enter here")}
                                />
                              </Form.Item>
                              {
                                <CloseIcon
                                  key={field.key}
                                  style={{
                                    width: "30px",
                                    position: "absolute",
                                    top: "5px",
                                    right: "0px",
                                  }}
                                  onClick={() => remove(field.name)}
                                />
                              }
                            </div>
                          ))}
                        </div>
                      </div>
                    );
                  }}
                </Form.List>
              </Col>
            </Row>
            <Row gutter={24} justify={"start"} className="mt-10">
              <Col xs={24} sm={24} md={24}>
                <QButton
                  className="text-capitalize"
                  type="primary"
                  htmlType="submit"
                  disabled={isLoading}
                >
                  {isEditMode ? t("Update") : t("Save")}
                </QButton>
              </Col>
            </Row>
          </Form>
        </section>
      </Modal>
    </>
  );
};
export default TargetingKeys;
