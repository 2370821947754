import React, {
  forwardRef,
  ReactElement,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import isEqual from "lodash/isEqual";
import {
  IAdEstimation,
  IAvailableServiceItem,
  ICartItem,
  ICartList,
  ICartServiceItem,
} from "../../brief.types";
import DetailsCompanyInfo from "src/components/PartnerDetailsComponents/Details.CompanyInfo";
import DetailsServicesTable from "src/components/PartnerDetailsComponents/Details.ServicesTable";
import DetailsAbout from "src/components/PartnerDetailsComponents/Details.About";
import { useSelector } from "react-redux";
import {
  getActiveSinglePartnerId,
  getBrief,
  getBriefSettings,
  getCart,
  getIsRejectionFlow,
  getLogisticInsertRates,
  getLogisticSampleRates,
  getPartnerDetails,
  getPartnerDetailsLoading,
  getSampleAllocated,
  getStickerCost,
  getTotalQuantity,
} from "../../brief.selectors";
import SpinnerCenter from "../../../../components/Spinner/spinner";
import DialogModal from "../../../../components/DialogModal/DialogModal";
import SelectedServicesForm from "../../../../components/PartnerDetailsComponents/Details.SelectedServicesForm";
import { useReduxDispatch } from "src/helpers";
import { actions } from "../../brief.module";
import {
  BRIEF_STEPS,
  BRIEF_TYPES,
  DIALOG_MODALS,
  DISTRIBUTION_TYPE,
  KEEP_MODAL,
  PARTNER_STEP_BUTTONS,
} from "src/constants/brief";
import { Col, Form, QInput, Radio, Row } from "quantum_components";
import { isMobile } from "react-device-detect";
import ServicesMobileForm from "../../../../components/PartnerDetailsComponents/Details.ServicesMobileForm";

import defaultImage from "src/assets/profile-default.svg";
import { getLogisticRate } from "src/helpers/logistic";
import {
  blockService,
  calcDistributionQuantity,
  calcLogisticPrice,
  calcStickerPrice,
  calcSubTotal,
  calculation,
  getRejectedServices,
  hasDistributionItems,
  hasMediaBuyItems,
} from "src/helpers/cart";
import { REQUEST_STATUS } from "../../../../constants/request";
import { onlyDistribution } from "../../../../helpers/brief";
import get from "lodash/get";
import { useTranslation } from "react-i18next";

interface IProps {
  briefId: number;
  partnerServices: ICartServiceItem[];
  onClose: () => void;
  updateBrief: () => Promise<void>;
  saveMediaBuy?: () => Promise<void>;
}

const BriefPartnerSinglePageForm = forwardRef(
  (
    { briefId, partnerServices, onClose, updateBrief, saveMediaBuy }: IProps,
    ref
  ): ReactElement => {
    const { t } = useTranslation(["translationABrief", "translationDashboard"]);
    const dispatch = useReduxDispatch();
    const details = useSelector(getPartnerDetails);
    const partnerId = useSelector(getActiveSinglePartnerId);
    const fetchingPartnerDetails = useSelector(getPartnerDetailsLoading);
    const cart = useSelector(getCart);
    const totalQuantity = useSelector(getTotalQuantity);
    const sampleAllocated = useSelector(getSampleAllocated);
    const brief = useSelector(getBrief);
    const logisticSampleRates = useSelector(getLogisticSampleRates);
    const logisticInsertRates = useSelector(getLogisticInsertRates);
    const stickerCost = useSelector(getStickerCost);
    const isRejectionFlow = useSelector(getIsRejectionFlow);
    const settings = useSelector(getBriefSettings);
    const MINIMUM_DISTRIBUTION_LOGISTIC_COUNT =
      settings.MINIMUM_DISTRIBUTION_LOGISTIC_COUNT;

    const [selectedServices, setSelectedServices] = useState<
      IAvailableServiceItem[]
    >([]);
    const [editMode, setEditMode] = useState<boolean>(true);
    const [isServices, setIsServices] = useState<boolean>(true);
    const [logisticSampleRate, setLogisticSampleRate] = useState<number>(0);
    const [logisticInsertRate, setLogisticInsertRate] = useState<number>(0);
    const [dialogModal, setDialogModal] = useState<boolean>(false);
    const [defaultCart, setDefaultCart] = useState<ICartList | null>(cart);
    const isCartChanged = !isEqual(defaultCart, cart);
    const [adEstimation, setAdEstimation] = useState<IAdEstimation>();

    const [searchServiceText, setSearchServiceText] = useState("");
    const [filteredDetails, setFilteredDetails] = useState<any>({});

    const [form] = Form.useForm();

    useEffect(() => {
      if (!details) return;
      const withoutSKUServices = details?.availableServices?.filter((s:any)=>s.serviceName !== "SKU Uplifting");
      setFilteredDetails({ ...details, availableServices: withoutSKUServices });
    }, [details]);

    useImperativeHandle(ref, () => ({
      updateCart(skipRejection?: boolean) {
        if ((editMode && isCartChanged) || (editMode && skipRejection)) {
          return handleSubmitForm(!!skipRejection);
        }
      },
    }));

    useEffect(() => {
      setDefaultCart(cart);
    }, []);

    useEffect(() => {
      const canEdit = partnerServices.length === 0;
      // setEditMode(canEdit)

      const services = partnerServices.map<IAvailableServiceItem>(
        (service: ICartServiceItem) => ({
          key: service.name,
          product: service.name,
          type: service.type,
          pricing: {
            amount: service.amount,
            currency: service.currency,
            quantity: service.quantity,
            total: service.total,
            duration: service.duration,
          },
          blocked: blockService(service),
          requestStatus: service.requestStatus,
          productSubType: service.productSubType,
        })
      );
      setSelectedServices(services);
    }, [partnerId]);

    useEffect(() => {
      setLogisticRates();
    }, [selectedServices, logisticSampleRates, logisticInsertRates]);

    const productSample = selectedServices.find(
      (s: IAvailableServiceItem) =>
        s.product === DISTRIBUTION_TYPE.PRODUCT_SAMPLE
    );
    const printedInsert = selectedServices.find(
      (s: IAvailableServiceItem) =>
        s.product === DISTRIBUTION_TYPE.PRINTED_INSERT
    );

    const sampleQuantity = productSample
      ? productSample.pricing.quantity || 0
      : 0;
    const insertQuantity = printedInsert
      ? printedInsert.pricing.quantity || 0
      : 0;

    const isOnlyDistribution = onlyDistribution(
      brief[BRIEF_STEPS.TYPE].briefTypes
    );

    const logisticSamplePrice = calcLogisticPrice(
      logisticSampleRate,
      sampleQuantity,
      MINIMUM_DISTRIBUTION_LOGISTIC_COUNT
    );
    const logisticInsertPrice = calcLogisticPrice(
      logisticInsertRate,
      insertQuantity,
      MINIMUM_DISTRIBUTION_LOGISTIC_COUNT
    );
    const stickerPrice = calcStickerPrice(stickerCost, sampleQuantity);

    const setLogisticRates = () => {
      if (!logisticSampleRates || !logisticInsertRates) {
        return;
      }

      if (productSample) {
        const sampleQuantity = productSample.pricing.quantity || 0;
        const logisticSampleRate = getLogisticRate(
          sampleQuantity,
          logisticSampleRates
        );

        setLogisticSampleRate(logisticSampleRate);
      }

      if (printedInsert) {
        const insertQuantity = printedInsert.pricing.quantity || 0;
        const logisticInsertRate = getLogisticRate(
          insertQuantity,
          logisticInsertRates
        );

        setLogisticInsertRate(logisticInsertRate);
      }
    };

    const calcServicesTotal = () => {
      if (!selectedServices.length) {
        return 0;
      }

      return selectedServices.reduce(
        (acc: number, item: IAvailableServiceItem) => {
          const total = item.pricing.total ? item.pricing.total : 0;
          return acc + total;
        },
        0
      );
    };

    const servicesTotal = calcServicesTotal();
    const subTotal = calcSubTotal(
      servicesTotal,
      logisticSamplePrice,
      logisticInsertPrice,
      stickerPrice
    );

    const prepareServices = () =>
      selectedServices.map((service: IAvailableServiceItem) => {
        return {
          name: service.product,
          type: service.type,
          amount: service.pricing.amount,
          currency: service.pricing.currency,
          quantity: service.pricing.quantity,
          total: service.pricing.total,
          duration: service.pricing.duration,
          requestStatus: service.requestStatus,
          productSubType: service.productSubType,
        } as ICartServiceItem;
      });

    const newCartItem = () => {
      if (!selectedServices.length) {
        return null;
      }

      return {
        partnerId: details!.id,
        companyName: details!.companyName,
        companyLogoUrl: details!.companyLogoUrl,
        companyWebsite: details!.companyWebsite,
        services: prepareServices(),
        subTotal: subTotal,
        logisticSampleRate,
        logisticInsertRate,
        logisticSamplePrice,
        logisticInsertPrice,
        stickerCost,
        stickerPrice,
        isApprovedByUser: true,
        priceModel: details?.mbPricingModel,
      } as ICartItem;
    };

    let timeout: any = {};
    const handleOnChange = async (selected: IAvailableServiceItem[]) => {
      timeout && clearTimeout(timeout);
      timeout = setTimeout(async () => {
        let selectedServicesArr = selectedServices.map((ele) => ele.product);
        let difference = selected.filter(
          (x) => !selectedServicesArr.includes(x.product)
        );

        setSelectedServices(selected);

        difference && (await updateBrief());
      }, 500);
    };

    const handleChangeRadio = (e: any) => {
      const { value } = e.target;
      setIsServices(value);
    };

    const handleEditMode = () => {
      // setEditMode(true)
    };

    const checkSelectedItems = (hasMediaBuy: boolean, hasDist: boolean) => {
      if (
        brief[BRIEF_STEPS.TYPE].briefTypes.includes(BRIEF_TYPES.MEDIA_BUYING) &&
        !hasMediaBuy
      ) {
        dispatch(actions.setDialogModal(DIALOG_MODALS.MEDIA_BUY));
        return true;
      }

      if (
        brief[BRIEF_STEPS.TYPE].briefTypes.includes(
          BRIEF_TYPES.PRODUCT_SAMPLE
        ) &&
        !hasDist
      ) {
        dispatch(actions.setDialogModal(DIALOG_MODALS.DISTRIBUTION));
        return true;
      }

      return false;
    };

    const handleNextStep = async () => {
      if (editMode) {
        const cartItems = await handleAddToCard();
        const distQuantity = calcDistributionQuantity(cartItems);
        await dispatch(actions.setTotalQuantity(distQuantity));

        const hasMediaBuy = hasMediaBuyItems(cartItems);
        const hasDist = hasDistributionItems(cartItems);
        const res = checkSelectedItems(hasMediaBuy, hasDist);
        if (res) return;

        let totalBudget = 0;
        cart.additional.items.map((ele) => {
          ele.services.map((ele1: any) => {
            if (
              ele1.name !== DISTRIBUTION_TYPE.PRODUCT_SAMPLE &&
              ele1.name !== DISTRIBUTION_TYPE.PRINTED_INSERT
            ) {
              totalBudget = totalBudget + ele1.total;
            }
          });
        });

        if (
          sampleAllocated !== totalQuantity &&
          brief?.BRIEF_MEDIA_BUY?.budgetValue &&
          totalBudget !== brief?.BRIEF_MEDIA_BUY?.budgetValue
        ) {
          await dispatch(
            actions.setDialogModal(
              DIALOG_MODALS.SAMPLE_BUDGET_ALLOCATED_MISMATCH
            )
          );
          return;
        }

        if (
          brief?.BRIEF_MEDIA_BUY?.budgetValue &&
          totalBudget !== brief?.BRIEF_MEDIA_BUY?.budgetValue
        ) {
          // await dispatch(actions.setDialogModal(DIALOG_MODALS.BUDGET_ALLOCATED_MISMATCH))
          // return
          onProceedModal();
        }

        if (sampleAllocated !== distQuantity) {
          dispatch(
            actions.setDialogModal(DIALOG_MODALS.SAMPLE_ALLOCATED_MISMATCH)
          );
          return;
        }
      } else {
        const hasMediaBuy = hasMediaBuyItems(cart.additional.items);
        const hasDist = hasDistributionItems(cart.additional.items);
        const res = checkSelectedItems(hasMediaBuy, hasDist);
        if (res) return;

        let totalBudget = 0;
        cart.additional.items.map((ele) => {
          ele.services.map((ele1: any) => {
            if (
              ele1.name !== DISTRIBUTION_TYPE.PRODUCT_SAMPLE &&
              ele1.name !== DISTRIBUTION_TYPE.PRINTED_INSERT
            ) {
              totalBudget = totalBudget + ele1.total;
            }
          });
        });

        if (
          sampleAllocated !== totalQuantity &&
          brief?.BRIEF_MEDIA_BUY?.budgetValue &&
          totalBudget !== brief?.BRIEF_MEDIA_BUY?.budgetValue
        ) {
          await dispatch(
            actions.setDialogModal(
              DIALOG_MODALS.SAMPLE_BUDGET_ALLOCATED_MISMATCH
            )
          );
          return;
        }

        if (
          brief?.BRIEF_MEDIA_BUY?.budgetValue &&
          totalBudget !== brief?.BRIEF_MEDIA_BUY?.budgetValue
        ) {
          await dispatch(
            actions.setDialogModal(DIALOG_MODALS.BUDGET_ALLOCATED_MISMATCH)
          );
          return;
        }

        if (sampleAllocated !== totalQuantity) {
          dispatch(
            actions.setDialogModal(DIALOG_MODALS.SAMPLE_ALLOCATED_MISMATCH)
          );
          return;
        }
      }

      await updateBrief();
      dispatch(actions.setCurrentStep(BRIEF_STEPS.UPLOAD));
    };

    const handleAddToCard = async () => {
      const cartItem = newCartItem();
      const cartItems = [...cart.additional.items];

      const meC = brief.remoteBriefId ?  cartItems.length > 0 ? cart.additional.miscellaneousRate : details?.meC : 0

      const targetItem = cartItems.find(
        (item: ICartItem) => item.partnerId === details?.id
      );

      if (targetItem) {
        cartItems.splice(cartItems.indexOf(targetItem), 1);
      }

      if (cartItem) {
        cartItems.push(cartItem);
      }

      const managementFeeRate =
        get(cart, "additional.managementFeeRate") || settings.managementFee;

      const result = calculation(
        cartItems,
        managementFeeRate,
        settings.partnerVat,
        meC
      );

      const estimationsData: IAdEstimation[] = cart.mediaBuyEstimates || [];
      if (
        adEstimation?.campaignDays ||
        adEstimation?.clicks ||
        adEstimation?.impressions ||
        adEstimation?.reach
      ) {
        const existingPartnerAdEstimateIndex = estimationsData.findIndex(
          (ele) => ele.partnerId === adEstimation.partnerId
        );
        if (existingPartnerAdEstimateIndex > -1) {
          estimationsData.splice(existingPartnerAdEstimateIndex, 1);
        }
        estimationsData.push(adEstimation);
      }

      const cartData = {
        briefId,
        additional: {
          managementFee: result.managementFee,
          managementFeeRate,
          miscellaneous: result.miscellaneous,
          miscellaneousRate: meC,
          taxes: result.taxes,
          cartTotal: result.total,
          items: cartItems,
        },
        mediaBuyEstimates: estimationsData,
      } as ICartList;

      await dispatch(actions.addToCart(cartData));
      await dispatch(actions.fetchCart(briefId));
      // setEditMode(false)
      return cartItems;
    };

    const handleSubmitForm = (skipRejection: boolean, button?: string) => {
      return form
        .validateFields()
        .then(() => {
          if (button === t(PARTNER_STEP_BUTTONS.BACK)) {
            onClose();
            return;
          }

          if (button === t(PARTNER_STEP_BUTTONS.NEXT)) {
            const rejection = getRejectedServices(cart.additional.items);
            if (isRejectionFlow && rejection.length > 0) {
              dispatch(actions.setDialogModal(DIALOG_MODALS.BLOCK_NEXT));
              return;
            }
            handleNextStep();
            return;
          }

          if (!skipRejection) {
            const rejection = selectedServices.some(
              (s: IAvailableServiceItem) =>
                s.requestStatus === REQUEST_STATUS.REJECTED
            );
            if (isRejectionFlow && rejection) {
              dispatch(
                actions.setDialogModal(DIALOG_MODALS.HAS_REJECTED_SERVICES)
              );
              return KEEP_MODAL;
            }
          }

          handleAddToCard();
        })
        .catch(() => {
          return;
          if (
            button === PARTNER_STEP_BUTTONS.ADD_TO_CART ||
            button === PARTNER_STEP_BUTTONS.NEXT
          ) {
            return;
          }

          dispatch(actions.setDialogModal(DIALOG_MODALS.CANCEL));
          return KEEP_MODAL;
        });
    };

    const onProceedModal = async () => {
      // if (isOnlyDistribution && saveMediaBuy) {
      if (saveMediaBuy) {
        await saveMediaBuy();
        // dispatch(actions.setCurrentStep(BRIEF_STEPS.MEDIA_BUY));
      }
      setDialogModal(false);
    };

    if (!details || fetchingPartnerDetails) {
      return (
        <div className="qu-details-content">
          <div className="qu-details-main">
            <div style={{ height: 600 }}>
              <SpinnerCenter />
            </div>
          </div>
        </div>
      );
    }

    const modalData = {
      question: "You have not selected Media Buy for this brief.",
      description: "Would you like to add Media Buy?",
      submitButtonText: "No, Cancel",
      cancelButtonText: "Yes, Proceed",
    };

    // console.log(details)

    const getServiceTableCategories = () => {
      let se = [...details.availableServices];
      let set = new Set();
      se.forEach((el) => {
        // console.log(el.serviceName)
        set.add(el.serviceName);
      });
      return Array.from(set);
    };
    // console.log(getServiceTableCategories())
    // console.log(details.availableServices)

    const getServicesAccordingToCategory = (category: string) => {
      let details_ = { ...details };
      details_.availableServices = details_.availableServices.filter(
        (el) => el.serviceName === category
      );
      return details_;
    };

    const handleServiceSearch = (e: any) => {
      setSearchServiceText(e.target.value);

      const filtered = details.availableServices.filter((x) =>
        x.serviceSubName?.toLowerCase().includes(e.target.value.toLowerCase()) && x.serviceName !== 'SKU Uplifting'
      );

      setFilteredDetails((old: any) => {
        return {
          ...details,
          availableServices: filtered,
        };
      });
    };

    return (
      <div className="qu-details-content">
        <div className="qu-details-main">
          <div className="qu-details-thumbnail">
            <img
              src={details.companyBackgroundImageUrl || defaultImage}
              alt={details.companyName}
            />
          </div>
          <div className="qu-details-body">
            <DetailsCompanyInfo details={details} />

            {!isMobile && <DetailsAbout details={details} />}

            <h2 className="section-title">{t(`available_services`)}</h2>
            <div>
              <QInput
              className="mt-5 mb-20"
              size={"large"}
                placeholder={t("search")}
                value={searchServiceText}
                onChange={handleServiceSearch}
              />
            </div>
            <div>
              {!isMobile && filteredDetails.id && (
                <DetailsServicesTable
                  details={filteredDetails}
                  selectedServices={selectedServices}
                  onChangeServices={handleOnChange}
                  editMode={editMode}
                  isBrief
                />
              )}
            </div>
            {/* {getServiceTableCategories().map((category:any,index)=>{
              return <div key={index}>
                {!isMobile && (
                  <>
                    <h5 style={{marginTop:"15px", fontWeight:"bold"}}>{t(`dashboard.service-types-labels.${category}`,category,{ns:'translationDashboard'})}</h5>
                    <DetailsServicesTable
                      details={getServicesAccordingToCategory(category)}
                      selectedServices={selectedServices}
                      onChangeServices={handleOnChange}
                      editMode={editMode}
                      isBrief
                    />
                  </>

            )}
              </div>
            })} */}

            {isMobile && (
              <div className="only-mobile">
                <Radio.Group
                  className="qu-radio-group full-width mb-15"
                  buttonStyle="solid"
                  value={isServices}
                  onChange={handleChangeRadio}
                >
                  <Radio.Button
                    className="qu-radio-button qu-radio-white"
                    value={true}
                  >
                    {t(`services`)}
                  </Radio.Button>
                  <Radio.Button
                    className="qu-radio-button qu-radio-white"
                    value={false}
                  >
                    {t(`about`)}
                  </Radio.Button>
                </Radio.Group>
                {isServices && (
                  // <ServicesMobileForm
                  //   // details={details}
                  //   details={filteredDetails}
                  //   briefId={briefId}
                  //   showModal={() => setDialogModal(true)}
                  // />
                  <DetailsServicesTable
                    details={filteredDetails}
                    selectedServices={selectedServices}
                    onChangeServices={handleOnChange}
                    editMode={editMode}
                    isBrief
                  />
                )}
                {!isServices && (
                  <React.Fragment>
                    <h2 className="section-title">
                      {t(`about`)} {details.companyName}
                    </h2>
                    <DetailsAbout details={details} />
                  </React.Fragment>
                )}
              </div>
            )}
          </div>
        </div>
        {!isMobile && (
          <SelectedServicesForm
            selectedServices={selectedServices}
            logisticSampleRate={logisticSampleRate}
            logisticInsertRate={logisticInsertRate}
            logisticSamplePrice={logisticSamplePrice}
            logisticInsertPrice={logisticInsertPrice}
            stickerCost={stickerCost}
            stickerPrice={stickerPrice}
            subTotal={subTotal}
            editMode={editMode}
            onChangeServices={handleOnChange}
            handleSubmitForm={handleSubmitForm}
            handleEditMode={handleEditMode}
            form={form}
            details={details}
            brief={brief}
            adEstimation={(estimations: IAdEstimation) =>
              setAdEstimation(estimations)
            }
          />
        )}

        {dialogModal && (
          <DialogModal
            modalOpen={dialogModal}
            question={modalData.question}
            description={modalData.description}
            submitButtonText={modalData.submitButtonText}
            cancelButtonText={modalData.cancelButtonText}
            onClose={() => setDialogModal(false)}
            onProceed={onProceedModal}
          />
        )}
      </div>
    );
  }
);

export default BriefPartnerSinglePageForm;
