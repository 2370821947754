import { createSelector } from 'reselect'
import { MODULE_NAME } from './promotions.constants'
import { IState } from './promotions.types'

const selectState = (state: { [MODULE_NAME]: any }) => state[MODULE_NAME]

export const getDataLoading = createSelector(
  selectState,
  (state: IState): boolean => state.loading
)

export const getAllPromotions = createSelector(selectState, (state: IState): any =>
  state.data.map((activity: any) => {
    return {
      ...activity,
      key: activity.id
    }
  })
)

export const getRootTotalRecords = createSelector(selectState, (state: IState): any => state.rootTotal)


// export const getCalendarServicesList = createSelector(selectState, (state: IState): any => state.services)
