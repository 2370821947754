import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useReduxDispatch } from '../../../helpers'
import moment from 'moment'
import {
  getIsLeadsListLoading,
  getLeadsList,
  getLeadsTotal,
  getPages,
  getTotalExistingUsers,
  getTotalInvitedClients,
} from '../leads.selectors'
import useDebounce from '../../../hooks/useDebounce'
import * as actions from '../leads.actions'

import { Row, Col, QTable, QInput, QPagination } from 'quantum_components'
import { SearchOutlined } from '@ant-design/icons'
import { FORMATS } from 'src/constants'

const LeadsList = () => {
  const dispatch = useReduxDispatch()
  const leadsList = useSelector(getLeadsList)
  const leadsTotal = useSelector(getLeadsTotal)
  const totalInvitedClient = useSelector(getTotalInvitedClients)
  const totalExistingUsers = useSelector(getTotalExistingUsers)
  const leadsListIsLoading = useSelector(getIsLeadsListLoading)
  const pages = useSelector(getPages)

  const [search, setSearch] = useState('')
  const [params, setParams] = useState({
    currentPage: 1,
    limit: 10,
    offset: 0,
    order: 'desc',
    orderBy: 'createdAt',
  })

  const debouncedSearch = useDebounce(search, 500)

  const fetchLeads = (queryParams = params) => {
    dispatch(
      actions.fetchLeadsList({
        ...queryParams,
        search: debouncedSearch,
      })
    )
  }

  const fetchLeadsForFilters = (queryParams = params) => {
    dispatch(
      actions.fetchLeadsList({
        ...queryParams,
        currentPage: 1,
        limit: 10,
        offset: 0,
        search: debouncedSearch,
      })
    )
  }

  useEffect(() => {
    fetchLeadsForFilters()
    dispatch(actions.setCurrentPage(1))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch])

  // @ts-ignore
  const handleTableChange = (pagination, filters, sorter) => {
    const sortingParams = {
      order: sorter.order.slice(0, -3),
      orderBy: sorter.columnKey,
    }
    const newParams = {
      ...params,
      ...sortingParams,
    }
    setParams(newParams)
    fetchLeads(newParams)
  }

  const handleChangeSearch = (event: any) => {
    setSearch(event.currentTarget.value)
  }

  const handleChangePage = (page: number, pageSize: number) => {
    dispatch(actions.setCurrentPage(page))

    dispatch(
      actions.fetchLeadsList({
        ...params,
        offset: (page - 1) * params.limit,
        currentPage: page,
        search: debouncedSearch,
        limit: pageSize,
      })
    )

    setParams({
      ...params,
      offset: (page - 1) * params.limit,
      currentPage: page,
      limit: pageSize,
    })
  }

  const columns = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: true,
    },
    {
      title: 'Company Name',
      dataIndex: 'companyName',
      key: 'companyName',
      sorter: true,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      sorter: true,
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      render: (text: string, record: any) => {
        return <div>{moment(record.createdAt).format(FORMATS.DATE_TIME_FORMAT)}</div>
      },
    },
  ]

  return (
    <>
      <h2 className="pt-10 pb-10">Leads</h2>
      <Row justify="space-between" align="bottom" gutter={12}>
        <Col className="qt-search">
          <QInput
            value={search}
            onChange={handleChangeSearch}
            placeholder="Search"
            size="large"
            prefix={<SearchOutlined />}
          />
        </Col>
        <Col className="mr-20">
          <h5 style={{ float: 'right' }}>Total invited clients: {totalInvitedClient}</h5>
          <h5>Total existing users: {totalExistingUsers}</h5>
        </Col>
      </Row>
      <div className="pt-15 pb-30">
        <QTable
          columns={columns}
          dataSource={leadsList}
          loading={leadsListIsLoading}
          scroll={{ x: 990 }}
          // @ts-ignore
          onChange={handleTableChange}
          sortDirections={['ascend', 'descend', 'ascend']}
        />
      </div>
      <div className="qt-footer">
        <QPagination
          size="small"
          defaultCurrent={1}
          hideOnSinglePage
          current={pages.currentPage}
          total={leadsTotal}
          onChange={handleChangePage}
        />
      </div>
    </>
  )
}

export default LeadsList
