/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import React, { useEffect, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { useReduxDispatch } from '../../../../helpers'
import { isMobile } from 'react-device-detect'
import * as actions from '../draftBriefs.actions'
import {
  Dropdown,
  IconsCommon,
  Menu,
  QInput,
  QMenuItem,
  QPagination,
  QTable,
  QTableCardList,
  Row,
} from 'quantum_components'
import { useSelector } from 'react-redux'
import {
  getBrief,
  getBriefList,
  getBriefListLoading,
  getBriefParams,
  getCardList,
  getPages,
  getTotalBriefList,
} from '../draftBriefs.selectors'
import { Col, Modal } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import useDebounce from '../../../../hooks/useDebounce'
import { BRIEF_STATUS, briefStatuses } from 'src/constants/brief'
import { omit } from 'lodash'
import { IBriefOwnerBase, IBriefCampaign, IBriefListElement } from '../draftBriefs.types'
import { COLORS } from '../../../../constants/colors'
import { getCurrentUserPermissionActions } from '../../../core/core.selectors'
import { hasPermission, showListingActions } from '../../../../helpers/permission'
import { PERMISSIONS } from '../../../../constants/permission'

const DraftBriefsList = () => {
  const history = useHistory()
  const dispatch = useReduxDispatch()
  const briefList = useSelector(getBriefList)
  const cardList = useSelector(getCardList)
  const brief = useSelector(getBrief)
  const totalBriefList = useSelector(getTotalBriefList)
  const briefListLoading = useSelector(getBriefListLoading)
  const params = useSelector(getBriefParams)
  const pages = useSelector(getPages)
  const permissions = useSelector(getCurrentUserPermissionActions)
  const { id } = useParams<{id: any}>()

  const [tableKey, setTableKey] = useState(1)
  const [modal, setModal] = useState({ visible: false, id: null })
  const [search, setSearch] = useState('')

  const debouncedSearch = useDebounce(search, 500)

  useEffect(() => {
    const newParams = id
      ? { ...params, userId: id, search: debouncedSearch, offset: 0, limit: 10 }
      : omit({ ...params, search: debouncedSearch, offset: 0, limit: 10 }, 'userId')
    dispatch(actions.setParamsAndFetch({ ...newParams }))
    dispatch(actions.setCurrentPage(1))
  }, [debouncedSearch])

  const handleOk = () => {
    setModal({ visible: false })
    setTableKey(tableKey + 1)
    dispatch(actions.deleteBrief(modal.id, params))
  }

  const handleDeleteBrief = id => {
    setModal({ visible: true, id })
  }

  const colKey = {
    product: 'name',
  }

  const columns = [
    {
      title: 'Product',
      dataIndex: 'product',
      key: 'product',
      width: '15%',
      fixed: 'left',
      sorter: true,
      render: product => {
        return (
          <div className="q-table-product">
            <div className="q-table-product_thumb">
              {product.productFirstImage && <img src={product.productFirstImage} alt={product.title} />}
            </div>
            <div className="q-table-product_info">
              <h3 className="q-table-product_title">{product.title || 'Unnamed Brief'}</h3>
              <div className="q-table-product_created">Created {product.created}</div>
              {product.discountText && (
                <div className="text-green line-height-10 font-size-12">{product.discountText}</div>
              )}
            </div>
          </div>
        )
      },
    },
    {
      title: 'Owner',
      dataIndex: 'owner',
      key: 'owner',
      width: '10%',
      render: (owner: IBriefOwnerBase) => {
        return (
          <Link to={`/users/clients/profile/${owner.id}`} style={{ textDecoration: 'none' }}>
            <div>
              {owner.firstName} {owner.lastName}
            </div>
            <div>{owner.companyName}</div>
          </Link>
        )
      },
    },
    {
      title: 'Invited By',
      dataIndex: 'exclusivePartnerCompanyName',
      key: 'exclusivePartnerCompanyName',
      width: '10%',
      render: exclusivePartnerCompanyName => <div>{exclusivePartnerCompanyName}</div>,
    },
    {
      title: 'Services',
      dataIndex: 'services',
      key: 'services',
      width: '15%',
      render: list => (
        <ul className="q-table-list">
          {list.map((service, i) => {
            return (
              <li key={i} className="q-table-list_item">
                <span className="check-icon">
                  <IconsCommon.IconCheck color={COLORS.LIGHT_GREEN} />
                </span>
                {service.title}
              </li>
            )
          })}
        </ul>
      ),
    },
    {
      title: 'Start & Duration',
      dataIndex: 'services',
      key: 'services',
      width: '15%',
      render: list => (
        <ul className="q-table-list">
          {list.map((service, i) => {
            return service.title === 'Media Buying' ? (
              <li key={i} className="q-table-list_item">
                {service.mediaStartDate || '-'}
                <br />
                {service.mediaDuration || '-'}
              </li>
            ) : (
              <li key={i} className="q-table-list_item">
                {service.productStartDate || '-'}
                <br />
                {service.productDuration || '-'}
              </li>
            )
          })}
        </ul>
      ),
    },
    {
      title: 'Qty',
      dataIndex: 'services',
      key: 'services',
      width: '7%',
      render: list => {
        const quantity = list.length > 0 && list[0].quantity ? list[0].quantity : '-'
        return (
          <ul className="q-table-list">
            <li className="q-table-list_item">{quantity}</li>
          </ul>
        )
      },
    },
    {
      title: 'Campaign',
      dataIndex: 'campaign',
      key: 'campaign',
      width: '10%',
      render: (campaign: IBriefCampaign) => {
        return campaign == null ? (
          <div>No</div>
        ) : (
          <div onClick={e => e.stopPropagation()}>
            <Link to={`/campaigns/${campaign.id}`} style={{ textDecoration: 'none' }}>
              <div>Yes</div>
            </Link>
          </div>
        )
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '15%',
      fixed: 'right',
      render: status => {
        const rejectionStatuses = [BRIEF_STATUS.REJECTED, BRIEF_STATUS.PARTIALLY_REJECTED]
        const actions = [
          PERMISSIONS.VIEW_EXCLUSIVE_BRIEF,
          PERMISSIONS.CREATE_AND_EDIT_EXCLUSIVE_BRIEF,
          PERMISSIONS.DELETE_EXCLUSIVE_BRIEF,
        ]
        const showActions = showListingActions(permissions, actions)
        return (
          <div>
            <span className={`status-label ${briefStatuses[status.value] && briefStatuses[status.value].classStyle}`}>
              {briefStatuses[status.value] ? briefStatuses[status.value].label : 'draft'}
            </span>
            {showActions && (
              /* QUA-1449, QP-492 - prevent event bubbling to onRowClick */
              <div onClick={e => e.stopPropagation()}>
                <Dropdown
                  overlay={menu(status.id, rejectionStatuses.includes(status.value))}
                  trigger={['click']}
                  placement="bottomRight"
                >
                  <div className="q-table_menu">
                    <IconsCommon.IconEllipsisV />
                  </div>
                </Dropdown>
              </div>
            )}
          </div>
        )
      },
    },
  ]

  const menu = (id: number, isRejected: boolean) => (
    <Menu className="user-menu-list">
      {hasPermission(permissions, PERMISSIONS.VIEW_EXCLUSIVE_BRIEF) && (
        <QMenuItem key={0} onClick={() => handleView(id)} name="View" icon={<IconsCommon.IconView />} />
      )}
      {hasPermission(permissions, PERMISSIONS.CREATE_AND_EDIT_EXCLUSIVE_BRIEF) && (
        <QMenuItem key={1} onClick={() => handleEdit(id)} name="Edit" icon={<IconsCommon.IconEdit />} />
      )}
      <Menu.Divider />
      {hasPermission(permissions, PERMISSIONS.DELETE_EXCLUSIVE_BRIEF) && (
        <QMenuItem key={2} onClick={() => handleDeleteBrief(id)} name="Delete" icon={<IconsCommon.IconDelete />} />
      )}
      {hasPermission(permissions, PERMISSIONS.VIEW_EXCLUSIVE_BRIEF) && (
        <QMenuItem
          key={3}
          onClick={() => handleDownloadMedia(id)}
          name="Download Media"
          icon={<IconsCommon.IconDownload />}
        />
      )}
    </Menu>
  )

  const handleChangeSearch = e => {
    setSearch(e.currentTarget.value)
  }

  const handleChangePage = (page: number, pageSize: number) => {
    dispatch(actions.setCurrentPage(page))
    dispatch(actions.setParamsAndFetch({ ...params, offset: (page - 1) * pageSize, limit: pageSize }))
  }

  const handleTableChange = (pagination, filters, sorter) => {
    const sortingParams = {
      direction: sorter.order.slice(0, -3),
      sortField: colKey[sorter.columnKey],
    }
    const newParams = {
      ...params,
      ...sortingParams,
    }
    dispatch(actions.setParamsAndFetch(newParams))
  }

  const handleView = id => {
    history.push(`/invitedClients/briefs/view/${id}`)
  }

  const handleEdit = id => {
    dispatch(actions.setCurrentStep(brief.lastFilledStep))
    dispatch(actions.setNeedCheckLastStep(true))
    history.push(`/invitedClients/briefs/edit/${id}`)
  }

  const handleRejection = id => {
    history.push(`/briefs/rejected/${id}`)
  }

  const handleDownloadMedia = id => {
    dispatch(actions.fetchDownloadMedia(id))
  }

  const handleOnRowClick = (brief: IBriefListElement) => ({
    onClick: (event: any) => history.push(`/invitedClients/briefs/view/${brief.key}`),
  })

  return (
    // qu-brief-wide
    <div className="qu-brief-view">
      <h2 className="pb-10">Briefs</h2>
      <div className="qu-brief-list pb-20">
        <Row className={`${isMobile && 'pb-15'}`} justify={`${isMobile ? 'center' : 'space-between'}`}>
          <Col className="qt-search">
            <QInput
              className={`${isMobile && 'full-width'}`}
              value={search}
              onChange={handleChangeSearch}
              placeholder="Search"
              size="large"
              prefix={<SearchOutlined />}
            />
          </Col>
        </Row>
        {isMobile ? (
          <div className="pl-15 pr-15">
            <QTableCardList
              cardList={cardList}
              labelStatus={briefStatuses}
              menu={menu}
              onCardClick={briefId => handleView(briefId)}
            />
          </div>
        ) : (
          <div className="pt-15 pb-30">
            <QTable
              //@ts-ignore
              onRow={handleOnRowClick}
              onChange={handleTableChange}
              sortDirections={['ascend', 'descend', 'ascend']}
              loading={briefListLoading}
              columns={columns}
              dataSource={briefList}
              scroll={{ x: 990 }}
              rowClassName="q-table"
              className="cursorPointer"
            />
          </div>
        )}

        <Modal
          key={tableKey}
          title="Are you sure?"
          visible={modal.visible}
          onOk={handleOk}
          onCancel={() => {
            setModal({ visible: false })
            setTableKey(tableKey + 1)
          }}
        >
          <p>Do you want delete the brief?</p>
        </Modal>
        <Row justify={`${isMobile ? 'center' : 'end'}`} className={`${isMobile && 'pt-15 mb-30'}`}>
          <QPagination
            size="default"
            defaultCurrent={1}
            current={pages.currentPage}
            total={totalBriefList}
            onChange={handleChangePage}
            showSizeChanger
          />
        </Row>
      </div>
    </div>
  )
}

export default DraftBriefsList
