import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { AxiosResponse } from 'axios'
import IAction from 'src/interfaces/IAction'
import queryBuilder from '../../utils/buildQueryString'
import { requestHttp, urls } from 'src/api'
import { IActivity, IActivitiesParams } from './activities.types'
import { getResponseErrorMessage } from 'src/helpers'
import * as CONSTANTS from './activities.constants'

export const activitiesRequest = (): IAction => ({
  type: CONSTANTS.FETCH_ACTIVITIES_REQUEST,
})

export const activitiesSuccess = (activities: { results: IActivity[]; total: number }): IAction => ({
  type: CONSTANTS.FETCH_ACTIVITIES_SUCCESS,
  payload: { activities },
})

export const activitiesFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_ACTIVITIES_FAILURE,
  error,
})

export const setCurrentPage = (page: number): IAction => ({
  type: CONSTANTS.SET_CURRENT_PAGE,
  payload: { page },
})

export const fetchActivities = (
  params: IActivitiesParams
): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse> => {
  try {
    dispatch(activitiesRequest())

    const response = await requestHttp.get(urls.getActivitiesUrl(), { params })
    const { content } = response.data.data

    dispatch(activitiesSuccess(content))
    return response
  } catch (error) {
    // @ts-ignore
    dispatch(activitiesFailure(getResponseErrorMessage(error)))
    // @ts-ignore
    return error
  }
}
