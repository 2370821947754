import get from 'lodash/get'
import { IState, IRole } from './roles.types'
import * as CONSTANTS from './roles.constants'

const initialState: IState = {
  rolesListLoading: true,
  rolesList: {
    results: [],
    total: 0,
  },
  roleDetails: {
    id: null,
    name: '',
    description: '',
    permissions: [],
    createdAt: '',
    updatedAt: '',
  },
  permissionsList: [],
  createRoleError: '',
  editRoleError: '',
  deleteRoleError: '',
  pages: {
    totalPages: 1,
    currentPage: 1,
  },
  updatingRoles: false,
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case CONSTANTS.FETCH_ROLES_REQUEST:
      return {
        ...state,
        rolesListLoading: true,
      }
    case CONSTANTS.FETCH_ROLES_SUCCESS:
      const rolesList = get(action, 'payload.rolesList')
      return {
        ...state,
        rolesList,
        rolesListLoading: false,
      }
    case CONSTANTS.FETCH_ROLES_FAILURE:
      return {
        ...state,
        rolesListLoading: false,
      }
    case CONSTANTS.FETCH_PERMISSIONS_REQUEST:
      return { ...state }
    case CONSTANTS.FETCH_PERMISSIONS_SUCCESS:
      const permissionsList = action.payload.filter((permission:any)=>permission.isHidden === false)
      return {
        ...state,
        permissionsList,
      }
    case CONSTANTS.FETCH_PERMISSIONS_FAILURE:
      return { ...state }
    case CONSTANTS.VIEW_ROLE_REQUEST:
      return { ...state }
    case CONSTANTS.VIEW_ROLE_SUCCESS:
      return {
        ...state,
        roleDetails: get(action, 'payload.roleDetails'),
      }
    case CONSTANTS.VIEW_ROLE_FAILURE:
      return { ...state }
    case CONSTANTS.CREATE_ROLE_REQUEST:
      return {
        ...state,
        updatingRoles: true,
        createRoleError: initialState.createRoleError,
      }
    case CONSTANTS.CREATE_ROLE_SUCCESS:
      return { ...state, updatingRoles: false }
    case CONSTANTS.CREATE_ROLE_FAILURE:
      return {
        ...state,
        updatingRoles: false,
        createRoleError: action.error,
      }
    case CONSTANTS.EDIT_ROLE_REQUEST:
      return {
        ...state,
        updatingRoles: true,
        editRoleError: initialState.editRoleError,
      }
    case CONSTANTS.EDIT_ROLE_SUCCESS:
      return {
        ...state,
        updatingRoles: false,
      }
    case CONSTANTS.EDIT_ROLE_FAILURE:
      return {
        ...state,
        updatingRoles: false,
        editRoleError: action.error,
      }
    case CONSTANTS.DELETE_ROLE_REQUEST:
      return {
        ...state,
        updatingRoles: true,
        deleteRoleError: initialState.deleteRoleError,
      }
    case CONSTANTS.DELETE_ROLE_SUCCESS:
      const restRoles = state.rolesList.results.filter((role: IRole) => {
        return role.id !== get(action, 'payload.id')
      })
      return {
        ...state,
        updatingRoles: false,
        rolesList: {
          ...state.rolesList,
          results: restRoles,
        },
      }
    case CONSTANTS.DELETE_ROLE_FAILURE:
      return {
        ...state,
        updatingRoles: false,
        deleteRoleError: action.error,
      }
    case CONSTANTS.SET_CURRENT_PAGE:
      return {
        ...state,
        pages: {
          ...state.pages,
          currentPage: get(action, 'payload.page'),
        },
      }
    default:
      return state
  }
}
