import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { TABS_NAMES } from 'src/constants/tabs'
import { PERMISSIONS } from 'src/constants/permission'

import { hasPermission } from 'src/helpers/permission'
import { getCurrentUserPermissionActions } from 'src/modules/core/core.selectors'

import BillingsTabs from '../components/BillingsTabs'
import ClientBillings from '../components/ClientBillings'
import PartnerBillings from '../components/PartnerBillings'
import OnlinePayments from '../components/OnlinePayments'

const Billings = () => {
  const { i18n } = useTranslation()
  const { t } = useTranslation(['translationSettings', 'translationCommon'])

  const permissions = useSelector(getCurrentUserPermissionActions)

  const [activeTab, setActiveTab] = useState(
    hasPermission(permissions, PERMISSIONS.CLIENT_BILLS_ALL)
      ? TABS_NAMES.CLIENTS
      : hasPermission(permissions, PERMISSIONS.PARTNER_BILLS_ALL)
      ? TABS_NAMES.PARTNERS
      : hasPermission(permissions, PERMISSIONS.VIEW_ONLINE_PAYMENTS)
      ? TABS_NAMES.ONLINE_PAYMENTS
      : TABS_NAMES.CLIENTS
  )

  useEffect(()=>{
    setActiveTab(TABS_NAMES.CLIENTS)
  },[])

  return (
    <div className="qu-brief-view">
      <h2 className="pt-10 mb-24">{t('settings.billings.Billings')}</h2>

      <BillingsTabs activeTab={activeTab} onChange={(event: any) => setActiveTab(event.target.value)} />
      {activeTab === TABS_NAMES.CLIENTS && hasPermission(permissions, PERMISSIONS.CLIENT_BILLS_ALL) && (
        <ClientBillings />
      )}
      {activeTab === TABS_NAMES.PARTNERS && hasPermission(permissions, PERMISSIONS.PARTNER_BILLS_ALL) && (
        <PartnerBillings canUploadBankTransfer />
      )}
      {activeTab === TABS_NAMES.ONLINE_PAYMENTS && hasPermission(permissions, PERMISSIONS.VIEW_ONLINE_PAYMENTS) && (
        <OnlinePayments />
      )}
    </div>
  )
}

export default Billings
