import { Col, Divider, Row, Tag } from "antd";
import { AgeGroupCard } from "src/modules/brief/containers/components/Brief.AgeGroupCard";
import lodash from "lodash";
import React, { useEffect, useState, createRef, useRef } from "react";
import SpinnerCenter from "src/components/Spinner/spinner";
import {
  Modal,
  QButton,
  QInput,
  qnotification,
  QSelect,
} from "quantum_components";
import { useTranslation } from "react-i18next";
import V3ProductComponent from "./V3Product.component";

const V3NativeTemplatesComponent = ({
  briefSettings,
  state,
  setState,
  arrayIndex,
  canEdit,
  isLoading,
  setIsLoading,
}: any) => {
  const { t } = useTranslation(["translationV2"]);
  const [showModal, setShowModal] = useState(false);
  const [variableValues, setVariableValues] = useState<any>({});
  const [localActiveTemplate, setLocalActiveTemplate] = useState<any>({});
  const [sanitizedHtml, setSanitizedHtml] = useState({ __html: "" });
  const [rawJS, setRawJS] = useState("");
  const [localActiveProduct, setLocalActiveProduct] = useState<any>({});

  // activeNativeTemplate and templateVariables, templateId

  useEffect(() => {
    if (lodash.get(briefSettings, "partnerProfileId") <= 0) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [briefSettings]);


  useEffect(() => {
    if (lodash.isEmpty(state)) return;
    handleSync();

    if (lodash.get(briefSettings, "partnerProfileId") <= 0) return;

    let existingTemplate = lodash.get(
      state[arrayIndex],
      "activeNativeTemplate",
      {}
    );
    if (lodash.isEmpty(existingTemplate)) {
      // check if there is a mapped template
      const mappedTemplateId = lodash.get(
        state[arrayIndex],
        "mappedTemplate.id",
        -1
      );
      if (
        mappedTemplateId > 0 &&
        briefSettings?.partnerProfileId > 0 &&
        briefSettings?.nativeadTemplates?.find(
          (o: any) => o.id === mappedTemplateId
        )
      ) {
        // select this template
        handleTemplateChange(mappedTemplateId);
      }
    }
  }, [, state, briefSettings]);

  const handleTemplateChange = (selectedTemplateId: any) => {
    let s = [...state];
    s[arrayIndex].activeNativeTemplate = {
      ...briefSettings?.nativeadTemplates?.find(
        (o: any) => o.id === selectedTemplateId
      ),
    };
    s[arrayIndex].activeNativeTemplate["values"] = {};
    setState(s);
  };

  const handleVariableChange = (placeholder: any, text: any) => {
    let ev = { ...state[arrayIndex].activeNativeTemplate?.values };
    ev = { ...ev, [placeholder]: text };

    let ant = { ...state[arrayIndex].activeNativeTemplate };
    ant.values = ev;

    let s = [...state];
    s[arrayIndex].activeNativeTemplate = ant;
    setState(s);
  };

  const getVariableValue = (placeholder: any) => {
    return lodash.get(
      state[arrayIndex].activeNativeTemplate.values,
      placeholder
    );
  };

  const shouldShowHTML = () => {
    if (
      !lodash.has(state[arrayIndex].activeNativeTemplate, "rawHtml") ||
      !lodash.has(state[arrayIndex].activeNativeTemplate, "values")
    )
      return false;

    // check all the variables if present then show otherwise dont show
    const vars = lodash.get(
      state[arrayIndex],
      "activeNativeTemplate.variables",
      []
    );
    if (
      lodash.isEmpty(
        lodash.get(state[arrayIndex], "activeNativeTemplate.values", {})
      )
    )
      return false;

    const templateVariables = lodash
      .values(lodash.get(state[arrayIndex], "activeNativeTemplate.values", {}))
      .filter((a) => a);
    if (vars.length != templateVariables.length) return false;

    return true;
  };
  
  const handleSync = () => {
    if (!shouldShowHTML()) return;

    let { rawHtml, values, rawJS } = state[arrayIndex].activeNativeTemplate;
    Object.keys(values).forEach((key) => {
      rawHtml = rawHtml.replaceAll(key, values[key]);
    });
    setSanitizedHtml({ __html: rawHtml });

    const htmlOuputDiv = document.getElementById(`html_output_${arrayIndex}`);
    if(htmlOuputDiv){
      htmlOuputDiv.style.background = "transparent";
    }

    if(!lodash.isEmpty(rawJS)){
      addRawJS(rawJS)
    }
  };

  const addRawJS = (rawJS:any) => {
    const script = document.createElement('script');
    script.id = `script_output_${arrayIndex}`;
    script.innerHTML = rawJS;
    script.defer = true;

    const htmlOuputDiv = document.getElementById(`html_output_${arrayIndex}`);
    if(htmlOuputDiv){
      setTimeout(() => {
        try {
          const loadedScript = document.getElementById(`script_output_${arrayIndex}`);
          if(!loadedScript){
            htmlOuputDiv.appendChild(script);
          }
        } catch (error) {}
        
      }, 2000);
    }
  }

  return (
    <>
      {/* {JSON.stringify(localActiveTemplate)} */}

      {(lodash.isEmpty(state[arrayIndex].activeNativeTemplate) ||
        !lodash.has(state[arrayIndex].activeNativeTemplate, "name")) && (
        <div>
          {t("No templates found")}{" "}
          <a onClick={() => canEdit && setShowModal(true)}>{t("Add Now.")}</a>
        </div>
      )}

      <div>
        {!lodash.isEmpty(state[arrayIndex].activeNativeTemplate) &&
          lodash.has(state[arrayIndex].activeNativeTemplate, "name") && (
            <>
              <Tag
                className="tag-preview"
                onClick={() => canEdit && setShowModal(true)}
              >
                {state[arrayIndex].activeNativeTemplate.name}
              </Tag>

              <div
                id={`html_output_${arrayIndex}`}
                className="mt-10 html-output-div"
                dangerouslySetInnerHTML={sanitizedHtml}
                onClick={(e) => {
                  if(lodash.isEmpty(lodash.get(state[arrayIndex],'activeNativeTemplate.rawJS',""))){
                    e.stopPropagation();
                    canEdit && setShowModal(true);
                  }
                }}
              >
              </div>
            </>
          )}
      </div>

      <Modal
        wrapClassName="qu-modal"
        visible={showModal}
        footer={false}
        closable={true}
        width={675}
        onCancel={(e) => {
          e.stopPropagation();
          // if(lodash.isEmpty(localActiveTemplate)){
          //   qnotification({message:t('Please select a template'),type:'error'});
          //   return false;
          // }

          // if(!lodash.has(localActiveTemplate,'values')){
          //   qnotification({message:t('Please add variable values'),type:'error'});
          //   return false;
          // }else if(Object.values(localActiveTemplate.values).filter(o=>o).length !== localActiveTemplate?.variables?.length){
          //   qnotification({message:t('Not all variable values were replaced'),type:'error'});
          //   return false;
          // }

          // if (
          //   state[arrayIndex].activeNativeTemplate &&
          //   state[arrayIndex].activeNativeTemplate?.id &&
          //   state[arrayIndex].activeNativeTemplate?.values
          // ) {
          //   let ss = state.map((s: any, index: any) => {
          //     if (index === arrayIndex) {
          //       s.activeNativeTemplate = state[arrayIndex].activeNativeTemplate;
          //       s.templateId = state[arrayIndex].activeNativeTemplate.id;
          //       s.templateVariables = state[arrayIndex].activeNativeTemplate.values;
          //     }
          //     return s;
          //   });
          //   setState(ss);
          //   handleSync();
          // }
          if (
            !lodash.isEmpty(state[arrayIndex]?.activeNativeTemplate?.values)
          ) {
            handleSync();
          }
          setShowModal(false);
        }}
      >
        <div className="v3-section">
          <h3 className="mb-20">{t("Edit Template")}</h3>
          <QSelect
            disabled={
              lodash.get(state[arrayIndex], "mappedTemplate.id", -1) > 0 &&
              briefSettings?.nativeadTemplates?.find(
                (o: any) => o.id === lodash.get(state[arrayIndex], "mappedTemplate.id", -1)
              )
            }
            value={state[arrayIndex]?.activeNativeTemplate?.id}
            onChange={handleTemplateChange}
            size="large"
            className="full-width"
            options={briefSettings?.nativeadTemplates?.map((template: any) => {
              return {
                label: template.name,
                value: template.id,
              };
            })}
            placeholder={t("Please select")}
          />
          <Divider></Divider>

          {!lodash.isEmpty(state[arrayIndex].activeNativeTemplate) &&
            lodash.has(state[arrayIndex].activeNativeTemplate, "variables") && (
              <Row gutter={24}>
                <Col md={24} xs={24}>
                  <h6>{t("Select a Product to Promote")}</h6>
                  <div className="mb-20">
                    <V3ProductComponent
                      arrayIndex={arrayIndex}
                      briefSettings={briefSettings}
                      state={state}
                      setState={setState}
                      canEdit={canEdit}
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                    />
                  </div>

                  <h6>{t("or Replace Variables Values Manually")}</h6>
                  {!lodash.isEmpty(state[arrayIndex]?.activeNativeTemplate) &&
                    state[arrayIndex]?.activeNativeTemplate?.variables?.map(
                      (v: any, index: number) => {
                        return (
                          <Row key={index} align="middle" className="mt-20">
                            <Col sm={10} md={10} xs={24}>
                              <label className="label-required">{v.name}</label>
                            </Col>
                            <Col sm={14} md={14} xs={24}>
                              <QInput
                                onChange={(e: any) =>
                                  handleVariableChange(
                                    v.placeholder,
                                    e.target.value
                                  )
                                }
                                value={getVariableValue(v.placeholder)}
                                placeholder={t("Enter here")}
                                size="large"
                              />
                            </Col>
                          </Row>
                        );
                      }
                    )}
                </Col>
              </Row>
            )}
          {/* {!lodash.isEmpty(state) && <QButton size="small" type="primary" className="mt-20" onClick={handleSync}>
            Visualise
          </QButton>}  */}
        </div>
      </Modal>
    </>
  );
};

export default React.memo(V3NativeTemplatesComponent);
