import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { AxiosResponse } from 'axios'
import IAction from 'src/interfaces/IAction'
import queryBuilder from '../../utils/buildQueryString'

import * as CONSTANTS from './roles.constants'
import { STATUS_CODES } from '../../constants'
import { requestHttp, urls } from 'src/api'
import { IRole, IRolesListParams } from './roles.types'
import { getResponseErrorMessage } from 'src/helpers'

export const rolesListRequest = (): IAction => ({
  type: CONSTANTS.FETCH_ROLES_REQUEST,
})

export const rolesListSuccess = (rolesList: { results: IRole[]; total: number }): IAction => ({
  type: CONSTANTS.FETCH_ROLES_SUCCESS,
  payload: { rolesList },
})

export const rolesListFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_ROLES_FAILURE,
  error,
})

export const permissionsListRequest = (): IAction => ({
  type: CONSTANTS.FETCH_PERMISSIONS_REQUEST,
})

export const permissionsListSuccess = (permissionsList: any): IAction => ({
  type: CONSTANTS.FETCH_PERMISSIONS_SUCCESS,
  payload: permissionsList,
})

export const permissionsListFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_PERMISSIONS_FAILURE,
  error,
})

export const viewRoleRequest = (): IAction => ({
  type: CONSTANTS.VIEW_ROLE_REQUEST,
})

export const viewRoleSuccess = (roleDetails: IRole): IAction => ({
  type: CONSTANTS.VIEW_ROLE_SUCCESS,
  payload: { roleDetails },
})

export const viewRoleFailure = (error: string): IAction => ({
  type: CONSTANTS.VIEW_ROLE_FAILURE,
  error,
})

export const createRoleRequest = (): IAction => ({
  type: CONSTANTS.CREATE_ROLE_REQUEST,
})

export const createRoleSuccess = (): IAction => ({
  type: CONSTANTS.CREATE_ROLE_SUCCESS,
})

export const createRoleFailure = (error: string): IAction => ({
  type: CONSTANTS.CREATE_ROLE_FAILURE,
  error,
})

export const editRoleRequest = (): IAction => ({
  type: CONSTANTS.EDIT_ROLE_REQUEST,
})

export const editRoleSuccess = (): IAction => ({
  type: CONSTANTS.EDIT_ROLE_SUCCESS,
})

export const editRoleFailure = (error: string): IAction => ({
  type: CONSTANTS.EDIT_ROLE_FAILURE,
  error,
})

export const deleteRoleRequest = (): IAction => ({
  type: CONSTANTS.DELETE_ROLE_REQUEST,
})

export const deleteRoleSuccess = (id: number): IAction => ({
  type: CONSTANTS.DELETE_ROLE_SUCCESS,
  payload: { id },
})

export const deleteRoleFailure = (error: string): IAction => ({
  type: CONSTANTS.DELETE_ROLE_FAILURE,
  error,
})

export const setCurrentPage = (page: number): IAction => ({
  type: CONSTANTS.SET_CURRENT_PAGE,
  payload: { page },
})

export const fetchRolesList = (
  params: IRolesListParams
): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse> => {
  try {
    dispatch(rolesListRequest())
    const response = await requestHttp.get(urls.getRolesListUrl(), { params })
    const { content } = response.data.data

    if (content.total > 0) dispatch(rolesListSuccess(content))
    else dispatch(rolesListFailure('No Roles yet'))

    return response
  } catch (error) {
    dispatch(rolesListFailure(getResponseErrorMessage(error)))

    return error
  }
}

export const fetchPermissionsList = (): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse> => {
  try {
    dispatch(permissionsListRequest())

    const response = await requestHttp.get(urls.getPermissionsListUrl())
    const { content } = response.data.data

    dispatch(permissionsListSuccess(content))

    return response
  } catch (error) {
    dispatch(permissionsListFailure(getResponseErrorMessage(error)))

    return error
  }
}

export const fetchViewRoleDetails = (id: number): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse> => {
  try {
    dispatch(viewRoleRequest())

    const response = await requestHttp.get(urls.getViewRoleUrl(id))
    const { content } = response.data.data

    dispatch(viewRoleSuccess(content))

    return response
  } catch (error) {
    dispatch(viewRoleFailure(getResponseErrorMessage(error)))
    return error
  }
}

export const createRole = (role: IRole): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    dispatch(createRoleRequest())

    const response = await requestHttp.post(urls.getCreateRoleUrl(), role)

    if (response.status === 201) {
      dispatch(createRoleSuccess())
    }
  } catch (error) {
    dispatch(createRoleFailure(getResponseErrorMessage(error)))
  }
}

export const editRole = (id: number, role: IRole): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    dispatch(editRoleRequest())

    const response = await requestHttp.put(urls.getEditRoleUrl(id), role)

    if (response.status === STATUS_CODES.BASE_SUCCESS) {
      dispatch(editRoleSuccess())
    }
  } catch (error) {
    dispatch(editRoleFailure(getResponseErrorMessage(error)))
  }
}

export const deleteRole = (id: number): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    dispatch(deleteRoleRequest())

    await requestHttp.delete(urls.getDeleteRoleUrl(id))
    dispatch(deleteRoleSuccess(id))
  } catch (error) {
    dispatch(deleteRoleFailure(getResponseErrorMessage(error)))
  }
}
