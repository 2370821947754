import React, { useState } from 'react'
import { Col, Form, IconsCommon, Modal, QButton, QInput, Row } from 'quantum_components'

import { CURRENCY } from 'src/constants/currencies'
import { renderRoundedNumber } from 'src/helpers/currency'
import { useTranslation } from 'react-i18next'

interface Props {
  availableAmountForRefund: number
  currency: CURRENCY
  visible?: boolean
  onCancel: () => void
  onSubmit: (amount: number) => void
}

const RefundModal = ({ availableAmountForRefund, currency, visible = true, onCancel, onSubmit }: Props) => {
  const { t } = useTranslation(['translationCommon', 'translation', 'translationSettings'])
  const [amount, setAmount] = useState<string>('')
  const [valid, setValid] = useState<boolean>(true)

  const submitHandler = () => {
    if (!valid) {
      return
    }

    onSubmit(Number(amount))
  }

  const changeHandler = (event: any) => {
    let amount: string = event.target.value
    const isDecimal = amount.includes('.') && Boolean(amount.split('.')[0])
    const amountSplittedByDot = amount.split('.')
    if (isDecimal && amountSplittedByDot[1].length > 2) {
      amount = `${amountSplittedByDot[0]}.${amountSplittedByDot[1].slice(0, 2)}`
    }

    // const isAmountAboveZero = amount[0] !== '-'
    setValid(event.target.value >= 0.1 ? true : false)
    setAmount(amount)
  }

  return (
    <Modal
      wrapClassName="qu-modal"
      closeIcon={<IconsCommon.IconClose />}
      title={t('brief.payment-statuses-array.REFUND', { ns: 'translation' })}
      visible={visible}
      onCancel={onCancel}
      footer={
        <Row align="middle" justify="end">
          <Col>
            <QButton className="qu-button-outline mr-16" size="small" onClick={onCancel}>
              {t('common.Cancel', { ns: 'translationCommon' })}
            </QButton>
          </Col>
          <Col>
            <QButton
              disabled={!amount?.length || !valid}
              className="w-100"
              type="primary"
              size="small"
              onClick={submitHandler}
            >
              {t('common.Confirm', { ns: 'translationCommon' })}
            </QButton>
          </Col>
        </Row>
      }
    >
      <Form layout="vertical">
        <Form.Item className="mb-0" colon={false}>
          <h4 className="text-bold">
            {t('settings.billings.You are going to make a refund for this payment.', { ns: 'translationSettings' })}<br />{t('settings.billings.This action is irreversible.', { ns: 'translationSettings' })}
          </h4>
          <div className="mb-5 mt-10">
            {t('settings.billings.Available amount for Refund', { ns: 'translationSettings' })}:{' '}
            <span className="text-bold">
              {availableAmountForRefund ? renderRoundedNumber(availableAmountForRefund, currency) : 0}
            </span>
          </div>
          <QInput
            size="large"
            placeholder={t('settings.billings.Amount for refund', { ns: 'translationSettings' })}
            type="number"
            min={0.1}
            value={amount}
            suffix={<div className="text-bold">{currency}</div>}
            onChange={changeHandler}
          />

          {!valid && <div className="error-message mt-5">Amount can't be below 0.1 {currency}</div>}
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default RefundModal
