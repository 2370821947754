import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useReduxDispatch } from '../../../helpers'
import { Link, useHistory } from 'react-router-dom'
import { getIsRolesListLoading, getPages, getRolesList, getRolesTotal, getUpdatingRoles } from '../roles.selectors'
import { PATHS, LOCALIZATION_LANGUAGES } from '../../../constants'
import { IModalState } from '../roles.types'
import useDebounce from '../../../hooks/useDebounce'
import * as actions from '../roles.actions'

import { Modal, Tooltip } from 'antd'
import { Col, QButton, QInput, QPagination, QTable, Row } from 'quantum_components'
import { DeleteTwoTone, EditTwoTone, PlusOutlined, SearchOutlined } from '@ant-design/icons'
import { getCurrentUserPermissionActions } from '../../core/core.selectors'
import { hasPermission } from '../../../helpers/permission'
import { PERMISSIONS } from '../../../constants/permission'

const RolesList = () => {
  const { i18n } = useTranslation()
  const { t } = useTranslation(['translationEmployees', 'translationCommon'])
  const dispatch = useReduxDispatch()
  const history = useHistory()
  const rolesList = useSelector(getRolesList)
  const rolesTotal = useSelector(getRolesTotal)
  const rolesListIsLoading = useSelector(getIsRolesListLoading)
  const pages = useSelector(getPages)
  const updatingRole = useSelector(getUpdatingRoles)
  const permissions = useSelector(getCurrentUserPermissionActions)

  const [search, setSearch] = useState('')
  const [tableKey, setTableKey] = useState(1)
  const [deleteModal, setDeleteModal] = useState<IModalState>({ visible: false, id: 0, name: '' })

  const [params, setParams] = useState({
    currentPage: 1,
    limit: 10,
    offset: 0,
    direction: 'DESC',
    sortField: 'createdAt',
  })

  const debouncedSearch = useDebounce(search, 500)

  const fetchRoles = (queryParams = params) => {
    dispatch(actions.fetchRolesList({ ...queryParams, search: debouncedSearch }))
  }

  useEffect(() => {
    fetchRoles()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    fetchRoles()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch])

  // @ts-ignore
  const handleTableChange = (pagination, filters, sorter) => {
    const sortingParams = {
      direction: sorter.order.slice(0, -3),
      sortField: sorter.columnKey,
    }
    const newParams = {
      ...params,
      ...sortingParams,
    }
    setParams(newParams)
    fetchRoles(newParams)
  }

  const handleChangeSearch = (event: any) => {
    setSearch(event.currentTarget.value)
  }

  const handleChangePage = (page: number, pageSize: number) => {
    dispatch(actions.setCurrentPage(page))

    dispatch(
      actions.fetchRolesList({
        ...params,
        offset: (page - 1) * params.limit,
        currentPage: page,
        search: debouncedSearch,
        limit: pageSize,
      })
    )
  }

  const handleDeleteClick = (role: IModalState) => {
    setDeleteModal({ visible: true, id: role.id, name: role.name })
  }

  const handleDeleteModalClose = () => {
    setDeleteModal({ visible: false, id: 0, name: '' })
  }

  const handleDeleteRole = async () => {
    if (updatingRole) return

    await dispatch(actions.deleteRole(deleteModal.id))
    setDeleteModal({ visible: false, id: 0, name: '' })
    setTableKey(tableKey + 1)

    fetchRoles(params)
  }

  const handleCreateRole = () => {
    history.push(PATHS.ROLE_CREATE)
  }

  const columns = [
    {
      title: t('roles.Role Name'),
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      sorter: true,
    },
    {
      title: t('roles.Description'),
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: t('roles.Actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: '15%',
      render: (text: string, record: any) => (
        <div style={{textAlign:'center'}}>
          {hasPermission(permissions, PERMISSIONS.ROLES_AND_PERMISSIONS) && (
            <Tooltip title={t('roles.Edit Role')}>
              <Link to={`/roles/edit/${record.id}`} style={{ fontSize: '20px', marginRight: '10px', marginLeft:'10px' }}>
                <EditTwoTone />
              </Link>
            </Tooltip>
          )}
          {hasPermission(permissions, PERMISSIONS.ROLE_DELETE) && (
            <Tooltip title={t('roles.Delete Role')}>
              <DeleteTwoTone style={{ fontSize: '20px', marginRight: '10px', marginLeft:'10px' }} onClick={() => handleDeleteClick(record)} />
            </Tooltip>
          )}
        </div>
      ),
    },
  ]

  return (
    <>
      <h2 className="pt-10 pb-10">{t('roles.Roles')}</h2>
      <Row justify="space-between">
        <Col className="qt-search">
          <QInput
            value={search}
            onChange={handleChangeSearch}
            placeholder={t('common.Search', {ns: 'translationCommon'})}
            size="large"
            prefix={<SearchOutlined />}
          />
        </Col>
        <Col>
          <Row align="middle" gutter={30}>
            <Col>
              <h5>{t('roles.Total available roles')}: {rolesTotal}</h5>
            </Col>
            <Col>
              <QButton
                disabled={!hasPermission(permissions, PERMISSIONS.ROLE_CREATE)}
                className="qu-button-blue"
                onClick={handleCreateRole}
              >
                <PlusOutlined style={{ marginBottom: '-2px', fontSize: '25px' }} />
              </QButton>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="pt-15 pb-30">
        <QTable
          columns={columns}
          dataSource={rolesList}
          loading={rolesListIsLoading}
          scroll={{ x: 990 }}
          // @ts-ignore
          onChange={handleTableChange}
          sortDirections={['ascend', 'descend', 'ascend']}
        />
      </div>
      <Row justify="end">
        <QPagination
          size="small"
          defaultCurrent={1}
          hideOnSinglePage
          current={pages.currentPage}
          total={rolesTotal}
          onChange={handleChangePage}
        />
      </Row>

      <Modal
        key={tableKey}
        title={t('roles.Are you sure?')}
        visible={deleteModal.visible}
        onOk={handleDeleteRole}
        okText={t('common.OKAY', {ns: 'translationCommon'})}
        cancelText={t('common.Cancel', {ns: 'translationCommon'})}
        onCancel={() => {
          handleDeleteModalClose()
          setTableKey(tableKey + 1)
        }}
      >
        <p>
          {t('roles.Do you want to delete', {roleName: deleteModal.name})}
        </p>
      </Modal>
    </>
  )
}

export default RolesList
