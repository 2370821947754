import get from 'lodash/get'
import { IState } from './subscriptionHistory.types'
import * as CONSTANTS from './subscriptionHistory.constants'

const initialState: IState = {
  subscriptionHistoryListLoading: false,
  subscriptionHistoryList: {
    latestSubscription: null,
    results: [],
    total: 0,
  },
  pages: {
    totalPages: 1,
    currentPage: 1,
  },
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case CONSTANTS.RESET_SUBSCRIPTION_HISTORY_STATE:
      return initialState

    case CONSTANTS.FETCH_SUBSCRIPTION_HISTORY_REQUEST:
      return {
        ...state,
        subscriptionHistoryListLoading: true,
      }
    case CONSTANTS.FETCH_SUBSCRIPTION_HISTORY_SUCCESS:
      const subscriptionHistoryList = action.payload
      return {
        ...state,
        subscriptionHistoryList: subscriptionHistoryList,
        subscriptionHistoryListLoading: false,
      }
    case CONSTANTS.FETCH_SUBSCRIPTION_HISTORY_FAILURE:
      return {
        ...state,
        subscriptionHistoryListLoading: false,
      }
    case CONSTANTS.CANCEL_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        subscriptionHistoryListLoading: true,
      }
    case CONSTANTS.CANCEL_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subscriptionHistoryListLoading: false,
      }
    case CONSTANTS.CANCEL_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        subscriptionHistoryListLoading: false,
      }
    case CONSTANTS.SET_CURRENT_PAGE:
      return {
        ...state,
        pages: {
          ...state.pages,
          currentPage: get(action, 'payload.page'),
        },
      }
    default:
      return state
  }
}
