import { Radio } from "antd";
import {
  Col,
  QButton,
  QPieChart,
  QRangePicker,
  QTable,
  Row,
} from "quantum_components";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  EyeTwoTone,
  CheckCircleTwoTone,
  PieChartTwoTone,
  PushpinTwoTone,
  CaretUpFilled,
  CaretDownFilled,
} from "@ant-design/icons";
import lodash, { divide } from "lodash";
import { getDashboardV2Details } from "../dashboardV2.actions";
import SpinnerCenter from "src/components/Spinner/spinner";
import {
  formatCount,
  metricsAreaChartDummyData,
  metricsAreaChartDummyDataReversed,
} from "../dashboardV2.helper";
import moment from "moment";
import { FORMATS } from "src/constants";
import { getIsoDate } from "src/helpers/datesHelper";
import { renderRoundedNumber } from "src/helpers/currency";
import { CURRENCY } from "src/constants/currencies";
import i18n from "src/i18n";

import { getLocaleCost } from "src/helpers/currency";

const DashboardV2 = () => {
  const colorsCountries = ["#6BE788", "#d9573b", "#CFFDE3"];
  const colorDevices = ["#233a90", "#6BE788", "#CFFDE3"];
  const { t } = useTranslation(["translationDashboardV2"]);
  const [isLoading, setIsLoading] = useState(false);
  const [masterFilter, setMasterFilter] = useState("");
  const [masterTimeline, setMasterTimeline] = useState<any>({
    start: "",
    end: "",
  });
  const graphs = [
    "IMPRESSIONS_CLICKS_VISITS",
    "SERVICES_PERFORMANCE",
    "REVENUE",
    "LATEST_TRANSACTION",
    "TOP_PERFORMANCE_CAMPAIGNS",
  ];
  const [payload, setPayload] = useState<any>({});
  const [dashboardData, setDashboardData] = useState<any>({});

  const clicksData = lodash.get(
    dashboardData,
    "IMPRESSIONS_CLICKS_VISITS.CLICKS",
    {}
  );
  const impressionsData = lodash.get(
    dashboardData,
    "IMPRESSIONS_CLICKS_VISITS.IMPRESSIONS",
    {}
  );
  const countriesData = lodash.get(
    dashboardData,
    "IMPRESSIONS_CLICKS_VISITS.COUNTRIES",
    {}
  );
  const devicesData = lodash.get(
    dashboardData,
    "IMPRESSIONS_CLICKS_VISITS.DEVICES",
    {}
  );
  const revenueData = lodash.get(dashboardData, "REVENUE", {});
  const servicesPerformanceData = lodash.get(
    dashboardData,
    "SERVICES_PERFORMANCE",
    {}
  );
  const mostPerformedCampaignsData = lodash.get(
    dashboardData,
    "TOP_PERFORMANCE_CAMPAIGNS",
    {}
  );
  const latestTransactionData = lodash.get(
    dashboardData,
    "LATEST_TRANSACTION",
    {}
  );

  useEffect(() => {
    handleMasterFilterChange({ target: { value: "LAST_MONTH" } });
  }, []);

  const handleMasterFilterChange = (e: any) => {
    let payload = { graphs: {} };
    graphs.forEach((graph: any, index: number) => {
      payload.graphs = {
        ...payload.graphs,
        [graph]: { systemFilter: e.target.value },
      };
    });
    setMasterTimeline({ ...{ start: "", end: "" } });
    setMasterFilter(e.target.value);
    setPayload({ ...payload });
  };

  const getDashboardDetails = async () => {
    setIsLoading(true);
    const data = await getDashboardV2Details(payload);
    setDashboardData(data);
    setIsLoading(false);
  };

  useEffect(() => {
    if (lodash.isEmpty(payload)) return;
    getDashboardDetails();
  }, [payload]);

  const calculateVariance = (current: number, old: number) => {
    // if (current == 0) return 0;
    if (current == 0 && old == 0) return 0;
    if (old == 0) return 0;

    const variance = ((current - old) / old) * 100;
    return +Number(variance).toFixed(1);
  };

  const impressionsVariance =
    calculateVariance(
      lodash.get(impressionsData, "currentFilterTotal", 0),
      lodash.get(impressionsData, "lastFilterTotal", 0)
    ) ?? 0;
  const clicksVariance =
    calculateVariance(
      lodash.get(clicksData, "currentFilterTotal", 0),
      lodash.get(clicksData, "lastFilterTotal", 0)
    ) ?? 0;
  const revenueVariance =
    calculateVariance(
      lodash.get(revenueData, "currentFilterTotal", 0),
      lodash.get(revenueData, "lastFilterTotal", 0)
    ) ?? 0;

  const calculateOverviewData = () => {
    let dates: any = [];
    let data: any = [];

    const _clicksData = lodash.get(clicksData, "breakdown.currentFilter", []);
    const clickEntries = lodash
      .get(clicksData, "breakdown.currentFilter", [])
      .map((o: any) => o.date);

    const _impressionsData = lodash.get(
      impressionsData,
      "breakdown.currentFilter",
      []
    );
    const impressionEntries = lodash
      .get(impressionsData, "breakdown.currentFilter", [])
      .map((o: any) => o.date);

    dates = [...clickEntries];
    for (const impression of impressionEntries) {
      if (!dates.includes(impression)) {
        dates.push(impression);
      }
    }

    for (const date of dates) {
      const formattedDate = masterFilter === "LAST_MONTH" 
        ? `${moment(date).format(FORMATS.DATE_DM_FORMAT)} - ${moment(date).add(7, 'days').format(FORMATS.DATE_DM_FORMAT)}`
        : `${moment(date).format(FORMATS.DATE_MM_FORMAT)}`
      data.push({
        date: formattedDate,
        // date: `${moment(date).format(FORMATS.DATE_MM_FORMAT)} - ${moment(date).add(7, 'days').format(FORMATS.DATE_MM_FORMAT)}`,
        [t("Clicks")]: _clicksData.find((o: any) => o.date === date).value,
        [t("Impressions")]: _impressionsData.find((o: any) => o.date === date)
          .value,
        Clicks: _clicksData.find((o: any) => o.date === date).value,
        Impressions: _impressionsData.find((o: any) => o.date === date).value,
      });
    }

    return data;
  };

  const calculateServicesPerformanceData = () => {
    const keys: any = [];
    const colors: any = ["#233a90", "#d9573b", "#6BE788", "#CFFDE3"];
    const data: any = [];
    lodash
      .get(servicesPerformanceData, "breakdown.currentFilter", [])
      .forEach((o: any) => {
        const _keys = Object.keys(o.value);
        for (const key of _keys) {
          if (!keys.includes(key)) {
            keys.push(key);
          }
        }
        const translatedValue = {};
        Object.keys(o.value).map((key: any) => {
            // @ts-ignore
            translatedValue[t(key)] = o.value[key];
          }
        )
        data.push({
          ...translatedValue,
          date: moment(o.date).format(FORMATS.DATE_MM_FORMAT),
        });
      });
    return {
      data,
      keys,
      colors,
    };
  };

  const handleRangePicker = (dates: moment.Moment[] | null) => {
    let formattedDates: any = {};

    if (!dates) {
      return;
    }

    formattedDates = { start: dates[0] };
    if (dates[1]) formattedDates = { ...formattedDates, end: dates[1] };

    if (formattedDates.start && formattedDates.end) {
      let payload = { graphs: {} };
      graphs.forEach((graph: any, index: number) => {
        payload.graphs = {
          ...payload.graphs,
          [graph]: { start: formattedDates.start, end: formattedDates.end },
        };
      });
      setMasterTimeline(formattedDates);
      setMasterFilter("");

      setPayload({ ...payload });
    }
  };

  const downloadFile = () => {
    const data = lodash.get(revenueData, "breakdown.currentFilter", []);
    let csvData = [];

    // Add headers to csvData
    csvData.push("Date" + "\t" + " Revenue (SAR)" + "\n");

    // Traveres through each row
    const rowValues = data.map((row: any) => {
      // Get values from each row
      return `${moment(row.date).format("MMM Do, YYYY")}\t${getLocaleCost(
        row.value
      )}\n`;
    });
    // Push values in csvData
    csvData.push(rowValues.join(""));

    // Call function to download CSV file
    getCSVFile(csvData.join(""));
  };

  const getCSVFile = (csvData: any) => {
    let anchor = document.createElement("a");
    anchor.href = "data:text/csv;charset=utf-8," + encodeURI(csvData);
    anchor.target = "_blank";
    anchor.download = "qrm_revenue.csv";
    anchor.click();
  };

  return (
    <div className="dashboard-v2">
      {isLoading && <SpinnerCenter />}

      {/* header */}
      <Row
        justify="space-between"
        align={"top"}
        gutter={24}
        className="mt-20 mb-30"
      >
        <Col lg={4} md={24} className="mb-10">
          <h2>{t("Dashboard")}</h2>
        </Col>
        <Col lg={20} md={24}>
          <Row justify="end">
            {/* system filters */}
            <Col className="mb-10">
              <Radio.Group
                className="qu-radio-panel system-filters"
                buttonStyle="solid"
                onChange={handleMasterFilterChange}
                value={masterFilter}
              >
                <Radio.Button className="qu-radio-button" value="LAST_MONTH">
                  {t("Last Month")}
                </Radio.Button>
                <Radio.Button className="qu-radio-button" value="LAST_WEEK">
                  {t("Last Week")}
                </Radio.Button>
                <Radio.Button className="qu-radio-button" value="LAST_DAY">
                  {t("Last Day")}
                </Radio.Button>
              </Radio.Group>
            </Col>
            {/* calendar filter */}
            <Col className="mb-10">
              <QRangePicker
                placeholder={[t("Start Date"), t("End Date")]}
                size="large"
                className="qu-datepicker custom-filter"
                onChange={handleRangePicker}
                value={[masterTimeline.start, masterTimeline.end]}
                format={FORMATS.DATE_FORMAT}
                allowEmpty={[false, false]}
                style={{height: "45px", float: "inline-end"}}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      {/* all charts left and right */}
      {(!lodash.isEmpty(masterFilter) ||
        (!lodash.isEmpty(masterTimeline.start) &&
          !lodash.isEmpty(masterTimeline.end))) && (
        <Row gutter={24}>
          <Col md={15} sm={24} xs={24}>
            {/* Impressions & Clicks */}
            <Row gutter={24}>
              <Col xl={12} lg={24} md={24} sm={24} xs={24} className={"mb-25"}>
                <div className="chart-container">
                  <div className="title">{t("Total Impressions")}</div>
                  <div className="amount">
                    {formatCount(
                      lodash.get(impressionsData, "currentFilterTotal", 0)
                    )}
                  </div>
                  {(
                    <div
                      className={`trend text-${
                        impressionsVariance >= 0 ? "green" : "red"
                      }`}
                    >
                      {impressionsVariance >= 0 ? (
                        <CaretUpFilled color="#6BE788" />
                      ) : (
                        <CaretDownFilled color="#D9573B" />
                      )}{" "}
                      {impressionsVariance}%
                    </div>
                  )}
                  <div className={i18n.dir() === "ltr" ? "chart" : "chart-rtl"}>
                    <ResponsiveContainer width={150} height={140}>
                      <AreaChart
                        margin={{
                          bottom: 0,
                        }}
                        id={"0002"}
                        data={
                          i18n.dir() === "ltr"
                            ? metricsAreaChartDummyData
                            : metricsAreaChartDummyDataReversed
                        }
                      >
                        <Area
                          type="bump"
                          dataKey="uv"
                          stroke={
                            impressionsVariance >= 0 ? "#6BE788" : "#D9573B"
                          }
                          fill={
                            impressionsVariance >= 0 ? "#6BE788" : "#D9573B"
                          }
                          fillOpacity={0.1}
                        />
                      </AreaChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </Col>
              <Col xl={12} lg={24} md={24} sm={24} xs={24} className={"mb-25"}>
                <div className="chart-container">
                  <div className="title">{t("Total Clicks")}</div>
                  <div className="amount">
                    {formatCount(
                      lodash.get(clicksData, "currentFilterTotal", 0)
                    )}
                  </div>
                  {(
                    <div
                      className={`trend text-${
                        clicksVariance >= 0 ? "green" : "red"
                      }`}
                    >
                      {clicksVariance >= 0 ? (
                        <CaretUpFilled color="#6BE788" />
                      ) : (
                        <CaretDownFilled color="#D9573B" />
                      )}{" "}
                      {clicksVariance}%
                    </div>
                  )}
                  <div className={i18n.dir() === "ltr" ? "chart" : "chart-rtl"}>
                    <ResponsiveContainer width={150} height={140}>
                      <AreaChart
                        margin={{
                          bottom: 0,
                        }}
                        id={"0001"}
                        data={
                          i18n.dir() === "ltr"
                            ? metricsAreaChartDummyData
                            : metricsAreaChartDummyDataReversed
                        }
                      >
                        <Area
                          type="bump"
                          dataKey="uv"
                          stroke={clicksVariance >= 0 ? "#6BE788" : "#D9573B"}
                          fill={clicksVariance >= 0 ? "#6BE788" : "#D9573B"}
                          fillOpacity={0.1}
                        />
                      </AreaChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </Col>
            </Row>

            {/* Countries & Device Types */}
            <Row gutter={24}>
              
              {/* Countries */}
              <Col xl={12} lg={24} md={24} sm={24} xs={24} className={"mb-25 countries-stats"}>
                <div className="chart-container bluebg">
                  <div className="title">{t("Top Countries")}</div>
                  <Row style={{alignItems: "center"}}>
                    <Col lg={12} md={24} sm={24} xs={24}>
                      {lodash
                        .get(countriesData, "breakdown.currentFilter", [])
                        .sort((a: any, b: any) => b.value - a.value)
                        .map((c: any, index: number) => {
                          const total = lodash
                            .get(countriesData, "breakdown.currentFilter", [])
                            .reduce(
                              (acc: number, ind: any) => (acc += ind.value),
                              0
                            );
                          return (
                            <div className="pieChartLegend"
                            >
                              <div
                                style={{
                                  width: 10,
                                  height: 10,
                                  backgroundColor: colorsCountries[index],
                                  display: "inline-block",
                                  marginInlineEnd: "10px"
                                }}
                              ></div>
                              <span className="label">{c.key}</span>
                              <div className="percentage">
                                {+Number((c.value / total) * 100).toFixed(2)}%
                              </div>
                            </div>
                          );
                        })}
                    </Col>
                    <Col lg={12} md={0} sm={0} xs={0}>
                      {lodash.get(countriesData, "breakdown.currentFilter", [])
                        .length !== 0 && (
                        <div>
                          <QPieChart
                            className="metric-pie"
                            data={lodash
                              .get(countriesData, "breakdown.currentFilter", [])
                              .sort((a: any, b: any) => b.value - a.value)
                              .map((o: any) => {
                                return {
                                  name: o.key,
                                  value: o.value,
                                };
                              })}
                            // @ts-ignore
                            width={170}
                            height={170}
                            innerRadius={35}
                            outerRadius={60}
                            chartArrow={false}
                            textColor={"#f4f4f4"}
                            textFontSize={"15"}
                            weeklyReport={false}
                            colors={colorsCountries}
                          />
                        </div>
                      )}
                    </Col>
                  </Row>

                  {lodash.get(countriesData, "breakdown.currentFilter", [])
                    .length == 0 && <div className="mt-24">{t("No Data")}</div>}
                </div>
              </Col>
              
              {/* Device Types */}
              <Col xl={12} lg={24} md={24} sm={24} xs={24} className={"mb-25 devices-stats"}>
                <div className="chart-container orangebg">
                  <div className="title">{t("Top Device Types")}</div>
                  <Row style={{alignItems: "center"}}>
                    <Col lg={12} md={24} sm={24} xs={24}>
                      {lodash
                        .get(devicesData, "breakdown.currentFilter", [])
                        .sort((a: any, b: any) => b.value - a.value)
                        .map((c: any, index: number) => {
                          const total = lodash
                            .get(devicesData, "breakdown.currentFilter", [])
                            .reduce(
                              (acc: number, ind: any) => (acc += ind.value),
                              0
                            );
                          return (
                            <div className="pieChartLegend"
                            >
                              <div
                                style={{
                                  width: 10,
                                  height: 10,
                                  backgroundColor: colorDevices[index],
                                  display: "inline-block",
                                  marginInlineEnd: "10px"
                                }}
                              ></div>
                              <span className="label">{c.key}</span>
                              <div className="percentage">
                                {+Number((c.value / total) * 100).toFixed(2)}%
                              </div>
                            </div>
                          );
                        })}
                    </Col>
                    <Col lg={12} md={0} sm={0} xs={0}>
                      {lodash.get(devicesData, "breakdown.currentFilter", [])
                        .length !== 0 && (
                        <div>
                          <QPieChart
                            className="metric-pie"
                            data={lodash
                              .get(devicesData, "breakdown.currentFilter", [])
                              .sort((a: any, b: any) => b.value - a.value)
                              .map((o: any) => {
                                return {
                                  name: o.key,
                                  value: o.value,
                                };
                              })}
                            // @ts-ignore
                            width={170}
                            height={170}
                            innerRadius={35}
                            outerRadius={60}
                            chartArrow={false}
                            textColor={"#f4f4f4"}
                            textFontSize={"15"}
                            weeklyReport={false}
                            colors={colorDevices}
                          />
                        </div>
                      )}
                    </Col>
                  </Row>

                  {/* {lodash.get(devicesData, "breakdown.currentFilter", [])
                    .length !== 0 && (
                    <div>
                      <QPieChart
                        className="metric-pie"
                        data={lodash
                          .get(devicesData, "breakdown.currentFilter", [])
                          .sort((a: any, b: any) => b.value - a.value)
                          .map((o: any) => {
                            return {
                              name: o.key,
                              value: o.value,
                            };
                          })}
                        // @ts-ignore
                        width={200}
                        height={200}
                        innerRadius={55}
                        outerRadius={72}
                        chartArrow={false}
                        // textColor={"#f4f4f4"}
                        // textFontSize={"15"}
                        // weeklyReport={true}
                        colors={colorDevices}
                      />
                    </div>
                  )}
                  {lodash
                    .get(devicesData, "breakdown.currentFilter", [])
                    .sort((a: any, b: any) => b.value - a.value)
                    .map((c: any, index: number) => {
                      const total = lodash
                        .get(devicesData, "breakdown.currentFilter", [])
                        .reduce(
                          (acc: number, ind: any) => (acc += ind.value),
                          0
                        );
                      return (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 10,
                            justifyContent: "center",
                          }}
                        >
                          <div
                            style={{
                              width: 10,
                              height: 10,
                              backgroundColor: colorDevices[index],
                            }}
                          ></div>
                          {c.key}
                          <div></div>
                          <div>
                            {+Number((c.value / total) * 100).toFixed(2)}%
                          </div>
                        </div>
                      );
                    })} */}
                  {lodash.get(devicesData, "breakdown.currentFilter", [])
                    .length == 0 && <div className="mt-24">{t("No Data")}</div>}
                </div>
              </Col>
            </Row>

            {/* Overview */}
            <Row gutter={24}>
              <Col md={24} xs={24}>
                <div className="chart-container">
                  <div className="title mb-24">{t("Overview")}</div>
                  {calculateOverviewData().length == 0 && <div>{t("No Data")}</div>}
                  {calculateOverviewData().length !== 0 && (
                    <ResponsiveContainer width={"100%"} height={350}>
                      <BarChart data={calculateOverviewData()}>
                        <CartesianGrid strokeDasharray="1" />
                        <XAxis
                          dataKey={"date"}
                          tickLine={false}
                          axisLine={false}
                        />
                        <YAxis axisLine={false} tickLine={false} />
                        <Tooltip />
                        <Legend />

                        <Bar
                          barSize={12}
                          // dataKey={"Impressions"}
                          dataKey={t("Impressions")}
                          fill="#233a90"
                        />
                        <Bar
                          barSize={12}
                          dataKey={t("Clicks")}
                          // dataKey={"Clicks"}
                          fill="#d9573b"
                        />
                      </BarChart>
                    </ResponsiveContainer>
                  )}
                </div>
              </Col>
            </Row>

            {/* Revenue */}
            <Row gutter={24} className={"mt-24"}>
              <Col md={24} xs={24} className={"mb-25"}>
                <div className="chart-container">
                  <div className="title mb-24">{t("Revenue")}</div>
                  {lodash.get(revenueData, "breakdown.currentFilter", [])
                    .length == 0 && <div>{t("No Data")}</div>}
                  {lodash.get(revenueData, "breakdown.currentFilter", [])
                    .length !== 0 && (
                    <ResponsiveContainer width={"100%"} height={350}>
                      <AreaChart
                        data={lodash
                          .get(revenueData, "breakdown.currentFilter", [])
                          .map((o: any) => {
                            return {
                              // name: moment(o.date).format(
                              //   FORMATS.DATE_MM_FORMAT
                              // )
                              name: masterFilter === "LAST_MONTH" 
                                ? `${moment(o.date).format(FORMATS.DATE_DM_FORMAT)} - ${moment(o.date).add(7, 'days').format(FORMATS.DATE_DM_FORMAT)}`
                                : `${moment(o.date).format(FORMATS.DATE_MM_FORMAT)}`,
                              [t("Revenue")]: o.value,
                            };
                          })}
                      >
                        <CartesianGrid strokeDasharray="1" />
                        <XAxis
                          dataKey={"name"}
                          tickLine={false}
                          axisLine={false}
                        />
                        <YAxis axisLine={false} tickLine={false} />
                        <Tooltip />
                        <Legend />
                        <Area
                          type="bump"
                          dataKey={t("Revenue")}
                          stroke="#233a90"
                          fill="#233a90"
                          fillOpacity={0.1}
                        />
                      </AreaChart>
                    </ResponsiveContainer>
                  )}
                  {lodash.get(revenueData, "currentFilterTotal", 0) > 0 && (
                    <Row justify="space-between" className="mt-10">
                      <Col>
                        <label>
                          <div
                            className="text-bold mr-10 mt-15 text-size-16"
                            style={{ display: "inline-block" }}
                          >
                            SAR{" "}
                            {formatCount(
                              lodash.get(revenueData, "currentFilterTotal", 0)
                            )}
                          </div>
                          <span className="text-size-14">
                            {revenueVariance !== 0 && (
                              <label
                                className={`trend text-${
                                  revenueVariance > 0 ? "green" : "red"
                                }`}
                              >
                                {revenueVariance > 0 ? (
                                  <CaretUpFilled color="#6BE788" />
                                ) : (
                                  <CaretDownFilled color="#D9573B" />
                                )}{" "}
                                {revenueVariance}%
                              </label>
                            )}
                          </span>
                        </label>
                      </Col>
                      <Col>
                        <span>
                          <QButton
                            onClick={() => downloadFile()}
                            className="qu-button-outline-green"
                          >
                            {t(`Download CSV`)}
                          </QButton>
                        </span>
                      </Col>
                    </Row>
                  )}
                </div>
              </Col>
            </Row>
          </Col>
          <Col md={9} sm={24} xs={24}>
            {/* most performed campaigns */}
            <Row gutter={24}>
              <Col md={24} xs={24}>
                <div className="chart-container">
                  <div className="title">{t("Most Performed Campaigns")}</div>
                  <div className="subtitle">
                    {t(
                      "Summary of the top performing campaigns based on statistics"
                    )}
                  </div>

                  {lodash
                    .get(
                      mostPerformedCampaignsData,
                      "breakdown.currentFilter",
                      []
                    )
                    .map((campaign: any, index: number) => {
                      return (
                        <div key={index}>
                          <hr className="form__hr" />
                          <div className="info text-bold">
                            {campaign?.campaignName}
                          </div>
                          <div className="subsubtitle mt-8">
                            <PushpinTwoTone
                              twoToneColor="#52c41a"
                              style={{ marginRight: "10px" }}
                            />
                            {t("Published on")}{" "}
                            {moment(campaign?.campaignCreatedDate).format(
                              FORMATS.DATE_LL_FORMAT
                            )}
                          </div>
                          <div className="flex-row mt-15 mb-10">
                            <div className="subtitle">
                              <PieChartTwoTone
                                twoToneColor="#52c41a"
                                style={{
                                  marginRight: "10px",
                                  background: "rgb(171 226 147 / 20%)",
                                  padding: "5px",
                                  borderRadius: "5px",
                                }}
                              />
                              {t("Clicks")}
                            </div>
                            <div className="text-bold">
                              {formatCount(campaign?.clicks)}
                            </div>
                          </div>
                          <div className="flex-row mb-10">
                            <div className="subtitle">
                              <EyeTwoTone
                                twoToneColor="#52c41a"
                                style={{
                                  marginRight: "10px",
                                  background: "rgb(171 226 147 / 20%)",
                                  padding: "5px",
                                  borderRadius: "5px",
                                }}
                              />
                              {t("Impressions")}
                            </div>
                            <div className="text-bold">
                              {formatCount(campaign?.impressions)}
                            </div>
                          </div>
                        </div>
                      );
                    })}

                  {lodash.get(
                    mostPerformedCampaignsData,
                    "breakdown.currentFilter",
                    []
                  ).length == 0 && (
                    <div className="mt-24 text-gray">{t("No Data")}</div>
                  )}
                </div>
              </Col>
            </Row>

            {/* services performance */}
            {/*<Row gutter={24} className={"mt-24"}>
                          <Col md={24} xs={24}>
                            <div className="chart-container">
                              <div className="title mb-24">
                                {t("Ads Services Performance")}
                              </div>
                              {calculateServicesPerformanceData().data.length == 0 && (
                                <div>{t("No Data")}</div>
                              )}
                              {calculateServicesPerformanceData().data.length !== 0 && (
                                <ResponsiveContainer width={"100%"} height={350}>
                                  <LineChart
                                    data={calculateServicesPerformanceData().data}
                                    margin={{
                                      left: -10,
                                      right: 10,
                                      top: 5,
                                    }}
                                  >
                                    <CartesianGrid strokeDasharray="1" />
                                    <XAxis
                                      dataKey={"date"}
                                      tickLine={false}
                                      axisLine={false}
                                    />
                                    <YAxis axisLine={false} tickLine={false} />
                                    <Tooltip />
                                    <Legend />
                                    {calculateServicesPerformanceData().keys?.map(
                                      (k: any, index: number) => {
                                        return (
                                          <Line
                                            strokeWidth={3}
                                            dataKey={t(k)}
                                            stroke={
                                              calculateServicesPerformanceData().colors[
                                                index
                                              ]
                                            }
                                          />
                                        );
                                      }
                                    )}
                                  </LineChart>
                                </ResponsiveContainer>
                              )}
                            </div>
                          </Col>
                        </Row>*/}

            {/* latest transactions */}
            <Row gutter={24} className={"mt-24"}>
              {/* Latest Transactions */}
              <Col md={24} xs={24}>
                <div className="chart-container">
                  <div className="title mb-24">{t("Latest Transactions")}</div>
                  <QTable
                    columns={[
                      {
                        title: t("Campaign Name"),
                        dataIndex: null,
                        key: null,
                        width: "45%",
                        render: (element: any, row: any) => {
                          return (
                            <div>
                              <div className="text-bold text-capitalize">
                                {row?.campaignName}
                              </div>
                              <div className="subtitle">
                                {moment(row?.campaignCreatedDate).format(
                                  FORMATS.DATE_LL_FORMAT
                                )}
                              </div>
                            </div>
                          );
                        },
                      },
                      {
                        title: t("Total Spend"),
                        dataIndex: "totalSpend",
                        key: "totalSpend",
                        width: "30%",
                        render: (element: any, row: any) => {
                          return (
                            <label
                              style={{
                                color: `${
                                  row?.status === "PENDING"
                                    ? "#d9573b"
                                    : "#6BE788"
                                }`,
                              }}
                            >
                              {renderRoundedNumber(
                                Number(element),
                                CURRENCY.SAR
                              )}
                            </label>
                          );
                        },
                      },
                      {
                        title: t("Status"),
                        dataIndex: "status",
                        key: "status",
                        width: "25%",
                        render: (element: string, row: any) => {
                          return (
                            <label
                              className="text-capitalize"
                              style={{
                                padding: "2px 10px",
                                background: "#fff",
                                whiteSpace: "nowrap",
                                // color: "#6BE788",
                                color: `${
                                  row?.status === "PENDING"
                                    ? "#d9573b"
                                    : "#6BE788"
                                }`,
                                border: `1px solid ${
                                  row?.status === "PENDING"
                                    ? "#d9573b"
                                    : "#6BE788"
                                }`,
                                borderRadius: "5px",
                              }}
                            >
                              {t(element)}
                            </label>
                          );
                        },
                      },
                    ]}
                    dataSource={lodash.get(
                      latestTransactionData,
                      "breakdown.currentFilter",
                      []
                    )}
                    scroll={{ x: "auto" }}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default DashboardV2;
