import React, { Fragment, ReactElement, useEffect, useState } from "react";
import {
  ICartItem,
  ICartList,
  ICartServiceItem,
  IProposal,
} from "../../brief.types";
import {
  QButton,
  ServicesSummary,
  IconsCommon,
  Modal,
  QBriefCartItem,
  QBriefCartProduct,
} from "quantum_components";
import { Descriptions } from "antd";
import {
  BRIEF_PRINT_ID,
  DISTRIBUTION_TYPE,
  MEDIA_TYPES,
  MIN_LOGISTIC_PRICE_TEXT,
  BRIEF_STATUS,
  BRIEF_STEPS,
  BRIEF_PAYMENT_STATUS,
} from "../../../../constants/brief";
import { renderRoundedNumber } from "../../../../helpers/currency";
import { isMobile } from "react-device-detect";
import { REQUEST_STATUS } from "../../../../constants/request";
import { useHistory, useParams } from "react-router-dom";
import {
  getBrief,
  getBriefSettings,
  getBriefView,
  getProposalLoading,
  getCopackingPrice,
  getPrintingPrice,
} from "../../brief.selectors";
import { useDispatch, useSelector } from "react-redux";
import { COLORS } from "../../../../constants/colors";
import {
  getBriefProposalPdf,
  togglePrintClass,
} from "../../../../utils/getScreenPage";
import { actions } from "../../brief.module";
import EmailProposalForm from "../../../../components/BriefView/EmailProposalForm.component";
import { hasPermission } from "../../../../helpers/permission";
import { PERMISSIONS } from "../../../../constants/permission";
import {
  getCurrency,
  getCurrentUserPermissionActions,
  getCurrentUser,
} from "../../../core/core.selectors";
import { getBase64 } from "../../../../utils/getBase64";
import { DynamicPricingTypeLabels, PRICING_MODEL } from "src/constants/profile";
import { getCampaignDetails } from "src/modules/campaign/campaign.selectors";
import { getRequestDetails } from "src/modules/partner/requests/request.selectors";

import BriefPayment from "./Brief.Payment";
import { IBillPaymentUploadTypes } from "../../../bills/bills.types";
import { useTranslation } from "react-i18next";
import { snakeCase, get } from "lodash";

import {
  TAX_TREATMENT,
} from 'src/constants/clientProfile'

const checkIcon = (
  <IconsCommon.IconCheck className="i" color={COLORS.LIGHT_GREEN} />
);

const uncheckIcon = (
  <svg className="i" width="16" height="16" viewBox="0 0 515.556 515.556">
    <path d="m257.778 0c-142.137 0-257.778 115.641-257.778 257.778s115.641 257.778 257.778 257.778 257.778-115.641 257.778-257.778-115.642-257.778-257.778-257.778zm113.926 326.141-45.564 45.564-68.362-68.362-68.362 68.362-45.564-45.564 68.362-68.362-68.362-68.362 45.564-45.564 68.362 68.362 68.362-68.362 45.564 45.564-68.362 68.362s68.362 68.362 68.362 68.362z" />
  </svg>
);

const closeIcon = <IconsCommon.IconClose />;

const arrowIcon = (
  <svg viewBox="0 0 511.993 511.993" width="14">
    <g>
      <path
        d="M487.292,235.408H20.59c-11.372,0-20.59,9.224-20.59,20.59c0,11.366,9.217,20.59,20.59,20.59h466.702
			c11.372,0,20.59-9.217,20.59-20.59C507.882,244.625,498.665,235.408,487.292,235.408z"
      />
    </g>
    <g>
      <path
        d="M505.96,241.434L304.187,39.653c-8.044-8.037-21.07-8.037-29.114,0c-8.044,8.044-8.044,21.084,0,29.121l187.216,187.223
			L275.073,443.221c-8.044,8.037-8.044,21.077,0,29.114c4.022,4.022,9.286,6.033,14.557,6.033s10.535-2.011,14.557-6.033
			l201.773-201.78C514.004,262.511,514.004,249.47,505.96,241.434z"
      />
    </g>
  </svg>
);

interface Props {
  cart: ICartList;
  setCurrentStep: (step: BRIEF_STEPS) => void;
  onEdit: (id: number) => void;
  viewMode?: boolean;
  hideProposalButtons?: boolean;
  hidePaymentSection?: boolean;
  hideSubmitPaymentButton?: boolean;
  onPaymentFilesChange?: (
    files: { files: File[]; filesType: IBillPaymentUploadTypes }[]
  ) => void;
}

export default function BriefCartSummary({
  cart,
  setCurrentStep,
  onEdit,
  viewMode,
  hideProposalButtons,
  hidePaymentSection = false,
  hideSubmitPaymentButton = false,
  onPaymentFilesChange,
}: Props): ReactElement {
  const { t } = useTranslation(["translationABrief", "translationDashboard", "translation"]);

  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams<{id: any}>();
  const briefId = id;
  const settings = useSelector(getBriefSettings);
  const emailProposalLoading = useSelector(getProposalLoading);
  const permissions = useSelector(getCurrentUserPermissionActions);
  const currency = useSelector(getCurrency);
  const viewBrief = useSelector(getBriefView);
  const brief = useSelector(getBrief);
  const copackingPrice = useSelector(getCopackingPrice);
  const printingPrice = useSelector(getPrintingPrice);
  const campaignDetails = useSelector(getCampaignDetails);
  const requestDetails = useSelector(getRequestDetails);
  const currentUser = useSelector(getCurrentUser)

  const MINIMUM_DISTRIBUTION_LOGISTIC_COUNT =
    settings.MINIMUM_DISTRIBUTION_LOGISTIC_COUNT;
  const [openProposalModal, setOpenProposalModal] = useState<boolean>(false);
  const [generatingPdf, setGeneratingPdf] = useState<boolean>(false);
  const [cartItems, setCartItems] = useState<ICartItem[]>([]);
  const [totalQuantities, setTotalQuantities] = useState(0);

  const briefPaymentStatus: BRIEF_PAYMENT_STATUS | undefined = viewBrief
    ?.payment?.status as BRIEF_PAYMENT_STATUS;

  const disabledButton = emailProposalLoading;

  useEffect(() => {
    async function calculateCart() {
      getLogos();
      let totalQuantity = 0;
      cart.additional.items.map((ele: any) => {
        ele.services.map((ele1: any) => {
          if (ele1.type === MEDIA_TYPES.DISTRIBUTION) {
            totalQuantity = totalQuantity + ele1.quantity;
          }
        });
      });
      setTotalQuantities(totalQuantity);
    }
    calculateCart();
  }, [cart]);

  const getUploadsBase64 = async (item: ICartItem) => {
    if (!item.companyLogoUrl) return "";
    return Promise.resolve(getBase64(item.companyLogoUrl));
  };

  const getLogos = async () => {
    const items = cart.additional.items;
    if (!items) return;

    const list = items.map((item: ICartItem) => {
      getUploadsBase64(item).then((data: string) => {
        item.companyLogoUrl = data;
      });
      return item;
    });

    setCartItems(list);
  };

  const handleEdit = (partnerId: number) => {
    onEdit(partnerId);
  };

  const renderServiceItem = (service: ICartServiceItem, priceModel: string) => {
    const label = (
      <span className="label-column">
        <span>
          {service.productSubType
            ? service.name +
            ": " +
            DynamicPricingTypeLabels[service.productSubType]
            : t(
              `dashboard.service-types-labels.${service.name}`,
              service.name,
              { ns: "translationDashboard" }
            )}
        </span>
        {service.type === MEDIA_TYPES.DISTRIBUTION ? (
          <small>{`${renderRoundedNumber(service.amount, currency)} x ${service.quantity
            }`}</small>
        ) : window.location.pathname.includes("view") ? (
          (!viewBrief.marketingObjective ||
            priceModel === PRICING_MODEL.DURATION) && (
            <small>{`${renderRoundedNumber(service.amount, currency)} x ${service.quantity
              }`}</small>
          )
        ) : (
          (!brief.BRIEF_MEDIA_BUY.marketingObjective ||
            priceModel === PRICING_MODEL.DURATION) && (
            <small>{`${renderRoundedNumber(service.amount, currency)} x ${service.quantity
              }`}</small>
          )
        )}
      </span>
    );

    return (
      <Descriptions.Item key={service.name} label={label}>
        {
          <div
            style={{
              textDecoration:
                brief.promotionId || viewBrief.promotionId
                  ? "line-through"
                  : "inherit",
              color:
                brief.promotionId || viewBrief.promotionId
                  ? "lightgray"
                  : "inherit",
            }}
          >
            {renderRoundedNumber(service.total, currency)}
          </div>
        }
        {(brief.promotionId || viewBrief.promotionId) && (
          <div>{renderRoundedNumber(service.offerPrice, currency)}</div>
        )}
      </Descriptions.Item>
    );
  };

  const renderLogisticPriceLabel = (
    service: ICartServiceItem,
    item: ICartItem
  ) => {
    const isSample = service.name === DISTRIBUTION_TYPE.PRODUCT_SAMPLE;
    const price = isSample
      ? item.logisticSamplePrice
      : item.logisticInsertPrice;
    const priceText = renderRoundedNumber(price, currency);

    const rate = isSample
      ? renderRoundedNumber(item.logisticSampleRate, currency)
      : renderRoundedNumber(item.logisticInsertRate, currency);

    const quantity = service.quantity ? service.quantity : 0;
    const description =
      quantity < MINIMUM_DISTRIBUTION_LOGISTIC_COUNT
        ? MIN_LOGISTIC_PRICE_TEXT
        : `${rate} x ${quantity}`;

    const label = (
      <span className="label-column">
        <span>{t(`logistic_price`)}</span>
        <small>{description}</small>
      </span>
    );

    return (
      <Descriptions.Item key="Logistic Price" label={label}>
        {priceText}
      </Descriptions.Item>
    );
  };

  const renderStickerCostItem = (
    service: ICartServiceItem,
    item: ICartItem
  ) => {
    const label = (
      <span className="label-column">
        <span>{t(`sticker_cost`)}</span>
        <small>{`${renderRoundedNumber(item.stickerCost, currency)} x ${service.quantity
          }`}</small>
      </span>
    );

    return (
      <Descriptions.Item key="Sticker Cost" label={label}>
        {renderRoundedNumber(item.stickerPrice, currency)}
      </Descriptions.Item>
    );
  };

  const renderAdditional = (amount: number | undefined, name: string, taxes?: boolean) => {
    if (!amount) {
      return;
    }

    const price = renderRoundedNumber(amount, currency);

    return (
      <div className="SSUnit__descriptions">
        <Descriptions column={1}>
          <Descriptions.Item label={name}>
            <span className="label-column text-right">
              {name === "Discount" ? (
                <span className="text-red">- {price}</span>
              ) : (
                <span>{price}</span>
              )}
              {taxes && <small>{t("plus_taxes")}</small>}
            </span>
          </Descriptions.Item>
        </Descriptions>
      </div>
    );
  };

  const handleFindPartner = () => {
    history.push(`/briefs/rejected/${briefId}`);
  };

  const handleSendProposal = async (data: IProposal) => {
    togglePrintClass(true);
    const markup = document.getElementById(BRIEF_PRINT_ID);
    if (!markup) return;

    const pdf = await getBriefProposalPdf(markup);
    dispatch(actions.emailProposal(id, data, pdf));
    setOpenProposalModal(false);
    togglePrintClass(false);
  };

  const handleDownloadProposal = async () => {
    setGeneratingPdf(true);
    togglePrintClass(true);

    const markup = document.getElementById(BRIEF_PRINT_ID);
    if (!markup) return;

    const pdf = await getBriefProposalPdf(markup);
    pdf.save("Brief Proposal.pdf");

    setGeneratingPdf(false);
    togglePrintClass(false);
  };

  const handleEmailModalOpen = () => {
    setOpenProposalModal(true);
  };

  const handleEmailModalClose = () => {
    setOpenProposalModal(false);
  };

  const renderCartAdditionalChargesItemCoPacking = (
    item: any,
    name: string,
    totalPrice: number,
    minQty: number,
    servicePackagingQuantity: any
  ) => {
    if (servicePackagingQuantity) {
      const key = item.name;
      const total = renderRoundedNumber(
        item.rate *
        (minQty > totalQuantities
          ? Math.floor(minQty / servicePackagingQuantity)
          : Math.floor(totalQuantities / servicePackagingQuantity)),
        item.currency
      );
      const description = `${item.rate} ${item.currency} x ${minQty > totalQuantities
        ? Math.floor(minQty / servicePackagingQuantity) +
        ` ${t("minimum_bundles")}`
        : Math.floor(totalQuantities / servicePackagingQuantity)
        }`;
      const bundles = `${minQty > totalQuantities
        ? Math.floor(minQty / servicePackagingQuantity)
        : Math.floor(totalQuantities / servicePackagingQuantity)
        } bundles (${servicePackagingQuantity} ${t(`units_bundle`)})`;

      return (
        <QBriefCartItem
          key={key}
          summaryPrice={total}
          summaryName={name}
          summaryDescription={description}
          hideRemove={true}
          summarySubName={item.name}
          bundlesDescription={bundles}
        />
      );
    }
  };

  const renderCartAdditionalChargesItem = (
    item: any,
    name: string,
    totalPrice: number,
    minQty: number
  ) => {
    const key = item.name;
    const total = renderRoundedNumber(totalPrice, item.currency);
    const description = `${item.rate} ${item.currency} x ${totalQuantities &&
      (minQty > totalQuantities
        ? minQty + ` ${t("min_quantity")}`
        : totalQuantities)
      }`;

    return (
      <QBriefCartItem
        key={key}
        summaryPrice={total}
        summaryName={name}
        summaryDescription={description}
        hideRemove={true}
        summarySubName={item.name}
      />
    );
  };

  const translateDiscountText = (discountText: any) => {
    // console.log(discountText)

    if(!String(discountText).includes('%')){
      return  t("discount_applied",{value:discountText.split(' ')[0]})
    }

    let buffer = new Array()
    buffer = discountText.split("%");

    if (buffer[0] == 0) return t("brief.cart." + snakeCase(buffer[1]), { ns: 'translation' });

    return buffer[0] + "% " + t("brief.cart." + snakeCase(buffer[1]), { ns: 'translation' });
  }

  const taxes = (get(viewBrief, 'payment.onlinePayment') && currentUser.partnerData.taxTreatment === TAX_TREATMENT.NON_VAT_REGISTERED) || (currentUser.partnerData.taxTreatment === TAX_TREATMENT.VAT_REGISTERED && !cart.additional.taxes ) ? cart.additional.servicesTotal * 0.15 : cart.additional.taxes
  const cartTotal = (get(viewBrief, 'payment.onlinePayment') && currentUser.partnerData.taxTreatment === TAX_TREATMENT.NON_VAT_REGISTERED) || (currentUser.partnerData.taxTreatment === TAX_TREATMENT.VAT_REGISTERED && !cart.additional.taxes ) ? cart.additional.cartTotal * 1.15 : cart.additional.cartTotal

  const content = (
    <Fragment>
      <div className="SS__category">
        <h4 className="SS__category-title">{t(`e_commerce`)}</h4>
        {cartItems.map((item: ICartItem) => {
          const distributions = item.services.filter(
            (s: ICartServiceItem) => s.type === MEDIA_TYPES.DISTRIBUTION
          );
          const media = item.services.filter(
            (s: ICartServiceItem) => s.type !== MEDIA_TYPES.DISTRIBUTION
          );

          const isDistributionAccepted = distributions.some(
            (s: ICartServiceItem) => s.requestStatus === REQUEST_STATUS.ACCEPTED
          );
          const isDistributionRejected = distributions.some(
            (s: ICartServiceItem) => s.requestStatus === REQUEST_STATUS.REJECTED
          );
          const isMediaBuyAccepted = media.some(
            (s: ICartServiceItem) => s.requestStatus === REQUEST_STATUS.ACCEPTED
          );
          const isMediaBuyRejected = media.some(
            (s: ICartServiceItem) => s.requestStatus === REQUEST_STATUS.REJECTED
          );

          return (
            <div key={item.partnerId} className="SSUnit">
              <div className="SSUnit__heading">
                <div className="SSUnit__title-frame">
                  {item.companyLogoUrl && (
                    <div className="SSUnit__thumb">
                      <img src={item.companyLogoUrl} alt={item.companyName} />
                    </div>
                  )}
                  <h4 className="SSUnit__title">{item.companyName}</h4>
                </div>
                {!viewMode && (
                  <span
                    onClick={() => handleEdit(item.partnerId)}
                    className="SSUnit__edit"
                    data-html2canvas-ignore
                  >
                    {t(`edit`)}
                  </span>
                )}
              </div>
              <div className="SSUnit__content">
                {distributions.length > 0 && (
                  <div
                    className={`SSUnit__descriptions ${isDistributionRejected && "rejected"
                      }`}
                  >
                    <Descriptions column={1} title={t(`distribution`)}>
                      {distributions.map((service: ICartServiceItem) => (
                        <React.Fragment key={item.partnerId + service.name}>
                          {renderServiceItem(service, item.priceModel)}
                          {renderLogisticPriceLabel(service, item)}
                          {!!item.stickerPrice &&
                            service.name === DISTRIBUTION_TYPE.PRODUCT_SAMPLE &&
                            renderStickerCostItem(service, item)}
                        </React.Fragment>
                      ))}
                    </Descriptions>
                    {isDistributionAccepted && (
                      <div className="SSUnit__sLabel accepted">
                        {checkIcon}
                        {t(`accepted`)}
                      </div>
                    )}
                    {isDistributionRejected && (
                      <React.Fragment>
                        <div className="SSUnit__sLabel rejected">
                          <i className="i">{uncheckIcon}</i>
                          {t(`rejected`)}
                        </div>
                        <QButton
                          disabled={
                            !hasPermission(
                              permissions,
                              PERMISSIONS.BRIEF_CREATE_AND_UPDATE
                            )
                          }
                          onClick={handleFindPartner}
                          className="SSUnit__btn"
                          type="primary"
                          block
                          data-html2canvas-ignore
                        >
                          {t(`find_a_partner`)} {arrowIcon}
                        </QButton>
                      </React.Fragment>
                    )}
                  </div>
                )}
                {media.length > 0 && (
                  <div
                    className={`SSUnit__descriptions ${isMediaBuyRejected && "rejected"
                      }`}
                  >
                    <Descriptions column={1} title={t(`media_buy`)}>
                      {media.map((service: ICartServiceItem) =>
                        renderServiceItem(service, item.priceModel)
                      )}
                    </Descriptions>
                    {isMediaBuyAccepted && (
                      <div className="SSUnit__sLabel accepted">
                        {checkIcon}
                        {t(`accepted`)}
                      </div>
                    )}
                    {isMediaBuyRejected && (
                      <React.Fragment>
                        <div className="SSUnit__sLabel rejected">
                          <i className="i">{uncheckIcon}</i>
                          {t(`rejected`)}
                        </div>
                        <QButton
                          disabled={
                            !hasPermission(
                              permissions,
                              PERMISSIONS.BRIEF_CREATE_AND_UPDATE
                            )
                          }
                          onClick={handleFindPartner}
                          className="SSUnit__btn"
                          type="primary"
                          block
                          data-html2canvas-ignore
                        >
                          {t(`find_a_partner`)} {arrowIcon}
                        </QButton>
                      </React.Fragment>
                    )}
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>

      {window.location.pathname.includes("campaigns/") &&
        totalQuantities > 0 &&
        (campaignDetails?.brief?.copackingPrice ||
          campaignDetails?.brief.printingPrice) && (
          <div className="selection-summary__cont additional_charges campaign_additional_charges">
            <QBriefCartProduct
              productName={t(`additional_charges`)}
              onEdit={() => null}
              isAdditional={true}
            />
            {campaignDetails?.brief?.copackingPrice &&
              renderCartAdditionalChargesItemCoPacking(
                campaignDetails?.brief?.copackingPrice,
                t(`copacking_price`),
                cart.additional.copackingTotal,
                cart.additional.copackingMinQuantity,
                campaignDetails?.brief?.servicePackagingQuantity
              )}
            {campaignDetails?.brief.printingPrice &&
              renderCartAdditionalChargesItem(
                campaignDetails?.brief.printingPrice,
                t(`printing_price`),
                cart.additional.printingTotal,
                cart.additional.printingMinQuantity
              )}
          </div>
        )}

      <div className="SS__total">
        {!!cart.couponCode && (
          <div className="qu-discount-badge mb-15">
            {checkIcon}{" "}
            {cart?.additional?.discountText === '0% Discount Applied' ? t("brief.cart.Discount_Applied", { ns: 'translation' }) : translateDiscountText(cart?.additional?.discountText)}
          </div>
        )}
        {renderAdditional(cart.additional.servicesTotal, t(`services_total`))}
        {renderAdditional(cart.additional.miscellaneous, t(`misc_fee`,'Misc. Fee'))}
        {!!cart.couponCode &&
          renderAdditional((brief.promotionId || viewBrief.promotionId) ? cart.additional.promotionCouponFee : cart.additional.couponFee, t(`discount`))}
        {renderAdditional(
          cart.additional.managementFee,
          `${t(`management_fee`)} (${window.location.pathname.includes("campaigns")
            ? campaignDetails?.cart?.additional.managementFeeRate ||
            campaignDetails?.brief.managementFee
            : window.location.pathname.includes("requests/")
              ? requestDetails?.cart?.additional.managementFeeRate ||
              requestDetails?.brief?.managementFee
              : cart?.additional.managementFeeRate || settings.managementFee
          }%)`
        )}
        {renderAdditional((brief.promotionId || viewBrief.promotionId) ? (cart.additional.servicesTotal - cart.additional.promotionCouponFee) : cart.additional.subTotal, t(`sub_total`))}
        {renderAdditional((brief.promotionId || viewBrief.promotionId) ? cart.additional.promotionTaxes : taxes, t(`taxes`))}
        {renderAdditional((brief.promotionId || viewBrief.promotionId) ? cart.additional.promotionCartTotal : cartTotal, t(`total`), true)}
      </div>
    </Fragment>
  );

  const emailModalHeader = <h3>{t(`email_this_proposal`)}</h3>;

  return isMobile ? (
    <>
      {!hidePaymentSection &&
        viewBrief &&
        viewBrief.status !== BRIEF_STATUS.DRAFT && (
          <BriefPayment
            briefView={viewBrief}
            onFilesChange={onPaymentFilesChange}
            hideSubmitButton={hideSubmitPaymentButton}
          />
        )}
      <div className="qu-view-card m-10">{content}</div>
    </>
  ) : (
    <>
      {!hidePaymentSection &&
        viewBrief &&
        viewBrief.status !== BRIEF_STATUS.DRAFT && (
          <ServicesSummary
            title={
              briefPaymentStatus &&
                [
                  BRIEF_PAYMENT_STATUS.PROCESSING,
                  BRIEF_PAYMENT_STATUS.PAID,
                ].includes(briefPaymentStatus)
                ? t(`payment_info`)
                : t(`make_payment`)
            }
            className="mb-20"
          >
            <BriefPayment
              briefView={viewBrief}
              onFilesChange={onPaymentFilesChange}
              hideSubmitButton={hideSubmitPaymentButton}
            />
          </ServicesSummary>
        )}
      <ServicesSummary title={t(`services_summary`)}>{content}</ServicesSummary>
      {!hideProposalButtons && (
        <>
          <QButton
            loading={disabledButton}
            disabled={disabledButton}
            onClick={handleEmailModalOpen}
            block
            className="qu-button-soft justify-between mt-20"
            data-html2canvas-ignore
          >
            {t(`email_proposal`)} <IconsCommon.IconSend />
          </QButton>
          <QButton
            loading={generatingPdf}
            disabled={generatingPdf}
            onClick={handleDownloadProposal}
            block
            className="qu-button-soft justify-between mt-20"
            data-html2canvas-ignore
          >
            {t(`download_proposal`)} <IconsCommon.IconDownload />
          </QButton>

          <Modal
            wrapClassName="qu-modal"
            title={emailModalHeader}
            width={400}
            closeIcon={closeIcon}
            visible={openProposalModal}
            onCancel={handleEmailModalClose}
            footer={false}
          >
            <EmailProposalForm onSend={handleSendProposal} />
          </Modal>
        </>
      )}
    </>
  );
}
