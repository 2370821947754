import React, { useEffect, useState } from 'react'

import { useSelector } from 'react-redux'
import { useReduxDispatch } from '../../../helpers'
import { IReferralListParams, IReferralTable } from '../referral.types'
import useDebounce from '../../../hooks/useDebounce'
import * as actions from '../referral.actions'

import { Row, Col, QTable, QInput, QSelect, QPagination, QRangePicker } from 'quantum_components'
import { SearchOutlined } from '@ant-design/icons'
import { IOptionForSelect } from '../../brief/brief.types'
import { getIsReferralListLoading, getPages, getReferralList, getReferralTotal } from '../referral.selectors'
import { REFERRAL_STATUS, REFERRAL_STATUSES, referralStatusesLabel } from '../../../constants/referral'
import { FORMATS } from '../../../constants'

import { getIsoDate } from '../../../helpers/datesHelper'

const columns = [
  {
    title: 'Client Name',
    dataIndex: 'clientName',
    key: 'clientName',
    fixed: 'left',
    sorter: true,
    width: '30%',
    render: (text: string, record: IReferralTable) => {
      return <p className="mb-0">{record.userFullName}</p>
    },
  },
  {
    title: 'Invited Email',
    dataIndex: 'email',
    key: 'email',
    sorter: true,
    width: '25%',
  },
  {
    title: 'Date of referral',
    dataIndex: 'startedDate',
    key: 'startedDate',
    sorter: true,
    width: '15%',
  },
  {
    title: 'Redeemed',
    dataIndex: 'redeemed',
    key: 'redeemed',
    width: '12%',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: '18%',
    fixed: 'right',
    render: (status: REFERRAL_STATUS) => {
      return (
        <div>
          <span className={`status-label ${referralStatusesLabel[status] && referralStatusesLabel[status].classStyle}`}>
            {referralStatusesLabel[status] ? referralStatusesLabel[status].label : REFERRAL_STATUS.IN_PROGRESS}
          </span>
        </div>
      )
    },
  },
]

const ReferralList = () => {
  const dispatch = useReduxDispatch()
  const referralsList = useSelector(getReferralList)
  const referralsTotal = useSelector(getReferralTotal)
  const referralsListIsLoading = useSelector(getIsReferralListLoading)
  const pages = useSelector(getPages)

  const [search, setSearch] = useState('')

  const [params, setParams] = useState<IReferralListParams>({
    currentPage: 1,
    limit: 10,
    offset: 0,
    direction: 'desc',
    sortField: 'createdAt',
  })

  const debouncedSearch = useDebounce(search, 500)

  const fetchReferrals = (queryParams = params) => {
    dispatch(actions.fetchReferralsList({ ...queryParams, search: debouncedSearch }))
  }

  useEffect(() => {
    fetchReferrals()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch])

  // @ts-ignore
  const handleTableChange = (pagination, filters, sorter) => {
    const sortingParams = {
      direction: sorter.order.slice(0, -3),
      sortField: sorter.columnKey,
    }
    const newParams = {
      ...params,
      ...sortingParams,
    }
    setParams(newParams)
    fetchReferrals(newParams)
  }

  const handleChangeSearch = (event: any) => {
    setSearch(event.currentTarget.value)
  }

  const handleChangePage = (page: number, pageSize: number) => {
    dispatch(actions.setCurrentPage(page))

    dispatch(
      actions.fetchReferralsList({
        ...params,
        offset: (page - 1) * params.limit,
        currentPage: page,
        search: debouncedSearch,
        limit: pageSize,
      })
    )
  }

  const handleChangeStatus = (status: REFERRAL_STATUS[]) => {
    const newParams = { ...params, offset: 0, status }
    fetchReferrals(newParams)
    setParams(newParams)
  }

  const handleChangeRedeem = (isRedeemed: boolean) => {
    const newParams = { ...params, offset: 0, isRedeemed }
    fetchReferrals(newParams)
    setParams(newParams)
  }

  const handleDatePicker = (range: any) => {
    const start = range && getIsoDate(range[0].startOf('day'))
    const end = range && getIsoDate(range[1].endOf('day'))

    const newParams = { ...params, offset: 0, start, end }
    fetchReferrals(newParams)
    setParams(newParams)
  }
  const statusOptions: IOptionForSelect[] = REFERRAL_STATUSES.map((status: REFERRAL_STATUS) => {
    return { label: status, value: status }
  })

  const redeemOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ]

  return (
    <>
      <h2 className="pt-10 pb-10">Referral Signups</h2>
      <Row gutter={16} justify="space-between">
        <Col xs={24} md={8}>
          <QInput
            className="full-width"
            value={search}
            onChange={handleChangeSearch}
            placeholder="Search"
            size="large"
            prefix={<SearchOutlined />}
          />
        </Col>
        <Col xs={24} md={6}>
          <QRangePicker format={FORMATS.DATE_FORMAT} onChange={handleDatePicker} className="full-width" size="large" />
        </Col>
        <Col xs={24} md={3}>
          <QSelect
            className="full-width"
            showArrow
            onChange={handleChangeRedeem}
            size="large"
            placeholder="Redeem"
            options={redeemOptions}
            value={params.isRedeemed}
            allowClear
          />
        </Col>
        <Col xs={24} md={4}>
          <QSelect
            className="full-width"
            showArrow
            maxTagTextLength={8}
            maxTagCount={2}
            onChange={handleChangeStatus}
            mode="multiple"
            size="large"
            placeholder="Status"
            options={statusOptions}
            value={params.status}
          />
        </Col>
      </Row>
      <div className="pt-15 pb-30">
        <QTable
          columns={columns}
          dataSource={referralsList}
          loading={referralsListIsLoading}
          scroll={{ x: 990 }}
          // @ts-ignore
          onChange={handleTableChange}
          sortDirections={['ascend', 'descend', 'ascend']}
        />
      </div>
      <Row className="pb-30" justify="end">
        <QPagination
          size="small"
          defaultCurrent={1}
          hideOnSinglePage
          current={pages.currentPage}
          total={referralsTotal}
          onChange={handleChangePage}
        />
      </Row>
    </>
  )
}

export default ReferralList
