import get from 'lodash/get'
import { IState } from './activities.types'
import * as CONSTANTS from './activities.constants'

const initialState: IState = {
  activitiesLoading: true,
  activities: {
    results: [],
    total: 0,
  },
  pages: {
    totalPages: 1,
    currentPage: 1,
  },
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case CONSTANTS.FETCH_ACTIVITIES_REQUEST:
      return {
        ...state,
        activitiesLoading: true,
      }
    case CONSTANTS.FETCH_ACTIVITIES_SUCCESS:
      const activities = get(action, 'payload.activities')
      return {
        ...state,
        activities,
        activitiesLoading: false,
      }
    case CONSTANTS.FETCH_ACTIVITIES_FAILURE:
      return {
        ...state,
        activitiesLoading: false,
      }
    case CONSTANTS.SET_CURRENT_PAGE:
      return {
        ...state,
        pages: {
          ...state.pages,
          currentPage: get(action, 'payload.page'),
        },
      }
    default:
      return state
  }
}
