// @ts-nocheck
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Badge } from 'antd'
import { BellOutlined, SettingOutlined } from "@ant-design/icons";
import { IconsCommon, Popover } from 'quantum_components'
import { InfoOutlined } from '@ant-design/icons'
import InfiniteScroll from 'react-infinite-scroll-component'
import SpinnerComponent from '../Spinner/SpinnerComponent'
import history from "src/utils/history";
import {
  LOCALIZATION_LANGUAGES,
} from "src/constants"

const ICONS = {
  COMPLETED: <IconsCommon.IconCheckMark />,
  REJECTED: <IconsCommon.IconWarning />,
  APPROVED: <IconsCommon.InfoOutlined />,
  ERROR: <IconsCommon.IconClose />,
}

export interface IProps {
  data: any
  onClick: (e: any, item: any) => void
  fetchData: () => void
  hasMore: boolean
}

const NotificationsList = ({ data = [], onClick, hasMore, fetchData }: IProps) => {
  const { i18n } = useTranslation()
  const { t } = useTranslation(['translationCommon'])
  return (
    <div className={`notifications-menu-list ${i18n.language === LOCALIZATION_LANGUAGES.ARABIC ? 'html-rtl' : ''}`} id="scrollableDiv">
      {!data.length && <div className="notifications-empty">{t('common.No_new_notifications')}</div>}
      {data.length > 0 && (
        <InfiniteScroll
          scrollableTarget="scrollableDiv"
          dataLength={data.length}
          next={fetchData}
          hasMore={hasMore}
          loader={
            <div style={{ textAlign: 'center' }}>
              <SpinnerComponent />
            </div>
          }
        >
          {data.map(item => (
            <a
              className="notifications-menu-item notifications-menu-approved"
              key={item.key}
              onClick={(e: any) => onClick(e, item)}
            >
              <span className={`notifications-menu-image ${item.status ? item.status.toLowerCase() : ''}`}>
                {ICONS[item.status] || <InfoOutlined />}
              </span>
              <span className="notifications-menu-info">
                <div className="notifications-text">
                  <div className="mr-5 font-weight-800">{item.titleAr && i18n.language === LOCALIZATION_LANGUAGES.ARABIC ? item.titleAr : item.title}</div>
                  {item.descriptionAr && i18n.language === LOCALIZATION_LANGUAGES.ARABIC ? item.descriptionAr : item.description}
                </div>
                <span className="notifications-menu-date">{item.date}</span>
              </span>
            </a>
          ))}
        </InfiniteScroll>
      )}
    </div>
  )
}
const SettingsList = ({ data = [], onClick, hasMore, fetchData }: IProps) => {
  const { i18n } = useTranslation()
  const { t } = useTranslation(['translationCommon'])
  return (
    <div className={`notifications-menu-list ${i18n.language === LOCALIZATION_LANGUAGES.ARABIC ? 'html-rtl' : ''}`} id="scrollableDiv">
      {!data.length && <div className="notifications-empty">{t('common.No_new_notifications')}</div>}
      {data.length > 0 && (
        <InfiniteScroll
          scrollableTarget="scrollableDiv"
          dataLength={data.length}
          next={fetchData}
          hasMore={hasMore}
          loader={
            <div style={{ textAlign: 'center' }}>
              <SpinnerComponent />
            </div>
          }
        >
          {data.map(item => (
            <a
              className="notifications-menu-item notifications-menu-approved"
              key={item.key}
              onClick={(e: any) => onClick(e, item)}
            >
              <span className={`notifications-menu-image ${item.status ? item.status.toLowerCase() : ''}`}>
                {ICONS[item.status] || <InfoOutlined />}
              </span>
              <span className="notifications-menu-info">
                <div className="notifications-text">
                  <div className="mr-5 font-weight-800">{item.titleAr && i18n.language === LOCALIZATION_LANGUAGES.ARABIC ? item.titleAr : item.title}1</div>
                  {item.descriptionAr && i18n.language === LOCALIZATION_LANGUAGES.ARABIC ? item.descriptionAr : item.description}
                </div>
                <span className="notifications-menu-date">{item.date}</span>
              </span>
            </a>
          ))}
        </InfiniteScroll>
      )}
    </div>
  )
}
  
const onRedirect = (url: string) => {
  history.push(url);
};

export interface Props {
  fetchData: () => void
  hasMore: boolean
  isSettings?: boolean
  className?: string
  overlayClassName?: string
  notificationsData?: any
  badgeCount?: number
  onOpen?: (e: any, name?: string) => void
  onClick?: (e: any, item: any) => void
}

const NotificationMenu = ({
  className = '',
  overlayClassName = '',
  notificationsData = [],
  badgeCount = 0,
  onOpen,
  onClick,
  hasMore,
  fetchData,
  isSettings = false
}: Props) => {
  const { t } = useTranslation(['translationCommon'])
  return (
    <Popover
      className={`notifications-menu-popover ${className}`}
      overlayClassName={`notifications-menu-wrapper ${overlayClassName}`}
      content={ isSettings ? <SettingsList data={notificationsData} onClick={onClick} fetchData={fetchData} hasMore={hasMore} /> : <NotificationsList data={notificationsData} onClick={onClick} fetchData={fetchData} hasMore={hasMore} />}
      title={t('common.Notifications')}
      trigger="click"
      placement="bottomRight"
      getPopupContainer={(node: any) => node.parentNode}
    >
        <Badge dot={!!badgeCount && !isSettings} color="#173994" offset={[-3, 10]}>
          <div className="notifications-menu" onClick={onOpen}>
              <span className="notifications-menu-icon">
              { isSettings?
                <SettingOutlined style={{ color: "#4E89FF", fontSize: "12px" }} />
                : <BellOutlined style={{ color: "#4E89FF", fontSize: "12px" }} />
              }
              </span>
          </div>
        </Badge>
    </Popover>
  )
}
export default NotificationMenu
