import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { AxiosResponse } from 'axios'
import IAction from 'src/interfaces/IAction'

import * as CONSTANTS from './dashboardV2.constants'
import { requestHttp, urls } from 'src/api'
import { getResponseErrorMessage } from 'src/helpers'
import { STATUS_CODES } from 'src/constants'

export const getDashboardV2Details = async(payload:any) => {
  try {
      const response = await requestHttp.post(urls.getDashboardChartDataUrl(),payload);
      if (response.status === STATUS_CODES.BASE_SUCCESS) {
        const { content } = response.data.data;
        return content
      }
    } catch (error) {
      return error
    }
}
