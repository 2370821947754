import { createSelector } from 'reselect'
import {
  IState,
  IBriefListElement,
  IBriefTableParams,
  IBrief,
  IBriefSettings,
  IBriefResponse,
  IPartnerFilter,
  IPartnerFilterSettings,
  IPartnerProps,
  IBriefRecommendedPartner,
  ICartList,
  IPackagingPrintingPrice,
  IBriefPartnerDetails,
  IUploadSettings,
  LogisticRate,
  IBriefRequestTableParams,
  IBriefRequestTable,
  BriefPaymentFilesStateType,
  IPotentialAudienceReach,
} from './brief.types'
import { MODULE_NAME } from './brief.constants'
import {
  BRIEF_STEPS,
  DIALOG_MODALS,
  LOGISTIC_RATES,
  MEDIA_GROUP_TYPE,
  SERVICE_NAMES_FOR_TABLE,
} from '../../constants/brief'
import first from 'lodash/first'
import { TABS_NAMES } from '../../constants/tabs'
import { IRequest } from '../partner/requests/request.types'
import { FORMATS } from 'src/constants'
import moment from 'moment'
import { renderRoundedNumber } from 'src/helpers/currency'
import { getCurrency } from '../core/core.selectors'
import { CURRENCY } from '../../constants/currencies'
import { IUserListElement } from '../users/users.types'

const selectState = (state: { [MODULE_NAME]: IState }): IState => state[MODULE_NAME]

export const getCurrentStep = createSelector(selectState, (state: IState): BRIEF_STEPS => state.currentStep)

export const getNeedCheckLastStep = createSelector(selectState, (state: IState): boolean => state.needCheckLastStep)

export const getIsSaving = createSelector(selectState, (state: IState): boolean => state.isSaving)

export const getBrief = createSelector(selectState, (state: IState): IBrief => state.brief)

export const getCurrentBriefId = createSelector(selectState, (state: IState): number | null => state.brief.id)

export const getBriefListLoading = createSelector(selectState, (state: IState): boolean => state.briefListLoading)

export const getBriefList = createSelector(selectState, (state: IState): IBriefListElement[] => state.briefList.results)

export const getBriefRequestsList = createSelector(
  [selectState, getCurrency],
  (state: IState, currency: CURRENCY): IBriefRequestTable[] => {
    const tableList = state.requests.results.map((request: IRequest) => {
      const startDate =
        request.type === MEDIA_GROUP_TYPE.MEDIA_BUY
          ? request.startMediaDate
            ? moment(request.startMediaDate).format(FORMATS.DATE_LL_FORMAT)
            : '-'
          : request.startDate
            ? moment(request.startDate).format(FORMATS.DATE_LL_FORMAT)
            : '-'

      return {
        key: request.id,
        product: {
          image: request.productImageUrl ? request.productImageUrl[0] : null,
          title: request.productName,
          received: request.lastApprovedDate ? moment(request.lastApprovedDate).format(FORMATS.DATE_LL_FORMAT) : '-',
          owner: {
            briefId: request.briefId,
            briefTitle: request.productName,
            ownerCompanyName: request.owner.companyName,
            ownerFirstName: request.owner.firstName,
            ownerLastName: request.owner.lastName,
          },
          // campaignName: request.campaignName,
          // campaignPromotionType: request.campaignPromotionType,
        },
        service: {
          title: request.type,
          startDate: startDate,
          duration: request.duration,
          revenue: renderRoundedNumber(request.revenue, currency),
        },
        briefOwner: {
          briefId: request.briefId,
          briefTitle: request.productName,
          ownerCompanyName: request.owner.companyName,
          ownerFirstName: request.owner.firstName,
          ownerLastName: request.owner.lastName,
        },
        partner: {
          partnerCompanyName: request.partner.companyName,
          partnerFirstName: request.partner.firstName,
          partnerLastName: request.partner.lastName,
        },
        assignedUsers: request.assignedUsers,
        status: request.status,
        request: request,
      } as IBriefRequestTable
    })

    return tableList
  }
)

export const getTotalBriefRequestsList = createSelector(selectState, (state: IState): number => state.requests.total)

export const getBriefRequestsListParams = createSelector(
  selectState,
  (state: IState): IBriefRequestTableParams => state.requestsParams
)

export const getCardList = createSelector(selectState, (state: IState): any[] =>
  state.briefList.results.map(el => ({
    ...el,
    services: el.services?.map(service => ({
      title: service.title,
      startDate: service.title === SERVICE_NAMES_FOR_TABLE.PRODUCT ? service.productStartDate : service.mediaStartDate,
      duration: service.title === SERVICE_NAMES_FOR_TABLE.PRODUCT ? service.productDuration : service.mediaDuration,
      quantity: service.quantity && service.quantity,
    })),
    discountApplied: el.product.discountText,
  }))
)

export const getTotalBriefList = createSelector(selectState, (state: IState): number => state.briefList.total)

export const getBriefView = createSelector(selectState, (state: IState): IBriefResponse => state.viewBrief)

export const getBriefViewPaymentFilesState = createSelector(
  selectState,
  (state: IState): BriefPaymentFilesStateType => state.viewBriefPaymentFilesState
)

export const getBriefParams = createSelector(selectState, (state: IState): IBriefTableParams => state.params)

export const getPages = createSelector(
  selectState,
  (
    state: IState
  ): {
    totalPages: number
    currentPage: number
  } => state.pages
)

export const getBriefSettings = createSelector(selectState, (state: IState): IBriefSettings => state.settings)

export const getIsImagesUploading = createSelector(selectState, (state: IState): boolean => state.imagesUploading)

export const getPartnerFilterSettings = createSelector(
  selectState,
  (state: IState): IPartnerFilterSettings => state.partnerFilterSettings
)

export const getPartnerFilter = createSelector(selectState, (state: IState): IPartnerFilter => state.partnerFilter)

export const getServiceImage = createSelector(
  selectState,
  (state: IState): string => first(state.viewBrief.serviceImages) || ''
)
export const getFirstProductImage = createSelector(
  selectState,
  (state: IState): string => first(state.viewBrief.productImages) || ''
)

export const getRecommendedPartners = createSelector(
  selectState,
  (state: IState): IBriefRecommendedPartner[] => state.recommendedPartners
)

export const getRecommendedPartnersLoading = createSelector(
  selectState,
  (state: IState): boolean => state.fetchRecommendedPartnersLoading
)
// difference from client app
export const getClientId = createSelector(selectState, (state: IState): string | null => state.clientId)

export const getPartnerDetails = createSelector(
  selectState,
  (state: IState): IBriefPartnerDetails | null => state.partnerDetails
)

export const getPartnerDetailsLoading = createSelector(
  selectState,
  (state: IState): boolean => state.fetchPartnerDetailsLoading
)

export const getCart = createSelector(selectState, (state: IState): ICartList => state.cart)

export const getCartLoading = createSelector(selectState, (state: IState): boolean => state.fetchCartLoading)

export const getUpdatingCart = createSelector(selectState, (state: IState): boolean => state.updateCartLoading)

export const getActiveSinglePartnerId = createSelector(
  selectState,
  (state: IState): number | null => state.activeSinglePartnerId
)

export const getSampleAllocated = createSelector(
  selectState,
  (state: IState): number => state.cart.sampleAllocated || state.brief[BRIEF_STEPS.PRODUCT].quantity || 0
)

export const getTotalQuantity = createSelector(selectState, (state: IState): number => state.totalQuantity)

export const getUploadSettings = createSelector(selectState, (state: IState): IUploadSettings[] => state.uploadSettings)

export const getUploadStepLoading = createSelector(selectState, (state: IState): string[] => state.uploadStepLoading)

export const getSampleAllocatedModal = createSelector(
  selectState,
  (state: IState): boolean => state.sampleAllocatedModal
)

export const getStickerCost = createSelector(selectState, (state: IState): number => {
  if (state.brief[BRIEF_STEPS.SERVICES].hasFreeSample) {
    return 0
  }

  return state.partnerDetails?.partnerProperties.stickerCost || 0
})

export const getPartnerProperties = createSelector(
  selectState,
  (state: IState): IPartnerProps => {
    return {
      dynamicSamplePriceTypes: state.partnerDetails?.partnerProperties?.dynamicSamplePriceTypes
        ? state.partnerDetails?.partnerProperties?.dynamicSamplePriceTypes
        : [],
      dynamicSamplePrices: state.partnerDetails?.partnerProperties?.dynamicSamplePrices,
      hasDynamicSamplePricing: state.partnerDetails?.partnerProperties?.hasDynamicSamplePricing,
    }
  }
)

export const getLogisticSampleRates = createSelector(selectState, (state: IState): LogisticRate[] =>
  state.partnerDetails && state.partnerDetails.logisticSampleRates
    ? state.partnerDetails.logisticSampleRates.map(_updateLogisticRate)
    : []
)

export const getLogisticInsertRates = createSelector(selectState, (state: IState): LogisticRate[] =>
  state.partnerDetails && state.partnerDetails.logisticInsertRates
    ? state.partnerDetails.logisticInsertRates.map(_updateLogisticRate)
    : []
)

export const getActiveTab = createSelector(selectState, (state: IState): TABS_NAMES => state.activeTab)

export const getIsRejectionFlow = createSelector(selectState, (state: IState): boolean => state.rejectionFlow)

export const getProposalLoading = createSelector(selectState, (state: IState): boolean => state.sendProposalLoading)

export const getDialogModal = createSelector(selectState, (state: IState): DIALOG_MODALS | null => state.dialogModal)

const _updateLogisticRate = (rate: LogisticRate) => {
  if (rate.min === LOGISTIC_RATES.SMALLEST) {
    rate.min = 0
  }

  if (!rate.max) {
    rate.max = LOGISTIC_RATES.BIGGEST
  }

  return rate
}

export const getBriefOwner = createSelector(selectState, (state: IState): IUserListElement | null => state.brief.owner)

export const getPotentialAudienceReachData = createSelector(
  selectState,
  (state: IState): IPotentialAudienceReach => state.potentialAudienceReach
)

export const getCopackingPrice = createSelector(
  selectState,
  (state: IState): IPackagingPrintingPrice | null => state.copackingPrice
)

export const getPrintingPrice = createSelector(
  selectState,
  (state: IState): IPackagingPrintingPrice | null => state.printingPrice
)

export const getViewBriefLoading = createSelector(selectState, (state: IState): boolean => state.viewBriefLoading)