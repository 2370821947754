import { IDialogModal } from '../modules/core/core.types'
import { BRIEF_TYPES, DIALOG_MODALS, DISTRIBUTION_TYPE } from '../constants/brief'
import { renderRoundedNumber } from './currency'
import { CURRENCY } from 'src/constants/currencies'

export const getModalData = (
  dialogModal: DIALOG_MODALS | null,
  totalQuantity: number,
  sampleAllocated: number,
  distributionType: DISTRIBUTION_TYPE,
  totalBudget: number = 0,
  budgetAllocated: number = 0,
  currency: CURRENCY = CURRENCY.SAR
) => {
  const isSampleAllocatedModal = dialogModal === DIALOG_MODALS.SAMPLE_ALLOCATED_MISMATCH
  const isBudgetAllocatedModal = dialogModal === DIALOG_MODALS.BUDGET_ALLOCATED_MISMATCH
  const isSampleBudgetAllocatedModal = dialogModal === DIALOG_MODALS.SAMPLE_BUDGET_ALLOCATED_MISMATCH
  const isRejectedServiceModal = dialogModal === DIALOG_MODALS.HAS_REJECTED_SERVICES
  const isBlockNextModal = dialogModal === DIALOG_MODALS.BLOCK_NEXT
  const isCancelModal = dialogModal === DIALOG_MODALS.CANCEL
  const isMediaBuy = dialogModal === DIALOG_MODALS.MEDIA_BUY
  const isDistribution = dialogModal === DIALOG_MODALS.DISTRIBUTION

  const convert_amount_into_currency_format = (amount: number) => {
    return amount > 0 ? renderRoundedNumber(amount, currency) : '0.00 ' + currency
  }

  const modal = {
    question: '',
    description: '',
    submitButtonText: '',
    cancelButtonText: '',
  } as IDialogModal

  if (isSampleAllocatedModal) {
    const question = totalQuantity > sampleAllocated ? 'You have added more samples!' : 'You still have samples left!'
    const description =
      totalQuantity > sampleAllocated
        ? `You allocated ${sampleAllocated} samples in your brief, but have selected ${totalQuantity}.`
        : `You allocated ${sampleAllocated} samples in your brief, but have only selected ${totalQuantity}.`
    const buttonText = totalQuantity > sampleAllocated ? 'Go Back & Decrease' : 'Go Back & Add More'
    modal.question = question
    modal.description = description
    modal.submitButtonText = buttonText
    modal.cancelButtonText = 'Proceed Anyways'
  }

  if (isBudgetAllocatedModal) {
    const question =
      totalBudget > budgetAllocated ? 'You have allocated more than your budget!' : 'You still have budget left!'
    const description =
      totalBudget > budgetAllocated
        ? `You allocated ${convert_amount_into_currency_format(
            budgetAllocated
          )} for Media Buy in your brief, but have selected ${convert_amount_into_currency_format(totalBudget)}.`
        : `You allocated ${convert_amount_into_currency_format(
            budgetAllocated
          )} for Media Buy in your brief, but have only selected ${convert_amount_into_currency_format(totalBudget)}.`
    const buttonText = totalBudget > budgetAllocated ? 'Go Back & Decrease' : 'Go Back & Add More'
    modal.question = question
    modal.description = description
    modal.submitButtonText = buttonText
    modal.cancelButtonText = 'Proceed Anyways'
  }

  if (isSampleBudgetAllocatedModal) {
    const isSampleOver = totalQuantity > sampleAllocated ? true : false
    const isBudgetOver = totalBudget > budgetAllocated ? true : false

    const question =
      isSampleOver && isBudgetOver
        ? 'You have over-allocated budget and samples!'
        : !isSampleOver && isBudgetOver
        ? 'You still have over-allocated budget and samples left!'
        : isSampleOver && !isBudgetOver
        ? 'You still have budget left and over-allocated samples!'
        : 'You still have budget and samples left!'

    const description =
      isSampleOver && isBudgetOver
        ? `You allocated ${convert_amount_into_currency_format(
            budgetAllocated
          )} for Media Buy in your brief, but have selected ${convert_amount_into_currency_format(totalBudget)}. \n
            You allocated ${sampleAllocated} samples in your brief, but have selected ${totalQuantity}.`
        : !isSampleOver && isBudgetOver
        ? `You allocated ${convert_amount_into_currency_format(
            budgetAllocated
          )} for Media Buy in your brief, but have selected ${convert_amount_into_currency_format(totalBudget)}. \n
              You allocated ${sampleAllocated} samples in your brief, but have only selected ${totalQuantity}.`
        : isSampleOver && !isBudgetOver
        ? `You allocated ${convert_amount_into_currency_format(
            budgetAllocated
          )} for Media Buy in your brief, but have only selected ${convert_amount_into_currency_format(totalBudget)}. \n
                You allocated ${sampleAllocated} samples in your brief, but have selected ${totalQuantity}.`
        : `You allocated ${convert_amount_into_currency_format(
            budgetAllocated
          )} for Media Buy in your brief, but have only selected ${convert_amount_into_currency_format(totalBudget)}. \n
                You allocated ${sampleAllocated} samples in your brief, but have only selected ${totalQuantity}.`

    const buttonText =
      isSampleOver && isBudgetOver
        ? 'Go Back & Decrease'
        : !isSampleOver && !isBudgetOver
        ? 'Go Back & Add More'
        : 'Go Back & Change'

    modal.question = question
    modal.description = description
    modal.submitButtonText = buttonText
    modal.cancelButtonText = 'Proceed Anyways'
  }

  if (isRejectedServiceModal) {
    modal.question = 'There are still some rejected services in your cart.'
    modal.description =
      '“Go back” and change them or proceed. Rejected services will be sent to Partner as a new request.'
    modal.submitButtonText = 'Go Back & Change'
    modal.cancelButtonText = 'Proceed Anyways'
  }

  if (isBlockNextModal) {
    modal.question = 'You have rejected services.'
    modal.description = 'You can’t proceed with rejection flow without updating your cart.'
    modal.submitButtonText = 'Go Back & Change'
    modal.cancelButtonText = 'Close Rejection Flow'
  }

  if (isCancelModal) {
    modal.question = 'You had not saved your Cart.'
    modal.description = 'Are you sure you want to close this page without saving the changes?'
    modal.submitButtonText = 'No, Cancel'
    modal.cancelButtonText = "Yes, Don't save"
  }

  if (isMediaBuy) {
    modal.question = 'You have previously selected Media Buy services.'
    modal.description = 'Do you want to proceed without adding any to the Cart?'
    modal.submitButtonText = 'No, Cancel'
    modal.cancelButtonText = 'Yes, Proceed'
  }

  if (isDistribution) {
    modal.question = `You have previously selected ${distributionType} service.`
    modal.description = 'Do you want to proceed without adding any to the Cart?'
    modal.submitButtonText = 'No, Cancel'
    modal.cancelButtonText = 'Yes, Proceed'
  }

  return modal
}

export const isSample = (distributionItem: DISTRIBUTION_TYPE | null) =>
  distributionItem === DISTRIBUTION_TYPE.PRODUCT_SAMPLE
export const isInsert = (distributionItem: DISTRIBUTION_TYPE | null) =>
  distributionItem === DISTRIBUTION_TYPE.PRINTED_INSERT

export const onlyDistribution = (briefTypes: BRIEF_TYPES[]) =>
  briefTypes.length === 1 && briefTypes.includes(BRIEF_TYPES.PRODUCT_SAMPLE)
export const onlyMediaBuy = (briefTypes: BRIEF_TYPES[]) =>
  briefTypes.length === 1 && briefTypes.includes(BRIEF_TYPES.MEDIA_BUYING)
