import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Row, Col } from 'quantum_components'
import { EditorState, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { useReduxDispatch } from '../../../helpers'
import * as actions from '../releaseNotes.actions'
import { getIsReleaseNotesLoading, getReleaseNotes } from '../releaseNotes.selectors';
import SpinnerCenter from 'src/components/Spinner/spinner';

const ReleaseNotes = () => {
  const { t } = useTranslation(['translationPlatform'])
  const dispatch = useReduxDispatch()
  const isReleaseNotesLoading = useSelector(getIsReleaseNotesLoading)
  const releaseNotes = useSelector(getReleaseNotes)

  const [editorState, setEditorState] = useState(EditorState.createEmpty())

  useEffect(() => {
    dispatch(actions.getReleaseNotes())
    return () => {
      dispatch(actions.resetReleaseNotesState())
    }
  }, [])

  useEffect(() => {
    if (releaseNotes?.htmlContent) {
      const contentBlock = htmlToDraft(releaseNotes?.htmlContent);
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorStateData = EditorState.createWithContent(contentState);
      setEditorState(editorStateData)
    }
  }, [releaseNotes])

  return (
    <>
      {isReleaseNotesLoading && <SpinnerCenter />}

      <Row justify="space-between">
        <Col className="qt-search">
          <h2 className="">{t(`menu.releaseNotes`)}</h2>
        </Col>
      </Row>

      <div className="pt-15 pb-30">
        <Editor
          toolbarHidden
          readOnly={true}
          editorState={editorState}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          editorStyle={{ minHeight: '600px', backgroundColor: 'white', padding: '20px' }}
        />
      </div>
    </>
  )
}

export default ReleaseNotes
