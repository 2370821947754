import React, { ReactElement, useState } from "react";
import { Link } from "react-router-dom";

import * as actions from "../signIn.actions";
import { useReduxDispatch } from "src/helpers";
import { PATHS } from "src/constants";
import {
  Col,
  Form,
  QButton,
  QInput,
  Row,
  QButtonEye,
} from "quantum_components";
import { validateMessages } from "../../../../helpers/validationMessage";
import { ReactComponent as ArrowRight } from "../../../../assets/icons/arrow-right-icon.svg";
import { ReactComponent as ArrowLeft } from "../../../../assets/icons/arrow-left-icon.svg";
import AuthLayout from "../../../../components/Layouts/AuthLayout";
import { Store } from "antd/lib/form/interface";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "src/components/LanguageSwitcher";
import i18n from "src/i18n";

export default function SignInContainer(): ReactElement {
  const dispatch = useReduxDispatch();
  const { t } = useTranslation(["translationUser", "translationCommon"]);

  const [passwordIsVisible, setPasswordVisible] = useState<boolean>(false);

  const getPasswordInputType = (passwordIsVisible: boolean) => {
    return passwordIsVisible ? "text" : "password";
  };

  const handlePasswordVisibility = () => {
    setPasswordVisible(!passwordIsVisible);
  };

  const submit = (values: Store) => {
    dispatch(actions.signIn(values.email, values.password));
  };

  return (
    <AuthLayout>
      <div className="auth-form">
        <h1>{t("user.login.sign_in_to_your_account")}</h1>
        <Form
          layout="vertical"
          hideRequiredMark
          validateMessages={validateMessages}
          onFinish={submit}
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                label={t('user.login.email_address')}
                name="email"
                rules={[
                  { required: true, max: 255, type: "email", whitespace: true },
                ]}
                validateTrigger={["onChange", "onBlur"]}
              >
                <QInput
                  placeholder={t('user.login.enter_email_address_you_registered_with')}
                  size="large"
                  type="email"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={t('user.login.password')}
                name="password"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                  },
                ]}
                validateTrigger={["onChange", "onBlur"]}
              >
                <QInput
                  size="large"
                  placeholder={t('user.login.enter_the_password')}
                  style={{ paddingRight: 50 }}
                  type={getPasswordInputType(passwordIsVisible)}
                />
              </Form.Item>
              <QButtonEye
                onClick={handlePasswordVisibility}
                isVisible={passwordIsVisible}
              />
            </Col>
          </Row>
          <div className="remember">
            <Link to={PATHS.RESET_PASSWORD}>{t('user.login.forgot_password')}</Link>
          </div>
          <QButton
            className="text-capitalize"
            type="primary"
            block
            htmlType="submit"
          >
            {t('user.login.sign_in')}

            {i18n.dir() === 'ltr'?<ArrowRight className="ml-5" />:<ArrowLeft className="mr-5" />}
          </QButton>
        </Form>
      </div>
      <div className="auth-section-footer">
        <LanguageSwitcher />
      </div>
    </AuthLayout>
  );
}
