export enum ALERTS_MSG {
  ALERT_BRIEF_LIST_NOT_EMPTY = 'Your account is under review. You will be notified as soon as your account has been approved or rejected. Once approved, you can complete your product brief by selecting and submitting it to Partners in order to start a Campaign.',
  ALERT_APPROVED = 'Your account has been approved. Please complete and submit your briefs to partners in order to start a campaign.',
  ALERT_BRIEF_LIST_EMPTY = 'Your account is under review. Marketplace access will be limited until your account is approved.',
  ALERT_BRIEF_VIEW = 'This brief is pending approval. A brief cannot be edited after being approved.',
  ALERT_BRIEF_UPLOAD_MAX_FIVE = 'You can upload maximum 5 images',
  ALERT_REJECTION_FLOW_ADD = 'Add to cart other Partner(s) services for replacement.',
  ALERT_YOU_ARE_VIEWING_CAMPAIGNS_WHICH_ARE_ASSIGNED_TO_YOU = 'You are viewing campaigns which are assigned to you.',
}

export const ALERT_BRIEF_UPLOAD_MAX = (count: number) => `You can upload maximum ${count} images`
