import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { AxiosResponse } from 'axios'
import IAction from 'src/interfaces/IAction'

import * as CONSTANTS from './supportTickets.constants'
import { requestHttp, urls } from 'src/api'
import { ISupportTicket, ISupportTicketsListParams } from './supportTickets.types'
import { getResponseErrorMessage } from 'src/helpers'

export const setCurrentPage = (page: number): IAction => ({
  type: CONSTANTS.SET_CURRENT_PAGE,
  payload: { page },
})

export const supportTicketsListRequest = (): IAction => ({
  type: CONSTANTS.FETCH_SUPPORT_TICKETS_REQUEST,
})

export const supportTicketsListSuccess = (supportTickets: { results: ISupportTicket[]; total: number }): IAction => ({
  type: CONSTANTS.FETCH_SUPPORT_TICKETS_SUCCESS,
  payload: supportTickets,
})

export const supportTicketsListFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_SUPPORT_TICKETS_FAILURE,
  error,
})

export const fetchSupportTicketsList = (params: ISupportTicketsListParams): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<any> => {
  try {
    dispatch(supportTicketsListRequest())
    const response = await requestHttp.get(urls.getSupportTicketsListUrl(), { params })
    const { content } = response.data.data
    dispatch(supportTicketsListSuccess(content))
    return response
  } catch (error) {
    dispatch(supportTicketsListFailure(getResponseErrorMessage(error)))
    return error
  }
}

export const actionSupportTicketRequest = (): IAction => ({
  type: CONSTANTS.ACTION_SUPPORT_TICKET_REQUEST,
})

export const actionSupportTicketSuccess = (): IAction => ({
  type: CONSTANTS.ACTION_SUPPORT_TICKET_SUCCESS,
})

export const deleteSupportTicket = (id: number): ThunkAction<void, {}, {}, AnyAction> => async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
  try {
    dispatch(actionSupportTicketRequest())
    await requestHttp.delete(urls.deleteSupportTicketUrl(id))
    dispatch(actionSupportTicketSuccess())
  } catch (error) {
    dispatch(actionSupportTicketSuccess())
  }
}

export const createSupportTicket = (supportTicket: ISupportTicket): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<any> => {
  try {
    dispatch(actionSupportTicketRequest())
    const response = await requestHttp.post(urls.postSupportTicketUrl(), supportTicket)
    dispatch(actionSupportTicketSuccess())
    return response
  } catch (error) {
    dispatch(actionSupportTicketSuccess())
    return error
  }
}

export const updateSupportTicket = (supportTicket: ISupportTicket, supportTicketId: number): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<any> => {
  try {
    dispatch(actionSupportTicketRequest())
    const response = await requestHttp.put(urls.putSupportTicketUrl(supportTicketId), supportTicket)
    dispatch(actionSupportTicketSuccess())
    return response
  } catch (error) {
    dispatch(actionSupportTicketSuccess())
    return error
  }
}

export const getTicketFeedback = (id: number): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<AxiosResponse> => {
  try {
    dispatch(actionSupportTicketRequest())
    const response = await requestHttp.get(urls.getFeedbackTicketUrl(id))
    const { content } = response.data.data
    dispatch(actionSupportTicketSuccess())
    return content
  } catch (error) {
    dispatch(actionSupportTicketSuccess())
    return error
  }
}

export const sendTicketMessage = (id: number, message: string): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<AxiosResponse> => {
  try {
    dispatch(actionSupportTicketRequest())
    const response = await requestHttp.put(urls.sendMessageTicketUrl(id), { message })
    const { content } = response.data.data
    dispatch(actionSupportTicketSuccess())
    return content
  } catch (error) {
    dispatch(actionSupportTicketSuccess())
    return error
  }
}

export const getTicketDetails = (id: number): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<AxiosResponse> => {
  try {
    dispatch(actionSupportTicketRequest())
    const response = await requestHttp.get(urls.getSupportTicketDetailsUrl(id))
    const { content } = response.data.data
    dispatch(actionSupportTicketSuccess())
    return content
  } catch (error) {
    dispatch(actionSupportTicketSuccess())
    return error
  }
}