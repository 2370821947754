import React, { ReactElement } from 'react'
import { QBriefFooter, QButton } from 'quantum_components'
import { ReactComponent as ArrowLeft } from '../../../../assets/icons/arrow-left-icon.svg'
import { ReactComponent as ArrowRight } from '../../../../assets/icons/arrow-right-icon.svg'
import { ReactComponent as LogOut } from 'src/assets/icons/logout-icon-alt.svg'
import { getIsSaving } from '../../brief.selectors'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import i18n from 'src/i18n'

export interface IProps {
  isZeroStep: boolean
  isPaymentStep?: boolean
  onBack: () => void
  onNext: () => void
  onFinishLater: () => void
  hideArrow?: boolean
  nextButtonText: string
  disableNext: boolean
  disableBack: boolean
}

export default function BriefFooter({
  isZeroStep,
  isPaymentStep = false,
  onBack,
  onNext,
  onFinishLater,
  hideArrow = false,
  nextButtonText,
  disableNext,
  disableBack,
}: IProps): ReactElement {
  const {t} = useTranslation(['translationABrief'])
  const isSaving = useSelector(getIsSaving)

  return (
    <QBriefFooter
      childrenCenter={
        <>
          {!isZeroStep && (
            <QButton
              disabled={disableBack}
              onClick={onBack}
              className={
                nextButtonText === 'Submit & View Quotation'
                  ? 'qu-button-outline footer-btn-width'
                  : 'qu-button-outline'
              }
            >
              {i18n.dir() === 'ltr'?<ArrowLeft className="mr-5" />:<ArrowRight className="ml-5" />}
              
              {t(`back`)}
            </QButton>
          )}
          <QButton
            disabled={isSaving || disableNext}
            onClick={onNext}
            type="primary"
            className={nextButtonText === t(`submit_and_view_quotation`) ? 'footer-btn-width' : ''}
          >
            {nextButtonText}
            {!hideArrow && !isPaymentStep && (i18n.dir()==='ltr'?<ArrowRight className="ml-5" />:<ArrowLeft className="mr-5" />) }
          </QButton>
        </>
      }
      childrenRight={
        isZeroStep ? null : (
          <QButton disabled={isPaymentStep ? false : disableNext} onClick={onFinishLater} className="qu-button-outline">
            {t(`save`)} & {isPaymentStep ? t(`pay`) : t(`finish`)} {t(`later`)} <LogOut className="ml-10" />
          </QButton>
        )
      }
    />
  )
}
