import React, { useEffect, useState } from 'react'

import ImageModal from 'src/components/ImageModal/ImageModal'
import { useSelector } from 'react-redux'
import { getCampaignAnalyticsTimeline } from '../../../campaign.selectors'
import { ITimeline } from '../../../campaign.types'
import { getBase64 } from '../../../../../utils/getBase64'

const ClientAnalyticsTimeline = () => {
  const timelineList = useSelector(getCampaignAnalyticsTimeline)

  const [activeTimeline, setActiveTimeline] = useState<any>(null)
  const [activeImage, setActiveImage] = useState<string>('')
  const [timelines, setTimelines] = useState<ITimeline[]>([])

  const getPhotosBase64 = async (timeline: ITimeline) => {
    return Promise.all(timeline.timelinePhotos.map((imageUrl: string) => Promise.resolve(getBase64(imageUrl))))
  }

  const getTimelines = async () => {
    const list = timelineList.map((t: ITimeline) => {
      getPhotosBase64(t).then((data: string[]) => {
        t.timelinePhotos = data
      })
      return t
    })

    setTimelines(list)
  }

  useEffect(() => {
    getTimelines()
  }, [timelineList])

  const handleClick = (url: string, timeline: ITimeline) => {
    setActiveTimeline(timeline)
    setActiveImage(url)
  }

  const handleClose = () => {
    setActiveTimeline(null)
    setActiveImage('')
  }

  return (
    <React.Fragment>
      <div className="timeline" style={{ marginBottom: '15px' }}>
        <div className="timeline__head">
          <h5 className="timeline__title">Timeline</h5>
        </div>
        {timelines.length > 0 && (
          <div className="timeline__cont">
            {timelines.map((timeline: ITimeline) => (
              <div key={timeline.id} className={`timeline__item`}>
                <div className="p--sm timeline__caption">{timeline.timelineCaption}</div>
                <div className="p--xs tc--light timeline__date">{timeline.timelineDate}</div>
                <div className="p--xs tc--light timeline__date">{timeline.timelinePartner}</div>
                {timeline.timelinePhotos.map((url: string) => (
                  <div className="timeline_photo" key={url} onClick={() => handleClick(url, timeline)}>
                    <img src={url} />
                  </div>
                ))}
              </div>
            ))}
          </div>
        )}
      </div>
      {activeTimeline && (
        <ImageModal
          timelineCaption={activeTimeline.timelineCaption}
          timelineDate={activeTimeline.timelineDate}
          imgUrl={activeImage}
          modalOpen={!!activeTimeline}
          onClose={handleClose}
        />
      )}
    </React.Fragment>
  )
}

export default ClientAnalyticsTimeline
