import { PERMISSIONS } from '../constants/permission'
import { INotificationList } from '../modules/notifications/notifications.types'
import { NOTIFICATION_GROUPS } from '../constants/notifications'

export const hasPermission = (permissions: string[], action: PERMISSIONS) => {
  if (action === PERMISSIONS.DEFAULT) return true

  return permissions.includes(action)
}

export const filterNotifications = (notifications: INotificationList[], permissions: string[]) => {
  let list = [] as INotificationList[]

  notifications.forEach((notification: INotificationList) => {
    if (
      !hasPermission(permissions, PERMISSIONS.NOTIFICATION_RECEIVE_BRIEF_CREATED) &&
      notification.group === NOTIFICATION_GROUPS.NEW_BRIEF_CREATED
    ) {
      return
    }

    if (
      !hasPermission(permissions, PERMISSIONS.NOTIFICATION_RECEIVE_BRIEF_UPDATED) &&
      notification.group === NOTIFICATION_GROUPS.NEW_BRIEF_UPDATED
    ) {
      return
    }

    if (
      !hasPermission(permissions, PERMISSIONS.NOTIFICATION_RECEIVE_CAMPAIGN_UPDATED) &&
      notification.group === NOTIFICATION_GROUPS.CAMPAIGN_UPDATED
    ) {
      return
    }

    if (
      !hasPermission(permissions, PERMISSIONS.NOTIFICATION_RECEIVE_NEW_CAMPAIGN_CREATED) &&
      notification.group === NOTIFICATION_GROUPS.NEW_CAMPAIGN_CREATED
    ) {
      return
    }

    if (
      !hasPermission(permissions, PERMISSIONS.NOTIFICATION_RECEIVE_REQUEST) &&
      notification.group === NOTIFICATION_GROUPS.NEW_REQUEST_RECEIVED
    ) {
      return
    }

    list = [...list, notification]
  })

  return list
}

export const showListingActions = (permissions: string[], actions: string[]) => {
  return permissions.some(p => actions.includes(p))
}
