import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { AxiosResponse } from 'axios'

import IAction from 'src/interfaces/IAction'
import { requestHttp, urls } from 'src/api'
import { IResetPasswordResponse } from './resetPassword.types'
import * as CONSTANTS from './resetPassword.constants'
import { getResponseErrorMessage } from 'src/helpers'

export const resetPasswordRequest = (): IAction => ({
  type: CONSTANTS.RESET_PASSWORD_REQUEST,
})

export const resetPasswordSuccess = (): IAction => ({
  type: CONSTANTS.RESET_PASSWORD_SUCCESS,
})

export const resetPasswordFailure = (error: string): IAction => ({
  type: CONSTANTS.RESET_PASSWORD_FAILURE,
  error,
})

export const resetPassword = (
  email: string
): ThunkAction<Promise<AxiosResponse<IResetPasswordResponse>>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse<IResetPasswordResponse>> => {
  try {
    dispatch(resetPasswordRequest())
    const response = await requestHttp.post<IResetPasswordResponse>(urls.getResetPasswordUrl(), { email })
    dispatch(resetPasswordSuccess())

    return response
  } catch (error) {
    dispatch(resetPasswordFailure(getResponseErrorMessage(error)))

    return error
  }
}
