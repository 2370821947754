import React, { ReactElement } from 'react'
import { QButton, IconsCommon } from 'quantum_components'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import i18n from 'src/i18n'

export interface IProps {
  currentIndex: number
  onBack: () => void
  onNext: () => void
  onFinishLater: () => void
  disabled: boolean
}

const { IconArrowLeft, IconArrowRight } = IconsCommon

const LAST_STEP = 3
const SECOND_LAST_STEP = 2

export default function PartnersProfileFooter({
  currentIndex,
  onBack,
  onNext,
  onFinishLater,
  disabled,
}: IProps): ReactElement {
  const {t} = useTranslation(['translationProfile'])
  const location = useLocation();
  const isSecondLastStep = currentIndex === SECOND_LAST_STEP
  const isLastStep = currentIndex === LAST_STEP
  return (
    <div className="partner-container-footer" style={isLastStep ? { flexDirection: 'row-reverse' } : undefined}>
      {!isLastStep && !isSecondLastStep && (
        <QButton disabled={disabled} onClick={onFinishLater} className="qu-button-soft">
          {t("profile.partner.save_updates")}
        </QButton>
      )}
      <div className="right">
        {!!currentIndex && location.pathname !== '/services' && (
          <QButton className="qu-button-outline" onClick={onBack}>
           {i18n.dir() === 'ltr'?<IconArrowLeft className="mr-5" />:<IconArrowRight className="ml-5" />}  
           { t("profile.partner.back")}
          </QButton>
        )}
        {!isLastStep && !isSecondLastStep ? (
          <QButton onClick={onNext} type="primary">
            {t("profile.partner.next")}
            {i18n.dir() === 'ltr'?<IconArrowRight className="ml-5" />:<IconArrowLeft className="mr-5" />}
          </QButton>
        ) : (
          <QButton disabled={disabled} onClick={onFinishLater} className="qu-button-soft">
            {t("profile.partner.save_updates")}
          </QButton>
        )}
      </div>
    </div>
  )
}
