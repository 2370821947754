import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useReduxDispatch } from '../../../helpers'
import moment from 'moment'
import { getIsSupportTicketsListLoading, getSupportTicketsList, getIsActionSupportTicketLoading, getSupportTicketsTotal, getPages } from '../supportTickets.selectors'
import { IActionModalState, ISupportTicket } from '../supportTickets.types'
import * as actions from '../supportTickets.actions'
import {
  Modal,
  Form,
  Row,
  Col,
  QButton,
  QTable,
  QInput,
  QTextArea,
  IconsCommon,
  QSelect,
  qnotification,
  QPagination,
  Dropdown,
  Menu,
  QMenuItem
} from 'quantum_components'
import { PlusOutlined, SearchOutlined, MessageFilled } from '@ant-design/icons'
import { validateMessages } from '../../../helpers/validationMessage'
import { getCurrentUserPermissionActions } from '../../core/core.selectors'
import { hasPermission } from '../../../helpers/permission'
import { PERMISSIONS } from '../../../constants/permission'
import { FORMATS, NOTIFICATION_TYPES, PATHS } from 'src/constants'
import SpinnerCenter from 'src/components/Spinner/spinner'
import { IOptionForSelect } from 'src/modules/brief/brief.types'
import { supportTicketsStatuses, SUPPORT_TICKET_STATUS } from 'src/constants/supportTickets'
import { isMobile } from 'react-device-detect'
import useDebounce from 'src/hooks/useDebounce'
import i18n from 'src/i18n'
import { useParams } from 'react-router-dom'
import history from 'src/utils/history'

const SupportTicketsList = () => {
  const { t } = useTranslation(['translationSupportTickets', 'translationCommon'])

  const { id, chatId } = useParams<{ id: string, chatId: string }>()
  const [form] = Form.useForm()

  const dispatch = useReduxDispatch()
  const supportTicketsList = useSelector(getSupportTicketsList)
  const supportTicketsTotal = useSelector(getSupportTicketsTotal)
  const supportTicketsListIsLoading = useSelector(getIsSupportTicketsListLoading)
  const isActionSupportTicketLoading = useSelector(getIsActionSupportTicketLoading)
  const permissions = useSelector(getCurrentUserPermissionActions)
  const pages = useSelector(getPages)

  const [search, setSearch] = useState<string>("");
  const [moduleFilter, setModuleFilter] = useState<string[]>([])
  const [statusFilter, setStatusFilter] = useState<string[]>([])
  const [params, setParams] = useState({
    currentPage: 1,
    limit: 10,
    offset: 0,
    direction: 'desc',
    sortField: 'createdAt',
  })
  const [viewModal, setViewModal] = useState<{ visible: boolean, data: any }>({ visible: false, data: null })
  const [addEditModal, setAddEditModal] = useState({ visible: false, id: 0 })
  const [deleteActionModal, setDeleteActionModal] = useState<IActionModalState>({
    visible: false,
    id: 0,
    title: ''
  })
  const [messagesModal, setMessagesModal] = useState<{ visible: boolean, data: any[], record: ISupportTicket | null }>({ visible: false, data: [], record: null })

  const debouncedSearch = useDebounce(search, 500);
  const debouncedModuleFilter = useDebounce(moduleFilter, 500)
  const debouncedStatusFilter = useDebounce(statusFilter, 500)

  useEffect(() => {
    if (id || chatId) dispatch(actions.getTicketDetails(id ? Number(id) : Number(chatId))).then((res: any) => {
      res.id && id ? handleViewClick(res) : getTicketFeedback(Number(chatId), res)
    })
  }, [id, chatId])

  useEffect(() => {
    fetchSupportTicketsForFilters()
    dispatch(actions.setCurrentPage(1))
  }, [debouncedSearch, debouncedModuleFilter, debouncedStatusFilter])

  const fetchSupportTicketsForFilters = (queryParams = params) => {
    dispatch(actions.fetchSupportTicketsList({
      ...queryParams,
      currentPage: 1,
      limit: 10,
      offset: 0,
      search: debouncedSearch,
      module: debouncedModuleFilter,
      status: debouncedStatusFilter,
    }))
  }

  const fetchSupportTickets = (queryParams = params) => {
    dispatch(
      actions.fetchSupportTicketsList({
        ...queryParams,
        search: debouncedSearch,
        module: debouncedModuleFilter,
        status: debouncedStatusFilter,
      })
    )
  }

  // @ts-ignore
  const handleTableChange = (pagination, filters, sorter) => {
    const sortingParams = {
      direction: sorter.order.slice(0, -3),
      sortField: sorter.columnKey,
    }
    const newParams = {
      ...params,
      ...sortingParams,
    }
    setParams(newParams)
    fetchSupportTickets(newParams)
  }

  const handleChangePage = (page: number, pageSize: number) => {
    dispatch(actions.setCurrentPage(page))

    dispatch(
      actions.fetchSupportTicketsList({
        ...params,
        offset: (page - 1) * params.limit,
        currentPage: page,
        limit: pageSize,
        search: debouncedSearch,
        module: debouncedModuleFilter,
        status: debouncedStatusFilter,
      })
    )

    setParams({
      ...params,
      offset: (page - 1) * params.limit,
      currentPage: page,
      limit: pageSize,
    })
  }

  const handleEditModalOpen = async (record: any = null) => {
    if (record) {
      form.setFieldsValue({
        module: record.module,
        title: record.title,
        description: record.description
      })
      setAddEditModal({ visible: true, id: record.id })
    } else {
      form.resetFields()
      setAddEditModal({ visible: true, id: 0 })
    }
  }

  const handleEditModalClose = () => {
    setAddEditModal({ visible: false, id: 0 })
    form.resetFields()
  }

  const handleOnFinish = async (data: any) => {
    let response: any = null;
    if (addEditModal.id) {
      response = await dispatch(actions.updateSupportTicket(data, addEditModal.id))
    } else {
      response = await dispatch(actions.createSupportTicket(data))
    }
    if (response?.status === 200 || response?.status === 201) {
      handleEditModalClose()
      fetchSupportTickets()
    }
  }

  const onCheck = async () => {
    try {
      const values = await form.validateFields()
      let newSupportTicket: any = {
        module: values.module,
        title: values.title,
        description: values.description
      }
      handleOnFinish(newSupportTicket)
    } catch (error) {
      return error
    }
  }

  const handleDeleteClick = (supportTicket: any) => {
    setDeleteActionModal({
      visible: true,
      id: supportTicket.id,
      title: supportTicket.title
    })
  }

  const handleDeleteModalClose = () => {
    setDeleteActionModal({
      visible: false,
      id: 0,
      title: ''
    })
  }

  const handleDeleteModalOkay = async () => {
    await dispatch(actions.deleteSupportTicket(deleteActionModal.id))
    handleDeleteModalClose()
    fetchSupportTickets()
  }

  const handleViewClick = async (record: any) => {
    setViewModal({ visible: true, data: record })
  }

  const columns = [
    {
      title: t('Module'),
      dataIndex: 'module',
      key: 'module'
    },
    {
      title: t('Title'),
      dataIndex: 'title',
      key: 'title'
    },
    {
      title: t('Description'),
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: t('Created Date'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      render: (text: string) => {
        return moment(text).format(FORMATS.DATE_TIME_FORMAT)
      },
    },
    {
      title: t('Status'),
      dataIndex: 'status',
      key: 'status',
      render: (status: SUPPORT_TICKET_STATUS, record: any) => {
        const statusClass = supportTicketsStatuses[status]?.classStyle ?? "";
        const statusLabel = supportTicketsStatuses[status]?.label ?? "Pending";
        return (
          <div>
            <span className={`status-label ${statusClass}`}>{t(`${statusLabel}`)}</span>
            <div onClick={(e) => e.stopPropagation()}>
              <Dropdown
                overlay={menu(record)}
                trigger={["click"]}
                placement="bottomRight"
              >
                <div className={`q-table_menu`}>
                  <IconsCommon.IconEllipsisV />
                </div>
              </Dropdown>
            </div>
          </div>
        )
      }
    }
  ]

  const menu = (record: ISupportTicket) => (
    <Menu className={`user-menu-list ${i18n.dir() === 'rtl' ? 'html-rtl' : ''}`}>
      {hasPermission(permissions, PERMISSIONS.SUPPORT_TICKETS_MODULE_ACCESS) && (
        <QMenuItem
          key={0}
          onClick={() => handleViewClick(record)}
          name={t(`View Support Ticket`)}
          icon={<IconsCommon.IconView />}
        />
      )}
      {hasPermission(permissions, PERMISSIONS.CREATE_AND_EDIT_SUPPORT_TICKET) && (
        <QMenuItem
          key={1}
          // @ts-ignore
          disabled={record.status !== SUPPORT_TICKET_STATUS.PENDING}
          onClick={() => handleEditModalOpen(record)}
          name={t(`Edit Support Ticket`)}
          icon={<IconsCommon.IconEdit />}
        />
      )}
      {hasPermission(permissions, PERMISSIONS.DELETE_SUPPORT_TICKET) && (
        <QMenuItem
          key={2}
          // @ts-ignore
          disabled={record.status !== SUPPORT_TICKET_STATUS.PENDING}
          onClick={() => handleDeleteClick(record)}
          name={t(`Delete Support Ticket`)}
          icon={<IconsCommon.IconDelete />}
        />
      )}
      {hasPermission(permissions, PERMISSIONS.CREATE_AND_EDIT_SUPPORT_TICKET) && (
        <QMenuItem
          // @ts-ignore
          disabled={record.status === SUPPORT_TICKET_STATUS.PENDING}
          key={3}
          onClick={() => getTicketFeedback(record.id, record)}
          name={t(`Messages`)}
          icon={<MessageFilled style={{ marginLeft: '5px' }} />}
        />
      )}
    </Menu>
  );

  const getTicketFeedback = async (id: number, record: ISupportTicket | null) => {
    const response: any = await dispatch(actions.getTicketFeedback(id))
    if (record?.status !== SUPPORT_TICKET_STATUS.IN_REVIEW && (!response || !response.length)) {
      qnotification({
        type: NOTIFICATION_TYPES.INFO,
        message: t('common.Error', { ns: 'translationCommon' }),
        description: t('No messages are available'),
      })
      return
    }
    setMessagesModal({
      visible: true,
      data: (response && !!response.length) ? response.reverse() : [],
      record: record
    })
  }

  const sendMessage = async (message: string) => {
    const response: any = await dispatch(actions.sendTicketMessage(messagesModal?.record?.id || 0, message))
    setMessagesModal({
      ...messagesModal,
      data: response ? response.reverse() : response || []
    })
    form.resetFields()
    let objDiv: any = document.getElementById('chat');
    objDiv.scrollTop = objDiv.scrollHeight;
  }

  const onMessageSend = async () => {
    try {
      const values = await form.validateFields()
      if (!values.message) return true;
      sendMessage(values.message)
    } catch (error) {
      return error
    }
  }

  const modulesOptions: IOptionForSelect[] = [
    {
      label: t('New Feature Request'),
      value: 'NEW FEATURE REQUEST',
    },
    {
      label: t('Report a bug'),
      value: 'REPORT A BUG',
    },
    {
      label: t('General'),
      value: 'GENERAL',
    },
    {
      label: t('Others'),
      value: 'OTHERS',
    },
  ]

  const statusOptions: IOptionForSelect[] = [
    {
      label: t('Pending'),
      value: 'PENDING',
    },
    {
      label: t('In Review'),
      value: 'IN REVIEW',
    },
    {
      label: t('Resolved'),
      value: 'RESOLVED',
    },
    {
      label: t('Rejected'),
      value: 'REJECTED',
    },
  ]

  const handleChangeSearch = (event: any) => {
    dispatch(actions.setCurrentPage(1))
    setSearch(event.currentTarget.value)
  }

  const handleChangeSelectModule = (status: string[]) => {
    dispatch(actions.setCurrentPage(1))
    setModuleFilter(status)
  }

  const handleChangeSelectStatus = (status: string[]) => {
    dispatch(actions.setCurrentPage(1))
    setStatusFilter(status)
  }

  return (
    <>
      {isActionSupportTicketLoading && <SpinnerCenter />}
      <Row justify="space-between" className="mt-20">
        <Col className="qt-search">
          <h2>{t('Support/Feedback Tickets')}</h2>
        </Col>
        <Col>
          <QButton
            type="primary"
            disabled={!hasPermission(permissions, PERMISSIONS.CREATE_AND_EDIT_SUPPORT_TICKET)}
            onClick={() => handleEditModalOpen()}
          >
            +&nbsp;{t('common.Add New', { ns: 'translationCommon' })}
          </QButton>
        </Col>
      </Row>

      <Row
        className={'pt-15'}
        justify={'space-between'}
      >
        <Col className="qt-filter">
          <QInput
            className={`${isMobile && "full-width mb-5"}`}
            onChange={handleChangeSearch}
            value={search}
            placeholder={t('common.Search', { ns: 'translationCommon' })}
            size="large"
            prefix={<SearchOutlined />}
          />
        </Col>
        <Col className="qt-filter">
          <QSelect
            className={`${isMobile && "mb-5"}`}
            showArrow
            mode="multiple"
            onChange={handleChangeSelectModule}
            size="large"
            maxTagCount={2}
            maxTagTextLength={10}
            options={modulesOptions}
            placeholder={t('Filter by module')}
          />
        </Col>
        <Col className="qt-filter">
          <QSelect
            className={`${isMobile && "mb-5"}`}
            showArrow
            mode="multiple"
            onChange={handleChangeSelectStatus}
            size="large"
            maxTagCount={2}
            maxTagTextLength={7}
            options={statusOptions}
            placeholder={t('common.Filter_by_status', { ns: 'translationCommon' })}
          />
        </Col>
      </Row>

      <div className="pt-15 pb-30">
        <QTable
          columns={columns}
          dataSource={supportTicketsList}
          loading={supportTicketsListIsLoading}
          scroll={{ x: 990 }}
          // @ts-ignore
          onChange={handleTableChange}
          sortDirections={['ascend', 'descend', 'ascend']}
        />
      </div>

      <Row
        justify={isMobile ? "center" : "end"}
        className={`${isMobile ? "pt-15 pb-30" : "pb-20"}`}
      >
        <QPagination
          className="force-ltr"
          size="default"
          defaultCurrent={1}
          current={pages.currentPage}
          total={supportTicketsTotal}
          onChange={handleChangePage}
          showSizeChanger
        />
      </Row>

      <Modal
        wrapClassName="qu-modal"
        closeIcon={<IconsCommon.IconClose />}
        visible={addEditModal.visible}
        onCancel={handleEditModalClose}
        width={400}
        title={addEditModal.id ? <h3>{t('Update Support/Feedback Ticket')}</h3> : <h3>{t('Add New Support/Feedback Ticket')}</h3>}
        onOk={onCheck}
        footer={null}
      >
        <Form
          layout="vertical"
          form={form}
          validateMessages={validateMessages}
          onFinish={onCheck}
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                label={t('Module')}
                name="module"
                rules={[{ required: true }]}
                validateTrigger={['onChange', 'onBlur']}
              >
                <QSelect
                  size="large"
                  placeholder={t('Module')}
                  options={modulesOptions}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label={t('Title')}
                name="title"
                rules={[{ required: true, max: 255, whitespace: true }]}
                validateTrigger={['onChange', 'onBlur']}
              >
                <QInput placeholder={t('Title')} size="large" />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label={t('Description')}
                name="description"
                rules={[{ required: true, max: 500, whitespace: true }]}
                validateTrigger={['onChange', 'onBlur']}
              >
                <QTextArea rows={5} className="form-textarea" />
              </Form.Item>
            </Col>

            <div className="grid-row grid-row--jce create-update-button">
              <div className="grid-col--auto">
                <QButton type="primary" block htmlType="submit">
                  {addEditModal.id ? t('common.Update', { ns: "translationCommon" }) : t('common.Create', { ns: 'translationCommon' })}
                </QButton>
              </div>
            </div>
          </Row>
        </Form>
      </Modal>

      <Modal
        title={t('common.Delete', { ns: 'translationCommon' })}
        visible={deleteActionModal.visible}
        onOk={handleDeleteModalOkay}
        onCancel={handleDeleteModalClose}
        okText={t('common.OKAY', { ns: 'translationCommon' })}
        cancelText={t('common.Cancel', { ns: 'translationCommon' })}
      >
        <p>
          {t('Do you want to delete')} <strong> {deleteActionModal.title} </strong> {t('support/feedback ticket')}?
        </p>
      </Modal>

      <Modal
        wrapClassName="qu-modal"
        closeIcon={<IconsCommon.IconClose />}
        visible={viewModal.visible}
        onCancel={() => {
          setViewModal({ visible: false, data: null })
          history.push(PATHS.SUPPORT_TICKETS)
        }}
        width={500}
        title={<h3>{t('Support/Feedback Ticket Details')}</h3>}
        footer={null}
      >
        <Row gutter={32}>
          <Col span={24}>
            <Row gutter={32}>
              <Col xs={24} sm={10}>
                <strong>{t('Module')}:</strong>
              </Col>
              <Col xs={24} sm={14}>
                {viewModal?.data?.module}
              </Col>
            </Row>
          </Col>

          <Col span={24}>
            <Row gutter={32}>
              <Col xs={24} sm={10}>
                <strong>{t('Title')}:</strong>
              </Col>
              <Col xs={24} sm={14}>
                {viewModal?.data?.title}
              </Col>
            </Row>
          </Col>

          <Col span={24}>
            <Row gutter={32}>
              <Col xs={24} sm={10}>
                <strong>{t('Description')}:</strong>
              </Col>
              <Col xs={24} sm={14}>
                {viewModal?.data?.description}
              </Col>
            </Row>
          </Col>

          <Col span={24}>
            <Row gutter={32}>
              <Col xs={24} sm={10}>
                <strong>{t('Status')}:</strong>
              </Col>
              <Col xs={24} sm={14}>
                {viewModal?.data?.status}
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>

      <Modal
        wrapClassName="qu-modal"
        closeIcon={<IconsCommon.IconClose />}
        visible={messagesModal.visible}
        onCancel={() => {
          setMessagesModal({ visible: false, data: [], record: null })
          history.push(PATHS.SUPPORT_TICKETS)
        }}
        width={600}
        title={<h3>{t('Messages')}</h3>}
        footer={null}
      >
        <div id="message-container">
          <div className="main-chat-div">
            <ul id="chat">
              {
                messagesModal?.data?.map((ele, index) =>
                  <div key={index}>
                    {
                      !ele.isAdmin
                        ? <li className="me">
                          <div className="entete">
                            <h3 className="mr-5">
                              {moment.unix(Number(moment(ele.createdAt).format("X"))).fromNow()}
                            </h3>
                            <h2>{ele.employeeName}</h2>
                            <span className="status blue"></span>
                          </div>
                          <div className="message">
                            {ele.message}
                          </div>
                        </li>

                        : <li className="you">
                          <div className="entete">
                            <span className="status green"></span>
                            <h2 className="mr-5">{ele.employeeName}</h2>
                            <h3>
                              {moment.unix(Number(moment(ele.createdAt).format("X"))).fromNow()}
                            </h3>
                          </div>
                          <div className="message">
                            {ele.message}
                          </div>
                        </li>
                    }
                  </div>
                )
              }
            </ul>
            <div className="chat-footer">
              {messagesModal?.record?.status === SUPPORT_TICKET_STATUS.IN_REVIEW
                ? <Form
                  layout="vertical"
                  form={form}
                  validateMessages={validateMessages}
                  onFinish={onMessageSend}
                >
                  <Row gutter={16} align="bottom">
                    <Col xs={24} sm={20}>
                      <Form.Item
                        name="message"
                        rules={[{ max: 500, whitespace: true }]}
                        validateTrigger={['onChange', 'onBlur']}
                      >
                        <QTextArea placeholder={t('Type your message')} rows={1} className="form-textarea" size="large" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={4}>
                      <QButton type="primary" block htmlType="submit">
                        {t('brief.summary.Send', { ns: "translation" })}
                      </QButton>
                    </Col>
                  </Row>
                </Form>
                : <p>{t('You can no longer send a message to this user')}</p>
              }
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default SupportTicketsList
