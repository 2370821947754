import * as CONSTANTS from './invitedClients.constants'
import { IState, IRequestListElement } from './invitedClients.types'

const initialState: IState = {
  requestListLoading: true,
  requestsList: {
    results: [],
    total: 0,
  },
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case CONSTANTS.FETCH_REQUESTS_REQUEST:
      return {
        ...state,
        requestListLoading: true,
      }
    case CONSTANTS.APPROVE_REQUEST_REQUEST:
      return {
        ...state,
        requestListLoading: true,
      }
    case CONSTANTS.APPROVE_REQUEST_SUCCESS:
      const approvedUserId = action.payload
      return {
        ...state,
        requestsList: {
          results: state.requestsList.results.filter((e: IRequestListElement) => {
            return e.id !== approvedUserId
          }),
          total: state.requestsList.total,
        },
        requestListLoading: false,
      }
    case CONSTANTS.APPROVE_REQUEST_FAILURE:
      return {
        ...state,
        requestListLoading: false,
      }
    case CONSTANTS.DECLINE_REQUEST_REQUEST:
      return {
        ...state,
        requestListLoading: true,
      }
    case CONSTANTS.DECLINE_REQUEST_SUCCESS:
      const declinedUserId = action.payload
      return {
        ...state,
        requestsList: {
          results: state.requestsList.results.map((e: IRequestListElement) => {
            if (e.id === declinedUserId) {
              return {
                ...e,
                status: 'REJECTED',
              }
            }
            return e
          }),
          total: state.requestsList.total,
        },
        requestListLoading: false,
      }
    case CONSTANTS.DECLINE_REQUEST_FAILURE:
      return {
        ...state,
        requestListLoading: false,
      }
    case CONSTANTS.FETCH_REQUESTS_SUCCESS:
      const requestsList = action.payload
      return {
        ...state,
        requestsList,
        requestListLoading: false,
      }
    case CONSTANTS.FETCH_REQUESTS_FAILURE:
      return { ...state, requestListLoading: false }
    default:
      return state
  }
}
