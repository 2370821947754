import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SearchOutlined } from "@ant-design/icons";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useReduxDispatch } from "src/helpers";
import { getLocaleCost, renderRoundedNumber } from "src/helpers/currency";
import { isMobile } from "react-device-detect";
import * as actions from "../campaign.actions";
import {
  Col,
  Dropdown,
  IconsCommon,
  Menu,
  QAlert,
  QInput,
  QMenuItem,
  QPagination,
  QSelect,
  QTable,
  Row,
} from "quantum_components";
import { useSelector } from "react-redux";
import {
  getCampaignList,
  getCampaignListLoading,
  getCampaignParams,
  getCampaignStats,
  getPages,
  getTotalCampaignList,
} from "../campaign.selectors";
import useDebounce from "src/hooks/useDebounce";
import {
  IBriefOwner,
  ICampaignTable,
  ICampaignTableProduct,
  ICampaignTableService,
} from "../campaign.types";
import { LOCALIZATION_LANGUAGES } from "src/constants";
import {
  CAMPAIGN_STATUS,
  campaignStatusesLabel,
} from "../../../constants/campaign";
import { DISTRIBUTION_TYPE } from "../../../constants/brief";
import { ReactComponent as EyeIcon } from "../../../assets/icons/eye-icon.svg";
import { ReactComponent as EditIcon } from "src/assets/icons/edit-icon.svg";
import {
  getCurrency,
  getCurrentUserPermissionActions,
} from "../../core/core.selectors";
import { hasPermission, showListingActions } from "../../../helpers/permission";
import { PERMISSIONS } from "../../../constants/permission";
import { snakeCase } from "lodash";
import { ALERTS_MSG } from "src/constants/alerts";

export default function CampaignListContainer(): ReactElement {
  const { i18n } = useTranslation();
  const { t } = useTranslation([
    "translationCampaign",
    "translationCommon",
    "translation",
  ]);
  const history = useHistory();
  const dispatch = useReduxDispatch();
  const campaignList = useSelector(getCampaignList);
  const campaignListLoading = useSelector(getCampaignListLoading);
  const campaignStats = useSelector(getCampaignStats);
  const params = useSelector(getCampaignParams);
  const totalCampaignList = useSelector(getTotalCampaignList);
  const pages = useSelector(getPages);
  const permissions = useSelector(getCurrentUserPermissionActions);
  const currency = useSelector(getCurrency);

  const [statusTitle, setStatusTitle] = useState("");

  const location = useLocation();

  const { id } = useParams<{ id: any }>();
  const briefId = id;

  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 500);

  const tableLoading = {
    spinning: campaignListLoading,
    indicator: <div className="loader loader-small" />,
  };

  const colKey = {
    revenue: "earnings",
    status: "status",
  };

  const statusOptions = [
    {
      label: t("campaign-statuses-array.ONGOING"),
      value: CAMPAIGN_STATUS.ONGOING,
    },
    {
      label: t("campaign-statuses-array.COMPLETED"),
      value: CAMPAIGN_STATUS.COMPLETED,
    },
    {
      label: t("campaign-statuses-array.ALL"),
      value: CAMPAIGN_STATUS.ALL,
    },
  ];

  const menu = (id: number, status: CAMPAIGN_STATUS, isV2?:boolean) => (
    <Menu
      className={`user-menu-list light-grey-disabled ${
        i18n.language === LOCALIZATION_LANGUAGES.ARABIC ? "html-rtl" : ""
      }`}
    >
      {hasPermission(permissions, PERMISSIONS.CAMPAIGN_VIEW) && (
        <QMenuItem
          key={0}
          onClick={() => handleView(id,isV2)}
          name={t("common.View", { ns: "translationCommon" })}
          icon={<EyeIcon />}
        />
      )}
      {hasPermission(permissions, PERMISSIONS.CAMPAIGN_EDIT) && !isV2 && (
        <QMenuItem
          //@ts-ignore
          disabled={status === CAMPAIGN_STATUS.COMPLETED}
          key={1}
          onClick={() => handleEdit(id)}
          name={t("common.edit", { ns: "translationCommon" })}
          icon={<EditIcon />}
        />
      )}
    </Menu>
  );

  useEffect(() => {
    const status = location.search.replace("?status=", "") || "ONGOING";
    setStatusTitle(status);

    dispatch(
      actions.setParamsAndFetch(briefId, {
        ...params,
        status,
        search: debouncedSearch,
        offset: 0,
        limit: 10,
      })
    );
    dispatch(actions.setCurrentPage(1));
  }, [debouncedSearch, location]);

  const handleChangeSearch = (e: any) => {
    setSearch(e.currentTarget.value);
  };

  const handleChangePage = async (page: number, pageSize: number) => {
    await dispatch(actions.setCurrentPage(page));
    await dispatch(
      actions.setParamsAndFetch(briefId, {
        ...params,
        offset: (page - 1) * pageSize,
        limit: pageSize,
      })
    );
  };

  const handleChangeSelect = async (status: string) => {
    await dispatch(
      actions.setParamsAndFetch(briefId, {
        ...params,
        offset: 0,
        status: status,
      })
    );
  };

  const handleTableChange = async (
    pagination: any,
    filters: any,
    sorter: any
  ) => {
    const sortingParams = {
      direction: sorter.order.slice(0, -3),
      //@ts-ignore
      sort: colKey[sorter.columnKey],
    };
    const newParams = {
      ...params,
      ...sortingParams,
    };
    await dispatch(actions.setParamsAndFetch(briefId, newParams));
  };

  const handleView = (campaignId: number,isV2?:boolean) => {
    if(isV2){
      history.push(`campaigns/v3/${campaignId}${location.search}`)
    }else{
      history.push(`campaigns/${campaignId}${location.search}`)
    }
  };

  const handleEdit = (campaignId: number) => {
    history.push(`/campaigns/edit/${campaignId}`);
  };

  const translateDiscountText = (discountText: any) => {
    let buffer = new Array();
    buffer = discountText.split("%");

    if (buffer[0] == 0)
      return t("brief.cart." + snakeCase(buffer[1]), { ns: "translation" });

    return (
      buffer[0] +
      "% " +
      t("brief.cart." + snakeCase(buffer[1]), { ns: "translation" })
    );
  };

  const isDistribution = (service: string) =>
    service === DISTRIBUTION_TYPE.PRODUCT_SAMPLE ||
    service === DISTRIBUTION_TYPE.PRINTED_INSERT;

  const isMediaBuy = (service: string) =>
    service !== DISTRIBUTION_TYPE.PRODUCT_SAMPLE &&
    service !== DISTRIBUTION_TYPE.PRINTED_INSERT;

  const columns = [
    {
      title: t("list.Product"),
      dataIndex: "product",
      key: "product",
      width: "20%",
      fixed: "left",
      render: (product: ICampaignTableProduct) => {
        return (
          <div className="q-table-product">
            <div className="q-table-product_thumb">
              {product.image && <img src={product.image} alt={product.title} />}
            </div>
            <div className="q-table-product_info">
              <h3 className="q-table-product_title">{product.title}</h3>
              <div className="q-table-product_created">
                {t("common.Started", { ns: "translationCommon" })}{" "}
                {product.startDate}
              </div>
              {product.discountText && (
                <div className="text-green line-height-10 font-size-12">
                  {translateDiscountText(product.discountText)}
                </div>
              )}
            </div>
          </div>
        );
      },
    },
    {
      title: t("list.Owner"),
      dataIndex: "owner",
      key: "owner",
      width: "16%",
      render: (owner: IBriefOwner) => {
        return (
          <React.Fragment>
            <div>
              <div>
                {owner.firstName} {owner.lastName}
              </div>
              <div>{owner.companyName}</div>
            </div>
          </React.Fragment>
        );
      },
    },
    {
      title: t("list.Des. Start Date"),
      dataIndex: "service",
      key: "service",
      width: "16%",
      render: (service: ICampaignTableService, raw: any) => {
        const distributions = service.types.filter(isDistribution);
        const mediaBuys = service.types.filter(isMediaBuy);

        return (
          <React.Fragment>
            {distributions.length > 0 && (
              <div>
                <div>{service.productStartDate}</div>
                <div>{service.productDuration}</div>
              </div>
            )}

            {mediaBuys.length > 0 && (
              <div>
                <div>
                  {service.mediaStartDate == "-"
                    ? service.mediaStartDateV2 == "-"
                      ? service.mediaStartDate
                      : service.mediaStartDateV2
                    : service.mediaStartDate}
                </div>
                <div>{service.mediaDuration}</div>
              </div>
            )}
          </React.Fragment>
        );
      },
    },
    {
      title: t("list.Earnings"),
      dataIndex: "service",
      key: "revenue",
      width: "16%",
      sorter: true,
      render: (service: ICampaignTableService) => (
        <span className="font-size-14 font-weight-600">{service.revenue}</span>
      ),
    },
    {
      title: t("list.Assignees"),
      dataIndex: "assignedUsers",
      key: "assignedUsers",
      width: "16%",
      render: (list: any) => (
        <ul className="q-table-list">
          {list && list.length ? (
            list.map((user: any, i: any) => {
              return (
                <li key={i} className="q-table-list_item">
                  <span className="check-icon">
                    <IconsCommon.IconUser />
                  </span>
                  {user.firstName} {user.lastName}
                </li>
              );
            })
          ) : (
            <span>-</span>
          )}
        </ul>
      ),
    },
    {
      title: t("list.Status"),
      dataIndex: "status",
      key: "status",
      width: "16%",
      sorter: true,
      render: (status: CAMPAIGN_STATUS, record: ICampaignTable) => {
        const actions = [PERMISSIONS.CAMPAIGN_VIEW, PERMISSIONS.CAMPAIGN_EDIT];
        const showActions = showListingActions(permissions, actions);
        return (
          <div>
            <span
              className={`status-label ${
                campaignStatusesLabel[status] &&
                campaignStatusesLabel[status].classStyle
              }`}
            >
              {campaignStatusesLabel[status]
                ? t(campaignStatusesLabel[status].label)
                : ""}
            </span>
            {showActions && (
              /*QUA-1449, QP-492 - prevent event bubbling to onRowClick*/
              <div onClick={(e) => e.stopPropagation()}>
                <Dropdown
                  overlay={menu(record.key, status, record.version == "2")}
                  trigger={["click"]}
                  placement="bottomRight"
                >
                  <div className="q-table_menu">
                    <IconsCommon.IconEllipsisV />
                  </div>
                </Dropdown>
              </div>
            )}
          </div>
        );
      },
    },
  ];

  const handleOnRowClick = (campaign: ICampaignTable, rowIndex: Number) => ({
    onClick: (event: any) => {
      // @ts-ignore
      document.getElementsByClassName("q-table_menu")[rowIndex].click();
      // history.push(`/campaigns/${campaign.key}`)
    },
  });

  return (
    <div className="qt">
      <div className="qu-statistic-wrapper">
        <h1>
          {t(`campaign-statuses-array-lower.${statusTitle}`)} {t("title")}
        </h1>
        <ul className="qu-statistic-list">
          <li className="qu-statistic-item">
            <span className="qu-statistic-value">
              {renderRoundedNumber(campaignStats.upcoming, currency)}
            </span>
            <span className="qu-statistic-name">
              {t("list.Upcoming Earnings")}
            </span>
          </li>
          <li className="qu-statistic-item">
            <span className="qu-statistic-value">
              {renderRoundedNumber(campaignStats.lifetime, currency)}
            </span>
            <span className="qu-statistic-name">
              {t("list.Lifetime Earnings")}
            </span>
          </li>
        </ul>
      </div>
      {hasPermission(permissions, PERMISSIONS.BRIEFS_ALL_ONLY_ASSIGNED) && (
        <Row>
          <Col>
            <QAlert
              message={t(
                "alert.ALERT_YOU_ARE_VIEWING_CAMPAIGNS_WHICH_ARE_ASSIGNED_TO_YOU",
                {
                  ns: "translationCommon",
                }
              )}
              type="info"
              className="centered pb-15"
            />
          </Col>
        </Row>
      )}
      <Row className="qt-header" justify="space-between">
        <Col className="qt-search">
          <QInput
            className={`${isMobile && "full-width"}`}
            value={search}
            onChange={handleChangeSearch}
            placeholder={t("common.Search", { ns: "translationCommon" })}
            size="large"
            prefix={<SearchOutlined />}
          />
        </Col>
        {/* <Col className="qt-filter">
          <QSelect onChange={handleChangeSelect} size="large" options={statusOptions} placeholder={t('list.Filter by status')} />
        </Col> */}
      </Row>
      <div className="qt-body">
        <QTable
          //@ts-ignore
          onRow={handleOnRowClick}
          //@ts-ignore
          loading={tableLoading}
          columns={columns}
          dataSource={campaignList}
          scroll={{ x: 990 }}
          rowClassName="q-table cursorPointer"
          sortDirections={["ascend", "descend", "ascend"]}
          onChange={handleTableChange}
        />
      </div>
      <div className="qt-footer">
        <QPagination
          size="small"
          defaultCurrent={1}
          current={pages.currentPage}
          total={totalCampaignList}
          onChange={handleChangePage}
        />
      </div>
    </div>
  );
}
