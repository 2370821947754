export default {
  ROOT: '/',
  NOT_FOUND: '404',
  SERVER_ERROR: '500',
  SIGN_IN: '/login',
  SIGN_UP: '/signup',
  RESET_PASSWORD: '/reset-password',
  RESTORE_PASSWORD: '/restore-password/:token',
  UPDATE_PASSWORD: '/update-password',

  TARGETING_KEYS:'/targeting-keys',
  NATIVE_TEMPLATES:'/native-templates',

  CALENDAR: '/calendar',

  PROMOTIONS: '/promotions',

  WORKFLOW: '/workflow',

  EMPLOYEES: '/employees',
  ACTIVITIES: '/activities',
  AGE_GROUPS: '/age-groups',
  SEGMENTS: '/segments',
  REFERRALS: '/referrals',
  DISCOUNT_CODES: '/discount-codes',
  SUBSCRIPTION_HISTORY: '/subscription-history',
  WALLET: '/wallet',
  SUPPORT_TICKETS: '/support-tickets',
  SUPPORT_TICKETS_ID: '/support-tickets/:id',
  SUPPORT_TICKETS_CHAT_ID: '/support-tickets/chat/:chatId',
  LEADS: '/leads',
  ADMIN_SETTINGS: '/admin-settings',
  PRODUCTS: '/products',
  PUBLISHERS: '/publishers',

  //SIGN UP REQUESTS
  REQUESTS_PARTNERS: '/requests/partners',
  REQUESTS_CLIENTS: '/requests/clients',

  //CLIENT
  CREATE_CLIENT: '/users/clients/create',
  CLIENTS: '/users/clients',
  CLIENT_PROFILES: '/users/clients/profile/:id',
  ADD_CLIENT: '/users/clients/create/',
  INVITE_CLIENT: '/users/clients/invite/',

  //PARTNER
  PARTNERS: '/users/partners',
  PARTNER_PROFILES: '/users/partners/partner-profile',
  ADD_PARTNER_PROPERTIES: '/users/partners/add-properties/:id',

  //SERVICES
  SERVICES: '/services',
  ADD_SERVICE: '/services/create',
  MANAGE_SERVICES: '/services/custom',

  //MARKETPLACE
  MARKETPLACE: '/marketplace',

  //ROLES
  ROLES: '/roles',
  ROLE_EDIT: '/roles/edit/:id',
  ROLE_CREATE: '/roles/create',

  //BRIEFS
  BRIEF: '/briefs',
  CREATE_BRIEF: '/briefs/create',
  BRIEF_VIEW: '/briefs/view/:id',
  BRIEF_EDIT: '/briefs/edit/:id',
  BRIEF_EDIT_V3: '/briefs/v3/edit/:id',
  BRIEF_REJECTED: '/briefs/rejected/:id',

  TESTAHEL_BRIEF: '/testahel-brief',
  TESTAHEL_BRIEF_VIEW: '/testahel-brief/view/:id',
  TESTAHEL_BRIEF_CREATE: '/testahel-brief/create',
  TESTAHEL_BRIEF_EDIT: '/testahel-brief/edit/:id',

  //BRIEF REQUESTS
  REQUEST_DETAILS: '/briefs/:briefId/requests/:requestId',
  REQUESTS_ALL: '/requests',

  //CAMPAIGNS
  CAMPAIGNS: '/campaigns',
  COMPLETED_CAMPAIGNS: '/campaigns?status=COMPLETED',
  COMPLETED_CAMPAIGNS_DETAIL: '?status=COMPLETED',
  CAMPAIGN_DETAILS: '/campaigns/:id',
  CAMPAIGN_DETAILS_V3: '/campaigns/v3/:id',
  CAMPAIGN_EDIT: '/campaigns/edit/:id',

  //INVITED CLIENTS
  INVITED_SIGNUPS: '/invitedClients/clients',
  INVITED_CLIENTS_BRIEFS: '/invitedClients/briefs',
  INVITED_CLIENTS_BRIEFS_VIEW: '/invitedClients/briefs/view/:id',
  INVITED_CLIENTS_BRIEFS_EDIT: '/invitedClients/briefs/edit/:id',

  //BILLINGS
  BILLINGS: '/billings',

  // PRIVACY_POLICY
  PRIVACY_POLICY: '/privacy-policy',

  // DISCLAIMER
  DISCLAIMER: '/disclaimer',

  // RELEASE_NOTES
  RELEASE_NOTES: '/release-notes',
}
