import React, { ReactElement } from 'react'
import { IconsCommon, Modal } from 'quantum_components'

const closeIcon = <IconsCommon.IconClose />

interface IProps {
  modalOpen: boolean
  timelineCaption: string
  timelineDate: string
  imgUrl: string
  onClose: () => void
}

export default function ImageModal({
  modalOpen = false,
  timelineCaption,
  timelineDate,
  imgUrl,
  onClose,
}: IProps): ReactElement {
  return (
    <Modal
      wrapClassName="qu-modal"
      title={
        <div className="qu-preview-head">
          <h3>{timelineCaption}</h3>
          <span className="tc--light">{timelineDate}</span>
        </div>
      }
      width={1000}
      closeIcon={closeIcon}
      visible={modalOpen}
      onCancel={onClose}
      footer={false}
    >
      <div className="qu-preview-body">
        <img className="preview-img" src={imgUrl} alt="img" />
      </div>
    </Modal>
  )
}
