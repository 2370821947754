import { PARTNER_STEPS } from '../../../constants/profile'
import { IBank, IMetrics, ISocialMediaLinks } from '../publicProfile/publicProfile.types'
import { CURRENCY } from '../../../constants/currencies'
import { GENDER, MEDIA_TYPES } from 'src/constants/brief'
import { MediaSubtype } from '../../brief/brief.types'
import IStatusedMessage from 'src/interfaces/IStatusedMessage'
import { ReactElement } from 'react'

export interface IPublicProfile {
  id: number
  userId: number
  listingShortDescription: string
  companyLogo: string
  previewCardImage: string
  listingHeaderImage: string
  highlights: string[]
  metricsMarketing: string[]
}

export interface IPartner {
  unfinishedSteps: PARTNER_STEPS[]
  filledSteps: PARTNER_STEPS[]
  currentStep: PARTNER_STEPS
  form: IForm
  settings: IPartnerSettings
  partnerProfilePercent: number
  fetchPartnerLoading: boolean
  formBoolean: IFormBoolean
  imagesUploading: boolean
  imagesUploadingError: string
  imagesRemoving: boolean
  imagesRemovingError: string
  customMediaServices: ICustomMediaType[]
}
export interface IPartnerProfileResponse extends IStatusedMessage {
  data: {
    content: IForm
  }
  message: string | null
}

export interface ICustomMediaType {
  id?: number
  name: string
  subtype: string | null
  description: string
  availableFields: string[]
  createdAt?: string
  updatedAt?: string
  createdBy?: string
  updatedBy?: string
  isRemoved?: boolean
}

export interface IForm {
  id?: number
  companyName: string
  companyDescription: string
  companyDescriptionAr: string
  companyWebsite: string
  headquarterAddress: string
  metrics: IMetrics
  socialMediaLinks: ISocialMediaLinks
  isAutomaticallyApprovedRequest: boolean
  productCategories: string[]
  amountOfOrders: number
  costOfOrder: number
  currency: CURRENCY
  taxTreatment: string
  cr: string

  bankDetails: IBank

  customInvoicing: string
  webhookUrl: string
  webhookAuth: string

  genderFocus: { [x: string]: number }
  ageGroups: { [x: string]: number }
  segments: { [x: string]: number }
  partnerGenders: GENDER[] | null
  partnerAgeGroups: string[] | null
  partnerTargetSegments: string[] | null

  countries: string[]
  cities: string[]
  isSampleDistribution: boolean
  pricePerSample?: number
  hasDynamicSamplePricing: boolean
  hasDistributionTypePricing: boolean
  hasSampleProductTypePricing: boolean
  dynamicSamplePriceTypes?: [] | null
  dynamicSamplePrices?: any
  isInsertDistribution: boolean
  pricePerInsert: number
  warehouseAddresses: string[]
  isMediaBuy: boolean
  impressionRate: number
  conversionRate: number
  reachRate: number
  advertisementLanguage: string[]
  mbPricingModel: string
  mediaBuyTypes: IMediaBuyTypesElement[]
  publicProfile: IPublicProfile

  userId?: number
  createdAt?: string
  updatedAt?: string
  createdBy?: number
  updatedBy?: number
  completion?: number
  capability?: string // ?
  industry?: string // ?
  additional?: {
    stickerCost: number
  }
  isExclusive?: boolean
  logisticInsertRates?: { [x: string]: number }
  logisticSampleRates?: { [x: string]: number }
  isMarketplaceShown?: boolean
}
export interface IMediaBuyTypesElement {
  name: MEDIA_TYPES
  description?: string
  previewImage?: string
  avgImpressions?: number
  priceForDuration?: string
  width?: number
  height?: number
  minOrderValue?: number
  executionType?: string
  pricePerExecution?: number
  priceForImpression?: string
  pricePerPush?: string
  pricePerActivity?: string
  subName?: string
  duration?: string
  adTitleCharacterLimit?: number
  adDescriptionCharacterLimit?: number
  marketingMetrics?: string[]
  dailyImpressions?: number
  costPerImpression?: number
  dailyClicks?: number
  costPerClick?: number
  dailyReach?: number
  costPerReach?: number
  isHide?: boolean
  adspace?: object | null
}

export interface IFormBoolean {
  companyName: boolean
  companyDescription: boolean
  companyDescriptionAr: boolean
  companyWebsite: boolean
  headquarterAddress: boolean
  metrics: boolean
  socialMediaLinks: boolean
  isAutomaticallyApprovedRequest: boolean
  Looks: boolean // monthlyVisitors: boolean
  Leads: boolean //monthlyCustomers: boolean
  productCategories: boolean
  amountOfOrders: boolean
  costOfOrder: boolean
  currency: boolean
  partnerGenders: boolean
  partnerAgeGroups: boolean
  partnerTargetSegments: boolean
  countries: boolean
  cities: boolean
  isSampleDistribution: boolean
  pricePerSample: boolean
  isInsertDistribution: boolean
  pricePerInsert: boolean
  warehouseAddresses: boolean
  isMediaBuy: boolean
  mediaBuyTypes?: boolean
  account_currency: boolean
  account_number: boolean
  address: boolean
  bank_address: boolean
  bank_name: boolean
  iban_number: boolean
  swift_code: boolean
}

export interface IPartnerSettings {
  ageGroups: string[]
  industries: string[]
  currencies: string[]
  segments: string[]
  mediaTypes: string[]
  countries: ICountry[]
  rejectionReasons: string[]
  mediaSubtypes: MediaSubtype[]
}

export interface ICountry {
  id: number
  name: string
  name_ar: string
  city: ICity[]
}

export interface ICity {
  id: number
  name: string
  name_ar: string
  country_id: number
}

export interface IAgeGroup {
  fullLabel: string
  label: string
  sublabel: string
  img: string
}

export interface IMediaTypePictures {
  [MEDIA_TYPES.HOME_BANNERS]: string
  [MEDIA_TYPES.PRICE_OFF_PROMO]: string
  [MEDIA_TYPES.POPUP_WINDOW]: string
  [MEDIA_TYPES.BRAND_STORE]: string
  [MEDIA_TYPES.PUSH_NOTIFICATIONS]: string
  [MEDIA_TYPES.FEED]: string
  [MEDIA_TYPES.SURVEY]: string
  [MEDIA_TYPES.CASHBACK]: string
  [MEDIA_TYPES.SOCIAL_MEDIA]: string
  [MEDIA_TYPES.SKU]: string
  [MEDIA_TYPES.FREE_DELIVERY]: string
  [MEDIA_TYPES.DISTRIBUTION]: string
}

export const BANNER_SUB_TYPES = {
  HOME_PAGE_WEB: 'Homepage banner (Web)',
  HOME_PAGE_APP: 'Homepage banner (App)',
  CATEGORY_PAGE_WEB: 'Category Page Banner (Web)',
  CATEGORY_PAGE_APP: 'Category Page Banner (App)',
  EMAIL_BLAST_BANNER: 'Email Blast Banner',
}
export const SKU_SUB_TYPES = {
  IN_OFFER: 'In offer page',
  ALL_ITEMS: 'All items Page',
  CATEGORY: 'Category Page',
  SUB_CATEGORY_PAGE: 'Sub-Category Page',
  OTHER: 'Other Upliftings',
}
export const SURVEY_SUB_TYPES = {
  IN_APP_SURVEY: 'In-app Survey',
  EMAIL: 'Email',
  SMS: 'SMS',
}
export const SOCIAL_MEDIA_ADS_SUB_TYPES = {
  TWITTER: 'Twitter',
  FACEBOOK: 'Facebook',
  SNAPCHAT: 'Snapchat',
  INSTAGRAM: 'Instagram',
  YOUTUBE: 'Youtube',
}
export enum PRICE_FIELDS_MEDIA_TYPES {
  ACTIVITY = 'servicePriceForActivity',
  DURATION = 'servicePriceForDuration',
  IMPRESSION = 'servicePriceForImpression',
  PUSH = 'servicePriceForPush',
}
export const MEDIA_TYPES_NAMES_FOR_PRICE = {
  [MEDIA_TYPES.HOME_BANNERS]: PRICE_FIELDS_MEDIA_TYPES.DURATION,
  [MEDIA_TYPES.BRAND_STORE]: PRICE_FIELDS_MEDIA_TYPES.DURATION,
  [MEDIA_TYPES.CASHBACK]: PRICE_FIELDS_MEDIA_TYPES.ACTIVITY,
  [MEDIA_TYPES.FREE_DELIVERY]: PRICE_FIELDS_MEDIA_TYPES.ACTIVITY,
  [MEDIA_TYPES.POPUP_WINDOW]: PRICE_FIELDS_MEDIA_TYPES.PUSH,
  [MEDIA_TYPES.PRICE_OFF_PROMO]: PRICE_FIELDS_MEDIA_TYPES.ACTIVITY,
  [MEDIA_TYPES.PUSH_NOTIFICATIONS]: PRICE_FIELDS_MEDIA_TYPES.PUSH,
  [MEDIA_TYPES.FEED]: PRICE_FIELDS_MEDIA_TYPES.DURATION,
  // [MEDIA_TYPES.FEED]: PRICE_FIELDS_MEDIA_TYPES.ACTIVITY,
  [MEDIA_TYPES.SKU]: PRICE_FIELDS_MEDIA_TYPES.DURATION,
  [MEDIA_TYPES.SOCIAL_MEDIA]: PRICE_FIELDS_MEDIA_TYPES.ACTIVITY,
  [MEDIA_TYPES.SURVEY]: PRICE_FIELDS_MEDIA_TYPES.ACTIVITY,
}

export enum FIELDS_MEDIA_TYPES {
  PRICE_ACTIVITY = 'pricePerActivity',
  PRICE_DURATION = 'priceForDuration',
  PRICE_IMPRESSION = 'priceForImpression',
  PRICE_PUSH = 'pricePerPush',
  DESCRIPTION = 'description',
  AVG_IMPRESSIONS = 'avgImpressions',
  WIDTH = 'width',
  HEIGHT = 'height',
  DURATION = 'duration',
  MIN_ORDER_VALUE = 'minOrderValue',
  IMAGE = 'previewImage',
  AD_TITLE_CHARACTER_LIMIT = 'adTitleCharacterLimit',
  AD_DESCRIPTION_CHARACTER_LIMIT = 'adDescriptionCharacterLimit',
  MARKETING_METRICS = 'marketingMetrics',
  DAILY_IMPRESSION = 'dailyImpressions',
  CPM = 'costPerImpression',
  DAILY_CLICKS = 'dailyClicks',
  CPC = 'costPerClick',
  DAILY_REACH = 'dailyReach',
  CPR = 'costPerReach',
}

export const FIELDS_MEDIA_TYPES_LABEL = {
  [FIELDS_MEDIA_TYPES.AVG_IMPRESSIONS]: 'Avg Impressions',
  [FIELDS_MEDIA_TYPES.DESCRIPTION]: 'Description',
  [FIELDS_MEDIA_TYPES.DURATION]: 'Duration',
  [FIELDS_MEDIA_TYPES.HEIGHT]: 'Height',
  [FIELDS_MEDIA_TYPES.IMAGE]: 'Image',
  [FIELDS_MEDIA_TYPES.MIN_ORDER_VALUE]: 'Minimum Order Value',
  [FIELDS_MEDIA_TYPES.PRICE_ACTIVITY]: 'Price per Activity',
  [FIELDS_MEDIA_TYPES.PRICE_DURATION]: 'Price for Duration',
  [FIELDS_MEDIA_TYPES.PRICE_IMPRESSION]: 'Price for Impression',
  [FIELDS_MEDIA_TYPES.PRICE_PUSH]: 'Price for Push',
  [FIELDS_MEDIA_TYPES.WIDTH]: 'Width',
  [FIELDS_MEDIA_TYPES.AD_TITLE_CHARACTER_LIMIT]: 'Ad Title Character Limit',
  [FIELDS_MEDIA_TYPES.AD_DESCRIPTION_CHARACTER_LIMIT]: 'Ad Description Character Limit',
  [FIELDS_MEDIA_TYPES.MARKETING_METRICS]: 'Marketing Metrics',
  [FIELDS_MEDIA_TYPES.DAILY_IMPRESSION]: 'Daily Impression',
  [FIELDS_MEDIA_TYPES.CPM]: 'CPM',
  [FIELDS_MEDIA_TYPES.DAILY_CLICKS]: 'Daily Clicks',
  [FIELDS_MEDIA_TYPES.CPC]: 'CPC',
  [FIELDS_MEDIA_TYPES.DAILY_REACH]: 'Daily Reach',
  [FIELDS_MEDIA_TYPES.CPR]: 'CPR',
}

export interface IMediaBuyTypesTableColumns {
  key: number,
  checkbox: boolean,
  image: ReactElement | any,
  serviceName: string,
  serviceNameEN: string,
  mediaSubServices: IMediaBuyTypesElement[],
}
