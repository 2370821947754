const MODULE_NAME = 'admin/partner'
const SUB_MODULE_NAME = 'admin'

export const createCustomMediaUrl = ():string => `${SUB_MODULE_NAME}/custom-media/create`
export const updateCustomMediaUrl = ():string => `${SUB_MODULE_NAME}/custom-media/edit`
export const deleteCustomMediaUrl = (serviceId:number):string => `${SUB_MODULE_NAME}/custom-media/delete/${serviceId}`
export const getAllCustomMediaUrl = ():string => `${SUB_MODULE_NAME}/custom-media/list`

export const getPublicProfileUrl = (userId: string): string => `${SUB_MODULE_NAME}/public-profile/${userId}`

export const getPartnerProfileUrl = (userId: string): string => `${MODULE_NAME}-profile/${userId}`

export const getPublicProfileSaveUpdatesUrl = (userId: string): string =>
  `${SUB_MODULE_NAME}/public-profile/save/${userId}`

export const getPartnerProfileSaveUpdatesUrl = (userId: string): string => `${MODULE_NAME}-profile/save/${userId}`

export const getPublicProfileUploadUrl = (userId: string): string =>
  `${SUB_MODULE_NAME}/public-profile/upload/${userId}`

export const getPublicProfileUploadImage = (): string => `media/upload`

export const getPartnerSettingsUrl = (): string => `${MODULE_NAME}/get-settings`

export const getPropertiesByUserIdUrl = (id: number): string => `${MODULE_NAME}-properties/user-id/${id}`

export const getSavePropertiesUrl = (): string => `${MODULE_NAME}/upsert-properties-user-id`

export const getPartnerDetailsUrl = (userId: number): string => `${MODULE_NAME}/marketplace-details/${userId}`

export const getPartnerRequestsListUrl = (userId: string | number): string =>
  `${SUB_MODULE_NAME}/requests/list/${userId}`

export const getBriefRequestsListUrl = (briefId: number): string => `${SUB_MODULE_NAME}/requests/brief/${briefId}`

export const getPartnerRequestDetailsUrl = (requestId: number): string =>
  `${SUB_MODULE_NAME}/requests/details/${requestId}`

export const getAdminRequestActionUrl = (): string => `${SUB_MODULE_NAME}/requests/action-admin`

export const getMediaTypesUploadUrl = (): string => `${MODULE_NAME}/upload`

export const userGeneratedPasswordUrl = (): string => `${SUB_MODULE_NAME}/users/reset-password`

export const postInviteClient = (): string => `${SUB_MODULE_NAME}/invite-client/send-invite`
export const getInviteClient = (): string => `${SUB_MODULE_NAME}/invite-client`

export const getActivateClientAccountUrl = (userId: number): string => `${SUB_MODULE_NAME}/users/activate-account/${userId}`
