import { createSelector } from "reselect";
import { MODULE_NAME } from "./create-client.constants";
import { IState } from "./create-client.types";

const selectState = (state: { [MODULE_NAME]: any }) => state[MODULE_NAME];

export const getCurrentState = createSelector(
  selectState,
  (state: IState): any => { return {isLoading:state.isLoading,error:state.error}}
);


