import React, { ReactElement } from "react";
import { IBrief, IPotentialAudienceReach } from "../../brief.types";
import {
  BRIEF_STEPS,
  BRIEF_TYPES,
  CREATE_BRIEF_STEPS,
  PROMOTION_TYPE,
} from "../../../../constants/brief";
import moment from "moment";
import { actions } from "../../brief.module";
import { useReduxDispatch } from "../../../../helpers";
import { FORMATS } from "../../../../constants";
import { isInsert, isSample } from "../../../../helpers/brief";
import {
  getLocaleCost,
  getLocaleCostWithoutCurrencyNDecimals,
} from "../../../../helpers/currency";
import { getBriefSettings, getCurrentStep } from "../../brief.selectors";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
export interface IProps {
  brief: IBrief;
  potentialAudienceReachData?: IPotentialAudienceReach;
}

export default function BriefSummary({
  brief,
  potentialAudienceReachData,
}: IProps): ReactElement {
  const { t } = useTranslation(["translationABrief", "translationProfile"]);
  const productFields = brief[BRIEF_STEPS.PRODUCT];
  const targetingFields = brief[BRIEF_STEPS.TARGETING];
  const servicesFields = brief[BRIEF_STEPS.SERVICES];
  const mediaBuyFields = brief[BRIEF_STEPS.MEDIA_BUY];
  const lastFilledIndex = brief.lastFilledStep
    ? CREATE_BRIEF_STEPS.indexOf(brief.lastFilledStep)
    : 0;
  const serviceStepIndex = CREATE_BRIEF_STEPS.indexOf(BRIEF_STEPS.SERVICES);

  const dispatch = useReduxDispatch();

  const setCurrentStep = (step: BRIEF_STEPS) => {
    dispatch(actions.setCurrentStep(step));
  };

  const onlyInsert = isInsert(productFields.distributionItem);
  const onlySample = isSample(productFields.distributionItem);
  const hasSample = brief[BRIEF_STEPS.TYPE].briefTypes.includes(
    BRIEF_TYPES.PRODUCT_SAMPLE
  );
  const hasMediaBuy = brief[BRIEF_STEPS.TYPE].briefTypes.includes(
    BRIEF_TYPES.MEDIA_BUYING
  );

  const currentStep = useSelector(getCurrentStep);
  const briefSettings = useSelector(getBriefSettings);


  return (
    <div>
      {/* {currentStep === BRIEF_STEPS.TARGETING && (hasSample || hasMediaBuy) && (
        <div>
          {hasSample && (
            <div className="cart-summary potential-audience-main">
              <div className="h6 cart-summary__heading potential-audience-title">Potential Audience Reach</div>
              <div className="h2 cart-summary__heading potential-audience-count">
                {getLocaleCostWithoutCurrencyNDecimals(potentialAudienceReachData?.totalSampleAudience)}
              </div>
              <hr className="potential-audience-hr" />
              <p className="p--sm cart-summary__ulist potential-audience-text">
                {potentialAudienceReachData?.messageSampleDistribution}
              </p>
            </div>
          )}

          {hasMediaBuy && (
            <div className="cart-summary potential-audience-main">
              <div className="h6 cart-summary__heading potential-audience-title">Potential Audience Reach</div>
              <div className="h2 cart-summary__heading potential-audience-count">
                {getLocaleCostWithoutCurrencyNDecimals(potentialAudienceReachData?.totalMediaBuyAudience)}
              </div>
              <hr className="potential-audience-hr" />
              <p className="p--sm cart-summary__ulist potential-audience-text">
                {potentialAudienceReachData?.messageMediaBuy}
              </p>
            </div>
          )}
        </div>
      )} */}

      {productFields.campaignName != null && (
        <div className="cart-summary">
          {/* {brief[BRIEF_STEPS.TYPE].briefTypes.length > 0 && (
          <>
            <a onClick={() => setCurrentStep(BRIEF_STEPS.TYPE)} className="p p--xs tc--light cart-summary__edit">
              Edit
            </a>
            <div className="h6 cart-summary__heading">Services</div>
            <ul className="p p--sm cart-summary__ulist">
              {hasSample && <li>Product Sample Distribution</li>}
              {hasMediaBuy && <li>Media-Buying</li>}
            </ul>
          </>
        )} */}
          {/* {productFields && productFields.name && (
          <>
            <hr className="cart-summary__divider" />
            <a onClick={() => setCurrentStep(BRIEF_STEPS.PRODUCT)} className="p p--xs tc--light cart-summary__edit">
              Edit
            </a>
            <div className="h6 cart-summary__heading">Product Brief</div>
            <dl className="cart-summary__dlist mb-0">
              <dt>Name</dt>
              <dd>{productFields.name}</dd>
              <dt>Type</dt>
              <dd>{productFields.productType}</dd>
              {hasSample && (
                <>
                  <dt>Format</dt>
                  <dd>{productFields.distributionItem}</dd>
                  <dt>Quantity</dt>
                  <dd>{productFields.quantity}</dd>
                  <dt>Des. Start</dt>
                  <dd>
                    {productFields.briefStartDate &&
                      moment(productFields.briefStartDate, FORMATS.DATE_LL_FORMAT).format(FORMATS.DATE_LL_FORMAT)}
                  </dd>
                  <dt>Duration</dt>
                  <dd>{productFields.duration}</dd>
                </>
              )}
            </dl>
          </>
        )} */}
          {hasMediaBuy && mediaBuyFields && mediaBuyFields.mediaStartDate && (
            <>
              {/* <hr className="cart-summary__divider" /> */}
              <a
                onClick={() => setCurrentStep(BRIEF_STEPS.PRODUCT)}
                className="p p--xs tc--light cart-summary__edit"
              >
                {t(`edit`)}
              </a>
              <div className="h6 cart-summary__heading">
                {t(`campaign_info`)}
              </div>
              <dl className="cart-summary__dlist">
                <dt>{t(`camp_name`)}</dt>
                <dd>{productFields.campaignName}</dd>
                <div className="clearfix"></div>
                <dt>{t(`promoting`)}</dt>
                <dd>{t(`${productFields.campaignPromotionType}`)}</dd>
                <div className="clearfix"></div>
                {productFields.campaignPromotionType ===
                  PROMOTION_TYPE.BRAND && (
                  <>
                    <dt>{t(`name`)}</dt>
                    <dd>{productFields.name}</dd>
                    <dt>{t(`desc`)}</dt>
                    <dd>{productFields.productDescription}</dd>
                    <div className="clearfix"></div>
                  </>
                )}
                <dt>{t(`des_start`)}</dt>
                <dd>
                  {mediaBuyFields.mediaStartDate &&
                    moment(
                      mediaBuyFields.mediaStartDate,
                      FORMATS.DATE_LL_FORMAT
                    ).format(FORMATS.DATE_LL_FORMAT)}
                </dd>
                <div className="clearfix"></div>
              </dl>
            </>
          )}
          {targetingFields &&
            targetingFields.targetFocus &&
            targetingFields.targetFocus.length > 0 && (
              <>
                <hr className="cart-summary__divider" />
                <a
                  onClick={() => setCurrentStep(BRIEF_STEPS.TARGETING)}
                  className="p p--xs tc--light cart-summary__edit"
                >
                  {t(`edit`)}
                </a>
                <div className="h6 cart-summary__heading">{t(`targeting`)}</div>
                <dl className="cart-summary__dlist mb-0">
                  <dt>{t(`ages`)}</dt>
                  <dd>
                    {targetingFields.targetAgeGroups
                      ?.map((group: string) =>
                        t(
                          `profile.partner.customer_and_locale.age-groups.${group.substring(
                            group.indexOf(" ") + 1,
                            group.length
                          )}`,
                          { ns: "translationProfile" }
                        )
                      )
                      .join(", ")}
                  </dd>
                  {/* <dt>Segments</dt>
              <dd>{targetingFields.targetSegments.join(', ')}</dd> */}
                  <dt>{t(`gender`)}</dt>
                  <dd>
                    {targetingFields.targetFocus
                      ?.map((gender: string) => t(gender))
                      .join(", ")}
                  </dd>
                  <dt>{t(`country`)}</dt>
                  <dd>{targetingFields.targetCountries.join(", ")}</dd>
                  <dt>{t(`cities`)}</dt>
                  <dd>{targetingFields.targetCities.join(", ")}</dd>
                </dl>
              </>
            )}

          {hasSample && servicesFields && lastFilledIndex >= serviceStepIndex && (
            <>
              <hr className="cart-summary__divider" />
              <a
                onClick={() => setCurrentStep(BRIEF_STEPS.SERVICES)}
                className="p p--xs tc--light cart-summary__edit"
              >
                Edit
              </a>
              <div className="h6 cart-summary__heading">Services</div>
              <dl className="cart-summary__dlist mb-0">
                {onlySample && (
                  <>
                    <dt>Packaging</dt>
                    <dd>{servicesFields.servicePackageType}</dd>
                    <dt>Sample</dt>
                    <dd>{servicesFields.serviceIsLiquid ? "Yes" : "No"}</dd>
                    {servicesFields.serviceTemperature && (
                      <>
                        <dt>Temperature</dt>
                        <dd>{servicesFields.serviceTemperature}</dd>
                      </>
                    )}
                  </>
                )}
                {onlyInsert && (
                  <>
                    <dt>Coupon</dt>
                    <dd>
                      {servicesFields.serviceIncludingCoupon ? "Yes" : "No"}
                    </dd>
                    {servicesFields.serviceIncludingCoupon && (
                      <>
                        <dt>Design</dt>
                        <dd>{servicesFields.serviceDesign ? "Yes" : "No"}</dd>
                      </>
                    )}
                    <dt>Printing</dt>
                    <dd>{servicesFields.servicePrinting ? "Yes" : "No"}</dd>
                  </>
                )}
                {onlySample && (
                  <>
                    <dt>Co-Packaging</dt>
                    <dd>{servicesFields.servicePackaging ? "Yes" : "No"}</dd>
                    {servicesFields.servicePackaging && (
                      <>
                        <dt>Co-Packaging needs</dt>
                        <dd>
                          {briefSettings?.copackingPrices?.filter(
                            (ele) => ele.id == servicesFields.copackingPriceId
                          ).length > 0 &&
                            briefSettings?.copackingPrices?.filter(
                              (ele) => ele.id == servicesFields.copackingPriceId
                            )[0].name}
                        </dd>
                        <br />
                        <dt>Quantity</dt>
                        <dd>{servicesFields.servicePackagingQuantity}</dd>
                      </>
                    )}
                    <dt>Printing</dt>
                    <dd>{servicesFields.servicePrinting ? "Yes" : "No"}</dd>
                    {servicesFields.servicePrinting && (
                      <>
                        <dt>Printing needs</dt>
                        <dd>
                          {briefSettings?.printingPrices?.filter(
                            (ele) => ele.id == servicesFields.printingPriceId
                          ).length > 0 &&
                            briefSettings?.printingPrices?.filter(
                              (ele) => ele.id == servicesFields.printingPriceId
                            )[0].name}
                        </dd>
                      </>
                    )}
                    <dt>Free Sample</dt>
                    <dd>{servicesFields.hasFreeSample ? "Yes" : "No"}</dd>
                  </>
                )}
              </dl>
            </>
          )}
        </div>
      )}
    </div>
  );
}
