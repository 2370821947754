import React, { useState } from "react";
import { useSelector } from "react-redux";
import * as selectors from "src/modules/core/core.selectors";
import { getCurrentUserPermissionActions } from "src/modules/core/core.selectors";
import * as actions from "src/modules/core/core.actions";
import * as notificationsSelectors from "src/modules/notifications/notifications.selectors";
import * as notificationsActions from "src/modules/notifications/notifications.actions";
import { useReduxDispatch } from "src/helpers";
import { Menu, QPartnerHeader, QSidenav, QUserMenu } from "quantum_components";
import { ReactComponent as LogoutIcon } from "src/assets/icons/logout-icon.svg";
import SidebarMenu from "../Layouts/PartnerLayout/SidebarMenu";
import { Link, useHistory, useLocation } from "react-router-dom";
import { PATHS } from "src/constants";
import { ReactComponent as KeyIcon } from "src/assets/icons/key-icon.svg";
import { INotificationList } from "../../modules/notifications/notifications.types";
import { getInitials } from "src/utils/getInitialsFromName";
import { filterNotifications, hasPermission } from "src/helpers/permission";
import { PERMISSIONS } from "src/constants/permission";
import CurrencyDropdown from "../CurrencyDropdown/CurrencyDropdown";
import NotificationMenu from "../NotificationMenu/NotificationMenu";
import LanguageSwitcher from "../LanguageSwitcher";
import { useTranslation } from "react-i18next";
import { WalletOutlined, SettingOutlined } from "@ant-design/icons";
import { CURRENCY } from "src/constants/currencies";
import { renderRoundedNumber } from "src/helpers/currency";
import { Avatar } from "antd"

const LIMIT = 10;

const AdminHeader = () => {
  const { t } = useTranslation(['translationPlatform'])
  const dispatch = useReduxDispatch();
  const history = useHistory();
  const location = useLocation();

  const currentUser = useSelector(selectors.getCurrentUser);
  const notificationsCount = useSelector(selectors.getNotificationCount);

  const notificationsList = useSelector(
    notificationsSelectors.getNotificationsList
  );
  const total = useSelector(notificationsSelectors.getNotificationsTotal);
  const permissions = useSelector(getCurrentUserPermissionActions);

  const [offset, setOffset] = useState(0);
  const hasMore = notificationsList.length < total;

  const onOpenNotificationsHandler = async () => {
    await dispatch(notificationsActions.resetState());
    await dispatch(
      notificationsActions.fetchNotificationsList({ offset: 0, limit: LIMIT })
    );
  };

  const fetchNotifications = () => {
    let newOffset = offset + LIMIT;
    if (hasMore) {
      setOffset(newOffset);
      dispatch(
        notificationsActions.fetchNotificationsList({
          offset: newOffset,
          limit: LIMIT,
        })
      );
    }
  };

  const handleLogout = () => {
    dispatch(actions.logout());
  };

  const menu = (
    <Menu className="user-menu-list">
      {hasPermission(permissions, PERMISSIONS.CHANGE_PASSWORD) && (
        <Menu.Item key="101">
          <Link to={PATHS.UPDATE_PASSWORD}>
            <span className="user-menu-icon">
              <KeyIcon />
            </span>
            {t(`update_password`)}
          </Link>
        </Menu.Item>
      )}
      <Menu.Item key="103">
        <div onClick={handleLogout}>
          <span className="user-menu-icon">
            <LogoutIcon />
          </span>
          {t(`logout`)}
        </div>
      </Menu.Item>
    </Menu>
  );

  const handleNotificationClick = (e: any, notification: INotificationList) => {
    if (!notification.redirectPath) return;
    history.push(notification.redirectPath);
  };

  const filteredNotifications = filterNotifications(
    notificationsList,
    permissions
  );

  const hideCurrencyDialog = () => {
    const exactRoutes = [
      PATHS.ROOT,
      PATHS.REQUESTS_PARTNERS,
      PATHS.REQUESTS_CLIENTS,
      PATHS.REQUESTS_ALL,
      PATHS.BRIEF,
      PATHS.CAMPAIGNS,
      PATHS.CLIENTS,
      PATHS.PARTNERS,
      PATHS.ROLES,
      PATHS.EMPLOYEES,
      PATHS.AGE_GROUPS,
      PATHS.SEGMENTS,
      PATHS.ACTIVITIES,
      PATHS.REFERRALS,
      PATHS.DISCOUNT_CODES,
      PATHS.SUBSCRIPTION_HISTORY,
      PATHS.WALLET,
      PATHS.SUPPORT_TICKETS,
      PATHS.BILLINGS,
      PATHS.ADMIN_SETTINGS,
    ];

    const detailsRoutes = [PATHS.CAMPAIGNS, PATHS.BRIEF];
    const exceptionRoutes = ["briefs/edit", "briefs/create", "campaigns/edit"];
    const editPages = exceptionRoutes.some((path: string) =>
      location.pathname.includes(path)
    );

    return (
      exactRoutes.includes(location.pathname) ||
      detailsRoutes.some((path: string) => {
        if (editPages) return false;
        return location.pathname.includes(path);
      })
    );
  };
  
  const onOpenRedirectHandler = () => {
    history.push('/admin-settings');
  };

  return (
    <QPartnerHeader
      childrenSidenav={
        <QSidenav
          onLogoLink={() => history.push(PATHS.ROOT)}
          childrenMenu={<SidebarMenu />}
        />
      }
      childrenTitle={<h1>Dashboard</h1>}
      childrenMenus={
        <>
          {/*!!currentUser?.wallet?.deploymentId && <div className="wallet-balance cursor-pointer" onClick={() => history.push(PATHS.WALLET)}>
            <WalletOutlined rev={undefined}  style={{ fontSize: '20px' }} /> {renderRoundedNumber(Number(currentUser?.wallet?.balance), CURRENCY.SAR)}
          </div>*/}

          <div>
            <LanguageSwitcher />
          </div>

          {hasPermission(permissions, PERMISSIONS.NOTIFICATION_ALL) && (
            <NotificationMenu
              fetchData={fetchNotifications}
              hasMore={hasMore}
              className="partner force-ltr"
              notificationsData={filteredNotifications}
              badgeCount={notificationsCount}
              onOpen={onOpenNotificationsHandler}
              onClick={handleNotificationClick}
            />
          )}

          {/*hasPermission(permissions, PERMISSIONS.NOTIFICATION_ALL) && (
            <NotificationMenu
              fetchData={fetchNotifications}
              hasMore={hasMore}
              className="partner force-ltr"
              overlayClassName="settings"
              notificationsData={filteredNotifications}
              badgeCount={notificationsCount}
              onOpen={onOpenRedirectHandler}
              isSettings={true}
            />
          )*/}


          <div className="user-menu-wrapper">
            <div className="user-menu" onClick={() => history.push('/admin-settings')}>
              <div className="user-name">
                {
                  <><span className="welcome profileName">{`${currentUser.firstName} ${currentUser.lastName}`} </span><p className="companyName">{currentUser.partnerData.name} </p>
                      {}</>
                }
              </div>
              <Avatar size={40} style={{background: "#00000014"}}><SettingOutlined  style={{ fontSize: '17px', color: '#828283'}} /></Avatar>
            </div>
          </div>
        </>
      }
    />
  );
};

export default AdminHeader;
