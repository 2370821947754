import React, { useEffect, useState } from "react";
import { QButton, QInput, QSelect, Row, Col } from "quantum_components";
import { BANNERS_BY_MEDIA_TYPE, MEDIA_TYPES } from "src/constants/brief";
import { useTranslation } from "react-i18next";

const ServiceV2HeaderFilters = ({
  servicesDetails,
  setFilteredServicesDetails,
  setShowTypesModal
}: any) => {
  const {t} = useTranslation(["translationServiceV2","translation"]);
  const [search, setSearch] = useState("");
  const [type, setType] = useState(null);

  useEffect(() => {
    const allServices = [...servicesDetails];
    if (search.length == 0) {
      setFilteredServicesDetails(allServices);
      return;
    }

    const filtered = allServices.filter((s: any) => {
      const name = String(s.name).toLowerCase().trim();
      const subName = String(s.subName).toLowerCase().trim();
      return [name,subName].join(" ").includes(search.toLowerCase().trim());
    });
    setFilteredServicesDetails(filtered);
  }, [search]);

  useEffect(() => {
    const allServices = [...servicesDetails];

    if (!type) {
      setFilteredServicesDetails(allServices);
      return;
    }

    let filtered = [];
    if (type === "Custom Media Service") {
      const definedServices = typeOptions
        .map((o: any) => o.label)
        .filter((a) => a !== type);
      filtered = allServices.filter(
        (s: any) => !definedServices.includes(s.name)
      );
    } else {
      filtered = allServices.filter((s: any) => s.name === type);
    }
    setFilteredServicesDetails(filtered);
  }, [type]);

  const typeOptions = Object.keys(BANNERS_BY_MEDIA_TYPE).map((key: any) => {
    return {
      // @ts-ignore
      label: t(`services.media-buy.types.${key}`,{ns:"translation"}),
      // @ts-ignore
      value: key,
    };
  });

  const renderFilters = () => {
    return (
      <Row justify="space-between" className="pb-10">
        <Col className="flex-container-row-flex-start">
          <div>
            <QInput
              placeholder={t("Search here")}
              size="large"
              className="w-170"
              value={search}
              onChange={(e: any) => setSearch(e.target.value)}
            />
          </div>
          <div>
            <QSelect
              className="w-170"
              size="large"
              placeholder={t("Filter by type")}
              options={typeOptions}
              onChange={(selected:any)=>setType(selected)}
              value={type}
            />
          </div>
          <div>
            <QButton type="primary" htmlType="button" className="qu-button-outline" onClick={()=>{
              setSearch("");
              setType(null);
            }}>{t("Clear Filters")}</QButton>
          </div>
        </Col>
        <Col>
          <QButton type="primary" htmlType="button" onClick={()=>setShowTypesModal(true)}>
            +&nbsp;{t("Create New Service")}
          </QButton>
        </Col>
      </Row>
    );
  };

  return <>{renderFilters()}</>;
};

export default ServiceV2HeaderFilters;
