import { IState } from './adminSettings.types'
import * as CONSTANTS from './adminSettings.constants'

const initialState: IState = {
  adminSettingsDataLoading: false,
  campaignOpsPrices: [],
  managementFee: {
    createdAt: '',
    id: 0,
    managementFee: 0,
    updatedAt: '',
  },
  targetingToggles:[],
  themeData:[],
  workflowEnabled:false
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case CONSTANTS.FETCH_ADMIN_SETTINGS_REQUEST:
      return {
        ...state,
        adminSettingsDataLoading: true,
      }
    case CONSTANTS.FETCH_ADMIN_SETTINGS_SUCCESS:
      return {
        ...state,
        campaignOpsPrices: action.payload.campaignOpsPrices,
        managementFee: action.payload.managementFee,
        targetingToggles: action.payload.targetingToggles,
        themeData: action.payload.themeData,
        workflowEnabled: action.payload.workflowEnabled,
        adminSettingsDataLoading: false,
      }
    case CONSTANTS.FETCH_ADMIN_SETTINGS_FAILURE:
      return {
        ...state,
        adminSettingsDataLoading: false,
      }
    default:
      return state
  }
}
