import { createSelector } from 'reselect'
import { MODULE_NAME } from './discountCodes.constants'
import { IState, IDiscountCode } from './discountCodes.types'

const selectState = (state: { [MODULE_NAME]: any }) => state[MODULE_NAME]

export const getIsDiscountCodesListLoading = createSelector(
  selectState,
  (state: IState): boolean => state.discountCodesListLoading
)

export const getDiscountCodesList = createSelector(selectState, (state: IState): IDiscountCode[] =>
  state.discountCodesList.results.map((discountCode: IDiscountCode) => {
    return {
      ...discountCode,
      key: discountCode.id,
    }
  })
)

export const getDiscountCodesTotal = createSelector(
  selectState,
  (state: IState): number => state.discountCodesList.total
)

export const getPages = createSelector(
  selectState,
  (
    state: IState
  ): {
    totalPages: number
    currentPage: number
  } => state.pages
)
