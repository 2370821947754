import React from 'react'
import { Col } from 'quantum_components'
import { IBriefResponse } from '../../brief.types'
import { useTranslation } from 'react-i18next'

interface Props {
  briefView: IBriefResponse | any
  onEdit: () => void
  viewMode?: boolean
}

const BriefTargeting = ({ briefView, onEdit, viewMode }: Props) => {
  const {t}=useTranslation(['translationABrief'])

  return <div className="qu-view-card m-10">
    <div className="qu-card-header">
      <h4>{t('targeting')}</h4>
      {!viewMode && <span onClick={onEdit}>{t('edit')}</span>}
    </div>
    <div className="product-brief">
    {
      
                briefView?.customTargets && Object.keys(briefView?.customTargets).map((key:any)=>{
                    return <div className="data-view mb-15">
                              <div className="data-view-label">{key}</div>
                              <div className="data-view-value">{briefView?.customTargets[key]?briefView?.customTargets[key]?.join(", "):t('none')}</div>
                            </div>






                //   return <Descriptions.Item label={key}>
                //     {/* @ts-ignore */}
                //     {briefView?.customTargets[key]?briefView?.customTargets[key]?.join(", "):t('none')}
                // </Descriptions.Item>
                })
              }
      {/* <Col span={12}>
        <div className="data-view mb-15">
          <div className="data-view-label">{t('ages')}</div>
          <div className="data-view-value">{briefView.targetAgeGroups?.join(', ') || t('none')}</div>
        </div>
      </Col>
      <Col span={12}>
        <div className="data-view mb-15">
          <div className="data-view-label">{t('segments')}</div>
          <div className="data-view-value">{briefView.targetSegments?.join(', ') || t('none')}</div>
        </div>
      </Col>
      <Col span={12}>
        <div className="data-view mb-15">
          <div className="data-view-label">{t('gender')}</div>
          <div className="data-view-value">{briefView.targetFocus?.join(', ') || t('none')}</div>
        </div>
      </Col>
      <Col span={12}>
        <div className="data-view mb-15">
          <div className="data-view-label">{t('countries')}</div>
          <div className="data-view-value">{briefView.targetCountries?.join(', ') || t('none')}</div>
        </div>
      </Col>
      <Col span={12}>
        <div className="data-view mb-15">
          <div className="data-view-label">{t('cities')}</div>
          <div className="data-view-value">{briefView.targetCities?.join(', ') || t('none')}</div>
        </div>
      </Col>
      <Col span={12}>
        <div className="data-view mb-15">
          <div className="data-view-label">{t('objective')}</div>
        </div>
      </Col> */}
    </div>
  </div>
}
export default BriefTargeting
