export const MODULE_NAME = 'roles'

/* actions */

export const FETCH_ROLES_REQUEST = `${MODULE_NAME}/FETCH_ROLES_REQUEST`
export const FETCH_ROLES_SUCCESS = `${MODULE_NAME}/FETCH_ROLES_SUCCESS`
export const FETCH_ROLES_FAILURE = `${MODULE_NAME}/FETCH_ROLES_FAILURE`

export const CREATE_ROLE_REQUEST = `${MODULE_NAME}/CREATE_ROLE_REQUEST`
export const CREATE_ROLE_SUCCESS = `${MODULE_NAME}/CREATE_ROLE_SUCCESS`
export const CREATE_ROLE_FAILURE = `${MODULE_NAME}/CREATE_ROLE_FAILURE`

export const EDIT_ROLE_REQUEST = `${MODULE_NAME}/EDIT_ROLE_REQUEST`
export const EDIT_ROLE_SUCCESS = `${MODULE_NAME}/EDIT_ROLE_SUCCESS`
export const EDIT_ROLE_FAILURE = `${MODULE_NAME}/EDIT_ROLE_FAILURE`

export const VIEW_ROLE_REQUEST = `${MODULE_NAME}/VIEW_ROLE_REQUEST`
export const VIEW_ROLE_SUCCESS = `${MODULE_NAME}/VIEW_ROLE_SUCCESS`
export const VIEW_ROLE_FAILURE = `${MODULE_NAME}/VIEW_ROLE_FAILURE`

export const DELETE_ROLE_REQUEST = `${MODULE_NAME}/DELETE_ROLE_REQUEST`
export const DELETE_ROLE_SUCCESS = `${MODULE_NAME}/DELETE_ROLE_SUCCESS`
export const DELETE_ROLE_FAILURE = `${MODULE_NAME}/DELETE_ROLE_FAILURE`

export const FETCH_PERMISSIONS_REQUEST = `${MODULE_NAME}/FETCH_PERMISSIONS_REQUEST`
export const FETCH_PERMISSIONS_SUCCESS = `${MODULE_NAME}/FETCH_PERMISSIONS_SUCCESS`
export const FETCH_PERMISSIONS_FAILURE = `${MODULE_NAME}/FETCH_PERMISSIONS_FAILURE`

export const SET_CURRENT_PAGE = `${MODULE_NAME}/SET_CURRENT_PAGE`
