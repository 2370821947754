import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { AxiosResponse } from 'axios'
import IAction from 'src/interfaces/IAction'
import queryBuilder from '../../utils/buildQueryString'

import * as CONSTANTS from './employees.constants'
import { STATUS_CODES } from '../../constants'
import { requestHttp, urls } from 'src/api'
import { IEmployee, IEmployeesListParams } from './employees.types'
import { getResponseErrorMessage } from 'src/helpers'

export const employeesListRequest = (): IAction => ({
  type: CONSTANTS.FETCH_EMPLOYEES_REQUEST,
})

export const employeesListSuccess = (employeesList: { results: IEmployee[]; total: number }): IAction => ({
  type: CONSTANTS.FETCH_EMPLOYEES_SUCCESS,
  payload: employeesList,
})

export const employeesListFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_EMPLOYEES_FAILURE,
  error,
})

export const createEmployeeRequest = (): IAction => ({
  type: CONSTANTS.CREATE_EMPLOYEE_REQUEST,
})

export const createEmployeeSuccess = (): IAction => ({
  type: CONSTANTS.CREATE_EMPLOYEE_SUCCESS,
})

export const createEmployeeFailure = (error: string): IAction => ({
  type: CONSTANTS.CREATE_EMPLOYEE_FAILURE,
  error,
})

export const editEmployeeRequest = (): IAction => ({
  type: CONSTANTS.EDIT_EMPLOYEE_REQUEST,
})

export const editEmployeeSuccess = (): IAction => ({
  type: CONSTANTS.EDIT_EMPLOYEE_SUCCESS,
})

export const editEmployeeFailure = (error: string): IAction => ({
  type: CONSTANTS.EDIT_EMPLOYEE_FAILURE,
  error,
})

export const deleteEmployeeRequest = (): IAction => ({
  type: CONSTANTS.DELETE_EMPLOYEE_REQUEST,
})

export const deleteEmployeeSuccess = (id: number): IAction => ({
  type: CONSTANTS.DELETE_EMPLOYEE_SUCCESS,
  payload: { id },
})

export const deleteEmployeeFailure = (error: string): IAction => ({
  type: CONSTANTS.DELETE_EMPLOYEE_FAILURE,
  error,
})

export const setCurrentPage = (page: number): IAction => ({
  type: CONSTANTS.SET_CURRENT_PAGE,
  payload: { page },
})

export const fetchEmployeesList = (
  params: IEmployeesListParams
): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse> => {
  try {
    dispatch(employeesListRequest())

    const response = await requestHttp.get(urls.getEmployeesListUrl(), { params })
    const { content } = response.data.data

    // if (content.total > 0)
    dispatch(employeesListSuccess(content))
    // else dispatch(employeesListFailure('No Employees yet'))

    return response
  } catch (error) {
    dispatch(employeesListFailure(getResponseErrorMessage(error)))

    return error
  }
}

export const createEmployee = (employee: IEmployee): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    dispatch(createEmployeeRequest())

    await requestHttp.post(urls.getCreateEmployeeUrl(), employee)
    dispatch(createEmployeeSuccess())
  } catch (error) {
    dispatch(createEmployeeFailure(getResponseErrorMessage(error)))
  }
}

export const editEmployee = (employee: IEmployee): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    dispatch(editEmployeeRequest())

    await requestHttp.patch(urls.getEditEmployeeUrl(employee.id), employee)
    dispatch(editEmployeeSuccess())
  } catch (error) {
    dispatch(editEmployeeFailure(getResponseErrorMessage(error)))
  }
}

export const deleteEmployee = (id: number): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    dispatch(deleteEmployeeRequest())

    await requestHttp.delete(urls.getDeleteEmployeeUrl(id))
    dispatch(deleteEmployeeSuccess(id))
  } catch (error) {
    dispatch(deleteEmployeeFailure(getResponseErrorMessage(error)))
  }
}
