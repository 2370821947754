export const MODULE_NAME = 'requests' // sing up requests

/* actions */

export const FETCH_REQUESTS_REQUEST = `${MODULE_NAME}/FETCH_REQUESTS_REQUEST`
export const FETCH_REQUESTS_SUCCESS = `${MODULE_NAME}/FETCH_REQUESTS_SUCCESS`
export const FETCH_REQUESTS_FAILURE = `${MODULE_NAME}/FETCH_REQUESTS_FAILURE`

export const APPROVE_REQUEST_REQUEST = `${MODULE_NAME}/APPROVE_REQUEST_REQUEST`
export const APPROVE_REQUEST_SUCCESS = `${MODULE_NAME}/APPROVE_REQUEST_SUCCESS`
export const APPROVE_REQUEST_FAILURE = `${MODULE_NAME}/APPROVE_REQUEST_FAILURE`

export const DECLINE_REQUEST_REQUEST = `${MODULE_NAME}/DECLINE_REQUEST_REQUEST`
export const DECLINE_REQUEST_SUCCESS = `${MODULE_NAME}/DECLINE_REQUEST_SUCCESS`
export const DECLINE_REQUEST_FAILURE = `${MODULE_NAME}/DECLINE_REQUEST_FAILURE`
