export const MODULE_NAME = 'referral'

/* actions */

export const FETCH_REFERRALS_REQUEST = `${MODULE_NAME}/FETCH_REFERRALS_REQUEST`
export const FETCH_REFERRALS_SUCCESS = `${MODULE_NAME}/FETCH_REFERRALS_SUCCESS`
export const FETCH_REFERRALS_FAILURE = `${MODULE_NAME}/FETCH_REFERRALS_FAILURE`

export const SET_CURRENT_PAGE = `${MODULE_NAME}/SET_CURRENT_PAGE`

export const FETCH_COUPONS_REQUEST = `${MODULE_NAME}/FETCH_COUPONS_REQUEST`
export const FETCH_COUPONS_SUCCESS = `${MODULE_NAME}/FETCH_COUPONS_SUCCESS`
export const FETCH_COUPONS_FAILURE = `${MODULE_NAME}/FETCH_COUPONS_FAILURE`
