import { Store, AnyAction } from 'redux'
import localStorage from 'redux-persist/es/storage'
import { qnotification } from 'quantum_components'
import { AxiosResponse } from 'axios'
import get from 'lodash/get'

import * as coreActions from 'src/modules/core/core.actions'
import { LOCAL_STORAGE_KEYS, PATHS } from './../constants'
import history from '../utils/history'

import NOTIFICATION_TYPES from '../constants/notificationTypes'
import { getEditBillUrl } from './urls'
import i18n from 'src/i18n'

/* eslint-disable @typescript-eslint/no-explicit-any */
type Interceptor = (error: any) => any

const serverSuccessInterceptor = (): Interceptor => async (response: AxiosResponse): Promise<AxiosResponse | void> => {
  if (response.config.responseType === 'blob' && !window.location.pathname.includes('/wallet')) {
    qnotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      // @ts-ignore
      message: i18n.t("push-notifications.Media downloaded successfully", { ns: 'translationCommon' }),
    })
  }

  const editBillUrl = getEditBillUrl()
  if (editBillUrl !== response.config.url && response.data.data?.message) {
    qnotification(<any>{
      type: NOTIFICATION_TYPES.SUCCESS,
      message: i18n.t("push-notifications." + response.data.data.message, response.data.data.message, { ns: 'translationCommon' }),
    })
  }

  return Promise.resolve(response)
}

const serverErrorInterceptor = (store: Store<any, AnyAction>): Interceptor => async (
  error: AxiosResponse
): Promise<AxiosResponse | void> => {
  const token = await localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN)
  if (error.status === 440 && !token) {
    return Promise.reject(error)
  }

  if (error.status >= 500) {
    qnotification(<any>{ type: NOTIFICATION_TYPES.ERROR, message: error.status, description: i18n.t("push-notifications.Something went wrong", { ns: 'translationCommon' }) })
    return Promise.reject(error)
  }
  let message = ''
  if (error.config.responseType === 'blob') {
    const blob = JSON.parse(await error.data.text())
    message = blob.error.message
  } else {
    message = error.data.error.message
  }

  if (
    error?.config?.url?.includes('brief/final-approve') &&
    error?.status === 409 &&
    error?.data?.error?.payload?.invalid
  ) {
  } else {
    qnotification(<any>{
      type: NOTIFICATION_TYPES.ERROR,
      message: i18n.t("push-notifications." + message, message, { ns: 'translationCommon' })
    })
  }

  if (get(error, 'config.skipError', false)) return Promise.reject(error)

  const statusesNoErrors  = get(error, 'config.statusesNoErrors', [])

  // @ts-ignore
  if (statusesNoErrors.includes(error.status)) return Promise.reject(error)

  if (error.status === 401 || error.status === 403 || error.status === 440 || error?.data?.error?.message == "Your session has expired and you must log in again") {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN)
    store.dispatch(coreActions.logoutSuccess())
    history.push(PATHS.SIGN_IN)
    return Promise.reject(error)
  }

  const isServerError = error.status > 300

  if (!isServerError) return Promise.reject(error)

  const isHtmlResponseError = get(error, 'headers.content-type', '').includes('text/html')
  const errorType = isHtmlResponseError ? 'html' : 'text'

  store.dispatch(
    coreActions.setServerError(get(error, 'data.message') || JSON.stringify(error.data) || String(error), errorType)
  )

  return error
}
/* eslint-enable @typescript-eslint/no-explicit-any */

export { serverSuccessInterceptor, serverErrorInterceptor }
