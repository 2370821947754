import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Row, Col } from 'quantum_components'

import { useReduxDispatch } from '../../../helpers'
import * as actions from '../disclaimer.actions'
import { getIsDisclaimerLoading, getDisclaimer } from '../disclaimer.selectors';
import SpinnerCenter from 'src/components/Spinner/spinner';

const Disclaimer = () => {
  const { t } = useTranslation(['translationPlatform'])

  const dispatch = useReduxDispatch()
  const isDisclaimerLoading = useSelector(getIsDisclaimerLoading)
  const disclaimer = useSelector(getDisclaimer)

  const [displayNoneClass, setDisplayNoneClass] = useState(true)

  useEffect(() => {
    dispatch(actions.getDisclaimer())
    return () => {
      dispatch(actions.resetDisclaimerState())
    }
  }, [])

  useEffect(() => {
    if (disclaimer?.htmlContent) {
      const wrapper: any = document.querySelector('#webpage-html');
      const range: any = document.createRange();
      range.setStart(wrapper, 0);
      wrapper.appendChild(
        range.createContextualFragment(disclaimer?.htmlContent)
      );
      if (disclaimer?.htmlContent === '<p></p>' || disclaimer?.htmlContent === null) setDisplayNoneClass(true)
      else setDisplayNoneClass(false)
    } else {
      setDisplayNoneClass(true)
    }
  }, [disclaimer])

  return (
    <>
      {isDisclaimerLoading && <SpinnerCenter />}
      <Row justify="space-between">
        <Col className="qt-search">
          <h2 className="">{t(`menu.disclaimer`)}</h2>
        </Col>
      </Row>

      <div className={`pt-15 pb-30 ${!displayNoneClass && 'q-table'}`}>
        <div className="ant-table">
          <div id="webpage-html" className="m-15"></div>
        </div>
      </div>
    </>
  )
}

export default Disclaimer
