import React from 'react'
import { Radio } from 'quantum_components'
import { useTranslation } from 'react-i18next'

interface Props {
  onChange: (v: any) => void
  value: boolean
}
const BriefSwitch = ({ onChange, value }: Props) => {
  const {t} = useTranslation(['translationABrief'])

  return (
    <Radio.Group
      className="qu-radio-panel full-width mt-10 ph-10"
      defaultValue={value}
      onChange={onChange}
      buttonStyle="solid"
    >
      <Radio.Button className="qu-radio-button qu-radio-white" value={false}>
        <span className="fw-500">{t("brief")}</span>
      </Radio.Button>
      <Radio.Button className="qu-radio-button qu-radio-white" value={true}>
        <span className="fw-500">{t('partners')}</span>
      </Radio.Button>
    </Radio.Group>
  )
}

export default BriefSwitch
