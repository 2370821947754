export const MODULE_NAME = 'employees'

/* actions */

export const FETCH_EMPLOYEES_REQUEST = `${MODULE_NAME}/FETCH_EMPLOYEES_REQUEST`
export const FETCH_EMPLOYEES_SUCCESS = `${MODULE_NAME}/FETCH_EMPLOYEES_SUCCESS`
export const FETCH_EMPLOYEES_FAILURE = `${MODULE_NAME}/FETCH_EMPLOYEES_FAILURE`

export const CREATE_EMPLOYEE_REQUEST = `${MODULE_NAME}/CREATE_EMPLOYEE_REQUEST`
export const CREATE_EMPLOYEE_SUCCESS = `${MODULE_NAME}/CREATE_EMPLOYEE_SUCCESS`
export const CREATE_EMPLOYEE_FAILURE = `${MODULE_NAME}/CREATE_EMPLOYEE_FAILURE`

export const EDIT_EMPLOYEE_REQUEST = `${MODULE_NAME}/EDIT_EMPLOYEE_REQUEST`
export const EDIT_EMPLOYEE_SUCCESS = `${MODULE_NAME}/EDIT_EMPLOYEE_SUCCESS`
export const EDIT_EMPLOYEE_FAILURE = `${MODULE_NAME}/EDIT_EMPLOYEE_FAILURE`

export const DELETE_EMPLOYEE_REQUEST = `${MODULE_NAME}/DELETE_EMPLOYEE_REQUEST`
export const DELETE_EMPLOYEE_SUCCESS = `${MODULE_NAME}/DELETE_EMPLOYEE_SUCCESS`
export const DELETE_EMPLOYEE_FAILURE = `${MODULE_NAME}/DELETE_EMPLOYEE_FAILURE`

export const SET_CURRENT_PAGE = `${MODULE_NAME}/SET_CURRENT_PAGE`
