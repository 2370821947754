import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useReduxDispatch } from "../../../helpers";
import {
  getPermissionsList,
  getRoleDetails,
  getUpdatingRoles,
} from "../roles.selectors";
import { IFieldValues, IPermission } from "../roles.types";
import entries from "lodash/entries";
import * as actions from "../roles.actions";

import {
  Col,
  Collapse,
  Form,
  QButton,
  QInput,
  Row,
  Switch,
} from "quantum_components";
import { SearchOutlined } from '@ant-design/icons'
import { LOCALIZATION_LANGUAGES, PATHS } from "../../../constants";
import { validateMessages } from "../../../helpers/validationMessage";
import { getCurrentUserPermissionActions } from "../../core/core.selectors";
import { hasPermission } from "../../../helpers/permission";
import { PERMISSIONS } from "../../../constants/permission";

const { Panel } = Collapse;

const EDIT_CAMPAIGN_ACTIONS = [
  PERMISSIONS.CAMPAIGN_EDIT_DISTRIBUTION,
  PERMISSIONS.CAMPAIGN_EDIT_MEDIA_BUY,
  PERMISSIONS.CAMPAIGN_EDIT_SURVEY,
  PERMISSIONS.CAMPAIGN_EDIT_GALLERY,
  PERMISSIONS.CAMPAIGN_EDIT_REPORTS,
];

const RoleEdit = () => {
  const { i18n } = useTranslation();
  const { t } = useTranslation(["translationEmployees", "translationCommon"]);
  const dispatch = useReduxDispatch();
  const history = useHistory();
  const roleDetails = useSelector(getRoleDetails);
  const permissionsList = useSelector(getPermissionsList);

  const updatingRole = useSelector(getUpdatingRoles);
  const permissionsActions = useSelector(getCurrentUserPermissionActions);

  const [form] = Form.useForm();

  const { id } = useParams<{ id: any }>();
  const isEditMode = !!id;

  const cantUpdate =
    isEditMode && !hasPermission(permissionsActions, PERMISSIONS.ROLE_UPDATE);
  const cantCreate =
    !isEditMode && !hasPermission(permissionsActions, PERMISSIONS.ROLE_CREATE);
  const disableSave = updatingRole || cantUpdate || cantCreate;

  const [rolePermissions, setRolePermissions] = useState<IPermission[]>([]);

  const [fieldValues, setFieldValues] = useState<IFieldValues>({
    name: "",
    description: "",
  });

  const [search, setSearch] = useState('')

  const { name, description, permissions } = roleDetails;

  useEffect(() => {
    dispatch(actions.fetchPermissionsList());

    if (isEditMode) {
      dispatch(actions.fetchViewRoleDetails(id));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (isEditMode) {
      const roleData = { name, description };
      setRolePermissions(permissions);
      setFieldValues(roleData);
      form.setFieldsValue(roleData);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleDetails]);

  const handleSelectAll = (isAllSelected: boolean) => {
    const updatedList = permissionsList.filter(
      (p) =>
        ![
          PERMISSIONS.BRIEFS_ALL_ONLY_ASSIGNED,
          PERMISSIONS.CLIENT_BILL_VIEW_DETAILS_FOR_ASSIGNED_BRIEFS_ONLY,
        ].includes(p.action)
    );

    isAllSelected
      ? setRolePermissions(updatedList)
      : setRolePermissions([]);
  };

  const handleCancel = () => {
    form.setFieldsValue(fieldValues);
    isEditMode ? setRolePermissions(permissions) : setRolePermissions([]);
    history.push(PATHS.ROLES);
  };

  const handleOnValuesChange = (changedValue: any) => {
    form.setFieldsValue(changedValue);
  };

  const handleOnFinish = (data: any) => {
    const result = {
      name: data.name,
      description: data.description,
      permissionsIds: rolePermissions.map((p) => p.id),
    };

    if (isEditMode) {
      // @ts-ignore
      dispatch(actions.editRole(id, result));
    } else {
      // @ts-ignore
      dispatch(actions.createRole(result));
      setRolePermissions([]);
      form.resetFields();
    }

    history.push(PATHS.ROLES);
  };

  const handleOnCheck = async () => {
    try {
      const values = await form.validateFields();
      handleOnFinish(values);
    } catch (error) {
      return error;
    }
  };

  const handleSwitchChange = (targetPermission: IPermission) => {
    const rolePermissionsClone = [...rolePermissions];
    const permission = rolePermissionsClone.find(
      (p) => p.action === targetPermission.action
    );

    if (permission) {
      rolePermissionsClone.splice(rolePermissionsClone.indexOf(permission), 1);
    } else {
      rolePermissionsClone.push(targetPermission);
    }

    setRolePermissions(rolePermissionsClone);
  };

  const updateCampaignPermissions = (
    targetPermission: IPermission,
    userPermissions: IPermission[]
  ) => {
    const isTargetView = targetPermission.action === PERMISSIONS.CAMPAIGN_VIEW;
    const isTargetEdit = targetPermission.action === PERMISSIONS.CAMPAIGN_EDIT;
    const isTargetEditAction = EDIT_CAMPAIGN_ACTIONS.some(
      (action: PERMISSIONS) => action === targetPermission.action
    );
    const isTargetDistAction =
      targetPermission.action === PERMISSIONS.CAMPAIGN_EDIT_DISTRIBUTION;

    const isViewEnabled = userPermissions.find(
      (p: IPermission) => p.action === PERMISSIONS.CAMPAIGN_VIEW
    );
    const isEditEnabled = userPermissions.find(
      (p: IPermission) => p.action === PERMISSIONS.CAMPAIGN_EDIT
    );
    const view = permissionsList.find(
      (p: IPermission) => p.action === PERMISSIONS.CAMPAIGN_VIEW
    );
    const editDist = permissionsList.find(
      (p: IPermission) => p.action === PERMISSIONS.CAMPAIGN_EDIT_DISTRIBUTION
    );

    if (isTargetView && !isViewEnabled && isEditEnabled) {
      userPermissions.splice(userPermissions.indexOf(isEditEnabled), 1);
      userPermissions = userPermissions.filter(
        (p: IPermission) => !EDIT_CAMPAIGN_ACTIONS.includes(p.action)
      );
    }

    if (isTargetEdit && !isViewEnabled) {
      userPermissions.push(view!);
    }

    if (isTargetEdit && !isEditEnabled) {
      userPermissions = userPermissions.filter(
        (p: IPermission) => !EDIT_CAMPAIGN_ACTIONS.includes(p.action)
      );
    }

    if (isTargetEdit && isEditEnabled) {
      const distribution = permissionsList.find(
        (p: IPermission) => p.action === PERMISSIONS.CAMPAIGN_EDIT_DISTRIBUTION
      );
      userPermissions.push(distribution!);
    }

    if (isTargetEditAction && !isEditEnabled) {
      const edit = permissionsList.find(
        (p: IPermission) => p.action === PERMISSIONS.CAMPAIGN_EDIT
      );
      userPermissions.push(edit!);
    }

    if (isTargetEditAction && !isViewEnabled) {
      userPermissions.push(view!);
    }

    if (isTargetDistAction && isEditEnabled) {
      userPermissions.push(editDist!);
    }

    return userPermissions;
  };

  const handleCampaignSwitchChange = (targetPermission: IPermission) => {
    const rolePermissionsClone = [...rolePermissions];
    const permission = rolePermissionsClone.find(
      (p) => p.action === targetPermission.action
    );

    if (permission) {
      rolePermissionsClone.splice(rolePermissionsClone.indexOf(permission), 1);
    } else {
      rolePermissionsClone.push(targetPermission);
    }

    const assignedPermissions = updateCampaignPermissions(
      targetPermission,
      rolePermissionsClone
    );
    setRolePermissions(assignedPermissions);
  };

  const renderPageTitle = () => {
    return isEditMode
      ? t("roles.Edit One Role", { name })
      : t("roles.Create Role");
  };

  const cancelAndSaveButtonsBlock = (
    <Col>
      <QButton
        className={`${i18n.language === LOCALIZATION_LANGUAGES.ARABIC ? 'qu-button-outline' : 'qu-button-outline mr-20'}`}
        onClick={handleCancel}
      >
        {t("common.Cancel", { ns: "translationCommon" })}
      </QButton>
      <QButton
        className={`${i18n.language === LOCALIZATION_LANGUAGES.ARABIC && 'mr-20'}`}
        disabled={disableSave}
        type="primary"
        htmlType="submit"
      >
        {t("common.Save", { ns: "translationCommon" })}
      </QButton>
    </Col>
  );

  const labels = {
    BriefRequest: "Brief Request",
    SignUpRequests: "Sign Up Requests",
    InvitedClientSignUpRequests: "Invited Client Sign Up Requests",
    InviteClientBrief: "Invite Client Brief",
    DiscountCodes: "Discount Codes",
  };

  const restructuredPermissions = permissionsList.reduce(
    (accumulator, permission) => {
      const { category } = permission;
      // @ts-ignore
      const label = labels[category] || category;

      // @ts-ignore
      if (!accumulator[label]) accumulator[label] = [{ ...permission, 'categorySearch': t("roles." + label.replace(/([a-z])([A-Z])/g, "$1 $2")), 'titleSearch': t("roles." + permission.title) }];
      // @ts-ignore
      else accumulator[label].push({ ...permission, 'categorySearch': t("roles." + label.replace(/([a-z])([A-Z])/g, "$1 $2")), 'titleSearch': t("roles." + permission.title) });
      return accumulator;
    },
    {}
  );

  const renderPermissionItem = (label: string, permissions: IPermission[]) => (
    <Panel
      header={t("roles." + label.replace(/([a-z])([A-Z])/g, "$1 $2"))}
      key={label}
    >
      <Row gutter={30}>
        {permissions.map((permission: IPermission) => {
          const isChecked = rolePermissions.some(
            (p) => p.action === permission.action
          );
          return (
            <Col span={6} key={permission.action}>
              <Form.Item
                className="qu-switch-control"
                label={t("roles." + permission.title)}
                name={permission.action}
              >
                <Switch
                  disabled={cantUpdate}
                  checked={isChecked}
                  onChange={() => handleSwitchChange(permission)}
                />
              </Form.Item>
            </Col>
          );
        })}
      </Row>
    </Panel>
  );

  const renderPermissionCampaignItem = (
    label: string,
    permissions: IPermission[]
  ) => {
    const firstRowPermissions = permissions.filter((p: IPermission) =>
      [
        PERMISSIONS.CAMPAIGNS_ALL,
        PERMISSIONS.CAMPAIGN_CREATE,
        PERMISSIONS.CAMPAIGN_COMPLETE,
      ].includes(p.action)
    );

    const secondRowPermissions = permissions.filter((p: IPermission) =>
      [PERMISSIONS.CAMPAIGN_VIEW, PERMISSIONS.CAMPAIGN_EDIT].includes(p.action)
    );

    const thirdRowPermissions = permissions.filter((p: IPermission) =>
      EDIT_CAMPAIGN_ACTIONS.includes(p.action)
    );

    const renderCol = (permission: IPermission) => {
      const isChecked = rolePermissions.some(
        (p) => p.action === permission.action
      );
      return (
        <Col span={6} key={permission.action}>
          <Form.Item
            className="qu-switch-control"
            label={t("roles." + permission.title)}
            name={permission.action}
          >
            <Switch
              disabled={cantUpdate}
              checked={isChecked}
              onChange={() => handleCampaignSwitchChange(permission)}
            />
          </Form.Item>
        </Col>
      );
    };

    return (
      <Panel header={t("roles." + label)} key={label}>
        <Row gutter={30}>
          {firstRowPermissions.map((permission: IPermission) =>
            renderCol(permission)
          )}
        </Row>
        <Row gutter={30}>
          {secondRowPermissions.map((permission: IPermission) =>
            renderCol(permission)
          )}
        </Row>
        <Row gutter={30}>
          {thirdRowPermissions.map((permission: IPermission) =>
            renderCol(permission)
          )}
        </Row>
      </Panel>
    );
  };

  const renderPermissions = entries(restructuredPermissions).map(
    ([label, permissions]: any) => {
      const filteredPermissions = search ? permissions.filter((ele: any) => ele.categorySearch.toLowerCase().includes(search.toLowerCase()) || ele.titleSearch.toLowerCase().includes(search.toLowerCase())) : permissions
      return label === "Campaign"
        ? !!filteredPermissions.length && renderPermissionCampaignItem(label, filteredPermissions)
        : !!filteredPermissions.length && renderPermissionItem(label, filteredPermissions);
    }
  );

  const handleChangeSearch = (event: any) => {
    setSearch(event.currentTarget.value)
  }

  return (
    <>
      <h2 className="pt-10 pb-30">{renderPageTitle()}</h2>
      <Form
        className="pb-50"
        layout="vertical"
        hideRequiredMark
        form={form}
        onFinish={handleOnCheck}
        onValuesChange={handleOnValuesChange}
        validateMessages={validateMessages}
      >
        <Row gutter={40}>
          <Col span={12}>
            <Form.Item
              label={t("roles.Role name")}
              name="name"
              rules={[{ required: true }]}
              validateTrigger={["onChange", "onBlur"]}
            >
              <QInput
                disabled={cantUpdate}
                placeholder={t("roles.Enter role name")}
                size="large"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t("roles.Role description")}
              name="description"
              rules={[{ required: true }]}
              validateTrigger={["onChange", "onBlur"]}
            >
              <QInput
                disabled={cantUpdate}
                placeholder={t("roles.Enter role description")}
                size="large"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={40} justify="space-between">
          <Col>
            <QButton
              disabled={cantUpdate}
              className={`${i18n.language === LOCALIZATION_LANGUAGES.ARABIC ? 'qu-button-soft' : 'qu-button-soft mr-20'}`}
              onClick={() => handleSelectAll(true)}
            >
              {t("roles.Select All")}
            </QButton>
            <QButton
              disabled={cantUpdate}
              className="qu-button-soft mr-20"
              onClick={() => handleSelectAll(false)}
            >
              {t("roles.Deselect All")}
            </QButton>
          </Col>
          {cancelAndSaveButtonsBlock}
        </Row>
        <Row className="mt-20">
          <Col className="qt-search">
            <QInput
              value={search}
              onChange={handleChangeSearch}
              placeholder={t('common.Search', { ns: 'translationCommon' })}
              size="large"
              prefix={<SearchOutlined />}
            />
          </Col>
        </Row>

        <Collapse
          ghost
          className="qu-collapse mt-40"
          expandIconPosition="right"
          defaultActiveKey={permissionsList.map(
            (item: IPermission, index: number) => index + 1
          )}
        >
          {renderPermissions}
        </Collapse>
      </Form>
    </>
  );
};

export default RoleEdit;
