import { getFileNameFromUrl } from '../utils'
import IUploadFile from '../interfaces/IUploadFile'

export const filesUrlsMapper = (urls: string[]) => {
  return urls.map(
    (url: string, i: number) =>
      ({
        uid: `${i}`,
        name: getFileNameFromUrl(url),
        status: 'done',
        url: url,
      } as IUploadFile)
  )
}
