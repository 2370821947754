import React, { ReactElement } from 'react'
import { QBriefHeader } from 'quantum_components'
import { PATHS } from '../../../../constants'
import { useHistory } from 'react-router-dom'
// import BriefPartnerFilter from './Brief.PartnerFilter'
import { isMobile } from 'react-device-detect'
import { useReduxDispatch } from 'src/helpers'
import { setClientId } from '../../brief.actions'
import { useTranslation } from 'react-i18next'

export interface IProps {
  isEditMode: boolean
  stepper: React.ReactNode
  showPartnerFilter?: boolean
}
export default function BriefHeader({ isEditMode, stepper, showPartnerFilter }: IProps): ReactElement {
  const {t} = useTranslation(['translationABrief'])
  const history = useHistory()
  const dispatch = useReduxDispatch()
  const handleOnCancel = () => {
    if (isEditMode) {
      if (window.location.pathname.includes('/invitedClients/briefs')) {
        history.push(PATHS.INVITED_CLIENTS_BRIEFS)
        return
      } else {
        history.push(PATHS.BRIEF)
        return
      }
    }
    dispatch(setClientId(null))
  }
  return (
    <QBriefHeader
      onClickCancel={handleOnCancel}
      title={isEditMode ? t('edit_campaign') : t('create_campaign')}
      children={<div className="header-stepper">{stepper}</div>}
      childrenFilter={null}
    />
  )
}
