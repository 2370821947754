import React, { ReactElement, useEffect, useState } from 'react'
import {
  IBriefPartnerDetails,
  ICartItem,
  ICartList,
  ICartServiceItem,
  IPartnerDetailsService,
  MediaSubtype,
} from '../../modules/brief/brief.types'
import { renderRoundedNumber } from '../../helpers/currency'
import { useSelector } from 'react-redux'
import {
  getActiveSinglePartnerId,
  getBriefSettings,
  getCart,
  getLogisticInsertRates,
  getLogisticSampleRates,
  getPartnerFilterSettings,
  getStickerCost,
} from '../../modules/brief/brief.selectors'
import { DISTRIBUTION_TYPE, MEDIA_TYPES, MEDIA_TYPES_DURATION } from '../../constants/brief'
import { getCurrency } from '../../modules/core/core.selectors'
import MobileServiceCard from './ServiceMobileCard/Details.MobileServiceCard'
import { actions } from '../../modules/brief/brief.module'
import { useReduxDispatch } from '../../helpers'
import {
  calcCartItemServicesSubTotal,
  calcLogisticPrice,
  calcStickerPrice,
  calcSubTotal,
  calculation,
} from '../../helpers/cart'
import { getLogisticRate } from '../../helpers/logistic'
import { getDuration } from '../../helpers/mediaTypes'
import { useTranslation } from 'react-i18next'

interface Props {
  details: IBriefPartnerDetails
  briefId: number
  viewMode?: boolean
  showModal?: () => void
}

export default function ServicesMobileForm({ details, briefId, viewMode, showModal }: Props): ReactElement {
  const {t} = useTranslation(['translationABrief'])
  const dispatch = useReduxDispatch()
  const cart = useSelector(getCart)
  const partnerId = useSelector(getActiveSinglePartnerId)
  const filterSettings = useSelector(getPartnerFilterSettings)
  const mediaSubTypes = filterSettings.mediaSubtypes
  const stickerCost = useSelector(getStickerCost)
  const logisticSampleRates = useSelector(getLogisticSampleRates)
  const logisticInsertRates = useSelector(getLogisticInsertRates)
  const settings = useSelector(getBriefSettings)
  const currency = useSelector(getCurrency)
  const MINIMUM_DISTRIBUTION_LOGISTIC_COUNT = settings.MINIMUM_DISTRIBUTION_LOGISTIC_COUNT

  const [data, setData] = useState<ICartServiceItem[]>([])

  const cartItem = cart.additional.items.find((item: ICartItem) => partnerId === item.partnerId)

  useEffect(() => {
    // @ts-ignore
    setData(servicesData())
  }, [details])

  useEffect(() => {
    // @ts-ignore
    setData(servicesData())
  }, [cart])

  const servicesData = () => {
    if(!details || !details.availableServices) return []

    const services = [] as ICartServiceItem[]

    if (details.isSampleDistribution) {
      const price = details.pricePerSample
      const targetService =
        cartItem && cartItem.services.find((s: ICartServiceItem) => s.name === DISTRIBUTION_TYPE.PRODUCT_SAMPLE)
      targetService
        ? services.push(targetService)
        : services.push({
            name: DISTRIBUTION_TYPE.PRODUCT_SAMPLE,
            type: MEDIA_TYPES.DISTRIBUTION,
            amount: price,
            currency: currency,
            quantity: 0,
            total: 0,
            duration: MEDIA_TYPES_DURATION.PER_SAMPLE,
          })
    }

    if (details.isInsertDistribution) {
      const price = details.pricePerInsert
      const targetService =
        cartItem && cartItem.services.find((s: ICartServiceItem) => s.name === DISTRIBUTION_TYPE.PRINTED_INSERT)
      targetService
        ? services.push(targetService)
        : services.push({
            name: DISTRIBUTION_TYPE.PRINTED_INSERT,
            type: MEDIA_TYPES.DISTRIBUTION,
            amount: price,
            currency: currency,
            quantity: 0,
            total: 0,
            duration: MEDIA_TYPES_DURATION.PER_INSERT,
          })
    }

    const mediaTypeServices = details.availableServices?.map((service: IPartnerDetailsService) => {
      const name = service.serviceSubName ? service.serviceSubName : service.serviceName
      const duration = getDuration(service)
      const targetService = cartItem && cartItem.services.find((s: ICartServiceItem) => s.name === service.serviceName)

      if (targetService) {
        return targetService
      } else {
        const price = service.servicePricePerExecution || service.servicePrice || 0
        const subType = mediaSubTypes.find((type: MediaSubtype) => type.name === service.serviceName)
        return {
          name: name,
          type: subType ? subType.subtype : '',
          amount: price,
          currency: currency,
          quantity: 0,
          total: 0,
          duration: duration,
        }
      }
    })
    
    return [...services, ...mediaTypeServices]
  }

  const handleInputChange = (e: any, name: string) => {
    const newData = [...data]
    const { value } = e.target
    const target = newData.find((service: ICartServiceItem) => service.name === name)
    if (!target) {
      return
    }

    target.quantity = value
    target.total = target.amount * +value

    setData(newData)
  }

  const checkIsInCart = (service: ICartServiceItem) => {
    const cartItems = cart.additional.items
    const targetCartItem = cartItems.find((item: ICartItem) => item.partnerId === partnerId)
    if (!targetCartItem) {
      return false
    }

    return !!targetCartItem.services.find((s: ICartServiceItem) => s === service)
  }

  const handleAddToCart = async (service: ICartServiceItem) => {
    const newCart = { ...cart }
    const cartItems = newCart.additional.items
    const partnerId = details!.id

    const targetCartItem = cartItems.find((item: ICartItem) => item.partnerId === partnerId)

    if (showModal) {
      showModal()
      return
    }

    let logisticSampleRate = targetCartItem ? targetCartItem.logisticSampleRate : 0
    let logisticSamplePrice = targetCartItem ? targetCartItem.logisticSamplePrice : 0
    let stickerPrice = targetCartItem ? targetCartItem.stickerPrice : 0
    let logisticInsertRate = targetCartItem ? targetCartItem.logisticInsertRate : 0
    let logisticInsertPrice = targetCartItem ? targetCartItem.logisticInsertPrice : 0
    const quantity = service.quantity ? service.quantity : 0

    if (service.name === DISTRIBUTION_TYPE.PRODUCT_SAMPLE) {
      logisticSampleRate = getLogisticRate(quantity, logisticSampleRates)
      logisticSamplePrice = calcLogisticPrice(logisticSampleRate, quantity, MINIMUM_DISTRIBUTION_LOGISTIC_COUNT)
      stickerPrice = calcStickerPrice(stickerCost, quantity)
    }

    if (service.name === DISTRIBUTION_TYPE.PRINTED_INSERT) {
      logisticInsertRate = getLogisticRate(quantity, logisticInsertRates)
      logisticInsertPrice = calcLogisticPrice(logisticInsertRate, quantity, MINIMUM_DISTRIBUTION_LOGISTIC_COUNT)
    }

    if (targetCartItem) {
      const targetItemServices = targetCartItem.services
      const targetService = targetItemServices.find((s: ICartServiceItem) => s === service)
      if (targetService) {
        const index = targetItemServices.indexOf(targetService)
        targetItemServices[index] = service
      } else {
        targetItemServices.push(service)
      }

      targetCartItem.logisticSampleRate = logisticSampleRate
      targetCartItem.logisticSamplePrice = logisticSamplePrice
      targetCartItem.stickerPrice = stickerPrice
      targetCartItem.stickerCost = stickerCost
      targetCartItem.logisticInsertRate = logisticInsertRate
      targetCartItem.logisticInsertPrice = logisticInsertPrice

      const servicesTotal = calcCartItemServicesSubTotal(targetItemServices)

      targetCartItem.subTotal = calcSubTotal(
        servicesTotal,
        targetCartItem.logisticSamplePrice,
        targetCartItem.logisticInsertPrice,
        targetCartItem.stickerPrice
      )
    } else {
      const servicesTotal = calcCartItemServicesSubTotal([service])

      const newCartItem = {
        partnerId: details!.id,
        companyName: details!.companyName,
        companyLogoUrl: details!.companyLogoUrl,
        companyWebsite: details!.companyWebsite,
        services: [service],
        logisticSampleRate,
        logisticSamplePrice,
        stickerCost,
        stickerPrice,
        logisticInsertPrice,
        logisticInsertRate,
        subTotal: calcSubTotal(servicesTotal, logisticSamplePrice, logisticInsertPrice, stickerPrice),
      } as ICartItem

      cartItems.push(newCartItem)
    }

    const result = calculation(cartItems, settings.managementFee, settings.partnerVat)

    const cartData = {
      briefId,
      additional: {
        managementFee: result.managementFee,
        taxes: result.taxes,
        cartTotal: result.total,
        items: cartItems,
      },
    } as ICartList

    await dispatch(actions.addToCart(cartData))
    await dispatch(actions.fetchCart(briefId))
  }

  const handleRemoveFromCart = async (service: ICartServiceItem) => {
    const newCart = { ...cart }
    const cartItems = newCart.additional.items
    const partnerId = details!.id

    const targetCartItem = cartItems.find((item: ICartItem) => item.partnerId === partnerId)

    if (!targetCartItem) {
      return
    }
    const targetItemServices = targetCartItem.services
    const targetService = targetItemServices.find((s: ICartServiceItem) => s === service)

    if (!targetService) {
      return
    }

    const index = targetItemServices.indexOf(targetService)
    targetItemServices.splice(index, 1)

    if (!targetItemServices.length) {
      cartItems.splice(cartItems.indexOf(targetCartItem), 1)
    }

    if (service.name === DISTRIBUTION_TYPE.PRODUCT_SAMPLE) {
      targetCartItem.stickerCost = 0
      targetCartItem.stickerPrice = 0
      targetCartItem.logisticSamplePrice = 0
      targetCartItem.logisticSampleRate = 0
    }

    if (service.name === DISTRIBUTION_TYPE.PRINTED_INSERT) {
      targetCartItem.logisticInsertRate = 0
      targetCartItem.logisticInsertPrice = 0
    }

    const servicesTotal = calcCartItemServicesSubTotal(targetItemServices)

    targetCartItem.subTotal = calcSubTotal(
      servicesTotal,
      targetCartItem.logisticSamplePrice,
      targetCartItem.logisticInsertPrice,
      targetCartItem.stickerPrice
    )

    const result = calculation(cartItems, settings.managementFee, settings.partnerVat)

    const cartData = {
      briefId,
      additional: {
        managementFee: result.managementFee,
        taxes: result.taxes,
        cartTotal: result.total,
        items: cartItems,
      },
    } as ICartList

    await dispatch(actions.addToCart(cartData))
    await dispatch(actions.fetchCart(briefId))
  }

  return (
    <div className="qu-services-list">
      {data?.map((service: ICartServiceItem) => {
        return (
          <MobileServiceCard
            key={service.name}
            selected={checkIsInCart(service)}
            imageUrl={''}
            service={service}
            onInputChange={handleInputChange}
            onClickAdd={handleAddToCart}
            onClickRemove={handleRemoveFromCart}
            viewMode={viewMode}
            details={details}
          />
        )
      })}
    </div>
  )
}
