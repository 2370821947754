import { AxiosResponse } from 'axios'

const downloadZipFile = async (response: AxiosResponse<any>) => {
  const downloadUrl = window.URL.createObjectURL(new Blob([response.data], { type: 'application/octet-stream' }))
  const link = document.createElement('a')
  link.href = downloadUrl
  link.setAttribute('download', 'file.zip')
  document.body.appendChild(link)
  link.click()
  link.remove()
}

export default downloadZipFile
