import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { AxiosResponse } from 'axios'
import IAction, { TName } from 'src/interfaces/IAction'

import * as CONSTANTS from './calendar.constants'
import { requestHttp, urls } from 'src/api'
import { getResponseErrorMessage } from 'src/helpers'

export const calendarDataRequest = (): IAction => ({
  type: CONSTANTS.FETCH_CALENDAR_REQUEST,
})

export const calendarDataSuccess = (data: any): IAction => ({
  type: CONSTANTS.FETCH_CALENDAR_SUCCESS,
  payload: data,
})

export const calendarDataFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_CALENDAR_FAILURE,
  error,
})

export const calendarDataSerivcesRequest = (): IAction => ({
  type: CONSTANTS.FETCH_CALENDAR_SERVICES_REQUEST,
})

export const calendarDataSerivcesSuccess = (data: any): IAction => ({
  type: CONSTANTS.FETCH_CALENDAR_SERVICES_SUCCESS,
  payload: data,
})

export const calendarDataSerivcesFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_CALENDAR_SERVICES_FAILURE,
  error,
})

export const fetchCalendarData = (filters:any): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse> => {
  try {
    dispatch(calendarDataRequest())
    const response = await requestHttp.post(urls.getCalendarDataUrl(),filters)
    const { content } = response.data.data;
    dispatch(calendarDataSuccess(content.results))
    
    return content;
  } catch (error) {
    dispatch(calendarDataFailure(getResponseErrorMessage(error)))
    return error
  }
}

export const fetchServicesList = (): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse> => {
  try {
    dispatch(calendarDataSerivcesRequest())
    const response = await requestHttp.get(urls.getCalendarServicesUrl())
    const { content } = response.data.data;
    dispatch(calendarDataSerivcesSuccess(content.mediaTypes))
    
    return content;
  } catch (error) {
    dispatch(calendarDataSerivcesFailure(getResponseErrorMessage(error)))
    return error
  }
}

