import React, { useState }  from "react";
import { useTranslation } from "react-i18next";
import LanguageLoader from "../LanguageLoader/LanguageLoader";
import { useHistory } from "react-router-dom";
import history from "src/utils/history";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const [showLangLoader,setShowLangLoader] = useState(false)

  const handleLanguageChange = (lang: string) => {
    
    if(document.location.pathname === '/calendar'){
      localStorage.setItem("lang", lang);
      document.location.reload();
      return;
    }

    setShowLangLoader(true)
    i18n.changeLanguage(lang);
    localStorage.setItem("lang", lang);
    hideLangLoader()
  };

  const hideLangLoader = async() => {
    await new Promise(resolve=>setTimeout(resolve,2000))
    setShowLangLoader(false)
  }

  const getLocalizationLanguage = () => {
    if (i18n.language === "ar") return "English";
    return "العربية";
  };

  const changeLanguage = () => {
    if (i18n.language === "en") {
      handleLanguageChange("ar");
    } else {
      handleLanguageChange("en");
    }
  };

  return (
    <>
      <LanguageLoader visible={showLangLoader} />
      <p className="m-0">
        <a className="languageChanger" onClick={() => changeLanguage()}>
          {getLocalizationLanguage()}
        </a>
      </p>
    </>
  );
};

export default LanguageSwitcher;
