import React, { createRef, useEffect, useState } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Col, QButton, Row } from 'quantum_components'
import { actions } from '../../../campaign.module'
import { useReduxDispatch } from '../../../../../helpers'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getCampaignSurveyList, getCampaignSurveyLoading, getCampaignSurveySaving } from '../../../campaign.selectors'
import { ICampaignSurvey } from '../../../campaign.types'
import { cloneDeep } from 'lodash'
import { ISurveyInitState } from '../../../campaign.reducer'
import CampaignSurveyForm from './CampaignSurvey.form'
import { SURVEY_MAX_LEN } from '../../../../../constants/survey'
import SpinnerCenter from '../../../../../components/Spinner/spinner'

const CampaignSurveyList = () => {
  const dispatch = useReduxDispatch()
  const surveysList = useSelector(getCampaignSurveyList)
  const loading = useSelector(getCampaignSurveyLoading)
  const saving = useSelector(getCampaignSurveySaving)
  const { id } = useParams<{id: any}>()
  const formRefs = React.useRef([])
  const [surveys, setSurveys] = useState<ICampaignSurvey[]>([])
  const newSurvey = cloneDeep(ISurveyInitState)

  useEffect(() => {
    const list = [...surveysList]
    list.map((survey: ICampaignSurvey) => (survey.campaignId = +id))
    setSurveys(list)
  }, [surveysList])

  useEffect(() => {
    dispatch(actions.fetchCampaignSettings(id))
    dispatch(actions.fetchCampaignSurveyList(id))
  }, [])

  const handleAddSurvey = () => {
    const newSurveys = [...surveys]
    //@ts-ignore
    newSurvey.campaignId = +id
    newSurveys.push(newSurvey)

    setSurveys(newSurveys)
  }

  const handleDeleteSurvey = (surveyIndex: number) => {
    const newSurveys = [...surveys]
    newSurveys.splice(surveyIndex, 1)

    setSurveys(newSurveys)
  }

  const handleSaveAll = async () => {
    if (!formRefs.current.length || saving) return

    const allValid = await Promise.all(
      formRefs.current.map(async (formRef: any) => await formRef.current.validateFields())
    )

    if (!allValid.every(res => !!res)) return

    await dispatch(actions.saveCampaignSurvey(surveys))
    await dispatch(actions.fetchCampaignSurveyList(id))
  }

  const handleSurveys = (newSurveys: ICampaignSurvey[]) => {
    setSurveys(newSurveys)
  }

  if (loading) {
    return <SpinnerCenter />
  }

  if (formRefs.current.length !== surveys.length) {
    formRefs.current = Array(surveys.length)
      //@ts-ignore
      .fill()
      .map((_, i) => formRefs.current[i] || createRef())
  }

  return (
    <div>
      <Row className="mb-20" justify="space-between">
        <Col>
          {surveys.length < SURVEY_MAX_LEN && (
            <QButton onClick={handleAddSurvey} type="primary" className="qu-button-soft mb-0 mr-16">
              Add Survey <PlusOutlined className="font-size-18" />
            </QButton>
          )}
        </Col>
        <Col>
          <QButton onClick={handleSaveAll} type="primary" disabled={saving}>
            Save All
          </QButton>
        </Col>
      </Row>
      <div className="qu-tree">
        <ul className="qu-tree-branch">
          {surveys.map((survey: ICampaignSurvey, index: number) => {
            return (
              <li key={index} className="qu-tree-leaf level-0">
                <CampaignSurveyForm
                  ref={formRefs.current[index]}
                  surveys={surveys}
                  surveyIndex={index}
                  survey={survey}
                  setSurveys={handleSurveys}
                  deleteSurvey={handleDeleteSurvey}
                />
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

export default CampaignSurveyList
