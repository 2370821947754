
// Configuration    
export const COUNT_FORMATS =
[
  { // 0 - 999
    letter: '',
    limit: 1e3
  },
  { // 1,000 - 999,999
    letter: 'K',
    limit: 1e6
  },
  { // 1,000,000 - 999,999,999
    letter: 'M',
    limit: 1e9
  },
  { // 1,000,000,000 - 999,999,999,999
    letter: 'B',
    limit: 1e12
  },
  { // 1,000,000,000,000 - 999,999,999,999,999
    letter: 'T',
    limit: 1e15
  }
];
    
// Format Method:
export function formatCount(value:number)
{
  const format:any = COUNT_FORMATS.find(format => (value < format.limit));

  value = (1000 * value / format.limit);
  value = Math.round(value * 10) / 10; // keep one decimal number, only if needed

  return (value + format.letter);
}

export   const metricsAreaChartDummyData = [
  {
    name: "A",
    uv: 0,
  },
  {
    name: "B",
    uv: 30,
  },
  {
    name: "C",
    uv: 20,
  },
  {
    name: "D",
    uv: 40,
  },
  {
    name: "E",
    uv: 30,
  },
  {
    name: "F",
    uv: 50,
  },
  {
    name: "G",
    uv: 40,
  },
]

export   const metricsAreaChartDummyDataReversed = [
  {
    name: "A",
    uv: 0,
  },
  {
    name: "B",
    uv: 30,
  },
  {
    name: "C",
    uv: 20,
  },
  {
    name: "D",
    uv: 40,
  },
  {
    name: "E",
    uv: 30,
  },
  {
    name: "F",
    uv: 50,
  },
  {
    name: "G",
    uv: 40,
  },
].reverse();