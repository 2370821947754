import React, { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, QButton, QInput, qnotification, QSelect } from 'quantum_components'
import { useDispatch, useSelector } from 'react-redux'
import { getCampaignGallerySaving, getCampaignPartners } from '../../../campaign.selectors'
import { ICampaignGallery, ICampaignPartner } from '../../../campaign.types'
import { MEDIA_GROUP_TYPE } from '../../../../../constants/brief'
import { VALIDATION_MESSAGE } from '../../../../../constants/validationMessages'
import { actions } from '../../../campaign.module'
import { useParams } from 'react-router-dom'
import { getIsoDate } from '../../../../../helpers/datesHelper'
import { validateRasterImage } from '../../../../../helpers/validation'
import SimpleUploadComponent from '../../../../../components/SimpleUploadComponent'
import NOTIFICATION_TYPES from '../../../../../constants/notificationTypes'
import { ALERT_BRIEF_UPLOAD_MAX } from '../../../../../constants/alerts'
import moment from 'moment'
import { CAMPAIGN_PARTNERS, CAMPAIGN_PARTNERS_NAMES, GALLERY_PER_PAGE } from 'src/constants/campaign'
import { validateMessages } from '../../../../../helpers/validationMessage'

const MAX_GALLERY_IMAGES_COUNT = 20

interface IProps {
  disabledButton: boolean
}

export default function CampaignGalleryForm({ disabledButton }: IProps): ReactElement {
  const { i18n } = useTranslation()
  const { t } = useTranslation(['translationCampaign', 'translationCommon'])
  const dispatch = useDispatch()
  const partners = useSelector(getCampaignPartners)

  const updating = useSelector(getCampaignGallerySaving)
  const [files, setFiles] = useState<any>([])
  const disabledSave = disabledButton || updating || !files.length
  const [uploadError, setUploadError] = useState<string>('')
  const [form] = Form.useForm()
  const { id } = useParams<{id: any}>()

  useEffect(() => {
    if (!uploadError) return
    qnotification({ type: NOTIFICATION_TYPES.ERROR, message: uploadError })
    setUploadError('')
  }, [uploadError])

  const partnerOptions = partners.map((partner: ICampaignPartner) => {
    const distributionType = partner.requestTypes.includes(MEDIA_GROUP_TYPE.PRODUCT_SAMPLE)
      ? MEDIA_GROUP_TYPE.PRODUCT_SAMPLE
      : MEDIA_GROUP_TYPE.PRINTED_INSERT
    return { label: `${partner.companyName} - ${distributionType}`, value: partner.id }
  })

  const partnerAllOptions = [
    // { label: CAMPAIGN_PARTNERS_NAMES[CAMPAIGN_PARTNERS.QUANTUM_TEAM], value: CAMPAIGN_PARTNERS.QUANTUM_TEAM },
    // { label: CAMPAIGN_PARTNERS_NAMES[CAMPAIGN_PARTNERS.ALL_PARTNERS], value: CAMPAIGN_PARTNERS.ALL_PARTNERS },
    ...partnerOptions,
  ]

  const initialValues = {
    partners: [...partners.map(item=>item.id)]
  }


  const resetFields = () => {
    form.resetFields()
    setFiles([])
  }

  const onFinish = async (values: any) => {
    const data = {
      title: values.title,
      description: values.description,
      campaignId: +id,
      startedDate: getIsoDate(moment()),
    } as ICampaignGallery

    const newFiles = files.map((f: any) => f.originFileObj)
    await dispatch(actions.saveCampaignGallery(data, newFiles, values.partners))
    await dispatch(actions.setParamsAndFetchGallery(id, { offset: 0, limit: GALLERY_PER_PAGE }))

    resetFields()
  }

  const onFileChange = (info: any) => {
    let uploaded = [] as any
    let newFiles = [] as any
    info.fileList.forEach((f: any) => (f.originFileObj ? newFiles.push(f) : uploaded.push(f)))

    const validatedFiles = newFiles.filter((f: any) => validateRasterImage(f))
    if (!validatedFiles.length) {
      return
    }
    const merged = [...uploaded, ...validatedFiles]
    const sliced = merged.slice(0, MAX_GALLERY_IMAGES_COUNT)

    setFiles(sliced)

    if (merged.length > sliced.length) {
      setUploadError(ALERT_BRIEF_UPLOAD_MAX(MAX_GALLERY_IMAGES_COUNT))
    }
  }

  

  const handleRemove = async (file: any) => {
    const updated = [...files]
    updated.splice(files.indexOf(file), 1)
    setFiles(updated)
  }

  return (
    <Form form={form} initialValues={initialValues} onFinish={onFinish} className="p-16">
      <Form.Item name="partners" validateTrigger={['onChange', 'onBlur']} style={{display:'none'}}>
        <QSelect
          mode="multiple"
          maxTagTextLength={8}
          maxTagCount={2}
          className="full-width"
          options={partnerAllOptions}
          size="large"
          placeholder="Select Partner"
          showArrow
          disabled
        />
      </Form.Item>
      <Form.Item
        name="title"
        rules={[{ required: true, max: 255, whitespace: true }]}
        validateTrigger={['onChange', 'onBlur']}
      >
        <QInput min={0} placeholder={t('edit.Enter Title')}
         size="large" />
      </Form.Item>
      <Form.Item
        name="description"
        rules={[{ required: true, max: 500, whitespace: true }]}
        validateTrigger={['onChange', 'onBlur']}
      >
        <QInput min={0} placeholder={t('edit.Enter Description')}
         size="large" />
      </Form.Item>
      <Form.Item className="mb-30" name="images">
        <SimpleUploadComponent
          multiple
          files={files}
          onChange={onFileChange}
          onRemove={handleRemove}
          isUploading={updating}
        />
      </Form.Item>

      <QButton disabled={disabledSave} loading={updating} className="full-width" type="primary" htmlType="submit">
        {t('common.Save', {ns: 'translationCommon'})}
      </QButton>
    </Form>
  )
}
