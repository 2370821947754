import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AxiosResponse } from 'axios'
import { AnyAction } from 'redux'
import { requestHttp, urls } from '../../../api'
import { getResponseErrorMessage } from '../../../helpers'
import IAction from '../../../interfaces/IAction'
import * as CONSTANTS from './request.constants'
import {
  IRequestDetails,
  IRequestDetailsResponse,
  IRequestList,
  IRequestListResponse,
  IRequestTableParams,
} from './request.types'
import { REQUEST_ACTIONS } from '../../../constants/request'

export const requestListRequest = (): IAction => ({
  type: CONSTANTS.FETCH_REQUESTS_REQUEST,
})

export const requestListSuccess = (requestList: IRequestList): IAction => ({
  type: CONSTANTS.FETCH_REQUESTS_SUCCESS,
  payload: { requestList },
})

export const requestListFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_REQUESTS_FAILURE,
  error,
})

export const setCurrentPage = (page: number): IAction => ({
  type: CONSTANTS.SET_CURRENT_PAGE,
  payload: { page },
})

export const setRequestListParams = (params: IRequestTableParams): IAction => ({
  type: CONSTANTS.SET_REQUEST_LIST_PARAMS,
  payload: { params },
})

export const extendRequestRequest = (): IAction => ({
  type: CONSTANTS.EXTEND_REQUEST_REQUEST,
})

export const extendRequestSuccess = () => ({
  type: CONSTANTS.EXTEND_REQUEST_SUCCESS,
})

export const extendRequestFailure = (error: string): IAction => ({
  type: CONSTANTS.EXTEND_REQUEST_FAILURE,
  error,
})

export const overrideRequestPaymentRequest = (): IAction => ({
  type: CONSTANTS.OVERRIDE_REQUEST_PAYMENT_REQUEST,
})

export const overrideRequestPaymentSuccess = () => ({
  type: CONSTANTS.OVERRIDE_REQUEST_PAYMENT_SUCCESS,
})

export const overrideRequestPaymentFailure = (error: string): IAction => ({
  type: CONSTANTS.OVERRIDE_REQUEST_PAYMENT_FAILURE,
  error,
})



export const setParamsAndFetch = (
  briefId: number,
  params: IRequestTableParams
): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  await dispatch(setRequestListParams(params))
  await dispatch(fetchRequestList(briefId, params))
}

export const fetchRequestList = (
  briefId: number,
  params: IRequestTableParams
): ThunkAction<Promise<AxiosResponse<IRequestListResponse>>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse<IRequestListResponse>> => {
  try {
    dispatch(requestListRequest())
    const response = await requestHttp.get<IRequestListResponse>(urls.getBriefRequestsListUrl(briefId), { params })
    const { content } = response.data.data

    //TODO sync with BE
    const result = {
      results: content,
      total: content ? content.length : 0,
    } as IRequestList

    dispatch(requestListSuccess(result))
    return response
  } catch (error) {
    dispatch(requestListFailure(getResponseErrorMessage(error)))
    return error
  }
}

export const fetchDetailsRequest = (): IAction => ({
  type: CONSTANTS.FETCH_REQUEST_DETAILS_REQUEST,
})

export const requestDetailsSuccess = (details: IRequestDetails): IAction => ({
  type: CONSTANTS.FETCH_REQUEST_DETAILS_SUCCESS,
  payload: { details },
})

export const initialiseRequestDetails = (): IAction => ({
  type: CONSTANTS.FETCH_REQUEST_DETAILS_SUCCESS,
  payload: {details:null},
})

export const requestDetailsFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_REQUEST_DETAILS_FAILURE,
  error,
})

export const fetchRequestDetails = (
  id: number
): ThunkAction<Promise<AxiosResponse<IRequestDetailsResponse>>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse<IRequestDetailsResponse>> => {
  try {
    dispatch(fetchDetailsRequest())
    const response = await requestHttp.get<IRequestDetailsResponse>(urls.getPartnerRequestDetailsUrl(id))
    const { content } = response.data.data

    dispatch(requestDetailsSuccess(content))
    return response
  } catch (error) {
    dispatch(requestDetailsFailure(getResponseErrorMessage(error)))
    return error
  }
}

export const updateRequestRequest = (): IAction => ({
  type: CONSTANTS.UPDATE_REQUEST_REQUEST,
})

export const updateRequestSuccess = (): IAction => ({
  type: CONSTANTS.UPDATE_REQUEST_SUCCESS,
})

export const updateRequestFailure = (error: string): IAction => ({
  type: CONSTANTS.UPDATE_REQUEST_FAILURE,
  error,
})

export const updateRequest = (
  requestId: number,
  action: REQUEST_ACTIONS,
  reason?: string | null
): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    dispatch(updateRequestRequest())
    const data = { requestId, action, reason }
    await requestHttp.post(urls.getAdminRequestActionUrl(), data)
    dispatch(updateRequestSuccess())
  } catch (error) {
    dispatch(updateRequestFailure(error))
    return error
  }
}

export const assignWorkflowToBrief = (
  briefId:any,
  workflow:any
): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    dispatch(updateRequestRequest())
    await requestHttp.post(urls.postCreateWorkflowUrl(briefId), workflow)
    dispatch(updateRequestSuccess())
  } catch (error) {
    dispatch(updateRequestFailure(error))
    return error
  }
}

export const processWorkflow = (
  briefId:any,
  workflowId:any,
  data:any
): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    dispatch(updateRequestRequest())
    await requestHttp.patch(urls.patchProcessWorkflowUrl(briefId,workflowId), data)
    dispatch(updateRequestSuccess())
  } catch (error) {
    dispatch(updateRequestFailure(error))
    return error
  }
}

export const extendRequest = (id: number): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    dispatch(extendRequestRequest())
    const response = await requestHttp.get(urls.getExtendRequestUrl(id))

    dispatch(extendRequestSuccess())
  } catch (error) {
    dispatch(extendRequestFailure(getResponseErrorMessage(error)))
  }
}

export const overrideRequestPayment = (id: number | string): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    dispatch(overrideRequestPaymentRequest())
    await requestHttp.post(urls.getOverrideRequestPaymentUrl(id))

    dispatch(overrideRequestPaymentSuccess())
  } catch (error) {
    dispatch(overrideRequestPaymentFailure(getResponseErrorMessage(error)))
  }
}
