import { IBillTableParams } from 'src/modules/bills/bills.types'

export const DEFAULT_BILL_TABLE_PARAMS: IBillTableParams = {
  offset: 0,
  limit: 10,
  direction: 'desc',
  sortField: 'createdAt',
  search: '',
  paymentStatus: [],
}

export const BILL_REJECTION_REASONS = [
  'payment_does_not_match_brief_value',
  'wrong_recipient_info',
  'vat_inclusive_exclusive',
  'payment_terms',
]
