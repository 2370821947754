import React, { ReactElement } from 'react'
import { SocialList, QMatchPopover } from 'quantum_components'
import { IBriefPartnerDetails } from '../../modules/brief/brief.types'
import { getMatchDec } from 'src/helpers/matchNumber'
import { MATCH_DETAILS } from 'src/constants/match'
import { getSocial } from '../../helpers/social'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getForm } from 'src/modules/partner/partner-Profile/profile.selectors'
import i18n from 'src/i18n'

interface Props {
  details: IBriefPartnerDetails
}
export default function DetailsCompanyInfo({ details }: Props): ReactElement {
  const { t } = useTranslation(['translationABrief'])
  const partnerDetails = useSelector(getForm)

  const getMatchDescriptions = () => {
    const match = getMatchDec(details.match)
    if (match >= 50) {
      return `Partner is a ${MATCH_DETAILS[match]} match to your target audience criteria`
    }

    return 'Partner does not match your target audience criteria'
  }

  const getSocialData = () => {
    let socialData = getSocial(details.social)
    if (Object.keys(details.monthlyOrderRange).length > 0) {
      socialData.push({
        name: 'Monthly Order Volume',
        counter: details.monthlyOrderRange.text ? details.monthlyOrderRange.text : '',
      })
    }
    // socialData = socialData.map(item=>{return {name:t(`social-channels.${item.name}`,item.name),counter:item.counter}})
    return socialData
  }

  return (
    <React.Fragment>
      <div className="qu-details-title">
        <div className="qu-details-logo">
          {details.companyLogoUrl && <img src={details.companyLogoUrl} alt="logo" />}
        </div>
        <div className="qu-details-block">
          <div className="qu-details-heading">
            <div className="qu-details-info">
              <h2 className="text-truncate">{details.companyName}</h2>
              <p className="text-truncate">{details.companyWebsite}</p>
            </div>
            {details.match && (
              <QMatchPopover
                match={details.match.toFixed()}
                description={getMatchDescriptions()}
                matchDetails={details.matchDetails}
              >
                <div className={`badge badge--lg badge--match badge--match-${getMatchDec(details.match)}`}>
                  {details.match.toFixed()}% Match
                </div>
              </QMatchPopover>
            )}
          </div>
          <div className="qu-details-tags">
            <span className="text-word-break">{details.companyShortDescription}</span>
          </div>
        </div>
      </div>
      <div className="qu-details-description text-word-break">
        {
          partnerDetails && partnerDetails?.companyDescriptionAr
            ? (i18n.language === "ar"
              ? partnerDetails?.companyDescriptionAr
              : partnerDetails?.companyDescription)
            : details.companyFullDescription
        }
      </div>
      <SocialList list={getSocialData()} />
    </React.Fragment>
  )
}
