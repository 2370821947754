import React, { useEffect, useState } from 'react'
import { store, persistor } from '../../../store'
import { serverSuccessInterceptor, serverErrorInterceptor } from '../../../api/serverInterceptor'
import { requestHttp } from '../../../api'
import { Provider } from 'react-redux'
import App from './App'
import { PersistGate } from 'redux-persist/integration/react'
import '../../../styles/main.scss'
import { useTranslation } from "react-i18next"

export default function () {

  const { t, i18n } = useTranslation();

  (async () => {
    const { REACT_APP_THEME: theme } = process.env;

    try {
      if(theme === 'NANA') {
        // @ts-ignore
        await import("../../../styles/nanaoverride.scss");
      }

      if(theme === 'MRSOOL') {
        // @ts-ignore
        await import("../../../styles/mrsooloverride.scss");
      }
        
    } catch (error) {
      console.log(error.message)
    }
  })();

  const [isLoading, setLoading] = useState(true)

  const fakeRequest = () => {
    return new Promise<void>(resolve => setTimeout(() => resolve(), 1500))
  }

  const hideLoader = async () => {
    await fakeRequest()
    const el = document.querySelector('.loader')
    if (el) {
      el.remove()
      setLoading(false)
    }
  }

  useEffect(() => {
    hideLoader()
  }, [])

  useEffect(()=>{
    const savedLang = localStorage.getItem("lang")
    if(savedLang){
      i18n.changeLanguage(savedLang)
    }
  },[])

  if (isLoading) {
    return null
  }

  return (
    <Provider store={store}>
      <PersistGate loading={null} onBeforeLift={undefined} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  )
}

requestHttp.interceptors.response.use(serverSuccessInterceptor(), serverErrorInterceptor(store))
