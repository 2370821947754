import { QButton, QInput, QSelect, Form, IconsCommon, Row, Col } from 'quantum_components'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actions } from '../../modules/referral'
import { getCoupons } from '../../modules/referral/referral.selectors'
import { ICartList, IOptionForSelect } from '../../modules/brief/brief.types'
import { ICoupon } from '../../modules/referral/referral.types'
import { getBriefSettings, getCart } from '../../modules/brief/brief.selectors'
import * as cartActions from '../../modules/brief/brief.actions'
import { useParams } from 'react-router-dom'
import { COLORS } from '../../constants/colors'
import DialogModal from '../DialogModal/DialogModal'
import { cloneDeep, snakeCase } from 'lodash'
import { calcManagementFee, calcTaxes } from 'src/helpers/cart'
import { useTranslation } from 'react-i18next'

const checkIcon = <IconsCommon.IconCheck color={COLORS.LIGHT_GREEN} />

const CouponForm = () => {
  const { t } = useTranslation(['translationABrief', 'translation'])
  const dispatch = useDispatch()
  const coupons = useSelector(getCoupons)
  const cart = useSelector(getCart)
  const { managementFee, partnerVat } = useSelector(getBriefSettings)
  const { id } = useParams<{id: any}>()
  const briefId = id
  const [code, setCode] = useState<string>('')
  const [deleteCouponCodeHandle, setDeleteCouponCodeHandle] = useState<boolean>(false)

  useEffect(() => {
    dispatch(actions.fetchCoupons(briefId))
  }, [])

  const handleCouponInput = (e: any) => {
    setCode(e.target.value)
  }

  const onAddCoupon = async () => {
    const cartData = {
      briefId,
      additional: cart.additional,
      couponCode: code,
    } as ICartList

    const newCartData = cloneDeep(cartData)
    newCartData.additional.servicesTotal =
      newCartData.additional.servicesTotal -
      (newCartData.additional.copackingTotal + newCartData.additional.printingTotal)
    newCartData.additional.managementFee = calcManagementFee(newCartData.additional.servicesTotal, managementFee)
    newCartData.additional.subTotal = newCartData.additional.servicesTotal + newCartData.additional.managementFee
    newCartData.additional.taxes = calcTaxes(newCartData.additional.subTotal, partnerVat)
    newCartData.additional.cartTotal = newCartData.additional.subTotal + newCartData.additional.taxes

    await dispatch(cartActions.addToCart(newCartData))
    await dispatch(cartActions.fetchCart(briefId))
  }

  const handleSelect = (option: string) => {
    setCode(option)
  }

  const translateDiscountText = (discountText: any) => {
    let buffer = new Array()
    buffer = discountText.split("%");

    if (buffer[0] == 0) return t("brief.cart." + snakeCase(buffer[1]), { ns: 'translation' });

    return buffer[0] + "% " + t("brief.cart." + snakeCase(buffer[1]), { ns: 'translation' });
  }

  const couponOptions: IOptionForSelect[] = coupons.map((c: ICoupon) => ({ label: c.couponCode, value: c.couponCode }))
  const showSelect = !cart.couponCode && couponOptions.length > 0

  if (!!cart.couponCode) {
    return (
      <>
        <Row align="middle" gutter={8}>
          <Col span={20}>
            <div className="qu-discount-badge mb-15">
              {checkIcon} {translateDiscountText(cart?.additional?.discountText)}
            </div>
          </Col>
          <Col span={4} className="selection-summary__description mb-16">
            <span className="qu-button-link" onClick={() => setDeleteCouponCodeHandle(true)}>
              {t(`remove`)}
            </span>
          </Col>
        </Row>

        <DialogModal
          modalOpen={deleteCouponCodeHandle}
          question={t(`remove_discount_code`)}
          description={t(`remove_confirm`)}
          submitButtonText={t(`cancel`)}
          cancelButtonText={t(`confirm`)}
          onClose={() => setDeleteCouponCodeHandle(false)}
          onProceed={() => {
            setCode('')
            onAddCoupon()
            setDeleteCouponCodeHandle(false)
          }}
          revert
        />
      </>
    )
  }

  return (
    <div className="coupon-container">
      {showSelect && (
        <QSelect
          value={null}
          onChange={handleSelect}
          className="full-width mb-15"
          options={couponOptions}
          size="large"
          placeholder={t(`select_coupon_code`)}
          showArrow
        />
      )}
      <div className="services-discount">
        <Form.Item label={t(`coupon_code`)}>
          <QInput
            value={cart.couponCode ? cart.couponCode : code}
            disabled={!!cart.couponCode}
            onChange={handleCouponInput}
            placeholder={t(`enter_code`)}
            size="large"
          />
        </Form.Item>
        {!cart.couponCode && (
          <QButton disabled={!code} onClick={onAddCoupon} className="qu-button-soft">
            {t(`add`)}
          </QButton>
        )}
      </div>
    </div>
  )
}

export default CouponForm
