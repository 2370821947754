import React, { FC, useCallback, useEffect } from 'react'
import { InfoCard, QInput, QTextArea, PublicProfileField, QRadio, Form, Radio } from 'quantum_components'

import { actions } from '../../../partnerProfile.module'

import '../../../styles.scss'
import { useReduxDispatch } from 'src/helpers'
import { useSelector } from 'react-redux'
import { getForm } from '../../../profile.selectors'
import { REGEXES } from 'src/constants'
import LocationSearchInput from 'src/modules/client/client-Profile/containers/components/LocationSearchInput'
import { debounce, pick } from 'lodash'
import { GENERAL_COMPANY_INFO, GENERAL_COMPANY_INFO_VALUES, PARTNER_STEPS } from 'src/constants/profile'
import { useTranslation } from 'react-i18next'

interface IProps {
  setError: (stepName: string, value: boolean) => void
}

export const StepOneContainer: FC<IProps> = ({ setError }) => {
  const { t }= useTranslation(['translationProfile'])

  const dispatch = useReduxDispatch()
  const partnerForm = useSelector(getForm)

  const stepFields = pick(partnerForm, Object.values(GENERAL_COMPANY_INFO_VALUES))
  const initialValues = { ...stepFields }
  const [form] = Form.useForm()

  const onValuesChange = (changedValues: object, allValues: typeof initialValues) => {
    const newMetrics = {
      ...stepFields.metrics,
      ...Object.keys(allValues.metrics || {}).reduce((acc, item) => {
        //@ts-ignore
        return { ...acc, [item]: allValues.metrics[item] ? allValues.metrics[item] : '' }
      }, {}),
    }
    const newSocialMediaLinks = {
      ...stepFields.socialMediaLinks,
      ...Object.keys(allValues.socialMediaLinks || {}).reduce((acc, item) => {
        //@ts-ignore
        return { ...acc, [item]: allValues.socialMediaLinks[item] ? allValues.socialMediaLinks[item] : '' }
      }, {}),
    }
    const formValues = {
      ...allValues,
      metrics: newMetrics,
      socialMediaLinks: newSocialMediaLinks,
    }
    dispatch(actions.setForm(formValues))
  }

  const delayedQuery = useCallback(
    //@ts-ignore
    debounce((c, a) => onValuesChange(c, a), 500),
    []
  )

  const onSelectLocation = (field: string) => (value: string) => {
    form.setFieldsValue({ ...form.getFieldsValue(), [field]: value })
    onValuesChange({ [field]: value }, { ...form.getFieldsValue() })
  }

  const validation = async (rule: any, v: string | number | null) => {
    const value = v === null ? '' : `${v}`

    if (value.length && !RegExp(REGEXES.URL).test(value)) {
      return await Promise.reject(t("profile.partner.general_info.your_full_url_including_https"))
    }
    return await Promise.resolve()
  }

  const validateFields = async () => {
    try {
      await form.validateFields()
      setError(PARTNER_STEPS.GENERAL, false)
    } catch (error) {
      setError(PARTNER_STEPS.GENERAL, true)
    }
  }

  useEffect(() => {
    validateFields()
  }, [partnerForm])

  return (
    <div className="first_content">
      <InfoCard headerLeft={<h3>{t("profile.partner.general_info.general_information")}</h3>}>
        <Form form={form} initialValues={initialValues} onValuesChange={delayedQuery}>
          <PublicProfileField
            description={{
              title: t("profile.partner.general_info.company_name"),
            }}
            input={
              <Form.Item className="mb-0" name="companyName">
                <QInput placeholder={`${t("profile.partner.general_info.enter")} ${t("profile.partner.general_info.company_name")}`} size="large" />
              </Form.Item>
            }
          />
          <PublicProfileField
            description={{
              title: t("profile.partner.general_info.company_description"),
            }}
            input={
              <>
                <Form.Item className="mb-0" name="companyDescription">
                  <QTextArea
                    className="form-textarea"
                    placeholder={t("profile.partner.general_info.tell_prospects_who_you_are_and_what_types_of_products_you_sell")}
                    style={{ height: '110px' }}
                  />
                </Form.Item>
              </>
            }
          />
          <PublicProfileField
            description={{
              title: t("profile.partner.general_info.company_description_ar"),
            }}
            input={
              <>
                <Form.Item className="mb-0" name="companyDescriptionAr">
                  <QTextArea
                    className="form-textarea"
                    placeholder={t("profile.partner.general_info.tell_prospects_who_you_are_and_what_types_of_products_you_sell")}
                    style={{ height: '110px' }}
                  />
                </Form.Item>
              </>
            }
          />
          <PublicProfileField
            description={{
              title: t("profile.partner.general_info.company_website"),
            }}
            input={
              <>
                <Form.Item
                  className="mb-0"
                  name="companyWebsite"
                  rules={[
                    {
                      validator: async (rule, value: string) => await validation(rule, value),
                    },
                  ]}
                >
                  <QInput placeholder={t("profile.partner.general_info.enter_url")} size="large" />
                </Form.Item>
                <span
                  style={{
                    fontSize: '11px',
                  }}
                >
                  {t("profile.partner.general_info.your_full_url_including_https")}
                </span>
              </>
            }
          />
          <PublicProfileField
            description={{
              title: t("profile.partner.general_info.headquarters_address"),
            }}
            input={
              <>
                <Form.Item className="mb-0" name="headquarterAddress">
                  <LocationSearchInput
                    onSelectHandler={onSelectLocation(
                      GENERAL_COMPANY_INFO_VALUES[GENERAL_COMPANY_INFO.HEADQUARTERS_ADDRESS]
                    )}
                    placeholder={t("profile.partner.general_info.headquarters_address")}
                  />
                </Form.Item>
              </>
            }
          />
          <PublicProfileField
            description={{
              title: t("profile.partner.general_info.social_media_links"),
              subTitle:t("profile.partner.general_info.social_media_links_subtitle")
            }}
            input={
              <>
                <Form.Item name={['socialMediaLinks', 'facebook']}>
                  <QInput placeholder={t("profile.partner.general_info.facebook_link")} size="large" type="url" />
                </Form.Item>
                <Form.Item name={['socialMediaLinks', 'instagram']}>
                  <QInput placeholder={t("profile.partner.general_info.instagram_link")} size="large" type="url" />
                </Form.Item>
                <Form.Item name={['socialMediaLinks', 'twitter']}>
                  <QInput placeholder={t("profile.partner.general_info.twitter_link")} size="large" type="url" />
                </Form.Item>
                <Form.Item name={['socialMediaLinks', 'linkedin']}>
                  <QInput placeholder={t("profile.partner.general_info.linkedin_link")} size="large" type="url" />
                </Form.Item>
                <Form.Item className="mb-0" name={['socialMediaLinks', 'youtube']}>
                  <QInput placeholder={t("profile.partner.general_info.youtube_link")} size="large" type="url" />
                </Form.Item>
              </>
            }
          />
          <PublicProfileField
            description={{
              title: t("profile.partner.general_info.social_media_followers"),
              subTitle:t("profile.partner.general_info.social_media_followers_subtitle")
            }}
            input={
              <>
                <Form.Item name={['metrics', 'Facebook']}>
                  <QInput placeholder={t("profile.partner.general_info.facebook_followers")} size="large" type="number" />
                </Form.Item>
                <Form.Item name={['metrics', 'Instagram']}>
                  <QInput placeholder={t("profile.partner.general_info.instagram_followers")} size="large" type="number" />
                </Form.Item>
                <Form.Item className="mb-0" name={['metrics', 'Twitter']}>
                  <QInput placeholder={t("profile.partner.general_info.twitter_followers")} size="large" type="number" />
                </Form.Item>
              </>
            }
          />
          <PublicProfileField
            description={{
              title: t("profile.partner.general_info.automatically_approve_requests"),
            }}
            input={
              <>
                <Form.Item name="isAutomaticallyApprovedRequest">
                  <Radio.Group className="qu-radio-group">
                    <QRadio value={true}>{t("profile.partner.general_info.yes")}</QRadio>
                    <QRadio value={false}>{t("profile.partner.general_info.no")}</QRadio>
                  </Radio.Group>
                </Form.Item>
                <p className="p--xs">
                  {t("profile.partner.general_info.auto_approve_help_text")}
                </p>
              </>
            }
          />
        </Form>
      </InfoCard>
    </div>
  )
}
