// @ts-nocheck
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useReduxDispatch } from '../../../helpers'
import { isMobile } from 'react-device-detect'
import * as actions from '../brief.actions'
import {
  QTable,
  Dropdown,
  Menu,
  Row,
  QPagination,
  QMenuItem,
  QInput,
  QSelect,
  IconsCommon,
  RequestCardItem,
  QAlert,
} from 'quantum_components'
import { useSelector } from 'react-redux'
import {
  getBriefListLoading,
  getBriefRequestsList,
  getPages,
  getBriefRequestsListParams,
  getTotalBriefRequestsList,
} from '../brief.selectors'
import { Col, Select } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import useDebounce from '../../../hooks/useDebounce'
import {
  briefPaymentStatuses,
  BRIEF_PAYMENT_STATUS,
  MEDIA_GROUP_TYPE,
  BRIEF_STATUS_GROUP,
  BRIEF_PAYMENT_STATUS_NAMES,
} from 'src/constants/brief'
import { ReactComponent as EyeIcon } from 'src/assets/icons/eye-icon.svg'
import { IBriefRequestTable, IBriefStatus, IBriefStatusGroup } from '../brief.types'
import {
  IRequest,
  IRequestTablePartner,
  IRequestTableProduct,
  IRequestTableService,
} from 'src/modules/partner/requests/request.types'
import { requestStatusesLabel, REQUEST_STATUS, REQUEST_STATUS_LIST, REQUEST_STATUS_NAMES } from 'src/constants/request'
import { useTranslation } from 'react-i18next'
import i18n from 'src/i18n'
import { hasPermission } from 'src/helpers/permission'
import { getCurrentUserPermissionActions } from 'src/modules/core/core.selectors'
import { PERMISSIONS } from 'src/constants/permission'
import { ALERTS_MSG } from 'src/constants/alerts'

const BriefRequestList = () => {
  const { t } = useTranslation(['translationABrief', 'translationCommon'])
  const { Option, OptGroup } = Select

  const dispatch = useReduxDispatch()
  const history = useHistory()
  const briefRequestList = useSelector(getBriefRequestsList)
  const totalBriefRequestList = useSelector(getTotalBriefRequestsList)
  const briefListLoading = useSelector(getBriefListLoading)
  const params = useSelector(getBriefRequestsListParams)
  const pages = useSelector(getPages)
  const permissions = useSelector(getCurrentUserPermissionActions)

  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 500)

  useEffect(() => {
    const newParams = { ...params, search: debouncedSearch, offset: 0 }
    dispatch(actions.fetchBriefRequestsList(newParams))
    dispatch(actions.setCurrentPage(1))

    return () =>
      dispatch(actions.setBriefRequestsListParams({ offset: 0, limit: 10 }));
  }, [debouncedSearch])

  const handleView = async (r: IBriefRequestTable) => {
    history.push(`/briefs/${r.request.briefId}/requests/${r.key}`)
  }

  const menu = (r: IBriefRequestTable) => (
    <Menu className={`user-menu-list ${i18n.dir() === 'rtl' ? 'html-rtl' : ''}`}>
      <QMenuItem key={0} onClick={() => handleView(r)} name={t('view')} icon={<EyeIcon />} />
    </Menu>
  )

  const colKey = {
    product: 'name',
  }

  const columns = [
    {
      title: t('product'),
      dataIndex: 'product',
      key: 'product',
      width: '25%',
      fixed: 'left',
      render: (product: IRequestTableProduct, record: IBriefRequestTable) => {
        return (
          <div className="q-table-product">
            <div className="q-table-product_thumb">
              {product.image && <img src={product.image} alt={product.title} />}
            </div>
            <div className="q-table-product_info">
              <h3 className="q-table-product_title">{product.title || ''}</h3>
              <div>{product.owner.ownerCompanyName}</div>
              <div className="q-table-product_created">
                <span className={record.status === REQUEST_STATUS.REVIEW_NOW ? 'text-primary' : ''}>
                  {t('received')} {product.received}
                </span>
              </div>
            </div>
          </div>
        )
      },
    },
    {
      title: t('start_date'),
      dataIndex: 'service',
      key: 'service',
      width: '20%',
      render: (service: IRequestTableService) => (
        <div>
          <div>{service.startDate}</div>
        </div>
      ),
    },
    {
      title: t('revenue'),
      dataIndex: 'service',
      key: 'service',
      width: '18%',
      render: (service: IRequestTableService) => (
        <span className="font-size-14 font-weight-600">{service.revenue}</span>
      ),
    },
    {
      title: t('assignees'),
      dataIndex: 'assignedUsers',
      key: 'assignedUsers',
      width: '17%',
      render: (list: any) => (
        <ul className="q-table-list">
          {list.length ? (
            list.map((user: any, i: any) => {
              return (
                <li key={i} className="q-table-list_item">
                  <span className="check-icon">
                    <IconsCommon.IconUser />
                  </span>
                  {user.firstName} {user.lastName}
                </li>
              )
            })
          ) : (
            <span>-</span>
          )}
        </ul>
      ),
    },
    {
      title: t('status'),
      dataIndex: 'status',
      key: 'status',
      width: '13%',
      render: (status: REQUEST_STATUS, record: IBriefRequestTable) => {
        return (
          <div>
            <span className={`status-label ${requestStatusesLabel[status] && requestStatusesLabel[status].classStyle}`}>
              {requestStatusesLabel[status] ? t(`${requestStatusesLabel[status].label.toLowerCase()}`) : t(`${REQUEST_STATUS.REVIEW_NOW.toLowerCase()}`)}
            </span>

            {/* QUA-1449, QP-492 - prevent event bubbling to onRowClick */}
            <div onClick={e => e.stopPropagation()}>
              <Dropdown overlay={menu(record)} trigger={['click']} placement="bottomRight">
                <div className="q-table_menu">
                  <IconsCommon.IconEllipsisV />
                </div>
              </Dropdown>
            </div>
          </div>
        )
      },
    },
  ]

  const handleChangeSearch = (e: any) => {
    setSearch(e.currentTarget.value)
  }

  const handleChangePage = (page: number, pageSize: number) => {
    dispatch(actions.setCurrentPage(page))
    dispatch(actions.fetchBriefRequestsList({ ...params, offset: (page - 1) * pageSize, limit: pageSize }))
  }

  const handleChangeSelect = (activeStatuses: string[]) => {
    const statuses = activeStatuses.reduce(
      (acc, statusFullName) => {
        const statusGroupName = statusFullName.split('-')[0]
        const statusName = statusFullName.split('-')[1]

        const groupNames: { [key in BRIEF_STATUS_GROUP]: string } = {
          [BRIEF_STATUS_GROUP.BRIEF]: 'status',
          [BRIEF_STATUS_GROUP.PAYMENT]: 'paymentStatus',
        }
        const groupName = groupNames[statusGroupName]

        if (acc[groupName]) {
          acc[groupName].push(statusName)
        } else {
          acc[groupName] = [statusName]
        }
        return acc
      },
      { status: [], paymentStatus: [] }
    )
    dispatch(actions.fetchBriefRequestsList({ ...params, ...statuses, offset: 0 }))
  }

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    const sortingParams = {
      direction: sorter.order.slice(0, -3),
      //@ts-ignore
      sortField: colKey[sorter.columnKey],
    }
    const newParams = {
      ...params,
      ...sortingParams,
    }
    dispatch(actions.fetchBriefRequestsList(newParams))
  }

  const tableLoading = {
    spinning: briefListLoading,
    indicator: <div className="loader loader-small" />,
  }

  const handleOnRowClick = (r: IBriefRequestTable) => ({
    onClick: (event: any) => history.push(`/briefs/${r.request.briefId}/requests/${r.key}`),
  })

  const briefStatusGroup: IBriefStatusGroup = {
    id: BRIEF_STATUS_GROUP.BRIEF,
    name: BRIEF_STATUS_GROUP.BRIEF,
    label: t('request_statuses'),
    statuses: REQUEST_STATUS_LIST.map((status, index) => ({
      id: `${BRIEF_STATUS_GROUP.BRIEF}_${index}`,
      name: status,
      label: REQUEST_STATUS_NAMES[status],
    })),
  }

  const paymentStatusGroup: IBriefStatusGroup = {
    id: BRIEF_STATUS_GROUP.PAYMENT,
    name: BRIEF_STATUS_GROUP.PAYMENT,
    label: t('payment_statuses'),
    statuses: [
      {
        id: `${BRIEF_STATUS_GROUP.PAYMENT}_${0}`,
        label: BRIEF_PAYMENT_STATUS_NAMES[BRIEF_PAYMENT_STATUS.PENDING],
        name: BRIEF_PAYMENT_STATUS.PENDING,
      },
      {
        id: `${BRIEF_STATUS_GROUP.PAYMENT}_${1}`,
        label: BRIEF_PAYMENT_STATUS_NAMES[BRIEF_PAYMENT_STATUS.PAID],
        name: BRIEF_PAYMENT_STATUS.PAID,
      },
    ],
  }

  const statusGroups: IBriefStatusGroup[] = [briefStatusGroup]

  return (
    <div className="qu-brief-view">
      <h2 className="pb-10">{t('campaign_requests')}</h2>
      {hasPermission(permissions, PERMISSIONS.BRIEFS_ALL_ONLY_ASSIGNED) &&
        <Row>
          <Col>
            <QAlert
              message={t('alert.ALERT_YOU_ARE_VIEWING_CAMPAIGNS_WHICH_ARE_ASSIGNED_TO_YOU', {
                ns: "translationCommon",
              })}
              type="info"
              className="centered pb-15"
            />
          </Col>
        </Row>}
      <div className="qu-brief-list pb-20">
        <Row
          className={`${isMobile && 'pb-15'}`}
          //@ts-ignore
          justify={`${isMobile ? 'center' : 'space-between'}`}
        >
          <Col className="qt-search">
            <QInput
              className={`${isMobile && 'full-width'}`}
              value={search}
              onChange={handleChangeSearch}
              placeholder={t('search')}
              size="large"
              prefix={<SearchOutlined />}
            />
          </Col>
          <Col className="qt-filter">
            <QSelect
              showArrow
              maxTagTextLength={8}
              maxTagCount={2}
              onChange={handleChangeSelect}
              mode="multiple"
              size="large"
              placeholder={t('filter_by_status')}
            >
              {statusGroups.map((group: IBriefStatusGroup) => (
                <OptGroup key={group.id} label={group.label}>
                  {group.statuses.map((status: IBriefStatus) => (
                    <Option key={status.id} value={`${group.name}-${status.name}`}>
                      {t(`request-status-options.${status.label.toLowerCase()}`)}
                    </Option>
                  ))}
                </OptGroup>
              ))}
            </QSelect>
          </Col>
        </Row>
        {isMobile ? (
          <div className="m-30">
            <ul className="qu-request-list">
              {briefRequestList.map((item: IBriefRequestTable) => (
                <RequestCardItem
                  key={item.key}
                  data={item}
                  onClick={() => handleView(item)}
                  paymentStatus={briefPaymentStatuses}
                />
              ))}
            </ul>
          </div>
        ) : (
          <div className="pt-15 pb-30">
            <QTable
              //@ts-ignore
              onRow={handleOnRowClick}
              //@ts-ignore
              onChange={handleTableChange}
              sortDirections={['ascend', 'descend', 'ascend']}
              //@ts-ignore
              loading={tableLoading}
              columns={columns}
              dataSource={briefRequestList}
              scroll={{ x: 990 }}
              rowClassName="q-table cursorPointer"
            />
          </div>
        )}

        <Row
          //@ts-ignore
          justify={`${isMobile ? 'center' : 'end'}`}
          className={`${isMobile && 'pt-15 mb-30'}`}
        >
          <QPagination
            className='force-ltr'
            size="default"
            defaultCurrent={1}
            current={pages.currentPage}
            total={totalBriefRequestList}
            onChange={handleChangePage}
            showSizeChanger
          />
        </Row>
      </div>
    </div>
  )
}

export default BriefRequestList
