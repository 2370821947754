import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useReduxDispatch } from 'src/helpers'
import { getFileNameFromUrl } from 'src/utils'
import { IEditModalState, IDeleteModalState } from '../ageGroups.types'
import * as actions from '../ageGroups.actions'
import IUploadFile from 'src/interfaces/IUploadFile'

import {
  getAgeGroupsList,
  getIsAgeGroupsListLoading,
  getUploadedImage,
  getIsImageUploading,
  getAgeGroupsUpdating,
} from '../ageGroups.selectors'

import { Modal, Tooltip } from 'antd'
import { EditTwoTone, DeleteTwoTone } from '@ant-design/icons'
import { QTable, QButton, QInput, Form, Row, Col, QSelect } from 'quantum_components'
import SimpleUploadComponent from '../../../components/SimpleUploadComponent'
import { validateVectorImage } from '../../../helpers/validation'
import { validateMessages } from '../../../helpers/validationMessage'
import { VALIDATION_MESSAGE } from '../../../constants/validationMessages'
import VALIDATE_STATUSES from '../../../constants/validateStatuses'
import { hasPermission } from 'src/helpers/permission'
import { PERMISSIONS } from 'src/constants/permission'
import { getCurrentUserPermissionActions } from 'src/modules/core/core.selectors'

const AgeGroups = () => {
  const { i18n } = useTranslation()
  const { t } = useTranslation(['translationSettings', 'translationCommon'])
  const dispatch = useReduxDispatch()
  const ageGroupsList = useSelector(getAgeGroupsList)
  const ageGroupsListIsLoading = useSelector(getIsAgeGroupsListLoading)
  const ageGroupImage = useSelector(getUploadedImage)
  const isImagesUploading = useSelector(getIsImageUploading)
  const ageGroupsUpdating = useSelector(getAgeGroupsUpdating)
  const permissions = useSelector(getCurrentUserPermissionActions)
  const [form] = Form.useForm()

  const [file, setFiles] = useState<any>([])
  const [tableKey, setTableKey] = useState(1)
  const [tableKey1, setTableKey1] = useState(100)
  const [editModal, setEditModal] = useState<IEditModalState>({
    visible: false,
    id: null,
    name: '',
    name_ar: '',
    from: null,
    to: null,
    src: '',
    sortNumber: null,
  })
  const [deleteModal, setDeleteModal] = useState<IDeleteModalState>({ visible: false, id: 0, name: '' })
  const [lowerAgeError, setLowerAgeError] = useState('')
  const [upperAgeError, setUpperAgeError] = useState('')
  const [positionOptions, setPositionOptions] = useState<any>([])

  useEffect(() => {
    filesMapper()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ageGroupImage])

  useEffect(() => {
    dispatch(actions.fetchAgeGroupsList())
  }, [dispatch])

  const filesMapper = (src?: string) => {
    const url = src || ageGroupImage
    const list = url
      ? [
        {
          uid: 0 + '',
          name: getFileNameFromUrl(url),
          status: 'done',
          url: url,
        } as IUploadFile,
      ]
      : []

    setFiles(list)
  }

  const handleImageRemove = () => {
    setFiles([])
  }

  const handleImageUpload = async (info: any) => {
    const validatedFiles = info.fileList.filter((f: any) => f.originFileObj && validateVectorImage(f))
    if (!validatedFiles.length) {
      return
    }

    const lastIndex = validatedFiles.length - 1
    const upload = validatedFiles[lastIndex]

    if (editModal.id) {
      const newFile = upload.originFileObj
      const imageSrc = await dispatch(actions.uploadGroupImage([newFile], editModal.id))

      if (!!imageSrc) {
        setEditModal({ ...editModal, src: imageSrc })
        form.setFieldsValue({ src: imageSrc })
      }
    }

    setFiles([upload])
  }

  const handleEditModalOpen = async (group?: IEditModalState) => {
    if (group) {
      const groupData = {
        id: group.id,
        name: group.name,
        name_ar: group.name_ar,
        from: group.from,
        to: group.to,
        src: group.src,
        sortNumber: group.sortNumber,
      }

      form.setFieldsValue(groupData)
      filesMapper(groupData.src)
      setEditModal({ ...groupData, visible: true })
    } else {
      setEditModal({ ...editModal, visible: true })
      form.setFieldsValue({ ...form, sortNumber: ageGroupsList.length + 1 })
    }
  }

  const handleEditModalClose = () => {
    if (isImagesUploading) return

    form.resetFields()
    if (file.length > 0 && !editModal.id) {
      handleImageRemove()
    }

    setFiles([])
    setEditModal({ visible: false, id: 0, name: '', name_ar: '', from: null, to: null, src: '', sortNumber: null })
    setTableKey1(tableKey1 + 1)
  }

  const validateAges = (values: any) => {
    setLowerAgeError('')
    setUpperAgeError('')

    if (!values.from) {
      setLowerAgeError(VALIDATION_MESSAGE.LOWER_AGE_REQUIRED)
      return false
    }

    if (values.to) {
      if (+values.to <= +values.from) {
        setLowerAgeError(VALIDATION_MESSAGE.LOWER_AGE)
        setUpperAgeError(VALIDATION_MESSAGE.UPPER_AGE)

        return false
      }
    }

    return true
  }

  const handleOnValuesChange = (changedValue: any) => {
    setLowerAgeError('')
    setUpperAgeError('')

    if (changedValue.hasOwnProperty('from')) {
      if (!changedValue.from.length) {
        setLowerAgeError(VALIDATION_MESSAGE.LOWER_AGE_REQUIRED)
        return
      }
      const getToValue = parseInt(form.getFieldValue('to'))
      const from = +changedValue.from
      const error = from >= getToValue ? VALIDATION_MESSAGE.LOWER_AGE : ''
      setLowerAgeError(error)
    }

    if (changedValue.hasOwnProperty('to')) {
      if (!changedValue.to.length) {
        setUpperAgeError(VALIDATION_MESSAGE.UPPER_AGE_REQUIRED)
        return
      }
      const getFromValue = parseInt(form.getFieldValue('from'))
      const to = +changedValue.to
      const error = to <= getFromValue ? VALIDATION_MESSAGE.UPPER_AGE : ''
      setUpperAgeError(error)
    }

    if (changedValue.hasOwnProperty('src') && !changedValue.src.fileList.length) {
      changedValue.src = ''
    }
    form.setFieldsValue(changedValue)
  }

  const handleOnFinish = async (data: any) => {
    if (ageGroupsUpdating || isImagesUploading) return

    if (editModal.id) {
      await dispatch(actions.editAgeGroup({ ...data, id: editModal.id }))
    } else {
      const newFile = file[0].originFileObj
      await dispatch(actions.createAgeGroup({ ...data, src: '' }, [newFile]))
    }

    await dispatch(actions.fetchAgeGroupsList())

    handleEditModalClose()
  }

  const onCheck = async () => {
    try {
      const values = await form.validateFields()
      const isValidAges = validateAges(values)
      if (!isValidAges) {
        return
      }

      if (file.length > 0) {
        if (values.to === '') {
          values.to = null
        }
        handleOnFinish(values)
      }
    } catch (error) {
      return error
    }
  }

  const handleDeleteClick = useCallback(
    (group: IDeleteModalState) => () => {
      setDeleteModal({ visible: true, id: group.id, name: group.name })
    },
    []
  )

  const handleDeleteModalClose = () => {
    setDeleteModal({ visible: false, id: 0, name: '' })
  }

  const handleDeleteGroup = async () => {
    if (ageGroupsUpdating) {
      return
    }
    await dispatch(actions.deleteAgeGroup(deleteModal.id))
    setDeleteModal({ visible: false, id: 0, name: '' })
    setTableKey(tableKey + 1)

    await dispatch(actions.fetchAgeGroupsList())
  }

  const calculatePositionOptions = (value: number) => {
    let newArr = []
    for (let i = 1; i <= value; i++) {
      newArr.push({
        label: i,
        value: i,
      })
    }
    setPositionOptions(newArr)
  }

  const renderModalTitle = () => {
    if (editModal.name) {
      return (
        <>
          {t('settings.agegroups.Edit Age Group')} <strong>{editModal.name}</strong>
        </>
      )
    }
    return t('settings.agegroups.Add new Age Group')
  }

  const columns = [
    {
      title: t('settings.agegroups.Sort'),
      dataIndex: 'sortNumber',
      key: 'sortNumber',
      width: '10%',
    },
    {
      title: t('settings.agegroups.Age Group name'),
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
    },
    {
      title: t('settings.agegroups.Age Group name (ar)'),
      dataIndex: 'name_ar',
      key: 'name_ar',
      width: '18%',
    },
    {
      title: t('settings.agegroups.Lower range'),
      dataIndex: 'from',
      key: 'from',
    },
    {
      title: t('settings.agegroups.Upper range'),
      dataIndex: 'to',
      key: 'to',
    },
    {
      title: t('settings.agegroups.Image'),
      dataIndex: 'src',
      key: 'src',
      render: (text: string, record: any) => (
        <div>{record.src && <img src={record.src} alt={record.name} style={{ maxWidth: 50, maxHeight: 50 }} />}</div>
      ),
    },
    {
      title: t('settings.agegroups.Actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: '15%',
      render: (text: string, record: any) => (
        <div style={{ textAlign: 'center' }}>
          {hasPermission(permissions, PERMISSIONS.UPDATE_DEMOGRAPHICS) &&
            <Tooltip title={t('settings.agegroups.Edit Age Group')}>
              <EditTwoTone
                style={{ fontSize: '20px', marginRight: '5px', marginLeft: '5px' }}
                onClick={() => {
                  calculatePositionOptions(ageGroupsList.length)
                  handleEditModalOpen(record)
                }}
              />
            </Tooltip>}
          {hasPermission(permissions, PERMISSIONS.DELETE_DEMOGRAPHICS) &&
            <Tooltip title={t('settings.agegroups.Delete Age Group')}>
              <DeleteTwoTone
                style={{ fontSize: '20px', marginRight: '5px', marginLeft: '5px' }}
                onClick={handleDeleteClick(record)}
              />
            </Tooltip>}
        </div>
      ),
    },
  ]

  // console.log(ageGroupsList)

  return (
    <>
      <h2 className="pt-10">{t('settings.agegroups.Age Groups')}</h2>
      <div className="pt-15 pb-20">
        <QTable columns={columns} dataSource={ageGroupsList} loading={ageGroupsListIsLoading} scroll={{ x: 990 }} />
      </div>
      <Row justify="end">
        <QButton
          disabled={!hasPermission(permissions, PERMISSIONS.CREATE_DEMOGRAPHICS)}
          type="primary"
          onClick={() => {
            calculatePositionOptions(ageGroupsList.length + 1)
            handleEditModalOpen()
          }}
        >
          {t('settings.agegroups.Add Age Group')}
        </QButton>
      </Row>

      <Modal
        key={tableKey1}
        title={renderModalTitle()}
        visible={editModal.visible}
        okText={t('common.OKAY', { ns: 'translationCommon' })}
        cancelText={t('common.Cancel', { ns: 'translationCommon' })}
        onOk={onCheck}
        onCancel={handleEditModalClose}
      >
        <Form
          layout="vertical"
          hideRequiredMark
          form={form}
          onFinish={onCheck}
          onValuesChange={handleOnValuesChange}
          validateMessages={validateMessages}
        >
          <Form.Item
            label={t('settings.agegroups.Age Group name')}
            name="name"
            rules={[{ required: true }]}
            validateTrigger={['onChange', 'onBlur']}
          >
            <QInput size="large" />
          </Form.Item>
          <Form.Item
            label={t('settings.agegroups.Age Group name (ar)')}
            name="name_ar"
            rules={[{ required: true }]}
            validateTrigger={['onChange', 'onBlur']}
          >
            <QInput size="large" />
          </Form.Item>
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item
                label={t('settings.agegroups.Lower range')}
                name="from"
                validateStatus={lowerAgeError ? VALIDATE_STATUSES.ERROR : undefined}
                help={lowerAgeError || undefined}
                rules={[{ required: true }]}
                validateTrigger={['onChange', 'onBlur']}
              >
                <QInput type="number" size="large" min="0" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={t('settings.agegroups.Upper range')} name="to" validateTrigger={['onChange', 'onBlur']}>
                <QInput type="number" size="large" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label={t('settings.agegroups.Position')} name="sortNumber">
            <QSelect size="large" placeholder="Select position" options={positionOptions} />
          </Form.Item>
          <Form.Item
            className="mb-0"
            label={t('settings.agegroups.Age Group image')}
            name="src"
            rules={[{ required: true }]}
            validateTrigger={['onChange', 'onBlur']}
          >
            <SimpleUploadComponent
              files={file}
              onChange={handleImageUpload}
              onRemove={handleImageRemove}
              isUploading={isImagesUploading}
              accept=".svg"
              caption={['SVG format only.']}
              showRemoveButton={!editModal.id}
            />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        key={tableKey}
        title=""
        okText={t('common.OKAY', { ns: 'translationCommon' })}
        cancelText={t('common.Cancel', { ns: 'translationCommon' })}
        visible={deleteModal.visible}
        onOk={handleDeleteGroup}
        onCancel={() => {
          handleDeleteModalClose()
          setTableKey(tableKey + 1)
        }}
      >
        <p>
          {t('settings.agegroups.Do you want to delete age group?')} <strong>{deleteModal.name}</strong> ?
        </p>
      </Modal>
    </>
  )
}

export default AgeGroups
