import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  isProductSample: boolean
  isMediaBuying: boolean
  onEdit: () => void
  viewMode?: boolean
}
const ServiceCard = ({ isProductSample, isMediaBuying, onEdit, viewMode }: Props) => {
  const {t} = useTranslation(['translationABrief'])

  return <div className="qu-view-card m-10">
    <div className="qu-card-header">
      <h4>{t('services')}</h4>
      {!viewMode && <span onClick={onEdit}>{t('edit')}</span>}
    </div>
    <div className="services mb-15">
      <ul className="p p--sm cart-summary__ulist mb-0">
        <li className={`BSUnit__list-item ${!isProductSample && 'disabled'}`}>{t('sample_distribution')}</li>
        <li className={`BSUnit__list-item ${!isMediaBuying && 'disabled'}`}>{t('media_buying')}</li>
      </ul>
    </div>
  </div>
}


export default ServiceCard
