export const MODULE_NAME = 'request'
/* actions */

export const RESET_REQUEST_STATE = `${MODULE_NAME}/RESET_REQUEST_STATE`
export const SET_REQUEST_LIST_PARAMS = `${MODULE_NAME}/SET_REQUEST_LIST_PARAMS`
export const SET_CURRENT_PAGE = `${MODULE_NAME}/SET_CURRENT_PAGE`

export const FETCH_REQUESTS_REQUEST = `${MODULE_NAME}/FETCH_REQUESTS_REQUEST`
export const FETCH_REQUESTS_SUCCESS = `${MODULE_NAME}/FETCH_REQUESTS_SUCCESS`
export const FETCH_REQUESTS_FAILURE = `${MODULE_NAME}/FETCH_REQUESTS_FAILURE`

export const FETCH_REQUESTS_INFO_SUCCESS = `${MODULE_NAME}/FETCH_REQUESTS_INFO_SUCCESS`
export const FETCH_REQUESTS_INFO_FAILURE = `${MODULE_NAME}/FETCH_REQUESTS_INFO_FAILURE`

export const FETCH_REQUEST_DETAILS_REQUEST = `${MODULE_NAME}/FETCH_REQUEST_DETAILS_REQUEST`
export const FETCH_REQUEST_DETAILS_SUCCESS = `${MODULE_NAME}/FETCH_REQUEST_DETAILS_SUCCESS`
export const FETCH_REQUEST_DETAILS_FAILURE = `${MODULE_NAME}/FETCH_REQUEST_DETAILS_FAILURE`

export const EXTEND_REQUEST_REQUEST = `${MODULE_NAME}/EXTEND_REQUEST_REQUEST`
export const EXTEND_REQUEST_SUCCESS = `${MODULE_NAME}/EXTEND_REQUEST_SUCCESS`
export const EXTEND_REQUEST_FAILURE = `${MODULE_NAME}/EXTEND_REQUEST_FAILURE`

export const OVERRIDE_REQUEST_PAYMENT_REQUEST = `${MODULE_NAME}/OVERRIDE_REQUEST_PAYMENT_REQUEST`
export const OVERRIDE_REQUEST_PAYMENT_SUCCESS = `${MODULE_NAME}/OVERRIDE_REQUEST_PAYMENT_SUCCESS`
export const OVERRIDE_REQUEST_PAYMENT_FAILURE = `${MODULE_NAME}/OVERRIDE_REQUEST_PAYMENT_FAILURE`

export const UPDATE_REQUEST_REQUEST = `${MODULE_NAME}/UPDATE_REQUEST_REQUEST`
export const UPDATE_REQUEST_SUCCESS = `${MODULE_NAME}/UPDATE_REQUEST_SUCCESS`
export const UPDATE_REQUEST_FAILURE = `${MODULE_NAME}/UPDATE_REQUEST_FAILURE`
