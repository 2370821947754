import React, { FC } from 'react'
import { AgeGroupCard } from 'src/modules/brief/containers/components/Brief.AgeGroupCard'
import { IAgeGroup } from '../../../profile.types'
import { useSelector } from 'react-redux'
import { getPartnerProfileSettings } from '../../../profile.selectors'
import { useTranslation } from 'react-i18next'
import i18n from 'src/i18n'
import { LOCALIZATION_LANGUAGES } from 'src/constants'

interface IProps {
  value: string[]
  onChange: (value: string[]) => void
}

const AgeGroupCardInput: FC<IProps> = ({ value, onChange }) => {
  const { t } = useTranslation(['translationProfile'])
  const settings = useSelector(getPartnerProfileSettings)
  const ageGroupsContent = () => {
    const reducer = (s: any) => {
      const fullLabel = s.from + (s.to ? '-' + s.to + ' ' + s.name : '+ ' + s.name)
      const sublabel = s.from + (s.to ? '-' + s.to : '+')
      const label = i18n.language === LOCALIZATION_LANGUAGES.ARABIC ? s.name_ar : s.name
      const img = s.src

      return { label, sublabel, fullLabel, img } as IAgeGroup
    }
    return settings.ageGroups.map((group: any) => reducer(group))
  }
  const handleSelectItem = (group: string) => {
    const newAgeGroups = [...value]
    newAgeGroups.includes(group) ? newAgeGroups.splice(newAgeGroups.indexOf(group), 1) : newAgeGroups.push(group)

    onChange(newAgeGroups)
  }
  return (
    <>
      {ageGroupsContent().map((group: IAgeGroup) => {
        const isChecked = value && value.includes(group.fullLabel)
        return (
          <AgeGroupCard
            key={group.label}
            checked={isChecked}
            label={group.label}
            sublabel={group.sublabel}
            img={group.img}
            onChange={() => handleSelectItem(group.fullLabel)}
          />
        )
      })}
    </>
  )
}

export default AgeGroupCardInput
