import React, { Fragment, ReactElement, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useReduxDispatch } from 'src/helpers'
import { Col, Form, QBackLink, QButton, QCheckbox, QInput, Row } from 'quantum_components'
import { useSelector } from 'react-redux'

import { validateMessages } from '../../../../../helpers/validationMessage'
import { GENDER } from '../../../../../constants/partner'
import { getCampaignProps } from '../../../campaign.selectors'
import { actions } from '../../../campaign.module'
import SpinnerCenter from '../../../../../components/Spinner/spinner'
import { getProperties } from '../../../../../helpers/properties'
interface IProps {
  disabledButton: boolean
}
export default function CampaignEditPropsForm({ disabledButton }: IProps): ReactElement {
  const dispatch = useReduxDispatch()
  const properties = useSelector(getCampaignProps)
  const [formProps] = Form.useForm()
  const { id } = useParams<{id: any}>()

  const [formData, setFormData] = useState({
    genderFocus: {},
    ageGroups: {},
  })

  useEffect(() => {
    if (!properties) {
      return
    }
    const data = { ...formData }
    data.genderFocus = properties.genderFocus
    data.ageGroups = properties.ageGroups

    const dataProps = {
      ...getProperties('genderFocus', properties),
      ...getProperties('ageGroups', properties),
    }

    formProps.setFieldsValue(dataProps)
    setFormData(data)
  }, [properties])

  const parseFormData = (values: any) => {
    const data = { ...formData }

    const fields = ['genderFocus', 'ageGroups']

    fields.forEach((name: string) => {
      //@ts-ignore
      Object.keys(data[name]).forEach((key: string) => {
        //@ts-ignore
        data[name][key] = values[key]
      })
    })

    return data
  }

  const handleOnFinish = async (values: any) => {
    const data = parseFormData(values)
    await dispatch(actions.campaignSaveProps(id, data))
  }

  const calculateGender = (field: GENDER, value: number) => {
    if (field === GENDER.MALE) {
      formProps.setFieldsValue({ [GENDER.FEMALE]: 10 - value })
    }

    if (field === GENDER.FEMALE) {
      formProps.setFieldsValue({ [GENDER.MALE]: 10 - value })
    }
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target

    if (value === '' || value === null || value === undefined) {
      return
    }
    let num = +value
    if (num < 0) {
      num = 0
    }
    if (num > 10) {
      num = 10
    }

    if (id === GENDER.MALE || id === GENDER.FEMALE) {
      calculateGender(id, num)
    }

    formProps.setFieldsValue({ [id]: num })
  }

  const renderInput = (name: string) => {
    return (
      <Form.Item
        label={name}
        name={name}
        rules={[
          {
            required: true,
            message: 'Only number between 0 and 10',
          },
        ]}
        validateTrigger={['onChange', 'onBlur']}
      >
        <QInput
          disabled={disabledButton}
          onChange={handleInputChange}
          type="number"
          min={0}
          max={10}
          step={0.01}
          placeholder="Enter number"
          size="large"
        />
      </Form.Item>
    )
  }

  if (!properties) {
    return <SpinnerCenter />
  }

  return (
    <div>
      <Form
        form={formProps}
        layout="vertical"
        hideRequiredMark
        validateMessages={validateMessages}
        initialValues={formData}
        onFinish={handleOnFinish}
      >
        <h5 className="pb-10">Gender</h5>
        <Row gutter={16} justify={'start'}>
          {Object.keys(properties.genderFocus).map((gender: string) => (
            <Col key={gender} xs={24} sm={24} md={8}>
              {renderInput(gender)}
            </Col>
          ))}
        </Row>
        <hr className="form__hr" />
        <h5 className="pb-10">Age Groups</h5>
        <Row gutter={16} justify={'start'}>
          {Object.keys(properties.ageGroups).map((group: string) => (
            <Col key={group} xs={24} sm={24} md={8}>
              {renderInput(group)}
            </Col>
          ))}
        </Row>
        <hr className="form__hr" />
        <Row className="mb-40" justify={'start'}>
          <Col xs={24} sm={24} md={8}>
            <QButton
              disabled={disabledButton}
              className={`full-width ${disabledButton ? 'qu-button-outline' : ''}`}
              type="primary"
              htmlType="submit"
              block
            >
              Save
            </QButton>
          </Col>
        </Row>
      </Form>
    </div>
  )
}
