import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { AxiosResponse } from 'axios'
import IAction from 'src/interfaces/IAction'

import * as CONSTANTS from './referral.constants'
import { requestHttp, urls } from 'src/api'
import { ICoupon, IReferral, IReferralListParams } from './referral.types'
import { getResponseErrorMessage } from 'src/helpers'

export const referralsListRequest = (): IAction => ({
  type: CONSTANTS.FETCH_REFERRALS_REQUEST,
})

export const referralsListSuccess = (referralsList: { results: IReferral[]; total: number }): IAction => ({
  type: CONSTANTS.FETCH_REFERRALS_SUCCESS,
  payload: referralsList,
})

export const referralsListFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_REFERRALS_FAILURE,
  error,
})

export const setCurrentPage = (page: number): IAction => ({
  type: CONSTANTS.SET_CURRENT_PAGE,
  payload: { page },
})

export const fetchReferralsList = (
  params: IReferralListParams
): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse> => {
  try {
    dispatch(referralsListRequest())

    const response = await requestHttp.get(urls.getReferralsListUrl(), { params })
    const { content } = response.data.data

    dispatch(referralsListSuccess(content))

    return response
  } catch (error) {
    dispatch(referralsListFailure(getResponseErrorMessage(error)))

    return error
  }
}

export const fetchCouponsRequest = (): IAction => ({
  type: CONSTANTS.FETCH_COUPONS_REQUEST,
})

export const fetchCouponsSuccess = (coupons: ICoupon[]): IAction => ({
  type: CONSTANTS.FETCH_COUPONS_SUCCESS,
  payload: coupons,
})

export const fetchCouponsFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_COUPONS_FAILURE,
  error,
})

export const fetchCoupons = (briefId: number): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    dispatch(fetchCouponsRequest())
    const response = await requestHttp.get(urls.getCouponsList(briefId))
    const { content } = response.data.data
    dispatch(fetchCouponsSuccess(content))
  } catch (error) {
    dispatch(fetchCouponsFailure(getResponseErrorMessage(error)))
  }
}
