import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useReduxDispatch } from 'src/helpers'
import BriefTypeForm from './briefForms/BriefType.form'
import BriefFooter from './components/Brief.footer'
import {
  ADDITIONAL_STEPS,
  BRIEF_STATUS,
  BRIEF_STEP_STATUS,
  BRIEF_UPLOAD_TYPES,
  BRIEF_STEPS,
  BRIEF_STEPS_NAMES,
  BRIEF_TYPES,
  CREATE_BRIEF_STEPS,
  DISTRIBUTION_TYPE,
  PROMOTION_TYPE,
} from '../../../constants/brief'
import BriefHeader from './components/Brief.header'
import ProductBriefForm from './briefForms/ProductBrief.form'
import { actions } from '../brief.module'
import { useSelector } from 'react-redux'
import {
  getBrief,
  getBriefSettings,
  getBriefView,
  getBriefViewPaymentFilesState,
  getCart,
  getCurrentStep,
  getIsSaving,
  getNeedCheckLastStep,
  getPotentialAudienceReachData,
  getUploadSettings,
  getUploadStepLoading,
  getUpdatingCart,
  getRecommendedPartnersLoading,
} from '../brief.selectors'
import TargetingForm from './briefForms/Targeting.form'
import ServicesForm from './briefForms/Services.form'
import { Col, QBriefStep, Row, Modal, IconsCommon, QButton, qnotification } from 'quantum_components'
import {
  IMediaBuyInitState,
  IMediaBuyStep,
  IProductInitState,
  IProductStep,
  IServiceInitState,
  IServicesStep,
  ITargetingInitState,
  ITargetingStep,
  IUploadPartner,
} from '../brief.types'
import { PATHS } from '../../../constants'
import BriefSummary from './components/Brief.summary'
import cloneDeep from 'lodash/cloneDeep'
import MediaBuyForm from './briefForms/MediaBuy.form'
import BriefDialog from 'src/components/BriefDialog/Brief.Dialog'
import SummaryForm from './briefForms/Summary.form'
import BriefPartnerForm from './briefForms/BriefPartner.form'
import UploadForm from './briefForms/Upload.form'
import FinalSummary from './briefForms/FinalSummary'
import Payment from './briefForms/Payment'
import SubmittedSuccessfully from './briefForms/SubmittedSuccessfully'
import { canEditBrief, canEditBriefWithPaymentStatus } from '../../../helpers/rules'
import {
  validateMediaBuyStep,
  validateProductStep,
  validateServicesStep,
  validateTargetingStep,
} from '../../../helpers/validation'
import { isInsert, isSample } from '../../../helpers/brief'
import { getIsoDate } from '../../../helpers/datesHelper'
import SpinnerCenter from '../../../components/Spinner/spinner'
import { calculation, hasMediaBuyItems } from 'src/helpers/cart'
import { IBillPaymentUploadTypes } from 'src/modules/bills/bills.types'
import { requestHttp, urls } from '../../../api'
import NOTIFICATION_TYPES from '../../../constants/notificationTypes'
import { ReactComponent as ArrowRight } from '../../../assets/icons/arrow-right-icon.svg'
import { getBriefQuotationPdf } from '../../../utils/getScreenPage'
import { useTranslation } from 'react-i18next'

export default function BriefEditContainer(): ReactElement {
  const { t } = useTranslation(['translationABrief', 'translation'])
  const dispatch = useReduxDispatch()
  const history = useHistory()

  const brief = useSelector(getBrief)
  const briefView = useSelector(getBriefView)
  const currentStep = useSelector(getCurrentStep)
  const needCheckLastStep = useSelector(getNeedCheckLastStep)
  const settings = useSelector(getBriefSettings)
  const uploadSettings = useSelector(getUploadSettings)
  const imageLoadingList = useSelector(getUploadStepLoading)
  const potentialAudienceReachData = useSelector(getPotentialAudienceReachData)
  const isSaving = useSelector(getIsSaving)
  const isUpdatingCart = useSelector(getUpdatingCart)
  const isRecommendedPartnersLoading = useSelector(getRecommendedPartnersLoading)

  const paymentStepEnabled = brief?.owner?.paymentEnabled

  const cart = useSelector(getCart)
  const hasMediaBuy = hasMediaBuyItems(cart.additional.items)

  const productInitState = cloneDeep(IProductInitState)
  const targetInitState = cloneDeep(ITargetingInitState)
  const servicesInitState = cloneDeep(IServiceInitState)
  const mediaBuyInitState = cloneDeep(IMediaBuyInitState)

  const [briefTypes, setBriefTypes] = useState<BRIEF_TYPES[]>([])
  const [productStepFields, setProductStepFields] = useState<IProductStep>(productInitState)
  const [targetingStepFields, setTargetingStepFields] = useState<ITargetingStep>(targetInitState)
  const [servicesStepFields, setServicesStepFields] = useState<IServicesStep>(servicesInitState)
  const [mediaBuyStepFields, setMediaBuyStepFields] = useState<IMediaBuyStep>(mediaBuyInitState)
  const [addMediaBuyStep, setAddMediaBuy] = useState<boolean>(false)
  const [addProductSample, setProductSample] = useState<boolean>(false)
  const [additionalStep, setAdditionalStep] = useState<ADDITIONAL_STEPS | null>(null)
  const [skipUploadStep, setSkipUploadStep] = useState<boolean>(false)
  const [formSteps, setFormSteps] = useState<BRIEF_STEPS[]>([])
  const [uploadError, setUploadError] = useState<boolean>(false)
  const [paymentProcessing, setPaymentProcessing] = useState<boolean>(false)
  const [discountCodeErrorModal, setDiscountCodeErrorModal] = useState({
    title: '',
    visible: false,
    description: '',
  })
  const [clientRequiredFieldsOpen, setClientRequiredFieldsOpen] = useState<boolean>(false);

  const filterAndRemoveSteps = (stepName?: string) => {
    if (!stepName) return CREATE_BRIEF_STEPS;
    return CREATE_BRIEF_STEPS.filter((step: string) => step !== stepName)
  }

  const uploadFormRef = useRef()

  const { id } = useParams<{ id: any }>()
  const isEditMode = !!id

  let disableEditWithPaymentStatus = false
  if (briefView.payment && briefView.payment?.status) {
    if (canEditBriefWithPaymentStatus(briefView.payment.status) && briefView.payment.inReview) {
      //if payment_status is pending && submitted for admin approval
      disableEditWithPaymentStatus = true
    } else if (!canEditBriefWithPaymentStatus(briefView.payment.status)) {
      //if payment_status is not pending
      disableEditWithPaymentStatus = true
    }
  }

  useEffect(() => {
    dispatch(actions.resetBriefState())
  }, [id])

  useEffect(() => {
    if (!mediaBuyStepFields.budgetType) {
      const fields = { ...mediaBuyStepFields }
      // @ts-ignore
      fields['budgetType'] = 'Lifetime'
      setMediaBuyStepFields(fields)
    }
  }, [mediaBuyStepFields])

  const fetchSettings = async () => {
    dispatch(actions.fetchBriefSettings())
  }

  const fetchBrief = async () => {
    await dispatch(actions.fetchEditBrief(id))
  }

  const fetchFilterSettings = () => {
    dispatch(actions.fetchPartnerFilterSettings())
  }

  const fetchCart = () => {
    dispatch(actions.fetchCart(id))
  }

  const getUpdatedSteps = () => {
    return paymentStepEnabled ? filterAndRemoveSteps() : filterAndRemoveSteps(BRIEF_STEPS.PAYMENT)
  }

  useEffect(() => {
    const steps = getUpdatedSteps()
    setFormSteps(steps)
    updateStepper(steps);
  }, [brief])

  useEffect(() => {
    fetchSettings()
    fetchBrief()
    fetchCart()


    return () => {
      dispatch(actions.resetBriefState())
    }
  }, [id])

  useEffect(() => {
    const fields = { ...productStepFields }
    fields.productImages = [...brief[BRIEF_STEPS.PRODUCT].productImages]
    setProductStepFields(fields)
  }, [brief[BRIEF_STEPS.PRODUCT].productImages.length])

  useEffect(() => {
    const fields = { ...productStepFields }
    fields.quantity = brief[BRIEF_STEPS.PRODUCT].quantity
    setProductStepFields(fields)
  }, [brief[BRIEF_STEPS.PRODUCT].quantity])

  useEffect(() => {
    const fields = { ...servicesStepFields }
    fields.serviceImages = [...brief[BRIEF_STEPS.SERVICES].serviceImages]
    setServicesStepFields(fields)
  }, [brief[BRIEF_STEPS.SERVICES].serviceImages.length])

  useEffect(() => {
    const fields = { ...servicesStepFields }
    fields.servicePackagingQuantity = brief[BRIEF_STEPS.SERVICES].servicePackagingQuantity
    setServicesStepFields(fields)
  }, [brief[BRIEF_STEPS.SERVICES].servicePackagingQuantity])

  useEffect(() => {
    setBriefTypes(brief[BRIEF_STEPS.TYPE].briefTypes)
  }, [brief[BRIEF_STEPS.TYPE]])

  useEffect(() => {
    const fields = { ...mediaBuyStepFields }
    fields.budgetValue = brief[BRIEF_STEPS.MEDIA_BUY].budgetValue
    setMediaBuyStepFields(fields)
  }, [brief[BRIEF_STEPS.MEDIA_BUY].budgetValue])

  useEffect(() => {
    if (cart.additional.items.length > 0) {
      const skipUploadStep = !hasMediaBuy
      setSkipUploadStep(skipUploadStep)

      if (skipUploadStep && currentStep === BRIEF_STEPS.UPLOAD) {
        showFinalSummary()
      }
    }
  }, [cart.additional.items])

  useEffect(() => {
    setBriefTypes(brief[BRIEF_STEPS.TYPE].briefTypes)
    setCurrentStep(BRIEF_STEPS.PRODUCT)

    if (brief[BRIEF_STEPS.PRODUCT]) {
      setProductStepFields(cloneDeep(brief[BRIEF_STEPS.PRODUCT]))
    }

    if (brief[BRIEF_STEPS.TARGETING]) {
      setTargetingStepFields(cloneDeep(brief[BRIEF_STEPS.TARGETING]))
    }

    if (brief[BRIEF_STEPS.SERVICES]) {
      setServicesStepFields(cloneDeep(brief[BRIEF_STEPS.SERVICES]))
    }

    if (brief[BRIEF_STEPS.MEDIA_BUY]) {
      setMediaBuyStepFields(cloneDeep(brief[BRIEF_STEPS.MEDIA_BUY]))
    }

    if (brief.lastFilledStep && brief.lastFilledStep !== BRIEF_STEPS.TYPE && needCheckLastStep) {
      setCurrentStep(brief.lastFilledStep)
    }

    if (forceShowFinalStep) {
      showFinalSummary()
    }

    if (brief.owner?.isApproved) {
      fetchFilterSettings()
    }
  }, [brief.id])

  useEffect(() => {
    const containers = document.getElementsByClassName('qu-brief-main')
    if (containers) {
      containers[0].scrollTo({ top: 0, behavior: 'smooth' })
    }
    if (currentStep === BRIEF_STEPS.TARGETING)
      dispatch(actions.getPotentialAudienceReach(id, brief[BRIEF_STEPS.TARGETING]))

    if (disableEdit && currentStep !== BRIEF_STEPS.UPLOAD && currentStep !== BRIEF_STEPS.SUMMARY && currentStep !== BRIEF_STEPS.PAYMENT) {
      dispatch(actions.setCurrentStep(BRIEF_STEPS.UPLOAD))
    }

    if (disableEditWithPaymentStatus && currentStep !== BRIEF_STEPS.UPLOAD && currentStep !== BRIEF_STEPS.SUMMARY && currentStep !== BRIEF_STEPS.PAYMENT) {
      dispatch(actions.setCurrentStep(BRIEF_STEPS.UPLOAD))
    }
  }, [currentStep])

  useEffect(() => {
    updateStepper(getUpdatedSteps())
  }, [briefTypes, briefTypes.length, brief])

  const updateStepper = (newSteps?: any[]) => {

    // const steps = [...formSteps]
    const steps = newSteps ? newSteps : [...formSteps]

    if (hasMediaBuyStep && hasSampleStep) {
      setFormSteps([...CREATE_BRIEF_STEPS])
      return
    }

    if (hasMediaBuyStep) {
      const servicesIndex = steps.indexOf(BRIEF_STEPS.SERVICES)
      if (servicesIndex > 0) {
        steps.splice(servicesIndex, 1)
      }

      // if (!steps.includes(BRIEF_STEPS.MEDIA_BUY)) {
      //   const partnerIndex = steps.indexOf(BRIEF_STEPS.PARTNER)
      //   steps.splice(partnerIndex, 0, BRIEF_STEPS.MEDIA_BUY)
      // }
    }

    if (hasSampleStep) {
      const mediaBuyIndex = steps.indexOf(BRIEF_STEPS.MEDIA_BUY)
      if (mediaBuyIndex > 0) {
        steps.splice(mediaBuyIndex, 1)
      }

      if (!steps.includes(BRIEF_STEPS.SERVICES)) {
        const targetingIndex = steps.indexOf(BRIEF_STEPS.TARGETING)
        steps.splice(targetingIndex + 1, 0, BRIEF_STEPS.SERVICES)
      }
    }

    setFormSteps(steps)
  }

  const handleFinishLater = async () => {
    if (uploadError) return
    if (imageLoadingList.length > 0) return

    if (!isPaymentStep) {
      await saveBrief()
    }

    if (window.location.pathname.includes('/invitedClients/briefs')) history.push(PATHS.INVITED_CLIENTS_BRIEFS)
    else history.push(PATHS.BRIEF)
  }

  const owner = brief.owner
  const isZeroStep = currentStep === BRIEF_STEPS.TYPE
  const isProductStep = currentStep === BRIEF_STEPS.PRODUCT
  const isServiceStep = currentStep === BRIEF_STEPS.SERVICES
  const isTargetingStep = currentStep === BRIEF_STEPS.TARGETING
  const isMediaBuyStep = currentStep === BRIEF_STEPS.MEDIA_BUY
  const isPartnerStep = currentStep === BRIEF_STEPS.PARTNER
  const isPaymentStep = currentStep === BRIEF_STEPS.PAYMENT
  const isUploadStep = currentStep === BRIEF_STEPS.UPLOAD
  // const isFinalSummary = additionalStep === ADDITIONAL_STEPS.FINAL_SUMMARY
  const isFinalSummary = currentStep === BRIEF_STEPS.SUMMARY
  const showFooter = !isPartnerStep || isFinalSummary
  const forceShowFinalStep = !canEditBrief(brief.status) && brief.lastFilledStep !== BRIEF_STEPS.UPLOAD

  const hasMediaBuyStep = briefTypes.includes(BRIEF_TYPES.MEDIA_BUYING)
  const hasSampleStep = briefTypes.includes(BRIEF_TYPES.PRODUCT_SAMPLE)
  const needShowDialogToAddMediaBuy = isServiceStep && !hasMediaBuyStep
  const needShowDialogToAddProductSample = isMediaBuyStep && !hasSampleStep

  const summaryPreviousStep = hasMediaBuyStep ? BRIEF_STEPS.MEDIA_BUY : BRIEF_STEPS.SERVICES
  const finalSummaryPreviousStep = skipUploadStep ? BRIEF_STEPS.PARTNER : BRIEF_STEPS.UPLOAD
  const needShowSummary = !owner?.isApproved && currentStep === summaryPreviousStep
  const disableEdit = !canEditBrief(brief.status)
  const isDraft = brief.status === BRIEF_STATUS.DRAFT
  const isSelectPartners = brief.status === BRIEF_STATUS.SELECT_PARTNERS
  const blockEditBrief = (isProductStep) || (disableEdit && !isFinalSummary) || (disableEdit && skipUploadStep && isFinalSummary)

  const onlyInsert = isInsert(productStepFields.distributionItem)
  const onlySample = isSample(productStepFields.distributionItem)
  const lastFilledStepIndex = brief.lastFilledStep ? formSteps.indexOf(brief.lastFilledStep) : 0

  const [bankTransferFiles, setBankTransferFiles] = useState<File[]>([])
  const [purchaseOrderFiles, setPurchaseOrderFiles] = useState<File[]>([])

  const bankTransferUploaded = Boolean(bankTransferFiles.length)
  const purchaseOrderUploaded = Boolean(purchaseOrderFiles.length)

  const paymentFilesState = useSelector(getBriefViewPaymentFilesState)
  const purchaseOrderProcessing = paymentFilesState.po.isUploading || paymentFilesState.po.isRemoving
  const bankTransferProcessing = paymentFilesState.bankTransfer.isUploading || paymentFilesState.bankTransfer.isRemoving

  const paymentInReview = Boolean(briefView.payment?.inReview)
  const submitPaymentButtonDisabled =
    (briefView.status !== BRIEF_STATUS.PENDING && briefView.status !== BRIEF_STATUS.REJECTED) &&
    (
      paymentProcessing ||
      purchaseOrderProcessing ||
      bankTransferProcessing ||
      paymentInReview ||
      (!purchaseOrderUploaded && !bankTransferUploaded)
    )

  const onPaymentFilesChange = (files: { files: File[]; filesType: IBillPaymentUploadTypes }[]) => {
    const bankTransfer = files.filter(({ filesType }) => filesType === 'bankTransfer')
    const purchaseOrder = files.filter(({ filesType }) => filesType === 'po')

    setBankTransferFiles(bankTransfer[0].files)
    setPurchaseOrderFiles(purchaseOrder[0].files)
  }

  const handleSubmitBrief = async () => {
    //generate quotation and upload
    const markup = document.getElementById('brief_quotation_print_area')
    if (!markup) return

    const pdf = await getBriefQuotationPdf(markup, 'en')
    // pdf.save('Brief Quotation.pdf')

    let blob = pdf.output('blob')
    await dispatch(
      actions.uploadGeneratedQuotation(
        [blob],
        BRIEF_UPLOAD_TYPES.BRIEFS_QUOTATION_FILES,
        id
      )
    )

    if (isDraft || isSelectPartners) {
      const response: any = await dispatch(actions.approveBrief(brief.id!))
      if (response?.data?.status === 409 && response?.data?.error?.payload?.invalid) {
        await dispatch(actions.createBriefFailure(response.data.error.message))
        setDiscountCodeErrorModal({
          title: `${cart.couponCode}: ${t(`code_invalid`)}`,
          visible: true,
          description: response.data.error.message,
        })
      }

      if (brief.owner?.isApproved && paymentStepEnabled) {
        setCurrentStep(BRIEF_STEPS.PAYMENT)
        return
      }

      history.push(PATHS.BRIEF)
      return
    }

    if (window.location.pathname.includes('/invitedClients/briefs')) {
      history.push(PATHS.INVITED_CLIENTS_BRIEFS)
      return
    }

    if (brief.owner?.isApproved && paymentStepEnabled) {
      setCurrentStep(BRIEF_STEPS.PAYMENT)
      return
    }

    history.push(PATHS.BRIEF)
  }

  const onProceedDiscountCodeErrorModal = async () => {
    const cartData = { ...cart }
    const cartItems = cartData.additional.items

    const result = calculation(cartItems, settings.managementFee, settings.partnerVat)

    cartData.additional.managementFee = result.managementFee
    cartData.additional.taxes = result.taxes
    cartData.additional.cartTotal = result.total
    cartData.couponCode = ''

    delete cartData.sampleAllocated

    await dispatch(actions.addToCart(cartData))
    await dispatch(actions.fetchCart(id))

    setDiscountCodeErrorModal({
      title: '',
      visible: false,
      description: '',
    })
  }

  const submitButtonText = () => {
    if (isPaymentStep) {
      return (briefView.status === BRIEF_STATUS.PENDING || briefView.status === BRIEF_STATUS.REJECTED)
        ? t("Done")
        : t(`make_payment`)
    }

    if (additionalStep === ADDITIONAL_STEPS.SUMMARY && !brief.owner?.isApproved) {
      return t(`save`)
    }

    // if (additionalStep === ADDITIONAL_STEPS.FINAL_SUMMARY && (!brief.owner?.id || !brief.owner?.isApproved || !paymentStepEnabled)) {
    if (isFinalSummary && (!brief.owner?.id || !brief.owner?.isApproved || !paymentStepEnabled)) {
      return t(`submit_brief`)
    }

    if (!isPaymentStep) {
      return t(`next`)
    }

    return isDraft ? t(`submit_and_view_quotation`) : t(`save_brief`)
  }

  const handleMediaBuyDialog = (e: MouseEvent, value: boolean) => {
    setAddMediaBuy(value)
  }

  const handleProductSampleDialog = (e: MouseEvent, value: boolean) => {
    setProductSample(value)
  }

  const showFinalSummary = () => {
    setCurrentStep(BRIEF_STEPS.SUMMARY)
    setAdditionalStep(ADDITIONAL_STEPS.FINAL_SUMMARY)
  }

  const saveMediaBuy = async () => {
    setAdditionalStep(null)
    setAddMediaBuy(false)
    briefTypes.push(BRIEF_TYPES.MEDIA_BUYING)
    await updateBriefTypes(BRIEF_TYPES.MEDIA_BUYING)
  }

  const saveProductSample = async () => {
    setAdditionalStep(null)
    setProductSample(false)
    briefTypes.push(BRIEF_TYPES.PRODUCT_SAMPLE)
    await updateBriefTypes(BRIEF_TYPES.PRODUCT_SAMPLE)
  }

  const handleNext = async () => {
    const index = formSteps.indexOf(currentStep)
    const lastIndex = index === formSteps.length - 1;

    const bannerWithoutMedia = brief.BRIEF_UPLOAD.uploadMaterial.filter((upload:any)=>{
      if(upload.mediaType === 'Banner' && !upload.imageTargetUrl.english && !upload.imageTargetUrl.arabic){
        return upload;
      }
    })
    if(bannerWithoutMedia.length > 0){
      qnotification({type:'error',message:t("Atleast one of the desktop/mobile media is required for Banner Ads")})
      return;
    }

    if (isFinalSummary && paymentStepEnabled === true) {
      await saveBrief()
      // setCurrentStep(BRIEF_STEPS.PAYMENT)
      await handleSubmitBrief()
      briefView.status === BRIEF_STATUS.MISSING_INFO && setClientRequiredFieldsOpen(true);
      return
    }

    if (additionalStep === ADDITIONAL_STEPS.FINAL_SUMMARY && paymentStepEnabled === false) {
      await saveBrief()
      return
    }

    if (!additionalStep && isPaymentStep) {
      if (briefView.status === BRIEF_STATUS.PENDING) return setAdditionalStep(ADDITIONAL_STEPS.CONGRATS);
      if (briefView.status === BRIEF_STATUS.REJECTED) return history.push(PATHS.BRIEF);

      setPaymentProcessing(true)

      if (bankTransferUploaded) {
        await dispatch(actions.uploadViewBriefPaymentFile({ files: bankTransferFiles, filesType: 'bankTransfer' }))
      }

      if (purchaseOrderUploaded) {
        await dispatch(actions.uploadViewBriefPaymentFile({ files: purchaseOrderFiles, filesType: 'po' }))
      }

      return setAdditionalStep(ADDITIONAL_STEPS.CONGRATS)
    }

    // show dialog Media Buy
    if (!additionalStep && needShowDialogToAddMediaBuy && !addMediaBuyStep) {
      setAdditionalStep(ADDITIONAL_STEPS.MEDIA_BUY_DIALOG)
      await saveBrief()

      return
    }

    // show dialog Product
    if (!additionalStep && needShowDialogToAddProductSample && !addProductSample) {
      setAdditionalStep(ADDITIONAL_STEPS.PRODUCT_SAMPLE_DIALOG)
      await saveBrief()

      return
    }

    // set&show media buy step
    // if (additionalStep === ADDITIONAL_STEPS.MEDIA_BUY_DIALOG && addMediaBuyStep) {
    //   await saveMediaBuy()
    //   setCurrentStep(BRIEF_STEPS.MEDIA_BUY)

    //   return
    // }
    if (brief.promotionId && isTargetingStep) {
      await saveBrief()
      setCurrentStep(BRIEF_STEPS.UPLOAD)
      return
    }

    // set Product Sample
    if (additionalStep === ADDITIONAL_STEPS.PRODUCT_SAMPLE_DIALOG && addProductSample) {
      await saveProductSample()
      setCurrentStep(BRIEF_STEPS.PRODUCT)

      return
    }

    // show summary
    if (additionalStep !== ADDITIONAL_STEPS.SUMMARY && !addMediaBuyStep && needShowSummary) {
      await saveBrief()
      setAdditionalStep(ADDITIONAL_STEPS.SUMMARY)

      return
    }

    if (!owner?.isApproved && additionalStep === ADDITIONAL_STEPS.SUMMARY) {
      await handleSubmitBrief()
      return
    }

    // show final summary
    if (isUploadStep && !needShowDialogToAddMediaBuy) {
      // @ts-ignore
      const hasErrors = uploadFormRef.current.validateFields()

      if (hasErrors) return
      else if (uploadError) return

      if (imageLoadingList.length > 0) return

      await saveBrief()
      showFinalSummary()

      return
    }

    await saveBrief()
    setAdditionalStep(null)
    if (!lastIndex) {
      setCurrentStep(formSteps[index + 1])
    }
  }


  const handleBack = () => {
    const index = formSteps.indexOf(currentStep)

    if (brief.promotionId && isUploadStep) {
      setCurrentStep(BRIEF_STEPS.TARGETING)
      return
    }

    if (additionalStep === ADDITIONAL_STEPS.MEDIA_BUY_DIALOG) {
      setAdditionalStep(null)
      setCurrentStep(BRIEF_STEPS.SERVICES)
      return
    }

    if (additionalStep === ADDITIONAL_STEPS.PRODUCT_SAMPLE_DIALOG) {
      setAdditionalStep(null)
      setCurrentStep(BRIEF_STEPS.MEDIA_BUY)
      return
    }

    if (additionalStep === ADDITIONAL_STEPS.SUMMARY) {
      setAdditionalStep(null)
      setCurrentStep(summaryPreviousStep)
      return
    }

    if (additionalStep === ADDITIONAL_STEPS.FINAL_SUMMARY) {
      setAdditionalStep(null)
      setCurrentStep(finalSummaryPreviousStep)
      return
    }

    if (index === 0) {
      return
    }

    setCurrentStep(formSteps[index - 1])
  }

  const setCurrentStep = (step: BRIEF_STEPS) => {
    setAdditionalStep(null)
    dispatch(actions.setCurrentStep(step))
  }

  const forceUpdateProductStep = async () => {
    const updatedBrief = { ...brief }
    const productFields = { ...productStepFields }

    productFields.briefStartDate = null
    productFields.distributionItem = null
    productFields.quantity = null
    productFields.duration = null

    setProductStepFields(productFields)
    updatedBrief[BRIEF_STEPS.PRODUCT] = productFields

    await dispatch(actions.updateBrief(BRIEF_STEPS.PRODUCT, updatedBrief))
  }

  const forceUpdateServicesStep = async () => {
    if (!brief.id) {
      return
    }

    const updatedBrief = { ...brief }

    if (onlyInsert) {
      servicesInitState.servicePrinting = servicesStepFields.servicePrinting
      servicesInitState.printingPriceId = servicesStepFields.printingPriceId
      servicesInitState.serviceIncludingCoupon = servicesStepFields.serviceIncludingCoupon
      servicesInitState.serviceDesign = servicesStepFields.serviceDesign
      servicesInitState.serviceImages = servicesStepFields.serviceImages
      servicesInitState.copackingPriceId = null
    }

    if (onlySample) {
      servicesInitState.servicePackageType = servicesStepFields.servicePackageType
      servicesInitState.servicePrinting = servicesStepFields.servicePrinting
      servicesInitState.printingPriceId = servicesStepFields.printingPriceId
      servicesInitState.servicePackaging = servicesStepFields.servicePackaging
      servicesInitState.copackingPriceId = servicesStepFields.copackingPriceId
      servicesInitState.servicePackagingQuantity = servicesStepFields.servicePackagingQuantity
      servicesInitState.serviceIsLiquid = servicesStepFields.serviceIsLiquid
      servicesInitState.hasFreeSample = servicesStepFields.hasFreeSample
      servicesInitState.serviceImages = servicesStepFields.serviceImages
    }

    setServicesStepFields(servicesInitState)
    updatedBrief[BRIEF_STEPS.SERVICES] = servicesInitState

    await dispatch(actions.updateBrief(BRIEF_STEPS.SERVICES, updatedBrief))
  }

  const forceUpdateMediaBuyStep = () => {
    if (!brief.id) {
      return
    }

    const updatedBrief = { ...brief }
    setMediaBuyStepFields(mediaBuyInitState)
    updatedBrief[BRIEF_STEPS.MEDIA_BUY] = mediaBuyInitState

    dispatch(actions.updateBrief(BRIEF_STEPS.MEDIA_BUY, updatedBrief))
  }

  const updateBriefBeforeSave = () => {
    const updatedBrief = { ...brief }
    const currentStepIndex = formSteps.indexOf(currentStep)

    if (currentStepIndex > lastFilledStepIndex) {
      updatedBrief.lastFilledStep = currentStep
    }

    if (isZeroStep) {
      updatedBrief[BRIEF_STEPS.TYPE].briefTypes = briefTypes
      if (!hasSampleStep && lastFilledStepIndex > 0) {
        forceUpdateProductStep()
        forceUpdateServicesStep()
      }

      if (!hasMediaBuyStep && lastFilledStepIndex > 0) {
        forceUpdateMediaBuyStep()
      }
    }

    if (isProductStep) {
      const productFields = { ...productStepFields }
      productFields.briefStartDate = productFields.briefStartDate ? getIsoDate(productFields.briefStartDate) : null
      productFields.instructions = productStepFields.instructions === '' ? null : productStepFields.instructions
      updatedBrief[BRIEF_STEPS.PRODUCT] = productFields

      if ((onlyInsert || onlySample) && lastFilledStepIndex > 1) {
        forceUpdateServicesStep()
      }
    }

    if (isTargetingStep) {
      updatedBrief[BRIEF_STEPS.TARGETING] = targetingStepFields
    }

    if (isServiceStep) {
      updatedBrief[BRIEF_STEPS.SERVICES] = servicesStepFields
    }

    if (isMediaBuyStep) {
      const mediaBuyFields = { ...mediaBuyStepFields }
      mediaBuyFields.mediaStartDate = mediaBuyFields.mediaStartDate ? getIsoDate(mediaBuyFields.mediaStartDate) : null
      updatedBrief[BRIEF_STEPS.MEDIA_BUY] = mediaBuyFields
    }

    return updatedBrief
  }

  const updateBriefTypes = async (type: BRIEF_TYPES) => {
    const updatedBrief = { ...brief }
    updatedBrief[BRIEF_STEPS.TYPE].briefTypes = briefTypes
    if (type === BRIEF_TYPES.PRODUCT_SAMPLE) {
      updatedBrief.lastFilledStep = BRIEF_STEPS.PRODUCT
    }
    await dispatch(actions.updateBrief(BRIEF_STEPS.TYPE, updatedBrief))
  }

  const saveBrief = async () => {
    const briefForSave = updateBriefBeforeSave()
    await dispatch(actions.updateBrief(currentStep, briefForSave))
  }

  const checkStatus = (step: BRIEF_STEPS) => {
    const stepIndex = formSteps.indexOf(step)
    const lockedSteps = [BRIEF_STEPS.PARTNER, BRIEF_STEPS.UPLOAD, BRIEF_STEPS.PAYMENT]

    if (disableEdit && step !== BRIEF_STEPS.UPLOAD && step !== BRIEF_STEPS.SUMMARY && step !== BRIEF_STEPS.PAYMENT) {
      return BRIEF_STEP_STATUS.DISABLED
    }

    if (disableEditWithPaymentStatus && step !== BRIEF_STEPS.UPLOAD && step !== BRIEF_STEPS.SUMMARY && step !== BRIEF_STEPS.PAYMENT) {
      return BRIEF_STEP_STATUS.DISABLED
    }

    if (brief.promotionId && step === BRIEF_STEPS.PARTNER) {
      return BRIEF_STEP_STATUS.DISABLED
    }

    if (!owner?.isApproved && lockedSteps.includes(step)) {
      return BRIEF_STEP_STATUS.DISABLED
    }

    if (currentStep === step) {
      return BRIEF_STEP_STATUS.CURRENT
    }

    if (lastFilledStepIndex >= stepIndex) {
      return BRIEF_STEP_STATUS.PASSED
    }

    return BRIEF_STEP_STATUS.DEFAULT
  }

  const handleStepperClick = (step: BRIEF_STEPS) => {
    if (!canProceed()) return
    setCurrentStep(step)
  }

  const steps = !isZeroStep && (
    <div className="qu-stepper">
      <div className="steps">
        {formSteps.map(
          (step: BRIEF_STEPS, index: number) =>
            step !== BRIEF_STEPS.TYPE && (
              <QBriefStep
                key={step}
                title={`${index}. ${t(`stepper.${BRIEF_STEPS_NAMES[step]}`)}`}
                onClickPath={() => handleStepperClick(step)}
                status={checkStatus(step)}
              />
            )
        )}
      </div>
    </div>
  )

  const handleBriefTypeStep = (briefTypes: BRIEF_TYPES[]) => {
    setBriefTypes(briefTypes)
  }

  const handleProductStep = (fields: IProductStep) => {
    if (fields.distributionItem === DISTRIBUTION_TYPE.PRODUCT_SAMPLE && lastFilledStepIndex > 1) {
      const newBrief = { ...brief }
      newBrief.lastFilledStep = BRIEF_STEPS.TARGETING
      dispatch(actions.createBriefSuccess(newBrief))
    }

    setProductStepFields(fields)
  }

  const handleTargetingStep = (fields: ITargetingStep) => {
    setTargetingStepFields(fields)
    dispatch(actions.getPotentialAudienceReach(id, fields))
  }

  const handleServicesStep = (fields: IServicesStep) => {
    setServicesStepFields(fields)
  }

  const handleMediaBuyStep = (fields: IMediaBuyStep) => {
    setMediaBuyStepFields(fields)
  }

  const handleUploadStep = (fields: IUploadPartner[]) => {
    setUploadError(false)
    if (fields.length > 0) {
      fields.map((ele: any) => {
        const uploadSetting: any = uploadSettings.find(elem => elem.partnerId === ele.partnerId)
        const validationData = uploadSetting.mediaTypes.find(
          (elem: any) =>
            (elem.subName ? elem.subName : elem.name) === (ele.mediaSubType ? ele.mediaSubType : ele.mediaType)
        )
        if (ele?.adTitle?.english && validationData?.adTitleCharacterLimit) {
          if (ele?.adTitle?.english.trim().length > validationData?.adTitleCharacterLimit) {
            setUploadError(true)
            return
          }
        }
        if (ele?.adTitle?.arabic && validationData?.adTitleCharacterLimit) {
          if (ele?.adTitle?.arabic.trim().length > validationData?.adTitleCharacterLimit) {
            setUploadError(true)
            return
          }
        }
        if (ele?.adDescription?.english && validationData?.adDescriptionCharacterLimit) {
          if (ele?.adDescription?.english.trim().length > validationData?.adDescriptionCharacterLimit) {
            setUploadError(true)
            return
          }
        }
        if (ele?.adDescription?.arabic && validationData?.adDescriptionCharacterLimit) {
          if (ele?.adDescription?.arabic.trim().length > validationData?.adDescriptionCharacterLimit) {
            setUploadError(true)
            return
          }
        }
      })
    }
    brief[BRIEF_STEPS.UPLOAD].uploadMaterial = fields
  }

  const canProceed = () => {
    if (isZeroStep && briefTypes.length === 0) return false
    if (isProductStep) return validateProductStep(productStepFields, hasSampleStep)
    if (isTargetingStep) return validateTargetingStep(targetingStepFields, settings.countries)
    if (isServiceStep) return validateServicesStep(servicesStepFields, onlyInsert)
    if (isMediaBuyStep) return validateMediaBuyStep(mediaBuyStepFields)

    return true
  }

  const renderCurrentStepForm = () => {
    if (isProductStep) {
      return (
        <ProductBriefForm
          productFields={productStepFields}
          onChange={handleProductStep}
          onFinishLater={handleFinishLater}
          disabledFinishLater={!canProceed()}
        />
      )
    }

    if (isTargetingStep) {
      return (
        <TargetingForm
          brief={brief}
          targetingFields={targetingStepFields}
          onChange={handleTargetingStep}
          onFinishLater={handleFinishLater}
          disabledFinishLater={!canProceed()}
        />
      )
    }

    if (isServiceStep) {
      return (
        <ServicesForm
          brief={brief}
          servicesFields={servicesStepFields}
          onChange={handleServicesStep}
          onFinishLater={handleFinishLater}
          disabledFinishLater={!canProceed()}
        />
      )
    }

    if (isMediaBuyStep) {
      return (
        <MediaBuyForm
          brief={brief}
          mediaBuyFields={mediaBuyStepFields}
          onChange={handleMediaBuyStep}
          onFinishLater={handleFinishLater}
          disabledFinishLater={!canProceed()}
        />
      )
    }

    return null
  }

  if (additionalStep === ADDITIONAL_STEPS.CONGRATS) {
    return <SubmittedSuccessfully approvedUser={!!owner?.isApproved} briefDetails={briefView} />
  }

  if (!brief.lastFilledStep) {
    return (
      <div className="qu-brief-layout">
        <BriefHeader isEditMode={isEditMode} stepper={null} showPartnerFilter={false} />
        <div className="qu-brief-main">
          <SpinnerCenter />
        </div>
      </div>
    )
  }

  return (
    <div className="qu-brief-layout">
      <BriefHeader isEditMode={isEditMode} stepper={steps} showPartnerFilter={false} />
      <div className={`qu-brief-main ${isPartnerStep ? 'qu-brief-partner height-auto' : ''}`}>
        {((isSaving && !isRecommendedPartnersLoading) || isUpdatingCart) && (
          <SpinnerCenter
            text={isSaving && isFinalSummary && (isDraft || isSelectPartners) ? t(`generating_quotation`) : ''}
          />
        )}

        {additionalStep === ADDITIONAL_STEPS.SUMMARY && (
          <SummaryForm briefId={brief.id!} setCurrentStep={setCurrentStep} />
        )}

        {additionalStep === ADDITIONAL_STEPS.MEDIA_BUY_DIALOG && (
          <BriefDialog checked={addMediaBuyStep} onChange={handleMediaBuyDialog} title={t(`media_buying`)} />
        )}

        {additionalStep === ADDITIONAL_STEPS.PRODUCT_SAMPLE_DIALOG && (
          <BriefDialog checked={addProductSample} onChange={handleProductSampleDialog} title={t(`product_sample`)} />
        )}

        {isFinalSummary && !isPaymentStep && (
          <FinalSummary onFinishLater={handleFinishLater} briefId={brief.id!} setCurrentStep={setCurrentStep} />
        )}

        {isZeroStep && !additionalStep && <BriefTypeForm briefTypes={briefTypes} onChange={handleBriefTypeStep} />}
        {isPartnerStep && !additionalStep && <BriefPartnerForm brief={brief} saveMediaBuy={saveMediaBuy} />}
        {isUploadStep && !additionalStep && (
          <UploadForm
            ref={uploadFormRef}
            brief={brief}
            onChange={handleUploadStep}
            onFinishLater={handleFinishLater}
            disabledFinishLater={!canProceed()}
            skipUploadStep={skipUploadStep}
            showFinalSummary={showFinalSummary}
          />
        )}
        {isPaymentStep && (
          <Payment
            briefId={brief.id!}
            setCurrentStep={setCurrentStep}
            onFilesChange={onPaymentFilesChange}
            hideSubmitButton={true}
          />
        )}
        {!isZeroStep && !additionalStep && !isPartnerStep && !isUploadStep && !isPaymentStep && !isFinalSummary && (
          <div className="qu-brief-content brief-step">
            <Row gutter={16}>
              <Col sm={24} md={17}>
                {renderCurrentStepForm()}
              </Col>
              <Col sm={24} md={7}>
                <div className="qu-summary-wrapper">
                  <BriefSummary brief={brief} potentialAudienceReachData={potentialAudienceReachData} />
                </div>
              </Col>
            </Row>
          </div>
        )}
      </div>
      {showFooter && (
        <BriefFooter
          disableNext={(isPaymentStep && submitPaymentButtonDisabled) || !canProceed()}
          disableBack={blockEditBrief}
          isZeroStep={isProductStep}
          isPaymentStep={isPaymentStep}
          hideArrow={additionalStep === ADDITIONAL_STEPS.SUMMARY && !brief.owner?.isApproved}
          onBack={handleBack}
          onNext={isFinalSummary && !paymentStepEnabled ? handleSubmitBrief : handleNext}
          onFinishLater={handleFinishLater}
          nextButtonText={submitButtonText()}
        />
      )}

      <Modal
        wrapClassName="qu-modal"
        title={<h3>{discountCodeErrorModal.title}</h3>}
        width={400}
        closeIcon={<IconsCommon.IconClose />}
        visible={discountCodeErrorModal.visible}
        onCancel={() =>
          setDiscountCodeErrorModal({
            title: '',
            visible: false,
            description: '',
          })
        }
        footer={false}
      >
        <div>
          <p className="mb-25">{discountCodeErrorModal.description}</p>
          <div className="align-vertical text-center">
            <QButton onClick={onProceedDiscountCodeErrorModal} className="w-280 mb-20" type="primary">
              <span className="mr-5">{t(`remove_code_and_review_brief`)}</span>
            </QButton>
          </div>
        </div>
      </Modal>

      <Modal
        wrapClassName="qu-modal"
        title={<h3>{t("brief.incomplete-client-profile.Incomplete Client Profile", { ns: 'translation' })}</h3>}
        // width={400}
        closeIcon={<IconsCommon.IconClose />}
        visible={clientRequiredFieldsOpen}
        onCancel={() => setClientRequiredFieldsOpen(false)}
        footer={false}
      >
        <div>
          <p className="mb-25">
            {t("brief.incomplete-client-profile.Last_thing_before_campaign_kick-off", { ns: 'translation' })}
          </p>
          <div className="grid-row grid-row--smg grid-row--jce">
            <div className="grid-col grid-col--auto">
              <QButton onClick={() => history.push(`/users/clients/profile/${briefView.owner.id}`)} type="primary">
                {t("brief.incomplete-client-profile.Edit Profile", { ns: 'translation' })}
              </QButton>
            </div>
            <div className="grid-col grid-col--auto">
              <QButton
                className="qu-button-outline"
                onClick={() => setClientRequiredFieldsOpen(false)}
              >
                {t("brief.incomplete-client-profile.Cancel", { ns: 'translation' })}
              </QButton>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}
