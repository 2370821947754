import React, { ReactElement, useEffect, useState } from 'react'
import { Col, Form, QButton, QInput, QServicesItemView, Row } from 'quantum_components'
import {
  IAvailableServiceItem,
  IServiceItemPricing,
  IPartnerDetailsService,
  IAdEstimation,
} from '../../modules/brief/brief.types'
import { REGEXES } from 'src/constants'
import { getLocaleCost, getLocaleCostWithoutCurrencyNDecimals, renderRoundedNumber } from '../../helpers/currency'
import { ReactComponent as CheckIcon } from 'src/assets/icons/check-icon.svg'
import { useSelector } from 'react-redux'
import {
  getBriefSettings,
  getIsRejectionFlow,
  getSampleAllocated,
  getTotalQuantity,
  getUpdatingCart,
} from '../../modules/brief/brief.selectors'
import {
  DISTRIBUTION_TYPE,
  LOGISTIC_RATES,
  PARTNER_STEP_BUTTONS,
  MIN_LOGISTIC_PRICE_TEXT,
  BRIEF_TYPES,
} from '../../constants/brief'
import { REQUEST_STATUS } from '../../constants/request'
import CouponForm from '../Coupon/CouponForm'
import { getCurrency } from '../../modules/core/core.selectors'
import { abbreviateNumberWithoutDecimal } from 'src/helpers/abbreviateNumber'
import { DynamicPricingTypeLabels, PRICING_MODEL } from 'src/constants/profile'
import { useTranslation } from 'react-i18next'
import { snakeCase } from 'lodash'

interface Props {
  selectedServices: IAvailableServiceItem[]
  onChangeServices: (services: IAvailableServiceItem[]) => void
  handleSubmitForm: (skipRejection: boolean, button: string) => void
  editMode: boolean
  handleEditMode: () => void
  logisticSampleRate: number
  logisticInsertRate: number
  logisticSamplePrice: number
  logisticInsertPrice: number
  stickerCost: number
  stickerPrice: number
  subTotal: number
  form: any
  details?: any
  brief?: any
  adEstimation: (estimations: IAdEstimation) => void
}

export default function SelectedServicesForm({
  selectedServices,
  onChangeServices,
  handleSubmitForm,
  editMode,
  handleEditMode,
  logisticSampleRate,
  logisticInsertRate,
  logisticSamplePrice,
  logisticInsertPrice,
  stickerPrice,
  stickerCost,
  subTotal,
  form,
  details,
  brief,
  adEstimation,
}: Props): ReactElement {
  const {t} = useTranslation(['translationABrief','translationDashboard'])
  const totalQty = useSelector(getTotalQuantity)
  const sampleAllocated = useSelector(getSampleAllocated)
  const settings = useSelector(getBriefSettings)
  const updatingCart = useSelector(getUpdatingCart)
  const currency = useSelector(getCurrency)

  const MINIMUM_DISTRIBUTION_LOGISTIC_COUNT = settings.MINIMUM_DISTRIBUTION_LOGISTIC_COUNT
  const isRejectionFlow = useSelector(getIsRejectionFlow)

  const [targetServices, setTargetServices] = useState<any>([])
  const [adEstimates, setAdEstimates] = useState<any>([])
  const [totalBudget, setTotalBudget] = useState<number>(0)

  const [offset,setOffset] = useState(0);

  useEffect(()=>{
    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  },[])

  const shouldShow = offset > 345 ? true : false;

  useEffect(() => {
    const services = {} as any
    selectedServices.forEach((s: IAvailableServiceItem) => {
      if (targetServices.includes(s.key) && details.mbPricingModel === PRICING_MODEL.METRICS) {
        services[s.key] = s.pricing.total > 0 ? s.pricing.total : null
      } else {
        services[s.key] = s.pricing.quantity
      }
    })

    form.setFieldsValue(services)
  })

  useEffect(() => {
    const targetService = details.availableServices.map((s: any) =>
      s.serviceSubName ? s.serviceSubName : s.serviceName
    )
    setTargetServices(targetService)
    const filteredServices: any = []
    const adEstimate: any = []
    details.availableServices.map((ele: any) => {
      const name = ele.serviceSubName ? ele.serviceSubName : ele.serviceName
      const selectedService = selectedServices.find(s => s.key === name)
      if (selectedService) {
        filteredServices.push({
          ...selectedService,
          serviceDailyImpressions: ele.serviceDailyImpressions,
          serviceCostPerImpression: ele.serviceCostPerImpression,
          serviceDailyClicks: ele.serviceDailyClicks,
          serviceCostPerClick: ele.serviceCostPerClick,
          serviceDailyReach: ele.serviceDailyReach,
          serviceCostPerReach: ele.serviceCostPerReach,
        })
      }
    })

    let total_days_budget = 0
    let total_impressions_per_week = 0
    let total_clicks_per_week = 0
    let total_reach_per_week = 0
    let total_budget = 0
    filteredServices.map((ele: any) => {
      if (ele.pricing.total) {
        total_budget = total_budget + ele.pricing.total
      }
      const total_impressions = ele.serviceCostPerImpression
        ? ele.pricing.total / parseFloat(ele.serviceCostPerImpression)
        : null
      const total_days_for_entered_budget = total_impressions ? total_impressions / ele.serviceDailyImpressions : null
      if (total_impressions) total_impressions_per_week = total_impressions_per_week + total_impressions

      const total_clicks = ele.serviceCostPerClick ? ele.pricing.total / parseFloat(ele.serviceCostPerClick) : null
      const total_days_for_entered_budget_clicks = total_clicks ? total_clicks / ele.serviceDailyClicks : null
      if (total_clicks) total_clicks_per_week = total_clicks_per_week + total_clicks

      const total_reach = ele.serviceCostPerReach ? ele.pricing.total / parseFloat(ele.serviceCostPerReach) : null
      const total_days_for_entered_budget_reach = total_reach ? total_reach / ele.serviceDailyReach : null
      if (total_reach) total_reach_per_week = total_reach_per_week + total_reach

      let totalDaysAverage = 0
      let totalDaysAverageNumber = 0
      if (total_days_for_entered_budget) {
        totalDaysAverage = totalDaysAverage + total_days_for_entered_budget
        totalDaysAverageNumber = totalDaysAverageNumber + 1
      }
      if (total_days_for_entered_budget) {
        totalDaysAverage = totalDaysAverage + total_days_for_entered_budget
        totalDaysAverageNumber = totalDaysAverageNumber + 1
      }
      if (total_days_for_entered_budget_reach) {
        totalDaysAverage = totalDaysAverage + total_days_for_entered_budget_reach
        totalDaysAverageNumber = totalDaysAverageNumber + 1
      }
      total_days_budget = totalDaysAverage
        ? total_days_budget + totalDaysAverage / totalDaysAverageNumber
        : total_days_budget
    })
    let mediaBuyEstimation: any = {
      partnerId: details.id,
    }
    if (total_days_budget) {
      adEstimate.push({
        label: t(`estimated_campaign_days`),
        min: parseInt(getLocaleCostWithoutCurrencyNDecimals(Math.round(total_days_budget * (90 / 100)))),
        max: parseInt(getLocaleCostWithoutCurrencyNDecimals(Math.round(total_days_budget * (110 / 100)))),
      })
      mediaBuyEstimation.campaignDays =
        parseInt(getLocaleCostWithoutCurrencyNDecimals(Math.round(total_days_budget * (90 / 100)))) !==
        parseInt(getLocaleCostWithoutCurrencyNDecimals(Math.round(total_days_budget * (110 / 100))))
          ? parseInt(getLocaleCostWithoutCurrencyNDecimals(Math.round(total_days_budget * (90 / 100)))) +
            ' - ' +
            parseInt(getLocaleCostWithoutCurrencyNDecimals(Math.round(total_days_budget * (110 / 100))))
          : parseInt(getLocaleCostWithoutCurrencyNDecimals(Math.round(total_days_budget * (90 / 100)))) === 0
          ? '1'
          : parseInt(getLocaleCostWithoutCurrencyNDecimals(Math.round(total_days_budget * (90 / 100))))
    }
    if (total_impressions_per_week) {
      adEstimate.push({
        label: t(`estimated_impressions`),
        min: abbreviateNumberWithoutDecimal(Math.round(total_impressions_per_week * (85 / 100))),
        max: abbreviateNumberWithoutDecimal(Math.round(total_impressions_per_week * (115 / 100))),
      })
      mediaBuyEstimation.impressions =
        abbreviateNumberWithoutDecimal(Math.round(total_impressions_per_week * (85 / 100))) +
        '-' +
        abbreviateNumberWithoutDecimal(Math.round(total_impressions_per_week * (115 / 100)))
    }
    if (total_clicks_per_week) {
      adEstimate.push({
        label: t(`total_clicks`),
        min: abbreviateNumberWithoutDecimal(Math.round(total_clicks_per_week * (85 / 100))),
        max: abbreviateNumberWithoutDecimal(Math.round(total_clicks_per_week * (115 / 100))),
      })
      mediaBuyEstimation.clicks =
        abbreviateNumberWithoutDecimal(Math.round(total_clicks_per_week * (85 / 100))) +
        '-' +
        abbreviateNumberWithoutDecimal(Math.round(total_clicks_per_week * (115 / 100)))
    }
    if (total_reach_per_week) {
      adEstimate.push({
        label: t(`total_reach`),
        min: abbreviateNumberWithoutDecimal(Math.round(total_reach_per_week * (85 / 100))),
        max: abbreviateNumberWithoutDecimal(Math.round(total_reach_per_week * (115 / 100))),
      })
      mediaBuyEstimation.reach =
        abbreviateNumberWithoutDecimal(Math.round(total_reach_per_week * (85 / 100))) +
        '-' +
        abbreviateNumberWithoutDecimal(Math.round(total_reach_per_week * (115 / 100)))
    }
    setTotalBudget(total_budget)
    setAdEstimates(adEstimate)
    adEstimation(mediaBuyEstimation)
  }, [selectedServices])

  useEffect(() => {
    const targetService = details.availableServices.map((s: any) =>
      s.serviceSubName ? s.serviceSubName : s.serviceName
    )
    setTargetServices(targetService)
    const filteredServices: any = []
    const adEstimate: any = []
    details.availableServices.map((ele: any) => {
      const name = ele.serviceSubName ? ele.serviceSubName : ele.serviceName
      const selectedService = selectedServices.find(s => s.key === name)
      if (selectedService) {
        filteredServices.push({
          ...selectedService,
          serviceDailyImpressions: ele.serviceDailyImpressions,
          serviceCostPerImpression: ele.serviceCostPerImpression,
          serviceDailyClicks: ele.serviceDailyClicks,
          serviceCostPerClick: ele.serviceCostPerClick,
          serviceDailyReach: ele.serviceDailyReach,
          serviceCostPerReach: ele.serviceCostPerReach,
        })
      }
    })

    let total_days_budget = 0
    let total_impressions_per_week = 0
    let total_clicks_per_week = 0
    let total_reach_per_week = 0
    let total_budget = 0
    filteredServices.map((ele: any) => {
      if (ele.pricing.total) {
        total_budget = total_budget + ele.pricing.total
      }
      const total_impressions = ele.serviceCostPerImpression
        ? ele.pricing.total / parseFloat(ele.serviceCostPerImpression)
        : null
      const total_days_for_entered_budget = total_impressions ? total_impressions / ele.serviceDailyImpressions : null
      if (total_impressions) total_impressions_per_week = total_impressions_per_week + total_impressions

      const total_clicks = ele.serviceCostPerClick ? ele.pricing.total / parseFloat(ele.serviceCostPerClick) : null
      const total_days_for_entered_budget_clicks = total_clicks ? total_clicks / ele.serviceDailyClicks : null
      if (total_clicks) total_clicks_per_week = total_clicks_per_week + total_clicks

      const total_reach = ele.serviceCostPerReach ? ele.pricing.total / parseFloat(ele.serviceCostPerReach) : null
      const total_days_for_entered_budget_reach = total_reach ? total_reach / ele.serviceDailyReach : null
      if (total_reach) total_reach_per_week = total_reach_per_week + total_reach

      let totalDaysAverage = 0
      let totalDaysAverageNumber = 0
      if (total_days_for_entered_budget) {
        totalDaysAverage = totalDaysAverage + total_days_for_entered_budget
        totalDaysAverageNumber = totalDaysAverageNumber + 1
      }
      if (total_days_for_entered_budget) {
        totalDaysAverage = totalDaysAverage + total_days_for_entered_budget
        totalDaysAverageNumber = totalDaysAverageNumber + 1
      }
      if (total_days_for_entered_budget_reach) {
        totalDaysAverage = totalDaysAverage + total_days_for_entered_budget_reach
        totalDaysAverageNumber = totalDaysAverageNumber + 1
      }
      total_days_budget = totalDaysAverage
        ? total_days_budget + totalDaysAverage / totalDaysAverageNumber
        : total_days_budget
    })
    if (total_days_budget) {
      adEstimate.push({
        label: t(`estimated_campaign_days`),
        min: parseInt(getLocaleCostWithoutCurrencyNDecimals(Math.round(total_days_budget * (90 / 100)))),
        max: parseInt(getLocaleCostWithoutCurrencyNDecimals(Math.round(total_days_budget * (110 / 100)))),
      })
    }
    if (total_impressions_per_week) {
      adEstimate.push({
        label: t(`estimated_impressions`),
        min: abbreviateNumberWithoutDecimal(Math.round(total_impressions_per_week * (85 / 100))),
        max: abbreviateNumberWithoutDecimal(Math.round(total_impressions_per_week * (115 / 100))),
      })
    }
    if (total_clicks_per_week) {
      adEstimate.push({
        label: t(`total_clicks`),
        min: abbreviateNumberWithoutDecimal(Math.round(total_clicks_per_week * (85 / 100))),
        max: abbreviateNumberWithoutDecimal(Math.round(total_clicks_per_week * (115 / 100))),
      })
    }
    if (total_reach_per_week) {
      adEstimate.push({
        label: t(`total_reach`),
        min: abbreviateNumberWithoutDecimal(Math.round(total_reach_per_week * (85 / 100))),
        max: abbreviateNumberWithoutDecimal(Math.round(total_reach_per_week * (115 / 100))),
      })
    }
    setTotalBudget(total_budget)
    setAdEstimates(adEstimate)
  }, [selectedServices])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target
    const services = [...selectedServices]

    const target = services.find((s: IAvailableServiceItem) => s.key === id)

    const filteredService = details.availableServices.find((ele: IPartnerDetailsService) => {
      const name = ele.serviceSubName ? ele.serviceSubName : ele.serviceName
      return name === target?.key
    })

    if (!target) {
      return
    }

    if (!value || +value < 0) {
      target.pricing.quantity = null
      target.pricing.total = 0
    } else {
      if (filteredService && details.mbPricingModel === PRICING_MODEL.METRICS) {
        const quantity = +value > LOGISTIC_RATES.BIGGEST ? LOGISTIC_RATES.BIGGEST : +value
        target.pricing.quantity = 1
        target.pricing.total = quantity
        target.pricing.amount = quantity
        target.pricing.duration = null
      } else {
        const quantity = +value > LOGISTIC_RATES.BIGGEST ? LOGISTIC_RATES.BIGGEST : +value
        target.pricing.quantity = quantity
        target.pricing.total = target.pricing.amount * quantity
      }
    }

    if (target.requestStatus === REQUEST_STATUS.REJECTED) {
      target.requestStatus = REQUEST_STATUS.REVIEW_NOW
    }

    onChangeServices(services)
  }

  const handleRemove = (service: IAvailableServiceItem) => {
    let services = [...selectedServices]
    services.splice(services.indexOf(service), 1)
    onChangeServices(services)
  }

  const calcTotal = (pricing: number, currency: any) => {
    return renderRoundedNumber(pricing, currency)
  }

  const priceText = (pricing: IServiceItemPricing) => {
    const duration = pricing.duration ? t('pricing-duration.' + snakeCase(pricing.duration)) : '';
    return `${renderRoundedNumber(pricing.amount, pricing.currency)} ${duration}`
  }

  const renderSubTotal = () => {
    if (!selectedServices.length) {
      return `${currency} 0`
    }

    return renderRoundedNumber(subTotal, currency)
  }

  const renderHeader = () => {
    if (editMode && !selectedServices.length) {
      return (
        <div className="services-form-header mb-400">
          <div className="mb-35 mt-25 p text-center">{t(`select_available_services`)}</div>
        </div>
      )
    }
    if (!editMode) {
      return (
        <div className="services-form-header">
          <CheckIcon />
          <h2>{t(`selected_services`)}</h2>
          <p>
            {t(`selected_help`)}
          </p>
        </div>
      )
    }
  }

  const renderLogisticPrice = (price: number, rate: number, quantity: number) => {
    if (quantity < MINIMUM_DISTRIBUTION_LOGISTIC_COUNT) {
      return MIN_LOGISTIC_PRICE_TEXT
    }

    return `${renderRoundedNumber(rate, currency)} x ${quantity || 0}`
  }

  const hasServices = selectedServices.length > 0

  const disabledButton = !hasServices || updatingCart

  const getApproxDistributionTime = (service: any) => {
    if (details && Object.keys(details?.monthlyOrderRange).length > 0) {
      let weeks = Math.round(
        (service.pricing.quantity / details?.monthlyOrderRange?.min +
          service.pricing.quantity / details?.monthlyOrderRange?.max) /
          2
      )
      if (service.pricing.quantity && service.pricing.quantity > 0 && service.pricing.quantity % 1 === 0) {
        return `Approx ${weeks === 0 || weeks === 1 ? 1 + ' week' : weeks + ' weeks'}  for ${
          details?.companyName
        } to distribute ${service.pricing.quantity} samples`
      } else {
        return ''
      }
    }
  }

  const getCartLabel = (duration:any) => {
    if(!duration) return t(`QTY`);
    let label = duration.split(" ")[1];
    return label.length > 5 ? label.substring(0,4) + ".." : label
  }

  const handleGoToTop = () => {
    // @ts-ignore
    document.getElementById('partner_main').scrollTo({top:0,left:0,behavior:'smooth'})
    window.scroll({behavior:'smooth',top:0})
    return true
  }

  return (
    <div className="qu-details-aside">
      {renderHeader()}

      {brief.BRIEF_MEDIA_BUY.marketingObjective && details.mbPricingModel === PRICING_MODEL.METRICS && (
        <div className="cart-summary ad-estimates-main">
          <div className="h6 cart-summary__heading ad-estimates-title">Ad Estimates</div>
          <hr className="ad-estimates-hr" />
          {adEstimates.length > 0 ? (
            <div className="cart-summary__heading ad-estimates-body">
              Based on your total budget of{' '}
              {totalBudget ? renderRoundedNumber(totalBudget, currency) : getLocaleCost(totalBudget) + ' ' + currency}:
            </div>
          ) : (
            <div className="cart-summary__heading ad-estimates-body">
              Select a Media Buy Service and set a budget to get your Ad Estimates
            </div>
          )}
          <div className="p--sm cart-summary__ulist ad-estimates-text">
            {adEstimates.map((ele: any) => (
              <div key={ele.label}>
                <Row>
                  <Col span={24} className="ad-estimates-text-left">
                    {ele.label}
                  </Col>
                </Row>
                <Row>
                  <Col span={24} className="ad-estimates-text-right">
                    {ele.label === t(`estimated_campaign_days`)
                      ? ele.min !== ele.max
                        ? ele.min + ' - ' + ele.max
                        : ele.min === 0
                        ? '1'
                        : ele.min
                      : ele.min && ele.max
                      ? ele.min + ' - ' + ele.max
                      : '-'}
                  </Col>
                </Row>
              </div>
            ))}
          </div>
        </div>
      )}

      <Form form={form} className="services-form" layout="vertical">
        {selectedServices?.map((service: IAvailableServiceItem) =>
          editMode && !service.blocked ? (
            <div key={service.key} className="services-item-wrapper">
              <div className="services-item-header">
                <div className="services-item-name">
                  {service.productSubType
                    ? service.product + ': ' + DynamicPricingTypeLabels[service.productSubType]
                    : t(`dashboard.service-types-labels.${service.product}`,service.product,{ns:'translationDashboard'})}
                </div>
                {(!targetServices.includes(service.key) || details.mbPricingModel === PRICING_MODEL.DURATION) && (
                  <div className="services-item-price">{priceText(service.pricing)}</div>
                )}
              </div>
              <div className="services-form-item">
                <div className="services-item-qty">
                  {targetServices.includes(service.key) && details.mbPricingModel === PRICING_MODEL.METRICS
                    ? 'Budget'
                    : t(`common.${getCartLabel(service.pricing?.duration)}`,getCartLabel(service.pricing?.duration))}
                </div>
                <div className="services-item-input">
                  <Form.Item
                    className="mb-0"
                    name={service.key}
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        pattern:
                          targetServices.includes(service.key) && details.mbPricingModel === PRICING_MODEL.METRICS
                            ? REGEXES.POSITIVE_AMOUNT_REGEX_WITH_TWO_DECIMAL
                            : REGEXES.POSITIVE_NUMBERS_REGEX,
                        message:
                          targetServices.includes(service.key) && details.mbPricingModel === PRICING_MODEL.METRICS
                            ? t(`invalid_budget`)
                            : t(`invalid_quantity`),
                      },
                    ]}
                    validateTrigger={['onChange', 'onBlur']}
                  >
                    <QInput placeholder="0" type="number" min={0} onChange={handleInputChange} />
                  </Form.Item>
                </div>
                <div className="services-item-result">
                  <div className="services-item-value">
                    {brief.BRIEF_MEDIA_BUY.marketingObjective && targetServices.includes(service.key)
                      ? calcTotal(service.pricing.total, service.pricing.currency)
                      : calcTotal(service.pricing.total, service.pricing.currency)}
                  </div>
                  <div className="services-item-remove" onClick={() => handleRemove(service)}>
                    {t(`remove`)}
                  </div>
                </div>
              </div>
              {service.product === DISTRIBUTION_TYPE.PRODUCT_SAMPLE && (
                <React.Fragment>
                  <span className="mt-5">{getApproxDistributionTime(service)}</span>
                  <QServicesItemView
                    servicesName={t(`logistic_price`)}
                    servicesPriseCalc={renderLogisticPrice(
                      logisticSamplePrice,
                      logisticSampleRate,
                      service.pricing.quantity || 0
                    )}
                    totalValue={`${renderRoundedNumber(logisticSamplePrice, currency)}`}
                  />

                  {!!stickerCost && (
                    <QServicesItemView
                      servicesName={t(`sticker_cost`)}
                      servicesPriseCalc={`${renderRoundedNumber(stickerCost, currency)} x ${
                        service.pricing.quantity || 0
                      }`}
                      totalValue={`${renderRoundedNumber(stickerPrice, currency)}`}
                    />
                  )}
                </React.Fragment>
              )}
              {service.product === DISTRIBUTION_TYPE.PRINTED_INSERT && (
                <>
                  <span className="mt-5">{getApproxDistributionTime(service)}</span>
                  <QServicesItemView
                    servicesName={t(`logistic_price`)}
                    servicesPriseCalc={renderLogisticPrice(
                      logisticInsertPrice,
                      logisticInsertRate,
                      service.pricing.quantity || 0
                    )}
                    totalValue={`${renderRoundedNumber(logisticInsertPrice, currency)}`}
                  />
                </>
              )}
            </div>
          ) : (
            <React.Fragment key={service.key}>
              <QServicesItemView
                servicesName={
                  service.productSubType
                    ? service.product + ': ' + DynamicPricingTypeLabels[service.productSubType]
                    : service.product
                }
                servicesPriseCalc={
                  targetServices.includes(service.key) && details.mbPricingModel === PRICING_MODEL.METRICS
                    ? ``
                    : `${renderRoundedNumber(service.pricing.amount, service.pricing.currency)} x ${
                        service.pricing.quantity
                      }`
                }
                totalValue={
                  targetServices.includes(service.key)
                    ? `${renderRoundedNumber(service.pricing.total, service.pricing.currency)}`
                    : `${renderRoundedNumber(service.pricing.total, service.pricing.currency)}`
                }
              />
              {service.product === DISTRIBUTION_TYPE.PRODUCT_SAMPLE && (
                <React.Fragment>
                  <QServicesItemView
                    servicesName={t(`logistic_price`)}
                    servicesPriseCalc={renderLogisticPrice(
                      logisticSamplePrice,
                      logisticSampleRate,
                      service.pricing.quantity || 0
                    )}
                    totalValue={`${renderRoundedNumber(logisticSamplePrice, currency)}`}
                  />
                  {!!stickerCost && (
                    <QServicesItemView
                      servicesName={t(`sticker_cost`)}
                      servicesPriseCalc={`${renderRoundedNumber(stickerCost, currency)} x ${
                        service.pricing.quantity || 0
                      }`}
                      totalValue={`${renderRoundedNumber(stickerPrice, currency)}`}
                    />
                  )}
                </React.Fragment>
              )}
              {service.product === DISTRIBUTION_TYPE.PRINTED_INSERT && (
                <QServicesItemView
                  servicesName={t(`logistic_price`)}
                  servicesPriseCalc={renderLogisticPrice(
                    logisticInsertPrice,
                    logisticInsertRate,
                    service.pricing.quantity || 0
                  )}
                  totalValue={`${renderRoundedNumber(logisticInsertPrice, currency)}`}
                />
              )}
            </React.Fragment>
          )
        )}

        {hasServices && (
          <div className="services-form-subtotal">
            <div className="subtotal-label">{t(`sub_total`)}</div>
            <div className="subtotal-value">
              <p>{renderSubTotal()}</p>
              {/* <span>{t(`plus_taxes`)}</span> */}
            </div>
          </div>
        )}

        <div className="services-form-coupon">{hasServices && !isRejectionFlow && <CouponForm />}</div>

        <div className="services-form-controls">
          {!editMode && (
            <div onClick={handleEditMode} className="services-edit">
              {t(`edit_products`)}
            </div>
          )}

          {/* <QButton
            onClick={() => handleSubmitForm(false, PARTNER_STEP_BUTTONS.BACK)}
            className="qu-button-soft full-width"
            size="large"
          >
            Back to Market
          </QButton> */}
          {shouldShow && <div onClick={handleGoToTop} className="go-to-top text-center p-10 cursorPointer">{t(`go_to_top`)}</div>} 
          <QButton
            disabled={disabledButton}
            onClick={() => handleGoToTop() && handleSubmitForm(false, t(PARTNER_STEP_BUTTONS.NEXT))}
            className="full-width"
            type="primary"
            size="large"
          >
            {t(`next_step`)}
          </QButton>
          <div className="services-help">{t(`services_help`)}</div>
        </div>
        {/* {editMode && (
          <QButton
            disabled={disabledButton}
            onClick={() => handleSubmitForm(false, PARTNER_STEP_BUTTONS.ADD_TO_CART)}
            className="full-width mb-15"
            type="primary"
            size="large"
          >
            Add To Cart
            {hasServices && <span className="button__counter">{selectedServices.length}</span>}
          </QButton>
        )} */}
        {brief.BRIEF_TYPE.briefTypes.includes(BRIEF_TYPES.PRODUCT_SAMPLE) && (
          <div className="services-form-samples">
            <p>
              {totalQty} of {sampleAllocated}
            </p>
            <span>samples allocated</span>
          </div>
        )}
      </Form>
    </div>
  )
}
