import React, { useEffect, useState } from "react";
import { QButton, Row, Col } from "quantum_components";
import { useTranslation } from "react-i18next";
import { AppstoreAddOutlined } from "@ant-design/icons"
import { useHistory } from "react-router-dom";


const ServiceV2Header = () => {
    const {t} = useTranslation(["translationServiceV2"]);
    const history = useHistory();


    const renderHeader = () => {
        return (
          <Row justify="space-between" className="mt-20 mb-20">
            <Col>
              <h2>{t("Services")}</h2>
              <p className="m-0">
                {t("Tell us about all the awesome services you offer and how advertisers can connect with you! 🙌")}
              </p>
            </Col>
            {/*<Col className="mt-20">
                            <QButton type="primary" htmlType="button" size="small" onClick={()=>history.push(`/services/custom`)}>
                              {t("Custom Services")}
                            </QButton>
                        </Col>*/}
          </Row>
        );
      };

      return <>
      {
        renderHeader()
      }
      </>
}

export default ServiceV2Header;