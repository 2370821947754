import React from 'react'
import { Row, Col } from 'quantum_components'
import moment from 'moment'
import { IBriefResponse } from '../../brief.types'
import { MediaTypeIcon } from './Brief.MediaTypeCard'
import { getLocaleCost } from 'src/helpers/currency'
import { useTranslation } from 'react-i18next'

interface Props {
  briefView: IBriefResponse
  onEdit: () => void
  viewMode?: boolean
}

const BriefMedia = ({ briefView, onEdit, viewMode }: Props) => {
  const {t} = useTranslation(['translationABrief'])
  
  return <div className="qu-view-card m-10">
    <div className="qu-card-header">
      <h4>{t('media_buying')}</h4>
      {!viewMode && <span onClick={onEdit}>{t('edit')}</span>}
    </div>
    <div className="media-buying mb-15">
      <div className="qu-card-media">
        <Row>
          <Col span={12}>
            <div className="data-view mb-15">
              <div className="data-view-label">{t('desired_start_date')}</div>
              <div className="data-view-value">
                {briefView.mediaStartDate ? moment(briefView.mediaStartDate)?.format('LL') : t('none')}
              </div>
            </div>
          </Col>
          <Col span={12}>
            <div className="data-view mb-15">
              <div className="data-view-label">{t('marketing_objective')}</div>
              <div className="data-view-value">{briefView.marketingObjective || t('none')}</div>
            </div>
          </Col>
          <Col span={12}>
            <div className="data-view mb-15">
              <div className="data-view-label">{t('duration')}</div>
              <div className="data-view-value">{briefView.mediaDuration || t('none')}</div>
            </div>
          </Col>
          <Col span={12}>
            <div className="data-view mb-15">
              <div className="data-view-label">{t('budget')}</div>
              <div className="data-view-value">
                {briefView.budgetValue ? (
                  <>
                    {getLocaleCost(briefView.budgetValue)} {briefView.budgetCurrency} ({briefView.budgetType})
                  </>
                ) : (
                  <>{t('none')}</>
                )}
              </div>
            </div>
          </Col>
          <Col>
            <div className="BSUnit__bList brief-view-mobile-media">
              {briefView.mediaTypes?.map((media: string) => (
                <MediaTypeIcon key={media} name={media} />
              ))}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  </div>
}
export default BriefMedia
