const MODULE_NAME = 'admin'

export const getSAASPartnerDetails = (): string => `p/saaspartner`

export const getCurrentUserUrl = (): string => `${MODULE_NAME}/common/current`

export const getUpdatePasswordUrl = (): string => `${MODULE_NAME}/common/change-password`

export const getUserCurrency = (): string => 'user/currency'

export const updateUserCurrency = (): string => 'user/change-currency'

export const getSettingsUrl = (): string => 'core/get-settings'

export const getConstantsUrl = (): string => 'core/get-constants'

export const getDashboardChartDataUrl = (): string => `${MODULE_NAME}/common/dashboard`

export const getCoreImage = (link: string): string => `core/get-image?link=${link}`

export const getMarketplacePartnersUrl = (): string => `${MODULE_NAME}/marketplace`

export const getCoreMediaUploadUrl = (): string => `core/media/upload`
