export enum NOTIFICATION_STATUSES {
  READ = 'READ',
  UNREAD = 'UNREAD',
}

export enum NOTIFICATION_GROUPS {
  NEW_BRIEF_CREATED = 'NEW_BRIEF_CREATED',
  NEW_BRIEF_UPDATED = 'NEW_BRIEF_UPDATED',
  NEW_CAMPAIGN_CREATED = 'NEW_CAMPAIGN_CREATED',
  CAMPAIGN_UPDATED = 'CAMPAIGN_UPDATED',
  NEW_REQUEST_RECEIVED = 'NEW_REQUEST_RECEIVED',
}

export const CUSTOM_NOTIFICATIONS_TYPES = {
  PUSH_NOTIFICATIONS: 'PUSH_NOTIFICATIONS',
  STANDARD_NOTIFICATIONS: 'STANDARD_NOTIFICATIONS',
  POPUP_NOTIFICATIONS: 'POPUP_NOTIFICATIONS',
};