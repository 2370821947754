import React, { ReactElement } from 'react'
// import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { FORMATS } from '../../../../constants'
import { renderRoundedNumber } from '../../../../helpers/currency'
import { IBriefResponse, ICartList } from '../../brief.types'

import Logo from 'src/assets/logo_email@2x.png'
import './styles.scss'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getCurrentUser } from 'src/modules/core/core.selectors'
import { CURRENCY } from 'src/constants/currencies'
import lodash from "lodash"

interface IProps {
  briefResponse:any,
  enableTax?: boolean
}

export default function BriefQuotationV3({ briefResponse, enableTax }: IProps): ReactElement {
  const { t } = useTranslation(['translationABrief', 'translationDashboard', 'translation', 'translationV2']);
  const currentUser = useSelector(getCurrentUser);

  const serviceTotal = briefResponse?.rawResponse?.cart?.additional?.services?.reduce(
    (acc: any, cur: any) => (acc += cur.total),
    0
  );
  const managementFee = 0;
  const discount = 0;
  const subTotal = serviceTotal + managementFee - discount;
  let totalTax = 0;
  if(enableTax === true){
    totalTax = subTotal * 1.15 - subTotal
  }
  const cartTotal = subTotal + totalTax;

  return (
    <div className="payment-sheet-container flex-column hide-proposal" id={enableTax?"new_brief_print_area_with_tax":"new_brief_print_area"}>
      <div className="sub-container sub-container-top flex-row flex-sb">
        <div className="flex-column">
          <div><img id='quotation-logo' src={currentUser?.partnerData?.logo} className="payment-document-logo" style={{height:'70px'}}/></div>
          <strong>{currentUser?.partnerData?.name}</strong>
          <span>{t("brief.payment.Address", { ns: 'translation' })}: {currentUser?.partnerData?.bankDetails?.address || '-'}</span>
          <span>{t(`vat`)} {currentUser?.partnerData?.vat || '-'}</span>

          <div style={{ marginTop: '40px' }} className="flex-column">
            <span>{t(`bill_to`)}</span>
            <strong>{briefResponse?.rawResponse?.owner?.companyName}</strong>
            <span>{t("brief.payment.Address", { ns: 'translation' })}: {briefResponse?.rawResponse?.owner?.clientProfile?.nationalAddress || '-'}</span>
            <span>{t("VAT")}: {briefResponse?.rawResponse?.owner?.clientProfile?.vat || '-'}</span>
          </div>
        </div>
        <div className="heading">
          <h1>{t(`quotation`)}</h1>
          <strong style={{ color: '#5b6475', float: 'right' }}>{t(`qtn`)} {briefResponse?.rawResponse?.cart?.briefId}</strong>
        </div>
      </div>
      <div className="sub-container-mid flex-column">
        <div className="section" style={{ paddingTop: 0, marginTop: 0 }}>
          <table className="fees-breakdown">
            <tr>
              <th>{t(`services`)}</th>
              <th>{t(`rate`)}</th>
              <th>{t(`quantity`)}</th>
              <th>{t(`total`)}</th>
            </tr>
            {briefResponse?.rawResponse?.cart?.additional?.services?.map((service:any) => {
              return (
                <>
                    <tr>
                          <td>{t('dashboard.service-types-labels.' + service.name, service.name, { ns: 'translationDashboard' })}<br/>
                          {service?.durationType === "Per Metric" && lodash.has(service,'pricePerMetric') && <small>@ {renderRoundedNumber(service?.pricePerMetric[String(service?.metricData?.metricType).toLowerCase()], CURRENCY.SAR)} [{t(service?.metricData?.metricType,{ns:'translationV2'})}]</small>}
                          {service?.durationType !== "Per Metric" && <small>@ {renderRoundedNumber(service?.basePrice, CURRENCY.SAR)} {t(service?.durationType, { ns: 'translationV2' })}</small>}
                          </td>
                          {service?.durationType === "Per Metric" && lodash.has(service,'pricePerMetric') &&  <td>{renderRoundedNumber(service?.metricData?.campaignBudget, CURRENCY.SAR)}</td>}
                          {service?.durationType !== "Per Metric" && <td>{renderRoundedNumber(service?.basePrice, CURRENCY.SAR)}</td>}
                          <td>{service?.quantity}</td>
                          <td>{renderRoundedNumber(service?.total, CURRENCY.SAR)}</td>
                        </tr>
                </>
              )
            })}

            <tr>
              <td></td>
              <td className="payment-label" colSpan={2}>
                {t(`services_total`)}
              </td>
              {/* <td>{renderRoundedNumber(briefResponse?.rawResponse?.cart?.additional?.servicesTotal, CURRENCY.SAR)}</td> */}
              <td>{renderRoundedNumber(serviceTotal, CURRENCY.SAR)}</td>
            </tr>
            <tr>
              <td></td>
              <td className="payment-label" colSpan={2}>
                {t("Management Fee",{ns:"translationV2"})} ({briefResponse?.rawResponse?.cart?.additional?.managementFee}%)
              </td>
              <td>{renderRoundedNumber(briefResponse?.rawResponse?.cart?.additional?.managementFee, CURRENCY.SAR)}</td>
            </tr>
            <tr>
              <td></td>
              <td className="payment-label" colSpan={2}>
                {t(`discount`)}
              </td>
              <td>{renderRoundedNumber(briefResponse?.rawResponse?.cart?.additional?.discount, CURRENCY.SAR)}</td>
            </tr>
            <tr>
              <td></td>
              <td className="payment-label" colSpan={2}>
                {t(`sub_total`)}
              </td>
              {/* <td>{renderRoundedNumber(briefResponse?.rawResponse?.cart?.additional?.subTotal, CURRENCY.SAR)}</td> */}
              <td>{renderRoundedNumber(subTotal, CURRENCY.SAR)}</td>
            </tr>
            <tr>
              <td></td>
              <td className="payment-label" colSpan={2}>
                {t(`taxes`)}
              </td>
              {/* <td>{renderRoundedNumber(briefResponse?.rawResponse?.cart?.additional?.totalTax, CURRENCY.SAR)}</td> */}
              <td>{renderRoundedNumber(totalTax, CURRENCY.SAR)}</td>
            </tr>
            <tr>
              <td></td>
              <td className="payment-label" colSpan={2}>
                <strong>{t(`total`)}</strong>
              </td>
              <td>
                {/* {renderRoundedNumber(briefResponse?.rawResponse?.cart?.additional?.cartTotal, CURRENCY.SAR)} */}
                <td>{renderRoundedNumber(cartTotal, CURRENCY.SAR)}</td>
                <br />
                <small>{t(`plus_taxes`)}</small>
              </td>
            </tr>
          </table>
          <div className="flex-column pt-50 pl-10">
            <span style={{ fontSize: '18px' }}>{t("brief.payment.Notes", { ns: 'translation' })}</span><br />
            <span>{t("brief.payment.Payable_to", { ns: 'translation' })}:</span>
            <span>{t("brief.payment.Bank_Name", { ns: 'translation' })}: {currentUser?.partnerData?.bankDetails?.bank_name|| '-'}</span>
            <span>{t("brief.payment.Address", { ns: 'translation' })}: {currentUser?.partnerData?.bankDetails?.bank_address || '-'}</span>
            <span>{t("brief.payment.Account_No", { ns: 'translation' })}: {currentUser?.partnerData?.bankDetails?.account_number || '-'}</span>
            <span>{t("brief.payment.IBAN_No", { ns: 'translation' })}: {currentUser?.partnerData?.bankDetails?.iban_number || '-'}</span>
            <span>{t("brief.payment.Swift_Code", { ns: 'translation' })}: {currentUser?.partnerData?.bankDetails?.swift_code || '-'}</span>
            <span>{t("brief.payment.Currency", { ns: 'translation' })}: {CURRENCY.SAR || '-'}</span>
          </div>
        </div>
      </div>
      <hr />
    </div>
  )
}
