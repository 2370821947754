import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useReduxDispatch } from '../../../helpers'
import {
  getIsSubscriptionHistoryListLoading,
  getSubscriptionHistoryList,
  getSubscriptionHistoryTotal,
  getPages,
  getLatestSubscription,
} from '../subscriptionHistory.selectors'
import * as actions from '../subscriptionHistory.actions'
import * as coreActions from 'src/modules/core/core.actions'

import {
  Modal,
  QButton,
  QTable,
  QPagination,
  Row,
  Col,
  QAlert,
} from 'quantum_components'
import { getCurrency, getCurrentUserPermissionActions } from '../../core/core.selectors'
import { hasPermission } from '../../../helpers/permission'
import { PERMISSIONS } from '../../../constants/permission'
import { getReadableDate, getReadableDateWithTime } from 'src/helpers/datesHelper'
import { ISubscriptionHistory } from '../subscriptionHistory.types'
import { isMobile } from 'react-device-detect'
import { getFileNameFromUrl } from 'src/utils'
import UploadComponent from 'src/components/UploadComponent'
import { SUBSCRIPTION_HISTORY_STATUS, SUBSCRIPTION_STATUS } from 'src/constants/subscriptionHistory'
import SpinnerCenter from 'src/components/Spinner/spinner'
import i18n from 'src/i18n'
import { LOCALIZATION_LANGUAGES } from 'src/constants'
import { renderRoundedNumber } from 'src/helpers/currency'

const SubscriptionHistoryList = () => {
  const { t } = useTranslation(['translationSubscriptionHistory', 'translationCommon'])

  const dispatch = useReduxDispatch()
  const currency = useSelector(getCurrency)
  const latestSubscription = useSelector(getLatestSubscription)
  const subscriptionHistoryList = useSelector(getSubscriptionHistoryList)
  const subscriptionHistoryTotal = useSelector(getSubscriptionHistoryTotal)
  const subscriptionHistoryListIsLoading = useSelector(getIsSubscriptionHistoryListLoading)
  const pages = useSelector(getPages)
  const permissions = useSelector(getCurrentUserPermissionActions)

  const [cancelSubscriptionModal, setCancelSubscriptionModal] = useState<boolean>(false)
  const [payConfirmation, setPayConfirmation] = useState<{ visible: boolean, data: ISubscriptionHistory | null }>({
    visible: false,
    data: null
  })
  const [params, setParams] = useState({
    currentPage: 1,
    limit: 10,
    offset: 0,
  })

  const fetchSubscriptionHistory = (queryParams = params) => {
    dispatch(
      actions.fetchSubscriptionHistoryList({
        ...queryParams,
      })
    )
  }

  const fetchSubscriptionHistoryForFilters = (queryParams = params) => {
    dispatch(
      actions.fetchSubscriptionHistoryList({
        ...queryParams,
        currentPage: 1,
        limit: 10,
        offset: 0,
      })
    )
  }

  useEffect(() => {
    fetchSubscriptionHistoryForFilters()
    dispatch(actions.setCurrentPage(1))

    return () => { dispatch(actions.resetSubscriptionHistoryState()) }
  }, [])

  // @ts-ignore
  const handleTableChange = (pagination, filters, sorter) => {
    const newParams = {
      ...params,
    }
    setParams(newParams)
    fetchSubscriptionHistory(newParams)
  }

  const handleChangePage = (page: number, pageSize: number) => {
    dispatch(actions.setCurrentPage(page))

    dispatch(
      actions.fetchSubscriptionHistoryList({
        ...params,
        offset: (page - 1) * params.limit,
        currentPage: page,
        limit: pageSize,
      })
    )

    setParams({
      ...params,
      offset: (page - 1) * params.limit,
      currentPage: page,
      limit: pageSize,
    })
  }

  // cancel the subscription
  const handleCancelSubscription = async () => {
    if (latestSubscription) {
      await dispatch(actions.getCancelSubscription({
        id: latestSubscription?.id,
        action: "CANCEL"
      }))
      setCancelSubscriptionModal(false)
      fetchSubscriptionHistory()
    }
  }

  const columns = [
    {
      title: t('Package Name'),
      dataIndex: 'packageName',
      key: 'packageName',
      width: '20%',
      render: (packageName: string, data: ISubscriptionHistory) => {
        return (i18n.language === LOCALIZATION_LANGUAGES.ARABIC ? data?.subscription?.package?.name_ar : data?.subscription?.package?.name) + ' ' + (t(data?.subscription?.package?.type) ?? '');
      },
    },
    {
      title: t('Amount'),
      dataIndex: 'amountPaid',
      key: 'amountPaid',
      width: '15%',
      render: (amountPaid: string) => {
        return renderRoundedNumber(Number(amountPaid) / 100, currency);
      },
    },
    {
      title: t('Due Date'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '20%',
      render: (date: string) => {
        return getReadableDate(date);
      },
    },
    {
      title: t('Payment Date'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: '20%',
      render: (date: string, data: ISubscriptionHistory) => {
        return data.status === SUBSCRIPTION_HISTORY_STATUS.PAID ? getReadableDateWithTime(date) : '-';
      },
    },
    {
      title: t('Invoice'),
      dataIndex: 'invoice',
      key: 'invoice',
      width: '10%',
      render: (invoice: string) => {
        return (
          <div onClick={(event: React.MouseEvent<HTMLDivElement>) => event.stopPropagation()}>
            {invoice && <UploadComponent
              viewOnly
              viewOnlyText={t('common.View', { ns: 'translationCommon' })}
              alternativeFileView
              dragAndDrop={false}
              files={[{ url: invoice, name: getFileNameFromUrl(invoice) }]}
              onView={async file => {
                const response: any = await dispatch(coreActions.downloadImage(file.url))
                const url = URL.createObjectURL(response.data)

                window.open(url, '_blank')
                URL.revokeObjectURL(url)
              }}
            />}
            {!invoice && <span>─</span>}
          </div>
        )
      },
    },
    {
      title: t('Status'),
      dataIndex: 'status',
      key: 'status',
      width: '15%',
      render: (status: SUBSCRIPTION_HISTORY_STATUS, data: ISubscriptionHistory) => {
        return <div>
          {t(status)}
          {
            status === SUBSCRIPTION_HISTORY_STATUS.OVERDUE &&
            <>
              <br />
              <QButton
                onClick={() => setPayConfirmation({ visible: true, data })}
                className="w-80"
                size="small"
                type="primary"
                disabled={!hasPermission(permissions, PERMISSIONS.PAY_SUBSCRIPTION)}
              >
                {t("PAY")}
              </QButton>
            </>
          }
        </div>
      }
    },
  ]

  // open the cancel subscription modal
  const handleCancelSubscriptionModal = () => {
    setCancelSubscriptionModal(true)
  }

  // pay for subscription
  const paySubscriptionPayment = async (id: number) => {
    await dispatch(coreActions.paySubscriptionPayment(id, false))
    setPayConfirmation({ visible: false, data: null })
    fetchSubscriptionHistory()
  }

  const handleOk = () => {
    if (payConfirmation.data?.subscription.id) paySubscriptionPayment(payConfirmation.data?.subscription.id)
  }

  return (
    <>
      {subscriptionHistoryListIsLoading && <SpinnerCenter />}
      <div className="flexRowContainer">
        <h2 className="pt-10 pb-10">{t('Subscription History')}</h2>
      </div>

      {latestSubscription && latestSubscription.status !== SUBSCRIPTION_STATUS.ONGOING &&
        <Row>
          <Col>
            <QAlert
              message={
                <div>
                  {t("We're Sorry To See You Go")}
                  <br />
                  {t("Your subscription has been cancelled but you can continue to bring in revenue for your Ad Spaces until", { subscriptionEndDate: getReadableDate(latestSubscription.nextBillDate) })}
                </div>
              }
              type="info"
              className="pb-15"
            />
          </Col>
        </Row>
      }

      <Row gutter={32} justify="start" align="bottom">
        <Col xs={24} sm={13} md={18}>
          {latestSubscription && <Row>
            <Col span={24} className="PPField__description-title">{t('Current Subscription')}: {latestSubscription ? latestSubscription.package.name + ' ' + (latestSubscription.package.type ?? '') : '-'}</Col>
            <Col span={24} className="PPField__description-title">
              {
                latestSubscription.status === SUBSCRIPTION_STATUS.ONGOING
                  ? <span>{t('Next Billing Date')}: {getReadableDate(latestSubscription.nextBillDate)}</span>
                  : <span>{t('Subscription Active Until')}: {getReadableDate(latestSubscription.nextBillDate)}</span>
              }
            </Col>
          </Row>}
        </Col>
        <Col xs={24} sm={11} md={6} className={`${i18n.language === LOCALIZATION_LANGUAGES.ARABIC ? 'text-left cancel-subscription-button-ar' : 'text-right cancel-subscription-button-en'}`}>
          <QButton
            type="primary"
            disabled={!hasPermission(permissions, PERMISSIONS.CANCEL_SUBSCRIPTION) || !latestSubscription || latestSubscription.status !== SUBSCRIPTION_STATUS.ONGOING}
            onClick={() => handleCancelSubscriptionModal()}
          >
            {t('Cancel Subscription')}
          </QButton>
        </Col>
      </Row>

      <div className="pt-15 pb-30">
        <QTable
          columns={columns}
          dataSource={subscriptionHistoryList}
          scroll={{ x: 990 }}
          // @ts-ignore
          onChange={handleTableChange}
        />
      </div>
      <Row
        // @ts-ignore
        justify={`${isMobile ? "center" : "end"}`}
      >
        <QPagination
          className="force-ltr mb-30"
          size="default"
          defaultCurrent={1}
          current={pages.currentPage}
          total={subscriptionHistoryTotal}
          onChange={handleChangePage}
          showSizeChanger
        />
      </Row>
      <Modal
        className={`${i18n.language === LOCALIZATION_LANGUAGES.ARABIC && 'cancel-subscription-modal'}`}
        visible={cancelSubscriptionModal}
        onOk={handleCancelSubscription}
        onCancel={() => setCancelSubscriptionModal(false)}
        okText={t('common.OKAY', { ns: 'translationCommon' })}
        cancelText={t('common.Cancel', { ns: 'translationCommon' })}
      >
        <p>
          {t('Are you sure, you want to cancel your subscription?')}
        </p>
      </Modal>

      <Modal
        className={`${i18n.language === LOCALIZATION_LANGUAGES.ARABIC && 'cancel-subscription-modal'}`}
        title={<h3>{t("Payment Confirmation")}</h3>}
        visible={payConfirmation.visible}
        onOk={handleOk}
        onCancel={() => { setPayConfirmation({ visible: false, data: null }) }}
        footer={false}
      >
        <p className="mb-25">
          {t("You will be charged Continue?", { amount: renderRoundedNumber(Number(payConfirmation.data?.amountPaid) / 100, currency) })}
        </p>
        <div className="grid-row grid-row--smg grid-row--jce">
          <div className="grid-col grid-col--auto">
            <QButton onClick={handleOk} type="primary">
              {t("common.Confirm", { ns: "translationCommon" })}
            </QButton>
          </div>
          <div className="grid-col grid-col--auto">
            <QButton
              className="qu-button-outline"
              onClick={() => { setPayConfirmation({ visible: false, data: null }); }}
            >
              {t("common.Cancel", { ns: "translationCommon" })}
            </QButton>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default SubscriptionHistoryList
