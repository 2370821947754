/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { QButton, QInput, QPagination, QTable, Row } from "quantum_components";
import { Col, Modal, Tooltip } from "antd";
import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  SearchOutlined,
} from "@ant-design/icons";
import { useReduxDispatch } from "../../../helpers";
import * as actions from "../requests.actions";
import { ROLES } from "src/constants";
import { useSelector } from "react-redux";
import {
  getRequestsList,
  getRequestsListLoading,
  getTotalRequestsList,
} from "../requests.selectors";
import { IRequestsListElement } from "../requests.types";
import useDebounce from "../../../hooks/useDebounce";
import { getCurrentUserPermissionActions } from "../../core/core.selectors";
import { hasPermission } from "../../../helpers/permission";
import { PERMISSIONS } from "../../../constants/permission";
import { useTranslation } from "react-i18next";

const APPROVE_ACTION = "APPROVE";
const DECLINE_ACTION = "DECLINE";

const RequestsList = () => {
  const { t } = useTranslation(["translationClient", "translationPlatform"]);
  const dispatch = useReduxDispatch();
  const history = useHistory();

  const requestsList = useSelector(getRequestsList);
  const requestsListTotal = useSelector(getTotalRequestsList);
  const requestsListIsLoading = useSelector(getRequestsListLoading);
  const permissions = useSelector(getCurrentUserPermissionActions);

  const requestUserRole = history.location.pathname.includes("partners")
    ? ROLES.PARTNER
    : ROLES.CLIENT;

  const [params, setParams] = useState({
    currentPage: 1,
    offset: 0,
    limit: 10,
    order: "desc",
    orderBy: "createdAt",
    role: requestUserRole,
    isApproved: false,
  });

  const [modalApprove, setModalApprove] = useState({
    visible: false,
    id: null,
  });
  const [modalDecline, setModalDecline] = useState({
    visible: false,
    id: null,
  });

  const [tableKey, setTableKey] = useState(1);
  const [tableKey1, setTableKey1] = useState(100);
  const [search, setSearch] = useState("");

  const debouncedSearch = useDebounce(search, 500);

  const fetchRequestsList = (queryParams = params) => {
    dispatch(
      actions.fetchRequestsList({ ...queryParams, search: debouncedSearch })
    );
  };

  useEffect(() => {
    fetchRequestsList();
  }, [debouncedSearch]);

  const handleOpenModal = (record: IRequestsListElement, action: string) => {
    const modalToOpenSetter =
      action === APPROVE_ACTION ? setModalApprove : setModalDecline;
    modalToOpenSetter({ visible: true, id: record.id });
  };

  const handleCloseModals = () => {
    setModalApprove({ visible: false, id: null });
    setModalDecline({ visible: false, id: null });
    setTableKey(tableKey + 1);
    setTableKey1(tableKey1 + 1);
  };

  const handleSubmitModal = async (action: string, id: number) => {
    const actionName =
      action === APPROVE_ACTION
        ? actions.approveRequest
        : actions.declineRequest;

    handleCloseModals();
    await dispatch(actionName(id));
    if (action === APPROVE_ACTION) {
      fetchRequestsList();
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const sortingParams = {
      order: sorter.order.slice(0, -3),
      orderBy: sorter.columnKey,
    };
    const newParams = {
      ...params,
      ...sortingParams,
    };
    setParams(newParams);
    fetchRequestsList(newParams);
  };

  const handleChangeSearch = (e) => {
    setSearch(e.currentTarget.value);
  };

  const handleChangePage = (page: number, pageSize: number) => {
    dispatch(
      actions.fetchRequestsList({
        ...params,
        offset: (page - 1) * params.limit,
        currentPage: page,
        limit: pageSize,
      })
    );
  };

  const columns = [
    {
      title: t(`clients.full_name`),
      dataIndex: "fullname",
      key: "firstName",
      sorter: true,
    },
    {
      title: t(`clients.email`),
      dataIndex: "email",
      key: "email",
      sorter: true,
    },
    {
      title: t(`clients.username`),
      dataIndex: "username",
      key: "username",
    },
    {
      title: t(`clients.company_name`),
      dataIndex: "companyName",
      key: "companyName",
      sorter: true,
    },
    {
      title: t(`clients.phone_number`),
      dataIndex: "phone",
      key: "phone",
      sorter: true,
    },
    {
      title: t(`clients.created_at`),
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: true,
    },
    {
      title: t(`clients.email_verified`),
      dataIndex: "status",
      key: "status",
      sorter: true,
      render: (text, record) => (
        <div>
          {record.status === "ACTIVE"
            ? t(`clients.verified`)
            : t(`clients.not_verified`)}
        </div>
      ),
    },
    {
      title: t(`clients.actions`),
      dataIndex: "actions",
      key: "services",
      render: (text, record) => {
        { return record.status === "INACTIVE" && hasPermission(permissions, PERMISSIONS.SIGN_UP_CLIENT_ACCEPT) && (
            <Tooltip title={t(`clients.approve_request`)}>
              <CheckCircleTwoTone
                style={{
                  fontSize: "20px",
                  marginRight: "5px",
                  marginLeft: "5px",
                }}
                onClick={() => handleOpenModal(record, APPROVE_ACTION)}
              />
            </Tooltip>
          );
        }
      },
    },
    //SAAS814_automate_admin_approval
    // {
    //   title: t(`clients.actions`),
    //   dataIndex: 'actions',
    //   key: 'services',
    //   render: (text, record) => {
    //     const actionApprove =
    //       requestUserRole === ROLES.PARTNER ? PERMISSIONS.SIGN_UP_PARTNER_ACCEPT : PERMISSIONS.SIGN_UP_CLIENT_ACCEPT
    //     const actionDecline =
    //       requestUserRole === ROLES.PARTNER ? PERMISSIONS.SIGN_UP_PARTNER_REJECT : PERMISSIONS.SIGN_UP_CLIENT_REJECT

    //     return (
    //       <div style={{textAlign:'center'}}>
    //         {record.status !== 'REJECTED' ? (
    //           <div>
    //             {hasPermission(permissions, actionApprove) && (
    //               <Tooltip title={t(`clients.approve_request`)}>
    //                 <CheckCircleTwoTone
    //                   style={{ fontSize: '20px', marginRight: '5px', marginLeft: '5px' }}
    //                   onClick={() => handleOpenModal(record, APPROVE_ACTION)}
    //                 />
    //               </Tooltip>
    //             )}
    //             {hasPermission(permissions, actionDecline) && (
    //               <Tooltip title={t(`clients.decline_request`)}>
    //                 <CloseCircleTwoTone
    //                   style={{ fontSize: '20px', marginRight:'5px', marginLeft:'5px' }}
    //                   onClick={() => handleOpenModal(record, DECLINE_ACTION)}
    //                 />
    //               </Tooltip>
    //             )}
    //           </div>
    //         ) : (
    //           <span> {t(`clients.declined`)} </span>
    //         )}
    //       </div>
    //     )
    //   },
    // },
  ];

  return (
    <div className="qu-brief-list">
      <Row className={`pt-10 brief-list-header`} justify="space-between">
        <h2 className="pb-10">
          {t(`menu.pending_clients`, { ns: "translationPlatform" })}
        </h2>
      </Row>
      <Row justify="space-between">
        <Col className="qt-search">
          <QInput
            value={search}
            onChange={handleChangeSearch}
            placeholder={t(`clients.search`)}
            size="large"
            prefix={<SearchOutlined />}
          />
        </Col>
        <Col>
          <h5 className="pb-10">
            {t(`clients.total_available_requests`, {
              total: requestsListTotal,
            })}
          </h5>
        </Col>
      </Row>
      <div className="pt-15 pb-30">
        <QTable
          columns={columns}
          dataSource={requestsList}
          scroll={{ x: 990 }}
          rowClassName="q-table"
          loading={requestsListIsLoading}
          onChange={handleTableChange}
          sortDirections={["ascend", "descend", "ascend"]}
        />
      </div>

      <Modal
        key={tableKey}
        title={t(`clients.are_you_sure`)}
        visible={modalApprove.visible}
        onOk={() => handleSubmitModal(APPROVE_ACTION, modalApprove.id)}
        onCancel={() => {
          setModalApprove({ visible: false, id: null });
          setTableKey(tableKey + 1);
        }}
      >
        <p>{t(`clients.approve_confirm`)}</p>
      </Modal>

      <Modal
        key={tableKey1}
        title={t(`clients.are_you_sure`)}
        visible={modalDecline.visible}
        onOk={() => handleSubmitModal(DECLINE_ACTION, modalDecline.id)}
        onCancel={() => {
          setModalDecline({ visible: false, id: null });
          setTableKey1(tableKey1 + 1);
        }}
      >
        <p>{t(`clients.decline_confirm`)}</p>
      </Modal>
      <Row justify="end">
        <QPagination
          size="small"
          className="force-ltr"
          total={requestsListTotal}
          onChange={handleChangePage}
          hideOnSinglePage
        />
      </Row>
    </div>
  );
};

export default RequestsList;
