import { IState } from './create-client.types'
import * as CONSTANTS from './create-client.constants'

const initialState: IState = {
  isLoading:false,
  error:''
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case CONSTANTS.CREATE_CLIENT_REQUEST:
      return {
        ...state,
        isLoading: true,
        error:''
      }
    case CONSTANTS.CREATE_CLIENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: 'no_error'
      }
    case CONSTANTS.CREATE_CLIENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: 'error'
      }
    default:
      return state
  }
}
