import React, { Fragment, ReactElement, useEffect } from 'react'
import { BRIEF_STEPS, BRIEF_TYPES } from '../../../../constants/brief'
import { isMobile } from 'react-device-detect'
import { Col, Row } from 'quantum_components'

import ServiceCard from '../components/Brief.Service'
import BriefProduct from '../components/Brief.Product'
import BriefMedia from '../components/Brief.Media'
import BriefTargeting from '../components/Brief.Targeting'
import BriefAdditional from '../components/Brief.Additional'
import BriefViewSummary from '../components/Brief.View.Summary'
import { useSelector } from 'react-redux'
import { getBriefView } from '../../brief.selectors'
import * as actions from '../../brief.actions'
import { useReduxDispatch } from '../../../../helpers'
import { useTranslation } from 'react-i18next'

interface IProps {
  briefId: number
  setCurrentStep: (step: BRIEF_STEPS) => void
}

export default function SummaryForm({ briefId, setCurrentStep }: IProps): ReactElement {
  const dispatch = useReduxDispatch()
  const briefView = useSelector(getBriefView)
  const {t} = useTranslation(['translationABrief'])

  useEffect(() => {
    dispatch(actions.fetchViewBriefDetails(briefId))
  }, [briefId])

  const isProductSample = briefView.briefTypes?.includes(BRIEF_TYPES.PRODUCT_SAMPLE)
  const isMediaBuying = briefView.briefTypes?.includes(BRIEF_TYPES.MEDIA_BUYING)

  return (
    <div className="qu-brief-content brief-step">
      <div className="brief-step-header">
        <h1>{t(`summary_of_brief`)}</h1>
        <p>{t(`summary_help`)}</p>
      </div>
      <Row gutter={16} align="middle" justify="center">
        <Col xs={24} sm={20}>
          {isMobile ? (
            <Fragment>
              <ServiceCard
                isProductSample={isProductSample}
                isMediaBuying={isMediaBuying}
                onEdit={() => setCurrentStep(BRIEF_STEPS.TYPE)}
              />
              <BriefProduct briefView={briefView} onEdit={() => setCurrentStep(BRIEF_STEPS.PRODUCT)} />
              <BriefTargeting briefView={briefView} onEdit={() => setCurrentStep(BRIEF_STEPS.TARGETING)} />
              <BriefAdditional briefView={briefView} onEdit={() => setCurrentStep(BRIEF_STEPS.SERVICES)} />
              {isMediaBuying && (
                <BriefMedia briefView={briefView} onEdit={() => setCurrentStep(BRIEF_STEPS.MEDIA_BUY)} />
              )}
            </Fragment>
          ) : (
            <BriefViewSummary briefView={briefView} setCurrentStep={setCurrentStep} hideTitle />
          )}
        </Col>
      </Row>
    </div>
  )
}
