import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import timeGridPlugin from "@fullcalendar/timegrid"; // a plugin!

import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { snakeCase, stubTrue } from "lodash";
import { useReduxDispatch } from "../../../helpers";
import * as actions from "../calendar.actions";
import { Row, Col, QButton, QSelect, QBackLink } from "quantum_components";
import {
  getDataLoading,
  getCalendarData,
  getCalendarServicesList,
} from "../calendar.selectors";
import SpinnerComponent from "src/components/Spinner/SpinnerComponent";
import { useHistory } from "react-router-dom";
import { PATHS } from "src/constants";
import { Tooltip } from "antd";
import { CSS_COLOR_NAMES } from "../calendar.types";
import useDebounce from "src/hooks/useDebounce";

// import {
//   ICopackingPrintingPricing,
//   ITargetingToggles,
//   IThemeData,
// } from "../calendar.types";

const Calendar = () => {
  const { i18n } = useTranslation();
  const { t } = useTranslation([
    "translationCalendar",
    "translation",
    "translationCommon",
  ]);
  const history = useHistory();
  const dispatch = useDispatch();
  let assignedColors = [];
  const [monthYear, setMonthYear] = useState("");
  const calendarData = useSelector(getCalendarData);
  const servicesData = useSelector(getCalendarServicesList);
  const isLoading = useSelector(getDataLoading);
  const [calendarDataRefined, setCalendarDataRefined] = useState([]);
  const [calendarDataFiltered, setCalendarDataFiltered] = useState([]);
  const [filter, setFilter] = useState<any>({
    startDate: "",
    endDate: "",
    serviceType: [],
  });
  const [serviceOptions, setServiceOptions] = useState([]);
  const [calendarViewOptions, setCalendarViewOptions] = useState([
    {
      label:t("Month View"),
      value:"dayGridMonth"
    },
    {
      label:t("Week View"),
      value:"dayGridWeek",
    },
  ]);
  
  const [statuses, setStatuses] = useState([
    {
      name: "ALL",
      colour: "#fff",
      textColor: "#333",
      active: true,
    },
    {
      name: "DRAFT",
      colour: "#66AED7",
      textColor: "#fff",
      active: false,
    },
    {
      name: "PENDING",
      colour: "#E5B086",
      textColor: "#fff",
      active: false,
    },
    {
      name: "APPROVED",
      colour: "#a29bfe",
      active: false,
    },
    // {
    //   name: "REJECTED",
    //   colour: "#EF471F",
    //   active: false,
    //   textColor: "#fff",
    // },
    {
      name: "ONGOING",
      colour: "#8DCB9F",
      active: false,
      textColor: "#fff",
    },
    {
      name: "COMPLETED",
      colour: "#A2A4AB",
      active: false,
      textColor: "#fff",
    },
  ]);
  const calendarRef: any = React.useRef();
  const debouncedFilter = useDebounce(filter, 800);

  useEffect(() => {
    if (filter.serviceType.length < 1) {
      if (filter.startDate && filter.endDate) {
        dispatch(actions.fetchCalendarData(filter));
      }
      return;
    }

    handleFilterClick();
    let statusUpdates = statuses.map((status, index) => {
      if (index === 0) {
        status.active = true;
      } else {
        status.active = false;
      }
      return status;
    });

    setStatuses(statusUpdates);
  }, [debouncedFilter]);

  useEffect(() => {
    dispatch(actions.fetchServicesList());
  }, []);

  useEffect(() => {
    setServiceOptions(
      servicesData.map((el: string) => {
        return {
          label: t("services.media-buy.types." + snakeCase(el), el, {
            ns: "translation",
          }),
          value: el,
        };
      })
    );
  }, [servicesData]);

  useEffect(() => {
    if (!calendarData.length) return;

    let refined: any = [];
    calendarData.map((el: any) => {
      el.services.forEach((service: any) => {
        if(service.startDate && service.endDate && service.name){
          let obj = {
            version:el.version,
            briefId: el.id,
            campaignId: el.campaign?.id,
            requestId: el.requests[0]?.id,
            name: el.campaignName,
            status: calculateEventStatus(el),
            serviceName: service.name,
            start: service.startDate.split("T")[0],
            end: service.endDate.split("T")[0],
            serviceDuration: service.duration,
            // title: `(${el.campaignName})/(${t('services.media-buy.types.'+snakeCase(service.name), service.name, {ns: 'translation'})})`,
            // title: `${!el.requests[0]?.id ? "!!" : ""} ${el.campaignName}`,
            title: `${el.campaignName} (${service.name})`,
            color: getEventColor(el),
            textColor: "white",
            editable: false,
            draggable: false,
          };
          refined.push({ ...obj, ...{ extendedProps: obj } });
        }
        
      });
    });

    setCalendarDataRefined(refined);
    setCalendarDataFiltered(refined);
  }, [calendarData]);

  const handleDatesSet = (args: any) => {
    const startDate = args.startStr.split("T")[0];
    const endDate = args.endStr.split("T")[0];

    setMonthYear(getMonthYearFromSelectedDateRange(args.start, args.end));

    // dispatch(actions.fetchCalendarData({ startDate, endDate }));
    setFilter({
      ...filter,
      startDate,
      endDate,
      serviceType: filter.serviceType,
    });
  };

  const handleEventClick = (info: any) => {
    const { briefId, campaignId, requestId, version } = info.event.extendedProps;

    if(version == "2"){
      if(campaignId){
        return history.push(`/campaigns/v3/${campaignId}`);
      }
      return history.push(`/briefs/v3/edit/${briefId}`);
    }

    // no request
    if (!requestId) {
      return history.push(`/briefs/view/${briefId}`);
    }

    // still not a campaign yet
    if (!campaignId) {
      return history.push(`/briefs/${briefId}/requests/${requestId}`);
    }

    return history.push(`/campaigns/${campaignId}`);
  };

  const handleServiceChange = (service: any) => {
    setFilter({
      ...filter,
      startDate: filter.startDate,
      endDate: filter.endDate,
      serviceType: service.map((el: String) => {
        return { name: el };
      }),
    });
  };

  const handleFilterClick = () => {
    dispatch(actions.fetchCalendarData(filter));
  };

  const handleEventMounted = (info: any) => {
    return (
      <Tooltip
        mouseEnterDelay={0.6}
        mouseLeaveDelay={0}
        title={info.event.title}
      >
        <span>{info.event.title}</span>
      </Tooltip>
    );
  };

  const generateDarkColorHex = () => {
    let color = "#";
    for (let i = 0; i < 3; i++)
      color += (
        "0" + Math.floor((Math.random() * Math.pow(16, 2)) / 2).toString(16)
      ).slice(-2);
    return color;
  };

  const getEventColor = (event: any) => {
    const isCampaign = event?.campaign?.id ?? false;
    const currentStatus = isCampaign ? event?.campaign?.status : event?.status;
    const mappedStatus = statuses.find(
      (status) => status.name === currentStatus
    );
    return mappedStatus?.colour ?? "";

    // if(!campaignId) return 'lightgray';
    // if(assignedColors.length >= CSS_COLOR_NAMES.length) return generateDarkColorHex()

    // let assignedColor = CSS_COLOR_NAMES[assignedColors.length];
    // assignedColors.push({id:campaignId,color:assignedColor});
    // return assignedColor
  };

  const calculateEventStatus = (event: any) => {
    const isCampaign = event?.campaign?.id ?? false;
    const currentStatus = isCampaign ? event?.campaign?.status : event?.status;
    const mappedStatus = statuses.find(
      (status) => status.name === currentStatus
    );
    return mappedStatus?.name ?? null;
  };

  const getMonthYearFromSelectedDateRange = (
    startDate: Date,
    endDate: Date
  ) => {
    let medianDate = new Date(
      new Date(startDate).setHours(startDate.getHours() + 24 * 15)
    );
    let months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return `${i18n.dir() === 'ltr'? `${months[medianDate.getMonth()]} ${medianDate.getFullYear()}`:`${t(months[medianDate.getMonth()])} ${medianDate.getFullYear()}`}`;
  };

  const resetStatusesToDefault = () => {
    let statusUpdates = statuses.map((status,index)=>{
      if(index === 0){
        status.active = true
      }else{
        status.active = false;
      }
      return status;
    })
    setStatuses(statusUpdates)
    return statusUpdates
  }

  const handleStatusFilterSelected = (index: number) => {
    let updatedStatuses = statuses.map((status, statusIndex) => {
      if (statusIndex === index) {
        status.active = true;
      } else {
        status.active = false;
      }
      return status;
    });

    setStatuses(updatedStatuses);
    if (updatedStatuses[index].name === "ALL") {
      setCalendarDataFiltered([...calendarDataRefined]);
    } else {
      setCalendarDataFiltered(
        calendarDataRefined.filter(
          (data: any) => data.status === updatedStatuses[index].name
        )
      );
    }
  };

  const renderStatusLengend = () => {
    return statuses.map((status, index) => {
      return (
        <span
          onClick={() => handleStatusFilterSelected(index)}
          className={`calendar-status-span ${
            status.active ? "calendar-status-active" : ""
          }`}
          id={status.name}
          style={{ backgroundColor: status.colour, color: status.textColor }}
        >
          {t(status.name)}
        </span>
      );
    });
  };

  const handleNavLinkDayClick = (date: Date, jsEvent: any) => {
    // console.log("day", date.toLocaleDateString());
    // console.log("coords", jsEvent.pageX, jsEvent.pageY);
  };

  const handleCalendarNext = () => {
    calendarRef.current.getApi().next()
    resetStatusesToDefault()
  };

  const handleCalendarSetView = (viewName:String) => {
    calendarRef.current.getApi().changeView(viewName)
    resetStatusesToDefault()
  }

  const handleCalendarPrev = () => {
    calendarRef.current.getApi().prev()
    resetStatusesToDefault()
  };

  return (
    <>
      {isLoading && (
        <div className="calendar-loader">
          <SpinnerComponent text={t("Loading")} />
        </div>
      )}

      <QBackLink
        title={t("common.Back", {ns: 'translationCommon'})}
        onClick={() => history.push("/briefs")}
      />

      <div className="mb-15 mt-20 calendar-month-container">
        <QButton
          className="qu-button-outline"
          size="small"
          onClick={handleCalendarPrev}
        >
          {"<"}
        </QButton>
        <h2>{monthYear}</h2>
        <QButton
          className="qu-button-outline"
          size="small"
          onClick={handleCalendarNext}
        >
          {">"}
        </QButton>
      </div>

      <Row gutter={24} className={"mb-15"}>
        <Col md={6} sm={6} xs={12}>
          <QSelect
            onChange={handleServiceChange}
            options={serviceOptions}
            className="full-width"
            placeholder={t("Filter Service Type")}
            showArrow
            size="large"
            mode="multiple"
            allowClear={true}
          />
        </Col>
        <Col md={6} sm={6} xs={12}>
          <QSelect
            onChange={(view:String) => handleCalendarSetView(view)}
            options={calendarViewOptions}
            defaultValue={"dayGridMonth"}
            className="full-width"
            placeholder="Calendar View"
            showArrow
            size="large"
            mode="single"
          />
        </Col>
        
        {/* <Col md={2}>
          <QButton
            className="qu-button-soft"
            size="middle"
            onClick={handleFilterClick}
          >
            {t('Filter')}
          </QButton>
        </Col> */}
      </Row>

      {/* <div className="mt-10">
        <div className="mb-5">{t('Color Legend:')}</div>
        <div className="calendarLegend pending">* {t('Pending Campaigns')}</div>
        <div className="calendarLegend others">* {t('All Active/Completed Campaigns')}</div>
      </div> */}

      <div className="mt-10">
        <div className="mb-5">
          <span className="mr-10">{t("Status")}:</span>
          {renderStatusLengend()}
        </div>
      </div>

      <div className="calendar-container">
        <FullCalendar
        headerToolbar={false}
          // headerToolbar={{
          //   start: "dayGridMonth,dayGridWeek"
          // }}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          buttonText={{ today: t("Today"), month: t("Month"), week: t("Week") }}
          datesSet={handleDatesSet}
          initialView={"dayGridMonth"}
          events={calendarDataFiltered}
          eventClick={handleEventClick}
          eventContent={handleEventMounted}
          navLinks={true}
          navLinkDayClick={handleNavLinkDayClick}
          dayMaxEvents={false}
          ref={calendarRef}
        />
      </div>
    </>
  );
};

export default Calendar;
