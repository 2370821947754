import get from 'lodash/get'

import { IState } from './campaign.types'
import * as CONSTANTS from './campaign.constants'
import IAction from 'src/interfaces/IAction'
import { CAMPAIGN_STATUS, GALLERY_PER_PAGE } from '../../constants/campaign'
import { cloneDeep } from 'lodash'
import { CLIENT_SURVEY_PER_PAGE } from '../../constants/survey'
import moment from 'moment'
import { getDateFromToday, getIsoDate } from 'src/helpers/datesHelper'
import { DATE_PRESET } from 'src/constants/dashboard'

export const ISurveyAnswerInitState = {
  title: null,
  quantity: null,
}

export const ISurveyQuestionInitState = {
  title: null,
  chartType: null,
  answers: [cloneDeep(ISurveyAnswerInitState)],
}

export const ISurveyInitState = {
  title: null,
  campaignId: null,
  isEqualAnswers: true,
  partnerIds: [],
  questions: [cloneDeep(ISurveyQuestionInitState)],
}

const initialState: IState = {
  fetchingCampaignListError: '',
  campaignInfoError: '',
  campaignDetailsError: '',
  campaignListLoading: true,
  campaignDetailsLoading: true,
  campaignList: {
    stats: {
      lifetime: 0,
      upcoming: 0,
    },
    results: [],
    total: 0,
  },
  pages: {
    totalPages: 1,
    currentPage: 1,
  },
  params: {
    offset: 0,
    limit: 10,
  },
  campaignDetails: null,
  settings: {
    partnerList: [],
    briefCities: [],
    ageGroups: {},
    genders: { Male: 0, Female: 0 },
    totalQuantity: 0,
    allocatedQuantity: 0,
    campaignStatus: CAMPAIGN_STATUS.ONGOING,
    mediaBuyMetrics: [],
  },
  campaignPartnerDistribution: [],
  campaignSettingsLoading: false,
  campaignSettingsError: '',
  campaignProps: null,
  campaignPropsLoading: false,
  campaignPropsError: '',
  campaignQuantityInfo: {
    assigned: 0,
    distributed: 0,
    remaining: 0,
  },
  campaignQuantityError: '',
  campaignUpdating: false,
  campaignAnalyticsInfo: null,
  campaignAnalyticsInfoLoading: false,
  campaignAnalyticsInfoError: '',
  campaignAnalyticsData: null,
  campaignAnalyticsDataLoading: false,
  campaignAnalyticsDataError: '',
  campaignTimelineError: '',
  campaignDistAnalyticsFilter: {
    partnerIds: [],
    cityIds: [],
    ageGroups: [],
  },
  campaignMediaBuyAnalyticsFilter: {
    partnerId: null,
    mediaType: '',
    beforeDate: getIsoDate(getDateFromToday(DATE_PRESET.WEEK)),
    afterDate: getIsoDate(moment().endOf('day'))
  },
  campaignMediaBuyAnalyticsData: null,
  campaignMediaBuyAnalyticsLoading: false,
  campaignSurveyList: [],
  campaignSurveyLoading: false,
  campaignSurveySaving: false,

  campaignMediaBuyFilter: [],
  campaignMediaBuyFilterLoading: false,
  campaignMediaBuyData: [],
  campaignMediaBuyDataLoading: false,

  campaignTimelineList: {
    results: [],
    total: 0,
  },
  campaignTimelineListLoading: false,
  uploadingTimelineImage: false,
  timelinePages: {
    totalPages: 1,
    currentPage: 1,
  },
  clientSurveys: {
    results: [],
    total: 0,
  },
  clientSurveysLoading: false,
  clientSurveysError: '',
  surveysPages: {
    totalPages: 1,
    currentPage: 1,
  },
  surveyParams: {
    offset: 0,
    limit: CLIENT_SURVEY_PER_PAGE,
    partnerIds: [],
  },
  campaignGalleryList: {
    results: [],
    total: 0,
  },
  campaignGalleryListLoading: false,
  savingGallery: false,
  galleryPages: {
    totalPages: 1,
    currentPage: 1,
  },

  galleryParams: {
    offset: 0,
    limit: GALLERY_PER_PAGE,
  },
}

export default (state: IState = initialState, action: IAction): IState => {
  switch (action.type) {
    case CONSTANTS.RESET_CAMPAIGN_STATE:
      return initialState
    case CONSTANTS.SET_CURRENT_PAGE:
      return { ...state, pages: { ...state.pages, currentPage: get(action, 'payload.page') } }
    case CONSTANTS.SET_TIMELINE_CURRENT_PAGE:
      return { ...state, timelinePages: { ...state.timelinePages, currentPage: get(action, 'payload.page') } }
    case CONSTANTS.SET_GALLERY_CURRENT_PAGE:
      return { ...state, galleryPages: { ...state.galleryPages, currentPage: get(action, 'payload.page') } }
    case CONSTANTS.SET_CAMPAIGN_LIST_PARAMS:
      return { ...state, params: get(action, 'payload.params') }
    case CONSTANTS.SET_CAMPAIGN_GALLERY_PARAMS:
      return { ...state, galleryParams: get(action, 'payload.params') }

    case CONSTANTS.FETCH_CAMPAIGNS_REQUEST:
      return {
        ...state,
        campaignListLoading: true,
      }
    case CONSTANTS.FETCH_CAMPAIGNS_SUCCESS:
      const campaignList = get(action, 'payload.campaignList')
      const totalPages = Math.ceil(campaignList.total / state.params.limit)
      return {
        ...state,
        campaignList,
        pages: { ...state.pages, totalPages },
        campaignListLoading: false,
      }
    case CONSTANTS.FETCH_CAMPAIGNS_FAILURE:
      return { ...state, fetchingCampaignListError: action.error, campaignListLoading: false }

    case CONSTANTS.FETCH_CAMPAIGN_DETAILS_SUCCESS:
      return {
        ...state,
        campaignDetails: get(action, 'payload.details'),
        campaignDetailsLoading: false,
      }
    case CONSTANTS.FETCH_CAMPAIGN_DETAILS_FAILURE:
      return { ...state, campaignDetailsError: action.error, campaignDetailsLoading: false }

    case CONSTANTS.FETCH_CAMPAIGN_SETTINGS_REQUEST:
      return {
        ...state,
        campaignSettingsLoading: true,
      }
    case CONSTANTS.FETCH_CAMPAIGN_SETTINGS_SUCCESS:
      return {
        ...state,
        settings: get(action, 'payload.settings'),
        campaignSettingsLoading: false,
      }
    case CONSTANTS.FETCH_CAMPAIGN_SETTINGS_FAILURE:
      return { ...state, campaignSettingsError: action.error, campaignSettingsLoading: false }

    case CONSTANTS.FETCH_CAMPAIGN_PROPS_REQUEST:
      return {
        ...state,
        campaignPropsLoading: true,
      }
    case CONSTANTS.FETCH_CAMPAIGN_PROPS_SUCCESS:
      return {
        ...state,
        campaignProps: get(action, 'payload.props'),
        campaignPropsLoading: false,
      }
    case CONSTANTS.FETCH_CAMPAIGN_PROPS_FAILURE:
      return { ...state, campaignPropsError: action.error, campaignPropsLoading: false }

    case CONSTANTS.FETCH_CAMPAIGNS_QUANTITY_INFO_SUCCESS:
      return {
        ...state,
        campaignQuantityInfo: get(action, 'payload.info'),
      }
    case CONSTANTS.FETCH_CAMPAIGNS_QUANTITY_INFO_FAILURE:
      return { ...state, campaignQuantityInfo: action.error }
    case CONSTANTS.FETCH_CAMPAIGN_DISTRIBUTION_SUCCESS:
      return { ...state, campaignPartnerDistribution: get(action, 'payload') }
    case CONSTANTS.FETCH_CAMPAIGN_DISTRIBUTION_FAILURE:
      return { ...state, campaignPartnerDistribution: [] }

    case CONSTANTS.UPDATE_CAMPAIGN_REQUEST:
      return { ...state, campaignUpdating: true }
    case CONSTANTS.UPDATE_CAMPAIGN_SUCCESS:
      return { ...state, campaignUpdating: false }
    case CONSTANTS.UPDATE_CAMPAIGN_FAILURE:
      return { ...state, campaignUpdating: false }

    case CONSTANTS.FETCH_CAMPAIGN_ANALYTICS_INFO_REQUEST:
      return { ...state, campaignAnalyticsInfoLoading: true }
    case CONSTANTS.FETCH_CAMPAIGN_ANALYTICS_INFO_SUCCESS:
      return {
        ...state,
        campaignAnalyticsInfo: get(action, 'payload.info'),
        campaignAnalyticsInfoLoading: false,
      }
    case CONSTANTS.FETCH_CAMPAIGN_ANALYTICS_INFO_FAILURE:
      return { ...state, campaignAnalyticsInfoError: action.error, campaignAnalyticsInfoLoading: false }

    case CONSTANTS.FETCH_CAMPAIGN_ANALYTICS_DATA_REQUEST:
      return { ...state, campaignAnalyticsDataLoading: true }
    case CONSTANTS.FETCH_CAMPAIGN_ANALYTICS_DATA_SUCCESS:
      return {
        ...state,
        campaignAnalyticsData: get(action, 'payload.data'),
        campaignAnalyticsDataLoading: false,
      }
    case CONSTANTS.FETCH_CAMPAIGN_ANALYTICS_DATA_FAILURE:
      return { ...state, campaignAnalyticsDataError: action.error, campaignAnalyticsDataLoading: false }

    case CONSTANTS.FETCH_CAMPAIGN_ANALYTICS_MEDIA_BUY_REQUEST:
      return { ...state, campaignMediaBuyAnalyticsLoading: true }
    case CONSTANTS.FETCH_CAMPAIGN_ANALYTICS_MEDIA_BUY_SUCCESS:
      return {
        ...state,
        campaignMediaBuyAnalyticsData: get(action, 'payload.data'),
        campaignMediaBuyAnalyticsLoading: false,
      }
    case CONSTANTS.FETCH_CAMPAIGN_ANALYTICS_MEDIA_BUY_FAILURE:
      return { ...state, campaignAnalyticsDataError: action.error, campaignMediaBuyAnalyticsLoading: false }

    case CONSTANTS.SET_DIST_ANALYTICS_FILTER:
      return { ...state, campaignDistAnalyticsFilter: get(action, 'payload.filter') }

    case CONSTANTS.SET_MEDIA_BUY_ANALYTICS_FILTER:
      return { ...state, campaignMediaBuyAnalyticsFilter: get(action, 'payload.filter') }

    case CONSTANTS.FETCH_CAMPAIGN_SURVEY_REQUEST:
      return {
        ...state,
        campaignSurveyLoading: true,
      }
    case CONSTANTS.FETCH_CAMPAIGN_SURVEY_SUCCESS:
      return {
        ...state,
        campaignSurveyList: get(action, 'payload.surveyList'),
        campaignSurveyLoading: false,
      }
    case CONSTANTS.FETCH_CAMPAIGN_SURVEY_FAILURE:
      return { ...state, campaignSurveyLoading: false }

    case CONSTANTS.SAVE_CAMPAIGN_SURVEY_REQUEST:
      return { ...state, campaignSurveySaving: true }
    case CONSTANTS.SAVE_CAMPAIGN_SURVEY_SUCCESS:
      return {
        ...state,
        campaignSurveySaving: false,
      }
    case CONSTANTS.SAVE_CAMPAIGN_SURVEY_FAILURE:
      return { ...state, campaignSurveySaving: false }

    case CONSTANTS.FETCH_CAMPAIGN_MEDIA_BUY_FILTER_REQUEST:
      return { ...state, campaignMediaBuyFilterLoading: true }
    case CONSTANTS.FETCH_CAMPAIGN_MEDIA_BUY_FILTER_SUCCESS:
      return {
        ...state,
        campaignMediaBuyFilter: get(action, 'payload.filter'),
        campaignMediaBuyFilterLoading: false,
      }
    case CONSTANTS.FETCH_CAMPAIGN_MEDIA_BUY_FILTER_FAILURE:
      return { ...state, campaignMediaBuyFilterLoading: false }

    case CONSTANTS.FETCH_CAMPAIGN_MEDIA_BUY_REQUEST:
      return { ...state, campaignMediaBuyDataLoading: true }
    case CONSTANTS.FETCH_CAMPAIGN_MEDIA_BUY_SUCCESS:
      return {
        ...state,
        campaignMediaBuyData: get(action, 'payload.data'),
        campaignMediaBuyDataLoading: false,
      }
    case CONSTANTS.FETCH_CAMPAIGN_MEDIA_BUY_FAILURE:
      return { ...state, campaignMediaBuyDataLoading: false }

    case CONSTANTS.FETCH_CAMPAIGN_TIMELINE_REQUEST:
      return { ...state, campaignTimelineListLoading: true }

    case CONSTANTS.FETCH_CAMPAIGN_TIMELINE_SUCCESS:
      const timelineList = get(action, 'payload.list')
      const totalTimelinePages = Math.ceil(timelineList.total / 10)

      return {
        ...state,
        campaignTimelineList: timelineList,
        timelinePages: { ...state.timelinePages, totalPages: totalTimelinePages },
        campaignTimelineListLoading: false,
      }
    case CONSTANTS.FETCH_CAMPAIGN_TIMELINE_FAILURE:
      return { ...state, campaignTimelineListLoading: false }

    case CONSTANTS.UPLOAD_CAMPAIGN_TIMELINE_IMAGES_REQUEST:
      return { ...state, uploadingTimelineImage: true }
    case CONSTANTS.UPLOAD_CAMPAIGN_TIMELINE_IMAGES_SUCCESS:
      return { ...state, uploadingTimelineImage: false }
    case CONSTANTS.UPLOAD_CAMPAIGN_TIMELINE_IMAGES_FAILURE:
      return { ...state, uploadingTimelineImage: false }

    case CONSTANTS.FETCH_CLIENT_SURVEYS_REQUEST:
      return {
        ...state,
        clientSurveysLoading: true,
      }
    case CONSTANTS.FETCH_CLIENT_SURVEYS_SUCCESS:
      const clientSurveys = get(action, 'payload.surveys')
      const surveyTotal = Math.ceil(clientSurveys.total / state.surveyParams.limit)
      return {
        ...state,
        clientSurveys,
        surveysPages: { ...state.surveysPages, totalPages: surveyTotal },
        clientSurveysLoading: false,
      }
    case CONSTANTS.FETCH_CLIENT_SURVEYS_FAILURE:
      return { ...state, clientSurveysError: action.error, clientSurveysLoading: false }

    case CONSTANTS.SET_CLIENT_SURVEYS_CURRENT_PAGE:
      return { ...state, surveysPages: { ...state.surveysPages, currentPage: get(action, 'payload.page') } }
    case CONSTANTS.SET_CLIENT_SURVEYS_PARAMS:
      return { ...state, surveyParams: get(action, 'payload.params') }

    case CONSTANTS.SAVE_CAMPAIGN_GALLERY_REQUEST:
      return { ...state, savingGallery: true }
    case CONSTANTS.SAVE_CAMPAIGN_GALLERY_SUCCESS:
      return { ...state, savingGallery: false }
    case CONSTANTS.SAVE_CAMPAIGN_GALLERY_FAILURE:
      return { ...state, savingGallery: false }

    case CONSTANTS.FETCH_CAMPAIGN_GALLERY_REQUEST:
      return { ...state, campaignGalleryListLoading: true }

    case CONSTANTS.FETCH_CAMPAIGN_GALLERY_SUCCESS:
      const galleryList = get(action, 'payload.list')
      const totalGalleryPages = Math.ceil(galleryList.total / 10)

      return {
        ...state,
        campaignGalleryList: galleryList,
        galleryPages: { ...state.galleryPages, totalPages: totalGalleryPages },
        campaignGalleryListLoading: false,
      }
    case CONSTANTS.FETCH_CAMPAIGN_GALLERY_FAILURE:
      return { ...state, campaignGalleryListLoading: false }

    default:
      return state
  }
}
