import { IState } from './workflow.types'
import * as CONSTANTS from './workflow.constants'

const initialState: IState = {
  data:[],
  loading:false,
  services:[]
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case CONSTANTS.WORKFLOW_REQUEST:
      return {
        ...state,
        loading:true
      }
      case CONSTANTS.WORKFLOW_END_REQUEST:
      return {
        ...state,
        loading:false
      }
    case CONSTANTS.WORKFLOW_SUCCESS:
      return {
        ...state,
        data:action.payload,
        loading: false,
      }
      case CONSTANTS.WORKFLOW_DETAILS_SUCCESS:
        return {
          ...state,
          loading: false,
        }
    case CONSTANTS.WORKFLOW_FAILURE:
      return {
        ...state,
        loading: false,
      }
      
    default:
      return state
  }
}
