import { createSelector } from "reselect";
import { MODULE_NAME } from "./nativeTemplates.constants";
import { IState } from "./nativeTemplates.types";

const selectState = (state: { [MODULE_NAME]: any }) => state[MODULE_NAME];

export const getDataLoading = createSelector(
  selectState,
  (state: IState): boolean => state.loading
);

export const nativeTemplatesList = createSelector(
  selectState,
  (state: IState): any => state.data
);
