import { IState } from './nativeTemplates.types'
import * as CONSTANTS from './nativeTemplates.constants'

const initialState: IState = {
  data:[],
  loading:false,
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case CONSTANTS.NATIVE_TEMPLATES_REQUEST:
      return {
        ...state,
        loading:true
      }
      case CONSTANTS.NATIVE_TEMPLATES_END_REQUEST:
      return {
        ...state,
        loading:false
      }
    case CONSTANTS.NATIVE_TEMPLATES_SUCCESS:
      return {
        ...state,
        data:action.payload.data,
        loading: false,
      }
      case CONSTANTS.NATIVE_TEMPLATES_DETAILS_SUCCESS:
        return {
          ...state,
          loading: false,
        }
    case CONSTANTS.NATIVE_TEMPLATES_FAILURE:
      return {
        ...state,
        loading: false,
      }
      
    default:
      return state
  }
}
