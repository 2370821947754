import { createSelector } from 'reselect'
import { MODULE_NAME } from './calendar.constants'
import { IState } from './calendar.types'

const selectState = (state: { [MODULE_NAME]: any }) => state[MODULE_NAME]

export const getDataLoading = createSelector(
  selectState,
  (state: IState): boolean => state.loading
)

export const getCalendarData = createSelector(selectState, (state: IState): any => state.data)

export const getCalendarServicesList = createSelector(selectState, (state: IState): any => state.services)
