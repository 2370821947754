import get from 'lodash/get'
import { IState } from './discountCodes.types'
import * as CONSTANTS from './discountCodes.constants'

const initialState: IState = {
  discountCodesListLoading: true,
  discountCodesList: {
    results: [],
    total: 0,
  },
  pages: {
    totalPages: 1,
    currentPage: 1,
  },
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case CONSTANTS.FETCH_DISCOUNT_CODES_REQUEST:
      return {
        ...state,
        discountCodesListLoading: true,
      }
    case CONSTANTS.FETCH_DISCOUNT_CODES_SUCCESS:
      const discountCodesList = action.payload
      return {
        ...state,
        discountCodesList: discountCodesList,
        discountCodesListLoading: false,
      }
    case CONSTANTS.FETCH_DISCOUNT_CODES_FAILURE:
      return {
        ...state,
        discountCodesListLoading: false,
      }
    case CONSTANTS.SET_CURRENT_PAGE:
      return {
        ...state,
        pages: {
          ...state.pages,
          currentPage: get(action, 'payload.page'),
        },
      }
    default:
      return state
  }
}
