import get from 'lodash/get'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'

import {
  IBrief,
  IBriefSettings,
  IProductInitState,
  IState,
  ITargetingInitState,
  IBriefResponse,
  IServiceInitState,
  IMediaBuyInitState,
  IPartnerFilter,
  IPartnerFilterSettings,
} from './briefv3.types'
import * as CONSTANTS from './briefv3.constants'
import IAction from 'src/interfaces/IAction'
import {
  BRIEF_PAYMENT_STATUS,
  BRIEF_SORT_TYPE,
  BRIEF_STATUS,
  BRIEF_STEPS,
  CREATE_BRIEF_STEPS,
  DEFAULT_BRIEF_TABLE_PARAMS,
} from '../../constants/brief'

export const IBriefInitState: IBrief = {
  id: null,
  promotionId:null,
  paymentEnabled:false,
  status: BRIEF_STATUS.DRAFT,
  lastFilledStep: null,
  [BRIEF_STEPS.TYPE]: { briefTypes: [] },
  [BRIEF_STEPS.PRODUCT]: cloneDeep(IProductInitState),
  [BRIEF_STEPS.TARGETING]: cloneDeep(ITargetingInitState),
  [BRIEF_STEPS.SERVICES]: cloneDeep(IServiceInitState),
  [BRIEF_STEPS.MEDIA_BUY]: cloneDeep(IMediaBuyInitState),
  [BRIEF_STEPS.PARTNER]: { quantity: 0, hasFreeSample: false },
  [BRIEF_STEPS.UPLOAD]: { uploadMaterial: [] },
  [BRIEF_STEPS.PAYMENT]: null,
  [BRIEF_STEPS.SUMMARY]: null,
}

const settingsInitState: IBriefSettings = {
  ageGroups: [],
  partnerProfileId:0,
  targetingToggles:[],
  distributionItems: [],
  productTypes: [],
  briefDurations: [],
  mediaDurations: [],
  targetSegments: [],
  targetCampaigns: [],
  targetingKeys:[],
  packagingTypes: [],
  mediaTypes: [],
  countries: [],
  MINIMUM_DISTRIBUTION_LOGISTIC_COUNT: 0,
  temperature: [],
  copackingPrices: [],
  printingPrices: [],
  managementFee: 0,
  partnerVat: '',
}

export const IPartnerFilterSettingsInitState: IPartnerFilterSettings = {
  categories: [],
  pricePerUnit: [],
  matches: [],
  types: [],
  sortBy: [],
  mediaSubtypes: [],
}

export const IPartnerFilterInitState: IPartnerFilter = {
  search: '',
  categories: [],
  pricePerUnit: [],
  matches: [],
  types: [],
  sortBy: BRIEF_SORT_TYPE.BEST_MATCH,
}

const initialState: IState = {
  createBriefError: '',
  isSaving: false,
  currentStep: CREATE_BRIEF_STEPS[1],
  needCheckLastStep: true,
  brief: cloneDeep(IBriefInitState),
  copackingPrice: null,
  printingPrice: null,
  fetchingBriefListError: '',
  briefListLoading: true,
  briefList: {
    results: [],
    total: 0,
  },
  pages: {
    totalPages: 1,
    currentPage: 1,
  },
  params: DEFAULT_BRIEF_TABLE_PARAMS,

  fetchSettingsError: '',
  settingsLoading: false,
  settings: settingsInitState,
  viewBrief: {} as IBriefResponse,
  viewBriefPaymentFilesState: null,
  imagesUploading: false,
  imagesUploadingError: '',
  imagesRemoving: false,
  imagesRemovingError: '',
  partnerFilterSettingsError: '',
  serviceImages: '',
  productImages: '',
  partnerFilterSettingsLoading: false,
  partnerFilterSettings: IPartnerFilterSettingsInitState,
  partnerFilter: cloneDeep(IPartnerFilterInitState),
  fetchRecommendedPartnersLoading: false,
  recommendedPartners: [],
  fetchPartnerDetailsLoading: false,
  partnerDetails: null,
  fetchCartLoading: false,
  updateCartLoading: false,
  cart: {
    briefId: null,
    sampleAllocated: 0,
    additional: {
      managementFee: 0,
      managementFeeRate: 0,
      couponFee: 0,
      promotionCouponFee:0,
      taxes: 0,
      cartTotal: 0,
      items: [],
      copackingTotal: 0,
      printingTotal: 0,
      servicesTotal: 0,
      subTotal: 0,
      copackingMinQuantity: 0,
      printingMinQuantity: 0,
      promotionCartTotal:0,
      promotionTaxes:0
    },
    mediaBuyEstimates: [],
  },
  activeSinglePartnerId: null,
  totalQuantity: 0,
  uploadSettings: [],
  uploadSettingsLoading: false,
  uploadStepLoading: [],
  rejectionFlow: false,
  dialogModal: null,
  sendProposalLoading: false,
  exclusivePartnerId: null,
  potentialAudienceReach: {
    totalSampleAudience: 0,
    totalMediaBuyAudience: 0,
    messageSampleDistribution: '',
    messageMediaBuy: '',
  },
  paymentSelectedType: null,
}

export default (state: IState = initialState, action: IAction): IState => {
  switch (action.type) {
    
    default:
      return state
  }
}
