import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { QSidenav, Modal, IconsCommon, QButton, qnotification } from 'quantum_components'
import SidebarMenu from './SidebarMenu'
import { useHistory } from 'react-router-dom'
import { PATHS, LOCALIZATION_LANGUAGES, NOTIFICATION_TYPES } from '../../../constants'
import AdminHeader from '../../Headers'
import { MAIN_PRINT_ID } from '../../../constants/brief'
import * as coreActions from 'src/modules/core/core.actions'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentUser, getCurrentUserPermissionActions, getCustomNotification, getIsLoading, getIsPaymentLoading, getSubscriptionOverduePayment } from 'src/modules/core/core.selectors'
import { CUSTOM_NOTIFICATIONS_TYPES } from 'src/constants/notifications'
import { Alert } from 'antd'
import SpinnerCenter from 'src/components/Spinner/spinner'
import { hasPermission } from 'src/helpers/permission'
import { PERMISSIONS } from 'src/constants/permission'
import { getReadableDate } from 'src/helpers/datesHelper'

interface Props {
  children: React.ReactNode
}

const PartnerLayout = ({ children }: Props) => {
  const { i18n } = useTranslation()
  const { t } = useTranslation(['translationSubscriptionHistory', 'translationCommon'])

  const history = useHistory()
  const dispatch = useDispatch()
  const currentUser = useSelector(getCurrentUser)
  const customNotification = useSelector(getCustomNotification)
  const isLoadingReadNotification = useSelector(getIsLoading)
  const subscriptionOverduePayment = useSelector(getSubscriptionOverduePayment)
  const isPaymentLoading = useSelector(getIsPaymentLoading)
  const permissions = useSelector(getCurrentUserPermissionActions);

  const [myCustomNotification, setmyCustomNotification] = useState<any>(null)

  useEffect(() => {
    if (currentUser?.id && !myCustomNotification) {
      dispatch(coreActions.getUnreadCustomNotification())
    }
    if (currentUser?.id && hasPermission(permissions, PERMISSIONS.PAY_SUBSCRIPTION)) {
      dispatch(coreActions.getSubscriptionOverduePayment())
    }
  }, [currentUser])

  useEffect(() => {
    setmyCustomNotification(customNotification)
    if (customNotification) {
      readNotification(customNotification)
    }
  }, [customNotification])

  // PUSH_NOTIFICATIONS
  useEffect(() => {
    if (myCustomNotification && myCustomNotification?.type === CUSTOM_NOTIFICATIONS_TYPES.PUSH_NOTIFICATIONS) setPushNotification()
  }, [myCustomNotification])


  const readNotification = async (record: any) => {
    dispatch(coreActions.markAsReadCustomNotification(record))
  }

  const setPushNotification = async () => {
    if (!!myCustomNotification?.redirectionLink){
      qnotification({
        // @ts-ignore
        language: i18n.language,
        type: NOTIFICATION_TYPES.INFO,
        message: myCustomNotification.title,
        description: myCustomNotification.description,
        onClose: closeNotification,
        placement: 'bottomRight',
        duration: 0,
        onClick:()=>{window.open(myCustomNotification?.redirectionLink, '_blank')}
      })
    }
    else
    { // @ts-ignore
      qnotification({ type: NOTIFICATION_TYPES.INFO, message: myCustomNotification.title, description: myCustomNotification.description, onClose: closeNotification, placement: 'bottomRight', duration: 0, language: i18n.language })
    }
  }

  const closeNotification = async () => {
    dispatch(coreActions.getUnreadCustomNotification())
  };

  const paySubscriptionPayment = async () => {
    dispatch(coreActions.paySubscriptionPayment(subscriptionOverduePayment.subscriptionId))
  }

  const closePaySubscriptionModal = async () => {
    dispatch(coreActions.getSubscriptionOverduePaymentSuccess(null))
  }

  return (
    <div className={`layout partner ${i18n.language === LOCALIZATION_LANGUAGES.ARABIC ? 'html-rtl' : ''}`}>
      <QSidenav onLogoLink={() => history.push(PATHS.ROOT)} childrenMenu={<SidebarMenu />} />
      <div className="partner-layout">
        <AdminHeader />

        {(isLoadingReadNotification || isPaymentLoading) && <SpinnerCenter />}

        {/* POPUP_NOTIFICATIONS */}
        <Modal
          wrapClassName="qu-modal"
          closeIcon={<IconsCommon.IconClose />}
          visible={myCustomNotification && myCustomNotification?.type === CUSTOM_NOTIFICATIONS_TYPES.POPUP_NOTIFICATIONS}
          onCancel={closeNotification}
          width={500}
          title={<h3>{myCustomNotification?.title}</h3>}
          footer={null}
        >
          <p>{myCustomNotification?.description}</p>
          {!!myCustomNotification?.redirectionLink && <div className="align-vertical text-center">
            <QButton onClick={() => window.open(myCustomNotification?.redirectionLink, '_blank')} className="w-240" type="primary">
              <span className="mr-5">Click to know more</span>
            </QButton>
          </div>}
        </Modal>

        {/* STANDARD_NOTIFICATIONS */}
        {myCustomNotification &&
          myCustomNotification?.type === CUSTOM_NOTIFICATIONS_TYPES.STANDARD_NOTIFICATIONS &&
          <div className="qu-alert p-20">
            <Alert
              message={myCustomNotification?.title}
              description={myCustomNotification?.description}
              type="info"
              action={
                !!myCustomNotification?.redirectionLink && <div className="align-vertical text-center">
                  <QButton onClick={() => window.open(myCustomNotification?.redirectionLink, '_blank')} className="w-240 redirect-button" type="primary">
                    <span className="mr-5">Click to know more</span>
                  </QButton>
                </div>
              }
              onClose={closeNotification}
              closable
            />
          </div>
        }

        {/* PAY SUBSCRIPTION MODAL */}
        <Modal
          wrapClassName="qu-modal"
          closeIcon={<IconsCommon.IconClose />}
          visible={subscriptionOverduePayment}
          onCancel={closePaySubscriptionModal}
          width={500}
          title={<h3>{t("Pending Subscription Payment")}</h3>}
          footer={null}
        >
          <p>{t("There was a failed payment for your active subscription due on. Please complete the payment by clicking on the Pay Button to avoid any service disruption.", { subscriptionDueDate: getReadableDate(subscriptionOverduePayment?.createdAt) })}</p>
          <div className="align-vertical text-center">
            <QButton
              onClick={paySubscriptionPayment}
              className="w-240"
              type="primary"
            >
              {t("PAY")}
            </QButton>
          </div>
        </Modal>

        <div className={`partner-main`} id={MAIN_PRINT_ID}>
          <div className="partner-content">{children}</div>
        </div>
      </div>
    </div>
  )
}

export default PartnerLayout
