import { createSelector } from 'reselect'
import { MODULE_NAME } from './leads.constants'
import { IState, ILead } from './leads.types'

const selectState = (state: { [MODULE_NAME]: any }) => state[MODULE_NAME]

export const getIsLeadsListLoading = createSelector(selectState, (state: IState): boolean => state.leadsListLoading)

export const getLeadsList = createSelector(selectState, (state: IState): ILead[] =>
  state.leadsList.results.map((lead: ILead) => {
    return {
      ...lead,
      key: lead.id,
    }
  })
)

export const getTotalInvitedClients = createSelector(
  selectState,
  (state: IState): number => state.leadsList.totalInvitedClient
)

export const getTotalExistingUsers = createSelector(
  selectState,
  (state: IState): number => state.leadsList.totalExistingUsers
)

export const getLeadsTotal = createSelector(selectState, (state: IState): number => state.leadsList.total)

export const getPages = createSelector(
  selectState,
  (
    state: IState
  ): {
    totalPages: number
    currentPage: number
  } => state.pages
)
