import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { AxiosResponse } from 'axios'

import { qnotification } from 'quantum_components'

import IAction from 'src/interfaces/IAction'
import { requestHttp, urls } from 'src/api'
import { ISignInResponse } from './signIn.types'
import * as CONSTANTS from './signIn.constants'
import { LOCAL_STORAGE_KEYS, PATHS, ROLES, NOTIFICATION_TYPES } from 'src/constants'
import { getResponseErrorMessage } from 'src/helpers'
import localStorage from 'redux-persist/es/storage'
import history from '../../../utils/history'

import { getUser } from '../../core/core.actions'

export const resetState = (): IAction => ({ type: CONSTANTS.RESET_STATE })

export const signInRequest = (): IAction => ({
  type: CONSTANTS.SIGN_IN_REQUEST,
})

export const signInSuccess = (email: string, accessToken: string, role: ROLES): IAction => ({
  type: CONSTANTS.SIGN_IN_SUCCESS,
  payload: { email, accessToken, role },
})

export const signInFailure = (error: string): IAction => ({
  type: CONSTANTS.SIGN_IN_FAILURE,
  error,
})

export const signInErrorReset = (): IAction => ({
  type: CONSTANTS.SIGN_IN_RESET_ERROR,
})

export const signIn = (
  email: string,
  password: string
): ThunkAction<Promise<AxiosResponse<ISignInResponse>>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse<ISignInResponse>> => {
  try {
    dispatch(signInRequest())
    const response = await requestHttp.post<ISignInResponse>(urls.getLoginUrl(), { email, password })

    if (response.status === 200) {
      const { content } = response.data.data

      if (content.role !== ROLES.ADMIN) {
        qnotification({ type: NOTIFICATION_TYPES.ERROR, message: 'Authorization Forbidden' })
        dispatch(signInFailure('Authorization Forbidden'))
        return response
      }

      dispatch(signInSuccess(content.email, content.token, content.role))
      await localStorage.setItem(LOCAL_STORAGE_KEYS.USER_ROLE, content.role)
      await localStorage.setItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, content.token)

      dispatch(getUser())

      history.push(PATHS.ROOT)
    }

    return response
  } catch (error) {
    dispatch(signInFailure(getResponseErrorMessage(error)))
    return error
  }
}
