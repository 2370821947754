import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next'
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useReduxDispatch } from "../../../helpers";
import * as actions from "../adminSettings.actions";
import { Tooltip, InputNumber, List, Button } from "antd";
import {
  Modal,
  Form,
  Row,
  Col,
  QButton,
  QTable,
  QInput,
  IconsCommon,
  Switch,
  InfoCard,
  Menu,
} from "quantum_components";
import { PoweroffOutlined, ToolOutlined, UserOutlined, FolderOpenOutlined } from "@ant-design/icons";
import { validateMessages } from "../../../helpers/validationMessage";
import {
  getCurrentUserPermissionActions,
  getCurrency,
  getCurrentUser,
} from "../../core/core.selectors";
import { hasPermission } from "../../../helpers/permission";
import { PERMISSIONS } from "../../../constants/permission";
import { REGEXES, LOCALIZATION_LANGUAGES, PATHS } from "src/constants";
import { MENU_ITEMS, PERMISSION_MENU } from "src/constants/menu";
import {
  getAdminSettingsDataLoading,
  getCampaignOpsPrices,
  getManagementFee,
  getTargetingToggles,
  getThemeData,
  getWorkflowSettings,
} from "../adminSettings.selectors";
import {
  ICopackingPrintingPricing,
  ITargetingToggles,
  IThemeData,
} from "../adminSettings.types";
import { getLocaleCostWithoutDecimal } from "src/helpers/currency";
import UploadComponent from "src/components/UploadComponent";
import IUploadFile from "src/interfaces/IUploadFile";
import { TName } from "src/interfaces/IAction";
import { getFileNameFromUrl } from "src/utils";
import * as coreActions from "src/modules/core/core.actions";

const AdminSettingsList = () => {
  const history = useHistory();
  const { i18n } = useTranslation()
  const { t } = useTranslation(['translationSettings', 'translationCommon', 'translationPlatform'])
  const [form] = Form.useForm();

  const [files, setFiles] = useState<{ [x: string]: IUploadFile[] }>({});
  const [filesObjectLoading, setFilesObjectLoading] = useState({
    companyLogo: false,
    previewCard: false,
    listingHeader: false,
  });
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [workflowEnabled, setWorkflowEnabled] = useState(false);
  const dispatch = useReduxDispatch();
  const permissions = useSelector(getCurrentUserPermissionActions);
  const currency = useSelector(getCurrency);
  const adminSettingsDataLoading = useSelector(getAdminSettingsDataLoading);
  const workflowSetting = useSelector(getWorkflowSettings)
  const managementFees = useSelector(getManagementFee);
  const campaignOpsPrices = useSelector(getCampaignOpsPrices);
  const currentUser = useSelector(getCurrentUser);

  const targetingToggleSetting = useSelector(getTargetingToggles);
  const themeData = useSelector(getThemeData);

  const [initialTargetingValues, setInitialTargetingValues] = useState<
    ITargetingToggles[]
  >([]);
  const [initialThemeValues, setInitialThemeValues] = useState<IThemeData[]>(
    []
  );

  useEffect(() => {
    dispatch(actions.fetchAdminSettings());
  }, []);

  useEffect(() => {
    if (targetingToggleSetting && targetingToggleSetting.length) {
      setInitialTargetingValues(targetingToggleSetting);
    }
  }, [targetingToggleSetting]);

  useEffect(() => {
    if (themeData && themeData.length) {
      setInitialThemeValues(themeData);
    }
  }, [themeData]);

  useEffect(() => {
    setWorkflowEnabled(workflowSetting)
  }, [workflowSetting])

  const [tableKey, setTableKey] = useState(1);
  const [adminSettings, setAdminSettings] = useState<any>({
    managementFee: 0,
    copackingPrices: [],
    printingPrices: [],
    testahelPrices: [],
  });
  const [managementFee, setManagementFee] = useState(0);
  const [editModal, setEditModal] = useState({
    visible: false,
    modalType: "",
    recordId: -1,
  });
  const [deleteModal, setDeleteModal] = useState<any>({
    visible: false,
    record: {},
  });

  useEffect(() => {
    async function calculateData() {
      const copakingData = new Array();
      const printingData = new Array();
      const testahelData = new Array();
      campaignOpsPrices.map((ele: ICopackingPrintingPricing, index: number) => {
        !ele.deleted &&
          (ele.type == "copacking"
            ? copakingData.push({ ...ele, key: index })
            : ele.type == "respondent"
              ? testahelData.push({ ...ele, key: index })
              : printingData.push({ ...ele, key: index }));
      });
      setAdminSettings({
        managementFee: managementFees,
        copackingPrices: copakingData,
        printingPrices: printingData,
        testahelPrices: testahelData.sort((a, b) =>
          a.minQuantity < b.minQuantity ? -1 : 1
        ),
      });
      setManagementFee(managementFees.managementFee);
      setTableKey(Math.floor(Math.random() * 1000 + 1));
    }
    calculateData();
  }, [campaignOpsPrices, managementFees]);

  const handleEditModalOpen = (modalType: string, record: any = null) => {
    if (record) {
      if (modalType === "Testahel Price") {
        form.setFieldsValue({
          name: record.name,
          rate: record.rate,
          minQuantity: record.minQuantity,
          maxQuantity: record.maxQuantity,
        });
      } else {
        form.setFieldsValue({
          name: record.name,
          rate: record.rate,
          minQuantity: record.minQuantity,
        });
      }
      setEditModal({
        visible: true,
        modalType: modalType,
        recordId: record.id,
      });
    } else {
      form.resetFields();
      setEditModal({
        visible: true,
        modalType: modalType,
        recordId: -1,
      });
    }
  };

  const handleEditModalClose = async () => {
    await setEditModal({
      ...editModal,
      visible: false,
    });
    setTimeout(() => {
      setEditModal({
        visible: false,
        modalType: "",
        recordId: -1,
      });
    }, 1000);
    form.resetFields();
  };

  const onCheck = async () => {
    try {
      const values = await form.validateFields();
      editModal.recordId >= 0
        ? editModal.modalType === "Testahel Price"
          ? await dispatch(
            actions.editAdminSettingRecord(
              {
                rate: values.rate,
                name: values.name,
                type: "respondent",
                minQuantity: values.minQuantity,
                maxQuantity: values.maxQuantity ? values.maxQuantity : null,
              },
              editModal.recordId
            )
          )
          : await dispatch(
            actions.editAdminSettingRecord(
              {
                rate: values.rate,
                name: values.name,
                type:
                  editModal.modalType === "Copacking Price"
                    ? "copacking"
                    : "printing",
                minQuantity: values.minQuantity ? values.minQuantity : 0,
              },
              editModal.recordId
            )
          )
        : editModal.modalType === "Testahel Price"
          ? await dispatch(
            actions.addAdminSettingRecord({
              rate: values.rate,
              name: values.name,
              type: "respondent",
              minQuantity: values.minQuantity,
              maxQuantity: values.maxQuantity ? values.maxQuantity : null,
            })
          )
          : await dispatch(
            actions.addAdminSettingRecord({
              rate: values.rate,
              name: values.name,
              type:
                editModal.modalType === "Copacking Price"
                  ? "copacking"
                  : "printing",
              minQuantity: values.minQuantity ? values.minQuantity : 0,
            })
          );
      setEditModal({
        ...editModal,
        visible: false,
      });
      setTimeout(() => {
        setEditModal({
          visible: false,
          modalType: "",
          recordId: -1,
        });
      }, 1000);
      setTableKey(Math.floor(Math.random() * 1000 + 1));
    } catch (error) {
      return error;
    }
  };

  const handleDeleteClick = (data: any) => {
    setDeleteModal({
      visible: true,
      record: data,
    });
  };

  const handleDeleteModalClose = () => {
    setDeleteModal({
      visible: false,
      record: {},
    });
    setTableKey(Math.floor(Math.random() * 1000 + 1));
  };

  const handleDeletePricingRecord = async () => {
    await dispatch(actions.deleteAdminSettingRecord(deleteModal.record.id));
    setDeleteModal({
      visible: false,
      record: {},
    });
    setTableKey(Math.floor(Math.random() * 1000 + 1));
  };

  const saveManagementFeeData = () => {
    dispatch(actions.editManagementFees(managementFee));
  };

  const saveTargetingForClients = () => {
    dispatch(actions.editTargetingContols(initialTargetingValues));
  };

  const handleOnValueChanges = (data: any) => {
    let key = Object.keys(data)[0];
    let ivClone = [...initialTargetingValues];
    ivClone.forEach((el) => {
      if (el.control_name === key) {
        el.control_toggle = !el.control_toggle;
      }
    });
    setInitialTargetingValues(ivClone);
  };

  const checkChecked = (type: any): boolean => {
    if (!initialTargetingValues.length) {
      return false;
    }
    return (
      initialTargetingValues &&
      initialTargetingValues.filter((el) => el.control_name === type)[0]
        .control_toggle
    );
  };

  const getDefaultThemeValue = (control: any) => {
    if (!initialThemeValues.length) {
      return "";
    }
    return (
      initialThemeValues &&
      initialThemeValues.filter((el) => el.control_name === control)[0]
        .control_value
    );
  };

  const setThemeValues = (control: any, value: any) => {
    let ivClone = [...initialThemeValues];
    ivClone.forEach((el) => {
      if (el.control_name === control) {
        el.control_value = value;
      }
    });
    setInitialThemeValues(ivClone);
  };

  const handleThemeUpdate = () => {
    // console.log(initialThemeValues);
    let v = [...initialThemeValues];
    let uv = v.filter((el) => el.control_value);
    dispatch(actions.editThemeData(uv));
  };

  const handleUpload = (control: string, file: File[]) => {
    let controlObj = initialThemeValues.filter(
      (el) => el.control_name === control
    )[0];
    // console.log(control, file);
    setFilesObjectLoading((prevState) => ({ ...prevState, [control]: true }));
    dispatch(
      actions.uploadProductImage(
        file,
        control,
        currentUser.partnerUserId,
        controlObj
      )
    );
  };

  const handleRemove = async (control: string) => {
    let uv = initialThemeValues.filter(
      (el: IThemeData) => el.control_name === control
    )[0];
    uv.control_value = null;
    dispatch(actions.editThemeData([uv]));
  };

  const getFileObj = (url: string) => {
    return {
      name: getFileNameFromUrl(url),
      status: "done",
      uid: Math.ceil(Math.random() * 10),
      url,
    };
  };

  const handleWorkflowUpdate = async () => {
    await dispatch(actions.updateWorkflow(workflowEnabled))
    await dispatch(coreActions.getUser())
  }

  interface MenuItem {
    key: MENU_ITEMS;
    title: string;
    path: string;
    callback?: () => void;
  }

  const menuConfiguration: MenuItem[] = [
    { key: MENU_ITEMS.TARGETING_KEYS, title: t(`menu.targetingKeys`, {ns: 'translationPlatform'}), path: PATHS.TARGETING_KEYS },
    { key: MENU_ITEMS.NATIVE_TEMPLATES, title: t(`menu.nativeTemplates`, {ns: 'translationPlatform'}), path: PATHS.NATIVE_TEMPLATES },
    {
      key: MENU_ITEMS.EMPLOYEES_ALL,
      title: t(`menu.manage_employees`, {ns: 'translationPlatform'}),
      path: PATHS.EMPLOYEES,
    },
    {
      key: MENU_ITEMS.EMPLOYEES_ROLES,
      title: t(`menu.manage_roles`, {ns: 'translationPlatform'}),
      path: PATHS.ROLES,
    },
  ];


  const menuAdmin: MenuItem[] = [
    {
      key: MENU_ITEMS.PARTNERS,
      title: t(`menu.profile`, {ns: 'translationPlatform'}),
      path: PATHS.PARTNER_PROFILES,
    },
    {
      key: MENU_ITEMS.UPDATE_PASSWORD,
      title: t(`update_password`, {ns: 'translationPlatform'}),
      path: PATHS.UPDATE_PASSWORD,
    },

    { key: MENU_ITEMS.BILLINGS, title: t(`menu.billings`, {ns: 'translationPlatform'}), path: PATHS.BILLINGS },
    {
      key: MENU_ITEMS.ACTIVITIES,
      title: t(`menu.activities`, {ns: 'translationPlatform'}),
      path: PATHS.ACTIVITIES,
    },
    {
      key: MENU_ITEMS.SUBSCRIPTION_HISTORY,
      title: t(`menu.subscriptionHistory`, {ns: 'translationPlatform'}),
      path: PATHS.SUBSCRIPTION_HISTORY,
    },
  ];


  const menuResources: MenuItem[] = [
    {
      key: MENU_ITEMS.PRIVACY_POLICY,
      title: t(`menu.privacyPolicy`, {ns: 'translationPlatform'}),
      path: PATHS.PRIVACY_POLICY,
    },
    {
      key: MENU_ITEMS.DISCLAIMER,
      title: t(`menu.disclaimer`, {ns: 'translationPlatform'}),
      path: PATHS.DISCLAIMER,
    },
    {
      key: MENU_ITEMS.RELEASE_NOTES,
      title: t(`menu.releaseNotes`, {ns: 'translationPlatform'}),
      path: PATHS.RELEASE_NOTES,
    },
  ];

  const onRedirect = (url: string) => {
    history.push(url);
  };

  const handleClick = (item: MenuItem) => {
    onRedirect(item.path);
  };
  
  const hasMenuPermission = (item: MenuItem) => {
    return hasPermission(permissions, PERMISSION_MENU[item.key]);
  };

  const renderMenuItem = (item: MenuItem) => {
    const disabled = !hasMenuPermission(item);
    return (
      <Menu.Item
        disabled={disabled}
        onClick={() => handleClick(item)}
        key={item.key}
      >
        <div className="full-width">{item.title}</div>
      </Menu.Item>
    );
  };

  const handleLogout = () => {
    dispatch(coreActions.logout());
  };

  return (
    <>
      <div>
        <div className="mb-30 mt-20">
          <Row gutter={22} justify="space-between" align="middle">
            <Col>
              <h2>{`${t('settings.system.Settings')}`}</h2>
            </Col>
            <Col>

              <Button type="link" danger onClick={handleLogout}>
                <PoweroffOutlined /> {t(`logout`, {ns: 'translationPlatform'})}
              </Button>
            </Col>
          </Row>

        </div>
        
        <Row gutter={24} justify="space-between">
          
          <Col xs={24} sm={8} md={8} className="ant-form-vertical settings-card">
            <InfoCard
              headerLeft={<><ToolOutlined /> <strong>{t('settings.system.Configuration')}</strong></>}
            >
              <div className="pb-20">
                <Menu
                  className="settings-menu"
                  mode="inline"
                >
                  {menuConfiguration.map((item: MenuItem) => renderMenuItem(item))}
                </Menu>
              </div>
            </InfoCard>
          </Col>
          
          <Col xs={24} sm={8} md={8} className="ant-form-vertical settings-card">
            <InfoCard
              headerLeft={<><UserOutlined /> <strong>{t('settings.system.Admin')}</strong></>}
            >
              <div className="pb-20">
                <Menu
                  className="settings-menu"
                  mode="inline"
                >
                  {menuAdmin.map((item: MenuItem) => renderMenuItem(item))}
                </Menu>
              </div>
            </InfoCard>
          </Col>
          
          <Col xs={24} sm={8} md={8} className="ant-form-vertical settings-card">
            <InfoCard
              headerLeft={<><FolderOpenOutlined /> <strong>{t('settings.system.Resources')}</strong></>}
            >
              <div className="pb-20">
                <Menu
                  className="settings-menu"
                  mode="inline"
                >
                  {menuResources.map((item: MenuItem) => renderMenuItem(item))}
                </Menu>
              </div>
            </InfoCard>
          </Col>
          
        </Row>

      </div>
    </>
  );
};

export default AdminSettingsList;
