import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useReduxDispatch } from '../../../helpers'
import {
  getIsPayoutsListLoading,
  getPayoutsList,
  getPayoutsPages,
  getPayoutsTotal,
} from '../wallet.selectors'
import * as actions from '../wallet.actions'
import * as coreActions from 'src/modules/core/core.actions'

import { QTable, QPagination, Row, Col, QSelect, QRangePicker, IconsCommon, QButton } from 'quantum_components'
import { getIsoDate } from 'src/helpers/datesHelper'
import { isMobile } from 'react-device-detect'
import SpinnerCenter from 'src/components/Spinner/spinner'
import useDebounce from 'src/hooks/useDebounce'
import { IOptionForSelect } from 'src/modules/brief/brief.types'
import { IDeployment, IPayout } from '../wallet.types'
import { FORMATS } from 'src/constants'
import moment from 'moment'
import UploadComponent from 'src/components/UploadComponent'
import { getFileNameFromUrl } from 'src/utils'
import { payoutsStatuses, PAYOUT_STATUS } from 'src/constants/payouts'
import { Link, useHistory, useParams } from "react-router-dom";
import { Modal } from "antd";
import { PATHS } from "src/constants";
import { getCurrentUser } from 'src/modules/core/core.selectors'
import { fetchPartnerProfileAsync } from 'src/modules/partner/partner-Profile/Profile.actions'

const PayoutListing = () => {
  const history = useHistory();
  const { t } = useTranslation(['translationWallet', 'translationCommon'])

  const dispatch = useReduxDispatch()
  const payoutsListIsLoading = useSelector(getIsPayoutsListLoading)
  const payoutsList = useSelector(getPayoutsList)
  const payoutsPages = useSelector(getPayoutsPages)
  const payoutsTotal = useSelector(getPayoutsTotal)

  const currentUser = useSelector(getCurrentUser)

  const [partnerRequiredFieldsOpen, setPartnerRequiredFieldsOpen] = useState<boolean>(false);
  const [checkingFields, setCheckingFields] = useState<boolean>(false);
  const [dateFilter, setDateFilter] = useState({})
  const [payoutInfoModal, setPayoutInfoModal] = useState(false)
  const [statusFilter, setStatusFilter] = useState<string[]>([])
  const [tableKey, setTableKey] = useState(1)
  const [payoutsParams, setPayoutsParams] = useState({
    currentPage: 1,
    limit: 10,
    offset: 0,
    order: 'desc',
    orderBy: 'createdAt',
  })

  const debouncedDateFilter = useDebounce(dateFilter, 500)
  const debouncedStatusFilter = useDebounce(statusFilter, 500)

  const [showProfileDialog,setShowProfileDialog] = useState(false);
  const [isLoading,setIsLoading] = useState(false);
  
  const fetchPartnerDetails = async(partnerUserId:string) => {
    setIsLoading(true)

    const data = await fetchPartnerProfileAsync(partnerUserId);
    if(!data?.nationalAddress || !data?.cr || !data?.taxTreatment || (data?.taxTreatment === 'VAT Registered' && !data?.vat)){
      setShowProfileDialog(true)
    }else{
      setShowProfileDialog(false)
    }

    setIsLoading(false)
  }

  useEffect(()=>{
    if(!currentUser) return;

    fetchPartnerDetails(currentUser.partnerUserId)
  },[currentUser])

  useEffect(() => {
    fetchPayoutsForFilters()
    dispatch(actions.setPayoutsCurrentPage(1))
  }, [debouncedDateFilter, debouncedStatusFilter])

  const fetchPayoutsForFilters = (queryParams = payoutsParams) => {
    dispatch(actions.fetchPayoutsList({
      ...queryParams,
      currentPage: 1,
      limit: 10,
      offset: 0,
      ...debouncedDateFilter,
      status: debouncedStatusFilter,
    }))
  }

  const fetchPayouts = (queryParams = payoutsParams) => {
    dispatch(
      actions.fetchPayoutsList({
        ...queryParams,
        ...debouncedDateFilter,
        status: debouncedStatusFilter,
      })
    )
  }

  // @ts-ignore
  const handlePayoutTableChange = (pagination, filters, sorter) => {
    const sortingParams = {
      order: sorter.order.slice(0, -3),
      orderBy: sorter.columnKey,
    }
    const newParams = {
      ...payoutsParams,
      ...sortingParams,
    }
    setPayoutsParams(newParams)
    fetchPayouts(newParams)
  }

  const handlePayoutChangePage = (page: number, pageSize: number) => {
    dispatch(actions.setPayoutsCurrentPage(page))

    dispatch(
      actions.fetchPayoutsList({
        ...payoutsParams,
        offset: (page - 1) * payoutsParams.limit,
        currentPage: page,
        limit: pageSize,
        ...debouncedDateFilter,
        status: debouncedStatusFilter,
      })
    )

    setPayoutsParams({
      ...payoutsParams,
      offset: (page - 1) * payoutsParams.limit,
      currentPage: page,
      limit: pageSize,
    })
  }

  const payoutsColumns = [
    {
      title: t('Date'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      width: '15%',
      render: (text: string) => {
        return moment(text).format(FORMATS.DATE_LL_FORMAT)
      },
    },
    {
      title: t('PO'),
      dataIndex: 'PO',
      key: 'PO',
      width: '25%',
      render: (text: string[], record: IPayout) => {
        return (
          <div className="q-table-product">
            {text && !!text.length && <div className="q-table-product_info">
              <h3 className="q-table-product_title">
                <div onClick={(event: React.MouseEvent<HTMLDivElement>) => event.stopPropagation()}>
                  <UploadComponent
                    viewOnly
                    viewOnlyText={t('common.View', { ns: 'translationCommon' })}
                    alternativeFileView
                    dragAndDrop={false}
                    files={text?.map(url => ({ url, name: getFileNameFromUrl(url) })) ?? []}
                    onView={async file => {
                      const response: any = await dispatch(coreActions.downloadImage(file.url))
                      const url = URL.createObjectURL(response.data)
                      window.open(url, '_blank')
                      URL.revokeObjectURL(url)
                    }}
                  />
                </div>
              </h3>
              <div className="q-table-product_created">
                {t(`Generated on`)} {moment(record.poDate).format(FORMATS.DATE_LL_FORMAT)}
              </div>
            </div>}
          </div>
        )
      },
    },
    {
      title: t('Invoice'),
      dataIndex: 'invoice',
      key: 'invoice',
      width: '25%',
      render: (text: string[], record: IPayout) => {
        return (
          <div className="q-table-product">
            {
              text && !!text.length
                ? <div className="q-table-product_info">
                  <h3 className="q-table-product_title">
                    <div onClick={(event: React.MouseEvent<HTMLDivElement>) => event.stopPropagation()}>
                      <UploadComponent
                        viewOnly
                        viewOnlyText={t('common.View', { ns: 'translationCommon' })}
                        alternativeFileView
                        dragAndDrop={false}
                        files={text?.map(url => ({ url, name: getFileNameFromUrl(url) })) ?? []}
                        onView={async file => {
                          const response: any = await dispatch(coreActions.downloadImage(file.url))
                          const url = URL.createObjectURL(response.data)
                          window.open(url, '_blank')
                          URL.revokeObjectURL(url)
                        }}
                      />
                    </div>
                  </h3>
                  <div className="q-table-product_created">
                    {t(`Uploaded on`)} {moment(record.invoiceDate).format(FORMATS.DATE_LL_FORMAT)}
                  </div>
                </div>
                : record.status !== PAYOUT_STATUS.EXPIRED &&
                <UploadComponent
                  multiple
                  dragAndDrop={false}
                  maxCount={1}
                  accept={'.pdf, .jpg, .jpeg, .png'}
                  caption={['You can upload only 1 file', 'Accepted file formats are: pdf, png, jpg, jpeg']}
                  files={[]}
                  onChange={(files: File[]) => { handleUpload(files, record.id) }}
                />
            }
          </div>
        )
      },
    },
    {
      title: t('Status'),
      dataIndex: 'status',
      key: 'status',
      width: '15%',
      render: (text: PAYOUT_STATUS) => {
        const statusClass = payoutsStatuses[text]?.classStyle ?? "";
        const statusLabel = payoutsStatuses[text]?.label ?? "Pending";
        return (
          <div>
            <span className={`status-label ${statusClass}`}>{t(`${statusLabel}`)}</span>
          </div>
        )
      }
    },
    {
      title: t('Payout Completed Date'),
      dataIndex: 'payoutDate',
      key: 'payoutDate',
      width: '20%',
      render: (text: string) => {
        return text && moment(text).format(FORMATS.DATE_LL_FORMAT)
      },
    },
  ]

  const payoutStatusOptions: IOptionForSelect[] = [
    {
      label: t('Pending Invoice'),
      value: 'PENDING INVOICE',
    },
    {
      label: t('Processing'),
      value: 'PROCESSING',
    },
    {
      label: t('Complete'),
      value: 'COMPLETE',
    },
    {
      label: t('Expired'),
      value: 'EXPIRED',
    }
  ]

  const handleRangePicker = (dates: moment.Moment[] | null) => {
    dispatch(actions.setPayoutsCurrentPage(1))

    if (!dates) {
      setDateFilter({})
      return
    }

    let formattedDates = { ...dateFilter }

    formattedDates = { start: getIsoDate(dates[0].startOf('day')) }
    if (dates[1]) formattedDates = { ...formattedDates, end: getIsoDate(dates[1].endOf('day')) }

    setDateFilter(formattedDates)
  }

  const handleChangeSelectStatus = (status: string[]) => {
    dispatch(actions.setPayoutsCurrentPage(1))
    setStatusFilter(status)
  }

  const handleUpload = async (uploads: File[], id: number) => {
    setCheckingFields(true);
    const res: any = await dispatch(actions.validatePartnerRequiredFields());
    if (res) {
      setCheckingFields(false);
      if (res?.missingInfo) {
        setPartnerRequiredFieldsOpen(true);
      } else {
        const res1: any = await dispatch(actions.uploadPayoutInvoice(id, uploads));
        if (res1?.status === 200 || res1?.status === 201) fetchPayouts()
      }
    }

  };

  const profileDialog = () => {
    return <>
        <Modal
        visible={showProfileDialog}
        closable={false}
        footer={
          [
            <QButton htmlType='button' size='small' type='primary' onClick={()=>history.push('/users/partners/partner-profile')}>{t(`Edit Profile`)}</QButton>
          ]
        }
        title={t(`Incomplete Profile`)}
      >
        <p>
          {t('update_sales_section')}
        </p>
      </Modal> 
    </>
  }

  return (
    <>
      {profileDialog()}
      {isLoading && <SpinnerCenter></SpinnerCenter>}
      {payoutsListIsLoading || checkingFields && <SpinnerCenter />}
      <Link
        to={``}
        onClick={(e)=> {
          e.preventDefault()
          e.stopPropagation()
          setPayoutInfoModal(true)
        }}
        style={{ textDecoration: "underline" }}
      >
        <div className="ml-1">
          {t("What are Payouts")}
        </div>
      </Link>
      <Row
        className={'pt-15'}
        justify={'space-between'}
      >
        <Col className="qt-filter">
          <QRangePicker
            placeholder={[t("Start date"), t("End date")]}
            size="large"
            className={`qu-datepicker ${isMobile && "mb-5"}`}
            onChange={handleRangePicker}
            format={FORMATS.DATE_FORMAT}
            allowEmpty={[false, true]}
          />
        </Col>
        <Col className="qt-filter">
          <QSelect
            className={`${isMobile && "mb-5"}`}
            showArrow
            mode="multiple"
            onChange={handleChangeSelectStatus}
            size="large"
            maxTagCount={2}
            maxTagTextLength={7}
            options={payoutStatusOptions}
            placeholder={t('common.Filter_by_status', { ns: 'translationCommon' })}
          />
        </Col>
      </Row>

      <div className="pt-15 pb-30">
        <QTable
          columns={payoutsColumns}
          dataSource={payoutsList}
          scroll={{ x: 990 }}
          // @ts-ignore
          onChange={handlePayoutTableChange}
          sortDirections={['ascend', 'descend', 'ascend']}
        />
      </div>
      <Row
        justify={isMobile ? "center" : "end"}
        className={`${isMobile ? "pt-15 pb-30" : "pb-20"}`}
      >
        <QPagination
          className="force-ltr"
          size="default"
          defaultCurrent={1}
          current={payoutsPages.currentPage}
          total={payoutsTotal}
          onChange={handlePayoutChangePage}
          showSizeChanger
        />
      </Row>

      <Modal
        key={tableKey}
        wrapClassName="qu-modal"
        closeIcon={<IconsCommon.IconClose />}
        width={800}
        title={t("What are Payouts")}
        visible={payoutInfoModal}
        footer={null}
        onOk={()=>{
          setTableKey(tableKey+1);
          setPayoutInfoModal(false);
        }}
        onCancel={() => {
          setTableKey(tableKey+1);
          setPayoutInfoModal(false)
        }}
      >
        <p
          className={"p-16"}
          dangerouslySetInnerHTML={{__html: t("What are Payouts Description")}}>
        </p>
        <div className="grid-row grid-row--jcc">
          <div className="grid-col grid-col--auto">
            <QButton
              onClick={()=> {
                setTableKey(tableKey+1);
                setPayoutInfoModal(false)
              }}
              type="primary" block className={'w-200'}>
              { t('common.OKAY', { ns: 'translationCommon' })}
            </QButton>
          </div>
        </div>
      </Modal>

      {partnerRequiredFieldsOpen && (

      <Modal
        wrapClassName="qu-modal"
        title={<h3>{t("Incomplete Profile")}</h3>}
        // width={400}
        closeIcon={<IconsCommon.IconClose />}
        open={partnerRequiredFieldsOpen}
        onCancel={() => setPartnerRequiredFieldsOpen(false)}
        footer={false}
      >
        <div>
          <p className="mb-25">
            {t("Last thing before")}
          </p>
          <div className="grid-row grid-row--smg grid-row--jce">
            <div className="grid-col grid-col--auto">
              <QButton onClick={() => history.push(PATHS.PARTNER_PROFILES)} type="primary">
                {t("Edit Profile")}
              </QButton>
            </div>
            <div className="grid-col grid-col--auto">
              <QButton
                className="qu-button-outline"
                onClick={() => {console.log(123); setPartnerRequiredFieldsOpen(false);}}
              >
                {t("Cancel")}
              </QButton>
            </div>
          </div>
        </div>
      </Modal>
      )}
    </>
  )
}

export default PayoutListing
