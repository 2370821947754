/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { QInput, QPagination, QTable, Row } from 'quantum_components'
import { Col, Modal, Tooltip } from 'antd'
import { CheckCircleTwoTone, CloseCircleTwoTone, SearchOutlined } from '@ant-design/icons'
import { useReduxDispatch } from '../../../../helpers'
import * as actions from '../invitedClients.actions'
import { ROLES } from 'src/constants'
import { useSelector } from 'react-redux'
import { getRequestsList, getRequestsListLoading, getTotalRequestsList } from '../invitedClients.selectors'
import { IRequestsListElement } from '../invitedClients.types'
import useDebounce from '../../../../hooks/useDebounce'
import { getCurrentUserPermissionActions } from '../../../core/core.selectors'
import { hasPermission } from '../../../../helpers/permission'
import { PERMISSIONS } from '../../../../constants/permission'

const APPROVE_ACTION = 'APPROVE'
const DECLINE_ACTION = 'DECLINE'

const InvitedClientsList = () => {
  const dispatch = useReduxDispatch()
  const history = useHistory()

  const requestsList = useSelector(getRequestsList)
  const requestsListTotal = useSelector(getTotalRequestsList)
  const requestsListIsLoading = useSelector(getRequestsListLoading)
  const permissions = useSelector(getCurrentUserPermissionActions)

  const requestUserRole = history.location.pathname.includes('partners') ? ROLES.PARTNER : ROLES.CLIENT

  const [params, setParams] = useState({
    currentPage: 1,
    offset: 0,
    limit: 10,
    order: 'desc',
    orderBy: 'createdAt',
  })

  const [modalApprove, setModalApprove] = useState({ visible: false, id: null })
  const [modalDecline, setModalDecline] = useState({ visible: false, id: null })

  const [tableKey, setTableKey] = useState(1)
  const [tableKey1, setTableKey1] = useState(100)
  const [search, setSearch] = useState('')

  const debouncedSearch = useDebounce(search, 500)

  const fetchRequestsList = (queryParams = params) => {
    dispatch(actions.fetchRequestsList({ ...queryParams, search: debouncedSearch }))
  }

  useEffect(() => {
    fetchRequestsList()
  }, [debouncedSearch])

  const handleOpenModal = (record: IRequestsListElement, action: string) => {
    const modalToOpenSetter = action === APPROVE_ACTION ? setModalApprove : setModalDecline
    modalToOpenSetter({ visible: true, id: record.id })
  }

  const handleCloseModals = () => {
    setModalApprove({ visible: false, id: null })
    setModalDecline({ visible: false, id: null })
    setTableKey(tableKey + 1)
    setTableKey1(tableKey1 + 1)
  }

  const handleSubmitModal = async (action: string, id: number) => {
    const actionName = action === APPROVE_ACTION ? actions.approveRequest : actions.declineRequest

    handleCloseModals()
    await dispatch(actionName(id))
    if (action === APPROVE_ACTION) {
      fetchRequestsList()
    }
  }

  const handleTableChange = (pagination, filters, sorter) => {
    const sortingParams = {
      order: sorter.order.slice(0, -3),
      orderBy: sorter.columnKey,
    }
    const newParams = {
      ...params,
      ...sortingParams,
    }
    setParams(newParams)
    fetchRequestsList(newParams)
  }

  const handleChangeSearch = e => {
    setSearch(e.currentTarget.value)
  }

  const handleChangePage = (page: number, pageSize: number) => {
    dispatch(
      actions.fetchRequestsList({ ...params, offset: (page - 1) * params.limit, currentPage: page, limit: pageSize })
    )
  }

  const columns = [
    {
      title: 'Full name',
      dataIndex: 'fullname',
      key: 'firstName',
      fixed: 'left',
      sorter: true,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      fixed: 'left',
      sorter: true,
    },
    {
      title: 'Company name',
      dataIndex: 'companyName',
      key: 'companyName',
      fixed: 'left',
      sorter: true,
    },
    {
      title: 'Phone number',
      dataIndex: 'phone',
      key: 'phone',
      fixed: 'left',
      sorter: true,
    },
    {
      title: 'Invited By',
      dataIndex: 'invite',
      key: 'invite',
      fixed: 'left',
      render: invite => <div>{invite[0].partner.companyName}</div>,
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      fixed: 'left',
      sorter: true,
    },
    {
      title: 'Email Verified',
      dataIndex: 'status',
      key: 'status',
      fixed: 'left',
      sorter: true,
      render: (text, record) => <div>{record.status === 'ACTIVE' ? 'Verified' : 'Not verified'}</div>,
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'services',
      render: (text, record) => {
        return (
          <div>
            {record.status !== 'REJECTED' ? (
              <div>
                {hasPermission(permissions, PERMISSIONS.ACCEPT_INVITED_CLIENT) && (
                  <Tooltip title="Approve Request">
                    <CheckCircleTwoTone
                      style={{ fontSize: '20px', marginRight: '7px' }}
                      onClick={() => handleOpenModal(record, APPROVE_ACTION)}
                    />
                  </Tooltip>
                )}
                {hasPermission(permissions, PERMISSIONS.REJECT_INVITED_CLIENT) && (
                  <Tooltip title="Decline Request">
                    <CloseCircleTwoTone
                      style={{ fontSize: '20px' }}
                      onClick={() => handleOpenModal(record, DECLINE_ACTION)}
                    />
                  </Tooltip>
                )}
              </div>
            ) : (
              <span> Declined </span>
            )}
          </div>
        )
      },
    },
  ]

  return (
    <div className="qu-brief-list">
      <Row className={`pt-10 brief-list-header`} justify="space-between">
        <h2 className="pb-10">{requestUserRole === ROLES.PARTNER ? 'Partners' : 'Clients'}</h2>
      </Row>
      <Row justify="space-between">
        <Col className="qt-search">
          <QInput
            value={search}
            onChange={handleChangeSearch}
            placeholder="Search"
            size="large"
            prefix={<SearchOutlined />}
          />
        </Col>
        <Col>
          <h5 className="pb-10">
            Total available {requestUserRole === ROLES.PARTNER ? 'partner' : 'client'} requests: {requestsListTotal}
          </h5>
        </Col>
      </Row>
      <div className="pt-15 pb-30">
        <QTable
          columns={columns}
          dataSource={requestsList}
          scroll={{ x: 990 }}
          rowClassName="q-table"
          loading={requestsListIsLoading}
          onChange={handleTableChange}
          sortDirections={['ascend', 'descend', 'ascend']}
        />
      </div>

      <Modal
        key={tableKey}
        title="Are you sure?"
        visible={modalApprove.visible}
        onOk={() => handleSubmitModal(APPROVE_ACTION, modalApprove.id)}
        onCancel={() => {
          setModalApprove({ visible: false, id: null })
          setTableKey(tableKey + 1)
        }}
      >
        <p>Do you want to approve the user request?</p>
      </Modal>

      <Modal
        key={tableKey1}
        title="Are you sure?"
        visible={modalDecline.visible}
        onOk={() => handleSubmitModal(DECLINE_ACTION, modalDecline.id)}
        onCancel={() => {
          setModalDecline({ visible: false, id: null })
          setTableKey1(tableKey1 + 1)
        }}
      >
        <p>Do you want to decline the user request?</p>
      </Modal>
      <Row justify="end">
        <QPagination size="small" total={requestsListTotal} onChange={handleChangePage} hideOnSinglePage />
      </Row>
    </div>
  )
}

export default InvitedClientsList
