import get from 'lodash/get'
import { IState } from './wallet.types'
import * as CONSTANTS from './wallet.constants'
import { CURRENCY } from 'src/constants/currencies'

const initialState: IState = {
  walletListLoading: false,
  walletDetails: {
    id: 0,
    deploymentId: 0,
    balance: "",
    totalPaidOut: "",
    totalRevenue: "",
    createdAt: "",
    updatedAt: "",
    updatedBy: 0,
    payoutMinimum: "",
    history: {
      results: [],
      total: 0
    }
  },
  pages: {
    totalPages: 1,
    currentPage: 1,
  },
  payoutsListLoading: false,
  payoutsList: {
    results: [],
    total: 0,
  },
  payoutsPages: {
    totalPages: 1,
    currentPage: 1,
  }
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case CONSTANTS.RESET_WALLET_STATE:
      return initialState

    case CONSTANTS.FETCH_WALLET_REQUEST:
      return {
        ...state,
        walletListLoading: true,
      }
    case CONSTANTS.FETCH_WALLET_SUCCESS:
      const walletDetails = action.payload
      return {
        ...state,
        walletDetails: walletDetails,
        walletListLoading: false,
      }
    case CONSTANTS.FETCH_WALLET_FAILURE:
      return {
        ...state,
        walletListLoading: false,
      }
    case CONSTANTS.SET_CURRENT_PAGE:
      return {
        ...state,
        pages: {
          ...state.pages,
          currentPage: get(action, 'payload.page'),
        },
      }
    case CONSTANTS.FETCH_PAYOUTS_REQUEST:
      return {
        ...state,
        payoutsListLoading: true,
      }
    case CONSTANTS.FETCH_PAYOUTS_SUCCESS:
      const payouts = action.payload
      return {
        ...state,
        payoutsList: payouts,
        payoutsListLoading: false,
      }
    case CONSTANTS.FETCH_PAYOUTS_FAILURE:
      return {
        ...state,
        payoutsListLoading: false,
      }
    case CONSTANTS.SET_PAYOUTS_CURRENT_PAGE:
      return {
        ...state,
        payoutsPages: {
          ...state.payoutsPages,
          currentPage: get(action, 'payload.page'),
        },
      }
    case CONSTANTS.PAYOUTS_LOADING_REQUEST:
      return {
        ...state,
        payoutsListLoading: true,
      }
    case CONSTANTS.PAYOUTS_LOADING_SUCCESS:
      return {
        ...state,
        payoutsListLoading: false,
      }
    default:
      return state
  }
}
