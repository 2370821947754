import React from 'react'
import { QFileUploader } from 'quantum_components'
import IUploadFile from '../../interfaces/IUploadFile'

interface Props {
  files: IUploadFile[] | any
  onChange: (info: any) => void
  onRemove: (file: IUploadFile) => void
  isUploading?: boolean
  multiple?: boolean
  accept?: string
  caption?: string[]
  disabled?: boolean,
  heading?:string,
  showRemoveButton?: boolean
}

const SimpleUploadComponent = ({
  files,
  onChange,
  onRemove,
  heading,
  isUploading = false,
  multiple = false,
  accept = '.jpg, .png',
  disabled = false,
  caption = ['Maximum dimension: 800x800.', 'Max file size 1MB in JPG, JPEG, or PNG format.'],
  showRemoveButton = true,
}: Props) => {
  const handleRemove = (file: IUploadFile) => {
    onRemove(file)
  }

  const uploadProps = {
    maxCount: 1,
    accept: accept,
    listType: 'picture',
    fileList: files,
    showUploadList: {
      showRemoveIcon: showRemoveButton,
      removeIcon: <span>Remove</span>,
    },
    multiple: multiple,
    onChange: onChange,
    onRemove: handleRemove,
    beforeUpload: () => false,
    caption: caption,
    isUploading: isUploading,
    disabled: disabled,
    heading
  }

  return <QFileUploader {...uploadProps} />
}

export default SimpleUploadComponent
