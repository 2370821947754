import React from 'react'
import { Col, Radio, Row } from 'quantum_components'
import { useSelector } from 'react-redux'

import { PARTNER_TABS } from 'src/constants/tabs'
import { PERMISSIONS } from 'src/constants/permission'

import { hasPermission } from 'src/helpers/permission'
import { getCurrentUserPermissionActions } from 'src/modules/core/core.selectors'
import { useTranslation } from 'react-i18next'

interface Props {
  onChange: (event: any) => void
  activeTab: PARTNER_TABS
}
const SwitchProfiles = ({ onChange, activeTab }: Props) => {
  const {t}=useTranslation(['translationProfile'])
  const permissions = useSelector(getCurrentUserPermissionActions)

  return (
    <Row gutter={16} justify="center">
      <Col sm={24} md={8}>
        <div className="mb-5 mt-10">
          <Radio.Group className="qu-radio-panel" defaultValue={activeTab} onChange={onChange} buttonStyle="solid" style={{width: "179px"}}>
            <Radio.Button className="qu-radio-button" value={PARTNER_TABS.PARTNER_PROFILE}>
              <span className="text-uppercase">{t(`profile.partner.title`)}</span>
            </Radio.Button>
            <Radio.Button className="qu-radio-button" value={PARTNER_TABS.PUBLIC_PROFILE}>
              <span className="text-uppercase">{t(`profile.public.title`)}</span>
            </Radio.Button>
            {/* <Radio.Button
              className="qu-radio-button"
              value={PARTNER_TABS.BILLINGS}
              disabled={!hasPermission(permissions, PERMISSIONS.PARTNER_BILLS_ALL)}
            >
              <span className="text-uppercase">Billings</span>
            </Radio.Button> */}
          </Radio.Group>
        </div>
      </Col>
    </Row>
  )
}

export default SwitchProfiles
