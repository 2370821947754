import React, { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Row, QSelectImage, QSelect, QRangePicker, QPagination, Empty } from 'quantum_components'

import { FORMATS } from '../../../../../constants'
import { actions } from '../../../campaign.module'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  getCampaignGalleryList,
  getCampaignGalleryLoading,
  getCampaignGalleryParams,
  getCampaignPartners,
  getCampaignUpdating,
  getGalleryPages,
  getTotalCampaignGalleryList,
} from '../../../campaign.selectors'
import { ICampaignGalleryItem, ICampaignPartner } from '../../../campaign.types'
import { MEDIA_GROUP_TYPE } from '../../../../../constants/brief'
import { Modal } from 'antd'
import { CAMPAIGN_PARTNERS, CAMPAIGN_PARTNERS_NAMES, GALLERY_PER_PAGE } from '../../../../../constants/campaign'
import SpinnerComponent from '../../../../../components/Spinner/SpinnerComponent'
import { getIsoDate } from '../../../../../helpers/datesHelper'
import moment from 'moment'
import GalleryImageModal from './GalleryImageModal'

interface IProps {
  disabledButton: boolean
}

const MAX_CAMPAIGN_GALLERY_ITEMS = 1000

export default function CampaignGalleryList(): ReactElement {
  const { i18n } = useTranslation()
  const { t } = useTranslation(['translationCampaign', 'translationCommon'])
  const dispatch = useDispatch()
  const { id } = useParams<{id: any}>()
  const [deleteModal, setDeleteModal] = useState<boolean>(false)
  const [activeImage, setActiveImage] = useState<ICampaignGalleryItem | null>(null)
  const [activeImagePreview, setActiveImagePreview] = useState<ICampaignGalleryItem | null>(null)
  const [activeIndex, setActiveIndex] = useState<number | null>(null)
  const [tableKey, setTableKey] = useState(1)

  const galleryList = useSelector(getCampaignGalleryList)
  const params = useSelector(getCampaignGalleryParams)
  const partners = useSelector(getCampaignPartners)
  const loading = useSelector(getCampaignGalleryLoading)
  const pages = useSelector(getGalleryPages)
  const total = useSelector(getTotalCampaignGalleryList)
  const updating = useSelector(getCampaignUpdating)

  const fetchGallery = async () => {
    await dispatch(actions.setParamsAndFetchGallery(id, { ...params, offset: 0, limit: GALLERY_PER_PAGE }))
  }

  useEffect(() => {
    fetchGallery()
  }, [])

  useEffect(() => {
    const index = activeImagePreview ? galleryList.indexOf(activeImagePreview) : null
    setActiveIndex(index)
  }, [activeImagePreview])

  const handleDelete = async () => {
    if (!activeImage || updating) return
    await dispatch(actions.removeCampaignGalleryImage(activeImage.imageUrl))
    fetchGallery()

    setActiveImage(null)
    setDeleteModal(false)
    setTableKey(Math.floor(Math.random() * 1000 + 1))
  }

  const handlePartner = async (partnerIds: number[]) => {
    await dispatch(actions.setParamsAndFetchGallery(id, { ...params, offset: 0, limit: GALLERY_PER_PAGE, partnerIds }))
  }

  const handleDatePicker = async (range: any) => {
    const start = range && getIsoDate(range[0].startOf('day'))
    const end = range && getIsoDate(range[1].endOf('day'))

    const newParams = { ...params, offset: 0, start, end }
    await dispatch(actions.setParamsAndFetchGallery(id, newParams))
  }

  const handleChangePage = async (page: number, pageSize: number) => {
    await dispatch(actions.setGalleryCurrentPage(page))
    await dispatch(actions.setParamsAndFetchGallery(id, { ...params, offset: (page - 1) * pageSize, limit: pageSize }))
  }

  const handleDeleteModalOpen = (item: ICampaignGalleryItem) => {
    setActiveImage(item)
    setDeleteModal(true)
  }

  const handleDeleteModalClose = () => {
    setDeleteModal(false)
    setActiveImage(null)
    setTableKey(Math.floor(Math.random() * 1000 + 1))
  }

  const handleOpenImageModal = (item: ICampaignGalleryItem) => {
    setActiveImagePreview(item)
  }

  const handleCloseImageModal = () => {
    setActiveImagePreview(null)
  }

  const handleLeft = () => {
    if (!activeIndex) return
    const prev = galleryList[activeIndex - 1]
    setActiveImagePreview(prev)
  }

  const handleRight = () => {
    if (activeIndex === null) return
    if (activeIndex === galleryList.length - 1) return
    const next = galleryList[activeIndex + 1]
    setActiveImagePreview(next)
  }

  const imageCard = (item: ICampaignGalleryItem) => (
    <div className="mb-8">
      <QSelectImage
        checked={false}
        onChange={() => handleOpenImageModal(item)}
        onDelete={() => handleDeleteModalOpen(item)}
        title={item.title}
        text={item.description}
        date={moment(item.startedDate).format(FORMATS.DATE_LL_FORMAT)}
        cardImage={item.imageUrl}
        cardType="checkbox"
        inputName="card"
      />
    </div>
  )

  const partnerOptions = partners.map((partner: ICampaignPartner) => {
    const distributionType = partner.requestTypes.includes(MEDIA_GROUP_TYPE.PRODUCT_SAMPLE)
      ? MEDIA_GROUP_TYPE.PRODUCT_SAMPLE
      : MEDIA_GROUP_TYPE.PRINTED_INSERT
    return { label: `${partner.companyName} - ${distributionType}`, value: partner.id }
  })

  const partnerAllOptions = [
    { label: CAMPAIGN_PARTNERS_NAMES[CAMPAIGN_PARTNERS.QUANTUM_TEAM], value: CAMPAIGN_PARTNERS.QUANTUM_TEAM },
    { label: CAMPAIGN_PARTNERS_NAMES[CAMPAIGN_PARTNERS.ALL_PARTNERS], value: CAMPAIGN_PARTNERS.ALL_PARTNERS },
    ...partnerOptions,
  ]

  return (
    <div className="p-16">
      <Row justify="end" gutter={24}>
        {!!total && (
          <Col xs={24} md={6}>
            <p style={{ lineHeight: '40px', fontWeight: 500 }}>
              <span className="text-primary">{MAX_CAMPAIGN_GALLERY_ITEMS - total}</span> of{' '}
              <span className="text-primary">{MAX_CAMPAIGN_GALLERY_ITEMS}</span> left
            </p>
          </Col>
        )}
        <Col xs={24} md={10}>
          <QRangePicker
            onChange={handleDatePicker}
            className="full-width mb-20"
            format={FORMATS.DATE_FORMAT}
            size="large"
          />
        </Col>
        {/*<Col xs={24} md={8}>
          <QSelect
            mode="multiple"
            maxTagCount={1}
            maxTagTextLength={8}
            className="full-width mb-20"
            options={partnerAllOptions}
            onChange={handlePartner}
            size="large"
            placeholder="Select Partner"
            showArrow
          />
        </Col>*/}
      </Row>
      {loading ? (
        <div className="gallery_spinner">
          <SpinnerComponent />
        </div>
      ) : !galleryList.length ? (
        <Empty />
      ) : (
        <Row gutter={8}>
          {galleryList.map((item: ICampaignGalleryItem, i: number) => (
            <Col key={i} xs={12} md={6}>
              {imageCard(item)}
            </Col>
          ))}
        </Row>
      )}
      <div className="qt-footer">
        <QPagination
          size="default"
          defaultCurrent={1}
          current={pages.currentPage}
          defaultPageSize={GALLERY_PER_PAGE}
          total={total}
          onChange={handleChangePage}
        />
      </div>
      <Modal key={tableKey + 1} title="" visible={deleteModal} onOk={handleDelete} onCancel={handleDeleteModalClose}
        okText={t('common.OKAY', {ns: 'translationCommon'})}
        cancelText={t('common.Cancel', {ns: 'translationCommon'})}
      >
        <p>
          {t('edit.Do you want to delete', {image:activeImage?.title})}
        </p>
      </Modal>
      {activeImagePreview && (
        <GalleryImageModal
          modalOpen={!!activeImagePreview}
          title={activeImagePreview.title}
          imgUrl={activeImagePreview.imageUrl}
          description={activeImagePreview.description}
          uploadedOn={moment(activeImagePreview.startedDate).format(FORMATS.DATE_LL_FORMAT)}
          onClose={handleCloseImageModal}
          onLeftClick={handleLeft}
          onRightClick={handleRight}
          showLeftArrow={activeIndex !== 0}
          showRightArrow={activeIndex !== galleryList.length - 1}
        />
      )}
    </div>
  )
}
