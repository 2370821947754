import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { AxiosResponse } from 'axios'
import IAction from 'src/interfaces/IAction'

import * as CONSTANTS from './subscriptionHistory.constants'
import { requestHttp, urls } from 'src/api'
import { getResponseErrorMessage } from 'src/helpers'
import { ISubscription, ISubscriptionHistory, ISubscriptionHistoryParams } from './subscriptionHistory.types'

export const resetSubscriptionHistoryState = (): IAction => ({
    type: CONSTANTS.RESET_SUBSCRIPTION_HISTORY_STATE,
})

export const subscriptionHistoryListRequest = (): IAction => ({
    type: CONSTANTS.FETCH_SUBSCRIPTION_HISTORY_REQUEST,
})

export const subscriptionHistoryListSuccess = (subscriptionHistoryList: { latestSubscription: ISubscription, results: ISubscriptionHistory[]; total: number }): IAction => ({
    type: CONSTANTS.FETCH_SUBSCRIPTION_HISTORY_SUCCESS,
    payload: subscriptionHistoryList,
})

export const subscriptionHistoryListFailure = (error: string): IAction => ({
    type: CONSTANTS.FETCH_SUBSCRIPTION_HISTORY_FAILURE,
    error,
})

export const setCurrentPage = (page: number): IAction => ({
    type: CONSTANTS.SET_CURRENT_PAGE,
    payload: { page },
})

export const fetchSubscriptionHistoryList = (params: ISubscriptionHistoryParams): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse> => {
    try {
        dispatch(subscriptionHistoryListRequest())
        const response = await requestHttp.get(urls.getSubscriptionHistoryListUrl(), { params })
        const { content } = response.data.data
        dispatch(subscriptionHistoryListSuccess(content))
        return response
    } catch (error: any) {
        dispatch(subscriptionHistoryListFailure(getResponseErrorMessage(error)))
        return error
    }
}

export const cancelSubscriptionRequest = (): IAction => ({
    type: CONSTANTS.CANCEL_SUBSCRIPTION_REQUEST,
})

export const cancelSubscriptionSuccess = (): IAction => ({
    type: CONSTANTS.CANCEL_SUBSCRIPTION_SUCCESS,
})

export const cancelSubscriptionFailure = (error: string): IAction => ({
    type: CONSTANTS.CANCEL_SUBSCRIPTION_FAILURE,
    error
})

export const getCancelSubscription = (payload: { id: number, action: string }): ThunkAction<void, {}, {}, AnyAction> => async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
        dispatch(cancelSubscriptionRequest())
        await requestHttp.post(urls.getCancelSubscriptionUrl(), { ...payload })
        dispatch(cancelSubscriptionSuccess())
    } catch (error: any) {
        dispatch(cancelSubscriptionFailure(getResponseErrorMessage(error)))
        return error
    }
}
