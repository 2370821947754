import React, { useEffect, useState } from "react";
import {
  Col,
  Form,
  IconsCommon,
  Modal,
  QButton,
  QDatePicker,
  QInput,
  qnotification,
  QSelect,
  QTextArea,
  Radio,
  Row,
  ServicesSummary,
} from "quantum_components";
import {
  REQUEST_ACTIONS,
  REQUEST_STATUS,
} from "../../../../../constants/request";
import { useSelector } from "react-redux";
import { IOptionForSelect } from "../../../../brief/brief.types";
import { useReduxDispatch } from "../../../../../helpers";
import { actions } from "../../request.module";
import * as promotionActions from "../../../../promotions/promotions.actions";
import * as briefActions from "../../../../brief/brief.actions";
import { useHistory } from "react-router-dom";
import { getRequestDetails, getUpdatingRequest } from "../../request.selectors";
import { getPartnerSettings } from "../../../../users/users.selectors";
import { TABS_NAMES } from "../../../../../constants/tabs";
import {
  getCurrentUser,
  getCurrentUserPermissionActions,
} from "../../../../core/core.selectors";
import { hasPermission } from "../../../../../helpers/permission";
import { PERMISSIONS } from "../../../../../constants/permission";
import SpinnerCenter from "../../../../../components/Spinner/spinner";
import { getAllWorkflows } from "src/modules/workflow/workflow.selectors";
import * as workflowActions from "src/modules/workflow/workflow.actions";
import { FORMATS, NOTIFICATION_TYPES } from "src/constants";
import { fetchRequestDetails, processWorkflow } from "../../request.actions";
import {
  CheckCircleTwoTone,
  ExclamationCircleTwoTone,
} from "@ant-design/icons";
import DialogModal from "src/components/DialogModal/DialogModal";
import { Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment";
import SimpleUploadComponent from "src/components/SimpleUploadComponent";
import { validateRasterImage } from "src/helpers/validation";
import { FileTwoTone } from "@ant-design/icons";
import { BRIEF_PAYMENT_STATUS, BRIEF_STATUS } from "src/constants/brief";

const closeIcon = <IconsCommon.IconClose />;

const acceptIcon = (
  <svg
    height="14"
    viewBox="0 0 15 14"
    width="15"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 0c.8 0 1.6.1 2.4.4.5.2.8.8.6 1.3s-.8.8-1.3.6C8.1 2.1 7.6 2 7 2 4.2 2 2 4.2 2 7s2.2 5 5 5 5-2.2 5-5c0-.6.4-1 1-1s1 .4 1 1c0 3.9-3.1 7-7 7s-7-3.1-7-7 3.1-7 7-7zm6.3.3c.4-.4 1-.4 1.4 0s.4 1 0 1.4l-7 7c-.2.2-.4.3-.7.3s-.5-.1-.7-.3l-2-2c-.4-.4-.4-1 0-1.4s1-.4 1.4 0L7 6.6z"
      fill="#37c95a"
    />
  </svg>
);

const rejectIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 24 24">
    <path
      fill="currentColor"
      d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"
    />
  </svg>
);

const OTHER = "Other";

export interface Props {
  requestId: number;
  briefId: number;
}

const RequestForm = ({ requestId, briefId }: Props) => {
  const { t } = useTranslation(["translationABrief"]);

  const history = useHistory();
  const dispatch = useReduxDispatch();
  const [form] = Form.useForm();
  const workflows = useSelector(getAllWorkflows);
  const settings = useSelector(getPartnerSettings);
  const requestDetails = useSelector(getRequestDetails);
  const updatingRequest = useSelector(getUpdatingRequest);
  const permissions = useSelector(getCurrentUserPermissionActions);
  const currentUser = useSelector(getCurrentUser);
  const [workflowOps, setWorkflowOps] = useState<any>([]);
  const [showModal, setShowModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showWorkflowExtrasModal, setShowWorkflowExtrasModal] = useState(false);
  const [activeWorkflowId, setActiveWorkflowId] = useState(-1);
  const [imageFilesLocal, setImageFilesLocal] = useState<any>([]);
  const [files, setFiles] = useState<any>([]);
  const [filesLocal, setFilesLocal] = useState<any>([]);

  const [processingNotes, setProcessingNotes] = useState<string>("");

  const initialValues: any = { departments: [] };

  const [requestAction, setRequestAction] = useState<REQUEST_ACTIONS>(
    REQUEST_ACTIONS.PENDING
  );
  const [reason, setReason] = useState<string | null>(null);
  const [input, setInput] = useState<string | null>(null);
  const [requestApproving, setRequestApproving] = useState(false);
  const [selectedDepartments, setSelectedDepartments] = useState<any>([]);

  const isApprove = requestAction === REQUEST_ACTIONS.APPROVE;
  const isReject = requestAction === REQUEST_ACTIONS.REJECT;
  const isPending = requestAction === REQUEST_ACTIONS.PENDING;

  const isAccepted = requestDetails?.status === REQUEST_STATUS.ACCEPTED;

  const checkIfImageFile = (url: string) => {
    return (
      url.indexOf(".jpeg") > -1 ||
      url.indexOf(".jpg") > -1 ||
      url.indexOf(".png") > -1 ||
      url.indexOf(".svg") > -1
    );
  };

  const renderUploadedImages = (files: any[]) => {
    return (
      <>
        {files.map((file: any) => {
          return (
            <div className="flex-container-row">
              <div>
                {checkIfImageFile(file.url) && (
                  <img src={file.url} alt="file" />
                )}
                {!checkIfImageFile(file.url) && (
                  <FileTwoTone className="icon"></FileTwoTone>
                )}
              </div>
              <div className="flex-grow flex-container-col">
                <div>
                  <span className="file-name">
                    <a
                      style={{ color: "inherit", textDecoration: "none" }}
                      href={file.url}
                      target="_blank"
                    >
                      {file.name}
                    </a>
                  </span>
                </div>
                <span>
                  <p
                    className="file-remove-action"
                    onClick={() =>
                      handleUploadedFileRemove(file.url)
                    }
                  >
                    {t("Remove")}
                  </p>
                </span>
              </div>
            </div>
          );
        })}
      </>
    );
  };

  const handleUploadedFileRemove = (url: string) => {
    let _files = [...files];
    setFiles(_files.filter((file: any) => file.url !== url));
  };

  const handleFileUpload = async (info: any) => {
    let uploaded = [] as any;
    let newFiles = [] as any;
    info.fileList.forEach((f: any) =>
      f.originFileObj ? newFiles.push(f) : uploaded.push(f)
    );

    const validatedFiles = newFiles.filter((f: any) => validateRasterImage(f));
    if (!validatedFiles.length) {
      return;
    }
    const merged = [...uploaded, ...validatedFiles];
    setFilesLocal([...filesLocal, ...merged]);
  };

  const handleFileRemove = (info: any) => {
    let _files = [...filesLocal];
    setFilesLocal(_files.filter((file: any) => file.url !== info.url));
  };

  useEffect(() => {
    (async () => {
      await dispatch(workflowActions.getAllWorkflows());
    })();
  }, []);

  useEffect(() => {
    if (!requestDetails) {
      return;
    }

    if (requestDetails.status === REQUEST_STATUS.ACCEPTED) {
      setRequestAction(REQUEST_ACTIONS.APPROVE);
    }
    if (requestDetails.status === REQUEST_STATUS.REJECTED) {
      setRequestAction(REQUEST_ACTIONS.REJECT);
    }

    setRejectionReason(requestDetails.rejectionReason);
  }, [requestDetails]);

  const setRejectionReason = (rejectionReason: string | null) => {
    if (!rejectionReason) {
      return;
    }

    const isSettingReason = settings.rejectionReasons.some(
      (reason: string) => reason === rejectionReason
    );

    if (isSettingReason) {
      setReason(rejectionReason);
    } else {
      setReason(OTHER);
      setInput(rejectionReason);
    }
  };

  const handleChange = (e: any) => {
    const value = e.target.value;

    if (value === REQUEST_ACTIONS.APPROVE) {
      setReason(null);
    }
    setRequestAction(e.target.value);
  };

  const handleSubmitAfterWorkflowUpdates = async () => {

    if (requestAction === REQUEST_ACTIONS.APPROVE) {
      setRequestApproving(true);
    }

    const reasonValue = reason === OTHER ? input : reason;
    await dispatch(
      actions.updateRequest(requestId, requestAction, reasonValue)
    );
    await dispatch(briefActions.setActiveTab(TABS_NAMES.REQUESTS));

    setRequestApproving(false);


    await dispatch(fetchRequestDetails(requestId));

    // history.push(
    //   requestDetails?.brief?.isTestahel
    //     ? `/testahel-brief/view/${briefId}`
    //     : `/briefs/view/${briefId}`
    // );
  };

  const handleSubmit = () => {

    if (!currentUser.workflowEnabled) {
      handleSubmitAfterWorkflowUpdates();
      return;
    }

    if (
      [REQUEST_ACTIONS.REJECT, REQUEST_ACTIONS.ADMIN_REJECT].includes(
        requestAction
      )
    ) {
      handleSubmitAfterWorkflowUpdates();
      return;
    }

    if (currentUser.workflowEnabled && workflows.length === 0) {
      qnotification({
        type: NOTIFICATION_TYPES.ERROR,
        message: t("workflow_error"),
      });
      return;
    }

    let set = new Set();
    workflows.forEach((el: any) => set.add(el.departmentName));
    setWorkflowOps(
      Array.from(set).map((el: any) => {
        return { label: el, value: el };
      })
    );

    // show workflow popup
    setShowModal(true);
  };

  const handleReason = (value: string) => {
    if (value !== OTHER) {
      setInput(null);
    }

    setReason(value);
  };

  const handleInput = (e: any) => {
    setInput(e.target.value);
  };

  const renderSubmit = () => {
    const className = isApprove ? "qu-button-outline-green" : "qu-button-outline-red";
    const cantSubmit =
      !hasPermission(permissions, PERMISSIONS.REQUEST_PARTNER_ACCEPT) &&
      !hasPermission(permissions, PERMISSIONS.REQUEST_PARTNER_REJECT);

    // @ts-ignore
    const disabled = isPending || updatingRequest || cantSubmit || isAccepted;

    return (
      <QButton
        onClick={currentUser?.workflowEnabled && (!requestDetails?.brief?.workflow || !requestDetails?.brief?.workflow?.length) ? handleSubmit:handleSubmitAfterWorkflowUpdates}
        disabled={disabled}
        className={`${className} full-width mv-20`}
      >
        {t("submit_response")}
      </QButton>
    );
  };
  const reasons = [...settings.rejectionReasons, OTHER];
  const reasonsOptions: IOptionForSelect[] = reasons.map((reason: string) => {
    return { label: reason, value: reason };
  });

  const handleWorkflowDepartmentChange = (depts: []) => {
    let oldDepts = [...selectedDepartments];
    let newDepts = depts.map((dept: any) => {
      let foundIndex = oldDepts.findIndex((el: any) => el.department === dept);
      if (foundIndex > -1) {
        return {
          department: dept,
          deadline: oldDepts[foundIndex].deadline,
          notes: oldDepts[foundIndex].notes,
        };
      } else {
        return {
          department: dept,
          deadline: null,
          notes: null,
        };
      }
    });
    setSelectedDepartments(newDepts);
  };

  const handleDeadlineChange = (
    date: moment.Moment,
    dateString: string,
    index: number
  ) => {
    let depts = [...selectedDepartments];
    depts[index]["deadline"] = date;
    setSelectedDepartments(depts);
  };

  const handleWorkflowInputChange = (
    e: React.ChangeEvent<any>,
    controlName: string,
    index: number,
    data?: string
  ) => {
    let depts = [...selectedDepartments];
    depts[index][controlName] = e?.target?.value ?? data;
    setSelectedDepartments(depts);
  };

  const handleValidateWorkflow = () => {

    let depts = [...selectedDepartments];
    const validDeadline = depts.every((dept: any) => dept.deadline != null)
    const validNotes = depts.every((dept: any) => {
      return dept.notes ? dept.notes.length <= 255 : true
    })

    

    if (validDeadline && validNotes) {
      handleWorkflowUpdate(depts);
    } else {
      qnotification({
        type: NOTIFICATION_TYPES.ERROR,
        message: t("error"),
        description: t("missing_required_fields"),
      });
    }
  };

  const handleWorkflowUpdate = async (data: any) => {
    const briefID = requestDetails?.brief.id;
    await dispatch(actions.assignWorkflowToBrief(briefID, data));
    setShowModal(false);
    setSelectedDepartments([]);
    form.resetFields();
    await handleSubmitAfterWorkflowUpdates();
  };

  const handleOnWorkflowProcess = async (
    workflowElementId: number,
    briefId: number,
    data: any
  ) => {
    setRequestApproving(true);
    await dispatch(processWorkflow(briefId, workflowElementId, data));
    setRequestApproving(false);
    await dispatch(fetchRequestDetails(requestId));
  };

  const shouldDisableProcessBtn = (workflowElement: any) => {
    if (workflowElement.processed) return true;
    if (hasPermission(permissions, PERMISSIONS.PROCESS_ALL_WORKFLOW_ITEMS)) return false;
    return !workflowElement.departmentEmployees
      .map((el: any) => el.employeeId)
      .includes(currentUser.id);
  };

  const shouldDisableReopenBtn = (workflowElement: any) => {
    // if already processed
    if (workflowElement.processed && hasPermission(permissions, PERMISSIONS.REOPEN_WORKFLOW_ITEM)) return false;
    return true;
  };

  const getImageTypeFromUrl = (url: string) => {
    let type = url.substring(url.lastIndexOf(".") + 1, url.length);
    if (["png", "jpeg", "jpg", "svg", "gif"].includes(type)) {
      return `image/${type}`;
    }
    return "application/pdf";
  };

  const getFileObj = (url: string) => {
    return {
      originFileObj: null,
      percent: 100,
      type: getImageTypeFromUrl(url),
      name: getFileNameFromUrl(url),
      status: "done",
      uid: -Math.ceil(Math.random() * 100),
      url,
      thumbUrl: url,
    };
  };


  const handleConfirmModalWrapper = (element: any) => {
    setActiveWorkflowId(element.id);

    if (element.processingImages?.length > 0) {
      setFiles(element.processingImages.map((file: any) => getFileObj(file)));
    } else {
      setFiles([]);
    }
    setProcessingNotes(element.processingNotes)

    setShowWorkflowExtrasModal(true);
  };

  const handleConfirmReopenModalWrapper = (id: number) => {
    setActiveWorkflowId(id);
    setShowConfirmModal(true);
  };

  const handleModalClose = () => {
    setActiveWorkflowId(-1);
    setProcessingNotes("");
    setShowWorkflowExtrasModal(false)
    setShowConfirmModal(false);
  };

  const handleModalProceed = () => {
    setShowConfirmModal(false);
    if (activeWorkflowId < 0) return;
    // @ts-ignore
    handleOnWorkflowProcess(activeWorkflowId, requestDetails?.brief.id, { processed: false });
  };

  const handleUpdateWorkflowExtras = async () => {

    setRequestApproving(true)


    let _files: any = await dispatch(
      promotionActions.upload(
        currentUser.partnerUserId,
        filesLocal.map((file: any) => file.originFileObj)
      )
    );
    let processingImages = [
      ..._files.data.data.content,
      ...files.map((i: any) => i.url),
    ]
    let payload = {
      processed: true,
      processingNotes,
      processingImages
    }

    // @ts-ignore
    await handleOnWorkflowProcess(activeWorkflowId, requestDetails?.brief.id, payload)

    setRequestApproving(false)
  }

  const getFileNameFromUrl = (url: string) => {
    return "..." + url.slice(url.length - 10);
  }

  const renderWorkflowElements = () => {
    return requestDetails?.brief.workflow.map((element: any, index: number) => {
      return (
        <div key={index} className="flexRowContainer mb-20">
          <div>
            {element.processed ? (
              <CheckCircleTwoTone
                style={{ fontSize: "20px", marginRight: "10px" }}
              />
            ) : (
              <ExclamationCircleTwoTone
                style={{ fontSize: "20px", marginRight: "10px" }}
              />
            )}{" "}
            {element.department}
            {element.deadline && (
              <div>
                <b>{t("deadline")}:</b>{" "}
                {moment(element.deadline).format(FORMATS.DATE_LL_FORMAT)}
              </div>
            )}
            {element.notes && (
              <div>
                <b>{t("notes")}:</b> {element.notes}
              </div>
            )}
            {element.processingImages?.length > 0 && (
              <div>
                <b>{t("uploads")}:</b>{" "}
                {
                  element.processingImages?.map((el: any) => {
                    return <span className="mr-5 workflow-uploads"><a href={el} target="_blank">{getFileNameFromUrl(el)}</a></span>
                  })
                }
              </div>
            )}
            {element.processingNotes && (
              <div>
                <b>{t("department_notes")}:</b> {element.processingNotes}
              </div>
            )}
            <div className="mt-10">
              <QButton
                type="primary"
                htmlType="button"
                size="small"
                disabled={shouldDisableProcessBtn(element)}
                onClick={() => handleConfirmModalWrapper(element)}
                className={element.processed ? "qu-button-green" : "qu-button"}
              >
                {element.processed ? t("completed") : t("process_now")}
              </QButton>
              <QButton
                type="primary"
                htmlType="button"
                size="small"
                disabled={shouldDisableReopenBtn(element)}
                onClick={() => handleConfirmReopenModalWrapper(element.id)}
                className={"qu-button ml-5"}
              >
                {t("reopen")}
              </QButton>
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <React.Fragment>
      {requestApproving && <SpinnerCenter text={t("please_wait")} />}

      <ServicesSummary>
        {/* @ts-ignore */}
        {requestDetails?.brief.workflow.length > 0 && (
          <div className="SS__category ph-15">
            <div className="mv-20">
              <h5 className="mb-10">{t("active_workflow")}</h5>
              {/* @ts-ignore */}
              {renderWorkflowElements()}
            </div>
          </div>
        )}

        {!isAccepted && requestDetails?.brief.status !== BRIEF_STATUS.MISSING_INFO && (
          <div className="SS__category ph-15">
            <Form layout="vertical">
              <div className="mv-20">
                <Radio.Group
                  className="qu-radio-row"
                  value={requestAction}
                  onChange={handleChange}
                  disabled={isAccepted}
                >
                  <Radio.Button
                    className="qu-radio-accept"
                    value={REQUEST_ACTIONS.APPROVE}
                    disabled={
                      !hasPermission(
                        permissions,
                        PERMISSIONS.REQUEST_PARTNER_ACCEPT
                      )
                    }
                  >
                    <div className="qu-radio-center full-height">
                      {acceptIcon}
                      <span>{t("accept")}</span>
                    </div>
                  </Radio.Button>
                  <Radio.Button
                    className="qu-radio-reject"
                    value={REQUEST_ACTIONS.REJECT}
                    disabled={
                      !hasPermission(
                        permissions,
                        PERMISSIONS.REQUEST_PARTNER_REJECT
                      )
                    }
                  >
                    <div className="qu-radio-center full-height">
                      {rejectIcon}
                      <span>{t("reject")}</span>
                    </div>
                  </Radio.Button>
                </Radio.Group>
              </div>
              {isReject && (
                <React.Fragment>
                  <Form.Item
                    colon={false}
                    label={<b>{t("rejection_reason")}</b>}
                  >
                    <QSelect
                      className="full-width"
                      showSearch
                      size="large"
                      placeholder={t("select_reason_for_rejection")}
                      value={reason}
                      onChange={handleReason}
                      options={reasonsOptions}
                      help={t("this_will_be_visible_to_client")}
                      disabled={
                        !hasPermission(
                          permissions,
                          PERMISSIONS.REQUEST_PARTNER_REJECT
                        )
                      }
                    />
                  </Form.Item>
                  {reason === OTHER && (
                    <Form.Item colon={false}>
                      <QInput
                        size="large"
                        placeholder={t("enter_reason")}
                        maxLength={255}
                        value={input}
                        onChange={handleInput}
                      />
                    </Form.Item>
                  )}
                </React.Fragment>
              )}
              {/*<Form.Item*/}
              {/*  colon={false}*/}
              {/*  label={<b>Invite others to view, accept or reject</b>}*/}
              {/*>*/}
              {/*  <QSelect*/}
              {/*    maxTagTextLength={8}*/}
              {/*    maxTagCount={2}*/}
              {/*    mode="multiple"*/}
              {/*    placeholder="enter email addresses"*/}
              {/*    options={selectOptions}*/}
              {/*    size="large"*/}
              {/*  />*/}
              {/*</Form.Item>*/}
            </Form>
          </div>
        )}
      </ServicesSummary>
      {/* @ts-ignore */}
      {!isAccepted && requestDetails?.brief.status !== BRIEF_STATUS.MISSING_INFO && renderSubmit()}

      {!isAccepted && isApprove && (
        <p className="p--sm text-center">{t("accept_help")}</p>
      )}
      {!isAccepted && isReject && (
        <p className="p--sm text-center text-red">
          {t("client_notified_help")}
        </p>
      )}

      {/* {(requestDetails?.brief?.payment?.status === BRIEF_PAYMENT_STATUS.PAID || requestDetails?.brief?.payment?.status === BRIEF_PAYMENT_STATUS.PO_ISSUED) && currentUser?.workflowEnabled && (!requestDetails?.brief?.workflow || !requestDetails?.brief?.workflow?.length) &&
        (
          <QButton
            onClick={handleSubmit}
            type={"primary"}
            className={"full-width mv-20"}
            data-html2canvas-ignore
          >
            {t('Assign Workflow')}
          </QButton>
        )} */}

      <Modal
        wrapClassName="qu-modal"
        title={<h5>{t("assign_workflow")}</h5>}
        closeIcon={closeIcon}
        visible={showModal}
        footer={null}
        onCancel={() => setShowModal(!showModal)}
      >
        <div className="p-20">
          <div>
            <label>{t("select_workflow_departments")}</label>
            <QSelect
              className="w-100"
              onChange={handleWorkflowDepartmentChange}
              size="large"
              mode="multiple"
              maxTagTextLength={8}
              maxTagCount={2}
              placeholder={t("please_select")}
              options={workflowOps}
            />
          </div>

          <div className={selectedDepartments.length > 0 ? "mt-20" : ""}>
            {selectedDepartments.length > 0 &&
              selectedDepartments.map((el: any, index: number) => {
                return (
                  <div key={index} className="mt-10">
                    <h5>{el.department}</h5>
                    <label>{t("deadline")}*</label>
                    <QDatePicker
                      value={el.deadline}
                      format={FORMATS.DATE_FORMAT}
                      // @ts-ignore
                      onChange={(date: moment.Moment, dateString: string) =>
                        handleDeadlineChange(date, dateString, index)
                      }
                      className="full-width"
                      size="large"
                      placeholder={t("select_deadline")}
                    />
                    <label>{t("notes")} {t("max_255_chars")}</label>
                    <QTextArea
                      size="large"
                      placeholder={t("enter_notes")}
                      onChange={(e: any) => {
                        handleWorkflowInputChange(e, "notes", index);
                      }}
                      className="form-textarea"
                      value={el.notes}
                    />
                    <label>{`${el.notes?.length ?? 0}/255`}</label>
                  </div>
                );
              })}
          </div>

          <div className="mt-10">
            <QButton
              disabled={selectedDepartments.length < 1}
              onClick={handleValidateWorkflow}
              type="primary"
              htmlType="button"
              size="small"
            >
              {t("save")}
            </QButton>
          </div>
        </div>
      </Modal>

      <Modal
        wrapClassName="qu-modal"
        title={<h5>{t("update_workflow")}</h5>}
        closeIcon={closeIcon}
        visible={showWorkflowExtrasModal}
        footer={null}
        onCancel={() => handleModalClose()}
      >
        <div className="p-20">
          <div className="mt-10">
            <label>{t("notes")} {t("optional")}</label>
            <QInput
              placeholder={t("enter_notes")}
              size="large"
              className="w-100"
              value={processingNotes}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setProcessingNotes(e.target.value)
              }
            />
          </div>

          <div className="mt-10">
            <label>{t("files")} {t("optional")}</label>
            <div>
              <SimpleUploadComponent
                multiple
                heading={t("upload_files")}
                caption={[]}
                files={imageFilesLocal}
                onChange={(info: any) =>
                  handleFileUpload(info)
                }
                onRemove={(info: any) =>
                  handleFileRemove(info)
                }
              // isUploading={isLoading}
              />
            </div>
            {renderUploadedImages(files)}
          </div>

          <div className="mt-10">
            <QButton
              onClick={handleUpdateWorkflowExtras}
              type="primary"
              htmlType="button"
              size="small"
            >
              {t("update_and_mark_processed")}
            </QButton>
          </div>
        </div>
      </Modal>

      <DialogModal
        modalOpen={showConfirmModal}
        question={t("are_you_sure")}
        description={t("workflow_confirm")}
        submitButtonText={t("cancel")}
        cancelButtonText={t("proceed")}
        onClose={handleModalClose}
        onProceed={handleModalProceed}
        revert
      />
    </React.Fragment>
  );
};

export default RequestForm;
