import get from 'lodash/get'

import { IDashboardParams, IState } from './dashboard.types'
import * as CONSTANTS from './dashboard.constants'
import IAction from 'src/interfaces/IAction'
import { getDateFromToday, getIsoDate } from '../../helpers/datesHelper'
import moment from 'moment'
import cloneDeep from 'lodash/cloneDeep'
import { CHART_TYPES, DATE_PRESET } from '../../constants/dashboard'

export const InitIDateRangeParams = {
  before: getIsoDate(getDateFromToday(DATE_PRESET.MONTH)),
  after: getIsoDate(moment().endOf('day')),
} as IDashboardParams

const initialState: IState = {
  params: {
    type: null,
    userRole: 'all',
    countries: [],
    [CHART_TYPES.SIGNUP_REQUESTS_CLIENT]: cloneDeep(InitIDateRangeParams),
    [CHART_TYPES.SIGNUP_REQUESTS_PARTNER]: cloneDeep(InitIDateRangeParams),
    [CHART_TYPES.APPROVED_CLIENTS]: cloneDeep(InitIDateRangeParams),
    [CHART_TYPES.APPROVED_PARTNERS]: cloneDeep(InitIDateRangeParams),
    [CHART_TYPES.SAMPLE_DISTRIBUTION_ACTIVE]: cloneDeep(InitIDateRangeParams),
    [CHART_TYPES.SAMPLE_DISTRIBUTION_COMPLETE]: cloneDeep(InitIDateRangeParams),
    [CHART_TYPES.TRANSACTIONS]: cloneDeep(InitIDateRangeParams),
    [CHART_TYPES.BRIEFS]: cloneDeep(InitIDateRangeParams),
    [CHART_TYPES.BRIEF_CONVERSION]: cloneDeep(InitIDateRangeParams),
    [CHART_TYPES.CAMPAIGNS]: cloneDeep(InitIDateRangeParams),
    [CHART_TYPES.REVENUE]: cloneDeep(InitIDateRangeParams),
    [CHART_TYPES.CAMPAIGN_TYPE]: cloneDeep(InitIDateRangeParams),
    [CHART_TYPES.ACTIVE_USERS]: cloneDeep(InitIDateRangeParams),
    [CHART_TYPES.CAMPAIGN_COMPLETION]: cloneDeep(InitIDateRangeParams),
  },
  chartLoading: false,
  chartData: null,
}

export default (state: IState = initialState, action: IAction): IState => {
  switch (action.type) {
    case CONSTANTS.RESET_DASHBOARD_STATE:
      return initialState
    case CONSTANTS.SET_DASHBOARD_PARAMS:
      return { ...state, params: get(action, 'payload.params') }
    case CONSTANTS.FETCH_DASHBOARD_REQUEST:
      return { ...state, chartLoading: true }

    case CONSTANTS.FETCH_DASHBOARD_SUCCESS:
      const responseData = get(action, 'payload.data')
      const newData = { ...state.chartData }
      Object.keys(responseData).forEach((key: string) => {
        //@ts-ignore
        newData[key] = responseData[key]
      })
      return {
        ...state,
        chartData: newData,
        chartLoading: false,
      }
    case CONSTANTS.FETCH_DASHBOARD_FAILURE:
      return { ...state, chartLoading: false }

    default:
      return state
  }
}
