import { createSelector } from 'reselect'
import { MODULE_NAME } from './adminSettings.constants'
import { IState } from './adminSettings.types'

const selectState = (state: { [MODULE_NAME]: any }) => state[MODULE_NAME]

export const getAdminSettingsDataLoading = createSelector(
  selectState,
  (state: IState): boolean => state.adminSettingsDataLoading
)

export const getCampaignOpsPrices = createSelector(selectState, (state: IState): any => state.campaignOpsPrices)

export const getManagementFee = createSelector(selectState, (state: IState): any => state.managementFee)

export const getTargetingToggles = createSelector(selectState, (state: IState): any => state.targetingToggles)

export const getThemeData = createSelector(selectState, (state: IState): any => state.themeData)

export const getWorkflowSettings = createSelector(selectState, (state: IState): any => state.workflowEnabled)
