export enum PAYOUT_STATUS {
    PENDING_INVOICE = 'PENDING INVOICE',
    PROCESSING = 'PROCESSING',
    COMPLETE = 'COMPLETE',
    EXPIRED = 'EXPIRED',
}

export const payoutsStatuses = {
    [PAYOUT_STATUS.PROCESSING]: {
        classStyle: 'pending',
        label: 'Processing',
    },
    [PAYOUT_STATUS.PENDING_INVOICE]: {
        classStyle: 'running',
        label: 'Pending Invoice',
    },
    [PAYOUT_STATUS.COMPLETE]: {
        classStyle: 'approved',
        label: 'Complete',
    },
    [PAYOUT_STATUS.EXPIRED]: {
        classStyle: 'completed',
        label: 'Expired',
    },
}

export enum PAYOUT_UPLOAD_TYPES {
    PAYOUT_INVOICE = 'payout_invoice',
}
