export const MODULE_NAME = 'segments'

/* actions */

export const FETCH_SEGMENTS_REQUEST = `${MODULE_NAME}/FETCH_SEGMENTS_REQUEST`
export const FETCH_SEGMENTS_SUCCESS = `${MODULE_NAME}/FETCH_SEGMENTS_SUCCESS`
export const FETCH_SEGMENTS_FAILURE = `${MODULE_NAME}/FETCH_SEGMENTS_FAILURE`

export const CREATE_SEGMENT_REQUEST = `${MODULE_NAME}/CREATE_SEGMENT_REQUEST`
export const CREATE_SEGMENT_SUCCESS = `${MODULE_NAME}/CREATE_SEGMENT_SUCCESS`
export const CREATE_SEGMENT_FAILURE = `${MODULE_NAME}/CREATE_SEGMENT_FAILURE`

export const EDIT_SEGMENT_REQUEST = `${MODULE_NAME}/EDIT_SEGMENT_REQUEST`
export const EDIT_SEGMENT_SUCCESS = `${MODULE_NAME}/EDIT_SEGMENT_SUCCESS`
export const EDIT_SEGMENT_FAILURE = `${MODULE_NAME}/EDIT_SEGMENT_FAILURE`

export const DELETE_SEGMENT_REQUEST = `${MODULE_NAME}/DELETE_SEGMENT_REQUEST`
export const DELETE_SEGMENT_SUCCESS = `${MODULE_NAME}/DELETE_SEGMENT_SUCCESS`
export const DELETE_SEGMENT_FAILURE = `${MODULE_NAME}/DELETE_SEGMENT_FAILURE`
