import React, { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useReduxDispatch } from '../../../helpers'
import { getSegmentsList, getIsSegmentsListLoading, getSegmentsUpdating } from '../segments.selectors'
import { IModalState } from '../segments.types'
import * as actions from '../segments.actions'

import { Modal, Tooltip } from 'antd'
import { EditTwoTone, DeleteTwoTone } from '@ant-design/icons'
import { QTable, QButton, QInput, Form, Row, QSelect } from 'quantum_components'
import { validateMessages } from '../../../helpers/validationMessage'

const Segments = () => {
  const dispatch = useReduxDispatch()
  const segmentsList = useSelector(getSegmentsList)
  const segmentsListIsLoading = useSelector(getIsSegmentsListLoading)
  const segmentsUpdating = useSelector(getSegmentsUpdating)
  const [form] = Form.useForm()

  const [tableKey, setTableKey] = useState(1)
  const [tableKey1, setTableKey1] = useState(100)
  const [editModal, setEditModal] = useState<IModalState>({ visible: false, id: 0, name: '', sortNumber: null })
  const [deleteModal, setDeleteModal] = useState<IModalState>({ visible: false, id: 0, name: '' })
  const [positionOptions, setPositionOptions] = useState<any>([])

  useEffect(() => {
    dispatch(actions.fetchSegmentsList())
  }, [dispatch])

  const handleEditModalOpen = (segment?: IModalState) => {
    if (segment) {
      calculatePositionOptions(segmentsList.length)
      const segmentData = { id: segment.id, name: segment.name, sortNumber: segment.sortNumber }

      form.setFieldsValue(segmentData)
      setEditModal({ ...segmentData, visible: true })
    } else {
      calculatePositionOptions(segmentsList.length + 1)
      form.resetFields()
      setEditModal({ ...editModal, visible: true })
      form.setFieldsValue({ ...form, sortNumber: segmentsList.length + 1 })
    }
  }

  const handleEditModalClose = () => {
    setEditModal({ visible: false, id: 0, name: '', sortNumber: null })
    setTableKey1(tableKey1 + 1)
  }

  const handleOnValuesChange = (changedValue: any) => {
    form.setFieldsValue(changedValue)
  }

  const handleOnFinish = async (data: any) => {
    if (segmentsUpdating) return

    if (editModal.id) await dispatch(actions.editSegment({ ...data, id: editModal.id }))
    else await dispatch(actions.createSegment(data))

    await dispatch(actions.fetchSegmentsList())
    setEditModal({ visible: false, id: 0, name: '', sortNumber: null })
    setTableKey1(tableKey1 + 1)
  }

  const onCheck = async () => {
    try {
      const values = await form.validateFields()
      handleOnFinish(values)
    } catch (error) {
      return error
    }
  }

  const handleDeleteClick = useCallback(
    (segment: IModalState) => () => {
      setDeleteModal({ visible: true, id: segment.id, name: segment.name })
    },
    []
  )

  const handleDeleteModalClose = () => {
    setDeleteModal({ visible: false, id: 0, name: '' })
  }

  const handleDeleteSegment = async () => {
    if (segmentsUpdating) return
    await dispatch(actions.deleteSegment(deleteModal.id))
    setDeleteModal({ visible: false, id: 0, name: '' })
    setTableKey(tableKey + 1)

    await dispatch(actions.fetchSegmentsList())
  }

  const calculatePositionOptions = (value: number) => {
    let newArr = []
    for (let i = 1; i <= value; i++) {
      newArr.push({
        label: i,
        value: i,
      })
    }
    setPositionOptions(newArr)
  }

  const renderEditModalTitle = () => {
    if (editModal.name) {
      return (
        <>
          Edit
          <strong> {editModal.name} </strong>
          segment
        </>
      )
    }
    return 'Add New Segment'
  }

  const columns = [
    {
      title: 'Sort',
      dataIndex: 'sortNumber',
      key: 'sortNumber',
      width: '15%',
    },
    {
      title: 'Segment name',
      dataIndex: 'name',
      key: 'name',
      width: '70%',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      width: '15%',
      render: (text: string, record: any) => (
        <div>
          <Tooltip title="Edit Segment">
            <EditTwoTone
              style={{ fontSize: '20px', marginRight: '20px' }}
              onClick={() => handleEditModalOpen(record)}
            />
          </Tooltip>
          <Tooltip title="Delete Segment">
            <DeleteTwoTone style={{ fontSize: '20px' }} onClick={handleDeleteClick(record)} />
          </Tooltip>
        </div>
      ),
    },
  ]

  return (
    <>
      <h2 className="pt-10">Segments</h2>
      <div className="pt-15 pb-20">
        <QTable columns={columns} dataSource={segmentsList} loading={segmentsListIsLoading} scroll={{ x: 990 }} />
      </div>
      <Row justify="end">
        <QButton type="primary" onClick={() => handleEditModalOpen()}>
          Add Segment
        </QButton>
      </Row>

      <Modal
        key={tableKey1}
        title={renderEditModalTitle()}
        visible={editModal.visible}
        onOk={onCheck}
        onCancel={handleEditModalClose}
      >
        <Form
          layout="vertical"
          hideRequiredMark
          form={form}
          onFinish={onCheck}
          onValuesChange={handleOnValuesChange}
          validateMessages={validateMessages}
        >
          <Form.Item
            label="Segment name"
            name="name"
            rules={[{ required: true }]}
            validateTrigger={['onChange', 'onBlur']}
          >
            <QInput size="large" />
          </Form.Item>

          <Form.Item label="Position" name="sortNumber">
            <QSelect size="large" placeholder="Select position" options={positionOptions} />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        key={tableKey}
        title="Are you sure?"
        visible={deleteModal.visible}
        onOk={handleDeleteSegment}
        onCancel={() => {
          handleDeleteModalClose()
          setTableKey(tableKey + 1)
        }}
      >
        <p>
          Do you want to delete
          <strong> {deleteModal.name} </strong>
          segment?
        </p>
      </Modal>
    </>
  )
}

export default Segments
