import { requestHttp, urls } from "src/api";
import {
  IBrief,
  IBriefResponse,
  IMediaBuyStep,
  IProductStep,
  IServicesStep,
  ITargetingStep,
  IUploadStep,
  IViewBriefDetailsResponse,
} from "./briefv3.types";
import {
  BRIEF_PAYMENT_STATUS,
  BRIEF_UPLOAD_TYPES,
} from "../../constants/brief";
import { upload } from "../core/core.actions";
import moment from "moment";
import { UPLOAD_ENTITY_KEYS } from "../../constants/upload";
import IUploadFile from "../../interfaces/IUploadFile";
import { cloneDeep } from "lodash";
import { IBriefInitState } from "./briefv3.reducer";
import { BRIEF_STEPS } from "src/constants/testahel_brief";
import history from "src/utils/history";

const parseBriefResponse = (brief: IBriefResponse) => {
  const newBrief:any = cloneDeep(IBriefInitState)
  newBrief.status = brief.status
  newBrief.promotionId = brief.promotionId
  newBrief.owner = brief.owner
  newBrief.lastFilledStep = brief.lastFilledStep ? brief.lastFilledStep : BRIEF_STEPS.TYPE
  newBrief[BRIEF_STEPS.TYPE].briefTypes = brief.briefTypes ? brief.briefTypes : []

  newBrief[BRIEF_STEPS.PRODUCT] = {
    name: brief.name,
    quantity: brief.quantity,
    distributionItem: brief.distributionItem,
    productType: brief.productType,
    mediaStartDate: brief.mediaStartDate ? moment(brief.mediaStartDate) : null,
    duration: brief.duration,
    productImages: brief.productImages ? brief.productImages : [],
    instructions: brief.instructions,
    campaignName: brief.campaignName,
    campaignPromotionType: brief.campaignPromotionType,
    productDescription: brief.productDescription,
    marketingObjective: brief.marketingObjective,
  } as IProductStep

  newBrief[BRIEF_STEPS.TARGETING] = {
    targetFocus: brief.targetFocus ? brief.targetFocus : [],
    targetAgeGroups: brief.targetAgeGroups ? brief.targetAgeGroups : [],
    targetSegments: brief.targetSegments ? brief.targetSegments : [],
    targetCountries: brief.targetCountries ? brief.targetCountries : [],
    targetCities: brief.targetCities ? brief.targetCities : [],
    customTargets: brief.customTargets ? brief.customTargets : {},
  } as ITargetingStep

  newBrief[BRIEF_STEPS.SERVICES] = {
    servicePackageType: brief.servicePackageType ? brief.servicePackageType : [],
    serviceIsLiquid: brief.serviceIsLiquid || false,
    serviceIncludingCoupon: brief.serviceIncludingCoupon || false,
    serviceDesign: brief.serviceDesign || false,
    servicePrinting: brief.servicePrinting || false,
    printingPriceId: brief.printingPriceId,
    servicePackaging: brief.servicePackaging || false,
    copackingPriceId: brief.copackingPriceId,
    servicePackagingQuantity: brief.servicePackagingQuantity,
    serviceImages: brief.serviceImages ? brief.serviceImages : [],
    hasFreeSample: brief.hasFreeSample || false,
    serviceTemperature: brief.serviceTemperature,
  } as IServicesStep

  newBrief[BRIEF_STEPS.MEDIA_BUY] = {
    mediaStartDate: brief.mediaStartDate ? moment(brief.mediaStartDate) : null,
    mediaDuration: brief.mediaDuration,
    mediaTypes: brief.mediaTypes ? brief.mediaTypes : [],
    marketingObjective: brief.marketingObjective,
    budgetCurrency: brief.budgetCurrency,
    budgetType: brief.budgetType,
    budgetValue: brief.budgetValue,
  } as IMediaBuyStep

  newBrief[BRIEF_STEPS.UPLOAD] = {
    uploadMaterial: brief.uploadMaterial,
  } as IUploadStep

  return newBrief
}

// v3 actions async
export const fetchEditBriefAsync = async (id: number) => {
  try {
    const response = await requestHttp.get<IViewBriefDetailsResponse>(
      urls.getViewBriefV3Url(id)
    );
    const { content } = response.data.data;

    const parsedBrief = parseBriefResponse(content);
    parsedBrief.id = id;

    return {
      ...parsedBrief,rawResponse:content
    };
  } catch (e) {
    console.log(JSON.stringify(e));
    return null;
  }
};

export const updateCartServicesStatus = async (cartServices:any) => {
  try {
    const response = await requestHttp.post<any>(
      urls.updateCartServicesStatus(),
      cartServices
    );
    const { content } = response.data.data;
    return content;
  } catch (e) {
    console.log(JSON.stringify(e));
    return null;
  }
};

export const removeImageAsync = async (
  brief: IBrief,
  file: IUploadFile,
  entity: BRIEF_UPLOAD_TYPES
) => {
  try {
    const data = {
      briefId: `${brief.id}`,
      entity: entity,
      urls: [file.url],
      version:2
    };

    const response = await requestHttp.delete(urls.getBriefRemoveMediaUrl(), {
      data,
    });
    if (response.status === 201) {
      const { content } = response.data.data;
      return content;
    }

    return null;
  } catch (error) {
    console.log(JSON.stringify(error));
    return null;
  }
};

export const uploadImageAsync = async (
  brief: any,
  files: File[],
  entity: BRIEF_UPLOAD_TYPES
) => {
  try {
    if (!brief.id) return null;

    const uploadUrl = urls.getBriefUploadUrl();
    const response:any = await upload(
      uploadUrl,
      files,
      brief.id,
      entity,
      UPLOAD_ENTITY_KEYS.BRIEF_ID,
      true,
      "2"
    );
    if (response.status === 201) {
      const { content } = response.data.data;
      return content;
    }

    return null;
  } catch (error) {
    console.log(JSON.stringify(error));
    return null;
  }
};

export const updateBriefAsync = async (briefDetails:any) => {
  try {
    const response = await requestHttp.put<any>(
      urls.getV3EditBriefUrl(),
      briefDetails
    );
    const { content } = response.data.data;

    return content
  } catch (e) {
    console.log(JSON.stringify(e));
    return null;
  }
};

export const overridePaymentByAdmin = async (briefId:any) => {
  try {
    const response = await requestHttp.post<any>(
      urls.getOverrideBriefPaymentByAdminV3Url(briefId)
    );
    const { content } = response.data.data;

    return content
  } catch (e) {
    console.log(JSON.stringify(e));
    return null;
  }
};

export const deleteBriefAsync = async (id:any) => {
  try {
    const response = await requestHttp.delete<any>(
      urls.getV3DeleteBriefUrl(id)
    );
    const { content } = response.data.data;

    return content
  } catch (e) {
    console.log(JSON.stringify(e));
    return null;
  }
};

export const uploadGeneratedQuotation = async(files:any[],entity:BRIEF_UPLOAD_TYPES,briefId:number) => {
  try {
    if (!briefId) return null;

    const uploadUrl = urls.getBriefUploadUrl();
    const response:any = await upload(
      uploadUrl,
      files,
      briefId,
      entity,
      UPLOAD_ENTITY_KEYS.BRIEF_ID,
      true,
      "2"
    );
    if (response.status === 201) {
      const { content } = response.data.data;
      return content;
    }
    return null;
  } catch (e) {
    console.log(JSON.stringify(e));
    return null;
  }
}

export const uploadBriefPaymentFile = async(files:any[],filesType:string, brief:any) => {
  const response:any = await upload(
    urls.getBriefUploadUrl(),
    files,
    brief.cart.briefId,
    BRIEF_UPLOAD_TYPES.BRIEFS_SERVICE_IMAGES,
    UPLOAD_ENTITY_KEYS.BRIEF_ID,
    false,
    "2"
  );

  if (response.status === 201) {
    const briefFiles:any = {
      id: brief.payment.id,
      po: brief.payment.po?.map((url:any) => url),
      bankTransfer: brief.payment.bankTransfer?.map((url:any) => url),
      version:"2"
    }
    const { content: newFilesUrls }: { content: string[] } = response.data.data
    briefFiles[filesType] = [...newFilesUrls, ...(brief.payment[filesType]?.map((url:any) => url) ?? [])]

    await requestHttp.put(urls.getEditBillUrl(), briefFiles)
  }


  if (response.status === 201) {
    const { content } = response.data.data;
    return content;
  }
  return null;
}

export const removeBriefPaymentFile = async(urlToRemove:string,filesType:string, brief:any) => {
  const data = {
    briefId: String(brief.payment.briefId),
    entity: BRIEF_UPLOAD_TYPES.BRIEFS_SERVICE_IMAGES,
    urls: [urlToRemove],
    version:"2"
  }
  const response = await requestHttp.delete(urls.getBriefRemoveMediaUrl(), { data })
  if (response.status === 201) {
    const briefFiles:any = {
      id: brief.payment.id,
      po: brief.payment.po?.map((url:any) => url),
      bankTransfer: brief.payment.bankTransfer?.map((url:any) => url),
      version:"2"
    }
    briefFiles[filesType] = briefFiles[filesType]!.filter((oldFileUrl:any) => oldFileUrl !== urlToRemove)

    await requestHttp.put(urls.getEditBillUrl(), briefFiles)
  }
  
  if (response.status === 201) {
    const { content } = response.data.data;
    return content;
  }
  return null;
}

export const downloadProtectedMedia = async(url:string) => {
  try {
    const response = await requestHttp.get(urls.getCoreImage(url), {
      skipDownloadProgress: false,
      responseType: 'blob',
    })
  
    return response
  } catch (error) {
    return null;
  }
  
}

export const acceptBriefPayment = async(payment:any) => {
  const poUploaded = Boolean(payment.po?.length)
  const bankTransferUploaded = Boolean(payment.bankTransfer?.length)
  const acceptStatus =
    poUploaded && !bankTransferUploaded ? BRIEF_PAYMENT_STATUS.PO_ISSUED : BRIEF_PAYMENT_STATUS.PAID
  await requestHttp.put(urls.getEditBillUrl(), {
    id: payment.id,
    status: acceptStatus,
    briefId: payment.briefId,
    version:"2"
  })
}

export const rejectBriefPayment = async(payment:any,reason?:string|null) => {
  await requestHttp.put(urls.getEditBillUrl(), {
    id: payment.id,
    status: BRIEF_PAYMENT_STATUS.REJECTED,
    reason,
    version:"2"
  })
}

export const upsertV3Campaign = async(briefId:number) => {
  try {
    
    const response = await requestHttp.post(urls.getCampaignUpsertUrl(briefId),{version:"2"});
    const { content } = response.data.data;

    
    // history.push(`/campaigns/${content.campaignId}`)
  } catch (error) {
    return error
  }
}
// v3 actions end


