

export enum CLIENT_STEPS {
  BASIC_INFORMATION = 'BASIC_INFORMATION',
  PRODUCT_TYPE = 'PRODUCT_TYPE',
  TARGET_AUDIENCE = 'TARGET_AUDIENCE',
}

export const CLIENT_STEPS_NAMES: { [key: string]: string } = {
  [CLIENT_STEPS.BASIC_INFORMATION]: '1.  Basic Information',
  [CLIENT_STEPS.PRODUCT_TYPE]: '2. Product Type',
  [CLIENT_STEPS.TARGET_AUDIENCE]: '3. Target Audience',
}
export const CLIENT_STEPS_SIDE_BAR: { [key: string]: string } = {
  [CLIENT_STEPS.BASIC_INFORMATION]: 'Basic Information',
  [CLIENT_STEPS.PRODUCT_TYPE]: 'Product Type',
  [CLIENT_STEPS.TARGET_AUDIENCE]: 'Target Audience',
}

export enum BASIC_INFO {
  PROFILE_PICTURE = 'Profile Picture',
  FIRST_NAME = 'First Name',
  LAST_NAME = 'Last Name',
  COMPANY_NAME = 'Company Name',
  COMPANY_WEBSITE = 'Company Website',
  OFFICIAL_EMAIL = 'Official Email',
  PHONE_NUMBER = 'Phone Number',
  LOCATION = 'Location',
  CURRENCY = 'Currency',
  VAT = 'VAT',
  TAX_TREATMENT = 'Tax Treatment',
  CR = 'CR',
  NATIONAL_ADDRESS = 'National Address',
}

export const BASIC_INFO_NAMES: { [key: string]: string } = {
  [BASIC_INFO.PROFILE_PICTURE]: 'Profile Picture',
  [BASIC_INFO.FIRST_NAME]: 'First Name',
  [BASIC_INFO.LAST_NAME]: 'Last Name',
  [BASIC_INFO.COMPANY_NAME]: 'Company Name',
  [BASIC_INFO.COMPANY_WEBSITE]: 'Company Website',
  [BASIC_INFO.OFFICIAL_EMAIL]: 'Official Email',
  [BASIC_INFO.PHONE_NUMBER]: 'Phone Number',
  [BASIC_INFO.LOCATION]: 'Location',
  [BASIC_INFO.CURRENCY]: 'Currency',
  [BASIC_INFO.VAT]: 'VAT Registration No.',
  [BASIC_INFO.TAX_TREATMENT]: 'Tax Treatment',
  [BASIC_INFO.CR]: 'CR',
  [BASIC_INFO.NATIONAL_ADDRESS]: 'National Address',
}

export const BASIC_INFO_NAMES_WITHOUT_VAT: { [key: string]: string } = {
  [BASIC_INFO.PROFILE_PICTURE]: 'Profile Picture',
  [BASIC_INFO.FIRST_NAME]: 'First Name',
  [BASIC_INFO.LAST_NAME]: 'Last Name',
  [BASIC_INFO.COMPANY_NAME]: 'Company Name',
  [BASIC_INFO.COMPANY_WEBSITE]: 'Company Website',
  [BASIC_INFO.OFFICIAL_EMAIL]: 'Official Email',
  [BASIC_INFO.PHONE_NUMBER]: 'Phone Number',
  [BASIC_INFO.LOCATION]: 'Location',
  [BASIC_INFO.CURRENCY]: 'Currency',
  [BASIC_INFO.TAX_TREATMENT]: 'Tax Treatment',
  [BASIC_INFO.CR]: 'CR',
  [BASIC_INFO.NATIONAL_ADDRESS]: 'National Address',
}

export enum PRODUCT_TYPE {
  PRODUCT_TYPES = 'Product Type(s)',
}

export enum TARGET_TYPE {
  GENDER_FOCUS = 'Gender Focus',
  AGE_GROUPS = 'Age Groups',
  // TARGETED_SEGMENTS = 'Segments',
}

export const BASIC_INFO_VALUES: { [key: string]: string } = {
  [BASIC_INFO.PROFILE_PICTURE]: 'profilePicture',
  [BASIC_INFO.FIRST_NAME]: 'firstName',
  [BASIC_INFO.LAST_NAME]: 'lastName',
  [BASIC_INFO.COMPANY_NAME]: 'companyName',
  [BASIC_INFO.COMPANY_WEBSITE]: 'companyWebsite',
  [BASIC_INFO.OFFICIAL_EMAIL]: 'email',
  [BASIC_INFO.PHONE_NUMBER]: 'phone',
  [BASIC_INFO.LOCATION]: 'location',
  [BASIC_INFO.CURRENCY]: 'currency',
  [BASIC_INFO.VAT]: 'vat',
  [BASIC_INFO.TAX_TREATMENT]: 'taxTreatment',
  [BASIC_INFO.CR]: 'cr',
  [BASIC_INFO.NATIONAL_ADDRESS]: 'nationalAddress',
}

export const PRODUCT_TYPE_VALUES: { [key: string]: string } = {
  [PRODUCT_TYPE.PRODUCT_TYPES]: 'productTypes',
}

export const TARGET_AUDIENCE_VALUES: { [key: string]: string } = {
  [TARGET_TYPE.GENDER_FOCUS]: 'genderFocus',
  [TARGET_TYPE.AGE_GROUPS]: 'ageGroups',
  // [TARGET_TYPE.TARGETED_SEGMENTS]: 'segments',
}

export const BASIC_INFO_INITIAL_VALUES: { [key: string]: any } = {
  [BASIC_INFO_VALUES[BASIC_INFO.PROFILE_PICTURE]]: '',
  [BASIC_INFO_VALUES[BASIC_INFO.FIRST_NAME]]: '',
  [BASIC_INFO_VALUES[BASIC_INFO.LAST_NAME]]: '',
  [BASIC_INFO_VALUES[BASIC_INFO.COMPANY_NAME]]: '',
  [BASIC_INFO_VALUES[BASIC_INFO.COMPANY_WEBSITE]]: '',
  [BASIC_INFO_VALUES[BASIC_INFO.OFFICIAL_EMAIL]]: '',
  [BASIC_INFO_VALUES[BASIC_INFO.PHONE_NUMBER]]: '',
  [BASIC_INFO_VALUES[BASIC_INFO.LOCATION]]: '',
  [BASIC_INFO_VALUES[BASIC_INFO.CURRENCY]]: '',
  [BASIC_INFO_VALUES[BASIC_INFO.VAT]]: '',
  [BASIC_INFO_VALUES[BASIC_INFO.TAX_TREATMENT]]: 'VAT Registered',
  [BASIC_INFO_VALUES[BASIC_INFO.CR]]: '',
  [BASIC_INFO_VALUES[BASIC_INFO.NATIONAL_ADDRESS]]: '',
}

export const PRODUCT_TYPE_INITIAL_VALUES: { [key: string]: any } = {
  [PRODUCT_TYPE_VALUES[PRODUCT_TYPE.PRODUCT_TYPES]]: [],
}

export const TARGET_AUDIENCE_INITIAL_VALUES: { [key: string]: any } = {
  [TARGET_AUDIENCE_VALUES[TARGET_TYPE.GENDER_FOCUS]]: [],
  [TARGET_AUDIENCE_VALUES[TARGET_TYPE.AGE_GROUPS]]: [],
  // [TARGET_AUDIENCE_VALUES[TARGET_TYPE.TARGETED_SEGMENTS]]: [],
}

export enum TAX_TREATMENT {
  VAT_REGISTERED = 'VAT Registered',
  NON_VAT_REGISTERED = 'Non-VAT Registered',
}
