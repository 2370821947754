/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import React, { useState, useEffect, Fragment } from 'react'
import { useSelector } from 'react-redux'
import { QButton, QBriefStep, ClientSidebar, Col, Row, IconsCommon } from 'quantum_components'

import { useParams } from 'react-router-dom'

import { actions } from '../clientProfile.module'
import {
  CLIENT_STEPS,
  CLIENT_STEPS_NAMES,
  CLIENT_STEPS_SIDE_BAR,
  BASIC_INFO_NAMES,
  TARGET_TYPE,
  PRODUCT_TYPE,
  BASIC_INFO_INITIAL_VALUES,
  PRODUCT_TYPE_INITIAL_VALUES,
  TARGET_AUDIENCE_INITIAL_VALUES,
  BASIC_INFO,
  BASIC_INFO_VALUES,
  TAX_TREATMENT,
  BASIC_INFO_NAMES_WITHOUT_VAT,
} from '../../../../constants/clientProfile'
import { useReduxDispatch } from '../../../../helpers'
import {
  getCurrentStep,
  getIsLoading,
  getFilledData,
  getIsUpdating,
  getClientProfilePercent,
} from '../profile.selectors'

import { ClientsProfileHeader } from './Layout/profile-header'

import { BRIEF_STEP_STATUS } from 'src/constants/brief'

import { ClientsProfileBody } from './Layout/profile-body'
import { ClientsProductBody } from './Layout/product-body'
import { ClientsTargetBody } from './Layout/target-body'

import { getCurrentUser, getCurrentUserPermissionActions } from '../../../core/core.selectors'
import { createObj } from '../../../../helpers/createObjectWithOldFields'

import { isMobile } from 'react-device-detect'
import { hasPermission } from '../../../../helpers/permission'
import { PERMISSIONS } from '../../../../constants/permission'
import { cloneDeep, omit, pick } from 'lodash'
import { useTranslation } from 'react-i18next'
import { t } from 'i18next'
import i18n from 'src/i18n'
import { hasErrorFields } from 'src/utils/validationRules'
import SpinnerCenter from 'src/components/Spinner/spinner'
const { IconArrowLeft, IconArrowRight } = IconsCommon

export const CLIENT_STEPS_EDIT: CLIENT_STEPS[] = [
  CLIENT_STEPS.BASIC_INFORMATION,
  CLIENT_STEPS.PRODUCT_TYPE,
  CLIENT_STEPS.TARGET_AUDIENCE,
]

const DATA_BY_STEP = {
  [CLIENT_STEPS.BASIC_INFORMATION]: BASIC_INFO_INITIAL_VALUES,
  [CLIENT_STEPS.PRODUCT_TYPE]: PRODUCT_TYPE_INITIAL_VALUES,
  [CLIENT_STEPS.TARGET_AUDIENCE]: TARGET_AUDIENCE_INITIAL_VALUES,
}

interface Props {
  children?: React.ReactNode
}



const checkValue = e => !!((e && e.length && !Array.isArray(e)) || (e && Array.isArray(e) && e.some(v => v)))

const ProfileClientContainer = ({ children }: Props) => {
  const { t } = useTranslation(['translationClient', 'translationProfile'])
  const currentStep = useSelector(getCurrentStep)
  const dispatch = useReduxDispatch()
  const user = useSelector(getCurrentUser)
  const filledData = useSelector(getFilledData)
  const isLoading = useSelector(getIsLoading)
  const isUpdating = useSelector(getIsUpdating)
  const permissions = useSelector(getCurrentUserPermissionActions)
  const param = useParams()
  const percent = useSelector(getClientProfilePercent)

  const stepData = createObj(DATA_BY_STEP[currentStep], filledData)
  stepData.taxTreatment === TAX_TREATMENT.NON_VAT_REGISTERED ? delete stepData.vat : delete stepData.taxTreatment

  const [stepsInfo, setStepsInfo] = useState<{ filledSteps: string[]; unfilledSteps: string[] }>({
    filledSteps: [],
    unfilledSteps: [],
  })

  const disabledSave = isUpdating || !hasPermission(permissions, PERMISSIONS.CLIENT_UPDATE_PROFILE) || hasErrorFields(
    filledData,
    Object.keys(
      omit(filledData, [BASIC_INFO_VALUES[BASIC_INFO.FIRST_NAME], BASIC_INFO_VALUES[BASIC_INFO.LAST_NAME], BASIC_INFO_VALUES[BASIC_INFO.CR], BASIC_INFO_VALUES[BASIC_INFO.NATIONAL_ADDRESS]])
    ),
    filledData?.taxTreatment === TAX_TREATMENT.NON_VAT_REGISTERED
      ? [BASIC_INFO_VALUES[BASIC_INFO.FIRST_NAME], BASIC_INFO_VALUES[BASIC_INFO.LAST_NAME], BASIC_INFO_VALUES[BASIC_INFO.CR], BASIC_INFO_VALUES[BASIC_INFO.NATIONAL_ADDRESS]]
      : [BASIC_INFO_VALUES[BASIC_INFO.FIRST_NAME], BASIC_INFO_VALUES[BASIC_INFO.LAST_NAME], BASIC_INFO_VALUES[BASIC_INFO.VAT], BASIC_INFO_VALUES[BASIC_INFO.CR], BASIC_INFO_VALUES[BASIC_INFO.NATIONAL_ADDRESS]]
  )

  const fetchSettingsAndInfo = async () => {
    dispatch(actions.fetchClientSettings())
    dispatch(actions.fetchClientInfo(user, param.id))
  }

  const PropertiesOfSteps: string[][] = [
    Object.values(BASIC_INFO_NAMES_WITHOUT_VAT).map(item => t(`clients.profile.sidebar.stepper.steps.${item}`)),
    Object.values(PRODUCT_TYPE).map(item => t(`clients.profile.sidebar.stepper.steps.${item}`)),
    Object.values(TARGET_TYPE).map(item => t(`clients.profile.sidebar.stepper.steps.${item}`))
  ]

  useEffect(() => {
    if (user && user.id) fetchSettingsAndInfo()
  }, [user])

  useEffect(() => {
    let newFilledSteps = [] as string[]
    let newUnfilledSteps = [] as string[]

    CLIENT_STEPS_EDIT.forEach(step => {
      if (Object.values(pick(filledData, Object.keys(DATA_BY_STEP[step]))).every(elem => checkValue(elem))) {
        newFilledSteps = [...newFilledSteps, step]
      } else if (Object.values(pick(filledData, Object.keys(DATA_BY_STEP[step]))).some(elem => !checkValue(elem))) {
        newUnfilledSteps = [...newUnfilledSteps, step]
      }
    })
    setStepsInfo({ filledSteps: newFilledSteps, unfilledSteps: newUnfilledSteps })
    updatePercent()
  }, [filledData])

  const handleNext = () => {
    const currentIndex = CLIENT_STEPS_EDIT.indexOf(currentStep)
    if (currentIndex === CLIENT_STEPS_EDIT.length - 1) {
      return
    }
    dispatch(actions.setCurrentStep(CLIENT_STEPS_EDIT[currentIndex + 1]))
    dispatch(actions.setFilledList(CLIENT_STEPS_EDIT[currentIndex]))
  }
  const handleBack = () => {
    const index = CLIENT_STEPS_EDIT.indexOf(currentStep)
    if (index === 0) {
      return
    }
    dispatch(actions.setCurrentStep(CLIENT_STEPS_EDIT[index - 1]))
  }
  const handleSave = () => {
    dispatch(actions.updateClient(filledData, param.id))
  }
  const getStatus = (step: CLIENT_STEPS) => {
    const currentIndex = CLIENT_STEPS_EDIT.indexOf(step)
    const index = CLIENT_STEPS_EDIT.indexOf(currentStep)

    if (currentIndex === index) {
      return BRIEF_STEP_STATUS.CURRENT
    }
    if (stepsInfo.filledSteps.includes(step)) {
      return BRIEF_STEP_STATUS.PASSED
    }
    return BRIEF_STEP_STATUS.DEFAULT
  }

  const updatePercent = () => {
    const newFilledData: any = cloneDeep(filledData)
    if (newFilledData.taxTreatment === TAX_TREATMENT.NON_VAT_REGISTERED) {
      delete newFilledData.vat
    } else if (newFilledData.taxTreatment === TAX_TREATMENT.VAT_REGISTERED) {
      delete newFilledData.taxTreatment
    }
    const values = Object.values(newFilledData)
    const filled = values.filter(e => checkValue(e))
    const percent = (filled.length / values.length) * 100
    dispatch(actions.setPercent(Math.ceil(percent)))
  }

  const steps = () => (
    <div className="qu-stepper" style={{ margin: '20px 0' }}>
      <div className="steps profile-steps">
        {CLIENT_STEPS_EDIT.map(step => {
          return (
            <QBriefStep
              status={getStatus(step)}
              key={step}
              title={t(`clients.profile.stepper.${step}`, CLIENT_STEPS_NAMES[step])}
              onClickPath={() => {
                dispatch(actions.setCurrentStep(step))
              }}
            />
          )
        })}
      </div>
    </div>
  )

  if (!user.id || isLoading) return null


  return (
    <Fragment>
      {isUpdating && <SpinnerCenter />}
      {isMobile ? (
        <div className="layout profile">
          <div className="profile-main">
            <Row gutter={20}>
              <Col xs={24} sm={24} md={16}>
                <div className="profile-header">
                  <ClientsProfileHeader stepper={steps()} />
                </div>
              </Col>
              <Col xs={24}>
                <Row gutter={20}>
                  <Col xs={24} sm={24} md={16}>
                    <div className="profile-content">{children}</div>
                    <div>
                      {currentStep === CLIENT_STEPS.BASIC_INFORMATION && <ClientsProfileBody />}
                      {currentStep === CLIENT_STEPS.PRODUCT_TYPE && <ClientsProductBody />}
                      {currentStep === CLIENT_STEPS.TARGET_AUDIENCE && <ClientsTargetBody />}
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xs={24} sm={24} md={16}>
                <div className="profile-footer_custom">
                  <div className="left">
                    {currentStep.includes(CLIENT_STEPS.TARGET_AUDIENCE) ? (
                      <QButton onClick={handleBack} className="qu-button-outline mr-10">
                        <IconArrowLeft className="mr-5" />
                        {t('clients.back')}
                      </QButton>
                    ) : (
                      <QButton disabled={disabledSave} onClick={handleSave} className="qu-button-soft mr-10">
                        {t('clients.save_updates')}
                      </QButton>
                    )}
                  </div>
                  <div className="right">
                    {!(
                      currentStep.includes(CLIENT_STEPS.BASIC_INFORMATION) ||
                      currentStep.includes(CLIENT_STEPS.TARGET_AUDIENCE)
                    ) && (
                        <QButton onClick={handleBack} type="primary" className="qu-button-outline mr-15">
                          <IconArrowLeft className="mr-5" />
                          {t('clients.back')}
                        </QButton>
                      )}

                    {currentStep.includes(CLIENT_STEPS.TARGET_AUDIENCE) ? (
                      <QButton disabled={disabledSave} onClick={handleSave} className="qu-button-soft">
                        {t('clients.save_updates')}
                      </QButton>
                    ) : (
                      <QButton onClick={handleNext} type="primary">
                        {t('clients.next')}
                        <IconArrowRight className="ml-5" />
                      </QButton>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      ) : (
        <div className="layout profile">
          <div className="profile-main">
            <Row gutter={20}>
              <Col xs={24} sm={24} md={16}>
                <div className="profile-header">
                  <ClientsProfileHeader stepper={steps()} />
                </div>
              </Col>
              <Col xs={24}>
                <Row gutter={20}>
                  <Col xs={24} sm={24} md={16}>
                    <div className="profile-content">{children}</div>
                    <div>
                      {currentStep === CLIENT_STEPS.BASIC_INFORMATION && <ClientsProfileBody />}
                      {currentStep === CLIENT_STEPS.PRODUCT_TYPE && <ClientsProductBody />}
                      {currentStep === CLIENT_STEPS.TARGET_AUDIENCE && <ClientsTargetBody />}
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <div className="profile-sidebar">
                      <ClientSidebar
                        completion={percent}
                        title={t('clients.profile.profile_completion')}
                        currentStep={currentStep}
                        steps={Object.values(CLIENT_STEPS)}
                        filledSteps={stepsInfo.filledSteps}
                        filledPropertiesOfStep={Object.keys(stepData).map(e => checkValue(stepData[e]))}
                        filledStepsPropertiesLength={
                          Object.keys(filledData)
                            .map(e => checkValue(filledData[e]))
                            .filter(e => e).length
                        }
                        propertiesOfSteps={PropertiesOfSteps}
                        description=""
                        unfinishedSteps={stepsInfo.unfilledSteps}
                        titles={Object.values(CLIENT_STEPS_SIDE_BAR).map(el => t(`clients.profile.sidebar.stepper.titles.${el}`))}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xs={24} sm={24} md={16}>
                <div className="profile-footer_custom">
                  <div className="left">
                    {currentStep.includes(CLIENT_STEPS.TARGET_AUDIENCE) ? (
                      <QButton onClick={handleBack} className="qu-button-outline mr-10">
                        {
                          i18n.dir() === 'ltr' && <>
                            <IconArrowLeft className="mr-5" />
                            {t('clients.back')}
                          </>
                        }
                        {
                          i18n.dir() === 'rtl' && <>
                            {t('clients.back')}
                            <IconArrowRight className="mr-5" />
                          </>
                        }
                      </QButton>
                    ) : (
                      <QButton disabled={disabledSave} onClick={handleSave} className="qu-button-soft mr-10">
                        {t('clients.save_updates')}
                      </QButton>
                    )}
                  </div>
                  <div className="right">
                    {!(
                      currentStep.includes(CLIENT_STEPS.BASIC_INFORMATION) ||
                      currentStep.includes(CLIENT_STEPS.TARGET_AUDIENCE)
                    ) && (
                        <QButton onClick={handleBack} type="primary" className="qu-button-outline mr-15">
                          {
                            i18n.dir() === 'ltr' && <>
                              <IconArrowLeft className="mr-5" />
                              {t('clients.back')}
                            </>
                          }
                          {
                            i18n.dir() === 'rtl' && <>
                              {t('clients.back')}
                              <IconArrowRight className="mr-5" />
                            </>
                          }
                        </QButton>
                      )}

                    {currentStep.includes(CLIENT_STEPS.TARGET_AUDIENCE) ? (
                      <QButton disabled={disabledSave} onClick={handleSave} className="qu-button-soft">
                        {t('clients.save_updates')}
                      </QButton>
                    ) : (
                      <QButton onClick={handleNext} type="primary">
                        {
                          i18n.dir() === 'ltr' && <>
                            {t('clients.next')}
                            <IconArrowRight className="ml-5" />
                          </>
                        }
                        {
                          i18n.dir() === 'rtl' && <>
                            {t('clients.next')}
                            <IconArrowLeft className="mr-5" />
                          </>
                        }
                      </QButton>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      )}
    </Fragment>
  )
}

export default ProfileClientContainer
