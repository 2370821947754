import { createSelector } from 'reselect'
import { MODULE_NAME } from './supportTickets.constants'
import { IState, ISupportTicket } from './supportTickets.types'

const selectState = (state: { [MODULE_NAME]: any }) => state[MODULE_NAME]

export const getIsSupportTicketsListLoading = createSelector(selectState, (state: IState): boolean => state.supportTicketsListLoading)

export const getSupportTicketsList = createSelector(selectState, (state: IState): ISupportTicket[] =>
  state.supportTicketsList.results.map((supportTicket: ISupportTicket) => {
    return {
      ...supportTicket,
      key: supportTicket.id,
    }
  })
)

export const getSupportTicketsTotal = createSelector(selectState, (state: IState): number => state.supportTicketsList.total)

export const getPages = createSelector(selectState, (state: IState): { totalPages: number; currentPage: number } => state.pages)

export const getIsActionSupportTicketLoading = createSelector(selectState, (state: IState): boolean => state.supportTicketActionLoading)
