import React, { ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Col,
  IconsCommon,
  QButton,
  QRadio,
  Radio,
  Row,
} from "quantum_components";

import {
  BRIEF_PAYMENT_STATUS,
  BRIEF_PAYMENT_UPLOAD_TYPES,
  BRIEF_STATUS,
} from "src/constants/brief";
import { PERMISSIONS } from "src/constants/permission";
import { hasPermission } from "src/helpers/permission";
import { getFileNameFromUrl } from "src/utils";
import IUploadFile from "src/interfaces/IUploadFile";
import ILocalFile from "src/interfaces/ILocalFile";
import { isUploadFile } from "src/type-guards";
import * as coreActions from "src/modules/core/core.actions";
import {
  getCurrentUser,
  getCurrentUserPermissionActions,
} from "src/modules/core/core.selectors";
import { IBillPaymentUploadTypes } from "../../../bills/bills.types";

import { actions } from "../../brief.module";
import { IBriefResponse } from "../../brief.types";
import {
  getBriefViewPaymentFilesState,
  getBriefSettings,
} from "../../brief.selectors";

import { Tooltip } from "antd";


import UploadComponent from "src/components/UploadComponent";
import { useTranslation } from "react-i18next";

interface Props {
  briefView: IBriefResponse;
  onFilesChange?: (
    files: { files: File[]; filesType: IBillPaymentUploadTypes }[]
  ) => void;
  hideSubmitButton?: boolean;
}

export default function BriefPayment({
  briefView,
  onFilesChange = () => {},
  hideSubmitButton = false,
}: Props): ReactElement {
  const infoIcon = (
    <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 -8 30 30">
      <path d="M13 9h-2V7h2m0 10h-2v-6h2m-1-9A10 10 0 002 12a10 10 0 0010 10 10 10 0 0010-10A10 10 0 0012 2z" />
    </svg>
  )

  const { t } = useTranslation(["translationABrief", "translation"]);

  const permissions = useSelector(getCurrentUserPermissionActions);
  const dispatch = useDispatch();

  const currentUser = useSelector(getCurrentUser);

  const [allPaymentDetailsShown, setAllPaymentDetailsShown] = useState<boolean>(
    false
  );
  const [
    activeUploadType,
    setActiveUploadType,
  ] = useState<BRIEF_PAYMENT_UPLOAD_TYPES | null>(
    briefView.status === BRIEF_STATUS.PENDING ||
      briefView.status === BRIEF_STATUS.REJECTED
      ? null
      : BRIEF_PAYMENT_UPLOAD_TYPES.PO
  );

  const [localBankTransferFiles, setLocalBankTransferFiles] = useState<
    ILocalFile[]
  >([]);
  const [localPurchaseOrderFiles, setLocalPurchaseOrderFiles] = useState<
    ILocalFile[]
  >([]);

  const canUploadPurchaseOrder = Boolean(briefView.owner?.poEnabled);

  const hasUploadedPurchaseOrderFiles = Boolean(briefView.payment?.po?.length);
  const hasLocalPurchaseOrderFiles = Boolean(localPurchaseOrderFiles.length);

  const hasUploadedBankTransferFiles = Boolean(
    briefView.payment?.bankTransfer?.length
  );
  const hasLocalBankTransferFiles = Boolean(localBankTransferFiles.length);
  const settings = useSelector(getBriefSettings);

  const paymentFilesState = useSelector(getBriefViewPaymentFilesState);
  const purchaseOrderProcessing =
    paymentFilesState.po.isUploading || paymentFilesState.po.isRemoving;
  const bankTransferProcessing =
    paymentFilesState.bankTransfer.isUploading ||
    paymentFilesState.bankTransfer.isRemoving;

  const paymentInReview = Boolean(briefView.payment?.inReview);
  const briefPaymentStatus: BRIEF_PAYMENT_STATUS | undefined = briefView.payment
    ?.status as BRIEF_PAYMENT_STATUS;
  const isPaidOnline =
    [BRIEF_PAYMENT_STATUS.PAID, BRIEF_PAYMENT_STATUS.PAYOUT_COMPLETE].includes(
      briefPaymentStatus
    ) && Boolean(briefView.payment?.onlinePayment);
  const isPaidWithWallet =
    [BRIEF_PAYMENT_STATUS.PAID, BRIEF_PAYMENT_STATUS.PAYOUT_COMPLETE].includes(
      briefPaymentStatus
    ) && Boolean(briefView.payment?.isPaidByWallet);

  const [paymentProcessing, setPaymentProcessing] = useState<boolean>(false);

  useEffect(() => {
    const files: { files: File[]; filesType: IBillPaymentUploadTypes }[] = [
      {
        files: localBankTransferFiles.map(({ file }) => file),
        filesType: "bankTransfer",
      },
      {
        files: localPurchaseOrderFiles.map(({ file }) => file),
        filesType: "po",
      },
    ];

    onFilesChange(files);
  }, [localBankTransferFiles.length, localPurchaseOrderFiles.length]);

  useEffect(() => {
    if (Boolean(briefView.payment?.onlinePayment))
      setActiveUploadType(BRIEF_PAYMENT_UPLOAD_TYPES.ONLINE);
  }, [briefView.payment?.onlinePayment]);

  useEffect(() => {
    if (!!briefView.payment?.bankTransfer?.length) {
      setLocalBankTransferFiles([]);
      setActiveUploadType(BRIEF_PAYMENT_UPLOAD_TYPES.BANK_TRANSFER);
    }
  }, [briefView.payment?.bankTransfer?.length]);

  useEffect(() => {
    if (!!briefView.payment?.po?.length) {
      setLocalPurchaseOrderFiles([]);
      setActiveUploadType(BRIEF_PAYMENT_UPLOAD_TYPES.PO);
    }
  }, [briefView.payment?.po?.length]);

  const toggleAllPaymentDetailsShown = () =>
    setAllPaymentDetailsShown(() => !allPaymentDetailsShown);

  const handleUpload = () => {
    setPaymentProcessing(true);
    if (hasLocalBankTransferFiles) {
      dispatch(
        actions.uploadViewBriefPaymentFile({
          files: localBankTransferFiles.map(({ file }) => file),
          filesType: "bankTransfer",
        })
      );
    }

    if (hasLocalPurchaseOrderFiles) {
      dispatch(
        actions.uploadViewBriefPaymentFile({
          files: localPurchaseOrderFiles.map(({ file }) => file),
          filesType: "po",
        })
      );
    }
  };

  const handleRemove = (
    file: ILocalFile | IUploadFile,
    filesType: IBillPaymentUploadTypes
  ) => {
    if (isUploadFile(file)) {
      dispatch(actions.removeViewBriefPaymentFile(file, filesType));
      return;
    }

    switch (filesType) {
      case "po": {
        setLocalPurchaseOrderFiles([
          ...localPurchaseOrderFiles.filter((oldFile) => oldFile !== file),
        ]);
        return;
      }
      case "bankTransfer": {
        setLocalBankTransferFiles([
          ...localBankTransferFiles.filter((oldFile) => oldFile !== file),
        ]);
        return;
      }
      default:
        return;
    }
  };

  return (
    <>
      <div className="SS__category">
        <h4 className="SS__category-title">{t("payable_to")}</h4>

        <div className="SSUnit">
          <h4 className="SSUnit__title mb-10">
            {currentUser?.partnerData?.name ?? "-"}
          </h4>

          <div className="SSUnit__content">
            <ul className="BSUnit__list">
              <li className="BSUnit__list-item">
                {currentUser?.partnerData?.bankDetails?.address ?? "-"}
              </li>
              <li className="BSUnit__list-item">
                {t("vat")} {settings.partnerVat ?? "-"}
              </li>
              {allPaymentDetailsShown && (
                <>
                  <li className="BSUnit__list-item">
                    <span className="BSUnit__content-title">
                      {t("brief.payment.Bank_Name", { ns: "translation" })}:
                    </span>{" "}
                    {currentUser?.partnerData?.bankDetails?.bank_name ?? "-"}
                  </li>
                  <li className="BSUnit__list-item">
                    <span className="BSUnit__content-title">
                      {t("brief.payment.Address", { ns: "translation" })}:
                    </span>{" "}
                    {currentUser?.partnerData?.bankDetails?.bank_address ?? "-"}
                  </li>
                  <li className="BSUnit__list-item">
                    <span className="BSUnit__content-title">
                      {t("brief.payment.Account_No", { ns: "translation" })}:
                    </span>{" "}
                    {currentUser?.partnerData?.bankDetails?.account_number ??
                      "-"}
                  </li>
                  <li className="BSUnit__list-item">
                    <span className="BSUnit__content-title">
                      {t("brief.payment.IBAN_No", { ns: "translation" })}:
                    </span>{" "}
                    {currentUser?.partnerData?.bankDetails?.iban_number ?? "-"}{" "}
                  </li>
                  <li className="BSUnit__list-item">
                    <span className="BSUnit__content-title">
                      {t("brief.payment.Swift_Code", { ns: "translation" })}:
                    </span>{" "}
                    {currentUser?.partnerData?.bankDetails?.swift_code ?? "-"}
                  </li>
                  <li className="BSUnit__list-item">
                    <span className="BSUnit__content-title">
                      {t("brief.payment.Currency", { ns: "translation" })}:
                    </span>{" "}
                    {currentUser?.partnerData?.bankDetails?.account_currency ??
                      "-"}
                  </li>
                </>
              )}
            </ul>
            <div
              className="BSUnit__title cursorPointer mt-10 text-center"
              onClick={() => toggleAllPaymentDetailsShown()}
            >
              {t("see")} {allPaymentDetailsShown ? t("less") : t("more")}
              {allPaymentDetailsShown ? (
                <IconsCommon.IconArrowUp className="ml-5" />
              ) : (
                <IconsCommon.IconArrowDown className="ml-5" />
              )}
            </div>
          </div>
          <div className="SSUnit__content">
            <div className="brief-payment-inline-wrapper">
              {briefView?.payment?.quotation && (
                <UploadComponent
                  viewOnly
                  alternativeFileView
                  viewOnlyText={t("quotation")}
                  dragAndDrop={false}
                  files={[
                    {
                      url: briefView.payment.quotation,
                      name: getFileNameFromUrl(briefView.payment.quotation),
                    },
                  ]}
                  disableView={
                    !hasPermission(
                      permissions,
                      PERMISSIONS.CLIENT_BILL_VIEW_DETAILS
                    ) &&
                    !hasPermission(
                      permissions,
                      PERMISSIONS.MANAGE_PO_BANK_TRANSFER_PAYMENTS_FOR_BRIEFS
                    )
                  }
                  onView={async (file) => {
                    // if (isUploadFile(file)) {
                    //   const response: any = await dispatch(coreActions.downloadImage(file.url))
                    //   const url = URL.createObjectURL(response.data)

                    //   window.open(url, '_blank')
                    //   URL.revokeObjectURL(url)
                    //   return
                    // }

                    window.open(file.url, "_blank");
                    URL.revokeObjectURL(file.url);
                  }}
                />
              )}
              <div>
                {isPaidOnline && (
                  <div className="status-label approved">
                    {t("paid_online")}
                  </div>
                )}
                {isPaidWithWallet && (
                  <div className="status-label approved">
                    {t("paid_with_wallet")}
                  </div>
                )}
                {(isPaidOnline || isPaidWithWallet) && (
                  <div className="font-size-12 text-truncate tc--light text-center">
                    {t("brief.payment.pending_payout", { ns: "translation" })}
                  </div>
                )}
              </div>
            </div>

            {briefView?.payment?.invoice?.length && (
              <UploadComponent
                viewOnly
                alternativeFileView
                viewOnlyText={t("invoice")}
                dragAndDrop={false}
                files={briefView.payment.invoice.map((url) => ({
                  url,
                  name: getFileNameFromUrl(url),
                }))}
                disableView={
                  !hasPermission(
                    permissions,
                    PERMISSIONS.CLIENT_BILL_VIEW_DETAILS
                  ) &&
                  !hasPermission(
                    permissions,
                    PERMISSIONS.MANAGE_PO_BANK_TRANSFER_PAYMENTS_FOR_BRIEFS
                  )
                }
                onView={async (file) => {
                  if (isUploadFile(file)) {
                    const response: any = await dispatch(
                      coreActions.downloadImage(file.url)
                    );
                    const url = URL.createObjectURL(response.data);

                    window.open(url, "_blank");
                    URL.revokeObjectURL(url);
                    return;
                  }

                  window.open(file.url, "_blank");
                }}
              />
            )}
          </div>

          {/* {![BRIEF_PAYMENT_STATUS.PROCESSING, BRIEF_PAYMENT_STATUS.PAID].includes(briefPaymentStatus) &&
            !canUploadPurchaseOrder &&
            briefView.payment && (
              <>
                <h4 className="brief-payment-files-group-title">{t("bank_transfer")}:</h4>
                <UploadComponent
                  multiple
                  dragAndDrop={false}
                  maxCount={3}
                  accept={'.pdf, .jpg, .jpeg, .png'}
                  caption={['You can upload up to 3 files', 'Accepted file formats are: pdf, png, jpg, jpeg']}
                  files={
                    hasUploadedBankTransferFiles
                      ? [
                        ...localBankTransferFiles,
                        ...briefView.payment.bankTransfer!.map(url => ({
                          url,
                          name: getFileNameFromUrl(url),
                        })),
                      ]
                      : localBankTransferFiles
                  }
                  showRemoveButton={
                    hasPermission(permissions, PERMISSIONS.CLIENT_BILL_VIEW_DETAILS) &&
                    (briefPaymentStatus === BRIEF_PAYMENT_STATUS.REJECTED ||
                      (!hasUploadedBankTransferFiles && !paymentInReview))
                  }
                  showPreview={hasPermission(permissions, PERMISSIONS.CLIENT_BILL_VIEW_DETAILS)}
                  isRemoving={paymentFilesState.bankTransfer.isRemoving}
                  isUploading={paymentFilesState.bankTransfer.isUploading}
                  disabled={
                    !hasPermission(permissions, PERMISSIONS.CLIENT_BILL_VIEW_DETAILS) ||
                    paymentInReview ||
                    bankTransferProcessing
                  }
                  disableView={!hasPermission(permissions, PERMISSIONS.CLIENT_BILL_VIEW_DETAILS)}
                  onChange={(files: File[]) => {
                    const newFiles: ILocalFile[] = files.map(file => ({
                      name: file.name,
                      url: URL.createObjectURL(file),
                      file,
                    }))
                    setLocalBankTransferFiles([...newFiles, ...localBankTransferFiles])
                  }}
                  onRemove={file => handleRemove(file, 'bankTransfer')}
                  onView={async file => {
                    if (isUploadFile(file)) {
                      const response: any = await dispatch(coreActions.downloadImage(file.url))
                      const url = URL.createObjectURL(response.data)

                      window.open(url, '_blank')
                      URL.revokeObjectURL(url)
                      return
                    }

                    window.open(file.url, '_blank')
                  }}
                />
              </>
            )} */}

          {briefPaymentStatus === BRIEF_PAYMENT_STATUS.PAID &&
            !isPaidOnline &&
            hasUploadedBankTransferFiles && (
              <>
                <h4 className="brief-payment-files-group-title">
                  {t("bank_transfer")}:
                </h4>
                <UploadComponent
                  viewOnly
                  dragAndDrop={false}
                  files={briefView.payment.bankTransfer!.map((url) => ({
                    url,
                    name: getFileNameFromUrl(url),
                  }))}
                  showPreview={
                    hasPermission(
                      permissions,
                      PERMISSIONS.CLIENT_BILL_VIEW_DETAILS
                    ) ||
                    hasPermission(
                      permissions,
                      PERMISSIONS.MANAGE_PO_BANK_TRANSFER_PAYMENTS_FOR_BRIEFS
                    )
                  }
                  disableView={
                    !hasPermission(
                      permissions,
                      PERMISSIONS.CLIENT_BILL_VIEW_DETAILS
                    ) &&
                    !hasPermission(
                      permissions,
                      PERMISSIONS.MANAGE_PO_BANK_TRANSFER_PAYMENTS_FOR_BRIEFS
                    )
                  }
                  onView={async (file) => {
                    if (isUploadFile(file)) {
                      const response: any = await dispatch(
                        coreActions.downloadImage(file.url)
                      );
                      const url = URL.createObjectURL(response.data);

                      window.open(url, "_blank");
                      URL.revokeObjectURL(url);
                      return;
                    }

                    window.open(file.url, "_blank");
                  }}
                />
              </>
            )}

          {briefPaymentStatus === BRIEF_PAYMENT_STATUS.PAID &&
            !isPaidOnline &&
            hasUploadedPurchaseOrderFiles && (
              <>
                <h4 className="brief-payment-files-group-title">{t("po")}:</h4>
                <UploadComponent
                  viewOnly
                  dragAndDrop={false}
                  files={briefView.payment.po!.map((url) => ({
                    url,
                    name: getFileNameFromUrl(url),
                  }))}
                  showPreview={
                    hasPermission(
                      permissions,
                      PERMISSIONS.CLIENT_BILL_VIEW_DETAILS
                    ) ||
                    hasPermission(
                      permissions,
                      PERMISSIONS.MANAGE_PO_BANK_TRANSFER_PAYMENTS_FOR_BRIEFS
                    )
                  }
                  disableView={
                    !hasPermission(
                      permissions,
                      PERMISSIONS.CLIENT_BILL_VIEW_DETAILS
                    ) &&
                    !hasPermission(
                      permissions,
                      PERMISSIONS.MANAGE_PO_BANK_TRANSFER_PAYMENTS_FOR_BRIEFS
                    )
                  }
                  onView={async (file) => {
                    if (isUploadFile(file)) {
                      const response: any = await dispatch(
                        coreActions.downloadImage(file.url)
                      );
                      const url = URL.createObjectURL(response.data);

                      window.open(url, "_blank");
                      URL.revokeObjectURL(url);
                      return;
                    }

                    window.open(file.url, "_blank");
                  }}
                />
              </>
            )}
        </div>
      </div>

      {![BRIEF_PAYMENT_STATUS.PROCESSING, BRIEF_PAYMENT_STATUS.PAID].includes(
        briefPaymentStatus
      ) &&
        // canUploadPurchaseOrder &&
        briefView.payment && (
          <div className="SS__category">
            <h4 className="SS__category-title">
              {t("select_payment_method")}:
            </h4>
            <div className="SSUnit">
              <div className="SSUnit__content">
                {!hideSubmitButton &&
                  briefView.status === BRIEF_STATUS.PENDING && (
                    <p>
                      {t(
                        "Once your proposal is approved, you will be notified to complete payment."
                      )}
                    </p>
                  )}
                <Row gutter={16} align="middle">
                  <Radio.Group
                    value={activeUploadType}
                    onChange={(e) => {
                      if (e.target.value !== BRIEF_PAYMENT_UPLOAD_TYPES.ONLINE)
                        setActiveUploadType(e.target.value);
                    }}
                    disabled={
                      briefView.status === BRIEF_STATUS.PENDING ||
                      briefView.status === BRIEF_STATUS.REJECTED
                    }
                  >
                    {(briefView?.owner?.purchaseOrder ||
                      !!briefView?.payment?.po?.length) && (
                      <>
                        <Col className="mb-10">
                          <Radio
                            className="qu-radio mr-0"
                            disabled={hasLocalBankTransferFiles}
                            checked={
                              activeUploadType === BRIEF_PAYMENT_UPLOAD_TYPES.PO
                            }
                            value={BRIEF_PAYMENT_UPLOAD_TYPES.PO}
                          >
                            {t("submit_purchase_order")}
                          </Radio>
                        </Col>
                        {activeUploadType === BRIEF_PAYMENT_UPLOAD_TYPES.PO && (
                          <Col offset={3}>
                            <UploadComponent
                              multiple
                              viewOnly={
                                briefPaymentStatus ===
                                BRIEF_PAYMENT_STATUS.PO_ISSUED
                              }
                              dragAndDrop={false}
                              maxCount={3}
                              accept={".pdf, .jpg, .jpeg, .png"}
                              caption={[
                                "You can upload up to 3 files",
                                "Accepted file formats are: pdf, png, jpg, jpeg",
                              ]}
                              files={
                                hasUploadedPurchaseOrderFiles
                                  ? [
                                      ...localPurchaseOrderFiles,
                                      ...briefView.payment.po!.map((url) => ({
                                        url,
                                        name: getFileNameFromUrl(url),
                                      })),
                                    ]
                                  : localPurchaseOrderFiles
                              }
                              showRemoveButton={
                                (hasPermission(
                                  permissions,
                                  PERMISSIONS.BILL_CREATE_AND_EDIT
                                ) ||
                                  hasPermission(
                                    permissions,
                                    PERMISSIONS.MANAGE_PO_BANK_TRANSFER_PAYMENTS_FOR_BRIEFS
                                  )) &&
                                (briefPaymentStatus ===
                                  BRIEF_PAYMENT_STATUS.REJECTED ||
                                  (!hasUploadedPurchaseOrderFiles &&
                                    !paymentInReview))
                              }
                              showPreview={
                                hasPermission(
                                  permissions,
                                  PERMISSIONS.CLIENT_BILL_VIEW_DETAILS
                                ) ||
                                hasPermission(
                                  permissions,
                                  PERMISSIONS.MANAGE_PO_BANK_TRANSFER_PAYMENTS_FOR_BRIEFS
                                )
                              }
                              disabled={
                                briefView.status === BRIEF_STATUS.PENDING ||
                                briefView.status === BRIEF_STATUS.REJECTED ||
                                (!hasPermission(
                                  permissions,
                                  PERMISSIONS.BILL_CREATE_AND_EDIT
                                ) &&
                                  !hasPermission(
                                    permissions,
                                    PERMISSIONS.MANAGE_PO_BANK_TRANSFER_PAYMENTS_FOR_BRIEFS
                                  )) ||
                                paymentInReview ||
                                hasUploadedBankTransferFiles ||
                                hasLocalBankTransferFiles ||
                                purchaseOrderProcessing
                              }
                              disableView={
                                !hasPermission(
                                  permissions,
                                  PERMISSIONS.CLIENT_BILL_VIEW_DETAILS
                                ) &&
                                !hasPermission(
                                  permissions,
                                  PERMISSIONS.MANAGE_PO_BANK_TRANSFER_PAYMENTS_FOR_BRIEFS
                                )
                              }
                              isRemoving={paymentFilesState.po.isRemoving}
                              isUploading={paymentFilesState.po.isUploading}
                              onChange={(files: File[]) => {
                                const newFiles: ILocalFile[] = files.map(
                                  (file) => ({
                                    name: file.name,
                                    url: URL.createObjectURL(file),
                                    file,
                                  })
                                );
                                setLocalPurchaseOrderFiles([
                                  ...newFiles,
                                  ...localPurchaseOrderFiles,
                                ]);
                              }}
                              onRemove={(file) => handleRemove(file, "po")}
                              onView={async (file) => {
                                if (isUploadFile(file)) {
                                  const response: any = await dispatch(
                                    coreActions.downloadImage(file.url)
                                  );
                                  const url = URL.createObjectURL(
                                    response.data
                                  );

                                  window.open(url, "_blank");
                                  URL.revokeObjectURL(url);
                                  return;
                                }

                                window.open(file.url, "_blank");
                              }}
                            />
                          </Col>
                        )}
                      </>
                    )}

                    {(briefView?.owner?.bankTransfer ||
                      !!briefView?.payment?.bankTransfer?.length) && (
                      <>
                        <Col className="mt-10 mb-10">
                          <Radio
                            className="qu-radio mr-0"
                            disabled={hasLocalPurchaseOrderFiles}
                            checked={
                              activeUploadType ===
                              BRIEF_PAYMENT_UPLOAD_TYPES.BANK_TRANSFER
                            }
                            value={BRIEF_PAYMENT_UPLOAD_TYPES.BANK_TRANSFER}
                          >
                            {t("submit_bank_transfer_receipt")}
                          </Radio>
                        </Col>
                        {activeUploadType ===
                          BRIEF_PAYMENT_UPLOAD_TYPES.BANK_TRANSFER && (
                          <Col offset={3}>
                            <UploadComponent
                              multiple
                              dragAndDrop={false}
                              maxCount={3}
                              accept={".pdf, .jpg, .jpeg, .png"}
                              caption={[
                                "You can upload up to 3 files",
                                "Accepted file formats are: pdf, png, jpg, jpeg",
                              ]}
                              files={
                                hasUploadedBankTransferFiles
                                  ? [
                                      ...localBankTransferFiles,
                                      ...briefView.payment.bankTransfer!.map(
                                        (url) => ({
                                          url,
                                          name: getFileNameFromUrl(url),
                                        })
                                      ),
                                    ]
                                  : localBankTransferFiles
                              }
                              showRemoveButton={
                                (hasPermission(
                                  permissions,
                                  PERMISSIONS.BILL_CREATE_AND_EDIT
                                ) ||
                                  hasPermission(
                                    permissions,
                                    PERMISSIONS.MANAGE_PO_BANK_TRANSFER_PAYMENTS_FOR_BRIEFS
                                  )) &&
                                (briefPaymentStatus ===
                                  BRIEF_PAYMENT_STATUS.REJECTED ||
                                  (!hasUploadedBankTransferFiles &&
                                    !paymentInReview))
                              }
                              showPreview={
                                hasPermission(
                                  permissions,
                                  PERMISSIONS.CLIENT_BILL_VIEW_DETAILS
                                ) ||
                                hasPermission(
                                  permissions,
                                  PERMISSIONS.MANAGE_PO_BANK_TRANSFER_PAYMENTS_FOR_BRIEFS
                                )
                              }
                              isRemoving={
                                paymentFilesState.bankTransfer.isRemoving
                              }
                              isUploading={
                                paymentFilesState.bankTransfer.isUploading
                              }
                              disabled={
                                briefView.status === BRIEF_STATUS.PENDING ||
                                briefView.status === BRIEF_STATUS.REJECTED ||
                                paymentInReview ||
                                hasLocalPurchaseOrderFiles ||
                                bankTransferProcessing ||
                                (!hasPermission(
                                  permissions,
                                  PERMISSIONS.BILL_CREATE_AND_EDIT
                                ) &&
                                  !hasPermission(
                                    permissions,
                                    PERMISSIONS.MANAGE_PO_BANK_TRANSFER_PAYMENTS_FOR_BRIEFS
                                  ))
                              }
                              disableView={
                                !hasPermission(
                                  permissions,
                                  PERMISSIONS.CLIENT_BILL_VIEW_DETAILS
                                ) &&
                                !hasPermission(
                                  permissions,
                                  PERMISSIONS.MANAGE_PO_BANK_TRANSFER_PAYMENTS_FOR_BRIEFS
                                )
                              }
                              onChange={(files: File[]) => {
                                const newFiles: ILocalFile[] = files.map(
                                  (file) => ({
                                    name: file.name,
                                    url: URL.createObjectURL(file),
                                    file,
                                  })
                                );
                                setLocalBankTransferFiles([
                                  ...newFiles,
                                  ...localBankTransferFiles,
                                ]);
                              }}
                              onRemove={(file) =>
                                handleRemove(file, "bankTransfer")
                              }
                              onView={async (file) => {
                                if (isUploadFile(file)) {
                                  const response: any = await dispatch(
                                    coreActions.downloadImage(file.url)
                                  );
                                  const url = URL.createObjectURL(
                                    response.data
                                  );

                                  window.open(url, "_blank");
                                  URL.revokeObjectURL(url);
                                  return;
                                }

                                window.open(file.url, "_blank");
                              }}
                            />
                          </Col>
                        )}
                      </>
                    )}

                    {briefPaymentStatus !== BRIEF_PAYMENT_STATUS.PO_ISSUED &&
                      (briefView?.owner?.onlinePayment ||
                        !!briefView?.payment?.onlinePayment) && (
                        <Col className="mt-10 mb-10">
                          <Radio
                            className="qu-radio mr-0"
                            disabled={true}
                            value={BRIEF_PAYMENT_UPLOAD_TYPES.ONLINE}
                          >
                            {t("Use_online_payment")}
                            {
                              <Tooltip data-html="true" title={t("15% VAT is charged for all online payment transactions")}>
                                <span
                                  style={{
                                    marginLeft: "5px",
                                    marginRight: "5px",
                                    fontWeight: "bold",
                                    fontSize: "12px",
                                    fontStyle: "italic",
                                    color: "#aaaaaa",
                                    cursor: "pointer",
                                  }}
                                >
                                  {infoIcon}
                                </span>
                              </Tooltip>
                            }
                            <br />
                            <span className="online_payment_sub_label">
                              {t("Powered by Quantum")}
                            </span>
                          </Radio>
                        </Col>
                      )}
                  </Radio.Group>
                </Row>
              </div>
            </div>
          </div>
        )}

      {!hideSubmitButton &&
        ![BRIEF_PAYMENT_STATUS.PROCESSING, BRIEF_PAYMENT_STATUS.PAID].includes(
          briefPaymentStatus
        ) && (
          <div className="SS__category">
            <div className="SSUnit">
              <QButton
                disabled={
                  briefView.status === BRIEF_STATUS.PENDING ||
                  briefView.status === BRIEF_STATUS.REJECTED ||
                  paymentProcessing ||
                  purchaseOrderProcessing ||
                  bankTransferProcessing ||
                  paymentInReview ||
                  (!hasLocalPurchaseOrderFiles && !hasLocalBankTransferFiles)
                }
                type="primary"
                className="justify-center full-width"
                onClick={handleUpload}
              >
                {t("Make_Payment")}
              </QButton>
            </div>
          </div>
        )}
    </>
  );
}
