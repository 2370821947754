import React from 'react'
import { Col, IconsCommon, Modal, QButton, Row } from 'quantum_components'

interface Props {
  visible: boolean
  onCancel: () => void
  onSubmit: () => void
}

const AcceptBillingModal = ({ visible, onCancel, onSubmit }: Props) => (
  <Modal
    wrapClassName="qu-modal"
    closeIcon={<IconsCommon.IconClose />}
    title="Accept"
    visible={visible}
    onCancel={onCancel}
    footer={
      <Row align="middle" justify="end">
        <Col>
          <QButton className="qu-button-outline mr-16" size="small" onClick={onCancel}>
            Cancel
          </QButton>
        </Col>
        <Col>
          <QButton className="w-100" type="primary" size="small" onClick={onSubmit}>
            Accept
          </QButton>
        </Col>
      </Row>
    }
  >
    <p>Do you want accept the payment?</p>
  </Modal>
)

export default AcceptBillingModal
