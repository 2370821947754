import IAction from "../../../interfaces/IAction";
import * as CONSTANTS from "./profile.constants";
import { PARTNER_STEPS, PRICING_MODEL } from "../../../constants/profile";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { requestHttp, urls } from "../../../api";
import { getResponseErrorMessage } from "../../../helpers";
import {
  fetchPublicProfileFailure,
  fetchPublicProfileSuccess,
} from "../publicProfile/publicProfile.actions";
import { IForm } from "./profile.types";
import { PARTNER_UPLOAD_TYPES } from "src/constants/partner";
import { upload } from "../../core/core.actions";
import { UPLOAD_ENTITY_KEYS } from "src/constants/upload";
import { getForm } from "./profile.selectors";
import { cloneDeep } from "lodash";
import { MEDIA_TYPES } from "src/constants/brief";
import { AxiosResponse } from "axios";
import { calculateProfilePercent, checkPartnerForm } from "src/helpers/profile";
import { STATUS_CODES } from "src/constants";

export interface Step {
  name: string;
  status: string;
}
export const setCurrentStep = (step: PARTNER_STEPS): IAction => ({
  type: CONSTANTS.SET_CURRENT_STEP,
  payload: { step },
});

export const setUnfinishedList = (step: string): IAction => ({
  type: CONSTANTS.SET_UNFINISHED,
  payload: { step },
});

export const setLastFilled = (step: PARTNER_STEPS): IAction => ({
  type: CONSTANTS.SET_LAST_FINISHED,
  payload: { step },
});

export const setFilledList = (step: string): IAction => ({
  type: CONSTANTS.SET_FILLED,
  payload: { step },
});

export const setPartnerSettings = (settings: boolean[]): IAction => ({
  type: CONSTANTS.SET_PARTNER_SETTING,
  payload: { settings },
});

export const fetchPartnerProfileSuccess = (partnerProfile: IForm): IAction => ({
  type: CONSTANTS.FETCH_PARTNER_PROFILE_SUCCESS,
  payload: { partnerProfile },
});
export const fetchPartnerProfileLoading = (loading: boolean): IAction => ({
  type: CONSTANTS.FETCH_PARTNER_PROFILE_LOADING,
  payload: { loading },
});
export const fetchPartnerProfileFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_PARTNER_PROFILE_FAILURE,
  error,
});

export const createCustomMediaSuccess = (data: any): IAction => ({
  type: CONSTANTS.CREATE_CUSTOM_MEDIA_SUCCESS,
  payload: { data },
});
export const createCustomMediaLoading = (loading: boolean): IAction => ({
  type: CONSTANTS.CREATE_CUSTOM_MEDIA_REQUEST,
  payload: { loading },
});
export const createCustomMediaFailure = (error: string): IAction => ({
  type: CONSTANTS.CREATE_CUSTOM_MEDIA_FAILURE,
  error,
});

export const getAllCustomMediaSuccess = (data: any): IAction => ({
  type: CONSTANTS.GET_ALL_CUSTOM_MEDIA_SUCCESS,
  payload: { data },
});
export const getAllCustomMediaLoading = (loading: boolean): IAction => ({
  type: CONSTANTS.GET_ALL_CUSTOM_MEDIA_REQUEST,
  payload: { loading },
});
export const getAllCustomMediaFailure = (error: string): IAction => ({
  type: CONSTANTS.GET_ALL_CUSTOM_MEDIA_FAILURE,
  error,
});

export const fetchPartnerProfileRequest = (): IAction => ({
  type: CONSTANTS.FETCH_PARTNER_PROFILE_REQUEST,
});

export const fetchPartnerProfileSave = (
  payload: any,
  id: string
): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    dispatch(fetchPartnerProfileRequest());
    const response = await requestHttp.patch(
      urls.getPartnerProfileSaveUpdatesUrl(id),
      {
        ...payload,
        impressionRate:
          payload.mbPricingModel === PRICING_MODEL.DURATION
            ? null
            : payload.impressionRate,
        conversionRate:
          payload.mbPricingModel === PRICING_MODEL.DURATION
            ? null
            : payload.conversionRate,
        reachRate:
          payload.mbPricingModel === PRICING_MODEL.DURATION
            ? null
            : payload.reachRate,
        vat: payload.vat ? payload.vat : null,
      }
    );
    const { content } = response.data.data;
    await dispatch(fetchPartnerProfileSuccess(content));
  } catch (error) {
    dispatch(fetchPartnerProfileFailure(getResponseErrorMessage(error)));
  }
};

export const fetchPartnerProfileSettings = (): ThunkAction<
  void,
  {},
  {},
  AnyAction
> => async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
  try {
    const response = await requestHttp.get(`${urls.getPartnerSettingsUrl()}`);
    const { content } = response.data.data;
    dispatch(fetchAgeGroups(content));
  } catch (error) {
    dispatch(fetchPartnerProfileFailure(getResponseErrorMessage(error)));
  }
};

export const fetchAgeGroups = (
  settings: any
): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    const response = await requestHttp.get(urls.getAgeGroupsListUrl());
    if (response.status === STATUS_CODES.BASE_SUCCESS) {
      const { content } = response.data.data;
      settings.ageGroups = content;
      dispatch(fetchTargetSegments(settings));
    }
  } catch (error) {
    dispatch(fetchPartnerProfileFailure(getResponseErrorMessage(error)));
  }
};

export const fetchAllCustomMedia = (): ThunkAction<
  void,
  {},
  {},
  AnyAction
> => async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
  try {
    const response = await requestHttp.get(urls.getAllCustomMediaUrl());
    if (response.status === STATUS_CODES.BASE_SUCCESS) {
      const { content } = response.data.data;
      dispatch(getAllCustomMediaSuccess(content));
    }
  } catch (error) {
    dispatch(fetchPartnerProfileFailure(getResponseErrorMessage(error)));
  }
};

export const createCustomMediaType = (
  mediaType: any
): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    dispatch(createCustomMediaLoading(true));
    const response = await requestHttp.post(
      urls.createCustomMediaUrl(),
      mediaType
    );

    if (response.status === STATUS_CODES.BASE_SUCCESS) {
      dispatch(createCustomMediaLoading(false));
    }
  } catch (error) {
    dispatch(fetchPartnerProfileFailure(getResponseErrorMessage(error)));
  }
};

export const updateCustomMediaType = (
  mediaType: any
): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    dispatch(createCustomMediaLoading(true));
    const response = await requestHttp.put(
      urls.updateCustomMediaUrl(),
      mediaType
    );

    if (response.status === STATUS_CODES.BASE_SUCCESS) {
      dispatch(createCustomMediaLoading(false));
    }
  } catch (error) {
    dispatch(fetchPartnerProfileFailure(getResponseErrorMessage(error)));
  }
};

export const deleteCustomMediaType = (
  id: number
): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    dispatch(createCustomMediaLoading(true));
    const response = await requestHttp.delete(urls.deleteCustomMediaUrl(id));

    if (response.status === STATUS_CODES.BASE_SUCCESS) {
      dispatch(createCustomMediaLoading(false));
    }
  } catch (error) {
    dispatch(fetchPartnerProfileFailure(getResponseErrorMessage(error)));
  }
};

export const fetchTargetSegments = (
  settings: any
): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    const response = await requestHttp.get(urls.getSegmentsListUrl());
    if (response.status === STATUS_CODES.BASE_SUCCESS) {
      const { content } = response.data.data;
      settings.segments = content;
      dispatch(setPartnerSettings(settings));
    }
  } catch (error) {
    dispatch(fetchPartnerProfileFailure(getResponseErrorMessage(error)));
  }
};

export const setForm = (form: {}): IAction => ({
  type: CONSTANTS.SET_FORM,
  payload: { form },
});

export const fetchPartnerProfileAsync = async (id: string) => {
  try {
    const response = await requestHttp.get(urls.getPartnerProfileUrl(id));
    const { content } = response.data.data;
    return content;
  } catch (error) {
    return null
  }
};

export const fetchPartnerProfile = (
  id: string
): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
  getState: any
): Promise<void> => {
  try {
    dispatch(fetchPartnerProfileLoading(true));
    dispatch(fetchPartnerProfileRequest());
    const response = await requestHttp.get(urls.getPartnerProfileUrl(id));
    const { content } = response.data.data;

    dispatch(fetchPartnerProfileSuccess(content));
    const {
      listingShortDescription,
      companyLogo,
      previewCardImage,
      listingHeaderImage,
      highlights,
      metricsMarketing,
    } = content.publicProfile;
    dispatch(
      fetchPublicProfileSuccess({
        ...content.publicProfile,
        listingShortDescription: listingShortDescription
          ? listingShortDescription
          : "",
        companyLogo: companyLogo ? companyLogo : "",
        previewCardImage: previewCardImage ? previewCardImage : "",
        listingHeaderImage: listingHeaderImage ? listingHeaderImage : "",
        highlights: highlights ? highlights : [],
        metricsMarketing: metricsMarketing ? metricsMarketing : [],
      })
    );
    dispatch(fetchPartnerProfileLoading(false));

    dispatch(setFormBoolean(checkPartnerForm(content)));
    const {
      partnerProfile: { formBoolean },
    } = getState();
    dispatch(setPartnerProfilePercent(calculateProfilePercent(formBoolean)));
  } catch (error) {
    dispatch(fetchPartnerProfileFailure(getResponseErrorMessage(error)));
    dispatch(fetchPublicProfileFailure(getResponseErrorMessage(error)));
  }
};

export const setPartnerProfilePercent = (percent: number) => ({
  type: CONSTANTS.SET_PARTNER_PROFILE_PERCENT,
  percent: percent,
});

export const setFormBoolean = (formBoolean: {}): IAction => ({
  type: CONSTANTS.SET_FORM_BOOLEAN,
  payload: { formBoolean },
});
export const unMountClearDataPartnerProfile = (): IAction => ({
  type: CONSTANTS.UNMOUNT_CLEAR_DATA,
});
export const imageUploadRequest = (): IAction => ({
  type: CONSTANTS.IMAGE_UPLOAD_REQUEST,
});

export const imageUploadSuccess = (form: any): IAction => ({
  type: CONSTANTS.IMAGE_UPLOAD_SUCCESS,
  payload: { form },
});

export const imageUploadFailure = (error: string): IAction => ({
  type: CONSTANTS.IMAGE_UPLOAD_FAILURE,
  error,
});
export const uploadProductImage = (
  partnerId: number,
  files: File[],
  entity: PARTNER_UPLOAD_TYPES,
  nameMediaType: MEDIA_TYPES,
  subNameMediaType?: string
): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
  getState: any
): Promise<void> => {
  try {
    const form = getForm(getState());
    if (!partnerId) {
      return;
    }
    dispatch(imageUploadRequest());
    const uploadUrl = urls.getMediaTypesUploadUrl();

    const response = await upload(
      uploadUrl,
      files,
      partnerId,
      entity,
      UPLOAD_ENTITY_KEYS.PARTNER_ID
    );
    if (response.status === 201) {
      const { content } = response.data.data;
      const newForm = cloneDeep(form);

      const check = newForm.mediaBuyTypes.find((i) =>
        subNameMediaType
          ? i.subName === subNameMediaType
          : i.name === nameMediaType
      );
      if (check) {
        newForm.mediaBuyTypes = newForm.mediaBuyTypes.map((type) => {
          if (subNameMediaType && type.subName === subNameMediaType) {
            return { ...type, previewImage: content[0] };
          } else if (!subNameMediaType && type.name === nameMediaType) {
            return { ...type, previewImage: content[0] };
          }
          return type;
        });
      } else {
        newForm.mediaBuyTypes = [
          ...newForm.mediaBuyTypes,
          {
            name: nameMediaType,
            subName: subNameMediaType,
            previewImage: content[0],
          },
        ];
      }

      dispatch(imageUploadSuccess(newForm));
    }
  } catch (error) {
    dispatch(imageUploadFailure(error));
  }
};
export const removeImage = (
  nameMediaType: MEDIA_TYPES,
  subNameMediaType?: string
): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
  getState: any
): Promise<any> => {
  try {
    const form = getForm(getState());
    dispatch(imageRemoveRequest());

    const newForm = cloneDeep(form);

    newForm.mediaBuyTypes = newForm.mediaBuyTypes.map((type) => {
      if (subNameMediaType && type.subName === subNameMediaType) {
        return { ...type, previewImage: undefined };
      } else if (!subNameMediaType && type.name === nameMediaType) {
        return { ...type, previewImage: undefined };
      }
      return type;
    });
    dispatch(imageRemoveSuccess(newForm));
    return null;
  } catch (error) {
    dispatch(imageRemoveFailure(error));
    return error;
  }
};

export const imageRemoveRequest = (): IAction => ({
  type: CONSTANTS.IMAGE_REMOVE_REQUEST,
});

export const imageRemoveSuccess = (form: any): IAction => ({
  type: CONSTANTS.IMAGE_REMOVE_SUCCESS,
  payload: { form },
});

export const imageRemoveFailure = (error: string): IAction => ({
  type: CONSTANTS.IMAGE_REMOVE_FAILURE,
  error,
});
