import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useReduxDispatch } from "src/helpers";
import { isMobile } from "react-device-detect";
import * as actions from "../request.actions";
import * as usersActions from "../../../users/users.actions";
import {
  Col,
  QBackLink,
  QButton,
  qnotification,
  Row,
} from "quantum_components";
import { useSelector } from "react-redux";
import {
  getOverrideRequestPaymentLoading,
  getRequestDetails,
  getRequestDetailsLoading,
  getUpdatingRequest,
} from "../request.selectors";
import {
  BRIEF_PAYMENT_STATUS,
  BRIEF_PRINT_ID,
  BRIEF_STATUS,
  BRIEF_TYPES,
  DIALOG_MODALS,
} from "../../../../constants/brief";
import BriefSwitch from "../../../brief/containers/components/Brief.Switch";
import BriefCartSummary from "../../../brief/containers/components/Brief.CartSummary";
import ServiceCard from "../../../brief/containers/components/Brief.Service";
import BriefProduct from "../../../brief/containers/components/Brief.Product";
import BriefMedia from "../../../brief/containers/components/Brief.Media";
import BriefTargeting from "../../../brief/containers/components/Brief.Targeting";
import BriefAdditional from "../../../brief/containers/components/Brief.Additional";
import BriefViewSummary from "../../../brief/containers/components/Brief.View.Summary";
import * as briefActions from "../../../brief/brief.actions";

// import TestahelBriefCartSummary from '../../../testahelBox/containers/components/TestahelBrief.CartSummary'
// import TestahelServiceCard from '../../../testahelBox/containers/components/TestahelBrief.Service'
// import TestahelBriefProduct from '../../../testahelBox/containers/components/TestahelBrief.Product'
// import TestahelBriefMedia from '../../../testahelBox/containers/components/TestahelBrief.Media'
// import TestahelBriefTargeting from '../../../testahelBox/containers/components/TestahelBrief.Targeting'
// import TestahelBriefAdditional from '../../../testahelBox/containers/components/TestahelBrief.Additional'
// import TestahelBriefViewSummary from '../../../testahelBox/containers/components/TestahelBrief.View.Summary'

import SpinnerCenter from "../../../../components/Spinner/spinner";
import RequestForm from "./components/RequestAside.Form";
import { REQUEST_ACTIONS, REQUEST_STATUS } from "../../../../constants/request";
import {
  daysPastFromToday,
  getDaysAgoText,
} from "../../../../helpers/datesHelper";
import moment from "moment";
import { FORMATS } from "../../../../constants";
import * as campaignActions from "../../../campaign/campaign.actions";
import { TABS_NAMES } from "../../../../constants/tabs";
import { calcDistributionQuantity } from "../../../../helpers/cart";
import { getCampaignUpdating } from "../../../campaign/campaign.selectors";
import { getCurrentUser, getCurrentUserPermissionActions } from "../../../core/core.selectors";
import { hasPermission } from "../../../../helpers/permission";
import { PERMISSIONS } from "../../../../constants/permission";
import DialogModal from "../../../../components/DialogModal/DialogModal";
import { getModalData } from "../../../../helpers/request";
import { requestHttp, urls } from "../../../../api";
import NOTIFICATION_TYPES from "../../../../constants/notificationTypes";
import { useTranslation } from "react-i18next";

export default function RequestDetailsContainer(): ReactElement {
  const { t } = useTranslation(['translationABrief'])
  const history = useHistory();
  const dispatch = useReduxDispatch();
  const requestDetails = useSelector(getRequestDetails);


  const isPaymentEnabled = requestDetails?.briefOwner?.paymentEnabled;

  const requestDetailsLoading = useSelector(getRequestDetailsLoading);
  const overrideRequestPaymentLoading = useSelector(
    getOverrideRequestPaymentLoading
  );
  const updatingRequest = useSelector(getUpdatingRequest);
  const updatingCampaign = useSelector(getCampaignUpdating);
  const permissions = useSelector(getCurrentUserPermissionActions);
  const currentUser = useSelector(getCurrentUser)

  const [showSpinner, setShowSpinner] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [dialogModal, setDialogModal] = useState<DIALOG_MODALS | null>(null);
  const { briefId, requestId } = useParams<{ briefId: any, requestId: any }>();

  useEffect(() => {
    dispatch(actions.fetchRequestDetails(requestId));
    dispatch(usersActions.fetchPartnerSettings());

    return () => {
      dispatch(actions.initialiseRequestDetails())
    }
  }, []);

  useEffect(() => {
    if (!requestDetails) return;

    if (!requestDetails.campaignId && requestDetails?.status === REQUEST_STATUS.ACCEPTED && requestDetails?.brief?.status !== BRIEF_STATUS.MISSING_INFO && hasPermission(permissions, PERMISSIONS.CAMPAIGN_CREATE) && (requestDetails?.isPaymentOverridden || (requestDetails?.brief?.payment?.status === BRIEF_PAYMENT_STATUS.PAID || requestDetails?.brief?.payment?.status === BRIEF_PAYMENT_STATUS.PO_ISSUED)) && !hasPendingWorkflowElements && ((currentUser.workflowEnabled && requestDetails.brief.workflow.length) || !currentUser.workflowEnabled)) {
      handleDialogModal(DIALOG_MODALS.CREATE_CAMPAIGN)
    }

  }, [requestDetails])

  const changeSwitch = (e: any) => {
    const { value } = e.target;
    setShowSummary(value);
  };

  if (!requestDetails || requestDetailsLoading) {
    return <SpinnerCenter />;
  }

  const brief: any = requestDetails.brief;
  const cart: any = requestDetails.cart;

  const partnerQuantity = calcDistributionQuantity(cart.additional.items);
  const isProductSample = brief.briefTypes?.includes(
    BRIEF_TYPES.PRODUCT_SAMPLE
  );
  const isCustomizeBox = brief.briefTypes?.includes(
    BRIEF_TYPES.CUSTOMIZED_TESTAHEL_BOX
  );
  const isConsumerResearch = brief.briefTypes?.includes(
    BRIEF_TYPES.CONSUMER_RESEARCH
  );
  const isMediaBuying = brief.briefTypes?.includes(BRIEF_TYPES.MEDIA_BUYING);
  const isPending = requestDetails.status === REQUEST_STATUS.PENDING;
  const isAccepted = requestDetails.status === REQUEST_STATUS.ACCEPTED;
  const isExpired = requestDetails.status === REQUEST_STATUS.EXPIRED;
  const requestHasCampaign = Boolean(requestDetails.campaignId);



  const disableAssignToPartner =
    updatingRequest ||
    !hasPermission(permissions, PERMISSIONS.REQUEST_ASSIGN_TO_PARTNER) ||
    (!requestDetails.isPaymentOverridden &&
      !requestHasCampaign &&
      ![BRIEF_PAYMENT_STATUS.PAID, BRIEF_PAYMENT_STATUS.PO_ISSUED].includes(
        requestDetails.brief.payment?.status
      ));

  const hasPendingWorkflowElements = requestDetails.brief.workflow.some(
    (el: any) => el.processed === false
  );

  const disableAddToCampaign =
    updatingCampaign ||
    !hasPermission(permissions, PERMISSIONS.CAMPAIGN_CREATE);

  const showOverridePaymentButton =
    !requestDetails.isPaymentOverridden &&
    !requestHasCampaign &&
    ![BRIEF_PAYMENT_STATUS.PAID, BRIEF_PAYMENT_STATUS.PO_ISSUED].includes(
      requestDetails.brief.payment?.status
    );

  const summaryTitle = `${t('received_from')} ${requestDetails.briefOwner?.companyName || ""
    }`;

  const summarySubtitle = () => {
    const dateMoment = moment(requestDetails.approvedDate);
    const approvedDate = moment(requestDetails.approvedDate).format(
      FORMATS.DATE_LL_FORMAT
    );
    const days = daysPastFromToday(dateMoment);
    const text = getDaysAgoText(days);

    return `${approvedDate}. (${t(text, { days })})`;
  };
  const handleAssign = async () => {
    await dispatch(
      actions.updateRequest(requestId, REQUEST_ACTIONS.ADMIN_APPROVE)
    );
    await dispatch(actions.fetchRequestDetails(requestId));
  };

  const handleOverridePayment = async () => {
    await dispatch(actions.overrideRequestPayment(requestId));
  };

  const handleDialogModal = (type: DIALOG_MODALS) => {
    setDialogModal(type);
  };

  const closeModal = () => {
    setDialogModal(null);
  };

  const handleExtend = async () => {
    await dispatch(actions.extendRequest(requestId));
    await dispatch(actions.fetchRequestDetails(requestId));
  };

  const handleBack = () => {
    dispatch(briefActions.setActiveTab(TABS_NAMES.REQUESTS));
    history.goBack();
  };

  const handleAddToCampaign = async () => {
    await dispatch(campaignActions.upsertCampaign(requestId));
  };

  const handleAssignToPartner = async () => {
    const response = await dispatch(actions.fetchRequestDetails(requestId));
    const partnerDataResponse = await requestHttp.get(
      urls.getPartnerProfileUrl(String(response.data.data.content.userId))
    );

    if (partnerDataResponse.status === 200) {
      const vat = partnerDataResponse.data.data.content.vat;
      if (!vat) {
        qnotification({
          type: NOTIFICATION_TYPES.ERROR,
          message: t('assignment_error_msg'),
          duration: 30,
          description:
            t('assignment_error_desc'),
        });
        return;
      }
    }

    handleDialogModal(DIALOG_MODALS.ASSIGN_PARTNER);
  };

  const renderForm = isAccepted && requestDetails.brief.status === BRIEF_STATUS.APPROVED && requestDetails.brief.payment.status === BRIEF_PAYMENT_STATUS.PENDING && !requestDetails.isPaymentOverridden ? (
    <>
      {/*<QButton
        disabled={disableAssignToPartner}
        type={'primary'}
        onClick={handleAssignToPartner}
        className={'full-width mv-20'}
      >
        Assign to Partner
      </QButton>
      */}
      {showOverridePaymentButton && (
        <QButton
          disabled={
            !hasPermission(permissions, PERMISSIONS.BILL_OVERRIDE) ||
            overrideRequestPaymentLoading
          }
          type={"primary"}
          onClick={() => handleDialogModal(DIALOG_MODALS.OVERRIDE_PAYMENT)}
          className="full-width mv-20"
        >
          {t('override_payment')}
        </QButton>
      )}
    </>
  ) : (
    !isExpired && <RequestForm briefId={briefId} requestId={requestId} />
  );

  const modalData = getModalData(dialogModal);

  const onProceed = async () => {
    setShowSpinner(true);

    if (dialogModal === DIALOG_MODALS.ASSIGN_PARTNER) {
      await handleAssign();
      setShowSpinner(false);
      closeModal();
    }

    if (dialogModal === DIALOG_MODALS.OVERRIDE_PAYMENT) {
      await handleOverridePayment();
      setShowSpinner(false);
      closeModal();
      await dispatch(actions.fetchRequestDetails(requestId));
    }

    if (dialogModal === DIALOG_MODALS.CREATE_CAMPAIGN) {
      await handleAddToCampaign();
      setShowSpinner(false);
      closeModal();
    }
  };

  return (
    <div className="qu-brief-view pb-40">
      <QBackLink title={t('back_to_requests')} onClick={handleBack} />
      {isMobile ? (
        <>
          <BriefSwitch onChange={changeSwitch} value={showSummary} />
          {showSummary ? (
            <>
              {
                <BriefCartSummary
                  setCurrentStep={() => null}
                  cart={cart}
                  onEdit={() => null}
                  viewMode
                  hideProposalButtons
                  hidePaymentSection
                />
              }
              {renderForm}
              {isExpired && (
                <QButton
                  disabled={
                    !hasPermission(permissions, PERMISSIONS.REQUEST_EXTEND)
                  }
                  type={"primary"}
                  onClick={handleExtend}
                  className={"full-width mv-20"}
                >
                  {t('extend_request')}
                </QButton>
              )}
            </>
          ) : (
            <>
              {
                <>
                  <ServiceCard
                    isProductSample={isProductSample}
                    isMediaBuying={isMediaBuying}
                    onEdit={() => null}
                    viewMode
                  />
                  <BriefProduct
                    briefView={brief}
                    onEdit={() => null}
                    viewMode
                  />
                  <BriefMedia briefView={brief} onEdit={() => null} viewMode />
                  <BriefTargeting
                    briefView={brief}
                    onEdit={() => null}
                    viewMode
                  />
                  <BriefAdditional
                    briefView={brief}
                    onEdit={() => null}
                    viewMode
                  />
                </>
              }
            </>
          )}
        </>
      ) : (
        <Row gutter={20} className="pt-20" id={BRIEF_PRINT_ID}>
          <Col span={16}>
            {
              <BriefViewSummary
                briefView={brief}
                setCurrentStep={() => null}
                title={summaryTitle}
                subtitle={summarySubtitle()}
                partnerQuantity={partnerQuantity}
                viewMode
                requestType={requestDetails.type}
                partnerIds={[requestDetails.partnerId]}
              />
            }
          </Col>
          <Col span={8}>
            {
              <BriefCartSummary
                setCurrentStep={() => null}
                cart={cart}
                onEdit={() => null}
                viewMode
                hideProposalButtons
                hidePaymentSection
              />
            }
            {renderForm}
            {isExpired && (
              <QButton
                disabled={
                  !hasPermission(permissions, PERMISSIONS.REQUEST_EXTEND)
                }
                type={"primary"}
                onClick={handleExtend}
                className={"full-width mv-20"}
                data-html2canvas-ignore
              >
                {t('extend_request')}
              </QButton>
            )}

            {!requestDetails.campaignId && isAccepted && hasPermission(permissions, PERMISSIONS.CAMPAIGN_CREATE) && (requestDetails?.isPaymentOverridden || (requestDetails?.brief?.payment?.status === BRIEF_PAYMENT_STATUS.PAID || requestDetails?.brief?.payment?.status === BRIEF_PAYMENT_STATUS.PO_ISSUED) && ((currentUser.workflowEnabled && requestDetails.brief.workflow.length) || !currentUser.workflowEnabled)) &&
              (
                <QButton
                  title={hasPendingWorkflowElements ? t('workflow_error_campaign') : ''}
                  disabled={disableAddToCampaign || hasPendingWorkflowElements || requestDetails?.brief?.status === BRIEF_STATUS.MISSING_INFO}
                  onClick={() => handleDialogModal(DIALOG_MODALS.CREATE_CAMPAIGN)}
                  type={"primary"}
                  className={"full-width mv-20"}
                  data-html2canvas-ignore
                >
                  {t('add_to_campaign')}
                </QButton>
              )}

            <QButton
              type={"primary"}
              onClick={() => history.push(`/calendar`)}
              className={"full-width mt-10 qu-button-soft"}
              data-html2canvas-ignore
            >
              {t('view_calendar')}
            </QButton>
          </Col>
        </Row>
      )}

      {showSpinner && <SpinnerCenter />}

      {dialogModal && (
        <DialogModal
          modalOpen={!!dialogModal}
          question={t(modalData.question)}
          description={t(modalData.description)}
          submitButtonText={t(modalData.submitButtonText)}
          cancelButtonText={t(modalData.cancelButtonText)}
          onClose={closeModal}
          onProceed={onProceed}
          revert
        />
      )}
    </div>
  );
}
