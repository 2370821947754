import { createSelector } from 'reselect'
import { MODULE_NAME } from './segments.constants'
import { ISegment } from './segments.types'

const selectState = (state: { [MODULE_NAME]: any }) => state[MODULE_NAME]

export const getIsSegmentsListLoading = createSelector(selectState, (state): boolean => state.segmentsListLoading)
export const getSegmentsUpdating = createSelector(selectState, (state): boolean => state.segmentsUpdating)

export const getSegmentsList = createSelector(selectState, state =>
  state.segmentsList.map((segment: ISegment) => {
    return {
      ...segment,
      key: segment.id,
    }
  })
)
