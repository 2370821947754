import React, { Fragment, ReactElement, useEffect, useState } from 'react'
import {
  BRIEF_STEPS,
  BRIEF_TYPES,
  DISTRIBUTION_TYPE,
  MEDIA_GROUP_TYPE,
  SERVICE_NAMES_FOR_TABLE,
} from '../../../../constants/brief'
import { Descriptions } from 'antd'
import moment from 'moment'
import { FORMATS, LOCALIZATION_LANGUAGES } from '../../../../constants'
import { BRIEF_ADVERTISEMENT_LANGUAGES } from '../../../../constants/brief'
import { BriefSummary, IconsCommon } from 'quantum_components'
import { IBriefResponse, IUploadPartner } from '../../brief.types'
import { MediaTypeIcon } from './Brief.MediaTypeCard'
import { COLORS } from '../../../../constants/colors'
import { getFileNameFromUrl } from '../../../../utils'
import { getBase64 } from '../../../../utils/getBase64'
import { getLocaleCost } from 'src/helpers/currency'
import { useSelector } from 'react-redux'
import { getBriefSettings, getViewBriefLoading } from '../../brief.selectors'
import { useTranslation } from 'react-i18next'
import SpinnerCenter from 'src/components/Spinner/spinner'
import lodash from 'lodash'

const checkIcon = <IconsCommon.IconCheck color={COLORS.LIGHT_GREEN} />
const uncheckIcon = <IconsCommon.IconStepIncomplete />

interface IProps {
  briefView: IBriefResponse
  setCurrentStep: (step: BRIEF_STEPS) => void
  hideTitle?: boolean
  title?: string
  subtitle?: string
  viewMode?: boolean
  partnerQuantity?: number
  requestType?: MEDIA_GROUP_TYPE
  partnerIds?: number[]
}

export default function BriefViewSummary({
  briefView,
  setCurrentStep,
  hideTitle,
  title,
  subtitle,
  viewMode,
  partnerQuantity,
  requestType,
  partnerIds,
}: IProps): ReactElement {
  const { i18n } = useTranslation()
  const { t } = useTranslation(['translationABrief', 'translationProfile'])
  const isProductSample = briefView.briefTypes?.includes(BRIEF_TYPES.PRODUCT_SAMPLE)
  const isMediaBuying = briefView.briefTypes?.includes(BRIEF_TYPES.MEDIA_BUYING)
  const briefSettings = useSelector(getBriefSettings)
  const viewBriefLoading = useSelector(getViewBriefLoading)

  const [productImage, setProductImage] = useState<string>('')
  const [productImageLoading, setProductImageLoading] = useState<boolean>(true)
  const [serviceImage, setServiceImage] = useState<string>('')
  const [uploadMaterial, setUploadMaterial] = useState<IUploadPartner[]>([])

  const getCities = (cities: string[]) => {
    if (lodash.isEmpty(lodash.get(briefSettings, 'countries'))) return "";
    let settings = briefSettings?.countries[0].city;

    let cities_ = cities.map(city => {
      let obj = lodash.first(lodash.filter(settings, (s) => s.name === city))
      return i18n.language === LOCALIZATION_LANGUAGES.ARABIC ? lodash.get(obj, 'name_ar') : city
    })

    return cities_.join(', ')
  }

  const getCountries = (countries: string[]) => {

    let settings = briefSettings?.countries;

    let countries_ = countries.map(country => {
      let obj = lodash.first(lodash.filter(settings, (s) => s.name === country))
      return i18n.language === LOCALIZATION_LANGUAGES.ARABIC ? lodash.get(obj, 'name_ar') : country
    })

    return countries_.join(', ')
  }

  const getAgeGroupNames = (ageGroups: string[]) => {

    let settings = briefSettings?.ageGroups;

    let ageGroups_ = ageGroups.map(ageGroup => {
      let obj: any = lodash.first(lodash.filter(settings, (s: any) => (s.from + (s.to ? "-" + s.to + " " + s.name : "+ " + s.name)) === ageGroup))
      return i18n.language === LOCALIZATION_LANGUAGES.ARABIC ? obj && (obj.from + (obj.to ? "-" + obj.to + " " + obj.name_ar : "+ " + obj.name_ar)) : ageGroup
    })

    return ageGroups_.join(i18n.language === LOCALIZATION_LANGUAGES.ARABIC ? ' ,' : ', ')
  }

  const getProductImage = async () => {
    const productImageUrl = briefView.productImages ? briefView.productImages[0] : ''
    if (!productImageUrl) {
      setProductImage('')
      setProductImageLoading(false)
      return
    }

    const image = await getBase64(productImageUrl)
    setProductImage(image)
    setTimeout(() => {
      setProductImageLoading(false)
    }, 1000);
  }

  const getServiceImage = async () => {
    const serviceImageUrl = briefView.serviceImages ? briefView.serviceImages[0] : ''
    if (!serviceImageUrl) return

    const image = await getBase64(serviceImageUrl)
    setServiceImage(image)
  }

  const getUploadsBase64 = async (upload: IUploadPartner) => {
    return Promise.resolve(getBase64(upload.imageTargetUrl))
  }

  const getUploadMaterial = async () => {
    const uploads = partnerIds
      ? briefView.uploadMaterial.filter((file: IUploadPartner) => partnerIds.includes(file.partnerId))
      : briefView.uploadMaterial
    if (!uploads) return

    const list = uploads.map((material: IUploadPartner) => {
      // getUploadsBase64(material).then((data: string) => {
      //   material.imageTargetUrl = data
      // })
      return material
    })

    setUploadMaterial(list)
  }

  useEffect(() => {
    getProductImage()
    getServiceImage()
    getUploadMaterial()
  }, [briefView])

  const isRequestMediaBuy = requestType && requestType === MEDIA_GROUP_TYPE.MEDIA_BUY
  const isRequestDistribution =
    requestType && (requestType === MEDIA_GROUP_TYPE.PRINTED_INSERT || requestType === MEDIA_GROUP_TYPE.PRODUCT_SAMPLE)

  const distributionItem =
    briefView.distributionItem === DISTRIBUTION_TYPE.PRINTED_INSERT
      ? SERVICE_NAMES_FOR_TABLE.INSERT
      : SERVICE_NAMES_FOR_TABLE.PRODUCT

  const getTitle = () => {
    if (hideTitle) {
      return ''
    }
    if (title) {
      return title
    }

    return t(`brief_summary`)
  }

  const serviceBlock = requestType ? (
    <ul className="BSUnit__list">
      <li className={'BSUnit__list-item'}>
        <i className="i">{checkIcon}</i>
        {requestType}
      </li>
    </ul>
  ) : (
    <ul className="BSUnit__list">
      <li className={`BSUnit__list-item ${!isProductSample && 'uncheck'}`}>
        <i className="i">{isProductSample ? checkIcon : uncheckIcon}</i>
        {distributionItem}
      </li>
      <li className={`BSUnit__list-item ${!isMediaBuying && 'uncheck'}`}>
        <i className="i">{isMediaBuying ? checkIcon : uncheckIcon}</i>
        {t(`media_buying`)}
      </li>
    </ul>
  )
  return (
    <BriefSummary title={getTitle()} subtitle={subtitle ? subtitle : ''}>
      {(viewBriefLoading || productImageLoading) && <SpinnerCenter />}
      {/* <div className="BSUnit">
        <div className="BSUnit__heading">
          <h4 className="BSUnit__title">Services</h4>
        </div>
        <div className="BSUnit__content">
          {!viewMode && (
            <span onClick={() => setCurrentStep(BRIEF_STEPS.TYPE)} className="BSUnit__edit" data-html2canvas-ignore>
              Edit
            </span>
          )}
          {serviceBlock}
        </div>
      </div> */}
      <div className="BSUnit">
        <div className="BSUnit__heading">
          <h4 className="BSUnit__title">{t(`campaign_brief`)}</h4>
          <div className="BSUnit__thumb">{productImage && <img src={productImage} alt={briefView.name} />}</div>
        </div>
        <div className="BSUnit__content">
          {!viewMode && (
            <span onClick={() => setCurrentStep(BRIEF_STEPS.PRODUCT)} className="BSUnit__edit" data-html2canvas-ignore>
              {t(`edit`)}
            </span>
          )}
          <div className="BSUnit__content-header">
            <h5 className="BSUnit__content-title">{briefView.campaignName}</h5>
            <h6 className="BSUnit__content-subTitle">{t(briefView.campaignPromotionType)}</h6>
          </div>
          {(!requestType || isRequestDistribution) && (
            <div className="BSUnit__descriptions">
              <Descriptions column={1}>
                <Descriptions.Item label={t(`product_name`)}>{briefView.name}</Descriptions.Item>
                <Descriptions.Item label={t(`product_desc`)}>{briefView.productDescription}</Descriptions.Item>
                {/*<Descriptions.Item label={t(`marketing_objective`)}>{t(`objectives.${briefView.marketingObjective}`)}</Descriptions.Item>*/}
                <Descriptions.Item label={t(`desired_start_date`)}>{briefView.mediaStartDate ? moment(briefView.mediaStartDate).format(FORMATS.DATE_LL_FORMAT) : t(`none`)}</Descriptions.Item>
                <Descriptions.Item label={t(`instructions`)}>{briefView.instructions || t(`none`)}</Descriptions.Item>
              </Descriptions>
            </div>
          )}
        </div>
      </div>
      <Fragment>
        <div className="BSUnit">
          <div className="BSUnit__heading">
            <h4 className="BSUnit__title">{t(`targeting`)}</h4>
          </div>
          <div className="BSUnit__content">
            {!viewMode && (
              <span
                onClick={() => setCurrentStep(BRIEF_STEPS.TARGETING)}
                className="BSUnit__edit"
                data-html2canvas-ignore
              >
                {t(`edit`)}
              </span>
            )}
            <div className="BSUnit__descriptions">
            <Descriptions column={1}>

              {
                briefView?.customTargets && Object.keys(briefView?.customTargets).map((key:any)=>{
                  return <Descriptions.Item label={key}>
                    {/* @ts-ignore */}
                    {briefView?.customTargets[key]?briefView?.customTargets[key]?.join(", "):t('none')}
                </Descriptions.Item>
                })
              }
              
                
                {/* <Descriptions.Item label={t(`gender`)}>{briefView.targetFocus?.map((gender: string) => t(gender)).join(', ') || t(`none`)}</Descriptions.Item>
                <Descriptions.Item label={t(`country`)}>
                  {briefView.targetCountries ? getCountries(briefView?.targetCountries) : t('none')}
                </Descriptions.Item>
                <Descriptions.Item label={t('cities')}>{briefView?.targetCities ? getCities(briefView?.targetCities) : t('none')}</Descriptions.Item> */}
              </Descriptions>
            </div>
          </div>
        </div>
        {((!requestType && isMediaBuying) || isRequestMediaBuy) && (
          <div className="BSUnit">
            <div className="BSUnit__heading">
              <h4 className="BSUnit__title">{t(`uploaded_media`)}</h4>
            </div>
            <div className="BSUnit__content">
              {!viewMode && (
                <span
                  onClick={() => setCurrentStep(BRIEF_STEPS.UPLOAD)}
                  className="BSUnit__edit"
                  data-html2canvas-ignore
                >
                  {t(`edit`)}
                </span>
              )}
              {uploadMaterial && (
                <div className="BSUnit__media">
                  {uploadMaterial.map((upload: any, i: number) => {
                    const name = upload.mediaSubType ? upload.mediaSubType : upload.mediaType
                    return (
                      <div className="media-item" key={i}>
                        <div className="media-item-label">{name}</div>
                        <div className="BSUnit__descriptions">
                          {
                            <Descriptions column={1}>
                              {upload.link && (
                                <Descriptions.Item label={t(`target_url`)}>
                                  <a href={upload.link} target="_blank" rel="noopener noreferrer">
                                    {upload.link}
                                  </a>
                                </Descriptions.Item>
                              )}
                              {Object.entries(BRIEF_ADVERTISEMENT_LANGUAGES).map(([key, value]) => (
                                <Fragment key={key}>
                                  {upload.adTitle[key] && (
                                    <Descriptions.Item label={`${t(`title`)}`}>
                                      {upload.adTitle[key]}
                                    </Descriptions.Item>
                                  )}
                                  {upload.adDescription[key] && (
                                    <Descriptions.Item label={`${t(`description`)}`}>
                                      {upload.adDescription[key]}
                                    </Descriptions.Item>
                                  )}
                                  {upload.imageTargetUrl[key] && (
                                    <Fragment key={key}>
                                      <div className="media-item-image">
                                        {upload.imageTargetUrl[key].includes('.webm') ||
                                          upload.imageTargetUrl[key].includes('.mp4') ||
                                          upload.imageTargetUrl[key].includes('.ogg') ? (
                                          <video width="178" height="100" controls>
                                            <source
                                              src={upload.imageTargetUrl[key]}
                                              type={`video/${upload.imageTargetUrl[key].split('.')[
                                                upload.imageTargetUrl[key].split('.').length - 1
                                              ]
                                                }`}
                                            />
                                            {t(`video_error`)}
                                          </video>
                                        ) : (
                                          <img src={upload.imageTargetUrl[key]} alt={name} />
                                        )}
                                      </div>
                                      <div className="media-item-hint">
                                        {getFileNameFromUrl(upload.imageTargetUrl[key])}
                                        <span>
                                          {upload.width}
                                          {upload.height && `x ${upload.height}`}
                                        </span>
                                      </div>
                                    </Fragment>
                                  )}
                                </Fragment>
                              ))}
                            </Descriptions>
                          }
                        </div>
                        <hr className="form__hr" />
                      </div>
                    )
                  })}
                </div>
              )}
            </div>
          </div>
        )}
      </Fragment>
    </BriefSummary>
  )
}
