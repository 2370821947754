import { Form, QButton, QCheckbox, QInput, QTextArea, Row } from 'quantum_components'
import React, { useEffect } from 'react'
import { IProposal } from '../../modules/brief/brief.types'
import { REGEXES } from '../../constants'
import { useTranslation } from 'react-i18next'

interface IProps {
  onSend: (form: IProposal) => void
  isInviteClient?: boolean
}
const EmailProposalForm = ({ onSend, isInviteClient = false }: IProps) => {
  const { t }= useTranslation(['translationInviteClients'])
  const [form] = Form.useForm()

  useEffect(() => {
    form.setFieldsValue({
      emails: '',
      message: '',
      cc: false,
    })
  })

  const onFinish = (values: any) => {
    let emails
    if (!isInviteClient)
      emails = values.emails
        .replace(' ', '')
        .split(',')
        .filter((e: string) => e)
    else emails = values.emails

    const data = {
      emails,
      message: values.message,
      cc: values.cc,
    } as IProposal

    onSend(data)
  }

  return (
    <Form form={form} onFinish={onFinish} layout="vertical">
      <Form.Item
        label={t("Email Address")}
        name="emails"
        rules={[{ required: true, pattern: REGEXES.EMAIL_SEPARATE_COMMA_REGEX, whitespace: true }]}
        help={t('Seperate Emails')}
        required
      >
        <QInput size="large" />
      </Form.Item>
      <Form.Item
        label={t("Message")}
        name="message"
        rules={[{ required: true, max: 255, whitespace: true }]}
        help={t('Message is required')}
        required
      >
        <QTextArea rows={5} className="form-textarea" />
      </Form.Item>
      <Row justify="space-between" align="middle">
        <Form.Item name="cc" valuePropName="checked">
          <QCheckbox>{t('CC me')}</QCheckbox>
        </Form.Item>
        <QButton htmlType={'submit'} type="primary">
          {t('Send')}
        </QButton>
      </Row>
    </Form>
  )
}

export default EmailProposalForm
