import { Col, Divider, Row, Tag, Space } from "antd";
import { AgeGroupCard } from "src/modules/brief/containers/components/Brief.AgeGroupCard";
import lodash, { includes } from "lodash";
import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom'
import SpinnerCenter from "src/components/Spinner/spinner";
import { Modal, QButton, QInput, qnotification, QRadio } from "quantum_components";
import { renderRoundedNumber } from "src/helpers/currency";
import { CURRENCY } from "src/constants/currencies";
import { Radio } from "quantum_components";
import {
  overridePaymentByAdmin,
  updateCartServicesStatus,
  uploadGeneratedQuotation,
} from "../briefv3.actions";
import { getBriefQuotationPdf } from "src/utils/getScreenPage";
import { BRIEF_UPLOAD_TYPES } from "src/constants/brief";
import {
  CloseOutlined,
  CheckOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getCurrentUser } from "src/modules/core/core.selectors";
import DialogModal from "src/components/DialogModal/DialogModal";
import moment from "moment"
import { calculateBudgetFeedback } from "../briefv3.helper";

const V3CartComponent = ({
  selectedServicesState,
  setSelectedServicesState,
  cartState,
  setCartState,
  briefResponse,
  parentRefresh,
  saveUpdates,
  validateData,
}: any) => {
  const { partnerData } = useSelector(getCurrentUser);

  const [serviceRemoveConfirm, setServiceRemoveConfirm] = useState(false);
  const [serviceForRemoval, setServiceForRemoval] = useState<any>({});

  const { t } = useTranslation(["translationV2", "translationABrief"]);
  const [serviceForPriceUpdate, setServiceForPriceUpdate] = useState<any>({});
  const [showPriceChangeModal, setShowPriceChangeModal] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const showCreateQuotation =
    lodash.get(briefResponse, "rawResponse.status") === "APPROVED" &&
    !lodash.get(briefResponse, "rawResponse.payment.quotation");
  const showViewQuotation =
    lodash.get(briefResponse, "rawResponse.status") === "APPROVED" &&
    lodash.get(briefResponse, "rawResponse.payment.quotation");

  const onlinePaymentStatus = lodash.get(
    briefResponse,
    "rawResponse.payment.onlinePayment.status"
  );

  const [serviceStatus, setServiceStatus] = useState<any>([]);

  const recalculateStatuses = () => {
    setServiceStatus([
      ...selectedServicesState.map((s: any) => {
        return {
          id: s.id,
          status: s.status,
          rejectionReason: s.rejectionReason,
        };
      }),
    ]);
  };

  const recalculateCart = () => {
    let c = { ...cartState };

    const servicesTotal = selectedServicesState.reduce(
      (acc: any, cur: any) => (acc += cur.total),
      0
    );

    const subTotal = servicesTotal + c.managementFee - c.discount;
    let totalTax = 0;
    if (
      partnerData?.taxTreatment === "VAT Registered" ||
      onlinePaymentStatus === "PAID"
    ) {
      totalTax = subTotal * 1.15 - subTotal;
    }
    const cartTotal = subTotal + totalTax;
    // c.subTotal = servicesTotal + c.managementFee - c.discount;
    // c.totalTax = servicesTotal * 1.15 - servicesTotal;
    // c.cartTotal = servicesTotal * 1.15;
    c.servicesTotal = servicesTotal;
    c.subTotal = subTotal;
    c.totalTax = totalTax;
    c.cartTotal = cartTotal;

    setCartState(c);
  };

  useEffect(() => {
    recalculateCart();
    recalculateStatuses();
  }, [selectedServicesState, onlinePaymentStatus, partnerData]);


  const handleServiceMetricDataChange = (
    serviceId: any,
    control: string,
    value: string
  ) => {

    let updatedServices = selectedServicesState.map((ss: any) => {


      if (ss.id === serviceId) {
        ss["metricData"][control] = value
        if (control === "campaignBudget") {
          ss.quantity = Number(1);
          ss.total = Number(value);
        }

        const {
          totalQuantity,
          estimatedDaysForTotalQuantity,
          dailyQuantity,
          estimatedDaysForDailyQuantity,
          dailyDistribution
        }: any = calculateBudgetFeedback(ss);

        ss["metricData"]["totalQuantity"] = totalQuantity;
        ss["metricData"]["estimatedDaysForTotalQuantity"] = estimatedDaysForTotalQuantity;
        ss["metricData"]["dailyQuantity"] = dailyQuantity;
        ss["metricData"]["estimatedDaysForDailyQuantity"] = estimatedDaysForDailyQuantity;
        ss["metricData"]["dailyDistribution"] = dailyDistribution;
      }
      return ss;
    });

    setSelectedServicesState(updatedServices);
    recalculateCart();
  };

  const handleQuantityChange = (serviceId: any, quantity: any) => {
    if (!quantity || quantity < 0) quantity = 0;
    let updatedServices = selectedServicesState.map((ss: any) => {
      if (ss.id === serviceId) {
        ss.quantity = Number(quantity);
        ss.total = Number(ss.basePrice) * Number(quantity);
      }
      return ss;
    });
    setSelectedServicesState(updatedServices);
    recalculateCart();
  };

  const handlePriceChange = () => {

    if (serviceForPriceUpdate?.durationType !== "Per Metric" && Number(serviceForPriceUpdate?.basePrice) <= 0) {
      qnotification({ type: "error", message: t("Invalid value") });
      return;
    }

    if (serviceForPriceUpdate?.durationType === "Per Metric") {
      if (Number(
        serviceForPriceUpdate?.pricePerMetric.cpm
      ) <= 0 || Number(
        serviceForPriceUpdate?.pricePerMetric.cpc
      ) <= 0) {
        qnotification({ type: "error", message: t("Invalid value") });
        return;
      }
    }


    const serviceId = serviceForPriceUpdate?.id;
    let basePrice = 0;
    let quantity = 0;

    if (serviceForPriceUpdate?.durationType !== "Per Metric") {
      basePrice = serviceForPriceUpdate?.basePrice;
      quantity = serviceForPriceUpdate?.quantity;
    } else {
      basePrice = serviceForPriceUpdate?.metricData?.campaignBudget;
      quantity = 1;
    }

    let updatedServices = selectedServicesState.map((ss: any) => {
      if (ss.id === serviceId) {
        if (serviceForPriceUpdate?.durationType !== "Per Metric") {
          ss.basePrice = Number(basePrice);
          handleQuantityChange(serviceId, quantity);
        } else {
          ss.pricePerMetric.cpm = Number(
            serviceForPriceUpdate?.pricePerMetric.cpm
          );
          ss.pricePerMetric.cpc = Number(
            serviceForPriceUpdate?.pricePerMetric.cpc
          );
        }
      }
      return ss;
    });

    if (serviceForPriceUpdate?.durationType !== "Per Metric") {
      handleQuantityChange(serviceId, quantity);
    }

    setSelectedServicesState(updatedServices);
    setShowPriceChangeModal(false);
  };

  const handleServiceStatusChange = (serviceId: any, status: any) => {
    setServiceStatus([
      ...serviceStatus.map((ss: any) => {
        if (ss.id === serviceId) {
          ss.status = status;
        }
        return ss;
      }),
    ]);
  };

  const handleServiceRejectionReasonChange = (
    serviceId: any,
    rejectionReason: any
  ) => {
    setServiceStatus([
      ...serviceStatus.map((ss: any) => {
        if (ss.id === serviceId) {
          ss.rejectionReason = rejectionReason;
        }
        return ss;
      }),
    ]);
  };

  const checkServiceStatus = (serviceId: any) => {
    const service = serviceStatus.find((o: any) => o.id === serviceId);
    if (service) {
      return service.status;
    }
  };

  const getQuotationBlob = async (taxEnabled: boolean) => {
    //generate quotation and upload

    const divId = taxEnabled
      ? "brief_quotation_print_area_with_tax"
      : "brief_quotation_print_area";
    const markup = document.getElementById(divId);
    if (!markup) return;

    const pdf = await getBriefQuotationPdf(
      markup,
      "en",
      divId.includes("tax") ? 1 : 0
    );
    // pdf.save("Brief Quotation.pdf");

    return pdf.output("blob");
  };

  const handleCreateAndUploadQuotation = async (doRefresh = false) => {
    setIsUploading(true);

    let quotationWithoutTax = await getQuotationBlob(false);
    let quotationWithTax = await getQuotationBlob(true);

    await uploadGeneratedQuotation(
      [quotationWithTax],
      BRIEF_UPLOAD_TYPES.BRIEFS_QUOTATION_FILES_WITH_TAX,
      lodash.get(briefResponse, "rawResponse.cart.briefId")
    );

    await uploadGeneratedQuotation(
      [quotationWithoutTax],
      BRIEF_UPLOAD_TYPES.BRIEFS_QUOTATION_FILES,
      lodash.get(briefResponse, "rawResponse.cart.briefId")
    );

    setIsUploading(false);
    if (doRefresh) await parentRefresh();
  };

  const handleStatusUpdate = async () => {
    if (!validateData()) return;
    await saveUpdates();

    const cartId = lodash.get(briefResponse, "rawResponse.cart.id");
    if (!cartId) return;
    const payload = {
      id: cartId,
      services: serviceStatus,
    };

    await updateCartServicesStatus(payload);
    await parentRefresh();

    // check if services are all accepted then create pdf
    if (serviceStatus.every((o: any) => o.status === "ACCEPTED")) {
      await handleCreateAndUploadQuotation();
      await parentRefresh();
    }
  };

  const handleServiceRemove = (serviceId: any) => {
    setSelectedServicesState(
      selectedServicesState.filter((service: any) => service.id !== serviceId)
    );
    recalculateCart();
  };

  const getQuotationURL = () => {
    const taxedBrief = Number(cartState?.totalTax) > 0;

    const normalQuotation = lodash.get(
      briefResponse,
      "rawResponse.payment.quotation"
    );
    const taxedQuotation = lodash.get(
      briefResponse,
      "rawResponse.payment.quotationWithTax"
    );

    if (taxedBrief === true) {
      if (taxedQuotation) {
        return taxedQuotation;
      }
    }
    return normalQuotation;
  };


  const getBudgetFeedbackLabel = (service: any) => {
    const {
      campaignBudget,
      simplifiedMetric,
      totalQuantity,
      estimatedDaysForTotalQuantity,
      dailyBudget,
      dailyQuantity,
      estimatedDaysForDailyQuantity,
      impressionsPerDay,
      clicksPerDay,
      selectedMetric
    }: any = calculateBudgetFeedback(service);
    if (!campaignBudget || !totalQuantity || !simplifiedMetric || !estimatedDaysForTotalQuantity) return;

    let label = "";

    if (dailyBudget > campaignBudget) return "";

    if (dailyBudget > 0) {
      label = t("daily_budget_helper", { selectedMetric, impressionsPerDay: Number(selectedMetric === "CPM" ? impressionsPerDay : clicksPerDay).toLocaleString(), dailyBudget: Number(dailyBudget).toLocaleString(), dailyQuantity: Number(dailyQuantity).toLocaleString(), simplifiedMetric: t(simplifiedMetric), estimatedDaysForDailyQuantity, totalQuantity: Number(totalQuantity).toLocaleString() })
    } else {
      label = t("total_budget_helper", { selectedMetric, impressionsPerDay: Number(selectedMetric === "CPM" ? impressionsPerDay : clicksPerDay).toLocaleString(), campaignBudget: Number(campaignBudget).toLocaleString(), dailyQuantity: Number(dailyQuantity).toLocaleString(), simplifiedMetric: t(simplifiedMetric), estimatedDaysForDailyQuantity, totalQuantity: Number(totalQuantity).toLocaleString(), estimatedDaysForTotalQuantity })
    }

    return label;
    // return t("budget_helper", {
    //   budget,
    //   amount: quantity,
    //   simplifiedMetric,
    //   timeline,
    // }); 
  }

  return (
    <div className="v3-cart-component">
      {isUploading && <SpinnerCenter></SpinnerCenter>}
      {lodash.isEmpty(selectedServicesState) && (
        <div className="text-center">{t("No data")}</div>
      )}
      {!lodash.isEmpty(selectedServicesState) && <h6>{t("Services")}</h6>}
      {selectedServicesState.map((ss: any, index: number) => {
        return (
          <Row align="top" className="mb-10" key={index}>
            <Col md={ss.durationType === "Per Metric" ? 24 : 12} xs={24}>
              <div>
                <label>
                  <b>
                    <label className="label-required">
                      {ss.status !== "ACCEPTED" && (
                        <DeleteOutlined
                          title={t("Remove")}
                          onClick={() => {
                            setServiceForRemoval(ss);
                            setServiceRemoveConfirm(true);
                          }}
                          style={{ cursor: "pointer", color: "red" }}
                        />
                      )}{" "}
                      {ss.name}
                    </label>
                  </b>
                </label>
              </div>
              <div>
                {ss.durationType !== "Per Metric" && (
                  <div>
                    <label className="text-size-14">
                      <a
                        onClick={() => {
                          if (ss.status === "ACCEPTED") return;
                          setServiceForPriceUpdate(ss);
                          setShowPriceChangeModal(true);
                        }}
                      >
                        {Number(ss.basePrice).toLocaleString()} SAR
                      </a>{" "}
                      [{t(ss.durationType)}]
                    </label>
                  </div>
                )}
                {ss.durationType === "Per Metric" && (
                  <div>
                    <Radio.Group
                      disabled={["ACCEPTED"].includes(ss.status)}
                      className="qu-radio-group mt-5"
                      value={ss?.metricData?.metricType}
                      onChange={(e: any) => {
                        handleServiceMetricDataChange(
                          ss.id,
                          "metricType",
                          e.target.value
                        );
                      }}
                    >
                      <Space direction="vertical">
                        <Radio value="CPM">
                          <label className="text-size-14">
                            <a
                              onClick={() => {
                                if (ss.status === "ACCEPTED") return;
                                setServiceForPriceUpdate(ss);
                                setShowPriceChangeModal(true);
                              }}
                            >
                              {ss.pricePerMetric?.cpm} SAR
                            </a>{" "}
                            [{t("CPM")}]
                          </label>
                        </Radio>
                        <Radio value="CPC">
                          <label className="text-size-14">
                            <a
                              onClick={() => {
                                if (ss.status === "ACCEPTED") return;
                                setServiceForPriceUpdate(ss);
                                setShowPriceChangeModal(true);
                              }}
                            >
                              {ss.pricePerMetric?.cpc} SAR
                            </a>{" "}
                            [{t("CPC")}]
                          </label>
                        </Radio>
                      </Space>
                    </Radio.Group>

                    <Row align={"middle"} justify={"center"} gutter={24}>
                      <Col md={24} className={"mt-10"}>
                        <div className="text-bold label-required">
                          {t("Campaign Budget (SAR)")}
                        </div>
                        <div>
                          <QInput
                            id={`${ss.id}_campaignBudget`}
                            onFocus={(e: any) => {
                              // @ts-ignore
                              document.getElementById(`${ss.id}_campaignBudget`)?.select();
                            }}
                            disabled={["ACCEPTED"].includes(ss.status)}
                            placeholder={t("Type here")}
                            value={ss?.metricData?.campaignBudget ?? 0}
                            size="small"
                            type="number"
                            onChange={(e: any) => {
                              handleServiceMetricDataChange(
                                ss.id,
                                "campaignBudget",
                                e.target.value
                              );
                            }}
                          />
                          {ss.impressionsPerDay && <small>{t("impressions_clicks_helper", { impressions: Number(ss.impressionsPerDay).toLocaleString(), clicks: Number(Math.ceil(ss.impressionsPerDay * 0.01)).toLocaleString() })}</small>}
                        </div>
                      </Col>
                      <Col md={24} className={"mt-10"}>
                        <div className="text-bold">
                          {t("Daily Budget (SAR)")}
                        </div>
                        <div>
                          <QInput
                            id={`${ss.id}_dailyBudget`}
                            onFocus={(e: any) => {
                              // @ts-ignore
                              document.getElementById(`${ss.id}_dailyBudget`)?.select();
                            }}
                            disabled={["ACCEPTED"].includes(ss.status)}
                            placeholder={t("Type here")}
                            value={ss?.metricData?.dailyBudget ?? 0}
                            size="small"
                            type="number"
                            onChange={(e: any) => {
                              handleServiceMetricDataChange(
                                ss.id,
                                "dailyBudget",
                                e.target.value
                              );
                            }}
                          />
                          {Number(ss?.metricData?.dailyBudget) > Number(ss?.metricData?.campaignBudget) && <div className="text-red text-sm">{t("Daily budget can't exceed campaign's budget")}</div>}
                          <small>{t("Having a daily budget will divide your campaign into a number of days according to average impressions and clicks for this ad-space")}</small>
                        </div>
                      </Col>
                      {/* <Col md={24} className={"mt-10"}>
                        <div className="text-bold">
                          {t("Min Duration (Days)")}
                        </div>
                        <div>
                          <QInput
                            disabled={["ACCEPTED"].includes(ss.status)}
                            placeholder={t("Type here")}
                            value={ss?.metricData?.minDuration ?? 0}
                            size="small"
                            type="number"
                            id={"minDuration"}
                            onFocus={(e:any)=>{
                              // @ts-ignore
                              document.getElementById("minDuration")?.select();
                            }}
                            onChange={(e: any) => {
                              handleServiceMetricDataChange(
                                ss.id,
                                "minDuration",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </Col> */}
                      <Col md={24} className={"mt-10"}>
                        <div className="text-italic text-bold">
                          {getBudgetFeedbackLabel(ss)}
                        </div>
                      </Col>
                    </Row>
                  </div>
                )}
              </div>
              {ss.status === "PENDING" &&
                lodash.get(
                  briefResponse,
                  "rawResponse.cart.isUnderReview",
                  false
                ) && (
                  <>
                    <Radio.Group
                      className="mt-5"
                      value={checkServiceStatus(ss.id)}
                      onChange={(e: any) =>
                        handleServiceStatusChange(ss.id, e.target.value)
                      }
                      disabled={["ACCEPTED", "REJECTED"].includes(ss.status)}
                    >
                      <Radio.Button value={"ACCEPTED"}>
                        <div>
                          <span>
                            <CheckOutlined
                              title="Accept"
                              style={{ cursor: "pointer", color: "green" }}
                            />
                          </span>
                        </div>
                      </Radio.Button>
                      <Radio.Button value={"REJECTED"}>
                        <div>
                          <span>
                            <CloseOutlined
                              title="Reject"
                              style={{ cursor: "pointer", color: "red" }}
                            />
                          </span>
                        </div>
                      </Radio.Button>
                    </Radio.Group>
                  </>
                )}
              {checkServiceStatus(ss.id) === "REJECTED" &&
                lodash.get(
                  briefResponse,
                  "rawResponse.cart.isUnderReview",
                  false
                ) && (
                  <QInput
                    disabled={
                      !lodash.get(
                        briefResponse,
                        "rawResponse.cart.isUnderReview",
                        false
                      )
                    }
                    className="mt-5"
                    size="medium"
                    placeholder={t("Enter rejection reason")}
                    onChange={(e: any) =>
                      handleServiceRejectionReasonChange(ss.id, e.target.value)
                    }
                  ></QInput>
                )}
              {/* {["ACCEPTED", "REJECTED"].includes(ss.status) && (
                <span
                  className={`mt-5 status-label ${
                    ss.status === "REJECTED" ? "rejected" : "approved"
                  }`}
                >
                  {t(`requests-status.${String(ss.status).toLowerCase()}`, {
                    ns: "translationABrief",
                  })}
                </span>
              )} */}
            </Col>
            {ss.durationType !== "Per Metric" && (
              <Col md={12} xs={24} className="text-right">
                <div>
                  <label>
                    <b>
                      <label className="label-required">{t("QTY")}</label>
                    </b>
                  </label>
                </div>
                <QInput
                  disabled={["ACCEPTED"].includes(ss.status)}
                  value={ss.quantity ?? 0}
                  onChange={(e: any) =>
                    handleQuantityChange(ss.id, e.target.value)
                  }
                  type="number"
                  size="small"
                  placeholder={t("QTY")}
                  style={{ width: "60px", textAlign: "left" }}
                />
                <div>
                  <label className="text-size-14">
                    <b>{renderRoundedNumber(ss.total, CURRENCY.SAR)}</b>
                  </label>
                </div>
              </Col>
            )}
            {index < selectedServicesState.length - 1 && <Divider></Divider>}
          </Row>
        );
      })}
      {!lodash.isEmpty(selectedServicesState) && (
        <>
          <Divider></Divider>
          <Row className="mb-5">
            <Col md={15} xs={24}>
              <label>{t("Services Total")}</label>
            </Col>
            <Col md={9} xs={24} className="text-right">
              <label className="text-size-14">
                <b>
                  {renderRoundedNumber(cartState.servicesTotal, CURRENCY.SAR)}
                </b>
              </label>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col md={15} xs={24}>
              <label>{t("Management Fee")}</label>
            </Col>
            <Col md={9} xs={24} className="text-right">
              <label className="text-size-14">
                <b>
                  {renderRoundedNumber(cartState.managementFee, CURRENCY.SAR)}
                </b>
              </label>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col md={15} xs={24}>
              <label>{t("Discount")}</label>
            </Col>
            <Col md={9} xs={24} className="text-right">
              <label className="text-size-14">
                <b>{renderRoundedNumber(cartState.discount, CURRENCY.SAR)}</b>
              </label>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col md={15} xs={24}>
              <label>{t("Subtotal")}</label>
            </Col>
            <Col md={9} xs={24} className="text-right">
              <label className="text-size-14">
                <b>{renderRoundedNumber(cartState.subTotal, CURRENCY.SAR)}</b>
              </label>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col md={15} xs={24}>
              <label>{t("Taxes (15%)")}</label>
            </Col>
            <Col md={9} xs={24} className="text-right">
              <label className="text-size-14">
                <b>{renderRoundedNumber(cartState.totalTax, CURRENCY.SAR)}</b>
              </label>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col md={15} xs={24}>
              <label>{t("Total")}</label>
            </Col>
            <Col md={9} xs={24} className="text-right">
              <label className="text-size-14">
                <b>{renderRoundedNumber(cartState.cartTotal, CURRENCY.SAR)}</b>
              </label>
            </Col>
          </Row>
        </>
      )}

      {/* approve/reject */}
      {!lodash.isEmpty(selectedServicesState) &&
        lodash.get(briefResponse, "rawResponse.cart.isUnderReview") && (
          <QButton
            htmlType="button"
            className="full-width justify-center mt-20"
            type="primary"
            onClick={handleStatusUpdate}
            disabled={
              !serviceStatus.every((o: any) =>
                ["ACCEPTED", "REJECTED"].includes(o.status)
              )
            }
          >
            {t("Moderate Cart")}
          </QButton>
        )}

      {/* Create Quotation */}
      {showCreateQuotation && (
        <QButton
          htmlType="button"
          className="full-width justify-center qu-button-soft mt-20"
          type="primary"
          onClick={() => handleCreateAndUploadQuotation(true)}
        >
          {t("Create Quotation")}
        </QButton>
      )}
      {showViewQuotation && (
        <a
          href={`${getQuotationURL()}`}
          target="_blank"
          className="ant-btn qu-button full-width justify-center qu-button-soft mt-20 ant-btn-primary"
        >
          {t("View Quotation")}
        </a>
      )}

      <Modal
        visible={showPriceChangeModal}
        title={`${t("Change price for")} [${serviceForPriceUpdate?.name}] (${t(
          serviceForPriceUpdate?.durationType
        )})`}
        okText={t("confirm")}
        cancelText={t("cancel")}
        onOk={() => handlePriceChange()}
        onCancel={() => setShowPriceChangeModal(false)}
      >
        {serviceForPriceUpdate.durationType !== "Per Metric" && (
          <div>
            <label className="label-required">{t("New Price")}</label>
            <br />
            <QInput
              id={"price"}
              onFocus={(e: any) => {
                // @ts-ignore
                document.getElementById("price")?.select();
              }}
              disabled={["ACCEPTED"].includes(serviceForPriceUpdate?.status)}
              value={serviceForPriceUpdate?.basePrice ?? 0}
              onChange={(e: any) =>
                setServiceForPriceUpdate({
                  ...serviceForPriceUpdate,
                  basePrice: Number(e.target.value),
                })
              }
              type="number"
              size="medium"
              placeholder={t("Price")}
            />
          </div>
        )}

        {serviceForPriceUpdate.durationType === "Per Metric" && (
          <div>
            <label className="label-required">{t("New Price for CPM")}</label>
            <br />
            <QInput
              id={"cpm"}
              onFocus={(e: any) => {
                // @ts-ignore
                document.getElementById("cpm")?.select();
              }}
              disabled={["ACCEPTED"].includes(serviceForPriceUpdate?.status)}
              value={serviceForPriceUpdate?.pricePerMetric?.cpm ?? 0}
              onChange={(e: any) => {
                const pricePerMetric = {
                  ...serviceForPriceUpdate.pricePerMetric,
                };
                pricePerMetric.cpm = Number(e.target.value);

                setServiceForPriceUpdate({
                  ...serviceForPriceUpdate,
                  pricePerMetric,
                });
              }}
              type="number"
              size="medium"
              placeholder={t("Enter here")}
            />
          </div>
        )}

        {serviceForPriceUpdate.durationType === "Per Metric" && (
          <div>
            <label className="label-required">{t("New Price for CPC")}</label>
            <br />
            <QInput
              id={"cpc"}
              onFocus={(e: any) => {
                // @ts-ignore
                document.getElementById("cpc")?.select();
              }}
              disabled={["ACCEPTED"].includes(serviceForPriceUpdate?.status)}
              value={serviceForPriceUpdate?.pricePerMetric?.cpc ?? 0}
              onChange={(e: any) => {
                const pricePerMetric = {
                  ...serviceForPriceUpdate.pricePerMetric,
                };
                pricePerMetric.cpc = Number(e.target.value);

                setServiceForPriceUpdate({
                  ...serviceForPriceUpdate,
                  pricePerMetric,
                });
              }}
              type="number"
              size="medium"
              placeholder={t("Enter here")}
            />
          </div>
        )}
      </Modal>

      {/* confirmation of removal of service */}
      <DialogModal
        modalOpen={serviceRemoveConfirm}
        question={t(`Are you sure?`)}
        description={t(`This service will be removed from the cart`)}
        submitButtonText={t(`cancel`)}
        cancelButtonText={t(`confirm`)}
        onClose={() => {
          setServiceForRemoval({});
          setServiceRemoveConfirm(false);
        }}
        onProceed={() => {
          handleServiceRemove(serviceForRemoval.id);
          setServiceRemoveConfirm(false);
        }}
        revert
      />
    </div>
  );
};

export default React.memo(V3CartComponent);
