const MODULE_NAME = 'auth'

export const getRefreshUrl = (): string => `${MODULE_NAME}/refresh`

export const getLoginUrl = (): string => `${MODULE_NAME}/sign-in`

export const getLogoutUrl = (): string => `${MODULE_NAME}/logout`

export const getSignUpUrl = (): string => `${MODULE_NAME}/sign-up`

export const getResetPasswordUrl = (): string => `${MODULE_NAME}/restore-password`

export const getChangePasswordUrl = (): string => `${MODULE_NAME}/change-password`
