const MODULE_NAME = 'admin/campaigns'
const MODULE_SURVEY = 'admin/surveys'
const MODULE_GALLERY = 'admin/gallery'

export const getCampaignsListUrl = (): string => `${MODULE_NAME}/list`
export const getCampaignDetailsUrl = (id: number): string => `${MODULE_NAME}/view/${id}`
export const getCampaignUpsertUrl = (requestId: number): string => `${MODULE_NAME}/upsert/${requestId}`
export const getCampaignCompleteUrl = (id: number): string => `${MODULE_NAME}/complete/${id}`

export const getCampaignInitialUrl = (id: number): string => `${MODULE_NAME}/distribution/${id}`
export const getCampaignsQuantityInfoUrl = (id: number, partnerId: number): string =>
  `${MODULE_NAME}/${id}/distribution/${partnerId}/quantity-info`
export const getCampaignsDistributionUpsertUrl = (id: number): string => `${MODULE_NAME}/${id}/distribution/upsert`
export const getCampaignsDistributionUrl = (id: number, partnerId: number): string =>
  `${MODULE_NAME}/${id}/distribution/${partnerId}`
export const getCampaignDistributionDeleteUrl = (id: number): string => `${MODULE_NAME}/${id}/distribution/remove`

export const getCampaignsTimelineSaveUrl = (): string => `${MODULE_NAME}/timeline/upsert`
export const getCampaignsTimelineListUrl = (id: number): string => `${MODULE_NAME}/timeline/${id}/list`
export const getCampaignsTimelineDeleteUrl = (timelineId: number): string =>
  `${MODULE_NAME}/timeline/${timelineId}/delete`
export const getCampaignsTimelineUploadUrl = (timelineId: number): string =>
  `${MODULE_NAME}/timeline/${timelineId}/image/upload`
export const getCampaignsTimelineUploadDeleteUrl = (): string => `${MODULE_NAME}/timeline/delete-one`

export const getCampaignsPropsUpsertUrl = (id: number): string => `${MODULE_NAME}/${id}/props/upsert`
export const getCampaignsPropsUrl = (id: number): string => `${MODULE_NAME}/${id}/props`

export const getClientCampaignAnalyticsInfoUrl = (campaignId: number): string =>
  `${MODULE_NAME}/edit-graph/${campaignId}/info`
export const getClientCampaignAnalyticsDistDataUrl = (campaignId: number): string =>
  `${MODULE_NAME}/edit-graph/${campaignId}/filter`
export const getClientCampaignAnalyticsMediaBuyDataUrl = (campaignId: number): string =>
  `${MODULE_NAME}/${campaignId}/media-buy/graph`

export const getCampaignsSurveyUrl = (id: number): string => `${MODULE_SURVEY}/${id}/all`
export const getSaveCampaignsSurveyUrl = (): string => `${MODULE_SURVEY}/upsert`
export const getDeleteCampaignsSurveyUrl = (surveyId: number): string => `${MODULE_SURVEY}/${surveyId}/delete`

export const getSaveCampaignsMediaBuyFilterUrl = (id: number): string => `${MODULE_NAME}/${id}/media-buy/filter/upsert`
export const getEditCampaignsMediaBuyFilterUrl = (id: number, partnerId: number): string =>
  `${MODULE_NAME}/${id}/media-buy/filter/${partnerId}`
export const getSaveCampaignsMediaBuyUrl = (id: number): string => `${MODULE_NAME}/${id}/media-buy/data/upsert`
export const getEditCampaignsMediaBuyUrl = (id: number, partnerId: number): string =>
  `${MODULE_NAME}/${id}/media-buy/data/${partnerId}`

export const getClientSurveysUrl = (campaignId: number): string => `${MODULE_SURVEY}/export/${campaignId}`

export const getCampaignsGallerySaveUrl = (): string => `${MODULE_GALLERY}/upsert`
export const getCampaignsGalleryListUrl = (id: number): string => `${MODULE_GALLERY}/${id}/all`
export const getCampaignsGalleryRemoveImageUrl = (): string => `${MODULE_GALLERY}/image/delete-one`

export const postReportFiles = (): string => `${MODULE_NAME}/upload`
export const deleteReportFiles = (): string => `${MODULE_NAME}/remove-media`

export const getCampaignReportDetailsUrl = (id: number, startDate: string, endDate: string): string => `${MODULE_NAME}/report/${id}?startDate=${startDate}&endDate=${endDate}`