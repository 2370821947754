import { IState } from './promotions.types'
import * as CONSTANTS from './promotions.constants'

const initialState: IState = {
  data:[],
  rootTotal:0,
  loading:false,
  services:[]
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case CONSTANTS.PROMOTIONS_REQUEST:
      return {
        ...state,
        loading:true
      }
      case CONSTANTS.PROMOTIONS_END_REQUEST:
      return {
        ...state,
        loading:false
      }
    case CONSTANTS.PROMOTIONS_SUCCESS:
      return {
        ...state,
        data:action.payload.data,
        rootTotal:action.payload.rootTotal,
        loading: false,
      }
      case CONSTANTS.PROMOTIONS_DETAILS_SUCCESS:
        return {
          ...state,
          loading: false,
        }
    case CONSTANTS.PROMOTIONS_FAILURE:
      return {
        ...state,
        loading: false,
      }
      
    default:
      return state
  }
}
