import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { AxiosResponse } from 'axios'
import IAction from 'src/interfaces/IAction'

import * as CONSTANTS from './servicesV2.constants'
import { requestHttp, urls } from 'src/api'
import { getResponseErrorMessage } from 'src/helpers'
import { STATUS_CODES } from 'src/constants'

export const getServiceV2CustomMediaTypes = async() => {
    try {
        const response = await requestHttp.get(urls.getAllCustomMediaUrl());
        if (response.status === STATUS_CODES.BASE_SUCCESS) {
          const { content } = response.data.data;
          return content
        }
      } catch (error) {
        return error
      }
}

export const getServicesV2List = async() => {
    try {
        const response = await requestHttp.get(urls.getListAllServicesV2Url())
        const { content } = response.data.data
        return content
    } catch (error) {
        return error
    }
}

export const createServicesV2 = async(service:any) => {
    try {
        const response = await requestHttp.post(urls.getCreateServicesV2Url(),service)
        const { content } = response.data.data
        return content
    } catch (error) {
        return error
    }
}

export const updateServicesV2 = async(service:any) => {
    try {
        const response = await requestHttp.put(urls.getUpdateServicesV2Url(),service)
        const { content } = response.data.data
        return content
    } catch (error) {
        return error
    }
}

export const deleteServicesV2 = async(id:any) => {
    try {
        const response = await requestHttp.delete(urls.getDeleteServicesV2Url(id))
        const { content } = response.data.data
        return content
    } catch (error) {
        return error
    }
}
