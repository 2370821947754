import React, { ReactElement, useEffect, useState } from 'react'
import { COUNTRIES, DEVICES, ICampaignReportData } from '../../campaignV3.types'
import Blue_Strip_Image from 'src/assets/ReportImages/blue-strip.png'
import Logo_Image from 'src/assets/ReportImages/logo.png'
import { Col, QPieChart, Row } from 'quantum_components'
import { abbreviateNumberWithoutDecimal } from 'src/helpers/abbreviateNumber'
import './styles.scss'
import moment from 'moment'
import { FORMATS } from 'src/constants'

const colors = [
  "#eb4b2d",
  "#00eb7d",
  "#c3ffe1",
  '#8FCBA1',
  '#63A6C8',
]
interface IProps {
  campaignReportDetails: ICampaignReportData
}

export default function ReportPage3({ campaignReportDetails }: IProps): ReactElement {
  const [devices, setDevices] = useState<any[]>([])
  const [devicesPercentages, setDevicesPercentages] = useState<COUNTRIES[]>([])
  const [countries, setCountries] = useState<any[]>([])
  const [countriesPercentages, setCountriesPercentages] = useState<COUNTRIES[]>([])

  useEffect(() => {
    if (!!campaignReportDetails?.countries?.length) {
      const country: any[] = [];
      campaignReportDetails.countries.slice(0, 5).map(e => {
        country.push({ name: e._id, value: e.count })
      })

      const total = campaignReportDetails.countries.slice(0, 5).reduce((a, i) => a + i.count, 0)
      const final: DEVICES[] = campaignReportDetails.countries.slice(0, 5).map(e => ({ ...e, percentage: e.count * 100 / total }))
      final.sort((a, b) => b.count - a.count);
      setCountriesPercentages(final)
      country.sort((a, b) => b.value - a.value);
      setCountries(country)
    }

    if (!!campaignReportDetails?.devices?.length) {
      const device: any[] = [];
      campaignReportDetails.devices.slice(0, 5).map(e => {
        device.push({ name: e._id, value: e.count })
      })

      const total = campaignReportDetails.devices.slice(0, 5).reduce((a, i) => a + i.count, 0)
      const final: COUNTRIES[] = campaignReportDetails.devices.slice(0, 5).map(e => ({ ...e, percentage: e.count * 100 / total }))
      final.sort((a, b) => b.count - a.count);
      setDevicesPercentages(final)
      device.sort((a, b) => b.value - a.value);
      setDevices(device)
    }
  }, [campaignReportDetails])


  return (
    <div className="report-page-container flex-column report-page3">
      <img className="second-page-green-strip-image" alt="green-strip-image" src={Blue_Strip_Image} />
      <h3 className="second-page-heading">Analytics</h3>
      <img className="second-page-logo-image" alt="logo-image" src={Logo_Image} />

      <Row className="second-page-brief-details" justify="center" align="top" gutter={[16, 32]}>
        {
          campaignReportDetails?.countries && !!campaignReportDetails?.countries.length &&
          <>
            <Col span={7}>
              <h3>Top Countries</h3>
              <Row className='chart-left-labels'>
                <ul>
                  {
                    countriesPercentages.map((e, index) =>
                      <li>
                        <Col span={24}><h3><span className='square-bullet' style={{ color: colors[index] }}>&#9632;</span>{e._id}</h3></Col>
                        <Col span={24}><h2>{Math.round(e.percentage * 100) / 100}%</h2></Col>
                      </li>
                    )
                  }
                </ul>
              </Row>
            </Col>
            <Col span={17}>
              <div className='pie-chart'>
                <QPieChart
                  data={countries}
                  // @ts-ignore
                  height={650}
                  minWidth={600}
                  innerRadius={140}
                  outerRadius={240}
                  chartArrow={true}
                  textColor={'#173994'}
                  textFontSize={'25'}
                  weeklyReport={true}
                  minAngle={4}
                  showOnlyPercentage={true}
                  colors={colors}
                />
              </div>
            </Col>
          </>
        }

        {
          campaignReportDetails?.devices && !!campaignReportDetails?.devices.length &&
          <>
            <Col span={7}>
              <h3>Top Device Types</h3>
              <Row className='chart-left-labels'>
                <ul>
                  {
                    devicesPercentages.map((e, index) =>
                      <li>
                        <Col span={24}><h3><span className='square-bullet' style={{ color: colors[index] }}>&#9632;</span>{e._id}</h3></Col>
                        <Col span={24}><h2>{Math.round(e.percentage * 100) / 100}%</h2></Col>
                      </li>
                    )
                  }
                </ul>
              </Row>
            </Col>
            <Col span={17}>
              <div className='pie-chart'>
                <QPieChart
                  data={devices}
                  // @ts-ignore
                  height={650}
                  minWidth={600}
                  innerRadius={140}
                  outerRadius={240}
                  chartArrow={true}
                  textColor={'#173994'}
                  textFontSize={'25'}
                  weeklyReport={true}
                  minAngle={4}
                  showOnlyPercentage={true}
                  colors={colors}
                />
              </div>
            </Col>
          </>
        }
      </Row>
    </div>
  )
}
