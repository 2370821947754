import { requestHttp, urls } from 'src/api'
import { STATUS_CODES } from 'src/constants'

import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { AxiosResponse } from 'axios'


export const getAllPublishersList = async({offset,limit,direction,sortField,search}:any) => {
    try {
        const response = await requestHttp.get(urls.getListAllPublishersUrl({offset,limit,direction,sortField,search}))
        const { content } = response.data.data
        return content
    } catch (error) {
        return error
    }
}

export const fetchPublisherFilterList = (
  limit: any
): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (): Promise<AxiosResponse> => {
  try {
    const params = {
      offset: 0,
      limit,
      direction: "asc",
      sortField: "companyName",
      search: ""
    }; 
    let totalRecords: any = []
    const response = await requestHttp.get(urls.getListAllPublishersUrl(params))
    response.data.data.content.results.map((ele: any) => {
      totalRecords.push({
        label: ele.companyName,
        value: ele.id,
      })
    })

    return totalRecords
  } catch (error) {
    return error
  }
}

export const createPublisher = async(payload:any) => {
    try {
        const response = await requestHttp.post(urls.getCreatePublisherUrl(),payload);
        const { content } = response.data.data
        return content
    } catch (error) {
        return null
    }
}

export const updatePublisher = async(payload:any) => {
    try {
        const response = await requestHttp.put(urls.getEditPublisherUrl(),payload);
        const { content } = response.data.data
        return content
    } catch (error) {
        return null
    }
}

export const deletePublisher = async(id:any) => {
    try {
        const response = await requestHttp.delete(urls.getDeletePublisherUrl(id));
        const { content } = response.data.data
        return content
    } catch (error) {
        return error
    }
}


