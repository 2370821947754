import { GUARD_META, PATHS } from '../../constants'
import { store } from '../../store'
import { getCurrentUserPermissionActions } from '../../modules/core/core.selectors'

const requirePermission = async (to: any, from: any, next: any) => {
  const actions = getCurrentUserPermissionActions(store.getState())

  if (to.meta[GUARD_META.PERMISSION] && !actions.includes(to.meta[GUARD_META.PERMISSION])) {
    next.redirect(PATHS.ROOT)
  }

  next()
}

export default requirePermission
