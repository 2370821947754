export enum PERMISSIONS {
  CREATE_TARGETING_KEYS = "create_targeting_keys",
  EDIT_TARGETING_KEYS = "edit_targeting_keys",
  VIEW_TARGETING_KEYS = "view_targeting_keys",
  DELETE_TARGETING_KEYS = "delete_targeting_keys",

  CREATE_CUSTOM_MEDIA = "create_custom_media",
  UPDATE_CUSTOM_MEDIA = "update_custom_media",
  DELETE_CUSTOM_MEDIA = "delete_custom_media",
  VIEW_CUSTOM_MEDIA = "view_custom_media",

  MANAGE_PROFILE = "manage_profile",
  MANAGE_SERVICES = "manage_services",
  UPDATE_SERVICES = "update_services",
  MANAGE_CALENDAR = "manage_calendar",
  MANAGE_WORKFLOW = "manage_workflow",
  CREATE_WORKFLOW = "create_workflow",
  DELETE_WORKFLOW = "delete_workflow",
  REOPEN_WORKFLOW_ITEM = "reopen_workflow_item",
  PROCESS_ALL_WORKFLOW_ITEMS = "process_all_workflow_items",

  CAMPAIGNS_ALL = "view_all_campaigns_listing",
  CAMPAIGN_CREATE = "create_campaign",
  CAMPAIGN_VIEW = "view_campaign",
  CAMPAIGN_COMPLETE = "complete_campaign",
  CAMPAIGN_EDIT = "edit_campaign",
  CAMPAIGN_EDIT_DISTRIBUTION = "edit_campaign_distribution",
  CAMPAIGN_EDIT_MEDIA_BUY = "edit_campaign_media_buy",
  CAMPAIGN_EDIT_SURVEY = "edit_campaign_survey",
  CAMPAIGN_EDIT_GALLERY = "edit_campaign_gallery",
  CAMPAIGN_EDIT_REPORTS = "edit_campaign_reports",

  PROMOTION_CREATE = "create_promotion",
  PROMOTION_UPDATE = "update_promotion",
  PROMOTION_DELETE = "delete_promotion",
  PROMOTION_VIEW = "view_promotion",

  PARTNER_VIEW = "view_partners_list",
  PARTNER_VIEW_PROFILE = "view_partner_and_public_profile",
  PARTNER_UPDATE_PROFILE = "update_partner_and_public_profile",
  PARTNER_UPDATE_PROPS = "update_partner_properties",
  PARTNER_SUSPEND = "suspend_partner",
  PARTNER_UPDATE_PASSWORD = "update_partner_password",

  CREATE_CLIENT = "create_client",
  CLIENT_VIEW = "view_clients_list",
  CLIENT_VIEW_PROFILE = "view_client_profile",
  CLIENT_CREATE = "create_client",
  CLIENT_UPDATE_PROFILE = "update_client_profile",
  CLIENT_SUSPEND = "suspend_client",
  CLIENT_UPDATE_PASSWORD = "update_client_password",
  CLIENT_ALLOW_PO_UPLOAD = "allow_po_upload",

  INVITE_CLIENT = "invite_client",

  SIGN_UP_REQUESTS = "view_request_listing",
  SIGN_UP_PARTNER_ACCEPT = "accept_partner",
  SIGN_UP_PARTNER_REJECT = "reject_partner",
  SIGN_UP_CLIENT_ACCEPT = "accept_client",
  SIGN_UP_CLIENT_REJECT = "reject_client",

  BRIEFS_ALL = "view_all_briefs_listings",
  BRIEF_VIEW = "view_brief",
  BRIEF_CREATE_AND_UPDATE = "create_and_edit_brief",
  BRIEF_DELETE = "delete_brief",
  BRIEFS_ALL_ONLY_ASSIGNED = "view_only_assigned_briefs",
  MANAGE_PO_BANK_TRANSFER_PAYMENTS_FOR_BRIEFS = "manage_po_bank_transfer_payments_for_briefs",

  BILLS_ALL = "view_all_bills_listings",
  PARTNER_BILLS_ALL = "view_partner_bills_listings",
  PARTNER_BILL_VIEW_DETAILS = "view_partner_bill_details",
  CLIENT_BILLS_ALL = "view_client_bills_listings",
  CLIENT_BILL_VIEW_DETAILS = "view_client_bill_details",
  CLIENT_BILL_VIEW_DETAILS_FOR_ASSIGNED_BRIEFS_ONLY = "view_client_bill_details_for_assigned_briefs_only",
  BILL_APPROVE = "approve_bill",
  BILL_REJECT = "reject_bill",
  BILL_OVERRIDE = "override_bill",
  BILL_DELETE = "delete_bill",
  BILL_VIEW = "view_bill",
  BILL_CREATE_AND_EDIT = "create_and_edit_bill",

  VIEW_ONLINE_PAYMENTS = "view_online_payments",
  INITIATE_REFUNDS = "initiate_refunds",

  EMPLOYEES_ALL = "view_all_employees_listing",
  EMPLOYEE_CREATE = "create_employee",
  EMPLOYEE_UPDATE = "update_employee",
  EMPLOYEE_DELETE = "delete_employee",
  EMPLOYEE_VIEW_PROFILE = "view_employees_profile",
  EMPLOYEE_UPDATE_PROFILE = "update_employees_profile",

  ROLES_AND_PERMISSIONS = "view_roles_and_permissions",
  ROLE_UPDATE = "update_roles_and_permissions",
  ROLE_CREATE = "create_role",
  ROLE_DELETE = "delete_role",

  REQUEST_ALL = "view_all_briefs_request_listings",
  REQUEST_DETAILS = "view_all_briefs_request_listings",
  REQUEST_EXTEND = "extend_request",
  REQUEST_ASSIGN_TO_PARTNER = "assign_request_to_partner",
  REQUEST_PARTNER_ACCEPT = "accept_request_on_partners_behalf",
  REQUEST_PARTNER_REJECT = "reject_request_on_partners_behalf",
  REQUEST_ASSIGN_TO_SELF = "assign_request_to_self",
  REQUEST_ASSIGN_TO_EMPLOYEE = "assign_request_to_employee",

  NOTIFICATION_ALL = "view_notifications",
  NOTIFICATION_RECEIVE_BRIEF_CREATED = "receive_on_new_brief_created",
  NOTIFICATION_RECEIVE_BRIEF_UPDATED = "receive_on_new_brief_updated",
  NOTIFICATION_RECEIVE_NEW_CAMPAIGN_CREATED = "receive_on_new_campaign_created",
  NOTIFICATION_RECEIVE_CAMPAIGN_UPDATED = "receive_on_campaign_updated",
  NOTIFICATION_RECEIVE_REQUEST = "receive_on_new_request_received",

  PROFILE_VIEW = "view_profile",
  PROFILE_UPDATE = "update_profile",
  CHANGE_PASSWORD = "change_password",
  EMPLOYEE_DASHBOARD = "view_admin_employee_dashboard",

  DEMOGRAPHICS = "demographics_management",
  CREATE_DEMOGRAPHICS = "create_demographics",
  UPDATE_DEMOGRAPHICS = "update_demographics",
  DELETE_DEMOGRAPHICS = "delete_demographics",
  ACTIVITIES = "view_activity",
  CHANGE_USER_PASSWORD = "change_user_password",
  VIEW_REFERRAL_PROGRAM = "view_referral_program",

  DISCOUNT_CODES = "view_discount_codes_listing",
  DISCOUNT_CODES_CREATE = "create_and_edit_discount_code",
  DISCOUNT_CODES_UPDATE = "create_and_edit_discount_code",
  DISCOUNT_CODES_DELETE = "delete_discount_code",

  ADMIN_SETTINGS = "view_admin_settings",
  ADMIN_SETTINGS_EDIT = "edit_admin_settings",

  ASSIGN_BRIEF_TO_EMPLOYEE = "assign_briefs",

  VIEW_INVITED_REQUEST_LISTING = "view_invited_request_listing",
  ACCEPT_INVITED_CLIENT = "accept_invited_client",
  REJECT_INVITED_CLIENT = "reject_invited_client",

  VIEW_EXCLUSIVE_BRIEFS_LISTINGS = "view_exclusive_briefs_listings",
  VIEW_EXCLUSIVE_BRIEF = "view_exclusive_brief",
  CREATE_AND_EDIT_EXCLUSIVE_BRIEF = "create_and_edit_exclusive_brief",
  DELETE_EXCLUSIVE_BRIEF = "delete_exclusive_brief",

  LEADS = "view_leads",

  SUPPORT_TICKETS_MODULE_ACCESS = "view_support_tickets_listing",
  CREATE_AND_EDIT_SUPPORT_TICKET = "create_and_edit_support_ticket",
  DELETE_SUPPORT_TICKET = "delete_support_ticket",

  VIEW_PRIVACY_POLICY = "view_privacy_policy",

  VIEW_DISCLAIMER = "view_disclaimer",

  VIEW_RELEASE_NOTES = "view_release_notes",

  VIEW_SUBSCRIPTION_HISTORY = "view_subscription_history_listing",
  CANCEL_SUBSCRIPTION = "cancel_subscription",
  PAY_SUBSCRIPTION = "pay_subscription",

  WALLET_MANAGEMENT = "wallet_management",

  PRODUCTS_MANAGEMENT = "products_management",

  VIEW_PUBLISHERS_LIST = "view_publishers_list",
  CREATE_PUBLISHER = "create_publisher",
  DELETE_PUBLISHER = "delete_publisher",
  UPDATE_PUBLISHER = "update_publisher",

  DEFAULT = "default",
}
