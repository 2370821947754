const MODULE_NAME = 'admin/admin-settings'

export const getAdminSettingsDatatUrl = (): string => `${MODULE_NAME}/list`

export const postManagementFeesUrl = (): string => `${MODULE_NAME}/management-fee`
export const patchTargetingControlsUrl = (): string => `${MODULE_NAME}/targeting-controls`
export const patchThemeDataUrl = (): string => `${MODULE_NAME}/theme`
export const postAdminSettingRecordUrl = (): string => `${MODULE_NAME}/campaign-ops-prices`
export const patchAdminSettingRecordUrl = (id: number): string => `${MODULE_NAME}/campaign-ops-prices/${id}`
export const deleteAdminSettingRecordUrl = (id: number): string => `${MODULE_NAME}/campaign-ops-prices/${id}`

export const updateWorkflowUrl = (): string => `${MODULE_NAME}/workflow`
export const createWorkflowUrl = (): string => `${MODULE_NAME}/workflow/items`
export const listWorkflowUrl = (): string => `${MODULE_NAME}/workflow/items`
export const deleteWorkflowUrl = (id:number): string => `${MODULE_NAME}/workflow/items/${id}`
