import IAction from '../../../interfaces/IAction'
import * as CONSTANTS from './profile.constants'
import get from 'lodash/get'
import { PARTNER_STEPS_EDIT } from './containers/ProfilePartner.container'
import { IForm, IPartner, IPartnerSettings } from './profile.types'
import { CURRENCY } from '../../../constants/currencies'

const settingsInitState: IPartnerSettings = {
  ageGroups: [],
  industries: [],
  segments: [],
  mediaTypes: [],
  countries: [],
  currencies: [],
  rejectionReasons: [],
  mediaSubtypes: [],
}

export const initialState: IPartner = {
  customMediaServices:[],
  unfinishedSteps: [],
  filledSteps: [],
  currentStep: PARTNER_STEPS_EDIT[0],
  formBoolean: {
    companyName: false,
    companyDescription: false,
    companyDescriptionAr: false,
    companyWebsite: false,
    headquarterAddress: false,
    metrics: false,
    socialMediaLinks: false,
    isAutomaticallyApprovedRequest: false,
    productCategories: false,
    Looks: false, // metrics.Looks monthlyVisitors
    Leads: false, // metrics.Leads monthlyCustomers
    amountOfOrders: false,
    costOfOrder: false,
    currency: false,
    partnerGenders: false,
    partnerAgeGroups: false,
    partnerTargetSegments: false,
    countries: false,
    cities: false,
    isSampleDistribution: false,
    pricePerSample: false,
    isInsertDistribution: false,
    pricePerInsert: false,
    warehouseAddresses: false,
    isMediaBuy: false,
    mediaBuyTypes: false,
    account_currency: false,
    account_number: false,
    address: false,
    bank_address: false,
    bank_name: false,
    iban_number: false,
    swift_code: false
  },
  form: {
    companyName: '',
    companyDescription: '',
    companyDescriptionAr: '',
    companyWebsite: '',
    headquarterAddress: '',
    customInvoicing:'NO',
    webhookUrl:'',
    webhookAuth:'',
    metrics: {
      Leads: 0,
      Looks: 0,
      Facebook: 0,
      Instagram: 0,
      Twitter: 0,
    },
    socialMediaLinks: {
      facebook: 0,
      instagram: 0,
      twitter: 0,
      linkedin: 0,
      youtube: 0,
    },
    bankDetails:{
      account_currency:"",
      account_number:"",
      address:"",
      bank_address:"",
      bank_name:"",
      iban_number:"",
      swift_code:""
    },
    isAutomaticallyApprovedRequest: false,
    productCategories: [],
    amountOfOrders: 0,
    costOfOrder: 0,
    currency: CURRENCY.USD,
    genderFocus: {},
    ageGroups: {},
    segments: {},
    partnerGenders: null,
    partnerAgeGroups: null,
    partnerTargetSegments: null,
    countries: [],
    cities: [],
    isSampleDistribution: false,
    pricePerSample: 0,
    hasDistributionTypePricing: false,
    hasDynamicSamplePricing: false,
    hasSampleProductTypePricing: false,
    dynamicSamplePriceTypes: null,
    dynamicSamplePrices: null,
    isInsertDistribution: false,
    pricePerInsert: 0,
    warehouseAddresses: [''],
    isMediaBuy: false,
    impressionRate: 0,
    conversionRate: 0,
    reachRate: 0,
    advertisementLanguage: [],
    mbPricingModel: '',
    mediaBuyTypes: [],
    publicProfile: {
      id: 0,
      userId: 0,
      listingShortDescription: '',
      companyLogo: '',
      previewCardImage: '',
      listingHeaderImage: '',
      highlights: [''],
      metricsMarketing: [''],
    },
    taxTreatment:'VAT Registered',
    cr:''
  },
  settings: settingsInitState,
  partnerProfilePercent: 0,
  fetchPartnerLoading: true,
  imagesUploading: false,
  imagesUploadingError: '',
  imagesRemoving: false,
  imagesRemovingError: '',
}

export default (state: IPartner = initialState, action: IAction): IPartner => {
  switch (action.type) {
    case CONSTANTS.GET_ALL_CUSTOM_MEDIA_SUCCESS:
      const customList = get(action, ['payload', 'data'])
      return { ...state, customMediaServices: [...customList] }
    case CONSTANTS.SET_UNFINISHED:
      const unfinishedList = get(action, ['payload', 'step'])
      return { ...state, unfinishedSteps: [...state.unfinishedSteps, unfinishedList] }
    case CONSTANTS.SET_CURRENT_STEP:
      const currentStep = get(action, ['payload', 'step'])
      return { ...state, currentStep }
    case CONSTANTS.SET_FILLED:
      const filledList = get(action, ['payload', 'step'])
      return { ...state, filledSteps: [...state.filledSteps, filledList] }
    case CONSTANTS.SET_PARTNER_SETTING:
      const settings = get(action, ['payload', 'settings'])
      return { ...state, settings: settings }
    case CONSTANTS.SET_FORM:
      const form = get(action, ['payload', 'form'])
      return { ...state, form: { ...state.form, ...form } }
    case CONSTANTS.SET_FORM_BOOLEAN:
      const formBoolean = get(action, ['payload', 'formBoolean'])
      return { ...state, formBoolean: formBoolean }
    case CONSTANTS.FETCH_PARTNER_PROFILE_SUCCESS:
      const partnerProfile: IForm = get(action, 'payload.partnerProfile')
      return {
        ...state,
        form: partnerProfile,
      }
    case CONSTANTS.SET_PARTNER_PROFILE_PERCENT:
      const percent = action.percent ? action.percent : 0
      return { ...state, partnerProfilePercent: percent }
    case CONSTANTS.FETCH_PARTNER_PROFILE_LOADING:
      const loading: boolean = get(action, 'payload.loading')
      return {
        ...state,
        fetchPartnerLoading: loading,
      }
    case CONSTANTS.UNMOUNT_CLEAR_DATA:
      return {
        ...initialState,
      }

    case CONSTANTS.IMAGE_UPLOAD_REQUEST:
      return { ...state, imagesUploading: true }
    case CONSTANTS.IMAGE_UPLOAD_SUCCESS:
      return {
        ...state,
        form: get(action, 'payload.form'),
        imagesUploading: false,
      }
    case CONSTANTS.IMAGE_UPLOAD_FAILURE:
      return { ...state, imagesUploadingError: action.error, imagesUploading: false }

    case CONSTANTS.IMAGE_REMOVE_REQUEST:
      return { ...state, imagesRemoving: true }
    case CONSTANTS.IMAGE_REMOVE_SUCCESS:
      return {
        ...state,
        form: get(action, 'payload.form'),
        imagesRemoving: false,
      }
    case CONSTANTS.IMAGE_REMOVE_FAILURE:
      return { ...state, imagesRemovingError: action.error, imagesRemoving: false }

    default:
      return state
  }
}
