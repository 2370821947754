import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useReduxDispatch } from '../../../helpers'
import { getIsActivitiesLoading, getActivities, getActivitiesTotal, getPages } from '../activities.selectors'
import { IUser } from '../activities.types'
import entries from 'lodash/entries'
import useDebounce from '../../../hooks/useDebounce'
import * as actions from '../activities.actions'

import { FORMATS, ROLES } from '../../../constants'
import { SearchOutlined } from '@ant-design/icons'
import { Row, Col, Form, QTable, QInput, QSelect, QRangePicker, QPagination } from 'quantum_components'
import { getIsoDate } from '../../../helpers/datesHelper'

import {
  LOCALIZATION_LANGUAGES,
} from "src/constants";
import { getLocaleCostWithoutDecimal } from 'src/helpers/currency'

const Activities = () => {
  const { i18n } = useTranslation()
  const { t } = useTranslation(['translationSettings', 'translationCommon', 'translationDiscounts'])
  const dispatch = useReduxDispatch()
  const activities = useSelector(getActivities)
  const activitiesTotal = useSelector(getActivitiesTotal)
  const activitiesIsLoading = useSelector(getIsActivitiesLoading)
  const pages = useSelector(getPages)

  const [search, setSearch] = useState<string>('')
  const [roleFilter, setRoleFilter] = useState<string[]>([])
  const [dateFilter, setDateFilter] = useState({})

  const [params, setParams] = useState({
    currentPage: 1,
    limit: 10,
    offset: 0,
    direction: 'desc',
    sortField: 'createdAt',
  })

  const debouncedSearch = useDebounce(search, 500)
  const debouncedRoleFilter = useDebounce(roleFilter, 500)
  const debouncedDateFilter = useDebounce(dateFilter, 500)

  const fetchActivities = (queryParams = params) => {
    dispatch(
      actions.fetchActivities({
        ...queryParams,
        ...debouncedDateFilter,
        search: debouncedSearch,
        role: debouncedRoleFilter,
      })
    )
  }

  useEffect(() => {
    fetchActivities()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch, debouncedRoleFilter, debouncedDateFilter])

  // @ts-ignore
  const handleTableChange = (pagination, filters, sorter) => {
    const sortingParams = {
      direction: sorter.order.slice(0, -3),
      sortField: sorter.columnKey,
    }
    const newParams = {
      ...params,
      ...sortingParams,
    }
    setParams(newParams)
    fetchActivities(newParams)
  }

  const handleChangeSearch = (event: any) => {
    setSearch(event.currentTarget.value)
  }

  const handleRoleSelect = (roles: string[]) => {
    dispatch(actions.setCurrentPage(1))
    setRoleFilter(roles)
  }

  const handleRangePicker = (dates: moment.Moment[] | null) => {
    dispatch(actions.setCurrentPage(1))

    if (!dates) {
      setDateFilter({})
      return
    }

    let formattedDates = { ...dateFilter }

    formattedDates = { start: getIsoDate(dates[0].startOf('day')) }
    if (dates[1]) formattedDates = { ...formattedDates, end: getIsoDate(dates[1].endOf('day')) }

    setDateFilter(formattedDates)
  }

  const handleChangePage = (page: number, pageSize: number) => {
    dispatch(actions.setCurrentPage(page))

    dispatch(
      actions.fetchActivities({
        ...params,
        ...debouncedDateFilter,
        search: debouncedSearch,
        role: debouncedRoleFilter,
        offset: (page - 1) * params.limit,
        currentPage: page,
        limit: pageSize,
      })
    )

    setParams({ ...params, limit: pageSize })
  }

  const selectRoleOptions = [
    { value: ROLES.CLIENT, label: t('settings.activities.CLIENT') },
    { value: ROLES.PARTNER, label: t('settings.activities.PARTNER') },
    { value: ROLES.ADMIN, label: t('settings.activities.ADMIN') },
  ]

  const columns = [
    {
      title: t('settings.activities.User Name'),
      dataIndex: 'user',
      key: 'user',
      fixed: 'left',
      width: '20%',
      sorter: true,
      render: (user: IUser) => {
        return (
          <p className="mb-0">
            {user.firstName} {user.lastName}
          </p>
        )
      },
    },
    {
      title: t('settings.activities.Role'),
      dataIndex: 'role',
      key: 'role',
      width: '15%',
      sorter: true,
      render: (role: string) => {
        return (
          <p className="mb-0">
            {t('settings.activities.' + role)}
          </p>
        )
      },
    },
    {
      title: t('settings.activities.Action'),
      dataIndex: 'action',
      key: 'action',
      width: '20%',
      sorter: true,
    },
    {
      title: t('settings.activities.Description'),
      dataIndex: 'description',
      key: 'description',
      width: '25%',
      sorter: true,
    },
    {
      title: t('settings.activities.Created Date Time'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
    },
  ]

  return (
    <>
      <Row justify="space-between" align="middle" gutter={40}>
        <Col>
          <h2 className="pt-10 pb-10">{t('settings.activities.Activities')}</h2>
        </Col>
        <Col>
          <h5>{t('settings.activities.Total available activities')}: {getLocaleCostWithoutDecimal(activitiesTotal)}</h5>
        </Col>
      </Row>
      <Form layout="vertical">
        <Row gutter={20}>
          <Col span={6}>
            <Form.Item label={t('settings.activities.Filter by text match')}
              name="search">
              <QInput
                value={search}
                onChange={handleChangeSearch}
                placeholder={t('common.Search', { ns: 'translationCommon' })}
                size="large"
                prefix={<SearchOutlined />}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label={t('settings.activities.Filter by role')}
              name="select">
              <QSelect
                size="large"
                mode="multiple"
                placeholder={t('settings.activities.Select a role')}
                maxTagCount={3}
                maxTagTextLength={8}
                options={selectRoleOptions}
                onChange={handleRoleSelect}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label={t('settings.activities.Filter by date')}
              name="date">
              <QRangePicker
                placeholder={[t("Start date", { ns: 'translationDiscounts' }), t("End date", { ns: 'translationDiscounts' })]}
                size="large"
                className="qu-datepicker"
                onChange={handleRangePicker}
                format={FORMATS.DATE_FORMAT}
                allowEmpty={[false, true]}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div className="pb-30">
        <QTable
          columns={columns}
          dataSource={activities}
          loading={activitiesIsLoading}
          scroll={{ x: 'auto' }}
          // @ts-ignore
          onChange={handleTableChange}
          sortDirections={['ascend', 'descend', 'ascend']}
        />
      </div>
      <Row className={`pb-30 ${i18n.language === LOCALIZATION_LANGUAGES.ARABIC ? 'html-rtl' : ''}`} justify="end">
        <QPagination
          size="small"
          defaultCurrent={1}
          showSizeChanger
          hideOnSinglePage
          current={pages.currentPage}
          total={activitiesTotal}
          onChange={handleChangePage}
        />
      </Row>
    </>
  )
}

export default Activities
