export const MODULE_NAME = 'signIn'

/* actions */
export const RESET_STATE = `${MODULE_NAME}/RESET_STATE`

export const SIGN_IN_REQUEST = `${MODULE_NAME}/SIGN_IN_REQUEST`
export const SIGN_IN_SUCCESS = `${MODULE_NAME}/SIGN_IN_SUCCESS`
export const SIGN_IN_FAILURE = `${MODULE_NAME}/SIGN_IN_FAILURE`

export const SIGN_IN_RESET_ERROR = `${MODULE_NAME}/SIGN_IN_RESET_ERROR`

export const SIGN_IN_STATUS_CODES = {
  SUCCESS: 200,
  FAILURE: 400,
}
