/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import { useReduxDispatch } from "../../../helpers";
import { isMobile } from "react-device-detect";
import * as actions from "../brief.actions";
import moment from "moment";
import lodash from "lodash";

import {
  Dropdown,
  IconsCommon,
  Menu,
  QButton,
  QInput,
  QMenuItem,
  QPagination,
  QSelect,
  QTable,
  QTableCardList,
  Row,
  Radio,
  QAlert,
} from "quantum_components";
import { useSelector } from "react-redux";
import {
  getBrief,
  getBriefList,
  getBriefListLoading,
  getBriefParams,
  getCardList,
  getPages,
  getTotalBriefList,
} from "../brief.selectors";
import { Col, Modal, Button, Select, Tooltip } from "antd";
import { SearchOutlined, CalendarOutlined } from "@ant-design/icons";
import useDebounce from "../../../hooks/useDebounce";
import {
  BRIEF_STATUS_GROUP,
  BRIEF_STATUS,
  BRIEF_STATUS_LIST,
  BRIEF_STATUS_NAMES,
  briefStatuses,
  BRIEF_PAYMENT_STATUS_LIST,
  BRIEF_PAYMENT_STATUS_NAMES,
  briefPaymentStatuses,
  FILTERS_BY_USERS,
  DEFAULT_BRIEF_TABLE_PARAMS,
  BRIEF_PAYMENT_STATUS,
} from "src/constants/brief";
import {
  campaignStatusesLabel,
  CAMPAIGN_STATUS_LIST,
} from "src/constants/campaign";
import { omit, snakeCase } from "lodash";
import {
  IBriefOwnerBase,
  IBriefCampaign,
  IBriefStatusGroup,
  IBriefStatus,
  IBriefListElement,
} from "../brief.types";
import { COLORS } from "../../../constants/colors";
import {
  getCurrentUser,
  getCurrentUserPermissionActions,
} from "../../core/core.selectors";
import { hasPermission, showListingActions } from "../../../helpers/permission";
import { PERMISSIONS } from "../../../constants/permission";
import { ReactComponent as WorkerChecked } from "src/assets/icons/worker-checked.svg";
import { ReactComponent as Search } from "src/assets/icons/search.svg";
import { TABS } from "src/modules/client/client-Profile/profile.types";
import { useTranslation } from "react-i18next";
import i18n from "src/i18n";
import { ALERTS_MSG } from "src/constants/alerts";
import { deleteBriefAsync } from "src/modules/briefV3/briefv3.actions";
import { FORMATS } from "src/constants";
import { fetchPublisherFilterList } from "src/modules/publishers/publishers.actions";

const BriefList = () => {
  const { t } = useTranslation([
    "translationABrief",
    "translation",
    "translationCommon",
    "translationCampaign",
  ]);
  const filterUsersOptions = [
    {
      label: t(`users-options.All`),
      value: FILTERS_BY_USERS.ALL_USERS,
    },
    {
      label: t(`users-options.Approved`),
      value: FILTERS_BY_USERS.USER_APPROVED,
    },
    {
      label: t(`users-options.Not Approved`),
      value: FILTERS_BY_USERS.USER_NOT_APPROVED,
    },
  ];
  const { Option, OptGroup } = Select;

  const history = useHistory();
  const location = useLocation();
  const dispatch = useReduxDispatch();
  const briefList = useSelector(getBriefList);
  const cardList = useSelector(getCardList);
  const brief = useSelector(getBrief);
  const totalBriefList = useSelector(getTotalBriefList);
  const briefListLoading = useSelector(getBriefListLoading);
  const params = useSelector(getBriefParams);
  const pages = useSelector(getPages);
  const permissions = useSelector(getCurrentUserPermissionActions);
  const currentUser = useSelector(getCurrentUser);
  const { id: userId } = useParams()
  const [modal, setModal] = useState({
    visible: false,
    id: null,
    clientId: null,
  });
  const [search, setSearch] = useState("");
  const [assignBriefToEmployeeModal, setAssignBriefToEmployeeModal] = useState<
    boolean
  >(false);
  const [assignBriefToEmployeeData, setAssignBriefToEmployeeData] = useState<
    any
  >();
  const [selectedEmployees, setSelectedEmployees] = useState<[]>([]);
  const [employeesList, setEmployeesList] = useState<[]>([]);
  const [publisherOptions, setPublisherOptions] = useState<[]>([]);
  const [tableKey, setTableKey] = useState(1);
  const [tableKey1, setTableKey1] = useState(100);
  const [activeTab, setActiveTab] = useState(TABS.CAMPAIGNS);

  const debouncedSearch = useDebounce(search, 500);

  const fetchPublishersList = async () => {
    const data = await getAllPublishersList({
      offset: 0,
      limit: 200,
      direction: "asc",
      sortField: "companyName",
      search: ""
    });
    const res = lodash.get(data, "results", []);
    return res;
  }

  useEffect(() => {
    dispatch(actions.fetchEmployeesList(200)).then((employees) =>
      setEmployeesList(employees)
    );
    dispatch(fetchPublisherFilterList(200)).then((publishers) => {
      setPublisherOptions(publishers);
    });

  }, []);

  useEffect(() => {
    const newParams = userId
      ? { ...params, userId, search: debouncedSearch, offset: 0 }
      : { ...params, search: debouncedSearch, offset: 0 };
    dispatch(actions.setParamsAndFetch({ ...newParams }));
    dispatch(actions.setCurrentPage(1));

    return () =>
      dispatch(actions.setBriefListParams(DEFAULT_BRIEF_TABLE_PARAMS));
  }, [debouncedSearch]);

  const handleOk = async () => {
    setModal({ visible: false });
    setTableKey1(tableKey1 + 1);

    if (modal.clientId) {
      await deleteBriefAsync(modal.id);
      dispatch(actions.fetchBriefList(params));
    } else {
      dispatch(actions.deleteBrief(modal.id, params));
    }
  };

  const handleDeleteBrief = (id, clientId?: any) => {
    setModal({ visible: true, id, clientId });
  };

  const translateDiscountText = (discountText: any) => {
    let buffer = new Array();
    buffer = discountText.split("%");

    if (buffer[0] == 0)
      return t("brief.cart." + snakeCase(buffer[1]), { ns: "translation" });

    return (
      buffer[0] +
      "% " +
      t("brief.cart." + snakeCase(buffer[1]), { ns: "translation" })
    );
  };

  const colKey = {
    product: "campaignName",
  };

  const trackerColumns = [
    {
      title: t(`product`),
      dataIndex: "product",
      key: "product",
      width: "20%",
      sorter: true,
      render: (product, list) => {
        return (
          <div className="q-table-product">
            {/*<div className="q-table-product_thumb">
                          {product.productFirstImage && (
                            <img src={product.productFirstImage} alt={product.title} />
                          )}
                        </div>*/}
            <div className="q-table-product_info">
              <h3 className="q-table-product_title">
                {product.title || t("unnamed_brief")}
              </h3>
              <div className="q-table-product_created">
                {t(`created`)} {product.created}
              </div>
              {product.discountText && (
                <div className="text-green line-height-10 font-size-12">
                  {product.discountText === "0% Discount Applied"
                    ? t("brief.cart.Discount_Applied", { ns: "translation" })
                    : translateDiscountText(product.discountText)}
                </div>
              )}
            </div>
          </div>
        );
      },
    },
    {
      title: t(`start_date`),
      dataIndex: "services",
      width: "15%",
      key: "services",
      render: (list) => (
        <ul className="q-table-list">
          {list.map((service, i) => {
            return service.title === "Media Buying" ? (
              <li key={i} className="q-table-list_item">
                {service.mediaStartDate || "-"}
              </li>
            ) : service.title === "Customized Box" ? (
              <li key={i} className="q-table-list_item">
                {service.customizedStartDate || "-"}
                <br />
                {service.productDuration || "-"}
              </li>
            ) : service.title === "Consumer Research" ? (
              <li key={i} className="q-table-list_item">
                {service.consumerStartDate || "-"}
                <br />
                {service.productDuration || "-"}
              </li>
            ) : (
              <li key={i} className="q-table-list_item">
                {service.productStartDate || "-"}
                <br />
                {service.productDuration || "-"}
              </li>
            );
          })}
        </ul>
      ),
    },
    {
      title: t(`workflow`),
      dataIndex: "workflow",
      key: "workflow",
      width: "40%",
      render: (workflow: any) => {
        if (workflow?.length < 1) return "-";
        return workflow?.map((el: any) => {
          return (
            <div
              className={`workflow-element ${
                el.processed ? "success" : "incomplete"
              }`}
            >
              {el.department}
            </div>
          );
        });
      },
    },
    {
      title: t(`status`),
      dataIndex: "status",
      key: "status",
      width: "13%",
      fixed: "right",
      render: (status) => {
        const statusClass = briefStatuses[status.value]?.classStyle ?? "";
        const statusLabel = briefStatuses[status.value]?.label ?? "draft";
        return (
          <div>
            <span className={`status-label ${statusClass}`}>
              {t(`status-options.${statusLabel}`)}
            </span>
          </div>
        );
      },
    },
    {
      title: t(`campaign`),
      dataIndex: "campaign",
      key: "campaign",
      width: "12%",
      render: (campaign: IBriefCampaign) => {
        return campaign == null ? (
          <div>-</div>
        ) : (
          <div onClick={(e) => e.stopPropagation()}>
            <Link
              to={`/campaigns/${campaign.id}`}
              style={{ textDecoration: "none" }}
            >
            <div>{t(`yes`)}</div>
            </Link>
          </div>
        );
      },
    },
  ];

  const columns = [
    {
      title: t(`product`),
      dataIndex: "product",
      key: "product",
      width: "18%",
      sorter: true,
      render: (product, list) => {
        return (
          <div className="q-table-product">
            {((list?.status?.value === BRIEF_STATUS.PENDING &&
              list?.paymentStatus?.value === BRIEF_PAYMENT_STATUS.PENDING) ||
              (list?.paymentStatus?.value === BRIEF_PAYMENT_STATUS.PENDING &&
                (list?.paymentStatus?.bankTransfer ||
                  list?.paymentStatus?.po)) ||
              ((list?.paymentStatus?.value === BRIEF_PAYMENT_STATUS.PAID ||
                list?.paymentStatus?.value ===
                  BRIEF_PAYMENT_STATUS.PO_ISSUED) &&
                currentUser?.workflowEnabled &&
                (!list?.workflow || !list?.workflow.length)) ||
              (list?.status?.value === BRIEF_STATUS.PENDING &&
                list?.v3Services?.some((o) => o.status === "PENDING"))) &&
            hasPermission(permissions, PERMISSIONS.BRIEF_VIEW) ? (
              <Tooltip title={t("Action Required")}>
                <span
                  className="action-required-red-dot"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleView(list.key, false,list.clientId);
                  }}
                ></span>
              </Tooltip>
            ) : (
              <span className="action-required-white-dot"></span>
            )}
            {/*<div className="q-table-product_thumb">
                          {product.productFirstImage && (
                            <img src={product.productFirstImage} alt={product.title} />
                          )}
                        </div>*/}
            <div className="q-table-product_info">
              <h3 className="q-table-product_title">
                {product.title || "Unnamed Brief"}
              </h3>
              <div className="q-table-product_created">
                {t(`created`)} {product.created}
              </div>
              {product.discountText && (
                <div className="text-green line-height-10 font-size-12">
                  {product.discountText === "0% Discount Applied"
                    ? t("brief.cart.Discount_Applied", { ns: "translation" })
                    : translateDiscountText(product.discountText)}
                </div>
              )}
            </div>
          </div>
        );
      },
    },
    {
      title: t(`owner`),
      dataIndex: "owner",
      key: "owner",
      width: "15%",
      render: (owner: IBriefOwnerBase) => {
        return (
          /* QUA-1449, QP-492 - prevent event bubbling to onRowClick */
          <div onClick={(e) => e.stopPropagation()}>
            <Link
              to={`/users/clients/profile/${owner.id}`}
              style={{ textDecoration: "none" }}
            >
              <div>
                {owner.firstName} {owner.lastName}
              </div>
              <div>{owner.companyName}</div>
            </Link>
          </div>
        );
      },
    },
    {
      title: t(`start_date`),
      dataIndex: "product",
      key: "product",
      width: "11%",
      render: (product, raw) => {
        if (raw.v3Services.length > 0) {
          return (
            <span>
              {moment(raw.v3Services[0].startDate).format(
                FORMATS.DATE_LL_FORMAT
              ) || "-"}
            </span>
          );
        } else {
          return <span>{product.mediaStartDate || "-"}</span>;
        }
      },
    },
    {
      title: t(`assignees`),
      dataIndex: "assignedUsers",
      key: "assignedUsers",
      width: "12%",
      render: (list) => (
        <ul className="q-table-list">
          {list.length ? (
            list.map((user, i) => {
              return (
                <li key={i} className="q-table-list_item">
                  <span className="check-icon">
                    <IconsCommon.IconUser />
                  </span>
                  {user.firstName} {user.lastName}
                </li>
              );
            })
          ) : (
            <span>-</span>
          )}
        </ul>
      ),
    },
    {
      title: t(`status`),
      dataIndex: "status",
      key: "status",
      width: "13%",
      render: (status) => {
        const statusClass = briefStatuses[status.value]?.classStyle ?? "";
        const statusLabel = briefStatuses[status.value]?.label ?? "draft";
        return (
          <div>
            <span className={`status-label ${statusClass}`}>
              {t(`status-options.${statusLabel}`)}
            </span>
          </div>
        );
      },
    },
    {
      title: t(`campaign`),
      dataIndex: "campaign",
      key: "campaign",
      width: "13%",
      render: (campaign: IBriefCampaign, raw) => {
        return campaign == null ? (
          <div>-</div>
        ) : (
          <div onClick={(e) => e.stopPropagation()}>
            <Link
              to={raw.v3Services.length > 0 ? `/campaigns/v3/${campaign.id}` : `/campaigns/${campaign.id}`}
              style={{ textDecoration: "none" }}
            >
              <span
                className={`status-label ${
                  campaignStatusesLabel[campaign.status] &&
                  campaignStatusesLabel[campaign.status].classStyle
                }`}
              >
                {campaignStatusesLabel[campaign.status]
                  ? t(campaignStatusesLabel[campaign.status].label, {ns: "translationCampaign"})
                  : ""}
              </span>
            </Link>
          </div>
        );
      },
    },
    {
      title: t(`payment_status`),
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      width: "15%",
      render: (paymentStatus, { status, clientId, campaign }) => {
        const paymentStatusClass =
          briefPaymentStatuses[paymentStatus?.value]?.classStyle ?? "";
        const paymentStatusLabel =
          briefPaymentStatuses[paymentStatus?.value]?.label ?? "";

        const rejectionStatuses = [
          BRIEF_STATUS.REJECTED,
          BRIEF_STATUS.PARTIALLY_REJECTED,
        ];
        const actions = [
          PERMISSIONS.BRIEF_VIEW,
          PERMISSIONS.BRIEF_CREATE_AND_UPDATE,
          PERMISSIONS.BRIEF_DELETE,
          PERMISSIONS.ASSIGN_BRIEF_TO_EMPLOYEE,
        ];
        const showActions = showListingActions(permissions, actions);
        return (
          <div className="w-100">
            {paymentStatus && status?.value !== BRIEF_STATUS.REJECTED ? (
              <span className={`status-label ${paymentStatusClass}`}>
                {t(paymentStatusLabel, { ns: "translation" })}
              </span>
            ) : (
              <div className="w-100 text-center">
                <span>-</span>
              </div>
            )}
            {paymentStatus?.value === BRIEF_PAYMENT_STATUS.PAID &&
              (paymentStatus?.onlinePayment ||
                paymentStatus?.isPaidByWallet) && (
                <span className="font-size-12 text-truncate tc--light">
                  {t("brief.payment.pending_payout", { ns: "translation" })}
                </span>
              )}

            {showActions && (
              /* QUA-1449, QP-492 - prevent event bubbling to onRowClick */
              <div onClick={(e) => e.stopPropagation()}>
                <Dropdown
                  overlay={menu(
                    status.id,
                    rejectionStatuses.includes(status.value),
                    clientId,
                    campaign ? campaign.id : null
                  )}
                  trigger={["click"]}
                  placement="bottomRight"
                >
                  <div className={`q-table_menu`}>
                    <IconsCommon.IconEllipsisV />
                  </div>
                </Dropdown>
              </div>
            )}
          </div>
        );
      },
    },
  ];

  const menu = (
    id: number,
    isRejected: boolean,
    clientId: string,
    publishedCampaignId: number,
  ) => (
    <Menu
      className={`user-menu-list ${i18n.dir() === "rtl" ? "html-rtl" : ""}`}
    >
      {hasPermission(permissions, PERMISSIONS.BRIEF_VIEW) && (
        <QMenuItem
          key={0}
          onClick={() => handleView(id, clientId)}
          name={t(`view`)}
          icon={<IconsCommon.IconView />}
        />
      )}
      {!isRejected &&
        hasPermission(permissions, PERMISSIONS.BRIEF_CREATE_AND_UPDATE) && (
          <QMenuItem
            key={1}
            onClick={() => handleEdit(id, clientId)}
            name={t(`edit`)}
            icon={<IconsCommon.IconEdit />}
          />
        )}
      {isRejected &&
        hasPermission(permissions, PERMISSIONS.BRIEF_CREATE_AND_UPDATE) && (
          <QMenuItem
            key={5}
            onClick={() => handleRejection(id, clientId)}
            name={t(`find_new_partner`)}
            icon={<Search />}
          />
        )}
      {!clientId &&
        hasPermission(permissions, PERMISSIONS.ASSIGN_BRIEF_TO_EMPLOYEE) && (
          <QMenuItem
            key={4}
            onClick={() => handleAssignToEmployee(id)}
            name={t(`assign_to_employee`)}
            icon={<WorkerChecked />}
          />
        )}
      
        {publishedCampaignId && <Menu.Divider />}
        {publishedCampaignId && hasPermission(permissions, PERMISSIONS.CAMPAIGN_VIEW) && (
            <QMenuItem
              key={1}
              onClick={() => handleView(id, clientId, publishedCampaignId, true)}
              name={t(`view_analytics`)}
              icon={<IconsCommon.IconView />}
            />
          )}
        {!clientId && publishedCampaignId &&
          hasPermission(permissions, PERMISSIONS.CAMPAIGN_EDIT) && (
            <QMenuItem
              key={1}
              onClick={() => handleEdit(id, clientId, publishedCampaignId, true)}
              name={t(`edit_analytics`)}
              icon={<IconsCommon.IconEdit />}
            />
          )}
      
      <Menu.Divider />
      {hasPermission(permissions, PERMISSIONS.BRIEF_DELETE) && (
        <QMenuItem
          key={2}
          onClick={() => handleDeleteBrief(id, clientId)}
          name={t(`delete`)}
          icon={<IconsCommon.IconDelete />}
        />
      )}
      {!clientId && hasPermission(permissions, PERMISSIONS.BRIEF_VIEW) && (
        <QMenuItem
          key={3}
          onClick={() => handleDownloadMedia(id)}
          name={t(`download_media`)}
          icon={<IconsCommon.IconDownload />}
        />
      )}
    </Menu>
  );

  const handleChangeSearch = (e) => {
    setSearch(e.currentTarget.value);
  };

  const handleFilterUsers = (activeFilter: string) => {
    const shouldFilterByApproved = [
      FILTERS_BY_USERS.USER_APPROVED,
      FILTERS_BY_USERS.USER_NOT_APPROVED,
    ].includes(activeFilter);
    if (shouldFilterByApproved) {
      const isApproved = activeFilter === FILTERS_BY_USERS.USER_APPROVED;
      dispatch(actions.setParamsAndFetch({ ...params, offset: 0, isApproved }));
      return;
    }

    dispatch(
      actions.setParamsAndFetch(omit({ ...params, offset: 0 }, "isApproved"))
    );
  };

  const handleChangeSelect = (activeStatuses: string[]) => {
    const statuses = activeStatuses.reduce(
      (acc, statusFullName) => {
        const statusGroupName = statusFullName.split("-")[0];
        const statusName = statusFullName.split("-")[1];

        const groupNames: { [key in BRIEF_STATUS_GROUP]: string } = {
          [BRIEF_STATUS_GROUP.BRIEF]: "status",
          [BRIEF_STATUS_GROUP.PAYMENT]: "paymentStatus",
          [BRIEF_STATUS_GROUP.CAMPAIGN]: "campaignStatus",
        };
        const groupName = groupNames[statusGroupName];

        if (acc[groupName]) {
          acc[groupName].push(statusName);
        } else {
          acc[groupName] = [statusName];
        }
        return acc;
      },
      { status: [], paymentStatus: [] }
    );
    dispatch(actions.setParamsAndFetch({ ...params, ...statuses, offset: 0 }));
  };

  const handlePublisherSelect = (selectedPublishers: string[]) => {
    
    dispatch(actions.setParamsAndFetch({ ...params, publisherId: selectedPublishers, offset: 0 }));
  };

  const handleChangePage = (page: number, pageSize: number) => {
    dispatch(actions.setCurrentPage(page));
    dispatch(
      actions.setParamsAndFetch({
        ...params,
        offset: (page - 1) * pageSize,
        limit: pageSize,
      })
    );
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const sortingParams = {
      direction: sorter.order.slice(0, -3),
      sortField: colKey[sorter.columnKey],
    };
    const newParams = {
      ...params,
      ...sortingParams,
    };
    dispatch(actions.setParamsAndFetch(newParams));
  };
  const handleView = (id, clientId, publishedCampaignId = null, isCampaignAction = false) => {
    //v2
    if(clientId) {
      if(isCampaignAction && publishedCampaignId) history.push(`/campaigns/v3/${publishedCampaignId}`);
      else history.push(`/briefs/v3/edit/${id}`);
    }
    //v1
    else{
      if(isCampaignAction) history.push(`/campaigns/${publishedCampaignId}`);
      else history.push(`/briefs/view/${id}`);

    }
  };

  const handleEdit = (id, clientId, publishedCampaignId = null, isCampaignAction = false) => {
    dispatch(actions.setCurrentStep(brief.lastFilledStep));
    dispatch(actions.setNeedCheckLastStep(true));
    //v2
    if(clientId) {
      history.push(`/briefs/v3/edit/${id}`);
    }
    //v1
    else{
      if (isCampaignAction && publishedCampaignId) history.push(`/campaigns/edit/${publishedCampaignId}`); 
      else history.push(`/briefs/edit/${id}`);
    }
  };

  const handleAssignToEmployee = async (id) => {
    let selectedBrief = briefList.filter((ele) => ele.key === id);
    setAssignBriefToEmployeeModal(true);
    setAssignBriefToEmployeeData(selectedBrief[0]);
    setSelectedEmployees(selectedBrief[0].assignedUsers.map((ele) => ele.id));
  };

  const assignBriefToEmployee = async () => {
    let response = await dispatch(
      actions.saveAssignBriefToEmployees(
        selectedEmployees,
        assignBriefToEmployeeData.key
      )
    );
    if (response == 200) {
      setAssignBriefToEmployeeModal(false);
      setTableKey(tableKey + 1);
      setAssignBriefToEmployeeData({});
      setSelectedEmployees([]);
      dispatch(actions.setParamsAndFetch({ ...params }));
    }
  };

  const handleChangeEmployees = (data) => {
    setSelectedEmployees(data);
  };

  const handleRejection = (id, clientId) => {
    if (clientId) {
      history.push(`/briefs/v3/edit/${id}`);
    } else {
      history.push(`/briefs/rejected/${id}`);
    }
  };

  const handleDownloadMedia = (id) => {
    dispatch(actions.fetchDownloadMedia(id));
  };

  const statusOptions: IOptionForSelect[] = BRIEF_STATUS_LIST.map(
    (opt: BRIEF_STATUS) => {
      return { label: BRIEF_STATUS_NAMES[opt], value: opt };
    }
  );

  const handleOnRowClick = (brief: IBriefListElement, rowIndex: Number) => ({
    onClick: (event: any) => {
      try {
        document.getElementsByClassName("q-table_menu")[rowIndex].click();
      } catch (error) {}
    }
  });

  const briefStatusGroup: IBriefStatusGroup = {
    id: BRIEF_STATUS_GROUP.BRIEF,
    name: BRIEF_STATUS_GROUP.BRIEF,
    label: t(`brief_statuses`),
    statuses: BRIEF_STATUS_LIST.map((status, index) => ({
      id: `${BRIEF_STATUS_GROUP.BRIEF}_${index}`,
      name: status,
      label: t(BRIEF_STATUS_NAMES[status], { ns: "translation" }),
    })),
  };

  const paymentStatusGroup: IBriefStatusGroup = {
    id: BRIEF_STATUS_GROUP.PAYMENT,
    name: BRIEF_STATUS_GROUP.PAYMENT,
    label: t(`payment_statuses`),
    statuses: BRIEF_PAYMENT_STATUS_LIST.map((status, index) => ({
      id: `${BRIEF_STATUS_GROUP.PAYMENT}_${index}`,
      name: status,
      label: t(BRIEF_PAYMENT_STATUS_NAMES[status], { ns: "translation" }),
    })),
  };

  const campaignStatusGroup: IBriefStatusGroup = {
    id: BRIEF_STATUS_GROUP.CAMPAIGN,
    name: BRIEF_STATUS_GROUP.CAMPAIGN,
    label: t(`campaign_statuses`),
    statuses: CAMPAIGN_STATUS_LIST.map((status, index) => ({
      id: `${BRIEF_STATUS_GROUP.CAMPAIGN}_${index}`,
      name: status,
      label: lodash.capitalize(t(campaignStatusesLabel[status].label, {ns: "translationCampaign"})),
    })),
  };

  const statusGroups: IBriefStatusGroup[] = [
    briefStatusGroup,
    campaignStatusGroup,
    paymentStatusGroup,
  ];

  const handleSwitcherChange = (event: any) => {
    if (event.target.value === TABS.TRACKER) {
      handleChangeSelect([`BRIEF-${BRIEF_STATUS.APPROVED}`]);
      setActiveTab(TABS.TRACKER);
      return;
    }

    handleChangeSelect([]);
    setActiveTab(TABS.CAMPAIGNS);
  };

  const renderSwitcher = () => {
    return (
      <div className="mb-5 mt-10 pb-10">
        <Radio.Group
          defaultValue={TABS.CAMPAIGNS}
          onChange={handleSwitcherChange}
          buttonStyle="solid"
        >
          <Radio.Button className="qu-radio-button" value={TABS.CAMPAIGNS}>
            <span className="text-uppercase">{t(`campaigns`)}</span>
          </Radio.Button>
          <Radio.Button className="qu-radio-button" value={TABS.TRACKER}>
            <span className="text-uppercase">{t(`tracker`)}</span>
          </Radio.Button>
        </Radio.Group>
      </div>
    );
  };

  const isTrackerTabActive = activeTab === TABS.TRACKER;

  return (
    // qu-brief-wide
    <div className="qu-brief-view">
      {/*renderSwitcher()*/}
      <Row justify="space-between" className="pb-20 pt-20">
        <Col>
          <h2>{t(`campaigns`)}</h2>
        </Col>
        {location.pathname === "/briefs" && <Col>
              <QButton htmlType="button" style={{top: '-1px'}} className="qu-button-outline-green" onClick={()=>history.push(`/calendar`)}>
                <CalendarOutlined color="#2BC155" />
              </QButton>
              &nbsp;&nbsp;
              <QButton type="primary" htmlType="button" onClick={()=>history.push(`/briefs/create`)}>
                <span className="inlineTextIcon">+</span>&nbsp;{t(`menu.new_campaign`, {ns: 'translationPlatform'})}
              </QButton>
          </Col>}
      </Row>
      {hasPermission(permissions, PERMISSIONS.BRIEFS_ALL_ONLY_ASSIGNED) && (
        <Row>
          <Col>
            <QAlert
              message={t(
                "alert.ALERT_YOU_ARE_VIEWING_CAMPAIGNS_WHICH_ARE_ASSIGNED_TO_YOU",
                {
                  ns: "translationCommon",
                }
              )}
              type="info"
              className="centered pb-15"
            />
          </Col>
        </Row>
      )}
      <div className="qu-brief-list pb-20">
        <Row
          className={`${isMobile && "pb-15"}`}
          justify={`${isMobile ? "center" : "space-between"}`}
        >
          <Col className="qt-search">
            <QInput
              className={`${isMobile && "full-width"}`}
              value={search}
              onChange={handleChangeSearch}
              placeholder={t(`search`)}
              size="large"
              prefix={<SearchOutlined />}
            />
          </Col>
          <Col className="qt-filter">
              <QSelect
                showArrow
                maxTagTextLength={8}
                maxTagCount={2}
                onChange={handlePublisherSelect}
                size="large"
                // options={publisherOptions}
                mode="multiple"
                placeholder={t(`filter_by_publisher`)}
                // showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {publisherOptions.map((e) => (
                  <Option key={e.value} value={e.value}>
                    {e.label}
                  </Option>
                ))}
              </QSelect>
          </Col>
          {!isTrackerTabActive && (
            <Col className="qt-filter">
              <QSelect
                showArrow
                maxTagTextLength={8}
                maxTagCount={2}
                onChange={handleChangeSelect}
                mode="multiple"
                size="large"
                placeholder={t(`filter_by_status`)}
              >
                {statusGroups.map((group: IBriefStatusGroup) => (
                  <OptGroup key={group.id} label={group.label}>
                    {group.statuses.map((status: IBriefStatus) => (
                      <Option
                        key={status.id}
                        value={`${group.name}-${status.name}`}
                      >
                        {status.label}
                      </Option>
                    ))}
                  </OptGroup>
                ))}
              </QSelect>
            </Col>
          )}
        </Row>
        {false ? (
          <div className="pl-15 pr-15">
            <QTableCardList
              cardList={cardList}
              labelStatus={briefStatuses}
              menu={menu}
              onCardClick={(briefId) => handleView(briefId)}
            />
          </div>
        ) : (
          <div className="pt-15 pb-30">
            <QTable
              //@ts-ignore
              onRow={handleOnRowClick}
              onChange={handleTableChange}
              sortDirections={["ascend", "descend", "ascend"]}
              loading={briefListLoading}
              columns={activeTab === TABS.CAMPAIGNS ? columns : trackerColumns}
              dataSource={briefList}
              scroll={{ x: 990 }}
              rowClassName={(record) =>
                "q-table cursorPointer"
              }
            />
          </div>
        )}

        <Modal
          key={tableKey1}
          title={t(`are_you_sure`)}
          visible={modal.visible}
          onOk={handleOk}
          onCancel={() => {
            setModal({ visible: false });
            setTableKey1(tableKey1 + 1);
          }}
        >
          <p>{t(`confirm_delete`)}</p>
        </Modal>

        <Modal
          key={tableKey}
          title={<h3>{t(`assign_brief`)}</h3>}
          visible={assignBriefToEmployeeModal}
          onCancel={() => {
            setAssignBriefToEmployeeModal(false);
            setAssignBriefToEmployeeData({});
            setSelectedEmployees([]);
            setTableKey(tableKey + 1);
          }}
          footer={
            <>
              <Button
                key="back"
                onClick={() => {
                  setAssignBriefToEmployeeModal(false);
                  setAssignBriefToEmployeeData({});
                  setSelectedEmployees([]);
                  setTableKey(tableKey + 1);
                }}
              >
                {t(`cancel`)}
              </Button>
              <Button
                key="submit"
                type="primary"
                onClick={assignBriefToEmployee}
              >
                {t(`save`)}
              </Button>
            </>
          }
        >
          <div>
            <label className="assignBrief">{t(`brief`)}</label>
            <QInput
              className="mt-5 mb-15"
              size="large"
              disabled={true}
              value={
                assignBriefToEmployeeData &&
                assignBriefToEmployeeData.product &&
                assignBriefToEmployeeData.product.title
              }
            />

            <label className="assignBrief">{t(`employees`)}</label>
            <QSelect
              value={selectedEmployees}
              mode="multiple"
              size="large"
              maxTagCount={2}
              showArrow
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              placeholder={t(`select_employees`)}
              className="mt-5"
              style={{ width: "100%" }}
              onChange={handleChangeEmployees}
            >
              {employeesList.map((e) => (
                <Option key={e.value} value={e.value}>
                  {e.label}
                </Option>
              ))}
            </QSelect>
          </div>
        </Modal>

        <Row
          justify={`${isMobile ? "center" : "end"}`}
          className={`${isMobile && "pt-15 mb-30"}`}
        >
          <QPagination
            className="force-ltr"
            size="default"
            defaultCurrent={1}
            current={pages.currentPage}
            total={totalBriefList}
            onChange={handleChangePage}
            showSizeChanger
          />
        </Row>
      </div>
    </div>
  );
};

export default BriefList;
