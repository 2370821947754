import { createSelector } from 'reselect'
import {
  IAnalyticsCity,
  ICampaignAnalyticsDistData,
  ICampaignAnalyticsInfo,
  ICampaignAnalyticsMediaBuyData,
  ICampaignDetails,
  ICampaignDistAnalyticsFilter,
  ICampaignMediaBuyData,
  ICampaignMediaBuyFilter,
  ICampaignPartner,
  ICampaignProps,
  ICampaignQuantityInfo,
  ICampaignSettings,
  ICampaignStats,
  ICampaignSurvey,
  ICampaignTable,
  ICampaignTableParams,
  ICampaignDistribution,
  IChartData,
  IState,
  ITimeline,
  ICampaignTimeline,
  ICampaignTimelineTable,
  IClientSurveysParams,
  IClientSurvey,
  IClientSurveyAnswer,
  ICampaignGalleryItem,
  ICampaignGalleryParams,
} from './campaign.types'
import { MODULE_NAME } from './campaign.constants'
import moment from 'moment'
import { campaignsToTable, onlyDistributionPartners, onlyMediaBuyPartners } from '../../helpers/campaign'
import { FORMATS } from '../../constants'
import { CAMPAIGN_METRICS, CAMPAIGN_METRICS_LIST } from '../../constants/campaign'
import { getCurrency } from '../core/core.selectors'
import { CURRENCY } from '../../constants/currencies'

const selectState = (state: { [MODULE_NAME]: IState }): IState => state[MODULE_NAME]

export const getCampaignListLoading = createSelector(selectState, (state: IState): boolean => state.campaignListLoading)

export const getCampaignList = createSelector(
  [selectState, getCurrency],
  (state: IState, currency: CURRENCY): ICampaignTable[] => {
    const tableList = campaignsToTable(state.campaignList.results, currency)

    return tableList
  }
)

export const getCampaignStats = createSelector(
  selectState,
  (state: IState): ICampaignStats => {
    return state.campaignList.stats
  }
)

export const getTotalCampaignList = createSelector(selectState, (state: IState): number => state.campaignList.total)

export const getCampaignParams = createSelector(selectState, (state: IState): ICampaignTableParams => state.params)

export const getPages = createSelector(
  selectState,
  (
    state: IState
  ): {
    totalPages: number
    currentPage: number
  } => state.pages
)

export const getCampaignDetails = createSelector(
  selectState,
  (state: IState): ICampaignDetails | null => state.campaignDetails
)
export const getCampaignDetailsLoading = createSelector(
  selectState,
  (state: IState): boolean => state.campaignDetailsLoading
)

export const getCampaignSettings = createSelector(selectState, (state: IState): ICampaignSettings => state.settings)

export const getCampaignPartnerOnlyDistribution = createSelector(selectState, (state: IState): ICampaignPartner[] => {
  return onlyDistributionPartners(state.settings.partnerList)
})

export const getCampaignPartnerOnlyMediaBuy = createSelector(selectState, (state: IState): ICampaignPartner[] => {
  return onlyMediaBuyPartners(state.settings.partnerList)
})

export const getCampaignProps = createSelector(
  selectState,
  (state: IState): ICampaignProps | null => state.campaignProps
)

export const getCampaignQuantityInfo = createSelector(
  selectState,
  (state: IState): ICampaignQuantityInfo => state.campaignQuantityInfo
)

export const getCampaignPartnerDistribution = createSelector(
  selectState,
  (state: IState): ICampaignQuantityInfo =>
    state.campaignPartnerDistribution
      .sort((a: any, b: any) => {
        return +new Date(b.date) - +new Date(a.date)
      })
      .map((e: ICampaignDistribution) => ({
        ...e,
        key: e.id,
        date: moment(e.date).format(FORMATS.DATE_LONG_FORMAT),
      }))
)

export const getCampaignUpdating = createSelector(selectState, (state: IState): boolean => state.campaignUpdating)

export const getCampaignAnalyticsInfo = createSelector(
  selectState,
  (state: IState): ICampaignAnalyticsInfo | null => state.campaignAnalyticsInfo
)

export const getCampaignAnalyticsTimeline = createSelector(selectState, (state: IState): ITimeline[] => {
  const timelines = state.campaignTimelineList.results.map((timeline: ICampaignTimeline) => {
    return {
      id: timeline.id,
      timelineCaption: timeline.title,
      timelineDate: moment(timeline.startedDate).format(FORMATS.DATE_LL_FORMAT),
      timelinePhotos: timeline.allImages,
      timelinePartner: timeline.partnerProfile?.companyName,
    } as ITimeline
  })

  return timelines
})

export const getCampaignAnalyticsData = createSelector(
  selectState,
  (state: IState): ICampaignAnalyticsDistData | null => state.campaignAnalyticsData
)
export const getCampaignAnalyticsDataCities = createSelector(selectState, (state: IState): IChartData[] => {
  return state.campaignAnalyticsData
    ? state.campaignAnalyticsData.reachVolumes.map(
        (c: IAnalyticsCity) => ({ name: c.cityName, value: c.quantity } as IChartData)
      )
    : []
})

export const getCampaignAnalyticsDataGender = createSelector(selectState, (state: IState): IChartData[] => {
  const data = state.campaignAnalyticsData
  return data
    ? Object.keys(data.gender).map(
        (key: string) =>
          //@ts-ignore
          ({ name: key, value: data.gender[key] } as IChartData)
      )
    : []
})

export const getCampaignAnalyticsDataAgeGroups = createSelector(selectState, (state: IState): IChartData[] => {
  const data = state.campaignAnalyticsData
  return data
    ? Object.keys(data.ageGroups).map(
        (key: string) =>
          //@ts-ignore
          ({ name: key, value: data.ageGroups[key] } as IChartData)
      )
    : []
})

export const getCampaignAnalyticsDataLoading = createSelector(
  selectState,
  (state: IState): boolean => state.campaignAnalyticsDataLoading
)

export const getCampaignAnalyticsDistFilter = createSelector(
  selectState,
  (state: IState): ICampaignDistAnalyticsFilter => state.campaignDistAnalyticsFilter
)

export const getCampaignAnalyticsMediaBuyFilter = createSelector(
  selectState,
  (state: IState): ICampaignMediaBuyFilter => state.campaignMediaBuyAnalyticsFilter
)

export const getCampaignAnalyticsMediaBuyData = createSelector(
  selectState,
  (state: IState): ICampaignAnalyticsMediaBuyData | null => state.campaignMediaBuyAnalyticsData
)

export const getCampaignPartners = createSelector(
  selectState,
  (state: IState): ICampaignPartner[] => state.settings.partnerList
)

export const getCampaignSurveyList = createSelector(
  selectState,
  (state: IState): ICampaignSurvey[] => state.campaignSurveyList
)

export const getCampaignSurveyLoading = createSelector(
  selectState,
  (state: IState): boolean => state.campaignSurveyLoading
)

export const getCampaignSurveySaving = createSelector(
  selectState,
  (state: IState): boolean => state.campaignSurveySaving
)
export const getCampaignMetrics = createSelector(selectState, (state: IState): CAMPAIGN_METRICS[] => {
  return CAMPAIGN_METRICS_LIST
  // return state.settings.mediaBuyMetrics
})

export const getCampaignMediaBuyFilter = createSelector(
  selectState,
  (state: IState): ICampaignMediaBuyFilter[] => state.campaignMediaBuyFilter
)

export const getCampaignMediaBuyData = createSelector(
  selectState,
  (state: IState): ICampaignMediaBuyData[] => state.campaignMediaBuyData
)

export const getCampaignMediaBuyDataLoading = createSelector(
  selectState,
  (state: IState): boolean => state.campaignMediaBuyDataLoading
)

export const getCampaignTimelineList = createSelector(selectState, (state: IState): ICampaignTimelineTable[] => {
  const timelines = state.campaignTimelineList.results.map((timeline: ICampaignTimeline) => {
    return {
      key: timeline.id!,
      title: timeline.title,
      date: timeline.startedDate,
      partnerId: timeline.partnerId,
      partner: timeline.partnerProfile,
      media: timeline.allImages,
    } as ICampaignTimelineTable
  })

  return timelines
})

export const getTotalCampaignTimelineList = createSelector(
  selectState,
  (state: IState): number => state.campaignTimelineList.total
)

export const getTimelinePages = createSelector(
  selectState,
  (
    state: IState
  ): {
    totalPages: number
    currentPage: number
  } => state.timelinePages
)

export const getCampaignTimelineListLoading = createSelector(
  selectState,
  (state: IState): boolean => state.campaignTimelineListLoading
)

export const getUploadingCampaignTimelineImages = createSelector(
  selectState,
  (state: IState): boolean => state.uploadingTimelineImage
)

export const getTotalClientSurveysList = createSelector(
  selectState,
  (state: IState): number => state.clientSurveys.total
)

export const getClientSurveysParams = createSelector(
  selectState,
  (state: IState): IClientSurveysParams => state.surveyParams
)

export const getClientSurveysPages = createSelector(
  selectState,
  (
    state: IState
  ): {
    totalPages: number
    currentPage: number
  } => state.surveysPages
)

export const getClientSurveysList = createSelector(selectState, (state: IState): IClientSurvey[] => {
  const surveys = state.clientSurveys.results.filter((survey: IClientSurvey) =>
    survey.sumAnswers.some((a: IClientSurveyAnswer) => !(a.sumQuantity < 0))
  )
  return surveys
})
export const getClientSurveysListLoading = createSelector(
  selectState,
  (state: IState): boolean => state.clientSurveysLoading
)

export const getCampaignGalleryLoading = createSelector(
  selectState,
  (state: IState): boolean => state.campaignGalleryListLoading
)

export const getCampaignGallerySaving = createSelector(selectState, (state: IState): boolean => state.savingGallery)

export const getCampaignGalleryList = createSelector(selectState, (state: IState): ICampaignGalleryItem[] => {
  return state.campaignGalleryList.results
})

export const getGalleryPages = createSelector(
  selectState,
  (
    state: IState
  ): {
    totalPages: number
    currentPage: number
  } => state.galleryPages
)

export const getTotalCampaignGalleryList = createSelector(
  selectState,
  (state: IState): number => state.campaignGalleryList.total
)

export const getCampaignGalleryParams = createSelector(
  selectState,
  (state: IState): ICampaignGalleryParams => state.galleryParams
)
