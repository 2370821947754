import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { AxiosResponse } from 'axios'
import IAction from 'src/interfaces/IAction'

import * as CONSTANTS from './create-client.constants'
import { requestHttp, urls } from 'src/api'
import { getResponseErrorMessage } from 'src/helpers'

export const createClientRequest = (): IAction => ({
  type: CONSTANTS.CREATE_CLIENT_REQUEST,
})

export const createClientSuccess = (data: any): IAction => ({
  type: CONSTANTS.CREATE_CLIENT_SUCCESS,
  payload: data,
})

export const createClientFailure = (error: string): IAction => ({
  type: CONSTANTS.CREATE_CLIENT_FAILURE,
  error,
})

export const createClient = (payload:any): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse> => {
  try {
    dispatch(createClientRequest())
    const response = await requestHttp.post(urls.getAdminCreatClientUrl(),payload)
    // console.log(response)
    if(response.status === 201){
      dispatch(createClientSuccess(response))  
    }else{
      // dispatch(createClientFailure(getResponseErrorMessage(response.error)))
      throw response;
    }
    return response
  } catch (error) {
    dispatch(createClientFailure(getResponseErrorMessage(error)))
    return error
  }
}
